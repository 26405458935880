import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const SubNavContainer = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    
`

export const SubNavItem = styled.li`
    /* margin-bottom: 1rem; */
`

export const SubNavLinkItem = styled.div`
           border-right: 2px solid ${({isActive, theme}) => isActive ? '#fff': '#ff'};
           padding-bottom:5px ;
           
`

export const SubNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    text-decoration: none;
   
    padding: 0.5rem 0 0.5rem 4.25rem;
    color: ${({theme}) => "#fff"};
    margin-right:11px;
    &:hover {
        border-radius: 4px;
        background: #fff 0% 0% no-repeat padding-box;
        color: #ff0000;
        margin-right:11px;
        margin-left:2px ;
    }

    &.${({activeclassname}) => activeclassname} {
        /* color: ${({theme}) => theme.colors.primary}; */
        border-radius: 4px;
        margin-left:2px ;
        background: #fff 0% 0% no-repeat padding-box;
        color: #ff0000;
      
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        padding: 0.5rem 0 0.5rem 4.25rem;
    }
`