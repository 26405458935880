import styled from 'styled-components/macro';



export const PVToolbar  = styled.div`
    display: flex;
    align-items: center;
`;

export const PVToolbarElements = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PVToolbarElementsActions = styled.div`
    display: flex;
    align-items: center;
`

export const PVToHideMobile = styled.div`
    display: block;
    padding: 0px 2px;

    @media (min-width: 320px) and (max-width: 767px) {
        display: none;
    }
`
export const NavTabsLi = styled.li`
    display: flex;
    justify-content: start;
    align-items: start;
    margin-right: 1rem;
    marg
    /* background-color: white; */
    `

export const PVContainer = styled.div`
    font-family:'Courier New';
    height: 600px;
    overflow: auto;
    ma
    & .rpv-default-layout-body {
        background-color: #ffffff;
    }

    & .rpv-default-layout-toolbar {
        background-color: rgba(25, 98, 158, 0.3);
    }

    & .rpv-default-layout-toolbar svg {
        stroke: ${({ theme }) => theme.colors.text};
    }

    & .rpv-default-layout-sidebar-headers{
        background-color: rgba(25, 98, 158, 0.3);
    }

    & .rpv-default-layout-sidebar-headers svg {
        stroke: ${({ theme }) => theme.colors.text};
    }

    @media (min-width: 320px) and (max-width: 767px) {
        .rpv-default-layout-sidebar {
            display: none;
        }
        .rpv-default-layout-container{
            z-index: 100;
        }
    }
`;
