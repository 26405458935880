import React, { useEffect, useState } from 'react'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';
import { CTooltip } from '../../../UI/CTooltip/CTooltip';
import Axios from '../../../../axios-proas';
import { formatDate } from '../../../../shared/utility';

function EntitiesTable({entities, selectEntity, selectedEntities, ownerUid, thresholdDate}) {
    const [rows, setRows] = useState(entities);

    useEffect(() => {
        setRows(entities)
    }, [entities])

    const loadInvoices = entity => {
        if(!entity.unpaidInvoices)
            Axios.get("accountingaudit/"+ownerUid+"/suppliersUnpaidInvoices", {params: {supplier: entity.uid, thresholdDate: thresholdDate}}).then(response => {
                const rowsTemp = [...rows];
                rowsTemp.find(r => r.id == entity.id).unpaidInvoices = response.data;
                setRows(rowsTemp);
            })
    }

    const displayUnpaidInvoices = entity => {
        return <table>
            <thead>
                <tr>
                    <th style={{border: "1px solid grey"}}>Numéro de facture</th>
                    <th style={{border: "1px solid grey"}}>Montant TTC</th>
                    <th style={{border: "1px solid grey"}}>Montant HT</th>
                    <th style={{border: "1px solid grey"}}>Date de facture</th>
                </tr>
            </thead>
            <tbody>
                {entity.unpaidInvoices.map(i => <tr key={i.uid}>
                    <td style={{border: "1px solid grey"}}>{i.number}</td>
                    <td style={{border: "1px solid grey"}}>{i.inclusiveTaxAmount}</td>
                    <td style={{border: "1px solid grey"}}>{i.exclusiveTaxAmount}</td>
                    <td style={{border: "1px solid grey"}}>{formatDate(+i.issuingDate)}</td>
                </tr>)}
            </tbody>
        </table>
    }

    return (
        <table className="table repertoireModal__entities_table">
            <thead className="thead-light">
                <tr>
                    <th scope="col" style={{width: "50px"}}></th>
                    <th scope="col">Raison sociale</th>
                    <th scope="col">Code</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Contacts actifs</th>
                    <th scope="col">Factures impayées à date</th>
                </tr>
            </thead>
            <tbody>
                {
                    rows?.length > 0 ? rows.map(entity => (
                        <tr key={entity.id}>
                            <td>
                                <div className="repertoireModal__entities_selectIcon"  onClick={() => {+entity.activeUsers>0 && +entity.hasUnpaidInvoices && selectEntity({uid : entity.uid, name : entity.name})}}>
                                    {selectedEntities.some(row => (row.uid === entity.uid))  ? 
                                        <ThumbUpIcon style={+entity.activeUsers>0 && +entity.hasUnpaidInvoices ? {cursor: "pointer", color: "#56296f"}:{cursor: "pointer", color: "#4341418c"}} />
                                    : <CTooltip title={+entity.activeUsers>0 && +entity.hasUnpaidInvoices ? "" : "Sélection impossible si pas de contact actif ou si pas de facture impayée à la date sélectionnée"}>
                                        <AddIcon style={+entity.activeUsers>0 && +entity.hasUnpaidInvoices ? {cursor: "pointer", color: "red"}:{cursor: "pointer", color: "#4341418c"}} />
                                    </CTooltip>}
                                </div>
                            </td>
                            <td>{entity.name} </td>
                            <td>{entity.code} </td>
                            <td>{entity.address} </td>
                            <td>{+entity.activeUsers ? "Oui" : "Non"} </td>
                            <td>{+entity.hasUnpaidInvoices ? 
                                    <CTooltip title={entity.unpaidInvoices && entity.unpaidInvoices?.length > 0 ? displayUnpaidInvoices(entity) : ""}>
                                        <a href="#" style={{color: "red"}} onMouseOver={() => {loadInvoices(entity)}}>Oui</a>
                                    </CTooltip>
                                    : "Non"}
                            </td>
                        </tr>
                    )) : <tr style={{whiteSpace: "nowrap", textAlign: "center"}}>
                            <td colSpan={6} style={{fontSize:"20px"}}>Aucune entreprise à afficher</td>
                        </tr>
                }
            </tbody>
        </table>
    )
}

export default EntitiesTable
