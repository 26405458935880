import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PdfViewer from "./PdfViewer/PdfViewer";
import * as actions from "../../../../../store/actions/index";
import { useParams } from "react-router-dom";
import XMLViewer from "react-xml-viewer";
import { IVContainer, EntHeaderWrapper, XmlDiv, IVWrapper, IVDownloadContainer, IVDownloadIcon } from "./InvoiceView.styled";
import {
  NavTabsLi,
  NavTabsLink,
  NavTabsUl,
} from "../../../../../containers/Entity/Entity.styled";
import EdiViewer from "../EdiViewer/EdiViewer";
import DownloadIcon from "@mui/icons-material/Download";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next"; 
function InvoiceView({
  navTabsList,
  loadingPdf,
  pdfFile,
  ediFileName,
  xmlFile,
  exportedXmlFile,
  ediFile,
  chorusFile,
  chorusFileName,
  pdfFileName,
  mimeType,
  xmlFileName,
  exportedXmlFileName,
  getInvoicePdf,
  getInvoicePdfByToken,
}) {
  const [tab, setTab] = useState(navTabsList[0]?.value);
  const {t} = useTranslation();

  let { uid, token } = useParams();
  const customTheme = {
    attributeKeyColor: "#FF0000",
    attributeValueColor: "#000FF",
    overflowBreak: true,
  };

  const changeTab = (tab) => {
    console.log(tab)
    if(tab.label!='GED'){
      setTab(tab.value);
    } else {
      window.open(tab.value, '_blank', 'noopener,noreferrer');
    }
  };

  const downloadClickHandler = (tab) => {
    const cleanXmlFileName = xmlFileName ? xmlFileName.split(".")[0]+".XML" : null;
    const cleanExportedXmlFileName = exportedXmlFileName ? exportedXmlFileName.split(".")[0]+".XML" : null;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE workaround
      if (tab == "xml") {
        var byteCharacters = atob(xmlFile);
      } else if (tab == "exportedXml") {
        var byteCharacters = atob(exportedXmlFile);
      } else {
        var byteCharacters = atob(ediFile);
      }

      let byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: "text/plain" });
      window.navigator.msSaveOrOpenBlob(
        blob,
        tab == "xml" ? cleanXmlFileName : (tab == "exportedXml" ? cleanExportedXmlFileName : ediFileName || "facture.xml")
      );
    } else {
      // much easier if not IE
      //________Direct Download_____
      var blob = null;
      switch (tab) {
        case "xml":
          blob = new Blob([xmlFile], { type: "text/plain" });
          break;
        case "exportedXml":
          blob = new Blob([exportedXmlFile], { type: "text/plain" });
          break;
        case "edi":
          blob = new Blob([ediFile], { type: "" });
          break;
        case "chorus":
          blob = new Blob([chorusFile], { type: "text/plain" });
          break;
        default:
          break;
      }

      //const downloadUrl = URL.createObjectURL(blob)
      let a = document.createElement("a");
      //a.setAttribute('href', window.URL.createObjectURL(blob));
      a.href = window.URL.createObjectURL(blob);
      a.download =
        tab == "xml"
          ? cleanXmlFileName
          : tab == "exportedXml"
          ? cleanExportedXmlFileName
          : tab == "edi"
          ? ediFileName
          : chorusFileName || "facture.xml";
      a.dataset.downloadurl = ["", a.download, a.href].join(":");
      document.body.appendChild(a);
      a.click();

      //__________Visualize In The Browser _____
      // const blob = dataURItoBlob(data);
      // const url = URL.createObjectURL(blob);

      // // to open the PDF in a new window
      // window.open(url, '_blank');
    }
  };

  useEffect(() => {
    if (token) getInvoicePdfByToken(token);
    else getInvoicePdf(uid, tab);
  }, [uid, token, tab]);

  let admView = null;

  if (loadingPdf) admView = <Spinner />;
  else {
    if (pdfFile || xmlFile || exportedXmlFile || ediFile || chorusFile)
      admView = (
        <>
          {mimeType === "application/pdf" && tab == "pdf" ? (
            <PdfViewer pdfFile={pdfFile} pdfFileName={pdfFileName} />
          ) : xmlFile && tab == "xml" ? (
            <XmlDiv>
              <XMLViewer xml={xmlFile} theme={customTheme} />
            </XmlDiv>
          ) : exportedXmlFile && tab == "exportedXml" ? (
            <XmlDiv>
              <XMLViewer xml={exportedXmlFile} theme={customTheme} />
            </XmlDiv>
          ) : ediFile && tab == "edi" ? (
            <EdiViewer ediFile={ediFile} ediFileName={ediFileName} />
          ) : chorusFile && tab == "chorus" ? (
            <XMLViewer xml={chorusFile} theme={customTheme} />
          ) : null}
        </>
      );
    else
      admView = (
        <>
          <div>
            {" "}
            {tab == "pdf" ? (
              <h5>{t("invoiceDetails:hasNoPdf", "This invoice has no PDF")}</h5>
            ) : tab == "xml" ? (
              <h5>Cette facture ne contient pas de XML </h5>
            ) : tab == "exportedXml" ? (
              <h5>Cette facture ne contient pas de XML exporté à CODA</h5>
            ) : tab == "edi" ? (
              <h5>Cette facture ne contient pas de EDI </h5>
            ) : tab == "chorus" ? (
              <h5>Cette facture ne contient pas de CHORUS </h5>
            ) : null}
          </div>
        </>
      );
  }

  return (
    <IVWrapper>
      <EntHeaderWrapper className="row">
        <NavTabsUl>
          {navTabsList.map((t, index) => (
            <NavTabsLi key={t.value}>
              <NavTabsLink
                active={+(tab === t.value)}
                onClick={() => t.value && changeTab(t)}
                to={`#`}
              >
                {t.label}
              </NavTabsLink>
            </NavTabsLi>
          ))}
        </NavTabsUl>
      </EntHeaderWrapper>
      {(tab == "xml" || tab == "exportedXml" || tab == "edi" || tab == "chorus") && (
          <IVDownloadContainer>
              <IVDownloadIcon
                onClick={() => downloadClickHandler(tab)}
                >
                    <DownloadIcon />
                </IVDownloadIcon>
          </IVDownloadContainer>
      )}
      <IVContainer accessToken={token}>{admView}</IVContainer>
    </IVWrapper>
  );
}
const mapStateToProps = (state) => ({
  loadingPdf: state.invoiceDetail.loadingPdf,
  ediFile: state.invoiceDetail.pdfFile?.ediFile || null,
  pdfFile: state.invoiceDetail.pdfFile?.pdfFile || null,
  xmlFile: state.invoiceDetail.pdfFile?.xmlFile || null,
  exportedXmlFile: state.invoiceDetail.pdfFile?.exportedXmlFile || null,
  errorPdf: state.invoiceDetail.errorPdf,
  pdfFileName: state.invoiceDetail?.pdfFile?.docFile || "facture.pdf",
  xmlFileName: state.invoiceDetail?.pdfFile?.docXml || "facture.xml",
  exportedXmlFileName: state.invoiceDetail?.pdfFile?.exportedDocXml || "facture.xml",
  ediFileName: state.invoiceDetail?.pdfFile?.docEdi || "facture.EDI",
  mimeType: state.invoiceDetail?.pdfFile?.mimeType,
  chorusFile: state.invoiceDetail.pdfFile?.chorusFile || null,
  chorusFileName: state.invoiceDetail?.pdfFile?.docChorus || "facture.xml",
});
const mapDispatchToProps = (dispatch) => ({
  getInvoicePdf: (invoiceUid, type) =>
    dispatch(actions.getInvoicePdf(invoiceUid, type)),
  getInvoicePdfByToken: (invoiceToken) =>
    dispatch(actions.getInvoicePdfByToken(invoiceToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView);
