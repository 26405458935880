import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import { AppBody, AppContainer, AppContent,AppWrapper } from '../../../styles/App.styled';
import Menu from '../../Menu/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import PublishIcon from '@mui/icons-material/Publish';
import HomeSupplier from '../../HomeSupplier/HomeSupplier';
import { connect } from 'react-redux';
import Entity from '../../Entity/Entity';
import UserForm from '../../UserForm/UserForm';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import UploadInvoice from '../../UploadInvoice/UploadInvoice';
import EditPassword from '../../EditPassword/EditPassword';
import UsersManagement from '../../UsersManagement/UsersManagement';
import ListAltIcon from '@mui/icons-material/ListAlt';
import * as actions from '../../../store/actions/';
import { BtnOutlineAction } from '../../../styles/Common';

function SupplierRoot({userLevel, statuses}) {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState(t("menu:home", 'Accueil'));
  const menuItems = useMemo(() => {
      const items = [
        {isLink: true, key: "home", label: t("menu:home", 'Home'), pathname: '/', icon: <HomeIcon />},
        // {isLink: true, label: 'Référentiel', pathname: `/referentiel`, icon: <BusinessIcon />},
        {isLink: true, key: "invoicesList", label: t("menu:invoiceTracking", 'Invoice tracking'), pathname: '/invoices', icon: <DescriptionIcon />},
        {isLink: true, key: "upload", label: t("menu:upload", 'Upload an invoice or a credit note'), pathname: '/upload', icon: <PublishIcon />}
    ];
    if(userLevel === "admin")
      items.push({isLink: true, key: "contactsList", label: t("menu:contactList", 'Contact list'), pathname: '/contacts', icon: <ListAltIcon />})
    return items;
  }, [t, userLevel])


  useEffect(() => {
    switch (location.pathname) {
      case "/invoices":
        setPageTitle(t("menu:invoiceTracking", 'Invoice tracking'));
        break;
      case "/userForm":
        setPageTitle(t("user:contactCreation", 'Contact creation'));
        break;
      case "/editPassword":
        setPageTitle(t("authentication:changePassword", "Password change"));
        break;
      case "/upload":
        setPageTitle(t("menu:upload", 'Upload an invoice or a credit note'));
        break;
      case "/contacts":
        setPageTitle(t("menu:contactList", 'Contact list'));
        break;
      default:
        if (location.pathname?.includes("/invoiceDetail/"))
          setPageTitle(t("menu:invoiceDetails", 'Invoice details'));
        // else if (location.pathname?.includes("/entity/"))
        //   setPageTitle("Consultation d'entité fournisseur");
        else if (location.pathname?.includes("/userForm/"))
          setPageTitle(t("user:contactEdition", 'Contact edition'));
        else
          setPageTitle(t("menu:home", 'Home'));
    }
  }, [location])

  return (
  <>
  
        <AppContainer>     
       <Menu items={menuItems} />
       <AppBody style={{flexDirection:"column",margin:""}} >
       <Header pageTitle={pageTitle}/>
        <AppWrapper>
          <AppContent>
            {location.pathname !== "/" && <BtnOutlineAction
                  border="red"
                  color="red"
                  style={{position: "absolute", zIndex:100, marginLeft: 0}}
                  onClick={() => navigate(-1) }>
                  <span>{t("global:back", "Back")}</span>
              </BtnOutlineAction>}
            <Routes>
                <Route path="/invoices" element={ <SuiviInvoices entityFilter={"supplier"} statuses={statuses} />}/>
                <Route path="/invoiceDetail/:uid" element={<InvoiceDetail statuses={statuses} />} />
                <Route path="/upload" element={<UploadInvoice />} />
                <Route path="/userForm/:uid" element={<UserForm />} />
                <Route path="/userForm" element={<UserForm />} />
                <Route path="/editPassword" element={<EditPassword />}/>
                <Route path="/contacts" element={<UsersManagement entityFilter={"supplier"}/>} />
                <Route path="/" element={<HomeSupplier statuses={statuses} />} />
            </Routes>
            </AppContent>
                </AppWrapper>
            
            </AppBody>
                
            </AppContainer>

  </>
  )
}

// const mapStateToProps = (state) => ({
//   userType : state.auth.userType,
//   login : state.auth.login
// })

// const mapDispatchToProps = dispatch => ({
// })
// export default connect(mapStateToProps, mapDispatchToProps) (SupplierRoot)



const mapStateToProps = (state) => ({
  loading: state.suppliers.loading,
  suppliersData: state.suppliers.data,
  count: state.suppliers.count,
  entityFirst : state.auth.entityFirst,
  status : state.auth.status,
  userLevel : state.auth.userLevel
 

})
const mapDispatchToProps = dispatch => ({
  getSuppliersPage: (page, filters, sort, reverse, searchField, pageSize) => dispatch(actions.suppliersGetPage(page, filters, sort, reverse, searchField, pageSize)),
 

})
export default connect(mapStateToProps, mapDispatchToProps) (SupplierRoot)