import axios from 'axios';
const instance = axios.create({
	//headers: {
    //    Pragma: 'no-cache'
    //},
    baseURL: env.REACT_APP_BASE_URL
    //baseURL: 'http://localhost/ws/'
    // headers: {
    //      'Authorization':  'Bearer ' + localStorage.getItem("token")
    // }
})

export default instance

