import React, { useState, useEffect, useMemo } from 'react'
import { EntContainer, Table, EntContent, EntDivContainer, TitleDiv, THead, LITableContainer } from './InvoicesPerMonth.styled'
import { TBody, TH, TR, TD, THContainer } from '../../../styles/Table.styled'
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-proas';
import { getNotyfObject } from '../../../shared/utility';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function InvoicesPerMonth() {
    const {t} = useTranslation();
    const notyf = getNotyfObject()
    const monthList = useMemo(() => { var arr=[];
            arr['01']="Janvier"; arr['02']="Février"; arr['03']="Mars"; arr['04']="Avril"; arr['05']="Mai"; arr['06']="Juin";
            arr['07']="Juillet"; arr['08']="Août"; arr['09']="Septembre"; arr['10']="Octobre"; arr['11']="Novembre"; arr['12']="Décembre"; 
            return arr;
        }, [])
    
    const [data, setData] = useState([]);

    useEffect( ()  => {
        Axios.get("/invoice//invoicesPerMonth").then(response => {
            const invoicesCountTemp = []
            response?.data?.map(row => {
                let found = invoicesCountTemp.find((element) => element.month == monthList[row.invoiceMonth.substr(5,2)] + " " + row.invoiceMonth.substr(0,4));
                if (found === undefined ) {
                    invoicesCountTemp.push({month: monthList[row.invoiceMonth.substr(5,2)] + " " + row.invoiceMonth.substr(0,4), paper: 0, email: 0, portal: 0, edi: 0})
                    found = invoicesCountTemp.find((element) => element.month == monthList[row.invoiceMonth.substr(5,2)] + " " + row.invoiceMonth.substr(0,4));
                }
                found[row.channel?.toLowerCase()] = row.invoicesCount;
            })
            setData(invoicesCountTemp);
        }).catch(res => {
            notyf.error("Une erreur s'est produite !");
            console.error(res)
        })
    }, [])

    return (
        <EntDivContainer>
            <TitleDiv>
                <CTooltip title="Ce KPI permet de comptabiliser le nombre de factures reçues par Transparence, dont les post-datées, et hors err01, err99 et err51">
                    <span>Total des factures par date de réception et par canal traitées par Transparence</span>
                </CTooltip>
            </TitleDiv>
            <EntContainer>
                <EntContent>
                    <LITableContainer style={{overflow: "auto", maxHeight: "307px"}}>
                        <Table>
                            <THead>
                                <tr>
                                    <TH scope="col" width={"20%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }}>
                                        <THContainer>
                                            <span>Mois</span>
                                        </THContainer>
                                    </TH>
                                    <TH scope="col" width={"20%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }}>
                                        <THContainer>
                                            <span>Papier</span>
                                        </THContainer>
                                    </TH>
                                    <TH scope="col" width={"20%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }}>
                                        <THContainer>
                                            <span>Email</span>
                                        </THContainer>
                                    </TH>
                                    <TH scope="col" width={"20%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }}>
                                        <THContainer>
                                            <span>Portail</span>
                                        </THContainer>
                                    </TH>
                                    <TH scope="col" width={"20%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }}>
                                        <THContainer>
                                            <span>EDI</span>
                                        </THContainer>
                                    </TH>
                                </tr>
                            </THead>
                            <TBody>
                                {data?.filter(row => row?.paper || row?.email || row?.portal || row?.edi).map(row => {
                                    return <TR key={row.month} style={{cursor: "default"}}>
                                        <TD>{row.month}</TD>
                                        <TD>{row.paper}</TD>
                                        <TD>{row.email}</TD>
                                        <TD>{row.portal}</TD>
                                        <TD>{row.edi}</TD>
                                    </TR>
                                })}
                            </TBody>
                        </Table>
                    </LITableContainer>
                </EntContent>
            </EntContainer>
        </EntDivContainer>
    )
}

export default InvoicesPerMonth
