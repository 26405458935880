import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    quickFilter: null,
    sortQuery: 'creationDate',
    reverse: {
        name: false
    },
    selectedRows: null,
    selectedAllRows: false
}

const nonEngagingStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const nonEngagingSuccess = (state, action) => {
    const selectedRows = state.selectedRows && action.data ? {...state.selectedRows} : null; 
    if (selectedRows)
        for(const [key, value] of Object.entries(selectedRows)) {
            const existing = action.data.find(i => i.id === key);
            if (!existing)
                selectedRows[key] = undefined;
        }

    return updateObject(state, {
        data: action.data,
        count: action.count ? action.count : 0,
        error: null,
        loading: false,
        selectedRows: selectedRows
    })
}

const nonEngagingFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const nonEngagingSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage

})
const nonEngagingSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const nonEngagingSetSortQuery = (state, action) => updateObject(state,{
    sortQuery: action.sortQuery
})

const nonEngagingSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: {...action.reverse}
    }
}

const nonEngagingUpdateShowFilters = (state, action) => {
    return updateObject(state, {showFilters: action.showFilters})
}

const nonEngagingSetFiltersQuery = (state, action) => updateObject(state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)

const nonEngagingSetQuickFilter = (state, action) => updateObject(state, {
    quickFilter : action.quickFilter
})

const nonEngagingSelectRow = (state, action) => {
    let newSelectedRows = {...state.selectedRows, [action.rowId]: action.selected}
    return updateObject(state, {
        selectedRows: newSelectedRows,
        selectedAllRows: !action.selected ? false : state.selectedAllRows
    })
}

const nonEngagingSelectAllRows = (state, action) => {
    let data = state.data;
    let newSelectedRows = {}
    data?.map(row => {
        newSelectedRows = {...newSelectedRows, [row.id]: action.selectedAll}
    })
    return updateObject(state, {
        selectedAllRows: action.selectedAll,
        selectedRows: newSelectedRows
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.NON_ENGAGING_START: return nonEngagingStart(state, action)
        case actionTypes.NON_ENGAGING_SUCCESS: return nonEngagingSuccess(state, action)
        case actionTypes.NON_ENGAGING_FAIL: return nonEngagingFail(state, action)
        case actionTypes.NON_ENGAGING_SET_PAGE: return nonEngagingSetPage(state, action)
        case actionTypes.NON_ENGAGING_SET_PAGESIZE: return nonEngagingSetPageSize(state, action)
        case actionTypes.NON_ENGAGING_SET_FILTERSQUERY: return nonEngagingSetFiltersQuery(state, action)
        case actionTypes.NON_ENGAGING_SET_SORTQUERY: return nonEngagingSetSortQuery(state, action)
        case actionTypes.NON_ENGAGING_SET_REVERSESORT: return nonEngagingSetReverseSort(state, action)
        case actionTypes.NON_ENGAGING_UPDATE_SHOWFILTERS: return nonEngagingUpdateShowFilters(state, action)
        case actionTypes.NON_ENGAGING_SET_QUICKFILTER: return nonEngagingSetQuickFilter(state, action)
        case actionTypes.NON_ENGAGING_SELECT_ROW: return nonEngagingSelectRow(state, action)
        case actionTypes.NON_ENGAGING_SELECT_ALL_ROWS: return nonEngagingSelectAllRows(state, action)
        default:
            return state
    }
}
export default reducer