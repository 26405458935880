import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { TH, THContainer, THead } from '../../../styles/Table.styled';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableHead(props) {
    const {columns, columnClickHandler, reverse, filters, unknownInvoicesArchived, selectedAllRows, setSelectedAllRows} = props;
    const {t} = useTranslation();

    const selectAllRowsHandler = (e) => {
        setSelectedAllRows(!selectedAllRows);
    }
    
    return (
        <THead>
            <tr>
                <TH width="50px" scope="col">
                    <CTooltip title={"Sélectionner toutes les lignes de la page"}>
                        <THContainer style={{justifyContent: "start"}}>
                            <CheckIcon onClick={(e) => selectAllRowsHandler(e)} />
                        </THContainer>
                    </CTooltip>
                </TH>
                {columns.filter(row=>row.show==true).map(column => (
                    <TH key={column.field}
                        className={filters?.[column.field] && 'filtered'}
                        scope="col"
                        width={column?.width} 
                        onClick={() => columnClickHandler(column.field, reverse)}>
                        <CTooltip title={column.title}>
                            <THContainer>
                            <span>{column.title}</span>
                                <div className="columnHead__sort">
                                    <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column?.field] == true ? 'block' : 'none' }} />
                                    <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column?.field] == false ? 'block' : 'none' }}/>
                                </div>
                            </THContainer>    
                        </CTooltip>
                    </TH>
                ))}
                {!unknownInvoicesArchived && <TH width= {"50px"} >
                    <THContainer>
                        <span>Solder </span>
                    </THContainer>
                </TH>}
            </tr>
        </THead>
    )
}

export default TableHead
