import styled from 'styled-components/macro';

export const THead = styled.thead`
background-color: #f00;
height: 32px;
    width: 100%;
    color: white;
    font-size: 0.7rem;
    margin-top:4px ;
  
`;
export const EntDivContainer = styled.div`
    border: 1px solid #D9E1E7CC;
    padding: 10px;
    border-radius: 16px;
    display: flex;
    flex-direction: column; 
    margin-bottom:10px ;
    height: fit-content;
    @media (max-width: ${({theme}) => theme.size.laptop}) {
        width:100%;
    }
`;
export const TitleDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom:15px ;
`;
export const EntContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
    border: 1px solid #D9E1E7CC;
`;