import React from 'react'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';
import { CTooltip } from '../../../UI/CTooltip/CTooltip';

function EntitiesTable({entities, selectEntity, selectedEntities, type, disabled}) {
    return (
        <>
            <table className="table repertoireModal__entities_table">
                <thead className="thead-light">
                    <tr>
                    <th scope="col" style={{width: "50px"}}></th>
                    <th scope="col">Raison sociale </th>
                    <th scope="col">Code </th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Contacts actifs</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {
                        entities?.length > 0 ? entities.map(entity => (
                            <tr key={entity.id}>
                                <td>
                                    <div className="repertoireModal__entities_selectIcon"  onClick={() => {entity.activeUsers>0 && !disabled && selectEntity({uid : entity.uid, name : entity.name, new : true, type})}}>
                                        {
                                            ( selectedEntities.some(row => (row.uid === entity.uid))   ) ? (
                                                <ThumbUpIcon style={entity.activeUsers>0 && !disabled ? {cursor: "pointer", color: "green"}:{cursor: "pointer", color: "#4341418c"}} />
                                            ) : <CTooltip title="Sélection impossible si pas de contact actif"><AddIcon style={entity.activeUsers>0 && !disabled ?{cursor: "pointer", color: "blue"}:{cursor: "pointer", color: "#4341418c"}} /></CTooltip>
                                        }
                                    </div>
                                </td>
                                <td>{entity.name} </td>
                                <td>{entity.code} </td>
                                <td>{entity.address} </td>
                                <td>{entity.activeUsers} </td>
                                
                            </tr>
                        )) : <tr style={{whiteSpace: "nowrap", textAlign: "center"}}>
                                <td colSpan={5} style={{fontSize:"20px"}}>Aucune entreprise à afficher</td>
                            </tr>
                    }
                </tbody>
            </table>
        </>
    )
}

export default EntitiesTable
