import React, { useCallback, useEffect, useState } from 'react';
import './ResetPassword.Styled.js'
import Axios from '../../../axios-proas';
import { Link, useParams } from 'react-router-dom';
import LOGO from '../../../assets/images/transdev_logo.png';
import '../ForgottenPassword/ForgottenPassword.css'
import { useTranslation } from 'react-i18next';
import {RSTBody, RSTitle, FormInput, FormLabel} from './ResetPassword.Styled'
import {FormGroup , BtnOutlineLink  , BtnOutlineAction  } from '../../../styles/Common'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { PasswdRules } from '../../../containers/EditPassword/EditPassword.styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LanguageList from '../../LanguageList/LanguageList.js';
function ResetPassword() {
    let {token} = useParams();
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [alert, setAlert] = useState(null);
    const {t} = useTranslation();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userUid, setUserUid] = useState('');
    const [errors, setErrors] = useState([]);
    const [in10Previous, setIn10Previous] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    useEffect(() => {
        Axios.get('/user/'+token+"/checkExistToken").then(response =>{
            setUserUid(response.data.user)
        }).catch(err => {
            setAlert("authentication:invalidToken");
        })
    }, [token])

    const ResetPassword = (e) => {
        e.preventDefault();
        setError(null)
        if (password === confirmedPassword) {
            editPassword(password);
        } else {
           
          //  setError("authentication:differentPasswords")
        }
    }

    const editPassword = useCallback((newPassword) => {
        Axios.put("/user/"+token+"/password", {
            password : newPassword
        }).then(response => {
            setAlert("authentication:passwordResetSuccess")
        }).catch(err => {
            setError("authentication:passwordResetFail")
        })
    }, [password])

    const inputChangeHandler = (e) => { 
        var passwd = e?.target?.value
      
        let tmpErrors = {}
        if(!passwd.match( /[0-9]/g)) tmpErrors['psswdNumber'] = t("authentication:passwordContainsDigit", "The password must contain a digit")
        if(!passwd.match( /[A-Z]/g)) tmpErrors['psswdUpperCharacter'] = t("authentication:passwordContainsUppercase", "The password must contain a capital letter")
        if(!passwd.match(/[a-z]/g)) tmpErrors['psswdLowerCharacter'] = t("authentication:passwordContainsLowercase", "The password must contain a lower case letter")
        if(!passwd.match( /[^a-zA-Z\d]/g)) tmpErrors['psswdSpecialCharacter'] = t("authentication:passwordContainsSpecial", "The password must contain a special character")
        if(passwd.length < 12) tmpErrors['psswdLength'] = t("authentication:passwordContains12Characters", "The password must contain at least 12 characters")
        
     //   if(lastPassword!=null && (lastPassword.includes(passwd))) tmpErrors['notLikePrevious'] = "Le mot de passe doit être différent du précédent" 
        
        //console.log("lastPassword.includes(passwd):"+lastPassword.includes(passwd))

       if(Object.keys(tmpErrors).length>0) {setConfirmedPassword("")  ;  setIsAuthenticated(false)}
       setErrors(tmpErrors)
    } 
    const changHandler=(e)=> {
        if(e.target.value!=password) {
            setIsAuthenticated(false)
        }else{
            setIsAuthenticated(true)
        }
            setConfirmedPassword(e.target.value)
    }

    const checkPreviousPasswords = password => {
        Axios.get("user/"+userUid+"/checkPreviousPasswords", {params: {hash: btoa(password)}}).then(response => {
            setIn10Previous(!response.data.allowed)
        })
    }

    let alertMessage = (<>
      <PasswdRules className="">
                    <li>
                        {t("authentication:passwordRules", "12 characters minimum among 90 symbols (from 0 to 9, from A to Z, from a to z and ! #$*% ? &[|]@^µ§ :/ ;.,<>°²³)")}
                    </li>     
                    {/* <li>
                        {passwdMaxObligatoire}
                    </li> */}
                    <div>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdNumber")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}} /> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContainsDigit", "The password must contain a digit")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdUpperCharacter")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContainsUppercase", "The password must contain a capital letter")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdLowerCharacter")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContainsLowercase", "The password must contain a lower case letter")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdSpecialCharacter")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContainsSpecial", "The password must contain a special character")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdLength")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContains12Characters", "The password must contain at least 12 characters")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                (!in10Previous && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordIsDifferent", "The password must be different from your 10 previous passwords")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                (isAuthenticated && password != "") ?
                                <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/>  : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordsAreSimilar", "The two passwords must be similar")}
                        </p>
                    </div>

                </PasswdRules>
                </>)     
            

    let content = (<>
        <RSTBody>
            <div className="signin_language_container">
                <LanguageList color="red" />
            </div>
            <Link to="/" className="signin__Icon">
                <img src={LOGO} alt="logo" style={{maxWidth:"200px"}} />
            </Link>
            <RSTitle >{t("authentication:resetPassword")}</RSTitle>
            {
                error && error.length > 0 ? (
                    <RSTitle className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                        <ul> {t(error)}</ul>
                    </RSTitle>
                ) : null
            }


            <FormGroup>   
                <FormLabel htmlFor='user' >{t("authentication:newPassword")}</FormLabel>
                <div>
                    <FormInput id='user' type={showNewPassword ? "text" : "password"} style={{paddingRight: "36px"}} name="password" onBlur={(e) => {checkPreviousPasswords(e.target.value)}} 
                    onChange={(e) => {setPassword(e.target.value); inputChangeHandler(e);}} value={password} onPaste={e => {e.preventDefault(); return false}} required autoFocus />
                    {showNewPassword ? <VisibilityIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowNewPassword(false)}/>
                    : <VisibilityOffIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowNewPassword(true)}/>}
                </div>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor='username'>{t("authentication:confirmPassword")}</FormLabel>
                <div>
                    <FormInput  id="username" type={showConfirmPassword ? "text" : "password"} style={{paddingRight: "36px"}} name="confirmedPassword"
                    onPaste={e => {e.preventDefault(); return false}} onChange={(e) => changHandler(e)} value={confirmedPassword} required/>
                    {showConfirmPassword ? <VisibilityIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowConfirmPassword(false)}/>
                    : <VisibilityOffIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowConfirmPassword(true)}/>}
                </div>
            </FormGroup>
            {/* <span className="signin__error"> {t(error)} </span> */}
            <div>
                {alertMessage}
            </div>
            <BtnOutlineAction   style={{alignSelf:"end"}}
                                color="#000" 
                                onClick={(e) => ResetPassword(e)} 
                                disabled={!password || password !== confirmedPassword || Object.keys(errors).length>0 || !isAuthenticated || in10Previous}
                                    >{t("global:validate")}
            </BtnOutlineAction>
        </RSTBody>
    </>)
    
    if (alert) {
        content = (
        <RSTBody style={{textAlign: "center"}}>
           
            <RSTitle>{t(alert)}</RSTitle>
            <BtnOutlineLink to={"/"}  
                            color="#06152B"
                            border="#FFF">
                                {t("authentication:signIn")}
            </BtnOutlineLink>
        </RSTBody>
        )
    }

    return (
        <div className="forgotPassword__container">
            {content}
        </div>
    )
}

export default ResetPassword
