import React, { useEffect, useState } from 'react';
import Axios from '../../../../../axios-proas'
import {getNotyfObject} from '../../../../../shared/utility';
import { FormContainer,FormDiv,FCmntDiv,BtnsContainer,BtnAction,Label,FormGroup,GroupBtns } from './InvoiceDetail.styled';
import './TraitementForm.css'
import CustomConfirmModal from '../../../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { UIFormStepDoneIcon, UIFormStepNumber } from '../../../../../containers/UploadInvoice/UploadInvoice.styled';
import DoneIcon from '@mui/icons-material/Done';
import { CTooltip } from '../../../../UI/CTooltip/CTooltip';

function TraitementForm({invoiceData, refreshTable, showRVCA, saveCodesHandler, message, waitingForCreditNote, needToSave,setMsg, initialInvoiceData, invoiceNewData, closeModal, userLevel, userProfile}) {
    // const [msg, setMsg] = useState(message)
    const [showEditStatusModal, setShowEditStatusModal] = useState(false);
    const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
    const [showArchiveCodaModal, setShowArchiveCodaModal] = useState(false);
    const [showUnarchiveCodaModal, setShowUnarchiveCodaModal] = useState(false);
    const [showRequalifyCodaModal, setShowRequalifyCodaModal] = useState(false);
    const [data, setData ] = useState({
        number: "",
        issuingDate: "",
        inclusiveTaxAmount: "",
        exclusiveTaxAmount: ""
    });
    const [dataChecked, setDataChecked ] = useState({
        number: false,
        issuingDate: false,
        inclusiveTaxAmount: false,
        exclusiveTaxAmount: false
    });

    const checkBoxChangeHandler = (event) => {
        let name = event?.target?.name
        let checked = event.target.checked
        setDataChecked({...dataChecked, [name]: event.target.checked})
        if(!checked)
            setData({...data})
    }
    
    const notyf = getNotyfObject();

    const canSendToCoda = () => {
        return initialInvoiceData?.number?.trim() && initialInvoiceData?.issuingDate && initialInvoiceData?.clientUid && initialInvoiceData?.supplierUid && initialInvoiceData?.exclusiveTaxAmount && initialInvoiceData?.inclusiveTaxAmount && !["err01", "err09"].includes(initialInvoiceData?.rejectTypes);
    }
    
    // useEffect(() => {
    //     console.log("second useEffect")
    //     let v={...data,issuingDate: formatDate(data.issuingDate)}
    //     let var2=Object.entries(v).filter(item=>dataChecked[item[0]]==true)
    //     let u={
    //         "number": data.number,
    //         "issuingDate": data.issuingDate, 
    //         "inclusiveTaxAmount": data.inclusiveTaxAmount, 
    //         "exclusiveTaxAmount": data.exclusiveTaxAmount
    //     }
    //     let res=var2.map((function(item){
    //         switch (item[0]) {
    //             case "number":
    //                 if(item[1] != "" && item[1]!=null){
    //                     return u[item[0]] = "N° facture : " + item[1]
    //                 }else 
    //                     return u[item[0]] = ""
    //                 break;
    //             case "issuingDate":
    //                 if(item[1] != "" && item[1]!=null){
    //                     return u[item[0]] = "Date facture : " + item[1]
    //                 }else 
    //                     return u[item[0]] = ""
    //                 break;
    //             case "inclusiveTaxAmount":
    //                 if(item[1] != "" && item[1]!=null){
    //                     return u[item[0]] = "Montant TTC : " + item[1]
    //                 }else 
    //                     return [item[0]] = ""
    //                 break;
    //             case "exclusiveTaxAmount":
    //                 if(item[1] != "" && item[1]!=null){
    //                     return u[item[0]] = "Montant HT : " + item[1];
    //                 }else 
    //                     return [item[0]] = "";
    //         }

    //     })
    //     )
    //     let variable = Object.values(res).filter(item => (item[1] != "" && item[1] != null)).join(" / ")
    //     // setMsg(msgHeader + variable)
    // }, [data, dataChecked]);
    
    const editStatusHandler = (status, permanent) => {
        return new Promise((resolve, reject) => {
            if (invoiceData?.rejectUids)
                Axios.put("reject/"+invoiceData?.rejectUids+"/status", {processingStatus: status, message: message, permanent: permanent}).then(response => {
                    if (response.data.success) {
                        if (refreshTable)
                            refreshTable();
                        else
                            window.location.reload(false);
                        notyf.success("Succès de la clôture");
                        closeModal();
                        resolve(true);
                    } else {
                        notyf.error("Erreur lors de la clôture");
                        reject(false);
                    }
                }).catch(() => {
                    notyf.error("Échec de la clôture");
                    reject(false);
                })
            else
                reject("No uid given")
        })
    }
    
    const archiveCodaHandler = () => {
        return new Promise((resolve, reject) => {
            if (invoiceData?.invoiceUid)
                Axios.put("invoice/"+invoiceData?.invoiceUid+"/archiveCoda").then(response => {
                    if (response.data.success) {
                        if (refreshTable)
                            refreshTable();
                        else
                            window.location.reload(false);
                        notyf.success("Succès de l'archivage du refus CODA");
                        closeModal();
                        resolve(true);
                    } else {
                        notyf.error("Erreur lors de l'archivage du refus CODA");
                        reject(false);
                    }
                }).catch(() => {
                    notyf.error("Échec de l'archivage du refus CODA");
                    reject(false);
                })
            else
                reject("No uid given")
        })
    }
    
    const requestUnarchivingCodaHandler = () => {
        return new Promise((resolve, reject) => {
            if (invoiceData?.invoiceUid)
                Axios.put("invoice/"+invoiceData?.invoiceUid+"/requestUnarchivingCoda").then(response => {
                    if (response.data.success) {
                        if (refreshTable)
                            refreshTable();
                        else
                            window.location.reload(false);
                        notyf.success("Succès de la demande de désarchivage du refus CODA");
                        closeModal();
                        resolve(true);
                    } else {
                        notyf.error("Erreur lors de la demande de désarchivage du refus CODA");
                        reject(false);
                    }
                }).catch(() => {
                    notyf.error("Échec de la demande de désarchivage du refus CODA");
                    reject(false);
                })
            else
                reject("No uid given")
        })
    }
    
    const requalifyCodaHandler = () => {
        return new Promise((resolve, reject) => {
            if (invoiceData?.invoiceUid && invoiceData?.rejectTypes)
                Axios.put("invoice/"+invoiceData?.invoiceUid+"/requalifyCoda", invoiceData?.rejectTypes).then(response => {
                    if (response.data.success) {
                        if (refreshTable)
                            refreshTable();
                        else
                            window.location.reload(false);
                        notyf.success("Succès de la requalification du refus CODA");
                        closeModal();
                        resolve(true);
                    } else {
                        notyf.error("Erreur lors de la requalification du refus CODA");
                        reject(false);
                    }
                }).catch(() => {
                    notyf.error("Échec de la requalification du refus CODA");
                    reject(false);
                })
            else
                reject("Missing data")
        })
    }

    // const handleRvca = () => {
    //     let comments = (invoiceData?.codeSupplier ? (invoiceData?.codeSupplier + " / ") : "") + (invoiceData?.trsCode ? (invoiceData?.trsCode + " / ") : "") + "CONTROLE OK";
    //     if (msg)
    //         comments += "\n" + msg;
    //     Axios.put('reject/'+invoiceData?.rejectUids+'/rvca', comments).then(response => {
    //         window.open(invoiceData?.alfrescoLink || '', '_blank', 'noopener,noreferrer');
    //     }).then(() => {
    //         notyf.error("Échec");
    //     })
    // }

    return (
        <FormContainer>
            <FormDiv>
                {showRVCA && !+invoiceData?.codaRefused && <>
                <FCmntDiv>
                    <div>
                        <Label style={{borderBottom:"1px solid #1e1e1e",width: "fit-content",marginBottom:"7px"}}>Commentaire final :</Label>
                            <TextareaAutosize
                             
                                minRows={4}
                                placeholder="Commentaire final"
                                style={{ width: "100%",color:"#FF0000",fontSize: "0.7rem"}}
                                value={message}
                                onChange={(e) => setMsg(e.target.value)}
                                />
                        {/*{
                            data?.map((item,index)=>(
                                    <FormLabel color={"#FF0000"} style={{fontSize:"0.7rem"}}>{item.defaultMsg}</FormLabel>
                                    
                                ))
                            
                        } */}
                        {/* <Label color={"#FF0000"} style={{fontSize:"0.7rem"}}>{invoiceData?.codeSupplier && (invoiceData?.codeSupplier + " / ")}{invoiceData?.trsCode && (invoiceData?.trsCode + " / ")}CONTROLE OK</Label> */}
                        {/* <Label color={"#FF0000"} style={{fontSize:"0.7rem"}}>{message}</Label> */}
                    </div>
                </FCmntDiv></>}
                <BtnsContainer>
                    {/*showRVCA && <BtnAction // plus de RVCA
                        bg={"#ff0000"}
                        color={"#ff0000"}
                        disabled={!invoiceData?.alfrescoLink}
                        title={invoiceData?.alfrescoLink ? "Renvoyer la facture en vidéocodage" : "La facture ne comporte pas de lien GED"}
                        onClick={() => handleRvca()}
                        >
                        RVCA
                    </BtnAction>*/}
                    <GroupBtns>
                        {saveCodesHandler && !+invoiceData?.codaRefused && <>
                            {needToSave ? 
                                <CTooltip title="Veuillez sauvegarder le formulaire avant de clôturer le rejet">
                                    <UIFormStepNumber style={{minWidth: "38px", minHeight: "38px", fontSize: "1.3rem"}}>1</UIFormStepNumber>
                                </CTooltip>
                                : 
                                <CTooltip title="Rien à sauvegarder">
                                    <UIFormStepDoneIcon style={{minWidth: "38px", minHeight: "38px", fontSize: "1.8em"}}><DoneIcon /></UIFormStepDoneIcon>
                                </CTooltip>}
                            <BtnAction
                                bg={"#ff0000"}
                                color={"#ff0000"}
                                minHeight="fit-content"
                                onClick={() => setShowSaveConfirmModal(true)}
                                style={{width: "100%"}}
                                >
                                {`Sauvegarder les actions`}
                            </BtnAction>
                        </>}
                        {+invoiceData?.codaRefused && !+invoiceData?.codaRefusedArchived ? 
                        <BtnAction
                            bg={"#ff0000"}
                            color={"#ff0000"}
                            minHeight="fit-content"
                            onClick={() => setShowRequalifyCodaModal(true)}
                            disabled={!invoiceNewData?.rejectTypes}
                            >
                            {`Requalifier en rejet`}
                        </BtnAction> : null}
                    </GroupBtns>

                    <GroupBtns>
                        {!["err90", "err91", "err92", "err93", "err94", "err99", "err51"].includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused ?
                        <>
                            <CTooltip title={needToSave ? "Veuillez sauvegarder le formulaire avant de clôturer le rejet" : "Vous pouvez clôturer le rejet"}>
                                <UIFormStepNumber style={{minWidth: "38px", minHeight: "38px", fontSize: "1.3rem", backgroundColor: needToSave ? "grey" : ""}}>2</UIFormStepNumber>
                            </CTooltip>
                            <BtnAction 
                                bg={"#ff0000"}
                                color={"#ff0000"}
                                height="fit-content"
                                onClick={() => setShowEditStatusModal({permanent: 1})}
                                disabled={waitingForCreditNote || needToSave}
                                >Clôturer le rejet sans envoi CODA
                            </BtnAction>
                        </> : (+invoiceData?.codaRefused ?
                        <><BtnAction
                            bg={"#ff0000"}
                            color={"#ff0000"}
                            onClick={() => {
                                if (["err90", "err91", "err92", "err93", "err94", "err99", "err51"].includes(initialInvoiceData?.rejectTypes))
                                    setShowEditStatusModal({permanent: 1})
                                else if (+invoiceData?.codaRefused)
                                    setShowArchiveCodaModal(true)}}
                            disabled={+invoiceData?.codaRefusedArchived}
                            >{+invoiceData?.codaRefusedArchived ? "Refus CODA archivé" : "Archiver"}
                        </BtnAction>
                        {+invoiceData?.codaRefusedArchived && userProfile === "REJECT_MANAGER" && userLevel === "admin" ? <BtnAction 
                            bg={"#ff0000"}
                            color={"#ff0000"}
                            onClick={() => setShowUnarchiveCodaModal(true)}
                            >Désarchiver</BtnAction> : null}
                        </> : null)}
                        {!["err90", "err91", "err92", "err93", "err94", "err99", "err51"].includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <BtnAction
                            bg={"#ff0000"}
                            color={"#ff0000"}
                            height="fit-content"
                            onClick={() => setShowEditStatusModal({permanent: 0})}
                            disabled={waitingForCreditNote || !canSendToCoda() || needToSave}
                            >
                            Clôturer le rejet avec envoi CODA
                        </BtnAction>}
                    </GroupBtns>
                    {/* <BtnActionlink >
                    Facture GED
                    </BtnActionlink> */}
                </BtnsContainer>
            </FormDiv>
            <CustomConfirmModal 
                show={showEditStatusModal}
                handleClose={() => setShowEditStatusModal(false)}
                text={`Êtes-vous sûr de vouloir clôturer ce rejet ? Il sera considéré comme traité et ${showEditStatusModal?.permanent ? "ne sera pas" : "sera" } envoyé à CODA.`}
                okCallback={() => editStatusHandler("TREATED", showEditStatusModal?.permanent)}/>
            <CustomConfirmModal 
                show={showSaveConfirmModal}
                handleClose={() => setShowSaveConfirmModal(false)}
                text={`Êtes-vous sûr de vouloir sauvegarder les données modifiées dans le formulaire ?`}
                okCallback={saveCodesHandler}/>
            <CustomConfirmModal 
                show={showArchiveCodaModal}
                handleClose={() => setShowArchiveCodaModal(false)}
                text={`Êtes-vous sûr de vouloir archiver ce refus d'entrée CODA ?`}
                okCallback={archiveCodaHandler}/>
            <CustomConfirmModal 
                show={showUnarchiveCodaModal}
                handleClose={() => setShowUnarchiveCodaModal(false)}
                text={`Êtes-vous sûr de vouloir désarchiver ce refus d'entrée CODA ? Votre demande devra être approuvée par un administrateur portail`}
                okCallback={requestUnarchivingCodaHandler}/>
            <CustomConfirmModal 
                show={showRequalifyCodaModal}
                handleClose={() => setShowRequalifyCodaModal(false)}
                text={`Êtes-vous sûr de vouloir requalifier ce refus d'entrée CODA en rejet ?`}
                okCallback={requalifyCodaHandler}/>
        </FormContainer>
    )
}
export default TraitementForm
