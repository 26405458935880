import React, {useCallback} from 'react'
import Axios from '../../../axios-proas';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TD, TR } from '../../../styles/Table.styled';
import { SupplierLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableBody(props) {
    const {clientsData, userRole,setEntitydetail,entityOwnerDetail,columns,userLevel} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleBoxChecked = (event, row) => {
        Axios.put('/clientbusiness/'+row.uid+'/receivesAttachment').then(response => {
            event.target.checked = response.data;
            row.receivesAttachment = event.target.checked;
        }).catch(error => {
            console.error(error);
        })
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])


    const getTokenVisualisation = (uid) => {
        Axios.get('/clientbusiness/'+uid+'/userTokenVisualisation')
            .then(response => {
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                //window.location.reload(false);
                //Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('loginVisualisation', response.data.login)
                navigate("/userVisualisation")
            })
    }
    
    const getDematType =(client) => {
        var dematExpression = new Array()
        if(client?.edi == 1) dematExpression.push('EDI'); 
        if(client?.chorus == 1) dematExpression.push('CHORUS');
        if(client?.paper == 1) dematExpression.push('PAPER');
        if(client?.sendMail == 1) dematExpression.push('EMAIL');
        if(client?.accessPortal == 1) dematExpression.push('Accès portail'); 
        return dematExpression
    }
    

    return (
        <>
            {
                clientsData ? clientsData.map((row,index) => (
                    <TR key={row.beaId || row.id} onClick={() => navigate("/entity/"+row?.uid+"?type=owner")}>
                        <CTooltip title={row.code || ""}><TD style={{display: !columns.filter(column => column?.field=="code")[0]?.show == true && 'none'}}>{row.code}</TD></CTooltip>
                        <CTooltip title={(row.name + (row.secondaryName ? " (" + row.secondaryName + ")" : "")) || ""}><TD style={{display: !columns.filter(column => column?.field=="name")[0]?.show == true && 'none'}}>{row.name + (row.secondaryName ? " (" + row.secondaryName + ")" : "")}</TD></CTooltip>
                        <CTooltip title={row.zipCode || ""}><TD style={{display: !columns.filter(column => column?.field=="zipCode")[0]?.show == true && 'none'}}>{row.zipCode}</TD></CTooltip>
                        <CTooltip title={row.city || ""}><TD style={{display: !columns.filter(column => column?.field=="city")[0]?.show == true && 'none'}}>{row.city}</TD></CTooltip>
                        <CTooltip title={getDematType(row).join(', ') || ""}><TD style={{display: !columns.filter(column => column?.field=="active")[0]?.show == true && 'none'}}>{ getDematType(row).join(', ') }</TD></CTooltip>
                        {/* <CTooltip title={formatDate(+row.creationDate) || ""}><TD style={{display: !columns.filter(column => column?.field=="creationDate")[0]?.show == true && 'none'}}>{ formatDate(+row.creationDate) }</TD></CTooltip> */}
                        <CTooltip title={formatDate(+row.startP2P) || ""}><TD style={{display: !columns.filter(column => column?.field=="startP2P")[0]?.show == true && 'none'}}>{formatDate(+row.startP2P) || ""}</TD></CTooltip>
                        <CTooltip title={row.startP2PAccounting || ""}><TD style={{display: !columns.filter(column => column?.field=="startP2PAccounting")[0]?.show == true && 'none'}}>{row.startP2PAccounting || ""}</TD></CTooltip>
                        <CTooltip title={row.endP2P || ""}><TD style={{display: !columns.filter(column => column?.field=="endP2P")[0]?.show == true && 'none'}}>{row.endP2P || ""}</TD></CTooltip>
                        <CTooltip title={row.tupEntity || ""}><TD style={{display: !columns.filter(column => column?.field=="tupEntity")[0]?.show == true && 'none'}}>{row.tupEntity || ""}</TD></CTooltip>
                        <CTooltip title={formatDate(+row.tupStart) || ""}><TD style={{display: !columns.filter(column => column?.field=="tupStart")[0]?.show == true && 'none'}}>{formatDate(+row.tupStart) || ""}</TD></CTooltip>
                        <CTooltip title={formatDate(+row.tupEnd) || ""}><TD style={{display: !columns.filter(column => column?.field=="tupEnd")[0]?.show == true && 'none'}}>{formatDate(+row.tupEnd) || ""}</TD></CTooltip>
                        <CTooltip title={row.fusionEntity || ""}><TD style={{display: !columns.filter(column => column?.field=="fusionEntity")[0]?.show == true && 'none'}}>{row.fusionEntity || ""}</TD></CTooltip>
                        <CTooltip title={formatDate(+row.fusionStart) || ""}><TD style={{display: !columns.filter(column => column?.field=="fusionStart")[0]?.show == true && 'none'}}>{formatDate(+row.fusionStart) || ""}</TD></CTooltip>
                    </TR>
                )) : null
            }
        </>
    )
}

export default TableBody
