import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, TD } from '../../../styles/Table.styled';
import {formatDate, showColumn} from '../../../shared/utility'
import DateFilter from '../../UI/DateFilter/DateFilter';

function TableFilters(props) {
    const {showFilters, filters, filtersChange, applyFilters, columns} = props;
    const [showDateModal, setShowDateModal] = useState(null)
    const [dateField, setDateField] = useState('')
    const {t} = useTranslation();

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        if (e.target.value?.length >= 3 || e.target.tagName !== "INPUT")
            applyFilters(newFilters);
        else if (e.target.value?.length < 3 && filters[key]?.length >= 3) {
            newFilters[key] = "";
            applyFilters(newFilters);
        }
    }

    const closeModalHandler = () => {
        setShowDateModal(false);
    }
    const dateShowHandler = (field) => {
        setShowDateModal({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    let [dateInputValue,setDateInputValue] = useState({
        thresholdDate: filters?.thresholdDate ? convertDate(filters.thresholdDate) : '',
        sentDate: filters?.sentDate ? convertDate(filters.sentDate) : ''
    });

    const getThresholdDate = () => {
        return filters?.thresholdDate ? convertDate(filters.thresholdDate) : [];
    }

    const getSentDate = () => {
        return filters?.sentDate ? convertDate(filters.sentDate) : [];
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    return (
        <>
            <tr style={{display: showFilters ? 'table-row' : 'none',
                position: "sticky",
                top: "38px",
                backgroundColor: "white",
                zIndex: "99"}}>
                <TD></TD>
                <TD style={{display: !showColumn(columns, "suCode") && 'none'}}></TD>
                <TD style={{display: !showColumn(columns, "supplierCode") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)}
                        placeholder="Code tiers"
                        value={filters?.supplierCode || ''} 
                        name="supplierCode" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "contacts") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)}
                        placeholder="Contacts"
                        value={filters?.contacts || ''} 
                        name="contacts" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "thresholdDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle="Date limite de la circularisation"
                            from={getThresholdDate()[0] || ''}
                            to={getThresholdDate()[1] || ''}
                            name="thresholdDate"
                            activeReset={filters?.thresholdDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={showDateModal?.thresholdDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "sentDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle="Date d'envoi du dernier mail"
                            from={getSentDate()[0] || ''}
                            to={getSentDate()[1] || ''}
                            name="sentDate"
                            activeReset={filters?.sentDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={showDateModal?.sentDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "statutoryAuditorEmail") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.statutoryAuditorEmail || ''} 
                            placeholder="Email commissaire aux comptes"
                            name="statutoryAuditorEmail" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD></TD>
            </tr>
        </>
    )
}

export default TableFilters
