import React, { useState, useEffect, useRef } from 'react'
import { EntContainer, Table, EntContent, EntDivContainer, TitleDiv, THead, LITableContainer } from './RejectsPerManager.styled'
import { TBody, TH, TR, TD, THContainer, SpinnerContainer } from '../../../styles/Table.styled'
import Axios from '../../../axios-proas';
import { getErrorLabel, formatDate, getTimeDate } from '../../../shared/utility';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip'
import { Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

function RejectsPerManager() {
    const [data, setData] = useState([]);
    const [dataCopy, setDataCopy] = useState([]);
    const [columnSort, setColumnSort] = useState("ratio");
    const [reverse, setReverse] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const csvBtn = useRef(null);

    const loadData = () => {
        setLoading(true);
        Axios.get("reject//rejectsAndManagers").then(response => {
            setData(response.data);
            setDataCopy(response.data);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (data) {
            const dataTemp = [...data];
            const upperQuery = searchQuery.toUpperCase();
            dataTemp.sort((d1, d2) => compare(d1[columnSort], d2[columnSort], reverse));
            setDataCopy(dataTemp.filter(d => d.rejectType.toUpperCase().includes(upperQuery) || d.lastEventManager.toUpperCase().includes(upperQuery)));
        }
    }, [searchQuery])

    const compare = (d1, d2, reverse) => {
        if (!reverse && d1 < d2 || reverse && d1 > d2)
            return -1;
        if (!reverse && d1 > d2 || reverse && d1 < d2)
            return 1;
        return 0;
    }

    useEffect(() => {
        const dataTemp = [...dataCopy];
        dataTemp.sort((d1, d2) => compare(d1[columnSort], d2[columnSort], reverse));
        setDataCopy(dataTemp);
    }, [columnSort, reverse])

    const sort = column => {
        if (columnSort === column)
            setReverse(!reverse);
        else {
            setReverse(false);
            setColumnSort(column);
        }
    }

    return (
        <EntDivContainer>
            <TitleDiv>
                <span>{"Rejets par responsable (à ce jour)"}</span>
            </TitleDiv>
            {loading ? <SpinnerContainer>
                <Spinner animation="border" variant="danger"/>
            </SpinnerContainer>:
            (data.length === 0 ? 
                <Button variant="danger" onClick={() => loadData()}>Afficher</Button>
                :
                <>
                    <input type="text" className="form-control" placeholder="Rechercher" onChange={e => setSearchQuery(e.target.value)} value={searchQuery}/>
                    <EntContainer>
                        <EntContent>
                            <LITableContainer>
                                <Table>
                                    <THead>
                                        <tr>
                                            <TH scope="col" width={"16%"} onClick={() => sort("rejectType")}>
                                                <THContainer>
                                                    <span>Type rejet</span>
                                                    {"rejectType" === columnSort ? 
                                                        (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                        null
                                                    }
                                                </THContainer>
                                            </TH>
                                            <TH scope="col" width={"28%"} onClick={() => sort("firstEventDate")}>
                                                <THContainer>
                                                    <span>Date prise en charge</span>
                                                    {"firstEventDate" === columnSort ? 
                                                        (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                        null
                                                    }
                                                </THContainer>
                                            </TH>
                                            <TH scope="col" width={"28%"} onClick={() => sort("lastEventDate")}>
                                                <THContainer>
                                                    <span>Date dernière action</span>
                                                    {"lastEventDate" === columnSort ? 
                                                        (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                        null
                                                    }
                                                </THContainer>
                                            </TH>
                                            <TH scope="col" width={"28%"} onClick={() => sort("lastEventManager")}>
                                                <THContainer>
                                                    <span>Responsable dernière action</span>
                                                    {"lastEventManager" === columnSort ? 
                                                        (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                        null
                                                    }
                                                </THContainer>
                                            </TH>
                                        </tr>
                                    </THead>
                                    <TBody>
                                        {dataCopy?.map(row => {
                                            return <TR key={row.id} style={{cursor: "default"}}>
                                                <TD><CTooltip title={getErrorLabel(row?.rejectType) || ""}><span>{row.rejectType}</span></CTooltip></TD>
                                                <TD><CTooltip title={formatDate(+row.firstEventDate) + " " + getTimeDate(+row.firstEventDate) || ""}><span>{formatDate(+row.firstEventDate) + " " + getTimeDate(+row.firstEventDate)}</span></CTooltip></TD>
                                                <TD><CTooltip title={formatDate(+row.lastEventDate) + " " + getTimeDate(+row.lastEventDate) || ""}><span>{formatDate(+row.lastEventDate) + " " + getTimeDate(+row.lastEventDate)}</span></CTooltip></TD>
                                                <TD><CTooltip title={row.lastEventManager || ""}><span>{row.lastEventManager}</span></CTooltip></TD>
                                            </TR>
                                        })}
                                    </TBody>
                                </Table>
                            </LITableContainer>
                        </EntContent>
                        {dataCopy ? (<CSVLink
                            ref={csvBtn}
                            data={dataCopy?.map(d => ({...d, 
                                                        firstEventDate: formatDate(+d.firstEventDate) + " " + getTimeDate(+d.firstEventDate),
                                                        lastEventDate: formatDate(+d.lastEventDate) + " " + getTimeDate(+d.lastEventDate)
                                                    }))}
                            filename={"Rejets par responsable.csv"}
                            headers={[
                                {label: 'Type rejet', key: 'rejectType'},
                                {label: 'Date prise en charge', key: 'firstEventDate'},
                                {label: 'Date dernière action', key: 'lastEventDate'},
                                {label: 'Responsable dernière action', key: 'lastEventManager'}
                            ]}
                            separator=";">
                        </CSVLink>) : null}
                        <div style={{width:"100%",display: "flex", justifyContent: "center", padding: "11px"}}>
                            <button type="button" style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} className="btn btn-danger" onClick={() => csvBtn.current.link.click()}>Export Excel</button>
                        </div>
                    </EntContainer>
                </>)
            }
        </EntDivContainer>
    )
}

export default RejectsPerManager
