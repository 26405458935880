import React, { useEffect, useMemo, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Axios from '../../../axios-proas';
import { getNotyfObject } from '../../../shared/utility';
import { IPSContainer, IPSTitle } from './InvoicesPerStatus.styled';
import HelpIcon from '@mui/icons-material/Help';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { useTranslation } from 'react-i18next';

function InvoicesPerStatus({period, userType, startDate, endDate, entityFilter }) {
    const {t} = useTranslation();
    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])
    const [dataColors, setDataColors] = useState([])
    const theme = useTheme();
    const notyf = getNotyfObject()
    const navigate = useNavigate();

    useEffect ( () =>{
        Axios.get("/invoice//supplierInvoicesPerStatus", {
            params: {
                period : period,
                endDate : endDate ,
                startDate : startDate,
                entityFilter : entityFilter
            },
        }).then((response) => {
            const invoiceData = response?.data
            let labelsTemp = [];
            let dataTemp = [];
            let colorsTemp = []
            if(invoiceData){
                invoiceData?.map(d => {
                    labelsTemp = [...labelsTemp, d.status]
                    dataTemp = [...dataTemp, d.currentInvoices]
                    colorsTemp = [...colorsTemp, theme.status.text[d.aifeCode]]
                })
                setLabels(labelsTemp)
                setData(dataTemp)
                setDataColors(colorsTemp)
            }
        }).catch(res => {
            notyf.error(t("global:errorOccurred", "An error has occurred"));
        })  
    }, [period, startDate, endDate])

   
    const chartData ={
        labels: labels.map(l => t("statuses:" + l, l)),
        datasets: [
            {    
                backgroundColor: dataColors,
                data: data
            }
            ,{
                backgroundColor:["yellow"]
            }
        ],
        series:[{
            data: data
        }]
        // These labels appear in the legend and in the tooltips when hovering different arcs
        
    }

    const pieChartClickEvent = (event, array) => {
        if(array[0]) {
            const index = array[0]._index;
            navigate("/invoices", { replace : false, state : {statusFilter: labels[index]} });
        }
    }

    const options = useMemo(() => ({
        maintainAspectRatio: false,
        onClick: pieChartClickEvent,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: false,
            text: t("home:invoicesPerStatus", "Invoices per status"),
            fontSize: 25,
            fontColor: "#566573",
            padding:30
        },
        legend: {
            position: "right",
            onClick: null,
            labels: {
                fontColor: '#566573',
                fontSize: 15,
                padding: 10
            }
        },
        tooltips: {
            backgroundColor: "#5a6e7f"
        },
        hover: {
            onHover: function(e) {
                var point = this.getElementAtEvent(e);
                if (point.length)
                    e.target.style.cursor = 'pointer';
                else 
                    e.target.style.cursor = 'default';
           }
        }
    }), [labels]) 

  return (
    <IPSContainer isSupplier={userType === 'supplier'}>
        <IPSTitle><span>{t("home:invoicesPerStatus", "Invoices per status")} <CTooltip title={t("home:invoicesPerStatusTooltip", "The different statuses' descriptions are explained in the FAQ")}><HelpIcon/></CTooltip></span></IPSTitle>
        <Pie data={chartData} options={options} datasetKeyProvider={() => btoa(Math.random()).substring(0,12)} />
    </IPSContainer>
  )
}

export default InvoicesPerStatus