import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import {formatDate, getTimeDate, getLabelWithKey,getErrorLabel} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';


function StatusEvent({eventData, isLast, statuses}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()
    const status = statuses?.find(s => s.id == eventData?.snapshot);

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    const getStatusColor = () => {
        switch(status?.aifeCode.toString()) {
            case '206':
            case '208':
                return theme.colors.warning;
            case '207':
            case '210':
            case '213':
                return theme.colors.danger;
            default:
                return theme.colors.success;
        }
    }

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={true} bdColor={getStatusColor()} color={getStatusColor()} >
                        <CheckIcon />
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={getStatusColor()}>
                            {t("invoiceList:status", "Status")} {t("statuses:"+status?.label, status?.label)}
                        </TETitle>
                        <TETime>
                            {eventData?.user ? (eventData?.user?.first_name || eventData?.user?.last_name ? (eventData?.user?.first_name || "") + " " + (eventData?.user?.last_name || "").trim() : eventData?.user?.login) + "\r\n" : (eventData?.noUser ? t("invoiceDetails:" + getLabelWithKey(eventData?.noUser)) + "\r\n" : null)}
                            {formatDate(+eventData?.creationDate)} {t("global:at", "at")} {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    showContent && (
                        <TEContent>
                            {
                                eventData?.comment && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("invoiceDetails:comment", "Comment:")}
                                        </MessageFrom>
                                        {eventData?.snapshot != "14" ? <MessageContent>
                                            {eventData?.comment?.slice(0, 128)}
                                            {eventData?.comment?.length > 128 ? '...' : null}
                                        </MessageContent> :
                                        <MessageContent>
                                            {eventData?.comment?.split("\r\n").map(c => c?.startsWith("err") ? c + " - " + t("reject:"+c, getErrorLabel(c)) : c).join("\r\n")}
                                        </MessageContent>}
                                    </MessageWrapper>
                                )
                            }
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default StatusEvent