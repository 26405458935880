import styled from 'styled-components/macro';

export const TLContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
`

export const AllContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content:space-between ;

  
`

export const CloseDiv = styled.div`
 
display: flex;
 margin-top:0.75rem ;

  
`
export const TEContainer = styled.div`
    width: 93%;
    display: flex;
    flex-direction: column;
    margin-bottom : 2rem;
`

export const TEHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
`;

export const TEPreview = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

export const TETitle = styled.h6`
    font-size: 1rem;
    margin: 0;
    padding: 0;
    color: ${({color, theme}) => color || theme.colors.secondary};
`

export const TEIcon = styled.div`
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: ${({borderBold}) => borderBold ? "3px" : "1px"} solid ${({bdColor, theme}) => bdColor || theme.colors.secondary};
    color: ${({color, theme}) => color || theme.colors.secondary};
    margin-right: 1rem;
`

export const TETime = styled.h6`
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    color: ${({theme}) => theme.colors.secondary};
`

export const TEContent = styled.div`
    padding-left: 4rem;
`

export const MessageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 2px solid ${({theme}) => theme.colors.orangeLight};
    padding-left: 0.25rem;
    padding-bottom: 0.5rem;
`

export const MessageFrom = styled.h6`
    font-size: 0.8rem;
    margin : 0;
    padding: 0;
    color: ${({theme}) => theme.colors.secondary};
`

export const MessageContent = styled.p`
    font-size: 0.8rem;
    margin : 0;
    padding: 0;
    width: 100%;
    word-break: break-all;
    white-space: pre-wrap; 

`
