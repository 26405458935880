import React, { useState, useEffect } from 'react'
import { EntContainer, Table, EntContent, Coselect, EntDivContainer, TitleDiv, THead, LITableContainer } from './RejectsResolutionKPI.styled'
import { TBody, TH, TR, TD, THContainer } from '../../../styles/Table.styled'
import Axios from '../../../axios-proas';
import { formatDate } from '../../../shared/utility';
import ResolutionDetailModal from './ResolutionDetailModal/ResolutionDetailModal';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function RejectsResolutionKPI() {
    const [data, setData] = useState([]);
    const [dataCopy, setDataCopy] = useState([]);
    const [columnSort, setColumnSort] = useState(null);
    const [reverse, setReverse] = useState(false);
    const [referential, setReferential] = useState("rejectsResolutionPerType");
    const [showLineDetails, setShowLineDetails] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const referentials = [{value: "rejectsResolutionPerType", label: "Par type d'erreur"}, {value: "rejectsResolutionPerSupplier", label: "Par fournisseur"}];

    useEffect(() => {
        Axios.get("reject//"+referential).then(response => {
            setData(response.data);
            setDataCopy(response.data);
            setColumnSort(referential === "rejectsResolutionPerSupplier" ? "supplierCode" : "errorType");
            setReverse(false);
            setSearchQuery("");
        })
    }, [referential])

    useEffect(() => {
        if (data) {
            const dataTemp = [...data];
            const upperQuery = searchQuery.toUpperCase();
            dataTemp.sort((d1, d2) => compare(d1[columnSort], d2[columnSort], reverse));
            setDataCopy(dataTemp.filter(d => d[referential === "rejectsResolutionPerSupplier" ? "supplierCode" : "errorType"].toUpperCase().includes(upperQuery)));
        }
    }, [searchQuery])

    const compare = (d1, d2, reverse) => {
        if (!reverse && d1 < d2 || reverse && d1 > d2)
            return -1;
        if (!reverse && d1 > d2 || reverse && d1 < d2)
            return 1;
        return 0;
    }

    useEffect(() => {
        const dataTemp = [...dataCopy];
        dataTemp.sort((d1, d2) => compare(d1[columnSort], d2[columnSort], reverse));
        setDataCopy(dataTemp);
    }, [columnSort, reverse])

    const colourStyles = {
        container: styles => ({ ...styles, display: 'flex', justifyContent: 'end' }),
        control: styles => ({ ...styles, backgroundColor: 'white', width: '250px' }),
        option: (styles) => {
            return {
                ...styles,
                fontSize:"0.8rem"
            };
        },
    };

    const sort = column => {
        if (columnSort === column)
            setReverse(!reverse);
        else {
            setReverse(false);
            setColumnSort(column);
        }
    }

    return (
        <EntDivContainer>
            <TitleDiv>
                <span>
                    <CTooltip title={<div><p>Règle de calcul :</p>
                        <ul>
                            <li>1 jour correspond à 24h entre l'arrivée du rejet sur Transparence et sa résolution</li>
                            <li>la durée de résolution moyenne correspond à la moyenne de toutes les durées de résolution par type d'erreur/fournisseur</li>
                        </ul></div>}>
                        <span>{"Durée de résolution des rejets - "}</span>
                    </CTooltip>
                    <CTooltip title="Calcul effectué une fois par mois, le premier jour du mois">
                        <span>{"Date de calcul : "+ formatDate(data?.[0]?.updateDate ? +data?.[0]?.updateDate : new Date().getTime())}</span>
                    </CTooltip>
                </span>
                <Coselect
                    name="mailType"
                    onChange={(e) => setReferential(e.value)}
                    placeholder={"Choisir"}
                    noOptionsMessage={() => "Aucun résultat"}
                    value={referentials.find(item => item.value === referential)}
                    styles={colourStyles}
                    options={referentials}
                />
            </TitleDiv>
            {referential === "rejectsResolutionPerSupplier" && <input type="text" className="form-control" placeholder="Rechercher" onChange={e => setSearchQuery(e.target.value)} value={searchQuery}/>}
            <EntContainer>
                <EntContent>
                    <LITableContainer>
                        <Table>
                            <THead>
                                <tr>
                                    <TH scope="col" width={"30%"} onClick={() => sort(referential === "rejectsResolutionPerSupplier" ? "supplierCode" : "errorType")}>
                                        <THContainer>
                                            <span>{referential === "rejectsResolutionPerSupplier" ? "Code fournisseur" : "Type de rejet"}</span>
                                            {(referential === "rejectsResolutionPerSupplier" ? "supplierCode" : "errorType") === columnSort ? 
                                                (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                null
                                            }
                                        </THContainer>
                                    </TH>
                                    <TH scope="col" width={"70%"} onClick={() => sort("resolutionTime")}>
                                        <THContainer>
                                            <span>Durée de résolution moyenne (jours)</span>
                                            {"resolutionTime" === columnSort ? 
                                                (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                null
                                            }
                                        </THContainer>
                                    </TH>
                                </tr>
                            </THead>
                            <TBody>
                                {dataCopy?.map(row => {
                                    const key = referential === "rejectsResolutionPerSupplier" ? row.supplierCode : row.errorType;
                                    return <TR key={key} onClick={() => setShowLineDetails(key)}>
                                        <TD>{key}</TD>
                                        <TD>{Math.round(row.resolutionTime)}</TD>
                                    </TR>
                                })}
                            </TBody>
                        </Table>
                    </LITableContainer>
                </EntContent>
            </EntContainer>
            <ResolutionDetailModal show={showLineDetails} handleClose={() => setShowLineDetails(false)} referential={referential} />
        </EntDivContainer>
    )
}

export default RejectsResolutionKPI
