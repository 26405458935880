import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';   
import EmailPreview from "../../EmailPreview/EmailPreview"

function EmailPreviewModal({ show, handleClose, convertedContent,event}) {      

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton> 
                <Modal.Title>Apercu</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <EmailPreview 
                        convertedContent={convertedContent}  
                        event={event} /> 
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    Fermer
                </Button>
                
                </Modal.Footer>
            </Modal>
        </>
    )
} 
 
export default  EmailPreviewModal
