import React, { useCallback, useRef, useState } from 'react'
import Axios from '../../../axios-proas';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CSVLink } from "react-csv";
import { useTranslation } from 'react-i18next';
import { getNotyfObject, getStatusWithKey,formatChannel,LastActionAuthorformatType, formatType} from '../../../shared/utility';
import { TableAction, TAContainer } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import Export from '../../UI/Export/Export';
import CustomConfirmModal from '../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal';
import ReplayIcon from '@mui/icons-material/Replay';

function TableActions(props) {
    const {showFilters, filterState, selectedRows, formatDate, filters, sortQuery, reverse, refreshTable,page,resetData, getCompressedDoc} = props;
    const {t} = useTranslation();
    const [csvData, setCsvData] = useState();
    const [showEditStatusModal, setShowEditStatusModal] = useState(false);
    const csvBtn = useRef();
    let notyf = getNotyfObject();

    const csvHeaders = [
        page !== "codaRefused" && {label: t("invoiceList:typeEchec"), key: "rejectType"},
        page !== "codaRefused" && {label: t("invoiceList:typeEchecOrigine"), key: "originalRejectType"},
        {label: t("invoiceList:dateArchibald"), key: "archibaldDate"},  
        {label: t("invoiceList:dateLastAction"), key: "lastActionDate"},
        {label: t("invoiceList:action"), key: "lastActionAuthor"},
        {label: t("invoiceList:TypeDepot"), key: "channel"},  

        {label: t("invoiceList:CodeSU"), key: "suCode"},
        {label: t("invoiceList:entité"), key: "ownerName"},
        {label: t("invoiceList:codeTiers"), key: "supplierCode"},
        {label: t("invoiceList:supplier"), key: "supplierName"},
     
        {label: t("invoiceList:number"), key: "number"},
        {label: "Type", key: 'documentType'},
        {label: "Montant TTC", key: 'inclusiveTaxAmount'},
        {label: "Montant HT", key: 'exclusiveTaxAmount'},
        {label: t("invoiceList:CDEnumber"), key: "orderNumber"},
        {label: t("invoiceList:issuingDate"), key: 'issuingDate'},
        page === "codaRefused" && { label: "Description de l'erreur", key: 'refusalDescription' },
        {label: t("invoiceList:compostageNumber"), key: "compostingNumber"},
        {label: t("invoiceList:Commentaire"), key: "unreadMessages"},
        {label: t("invoiceList:dateCODA"), key: "dueDate"},
        {label: t("invoiceList:ivmStatus"), key: "ivmStatus"}, 
        {label: t("invoiceList:chronoCode"), key: "chronoCode"},
     
        // {label: t("invoiceList:status"), key: "statusLabel"},
        // {label: t("invoiceList:dateChangementStatut"), key: "statusDate"},
      
        // {label: t("invoiceList:region"), key: "region"},
        // {label: t("invoiceList:exclusiveAmount"), key: "exclusiveTaxAmount"},
        // {label: t("invoiceList:inclusiveAmount"), key: "inclusiveTaxAmount"},
        // {label: t("invoiceList:currency"), key: "currency"},
        // {label: t("invoiceList:codeDocument"), key: "documentCode"},   
        // {label: t("invoiceList:numberFactureInterne"), key: "internalNumber"},
    ];

    const exportSelectionZip = (selectedRows) => {
        let selectedIds = [];
        for (const key in selectedRows) {
            
            if (selectedRows[key])
                selectedIds.push(key) 
        }
        getCompressedDoc(selectedIds, filters)
    }
    const exportCsv = useCallback((selectedRows, filters) => {
        const selectedIds = [];
        for (const key in selectedRows)
            if (selectedRows[key])
                selectedIds.push(key)
        
        const newFilters = {};
        if(filters)
            for (const key in filters)
                if(filters[key] != undefined && filters[key] != null)
                    newFilters[key] = filters[key];
        
        if(sortQuery)
            newFilters.orderBy = sortQuery;
        
        if(reverse)
            newFilters.reverse = reverse[sortQuery];
        
        Axios.get('/reject//csvCompressedDocument' + (selectedIds.length > 0 ? '?selection='+selectedIds : ""), {params: newFilters})
        .then((response) => {
            const csvDataTemp = response.data.result.map(row => {
                    return {
                        ...row,
                        issuingDate: formatDate(+row.issuingDate),
                        archibaldDate: formatDate(+row.archibaldDate),
                        lastActionDate: formatDate(+row.lastActionDate),
                        status : getStatusWithKey(row?.status),
                        dueDate: formatDate(+row.dueDate),
                        channel:formatChannel(row.channel),
                        readStatus: row.firstAccessDate !== null ? t("global:yes") : t("global:no"),
                        lastActionAuthor:LastActionAuthorformatType(row.lastActionAuthor),
                        documentType: formatType(row.documentType),
                        unreadMessages: row.unreadMessages ? "Oui" : "Non",
                    } 
                })
                setCsvData(csvDataTemp);
               csvBtn.current.link.click();
        }).catch(err => notyf.error("Une erreur s'est produite"))
    },[selectedRows, filters])
    
    const createEReporting = useCallback((selectedRows) => {
        const selectedIds = [];
        for (const key in selectedRows)
            if (selectedRows[key])
                selectedIds.push(key)
        Axios.post('/ereporting/',{
            type: 'SENT',
            selectedIds
        })
        .then((response) => {
            let success = response?.data?.hasOwnProperty('success') ? response.data.success : false;
            if(success)
                notyf.success(t("ereporting:success", "E-Reporting créé avec succès"));
            else
                notyf.error(t("ereporting:error", "Une erreur s'est produite"));
        }).catch(err => notyf.error("Une erreur s'est produite"))
    },[selectedRows])

    const hasSelection = (selectedRows) => {
        for (const key in selectedRows) {
            if (selectedRows[key])
                return true
        }
        return false
    }

    const hasSelectionErr01 = (selectedRows) => {
        for (const key in selectedRows) {
            if (selectedRows[key] === "err01")
                return true
        }
        return false
    }

    const archiveRejects01 = () => {
        return new Promise((resolve, reject) => {
            const selectedErr01 = {};
            for (const key in selectedRows)
                if (selectedRows[key] === "err01")
                    selectedErr01[key] = "err01";
            Axios.put("reject//status", { rejects: selectedErr01, processingStatus: "TREATED", permanent: 1}).then(response => {
                if (response.data.success) {
                    refreshTable();
                    notyf.success("Succès de l'archivage des rejets err01");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors de l'archivage des rejets err01");
                    reject(false);
                }
            }).catch(error => {
                notyf.error("Échec de l'archivage des rejets err01");
                reject(false);
            })
        })
    }

    return (
        <TAContainer>
            <TableAction title="Réinitialiser les filtres" onClick={() => resetData()}>
                {/* <span>{t("global:Rest")} </span> */}
                <ReplayIcon />
            </TableAction>
            <TableAction active={filterState} onClick={() => showFilters()}>
                <span>{t("global:filters")} </span>
                <FilterListIcon />
            </TableAction>
            <Export
                exportPdf={() => exportSelectionZip(selectedRows)}
                exportCsv={() => exportCsv(selectedRows, filters)}
                hasSelection={hasSelection(selectedRows)}
            />
            {hasSelectionErr01(selectedRows) && ["new", "pending"].includes(page) && <TableAction onClick={() => setShowEditStatusModal(true)}>
                <span>Archiver les rejets err01 sélectionnés</span>
            </TableAction>}
            {csvData ? (<CSVLink
                ref={csvBtn}
                data={csvData}
                filename={"documentsCsv - " +new Date().toISOString().replace(":", "")+ ".csv"}
                headers={csvHeaders.filter(h => h)}
                separator={";"}>
            </CSVLink>) : null}

            <CustomConfirmModal 
                show={showEditStatusModal}
                handleClose={() => setShowEditStatusModal(false)}
                text={selectedRows && Object.keys(selectedRows)?.length > 1 ? 
                    "Êtes-vous sûr de vouloir archiver les rejets err01 sélectionnés ? Ils seront considérés comme traités et ne seront pas envoyés à CODA."
                :
                    "Êtes-vous sûr de vouloir archiver le rejet err01 sélectionné ? Il sera considéré comme traité et ne sera pas envoyé à CODA."}
                okCallback={() => archiveRejects01()}/>
        </TAContainer>
    )
}

export default TableActions
