import React, { useCallback, useState } from 'react';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TodayIcon from '@mui/icons-material/Today';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { IPQContainer, IPQInitialFilter, IPQInitialFilters } from './InvoicesPeriodQuery.styled';
import DateRange from '../../components/UI/DateRange/DateRange';
import { useNavigate } from 'react-router-dom';

function InvoicesPeriodQuery() {
    const [showDateRange, setShowDateRange] = useState(false);
    const navigate = useNavigate();

    const dateChangeHandler = (date, dateType) => {
        navigate("/fournisseurInvoices", {state: {
            [dateType]: date.startDate.getTime() + ':' + date.endDate.getTime(),
            invoiceTrackingByDate : dateType
        }})
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    return (
        <IPQContainer>
            <TitleHelmet title={"Transdev | Suivi des factures"} />
            <IPQInitialFilters>
                <IPQInitialFilter onClick={() => setShowDateRange("issuingDate")}>
                    <CalendarTodayIcon />
                    <p>Date de Facture</p>
                </IPQInitialFilter>
                <IPQInitialFilter onClick={() => setShowDateRange("creationDate")}>
                    <TodayIcon />
                    <p>Sur la date d'acquisition</p>
                </IPQInitialFilter>
                <IPQInitialFilter onClick={() => navigate("/fournisseurInvoices", {state: {nada: "nada1"}})}>
                    <AllInclusiveIcon />
                    <p>Toutes les factures</p>
                </IPQInitialFilter>
            </IPQInitialFilters>
            {showDateRange && <DateRange
                title={"Période"}
                show={showDateRange} 
                format={formatDate} 
                clicked={() => setShowDateRange(false)} 
                changed={date => dateChangeHandler(date, showDateRange)} 
                field={showDateRange}  
            />}
        </IPQContainer>
    )
}
export default InvoicesPeriodQuery
