
import styled from 'styled-components/macro';

export const AppContainer = styled.div`
    min-height: 100vh;
    width: 100%;
   background-color:white;
    display:flex;
    font-family: Verdana;
`;

export const AppBody = styled.div`
  display: flex;
    width: 100%;
    height: 100vh;
    overflow: auto;
`;
export const AppWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    /* overflow:${({overflowShow}) => overflowShow ? "hidden" : 'auto'}; */
    overflow: auto;
   
   // padding: 0 1rem 1rem 1rem;
`;

export const AppContent = styled.div`
    /* width: calc(100% - 330px); */
    width: 100%;
    height: fit-content;
    /* overflow: auto; */
    /* margin: 0 1rem 1rem 0; */
    /* padding: 2rem 2rem; */
    padding:10px;
    background-color: white;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* margin:0px 18px 8px 18px; */
    border-radius: 5px;
    color: ${({theme}) => theme.colors.text};

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        /* width:calc(100% - 240px); */
    }
`
