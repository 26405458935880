import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Axios from '../../axios-proas';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import { checkValidity, formatDate, getNotyfObject,verificationData,validatDocs } from '../../shared/utility';
import EntitiesComponent from '../../components/UI/EntitiesComponent/EntitiesComponent';
import UsersManagement from '../UsersManagement/UsersManagement';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import Download from '../../components/Download/Download';
import { connect } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { EntContainer, EntContent, EntHeader, EntHeaderAction, EntHeaderActions, EntHeaderTitle, EntHeaderWrapper,NavTabsSpan, NavTabsLi, NavTabsLink, NavTabsUl} from './Entity.styled'
import { useTheme } from 'styled-components';
import Infos from '../../components/Entity/Infos/Infos';
import { BtnAction, BtnOutlineAction } from '../../styles/Common';
import Documents from '../../components/Entity/Documents/Documents';
import TableActions from '../../components/UsersManagTable/TableActions/TableActions';
import { TableActionLink } from '../../styles/Table.styled';
import axios from 'axios';
import {SpinnerContainer } from '../../styles/Table.styled';
import { Spinner } from 'react-bootstrap';
// import SuspendedDocsModal from '../../components/Entity/Infos/SuspendedDocsModal/SuspendedDocsModal';
function Entity({userType, userLevel}) {
    const theme = useTheme()
    let {uid} = useParams();
    const navigate = useNavigate();
    const notyf = getNotyfObject();
    const {t} = useTranslation();
   let location = useLocation(); 
   const [errors, setErrors] = useState(null);
    const [entity, setEntity] = useState({
        countryCode : "fr",
        family : "ADMINISTRATION",
        lang: "fr",
        type: location?.state?.entityType ? location?.state?.entityType : "owner",
    });
    const [navTabsList, setNavTabsList] = useState([
        {label: 'Informations Administratives', value: 'infos'},
        {label: 'Contacts', value: 'contacts'},
        // {label: 'Documents Juridiques', value: 'documents'},
    ])
    const canalList = useMemo(() => ([
        {value: 'EMAIL', label: t("signin:canalEmail", "Email")},
        {value: 'CHORUS', label: t("signin:chorus", "Chorus")},
        {value: 'PORTAL', label: t("signin:accessPortal", "Portail")},
        {value: 'EDI', label: t("signin:edi", "EDI")}
    ]), [t]);
    
    const supplierCanalList = useMemo(() => ([
        {value: 'PORTAL', label: t("signin:accessPortal", "Portail")},
        {value: 'EMAIL', label: t("signin:canalEmail", "Email")},
        {value: 'PAPER', label: t("signin:edi", "Papier")},
        {value: 'EDI', label: t("signin:edi", "EDI")},
        {value: 'PDP', label: t("signin:pdp", "PDP")},
        {value: 'PPF', label: t("signin:pdp", "PPF")}
    ]), [t]);

    const [tab, setTab] = useState(localStorage.getItem("supplierActiveTab") ? localStorage.getItem("supplierActiveTab") : "infos");
    const[validate,setValidate]=useState(false)
    const[checked,setChecked]=useState(false)
    const[valideSirenError,setValideSirenError]=useState(false)
    const[docTypes,setDocTypes] =useState([
        {label: 'KBIS', value: 'KBIS', alert:false, valide:false,attent:false},
        {label: 'RIB', value: 'IBAN', alert:false, valide:false,attent:false},
        {label: 'Attestation URSSAF', value: 'URSSAF', alert:false, valide:false,attent:false},
        {label: 'Attestation d assurance', value: 'INSURANCE', alert:false, valide:false,attent:false},
        {label: 'Travailleurs étrangers', value: 'FOREIGN', alert:false, valide:false,attent:false},
    ])
    const typeUser = localStorage.getItem( "type" );
    const[update,setUpdate]=useState(false)
    // const[showSpinner,setShowSpinner]=useState(false)
    const[suspendedDocs, setSuspendedDocs] = useState([]);
    const [showSuspendedDocsModal, setShowSuspendedDocsModal] = useState(false);

    const checkValidInfo=()=>{  
        setShowSpinner(true)
        setTimeout(function(){ 
            let token="5bee708d-0add-378a-ac81-47b6ee0b73de";
            let siren="441136785"
            axios.get("https://api.insee.fr/entreprises/sirene/V3/siren?q=siren:"+siren, {headers: {'Authorization': 'Bearer '+token, 'Accept': 'application/json'}}).then(response => {
                console.log(response)
            // verificationData(response.data,entity);
                checked_siren();
                setChecked(true)
                setValideSirenError(false)
                setShowSpinner(false)
            }).catch(error => {
                setValideSirenError(true)
                setShowSpinner(false)
        });
        
        },5000);
    
    }
   
    const updateStatus=()=>{
        let params={
            "user_uid":entity?.admins[0]?.uid,
            "entity_id":entity?.id
            }
        Axios.put('/entity/'+entity?.uid+'/validate_entity', params).then(response => {
            setValidate(true)
            notyf.success("validation terminé");
            navigate("/referentielFournisseur/")
        }).catch(err => {
            console.error(err);
        })
    }
    const checked_siren=()=>{
        Axios.put('/entity/'+entity?.uid+'/checked_siren', entity).then(response => {
            notyf.success(t("siren Verifié"));
        }).catch(err => {
            console.error(err);
        })
    }
    //jai fait ca car je veux pas rafraicher useEffect avec la dependence update
    useEffect(() => { 
        return () => {
            setTab("infos")
           localStorage.removeItem("supplierActiveTab");
            localStorage.removeItem("show");
        }
    },[uid,validate,checked])

    useEffect(() => {
       const tabProps = location.state?.tab;
       if(tabProps) {
           setTab(tabProps)
       }
       const entityUid = uid;
       
        if (entityUid){
            Axios.get('/entity/'+entityUid+'/object').then((response) => {
                setDocTypes(validatDocs(docTypes, response?.data?.entity?.documents));
                if(response?.data?.entity?.type === "supplier"){
                    if(response?.data?.entity?.status=="INPROGRESS"){
                        setNavTabsList([
                            {label: 'Informations Administratives', value: 'infos'}
                           
                        ])
                    }else{
                        setNavTabsList([
                            {label: 'Informations Administratives', value: 'infos'},
                            {label: 'Contacts', value: 'contacts'},
                            // {label: 'Documents Juridiques', value: 'documents'}
                        ])
                   }
                } else {
                    setNavTabsList([
                        {label: 'Informations Administratives', value: 'infos'},
                        {label: 'Contacts', value: 'contacts'}
                    ])
                }
                setEntity(response.data.entity);
                setUpdate(false)
            }).catch(err => {
                notyf.error("Une erreur s'est produite!");
            })

        }else{
            setNavTabsList([
                {label: 'Informations Administratives', value: 'infos'}
            ])
        }
         let locale=localStorage.getItem("show")
         if(locale=="document"){
             changeTab("documents")
         }

        // return () => {
        //     setTab("infos")
        //     localStorage.removeItem("supplierActiveTab");
        //     let local=localStorage.getItem("show")
        //     if(local=="document"){
        //         changeTab("documents")
        //         localStorage.removeItem("show");
        //         localStorage.removeItem("supplierActiveTab");
        //     }else{
        //         localStorage.removeItem("show");
                
        //     }
           
        // }
            
    },[uid,validate,checked,update])


    const changeTab = tab => {
        if(tab!=="documents"){localStorage.removeItem("show");}
        setTab(tab);
        localStorage.setItem("supplierActiveTab", tab);
    }

    const handleInputChange = e => {
        const key = e.target.name;
        var value = e.target.value;
        if( key == "actif"){
            e.target.checked ? value = 1 : value = 0;
        }
        const newSupplier = {...entity, [key]: value};
        setEntity(newSupplier);
    }

    const canalChangeHandler = (value) => {
        let entityTmp = {
            ...entity,
            channel : value
        };
        setEntity(entityTmp);
    }

    const supplierCanalChangeHandler = (field, value) => {
        let entityTmp = {...entity};
        supplierCanalList.forEach(canal => {
            entityTmp[canal.field] = canal.field === field ? value : 0;
        });
        setEntity(entityTmp);
    }

    const checkUserFormValidity = (entity) => {
        let errors = [];
        if (
          !entity.hasOwnProperty("name") ||
          !checkValidity(entity?.name, { required: true })
        ) {
          errors.push(
            t(
              "masterData:error_name",
              "Le champ 'name' est vide ou non valide"
            )
          );
        }
        if (
          !entity.hasOwnProperty("siren") ||
          !checkValidity(entity?.siren, { required: true })
        ) {
          errors.push(
            t(
              "masterData:error_siren",
              "Le champ 'siren' est vide ou non valide"
            )
          );
        }
        if (
          !entity.hasOwnProperty("siret") ||
          !checkValidity(entity?.siret, { required: true})
        ) {
          errors.push(
            t(
              "masterData:error_siret",
              "Le champ 'siret' est vide ou non valide"
            )
          );
        }
        if (
          !entity.hasOwnProperty("vat") ||
          !checkValidity(entity?.vat, { required: true})
        ) {
          errors.push(
            t(
              "masterData:errVat",
              "Le champ 'vat' est vide ou non valide"
            )
          );
        }
        return errors;
      };

    const addEntityclickHandler = () => {
        let checkErrors = checkUserFormValidity(entity);
        if (checkErrors && checkErrors.length > 0) {
          setErrors(checkErrors);
        } else {
          setErrors(null);
        const entityToAdd = {
            ...entity
        }
        Axios.post('entity//entity', entityToAdd).then(response => {
            if (response.data.hasOwnProperty('success')) {
                notyf.success(t("default:entityAdded", "L'entité a bien été ajoutée"))
                setEntity({
                    countryCode : "fr",
                    family : "ADMINISTRATION",
                    lang: "fr"
                });
                navigate(-1);
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }
    } 

    const editEntityclickHandler = () => {
        Axios.put(`entity/${entity?.uid}/entity`, entity).then(response => {
            if (response?.data.hasOwnProperty('success')) {
                notyf.success(t("default:userAdded", "L'entité a bien été modifiée"))
            }
            if (response?.data.hasOwnProperty('suspendedDocs')) {
                let suspendedDocsTmp = response?.data?.suspendedDocs;
                setSuspendedDocs(suspendedDocsTmp);
                if (suspendedDocsTmp.length > 0) 
                    setShowSuspendedDocsModal(true);
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }


    const saveClickHandler = () => {
        if(entity?.id)
            editEntityclickHandler();
        else
            addEntityclickHandler();
    } 

    const deleteOwnerclickHandler = (owner) => {
        let entityTemp = {...entity}
        if(entity?.id && !owner?.new ){
            entityTemp = {
                ...entityTemp,
                ownersToDelete : entityTemp?.ownersToDelete ? [...entityTemp?.ownersToDelete, owner.uid] : [owner.uid]
            }
        }
        let entityOwners = [...entity?.owners]
        let ownerIndex = entityOwners.findIndex(ent => ent.uid === owner.uid);
        if(ownerIndex >=0 ){
            entityOwners.splice(ownerIndex, 1)
        }
        setEntity({
            ...entityTemp,
            owners: entityOwners
        })
    }

    const ownersChangeHandler = (ownersList) => {
        let entityTemp = {
            ...entity,
            owners : ownersList
        }
        setEntity(entityTemp)
    }

    const setDownloadDate = document => {
        const newEntity = {...entity};
        const newDocuments = newEntity.documents;
        const doc = newDocuments.find(d => document.uid === d.uid);
        doc.downloadDate = new Date().getTime();
        setEntity(newEntity);
    }
    const clickCondition=()=>{
        if (typeUser=="owner")
            return (entity?.status === "INPROGRESS"&& parseInt(entity?.checked) === 0 ) ?
                        "Verifier" 
                    : (entity?.status === "INPROGRESS" && parseInt(entity?.checked) === 1) 
                        ? "Valider" 
                    :"Enregistrer"
        else
            return "Enregistrer"
      }
      
    const clickConditionForCallFunction=()=>{
       if (typeUser=="owner")
         return (entity?.status === "INPROGRESS" && parseInt(entity?.checked) === 0) ? 
                    checkValidInfo() 
                : (entity?.status === "INPROGRESS" && parseInt(entity?.checked) ===1) ? 
                    updateStatus() 
                :
                    saveClickHandler()
       else 
         return saveClickHandler() 
    }

    // let tSpinner = showSpinner ? (<>
    //     <SpinnerContainer style={{display: "flex",flexDirection: "column"}}>
    //         <Spinner animation="border" variant="primary"/>
    //        <p>verification..</p>
    //         </SpinnerContainer>
    //          </>
    //     ) : null;
    let jSXcontent = null;
    let jsxActions = null;

    switch (tab) {
        case "contacts":
            jsxActions = (<TableActions 
                entityUid={uid}
                userType={userType}
                entityFilter={entity?.type}
            />)
            jSXcontent = (
                <UsersManagement entityFilter={entity?.type} notShowAction={true}/>  
            )
            break;
        case "documents":
            jsxActions = (
                <TableActionLink 
                    color={theme.colors.primary} 
                    to={"/uploadDoc/"+uid}
                >
                    <span>{t('tableAction:add',"Ajouter" )}</span>
                    <AddIcon />
                </TableActionLink>
            )
            jSXcontent = (
                docTypes &&
                <Documents 
                    setUpdate={setUpdate}
                    documents={entity?.documents}
                    type={userType}
                    Admin={entity?.admins}
                    docTypes={docTypes}
                    setDownloadDate={setDownloadDate}
                    
                />
            )
            break;
    
        default:
            // if (entity?.type !== "supplier" && userLevel === "admin")   
            
          
            jSXcontent = (
                <div>
                    {errors && errors.length > 0 ? (
                        <div className="alert alert-danger mt-2" role="alert">
                            <ul>
                                {errors.map((error) => (
                                     <li key={error}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                <Infos 
                    entity={entity} 
                    entities={entity?.owners}
                    handleInputChange={handleInputChange}
                    deleteEntity={deleteOwnerclickHandler} 
                    addEntity={ownersChangeHandler}
                    entitiesType={"owner"}
                    canalChangeHandler={canalChangeHandler}
                    canalList={canalList}
                    supplierCanalList={supplierCanalList}
                    supplierCanalChangeHandler={supplierCanalChangeHandler}
                    entityUid={uid}
                />
                </div>
            )
            break;
    }

    return (
        <EntContainer>
            <TitleHelmet title={"Transdev | " + t(entity?.type === "client" ? "clientList:client" : "supplier:supplier")} />
            <EntHeaderWrapper>
                <EntHeader>
                    <EntHeaderTitle>
                        Fiche {entity?.type === "client" ? "Client" : entity?.type === "supplier" ? "Fournisseur" : "Entité"} : 
                        <span style={{marginLeft: "0.5rem"}}>{ entity?.name || '' } {entity?.checked==1&& entity?.status=="INPROGRESS" &&  <CheckCircleIcon color="success"></CheckCircleIcon>}</span>
                    </EntHeaderTitle>
                    <EntHeaderActions>
                        {
                            jsxActions
                        }
                    </EntHeaderActions>
                </EntHeader>

                <NavTabsUl>
                    {
                        navTabsList.map((t, index) => (
                    <NavTabsLi>
                        <NavTabsSpan 
                                    active={tab === t.value} 
                                    onClick={() => changeTab(t.value)}
                                    // to={`#`}
                        > 
                                    {t.label}
                        </NavTabsSpan>
                    </NavTabsLi>
                        ))
                    }
                </NavTabsUl>
            </EntHeaderWrapper>
                {
                    valideSirenError ? (
                        <div className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                            <ul>
                                {
                                    <li >Numero de siren n'est pas valide</li>
                                }
                            </ul>
                        </div>
                    ) : null
                }
            <EntContent>
                {jSXcontent}
                {/* {
                    showSuspendedDocsModal && (
                        <SuspendedDocsModal
                            show={showSuspendedDocsModal}
                            closeModal={() => setShowSuspendedDocsModal(false)}
                            documents={suspendedDocs}
                        />
                    )
                } */}
            </EntContent>
        </EntContainer>
    )
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    userLevel : state.auth.userLevel,
    login : state.auth.login
  })
  const mapDispatchToProps = dispatch => ({
  })
  export default connect(mapStateToProps, mapDispatchToProps) (Entity)