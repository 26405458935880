import React, { useCallback, useEffect, useState } from 'react';
import TableActions from '../../components/Regroupement/TableActions/TableActions';
import TableHead from '../../components/Regroupement/TableHead/TableHead';
import TableBody from '../../components/Regroupement/TableBody/TableBody';
import TableFilters from '../../components/Regroupement/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import { EMBody, EMContainer, EMTableContainer } from './EntitiesManager.styled';
import { Spinner } from 'react-bootstrap';
import Axios from '../../axios-proas'
import { filter } from 'lodash';
import CustomConfirmModal from '../FAQ/CustomConfirmModal/CustomConfirmModal';
import { getNotyfObject } from '../../shared/utility';

function EntitiesManager(props) {
    const {
        loading,
        regroupementData,
        entitiesData,
        count,
        currentPage,
        pageSize,
        getEntitiesPage,
        getRegroupementPage,
        setEntitiesPage,
        setEntitiesPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userRole,
        updateShowFilters,
        entityOwnerDetail,
        setEntitydetail,
        setEntitiesFilters,
        setSortQuery,
        setReverse,
        userUid,
        userLevel,
        userType
    } = props;
    const { t } = useTranslation();
    const [showInformationModal, setShowInformationModal] = useState(false);
    const navTitre = "grouping";
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const notyf = getNotyfObject();

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{navTitre:navTitre}})
            .then(response => {
                let tempColumns = response?.data?.columns
                if (tempColumns === null)
                    tempColumns = {creationDate: 1, title: 1, entities: 1};
                let columnsCopy = [
                    { title: t("entitiesList:creationDate"), field: 'creationDate', show: tempColumns.creationDate, width: "10%" },
                    { title: "Nom", field: 'title', show: tempColumns.title, width: "20%" },
                    { title: "Entités", field: 'entities', show: tempColumns.entities }
                ]
                setColumns(columnsCopy)
                columnsCopy.filter( column => column?.show == 0 ).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns])

    useEffect(() => {
        localStorage.setItem("origin", "owner")
        getRegroupementPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
        getEntitiesPage(1,null,null,null,100)
    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);


    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getRegroupementPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setEntitiesPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getRegroupementPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const handleRefresh = useCallback(() => {
        getRegroupementPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const resetFilters = () => {
        setEntitiesFilters(null);
        setEntitiesPage(1)
        getRegroupementPage(1, null, sortQuery, reverse, pageSize)
    }

    const handleDeleteGroup = () => {
        return new Promise((resolve, reject) => {
            Axios.delete("regroupement/"+showDeleteConfirmModal?.id).then(response => {
                if (response.data?.success) {
                    handleRefresh();
                    notyf.success("Succès de la suppression");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors de la suppression");
                    reject(false);
                }
            }).catch(() => {
                notyf.error("Échec de la suppression");
                reject(false);
            })
        })
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>Aucun regroupement à afficher</span>
        </NoContent>
    );

    if (!loading && regroupementData?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            userLevel={userLevel}
            columns={columns}
            regroupementData={regroupementData}
            userRole={userRole}
            setEntitydetail={setEntitydetail}
            entityOwnerDetail={entityOwnerDetail}
            setShowDeleteConfirmModal={setShowDeleteConfirmModal}
        />)
    }
const showRegroupment=()=>{
    setShowInformationModal(true)
}
    return (
        <EMContainer>
            <TitleHelmet title={"Transdev | " + t("menu:entities")} />
            <Header>
                <HeaderActions>
                    <TableActions
                        showRegroupment={showRegroupment}
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        userLevel={userLevel}
                        userType={userType}
                        entitiesData={entitiesData}
                    />
                </HeaderActions>
            </Header>
            <EMBody>

 
         <TablePagination setDensity={setDensity} columns={columns.filter(column => column!==false)} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setEntitiesPageSize}
                    />
                <EMTableContainer>
                   
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                            entitiesFilters={filtersQuery}
                        />
                        <TBody height={density}>
                            <TableFilters
                              userLevel={userLevel}
                                columns={columns}
                                showFilters={showFilters}
                                entitiesFilters={filtersQuery}
                                filtersChange={setEntitiesFilters}
                                applyFilters={handleApplyFiltering}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                
                </EMTableContainer>
            </EMBody>
            <CustomConfirmModal 
                show={showDeleteConfirmModal}
                handleClose={() => setShowDeleteConfirmModal(false)}
                text={`Êtes-vous sûr de vouloir supprimer le regroupement d'entités sélectionné ?`}
                okCallback={handleDeleteGroup}
            />
        </EMContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.regroupement.loading,
    regroupementData: state.regroupement.data,
    entitiesData: state.entities.data,
    count: state.regroupement.count,
    currentPage: state.regroupement.currentPage,
    pageSize: state.regroupement.pageSize,
    sortQuery: state.regroupement.sortQuery,
    reverse: state.regroupement.reverse,
    filtersQuery: state.regroupement.filtersQuery,
    showFilters: state.regroupement.showFilters,
    userRole: state.auth.role,
    entityOwnerDetail: state.clients.entity,
    userUid: state.auth.userUid,
    userLevel: state.auth.userLevel,
    userType : state.auth.userType,

})
const mapDispatchToProps = dispatch => ({
    getEntitiesPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.entitiesGetPage(page, null, null, null, 100)),
    getRegroupementPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.regroupementGetPage(page, filters, sort, reverse, pageSize)),
    setEntitiesPage: (page) => dispatch(actions.regroupementSetPage(page)),
    setEntitiesPageSize: (pageSize) => dispatch(actions.regroupementSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.regroupementSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.regroupementSetReverseSort(reverseS)),
    setEntitiesFilters: (filters) => dispatch(actions.regroupementSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.regroupementUpdateShowFilters(show)),
    setEntitydetail: (entity) => dispatch(actions.setEntitydetail(entity)),
})
export default connect(mapStateToProps, mapDispatchToProps)(EntitiesManager)
