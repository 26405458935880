import React, { useState, useEffect } from 'react'
import Axios from '../../axios-proas';
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import { useTranslation } from 'react-i18next';
import { checkValidity, getNotyfObject,getNotyfObject10s } from '../../shared/utility';
import { useNavigate, useLocation , useParams} from 'react-router-dom';
import { BtnOutlineAction, FormGroup, FormInput, FormLabel, Header, HeaderActions } from '../../styles/Common';
import { useTheme } from 'styled-components';
import { TelInput, UFBody, UFContainer,ToggleIcon, UFProfileContainerBody } from './UserForm.styled';
import { EIFormCol, EIFormContainer } from '../Entity/Entity.styled';
import EntitiesComponent from '../../components/UI/EntitiesComponent/EntitiesComponent';
import RegionComponent from '../../components/UI/EntitiesComponent/RegionComponent';
import MandatoryMarker from '../../components/UI/MandatoryMarker';

import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { connect } from 'react-redux';
import GroupComponent from '../../components/UI/GroupComponent/GroupComponent';
import Spinner from '../../components/UI/Spinner/Spinner';
import { CTooltip } from '../../components/UI/CTooltip/CTooltip';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';

function UserForm({userType, userProfile, userLevel, connectedUid, connectedEntity}) {
    const [user, setUser] = useState({
        type: userType,
        level: "standard"
    }) 

    const [profile, setProfile] = useState({
        ADMIN: 0,
        ACCOUNTING_standard: userType === "owner" && userProfile === "ACCOUNTING" ? 1 : 0,
        BUYER_standard: userType === "owner" && userProfile === "BUYER" ? 1 : 0,
        REJECT_MANAGER_standard : userType === "owner" && userProfile === "REJECT_MANAGER" ? 1 : 0,
        ACCOUNTING_admin: 0,
        BUYER_standard_admin: 0,
        REJECT_MANAGER_admin: 0,
        SUPPLIER_standard: userType === "supplier" ? 1 : 0,
        SUPPLIER_admin : 0
    });
    const [errors, setErrors] = useState(null);
    const [existingEmail, setExistingEmail] = useState(false);
    const [regions, setRegions] = useState([]);
    const [regionsCopie, setRegionsCopie] = useState([]);
    const [groupsCopie, setGroupsCopie] = useState([]);
    const [saving, setSaving] = useState(false);
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    let notyf10s = getNotyfObject10s();
    let location = useLocation();
    let params = useParams()
    const [origin, setOrigin] = useState();  
    const navigate = useNavigate();
    const theme = useTheme()
const [entitiesCopie, setEntitiesCopie] = useState([]);
    useEffect(() => {
        Axios.get('/region').then(response => {
            setRegions(response.data[0])
        }).catch(err => {
            console.log(err);
        })
    } ,[])

    useEffect(() => {
        setOrigin(localStorage.getItem("origin"))
        if(location?.state?.entityFilter)
            setUser({...user, type: location?.state?.entityFilter});
        if(params?.uid){
            const userUid = params?.uid;
            Axios.get('/user/'+userUid+'/user').then((response) => {
                setUser(response.data?.user);
                setProfile(response.data.user.profile)
                setEntitiesCopie(response.data.user.entities)
                setRegionsCopie(response.data.user.region)
                setGroupsCopie(response.data.user.groups)
            }).catch(err => {
                console.log(err);
            })
        } else if(location?.state?.entityUid || userType === 'supplier' && user?.type === "supplier"){
            const entityUid = location?.state?.entityUid ? location?.state?.entityUid : connectedEntity;
            Axios.get('/entity/'+entityUid+'/object').then((response) => {
                let newUser = {}
                if(response.data?.entity?.type=='owner')
                    newUser.entities = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid, new: true }]
                else if(response.data?.entity?.type=='supplier')
                    newUser.suppliers = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid, new: true }]
                else if(response.data?.entity?.type=='client')
                    newUser.clients = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid, new: true }]
                newUser.type = response.data?.entity?.type
                newUser.level = 'standard'
                setUser(newUser)
            }).catch(err => {
                console.log(err);
            })
        }
    },[location, params?.uid])

    useEffect(() => {
        const errorsTemp = errors ? [...errors] : [];
        const errorMessage = t("user:alreadyExists", "The input email address is already attributed");
        if (existingEmail) {
            if (errorsTemp.indexOf(errorMessage) === -1)
                errorsTemp.push(errorMessage);
        } else {
            const index = errorsTemp.indexOf(errorMessage);
            if (index !== -1)
                errorsTemp.splice(index, 1);
        }
        setErrors(errorsTemp)
    }, [existingEmail])

    const inputChangeHandler = (e) => {
        const name = e.target.name;
        let value =  name === "login" ? e.target.value?.toLowerCase() : e.target.value;

        let userTemp = {
            ...user,
            [name]: value
        }
        setUser(userTemp)
        if (name === "login" && value)
            checkLoginDuplicate(value);
    }

    const phoneInputChangeHandler = (value) => {
        let userTemp = {
            ...user,
            telephone_number : value
        }
        setUser(userTemp)
    }

    const entitiesChangeHandler = (entitiesList,p) => {
   
        let userTemp = {
            ...user,
            entities : entitiesList,
            entitiesToDelete : p
        }
       setUser(userTemp)
    
      
    }
    const supplierChangeHandler = (entitiesList) => {
        let userTemp = {
            ...user,
            suppliers : entitiesList,
          
        }
        setUser(userTemp)
    }
    const groupChangeHandler = (groupsList,p) => {
     
        let userTemp = {
            ...user,
            groups : groupsList,
            groupsToDelete : p
        }
        setUser(userTemp)
    }
    const regionChangeHandler = (regionList,p) => {
    
        let userTemp = {
            ...user,
            region : regionList,
            regionsToDelete : p
        }
        setUser(userTemp)
    }

    const suppliersChangeHandler = (suppliersList) => {
        let userTemp = {
            ...user,
            suppliers : suppliersList
        }
        setUser(userTemp)
    }
    
    const deleteEntityclickHandler = (entity) => {
        let userTemp = {...user}
        if(user?.id && !entity?.new ){
            userTemp = {
                ...userTemp,
                entitiesToDelete : userTemp?.entitiesToDelete ? [...userTemp?.entitiesToDelete, entity.uid] : [entity.uid]
            }
        }
        let userEntities = [...user?.entities]
        let entityIndex = user?.entities.findIndex(ent => ent.uid === entity.uid);
        if(entityIndex >=0 ){
            userEntities.splice(entityIndex, 1)
        }
        setUser({  
            ...userTemp,
            entities: userEntities
        })
    }
    const deleteSupplierclickHandler = (entity) => {
        let userTemp = {...user}
        if(user?.id && !entity?.new ){
            userTemp = {
                ...userTemp,
                entitiesToDelete : userTemp?.entitiesToDelete ? [...userTemp?.entitiesToDelete, entity.uid] : [entity.uid]
            }
        }
        let userEntities = [...user?.suppliers]
        let entityIndex = user?.suppliers.findIndex(ent => ent.uid === entity.uid);
        if(entityIndex >=0 ){
            userEntities.splice(entityIndex, 1)
        }
        setUser({
            ...userTemp,
            suppliers: userEntities
        })
    }
    const deleteRegionclickHandler = (region) => {
        let userTemp = {...user}
        if(user?.id && !region?.new ){
            userTemp = {
                ...userTemp,
                regionsToDelete : userTemp?.regionsToDelete ? [...userTemp?.regionsToDelete, region.uid] : [region.uid]
            }
        }
        let userRegions = [...user?.region]
        let regionIndex = user?.region.findIndex(g => g.uid === region.uid);
        if(regionIndex >=0 ){
            userRegions.splice(regionIndex, 1)
        }
        setUser({
            ...userTemp,
            region: userRegions
        })
    }

    const deleteGroupclickHandler = (group) => {
        let userTemp = {...user}
        if(user?.id && !group?.new ){
            userTemp = {
                ...userTemp,
                groupsToDelete : userTemp?.groupsToDelete ? [...userTemp?.groupsToDelete, group.uid] : [group.uid]
            }
        }
        let userGroups = [...user?.groups]
        let groupIndex = user?.groups.findIndex(g => g.uid === group.uid);
        if(groupIndex >=0 ){
            userGroups.splice(groupIndex, 1)
        }
        setUser({
            ...userTemp,
            groups: userGroups
        })
    }

    const deleteSupplierClickHandler = (supplier) => {
        let userTemp = {...user}
        if(user?.id && !supplier?.new ){
            userTemp = {
                ...userTemp,
                suppliersToDelete : userTemp?.suppliersToDelete ? [...userTemp?.suppliersToDelete, supplier.uid] : [supplier.uid]
            }
        }
        let userSuppliers = [...user?.suppliers]
        let supplierIndex = user?.suppliers.findIndex(ent => ent.uid === supplier.uid);
        if(supplierIndex >=0 ){
            userSuppliers.splice(supplierIndex, 1)
        }
        setUser({
            ...userTemp,
            suppliers: userSuppliers
        })
    }
    
    const checkUserFormValidity = (user) => {
        let errors = []
        if (!user.hasOwnProperty('type') || !checkValidity(user?.type, {required: true})){
            errors.push(t("user:error_userType", "Please select a type of user")) ;
        }
        if (Object.keys(profile).filter(p => profile[p]).length == 0){
            errors.push(t("user:error_userProfile", "Please select one or several profiles of user")) ;
        }
        if (!user.hasOwnProperty('level') || !checkValidity(user?.level, {required: true})){
            errors.push(t("user:error_userRole", "Please select a role of user")) ;
        }
        if (!user.hasOwnProperty('login') || !checkValidity(user?.login, {required: true, isEmail: true})){
            errors.push(t("user:error_userEmail", "The field 'Email' is either empty or invalid")) ;
        }
        /*if(showEntitiesListComponent() && +user?.accessOwner !== 1 && (!user.hasOwnProperty('entities') || user?.entities.length == 0)){
            errors.push(t("masterData:error_userEntity", "Aucune entité interne n'a été sélectionnée ! ")) ;
        }
        
            if(showClientsListComponent() && +user?.accessClient !== 1 && (!user.hasOwnProperty('clients') || user?.clients.length == 0)){
                errors.push(t("masterData:error_userClients", "Aucun client n'a été sélectionné ! ")) ;
            }
        */
       
        if (origin=="supplier" && showSuppliersListComponent() && +user?.accessSupplier !== 1 && (!user.hasOwnProperty('suppliers') || user?.suppliers.length == 0)){
            errors.push(t("user:error_userSupplier", "No supplier was selected"));
        }

        if (user?.type === "owner" && hasNoProfile()){
            errors.push("Aucun profil n'a été sélectionné ! Veuillez sélectionner au moins un profil");
        }
        if (existingEmail) {
            errors.push(t("user:alreadyExists", "The input email address is already attributed"));
        }
        return errors;
    }
    
    const checkLoginDuplicate = login => {
        Axios.get("user/"+login+"/checkLogin").then(response => {
            setExistingEmail(response.data.exists && response.data.id && response.data.id !== user?.id);
        })
    }

    const addUserclickHandler = () => {
        let checkErrors = checkUserFormValidity(user);
        if(checkErrors && checkErrors.length > 0){
            setErrors(checkErrors)
        }else{
            setSaving(true);
            setErrors(null)
            Axios.post('user', {...user, profile: profile}).then(response => {
                if(response.data.hasOwnProperty('error')){
                    notyf.error(t("user:alreadyExists", "The input email address is already attributed"));
                }else if (response.data.hasOwnProperty('success')) {
                    notyf10s.success(t("userList:creationSuccess", "User created successfully"))
                    setUser(null);
                    navigate(-1);
                }
            }).catch(response => {
                notyf.error(t("global:errorOccurred", "An error has occurred"))
            }).finally(() => {
                setSaving(false);
            })
        }
    }

    const editUserclickHandler = () => {
        let checkErrors = checkUserFormValidity(user);
        if(checkErrors && checkErrors.length > 0){
            setErrors(checkErrors)
        }else{
            setSaving(true);
            setErrors(null)
            Axios.put(`user/${user?.uid}/user`, {...user, profile: profile}).then(response => {
                if (response.data.hasOwnProperty('success')) {
                    notyf10s.success(t("userList:editionSuccess", "L'utilisateur a bien été modifié"))
                    setUser(null);
                    navigate(-1);
                }
            }).catch(response => {
                notyf.error(t("global:errorOccurred", "An error has occurred"))
            }).finally(() => {
                setSaving(false);
            })
        }
    }

    const saveClickHandler = () => {
        if(user?.id){
            editUserclickHandler()
        }else{
            addUserclickHandler()
        }
    }

    const showEntitiesListComponent = () => {
        return (user?.type === "owner")
    }

    const showClientsListComponent = () => {
        return (user?.type === "owner" && user?.level === "standard")
                || (user?.type === "client")
    }

    const showSuppliersListComponent = () => {
        return (user?.type === "owner" && user?.level === "standard")
                || (user?.type === "supplier")
    }

    const hasNoProfile = () => {
        let result = true;
        Object.values(profile).forEach(p => {
            if (result && p)
                result = false;
        })
        return result;
    }
    
    const optionChangeHandler = (name, value) => {
        // const name = e.target.name;
        // const value = e.target.value;
        if (userProfile === "ADMIN" || userProfile === name.replace("_standard", "").replace("_admin", ""))
            setProfile({
                ...profile,
                [name]: value
            })
    }

    return (
        <>
            <UFContainer>
                <TitleHelmet title={"Transdev | " + t('menu:userManagement')} />
                <Header>
                    <HeaderActions>
                        {saving ? <Spinner/> : (userType === user?.type && userLevel === "admin" || userProfile === "ADMIN") &&
                        <BtnOutlineAction
                            color={theme.colors.success}
                            border={theme.colors.success}
                            onClick={() => saveClickHandler()}
                        >
                            {t("global:validate", "Valider")}
                        </BtnOutlineAction>}
                    </HeaderActions>
                </Header>

                <UFBody>
                    {
                        errors && errors.length > 0 ? (
                            <div className="alert alert-danger mt-2" role="alert">
                                <ul>
                                    {
                                        errors.map(error => (<li key={error}>{error}</li>))
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                    {/* <EntFormSection>
                        <EntitiesDropDown 
                            entitiesType={"supplier"}
                            userEntities={user?.suppliers}
                            addEntitiesUser={suppliersChangeHandler}
                        />
                        <EntFormList>
                            {
                                user?.suppliers?.length  > 0 ? user?.suppliers.map(entity => (
                                    <EntFormItem key={entity.uid} >
                                        <span>{entity.name}</span>
                                        <CloseIcon style={{fontSize: "0.7rem", cursor: "pointer"}} onClick={() => deleteSupplierClickHandler(entity)} />
                                    </EntFormItem>
                                )) : null
                            }
                        </EntFormList>
                    </EntFormSection> */}
                    <EIFormContainer>
                        <EIFormCol>
                            <FormGroup>
                                <FormLabel htmlFor="first_name" >{t("userList:first_name", "First name")}</FormLabel>
                                <FormInput 
                                    id="first_name"
                                    type="text"
                                    name="first_name"
                                    placeholder={t("userList:first_name", "First name")}
                                    disabled={!(userType === user?.type && userLevel === "admin" || userProfile === "ADMIN")}
                                    value={user?.first_name || ""}
                                    onChange={(e) => inputChangeHandler(e)} 
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="last_name" >{t("userList:last_name", "Last name")}</FormLabel>
                                <FormInput 
                                    id="last_name"
                                    type="text"
                                    name="last_name"
                                    placeholder={t("userList:last_name", "Last name")}
                                    disabled={!(userType === user?.type && userLevel === "admin" || userProfile === "ADMIN")}
                                    value={user?.last_name || ""}
                                    onChange={(e) => inputChangeHandler(e)} 
                                />
                            </FormGroup>
                            <FormGroup>
                           
                                <FormLabel htmlFor="login" >Email<MandatoryMarker /></FormLabel>
                                <FormInput 
                                    id="login"
                                    type="text"
                                    name="login"
                                    placeholder="Email"
                                    value={user?.login || ""}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    disabled={user?.uid && userProfile !== "ADMIN"}
                                />
                            </FormGroup>
                            <div className="userForm__entitiesSection_container"> 
                                <EntitiesComponent
                                    show={user?.type === "owner"} 
                                    label={"Entités"} 
                                    modalTitle={t("user:linkToEntities", "Link the user to entities")}
                                    entities={user?.entities} 
                                    deleteEntity={deleteEntityclickHandler} 
                                    addEntity={entitiesChangeHandler}
                                    entitiesType={"owner"}
                                    userType={user?.type}
                                    isMandatory={false}
                                    disabled={!(userLevel === "admin" || userProfile === "ADMIN")}
                                    entitiesCopie={entitiesCopie}
                                    entitiesToDelete={user?.entitiesToDelete}
                                    />
                                <EntitiesComponent
                                    show={user?.type === "supplier"} 
                                    label={t("user:suppliersList", "List of suppliers to link")} 
                                    modalTitle={t("user:linkToSuppliers", "Link the user to suppliers")}
                                    entities={user?.suppliers} 
                                    deleteEntity={deleteSupplierclickHandler} 
                                    addEntity={supplierChangeHandler}
                                    entitiesType={"supplier"}
                                    userType={user?.type}
                                    disabled={!(userType === "supplier" && userLevel === "admin" || userProfile === "ADMIN") || userType === "supplier" && userLevel === "admin" && connectedUid === user?.uid}
                                    isMandatory={true}
                                    />
                                {user?.type === "owner" &&
                                 <GroupComponent
                                    groups={user?.groups} 
                                    deleteGroup={deleteGroupclickHandler} 
                                    addGroup={groupChangeHandler}
                                    groupsCopie={groupsCopie}
                                    groupsToDelete={user?.groupsToDelete}
                                    disabled={!(userLevel === "admin" || userProfile === "ADMIN")}
                                    />}

                         {user?.type === "owner" && 
                                <RegionComponent
                                    show={true} 
                                    label={"Régions"} 
                                    regions={user?.region} 
                                    deleteEntity={deleteRegionclickHandler} 
                                    addEntity={regionChangeHandler}
                                    entitiesType={"owner"}
                                    userType={""}
                                    regionsToDelete={user?.regionsToDelete}
                                    disabled={!(userLevel === "admin" || userProfile === "ADMIN")}
                                    regionsCopie={regionsCopie}

                                    />
                      }

                                 
                            </div>
                        </EIFormCol>
                        <EIFormCol>
                            <FormGroup>
                                <FormLabel htmlFor="telephone_number" >{t("user:phoneNumber", "Phone number")}</FormLabel>
                                <TelInput
                                    localization={fr}
                                    country={'fr'}
                                    dropdownStyle={{width: "auto"}}
                                    preferredCountries={['fr', 'us']}
                                    enableSearch={false}
                                    inputProps={{name: "telephone_number"}}
                                    placeholder={"33 0 00 00 00 00"}
                                    disabled={!(userType === user?.type && userLevel === "admin" || userProfile === "ADMIN")}
                                    value={user?.telephone_number?.substr(2) || ''}
                                    onChange={(phone) => phoneInputChangeHandler("00" + phone)} 
                                />
                            </FormGroup>

                            
                            <FormGroup>
                                <FormLabel style={{textDecoration: "underline", fontWeight: "bold", cursor: "default"}}>{t("global:profile", "Profile")}</FormLabel>
                                <UFProfileContainerBody>
                                    <FormLabel style={{textDecoration: "underline", fontWeight: "bold", cursor: "default"}}>{t("user:standard", "Standard")}</FormLabel>
                                    <FormLabel style={{textDecoration: "underline", fontWeight: "bold", cursor: "default"}}>{t("user:admin", "Administrator")}</FormLabel>
                                </UFProfileContainerBody>
                            </FormGroup>
                            { user?.type === "owner" ? <>
                                <FormGroup>
                                    <FormLabel>Administrateur portail</FormLabel>
                                    <CTooltip title={userProfile !== "ADMIN" ? "Vous n'avez pas les droits de changer ce type de profil" : ""}>
                                        <ToggleIcon active={+profile.ADMIN} >
                                            {
                                                +profile.ADMIN ? 
                                                    <ToggleOnIcon 
                                                        fontSize="small"
                                                        onClick={() => optionChangeHandler("ADMIN", 0)}
                                                    /> :
                                                    <ToggleOffIcon 
                                                        fontSize="small"
                                                        onClick={() => optionChangeHandler("ADMIN", 1)}
                                                    />
                                            }
                                        </ToggleIcon>
                                    </CTooltip>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Comptable</FormLabel>
                                    <UFProfileContainerBody>
                                        <CTooltip title={userProfile !== "ADMIN" && userProfile !== "ACCOUNTING" ? "Vous n'avez pas les droits de changer ce type de profil" : ""}>
                                            <ToggleIcon active={+profile.ACCOUNTING_standard}>
                                                {
                                                    +profile.ACCOUNTING_standard ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("ACCOUNTING_standard", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("ACCOUNTING_standard", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CTooltip>
                                        <CTooltip title={userProfile !== "ADMIN" && userProfile !== "ACCOUNTING" ? "Vous n'avez pas les droits de changer ce type de profil" : ""}>
                                            <ToggleIcon active={+profile.ACCOUNTING_admin}>
                                                {
                                                    +profile.ACCOUNTING_admin ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("ACCOUNTING_admin", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("ACCOUNTING_admin", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CTooltip>
                                    </UFProfileContainerBody>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Gestionnaire Achats </FormLabel>
                                    <UFProfileContainerBody>
                                        <CTooltip title={userProfile !== "ADMIN" && userProfile !== "BUYER" ? "Vous n'avez pas les droits de changer ce type de profil" : ""}>
                                            <ToggleIcon active={+profile.BUYER_standard}>
                                                {
                                                    +profile.BUYER_standard ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("BUYER_standard", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("BUYER_standard", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CTooltip>
                                        <CTooltip title={userProfile !== "ADMIN" && userProfile !== "BUYER" ? "Vous n'avez pas les droits de changer ce type de profil" : ""}>
                                            <ToggleIcon active={+profile.BUYER_admin}>
                                                {
                                                    +profile.BUYER_admin ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("BUYER_admin", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("BUYER_admin", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CTooltip>
                                    </UFProfileContainerBody>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Gestionnaire conformité </FormLabel>
                                    <UFProfileContainerBody>
                                        <CTooltip title={userProfile !== "ADMIN" && userProfile !== "REJECT_MANAGER" ? "Vous n'avez pas les droits de changer ce type de profil" : ""}>
                                            <ToggleIcon active={+profile.REJECT_MANAGER_standard}>
                                                {
                                                    +profile.REJECT_MANAGER_standard ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("REJECT_MANAGER_standard", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("REJECT_MANAGER_standard", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CTooltip>
                                        <CTooltip title={userProfile !== "ADMIN" && userProfile !== "REJECT_MANAGER" ? "Vous n'avez pas les droits de changer ce type de profil" : ""}>
                                            <ToggleIcon active={+profile.REJECT_MANAGER_admin}>
                                                {
                                                    +profile.REJECT_MANAGER_admin ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("REJECT_MANAGER_admin", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("REJECT_MANAGER_admin", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CTooltip>
                                    </UFProfileContainerBody>
                                </FormGroup>


                            {/* /////////////////////////////////////////////////////// */}
                            {/* <FormGroup>
                                <FormLabel htmlFor="region" >Pôle/région</FormLabel>
                                <FormSelect 
                                    id="region"
                                    name="region"
                                    value={user?.region || ""}
                                    onChange={(e) => inputChangeHandler(e)} 
                                >
                                    <option value=""></option>
                                    {regions.map(r => <option key={r.id} value={r.id}>{r.name}</option>)}
                                </FormSelect>
                            </FormGroup> */}
                            
                            </> : <FormGroup>
                                    <FormLabel>{t("global:supplier", "Supplier")}</FormLabel>
                                    <UFProfileContainerBody>
                                        <CTooltip title={userProfile !== "ADMIN" && userProfile !== "SUPPLIER" ? t("user:noRightsToChangeProfile", "You do not have the right to edit this type of profile") : ""}>
                                            <ToggleIcon active={+profile.SUPPLIER_standard}>
                                                {
                                                    +profile.SUPPLIER_standard ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("SUPPLIER_standard", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("SUPPLIER_standard", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CTooltip>
                                        <CTooltip title={userProfile !== "ADMIN" && userProfile !== "SUPPLIER" ? t("user:noRightsToChangeProfile", "You do not have the right to edit this type of profile") : ""}>
                                            <ToggleIcon active={+profile.SUPPLIER_admin}>
                                            {
                                                    +profile.SUPPLIER_admin ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("SUPPLIER_admin", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("SUPPLIER_admin", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CTooltip>
                                    </UFProfileContainerBody>
                                </FormGroup>
                            }
                           
                        </EIFormCol>
                    </EIFormContainer>
                </UFBody>
            </UFContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    userLevel: state.auth.userLevel,
    userProfile: state.auth.profile,
    connectedUid: state.auth.userUid,
    connectedEntity: state.auth.entityFirst
  })
  const mapDispatchToProps = dispatch => ({
  })
  export default connect(mapStateToProps, mapDispatchToProps) (UserForm)
