import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../assets/images/transdev_logo.png'
import './Signin.css';
import { Trans, useTranslation } from 'react-i18next';
import LanguageList from '../LanguageList/LanguageList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const Signin = props => {
    const { t } = useTranslation();
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    return (
        <div className="signin__container">
            <div className="signin__FromWrap">
                <div className="signin__fromContent">
                    <form className="signin__form" action="#">
                        <div className="signin_language_container">
                            <LanguageList color="red" />
                        </div>
                        <div className="signin__header">
                            <Link to="/" className="signin__Icon">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        {
                            props.signinError ? 
                                <span className="signin__error"> 
                                    {t("authentication:"+props.signinError)}
                                </span>
                            : null
                        } 
                        {/* {
                            props.nbTriesError != "" &&
                                <p className="signin__error">{t("authentication:"+props?.nbTriesError)}</p>
                        } */}
                        <div className="signin__formGroup">
                            {/* <label className="signin__formLabel" htmlFor='username'>{t("signin:login", "Login")}</label> */}
                            <input 
                                className="signin__formInput" 
                                type="email" 
                                name="email" 
                                placeholder={t("authentication:login", "Login")}
                                value={props.authForm.email} 
                                onChange={props.changed} 
                                required 
                                autoFocus 
                            />
                        </div>
                        <div className="signin__formGroup">
                            {/* <label className="signin__formLabel" htmlFor='password'>{t("signin:password", "Mot de passe")}</label> */}
                            <div style={{display:"flex"}}>
                                <input 
                                    className="signin__formInput" 
                                    type={showCurrentPassword ? "text" : "password"} 
                                    style={{paddingRight: "36px", width: "100%"}}
                                    name="password" 
                                    placeholder={t("authentication:password", "Password")}
                                    onChange={props.changed} 
                                    required 
                                />
                                {showCurrentPassword ? <VisibilityIcon style={{position: "absolute", right: "106px", marginTop: "8px", cursor: "pointer", color: "grey"}} onClick={() => setShowCurrentPassword(false)}/>
                                : <VisibilityOffIcon style={{position: "absolute", right: "106px", marginTop: "8px", cursor: "pointer", color: "grey"}} onClick={() => setShowCurrentPassword(true)}/>}
                        </div></div>
                        <div className="signin__formBtnGroup">
                            <button 
                                className="signin__formButton" 
                                type="submit" 
                                onClick={props.submited}
                            >
                                {t("authentication:logon", "Log in")}
                            </button>
                            <Link to="/forgotPassword" className="forgotPWD__link">{t("authentication:forgottenPsw")}</Link>
                            <Link to="/contact" className="forgotPWD__link">{t("authentication:clickIfYouCannotLogin", "If you cannot log in, click here")}</Link>
                        </div>

                        <div className="signin__footer">
                            <p>
                                {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)}
                            </p>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    )
}

export default Signin
