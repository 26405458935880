import axios from 'axios';
import * as actionTypes from './actionTypes';
import {Base64} from 'js-base64';
import Axios from '../../axios-proas';
import UserService from '../../services/UserService';
import { env } from './../../env'

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (login,token, userUid, userType, userLevel,entityFirst, multiEntities, profile, status) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        login: login,
        userUid: userUid,
        userType: userType, 
        userLevel: userLevel,
        entityFirst:entityFirst,
        multiEntities:multiEntities,
        profile: profile,
        status:status
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const authSetTimeout = (timeout) => {
    return {
        type: actionTypes.AUTH_SET_TIMEOUT,
        timeout: timeout
    }
}

export const logout = () => {
    localStorage.removeItem('profile')
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    localStorage.removeItem('login')
    localStorage.removeItem('type')
    localStorage.removeItem('level')
    Axios.put("reject//clearManager").then(() => {}).catch(() => {})
    if(UserService.getUsername()) UserService.doLogout()
    
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = () => {
    return dispatch => {
        document.onmousemove = () => {
            dispatch(authSetTimeout(setTimeout(() => { dispatch(logout()) }, 1800000)));
        }
    }
}

export const auth = (email, password) => { 
    return dispatch => {
        dispatch(authStart());
        let authAuthorization = Base64.encode(email + ":" + password)
        let config = {
            headers: {
                'Authorization': 'Basic ' + authAuthorization
            }
        }
        axios.get(env.REACT_APP_BASE_URL+'/user/'+email+'/authenticate/', config)
            .then(response => {
                const expirationDate = new Date(response.data.expireIn * 1000 );
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                let token = response.data.token;
                let login = response.data.email;
                let userUid = response.data.uid;
                let userType = response.data.type;
                let userLevel = response.data.level;
                let profile = response?.data?.profile;
                let userProfile = response?.data?.userProfile;
                let entityFirst=response.data.entityFirst;
                let multiEntities=response.data.multiEntities;
                let status=response.data.status;
                localStorage.setItem('token', token)
                localStorage.setItem('expirationDate', response.data.expireIn)
                localStorage.setItem('message', response.data.message)
                localStorage.setItem('code', response.data.code)
                localStorage.setItem('userId', userUid)
                localStorage.setItem('login', login)
                localStorage.setItem('type', userType)
                localStorage.setItem('level', userLevel)
                localStorage.setItem('profile', profile)
                localStorage.setItem('userProfile', userProfile)
                dispatch(authSuccess(login, token, userUid, userType, userLevel,entityFirst, multiEntities, profile, status))
                dispatch(checkAuthTimeout())
            })
            .catch(err => {
                dispatch(authFail(err.response?.data.message))
            })
    }
}

export const authSSO = (email) => {
    return dispatch => {
        dispatch(authStart());
        let authAuthorization = Base64.encode(email)
        let config = {
            headers: {
                'Authorization': 'Basic ' + authAuthorization
            }
        }
        axios.get(env.REACT_APP_BASE_URL+'/user/sso/authenticate/', config)
            .then(response => {
                let token = response.data.token;
                window.location.replace('https://transdev.dematrust.com?token=' + token);
            })
            .catch(err => {
                dispatch(authFail(err.response?.data.message))
            })
    }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}


export const changeProfile = (p) => ({
    type: actionTypes.AUTH_CHANGE_PROFILE,
    profile: p
})

export const authCheckState = (p) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const login = localStorage.getItem('login');
        if(!token) {
            dispatch(logout())
        } else {
            dispatch(authStart());
            const instance = Axios;
            instance.defaults.headers.authorization = 'Bearer ' + token;
            let pf=null
            if(p==null)
                pf= localStorage.getItem('userProfile') ? localStorage.getItem('userProfile') : null
            else {
                pf=p.id
                localStorage.setItem('userProfile', p.id);
                localStorage.setItem('profile', p.profile);
                localStorage.setItem('level', p.level);
            }
            instance.get('/user/'+(pf ? pf : "")+'/checkAuth')
                .then(response =>{
                    if (!response.data)
                        throw new Exception("Inactive user");
                    let token = response.data.token;
                    let login = response.data.email;
                    let userUid = response.data.uid;
                    let userType = response.data.type;
                    let userLevel = response.data.level;
                    let entityFirst = response.data.entityFirst;
                    let multiEntities = response.data.multiEntities;
                    let profile = response.data.profile;
                    localStorage.setItem('token', token)
                    Axios.defaults.headers.authorization = 'Bearer ' + token;
                    if(p!=null) {
                        if(p.profile != profile)
                            profile = p.profile;

                        if(p.level != userLevel)
                            userLevel = p.level;
                    }
                    dispatch(authSuccess(login, token, userUid, userType, userLevel, entityFirst, multiEntities, profile))
                    dispatch(checkAuthTimeout())
                })
                .catch(err => {
                    dispatch(logout());
                })
        }
    }
    
}

export const authCheckStateFromToken = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(!token) {
            dispatch(logout())
        } else {
            dispatch(authStart());
            const instance = Axios;
            instance.defaults.headers.authorization = 'Bearer ' + token;
            instance.get('/user//checkAuthSSO')
                .then(response =>{
                    if (!response.data)
                        throw new Exception("Inactive user");
                    let login = response.data.email;
                    let userUid = response.data.uid;
                    let userType = response.data.type;
                    let userLevel = response.data.level;
                    let entityFirst = response.data.entityFirst;
                    let multiEntities = response.data.multiEntities;
                    let profile = response.data.profile;
                    let userProfile = response?.data?.userProfile;
                    localStorage.setItem('expirationDate', response.data.expireIn)
                    localStorage.setItem('message', response.data.message)
                    localStorage.setItem('code', response.data.code)
                    localStorage.setItem('userId', userUid)
                    localStorage.setItem('login', login)
                    localStorage.setItem('type', userType)
                    localStorage.setItem('level', userLevel)
                    localStorage.setItem('profile', profile)
                    localStorage.setItem('userProfile', userProfile)
                    Axios.defaults.headers.authorization = 'Bearer ' + token;
                    dispatch(authSuccess(login, token, userUid, userType, userLevel, entityFirst, multiEntities, profile))
                    dispatch(checkAuthTimeout())
                })
                .catch(err => {
                    dispatch(logout());
                })
        }
    }
    
}