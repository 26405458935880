import React, { useCallback, useEffect, useRef, useState } from 'react'
import './FAQ.css';
import Axios from '../../axios-proas';
import {getNotyfObject} from '../../shared/utility';
import { connect } from 'react-redux';
// import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageChangeModal from './PageChangeModal/PageChangeModal';
import AddCategoryModal from './AddCategoryModal/AddCategoryModal';
import Spinner from 'react-bootstrap/Spinner'
import CustomConfirmModal from './CustomConfirmModal/CustomConfirmModal';
import 'suneditor/dist/css/suneditor.min.css';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { HeaderLocation } from '../../styles/Common';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import QRContainer from './QRContainer/QRContainer'
import QREditor from './QREditor/QREditor';
import { CTooltip } from '../../components/UI/CTooltip/CTooltip';
import QuestionCategoryCard from './QuestionCategoryCard/QuestionCategoryCard';
import FileUploaderBtn from './AddCategoryModal/FileUploaderBtn/FileUploaderBtn';
import CloseIcon from '@mui/icons-material/Close';

function FAQ({userProfile}) {
    const {t, i18n} = useTranslation();
    const notyf = getNotyfObject();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchQuestion, setSearchQuestion] = useState("");
    const [categoriesList, setCategoriesList] = useState([]);
    const [category, setCategory] = useState(null);
    const [formChanged, setFormChanged] = useState(false);
    const [pageChangeModal, setPageChangeModal] = useState(null);
    const [showEnglishResponse, setShowEnglishResponse] = useState(false);
    const [showFrenchResponse, setShowFrenchResponse] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [putLoading, setPutLoading] = useState(false);
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
    const questionsRef = useRef(null);

    useEffect(() => {
        getQuestionCategories();
    }, [])

    const getTranslatedField = (object, field) => {
        const suffix = i18n.language === "fr" ? "Fr" : "En";
        return object ? object[field+suffix] : "";
    }

    const getQuestionCategories = useCallback((searchQ = searchQuery, searchQuest = searchQuestion, order = false) => {
        setGetLoading(true)
        Axios.get("questioncategory", {
            params : {
                searchQuery : searchQ,
                searchQuestion : searchQuest,
                sortOrder: (order) ? "DESC" : "ASC"
            }
        }).then(response => {
            setGetLoading(false)
            setCategoriesList(response?.data);
            if (!response?.data?.find(d => d.id === category?.id))
                setCategory(null);
        }).catch(response => {
            setGetLoading(false)
            notyf.error("Échec de la récupération des catégories");
        })
    }, [categoriesList, searchQuery, searchQuestion])

	const getQuestions = (category, searchQuest = searchQuestion, saved = false) => {
        const callback = () => {
            Axios.get("questioncategory/"+category.uid+"/object", {params : { searchQuestion : searchQuest} }).then(response => {
                if (!searchQuest || response?.data?.questions?.length > 0) {
                    setCategory(response?.data);
                    questionsRef.current.scrollIntoView();
                } else
                    setCategory(null);
            }).catch(response => {
                notyf.error("Échec de la récupération des questions");
            })
        }
        if (saved)
            callback();
        else
            handlePageChangeModal(() => callback())
    }

    const searchHandler = (e) => {
        setSearchQuery(e.target.value);
        getQuestionCategories(e.target.value, searchQuestion)
    }

    const searchQuestionHandler = (e) => {
        setSearchQuestion(e.target.value);
        getQuestionCategories(searchQuery, e.target.value)
        if (category)
            getQuestions(category, e.target.value)
    }

    const handleAddCategory = category => {
        Axios.post("questioncategory", category).then(response => {
            setCategoriesList([...categoriesList, response.data])
            notyf.success("Catégorie ajoutée avec succès")
            setShowAddCategoryModal(false);
        }).catch(error => {
            notyf.error("Erreur lors de l'ajout de catégorie");
        })
    }

    const deleteQuestionHandler = index => {
        const newCategory = {...category};
        newCategory.questions.splice(index, 1);
        setCategory(newCategory);
    }

    const deleteCategoryHandler = cat => {
        return new Promise((resolve, reject) => {
            Axios.delete("questioncategory/"+cat.uid+"/category").then(response => {
                if (response.data.success) {
                    const newCategories = [...categoriesList];
                    const index = newCategories.findIndex(c => c.uid === cat.uid);
                    if (index > -1)
                        newCategories.splice(index, 1);
                    setCategoriesList(newCategories);
                    if (category?.uid === cat.uid)
                        setCategory(null)
                    notyf.success("Succès de la suppression");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors de la suppression");
                    reject(false);
                }
            }).catch(() => {
                notyf.error("Échec de la suppression");
                reject(false);
            })
        })
    }
    
    const handleAddQuestion = () => {
        const newCategory = {...category};
        newCategory.questions.push({questionFr: "", questionEn: "", answerFr: "", answerEn: "", public: 1, category: category.id});
        setCategory(newCategory);
    }

    const saveCategory = () => {
        Axios.put("questioncategory", category).then(response => {
            getQuestions(category);
            const newCategories = [...categoriesList];
            const newCat = newCategories.find(c => c.id === category.id);
            if (newCat) {
                if (category.labelFr)
                    newCat.labelFr = category.labelFr;
                if (category.labelEn)
                    newCat.labelEn = category.labelEn;
            }
            setCategoriesList(newCategories);
            notyf.success("Succès de la sauvegarde")
        }).catch(error => {
            notyf.error("Erreur lors de la sauvegarde")
        })
    }
    
    const inputCategoryChangeHandler = (event) => {
        const newCategory = {...category};
        newCategory[event.target.name] = event.target.value;
        setCategory(newCategory);
    }

    const inputChangeHandler = (event, index) => {
        const newCategory = {...category};
        const newQuestion = newCategory.questions[index];
        newQuestion[event.target.name] = event.target.value;
        setCategory(newCategory);
    }

    const visibilityChangeHandler = (isPublic, index) => {
        const newCategory = {...category};
        const newQuestion = newCategory.questions[index];
        newQuestion.public = isPublic;
        setCategory(newCategory);
    }

    const handlePageChangeModal = callback => {
        if (formChanged) {
            const modal = React.createElement(PageChangeModal, {handleClose: confirm => {setPageChangeModal(null); if(confirm) setFormChanged(false)}, callback: callback});
            setPageChangeModal(modal);
        } else
            callback();
    }
    
    return (
        <div className="masterData_container" style={{marginBottom: "30px"}}>
            <TitleHelmet title={"Transdev | " + t("menu:enti", "FAQ")} />
            {/* <Prompt when={formChanged} message={location => location.pathname.startsWith("/faq") ? true : t('masterData:pageChangeModal', "Voulez-vous vraiment quitter la page ? Toutes les modifications non sauvegardées seront perdues.")}/> */}
            {pageChangeModal}
            <div> 
                {/* Partie gauche */}
                <div className="masterData_leftSide_container" >
                    <div className="leftSide_header_container">
                        <div className="header_actionTypes_container">
                            <div className="header_actionTypes_items ">
                                {/* <div className="header_icon">?</div> */}
                                <HeaderLocation style={{fontSize:"1rem"}}>
                                    {t('faq:faq', "Foire aux questions")} 
                                </HeaderLocation>
                            </div>
                        </div>
                        <div className="leftSide_searchInput_container">
                            <div style={{width: "20vw"}}>
                                <label className="searchInput_label" htmlFor="search">{t('default:search', "Rechercher")} </label>
                                <div className="searchInput_input_container">
                                    <SearchIcon className="searchInput_input_icon"/>
                                    <input 
                                        type="text"  
                                        className="form-control searchInput_input" 
                                        name="searchQuery"  
                                        id="searchQuery" 
                                        placeholder={t('faq:category',"Catégorie")}
                                        onChange={(e) => searchHandler(e)}
                                        value={searchQuery || ''}
                                    />
                                </div>
                            </div>
                            <div style={{margin: "0 30px", width: "20vw"}}>
                                <div className="searchInput_input_container">
                                    <SearchIcon className="searchInput_input_icon"/>
                                    <input 
                                        type="text"  
                                        className="form-control searchInput_input" 
                                        name="searchQuery"  
                                        id="searchQuery" 
                                        placeholder="Mot-clé"
                                        onChange={(e) => searchQuestionHandler(e)}
                                        value={searchQuestion || ''}
                                    />
                                </div>
                            </div>
                            {userProfile === "ADMIN" && <div className="header_addIcon_container" onClick={() => setShowAddCategoryModal(true)} >
                                <CTooltip title="Ajouter une nouvelle catégorie"><AddIcon style={{color:'rgb(245, 79, 79)'}}/></CTooltip>
                            </div>}
                        </div>
                    </div>
                    {/* Info list */}
                    <div className="leftSide_listElements_container">
                        {
                            getLoading ?
                                <div style={{width: "100%", display: 'flex', justifyContent: 'center', marginTop: "4rem"}}>
                                    <Spinner animation="border" variant="danger" />
                                </div>
                            : categoriesList && categoriesList.map((cat) =>  
                                <QuestionCategoryCard key={cat.id} category={cat} userProfile={userProfile} setShowDeleteCategoryModal={setShowDeleteCategoryModal} selected={cat.id === category?.id} clicked={() => getQuestions(cat)} />
                            )
                        }
                    </div>
                </div>
                <div className="masterData_rightSide_container" ref={questionsRef}>
                    {category && <div className="rightSide_header_container">
                        {userProfile === "ADMIN" ? <>
                            <div className="header_categoryInput_container">
                                <h2>Français</h2>
                                <input type="text" className="form-control header_categoryInput" style={{width:"100%", height: "fit-content"}} required minLength="1" name="labelFr" placeholder="Catégorie" value={category?.labelFr || ''} onChange={inputCategoryChangeHandler} /> 
                            </div>
                            <div style={{borderLeft: "1px solid rgb(231, 231, 231)"}}></div>
                            <div className="header_categoryInput_container">
                                <h2>Anglais</h2>
                                <input type="text" className="form-control header_categoryInput" style={{width:"100%", height: "fit-content"}} required minLength="1" name="labelEn" placeholder="Catégorie (anglais)" value={category?.labelEn || ''} onChange={inputCategoryChangeHandler} /> 
                            </div>
                        </>
                        :
                        <div className="header_infos_container">  
                            <div className="header_title">
                                <strong>{getTranslatedField(category, "label")}</strong>
                            </div>
                        </div>}
                    </div>}
                    <div className="rightSide_form_container">
                        <form>
                            {userProfile === "ADMIN" ? category?.questions?.map((question, index) =>
                                <QREditor
                                    key={question.id}
                                    questionId={question.id}
                                    frQuestion={question?.questionFr}
                                    enQuestion={question?.questionEn}
                                    frAnswer={question?.answerFr}
                                    enAnswer={question?.answerEn}
                                    inputChangeHandler={inputChangeHandler}
                                    visibilityChangeHandler={visibilityChangeHandler}
                                    index={index}
                                    isPublic={question?.public}
                                    deleteQuestionHandler={deleteQuestionHandler}
                                />
                            )  :
                            <div>
                            {category?.questions?.map(question => 
                                <QRContainer key={question.id} question={question} getTranslatedField={getTranslatedField} searchQuestion={searchQuestion} />
                            )}
                            </div>}
                            {
                                category != null && userProfile === "ADMIN" ?
                                <div className="questionAnswer_form_container">
                                    {
                                        putLoading ?
                                            <div className="rightSide_form_submit_container">
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        : 
                                            <div className="rightSide_form_submit_container">
                                                <button 
                                                    type="button" 
                                                    onClick={() => handleAddQuestion()}
                                                    className="btn btn-light rightSide_form_submitBtn"><AddIcon /> Ajouter une question</button>
                                                <button 
                                                    type="button" 
                                                    style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}}
                                                    onClick={() => saveCategory()}
                                                    className="btn btn-danger rightSide_form_submitBtn">{t('default:save', "Sauvegarder")}  </button>
                                            </div>
                                    }
                                </div> : null
                            }
                        </form>
                    </div>
                </div>
            </div>
            <AddCategoryModal
                show={showAddCategoryModal}
                handleClose={() => setShowAddCategoryModal(false)}
                categories={categoriesList}
                categoryAddHandler={handleAddCategory}
            />
            <CustomConfirmModal
                show={showDeleteCategoryModal}
                handleClose={() => setShowDeleteCategoryModal(false)}
                text="Voulez-vous vraiment supprimer cette catégorie de questions ? Toutes les questions/réponses qui lui sont liées seront également supprimées."
                okCallback={() => deleteCategoryHandler(showDeleteCategoryModal)}
            />
        </div>
    )
}
const mapStateToProps = (state) => ({ 
    userProfile : state.auth.profile
})
const mapDispatchToProps = dispatch => ({     
    // getCountMasterDatasPending: () => dispatch(actions.getCountMasterDatasPending())
})

export default connect(mapStateToProps, mapDispatchToProps)(FAQ)