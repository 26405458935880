import React, { useCallback, useEffect, useState } from 'react'

import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import {Spinner} from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { getNotyfObject } from '../../shared/utility';
import {EXContainer, EXBody, EXListWrapper}from './AuditTrail.styled'
import { NoContent, SpinnerContainer  } from '../../styles/Table.styled';
import { BtnIcon, BtnOutlineLink, Header, HeaderActions, SearchInput, SearchInputContainer } from '../../styles/Common';
import AuditTrailItem from './AuditTrailItem/AuditTrailItem';
import { useTheme } from 'styled-components';

function AuditTrail(props) {
    let {
        loading, 
        auditTrailData, 
        count, 
        currentPage, 
        pageSize,
        setAuditTrailPageSize,
        setAuditTrailPage, 
        getAuditTrailPage, 
        setAuditTrailFilters, 
        filtersQuery
    } = props;
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const theme =  useTheme();
    const [cursor,setCursor]=useState("pointer")
    const [auditTrails, setAuditTrails] = useState(auditTrailData);

    useEffect(() => {
        setAuditTrailFilters({})
        setAuditTrailPage(1)
        getAuditTrailPage(1, {}, pageSize);
    }, [getAuditTrailPage, pageSize])

    useEffect(() => {
        setAuditTrails(auditTrailData)
    }, [auditTrailData])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setAuditTrailPage(newPage);
        getAuditTrailPage(newPage, filtersQuery, pageSize);
    }, [filtersQuery, pageSize]);

    const handleApplyFiltering = useCallback((filters) => {
        getAuditTrailPage(1,filters, pageSize);
    }, [currentPage, filtersQuery, pageSize]);

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filtersQuery,
            [key]: e.target.value
        }
        setAuditTrailFilters(newFilters);
        if (e.target.value?.length >= 3)
            handleApplyFiltering(newFilters);
        else if (e.target.value?.length < 3 && filtersQuery?.[key]?.length >= 3) {
            newFilters[key] = "";
            handleApplyFiltering(newFilters);
        }
    }
    const deleteAuditTrail =(item)=>{
        Axios.delete('auditTrail/'+item+'/deleteByUid').then(response => {
            handleReset()
        }).catch(response => {
            notyf.error("Une erreur s'est produite")
        })

    }

    const handleReset = useCallback(() =>{
        let filtersTemp = {}
        setAuditTrailFilters(filtersTemp);
        setAuditTrailPage(1);
        getAuditTrailPage(1, filtersTemp, pageSize);
    }, [])

    const handleRefreshItem = uid => {
        const auditTrailsTemp = [...auditTrails];
        const item = auditTrailsTemp.find(e => e.uid === uid);
        Axios.get("auditTrail/"+uid+"/status").then(response => {
            item.status = response.data.status;
            setAuditTrails(auditTrailsTemp);
        })
    }

    let tBody = null;
    let tSpinner = loading  ? (
        <SpinnerContainer>
                <Spinner />
        </SpinnerContainer>
       
        ) : 
        <NoContent>
            <span style={{fontSize:"1rem",color:"#888888",padding:"0px 0px 0px 48px"}}>Aucune demande de piste d'audit fiable à afficher</span>
        </NoContent>

    if(!loading && auditTrails?.length > 0){
        tSpinner = null;
        tBody = ( 
            <EXListWrapper> 
                {
                    auditTrails?.length > 0 ? auditTrails.map((data, index) => (
                        <AuditTrailItem
                            key={data.uid}
                            cursor={cursor}
                            setCursor={setCursor}
                            data={data} 
                            deleteAuditTrail={deleteAuditTrail}
                            refreshItem={handleRefreshItem}
                        />
                    )) : null
                }
            </EXListWrapper>
        )
    }

    return (
        <EXContainer cursor={cursor}>
            <Header>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''} 
                            onChange={(e) => inputChangeHandler(e)}
                            
                        />
                        <SearchIcon/>
                    </SearchInputContainer>
                    <BtnOutlineLink
                        color={theme.colors.primary}
                        border={theme.colors.primary}
                        to="/auditTrailForm"
                    >
                        <span>{t('tableAction:ask',"Nouvelle demande" )}</span>
                        <BtnIcon>
                            <AddIcon />
                        </BtnIcon>
                    </BtnOutlineLink>
                </HeaderActions>
            </Header>       
            <EXBody>
                <TablePagination 
                    currentPage={currentPage} 
                    pageChange={handleChangePage} 
                    totalElement={count} 
                    perPage={pageSize}
                    perPageChange={setAuditTrailPageSize}
                />
                {tBody}
                {tSpinner}
                <TablePagination 
                    currentPage={currentPage} 
                    pageChange={handleChangePage} 
                    totalElement={count} 
                    perPage={pageSize}
                    perPageChange={setAuditTrailPageSize}
                />
            </EXBody>
            </EXContainer>
    )
}
const mapStateToProps = (state) => ({
    loading: state.auditTrail.loading,
    auditTrailData : state.auditTrail.data,
    count: state.auditTrail.count,
    currentPage: state.auditTrail.currentPage,
    pageSize: state.auditTrail.pageSize,
    filtersQuery: state.auditTrail.filtersQuery
})
const mapDispatchToProps = dispatch => ({
    getAuditTrailPage: (page, filters, pageSize) => dispatch(actions.auditTrailGetPage(page, filters, pageSize)),
    setAuditTrailPage: (page) => dispatch(actions.auditTrailSetPage(page)),
    setAuditTrailPageSize: (pageSize) => dispatch(actions.auditTrailSetPageSize(pageSize)),
    setAuditTrailFilters: (filters) => dispatch(actions.auditTrailSetFilterQuery(filters))
})
export default connect(mapStateToProps, mapDispatchToProps) (AuditTrail)
