import styled from 'styled-components/macro';

export const MCContainer = styled.div`
    // width: 400px;
    // max-width: 400px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: flex-start;
    height: calc(800px - 7rem - 40px);
    max-height: calc(800px - 7rem - 40px);
    padding-right: 10px;
`

export const MCMessagesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const ScrollToUpBtn =  styled.div`
    position: sticky;
    width: 26px;
    height: 27px;
    top: 0px;
    right: 0;
    background-color: rgba(25, 98, 158, 0.3);
    color: white;
    border-radius: 5px;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
`

export const MessagesContainer = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const Message = styled.div`
    flex-direction: ${({isMine}) => isMine ? "row-reverse" : "row"};
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;
`

export const MessageAvatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border : 1px solid ${({theme}) => theme.colors.secondary};
    color: black;
    background-color: white;
    margin-right: 1rem;
`

export const MessageContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0.25rem;
    // border-left: ${({isMine, theme}) => !isMine ? `2px solid ${theme.colors.orangeLight}` : 'none'};
    // border-right: ${({isMine, theme}) => isMine ? `2px solid ${theme.colors.orangeLight}` : 'none'};
    margin-right: 0.25rem;
    border: ${({isMine, theme}) => isMine ? `1px solid ${theme.colors.secondary}` : 'none'};
    border-radius: 4px;
    padding: 10px;
    word-wrap: break-word;
    max-width: calc(100% - 50px - 1rem);
`

export const MessageValue = styled.p`
    font-size: 0.8rem;
    margin : 0;
    padding: 0;
    width: 100%;
`

export const MessageDate = styled.p`
    font-size: 0.7rem;
    margin : 0;
    padding: 0;
    color: ${({theme}) => theme.colors.secondary};
    align-self: ${({isMine}) => isMine ? "flex-end" : "flex-start"};
`

export const MsgInputContainer = styled.form`
    width: 100%;
    color : ${({theme}) => theme.colors.secondary};
    border: 2px solid ${({theme}) => theme.colors.secondary};
    border-radius: 16px;

    &:focus-within {
        border: 2px solid ${({theme}) => theme.colors.primary};
    }

`
export const MsgInputDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding : 0.5rem 1rem;
`

export const MsgInput = styled.textarea`
    flex: 1;
    overflow: hidden;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 0.8rem;
    color: ${({theme}) => theme.colors.secondary};
    resize: none;

    &::placeholder {
        color: ${({theme}) => theme.colors.secondary};
    }
`

export const MsgSendBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: rotate(-45deg);
    margin-bottom: 0.25rem;

    &:hover {
        color: ${({theme}) => theme.colors.primary};
    }
`