import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
    width: 100%;
    height: 45px;
    font-size: 0.8rem;
    padding: 0.25rem 0;
    background-color: rgb(86,41,111);
    display: flex;
    justify-content: space-between;
    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        min-height:45px;
        /* height: auto; */
    }
`;

export const HeaderTitle = styled.h5`
    display: flex;
    color: white;
    padding-left: 16px;
    font-size:1.4rem;
    margin: auto 0px;
    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        font-size:0.8rem;
    }
`;

export const HeaderLinks = styled.div`
    height: 100%;

    display: flex;
    align-items: baseline;
    justify-content: end;
    margin-right: 4rem;
    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        margin-right: 1rem;
    }
`;

export const Hlink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
   // background-color:#ff0000 ;
    color: ${({lcolor}) => lcolor || '#fff'} ;
    padding: 0.5rem;
    
    & span {
        margin-left: 0.25rem;
    }
    &:hover {
        color: ${({lcolor}) => lcolor || '#e5d1f2'} ;
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        font-size: 0.6rem;
    }
`;

export const HLang = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color:#fff ;
`;

export const HTheme = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: ${({theme, isActive}) => isActive ? theme.colors.primary : theme.colors.secondary};
    cursor: pointer;

    & span {
        color: ${({lcolor}) => lcolor || '#063E53'} ;
        margin-left: 0.25rem;
    }
`;

