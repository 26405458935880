import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { EntContainer,Table, EntContent, EntHeader, EntHeaderAction,Selectdiv,Coselect, EntDivContainer,NavTabsLi,TitleDiv, THead,NavTabsLink,LITableContainer, NavTabsUl, EmptyResultMessageDiv} from './TopAndFlop.styled'
import {  TBody,TH,TR,TD, THContainer} from '../../../styles/Table.styled'   
import './Select.css'
function TopAndFlop({columns, dataTop,dataFlop,haveList,setProfileForGetUser,profileForGetUser,title,noNavList,width, showList, emptyListMessage}) {
    const navigate = useNavigate();
    const {t} = useTranslation();
   let location = useLocation(); 
const[profile,setProfile] = useState("ADMIN");
   const eventsList = [
    { label: 'Administrateur portail', value: 'ADMIN'},
    { label: 'Comptable', value: 'ACCOUNTING'},
    { label: 'Gestionnaire conformité', value: 'REJECT_MANAGER'},
    { label: "Gestionnaire Achats", value: "BUYER"}
    
];

    const [navTabsList, setNavTabsList] = useState([
        {label: 'Top', value: 'top'},
        {label: 'Flop', value: 'flop'},

    ])
    const [tab, setTab] = useState("top");
    const changeTab = tab => {
        setTab(tab);
    }

    const typeACChangeHandler = (e) => {
        console.log(e)
        setProfile(e.value)
        setProfileForGetUser(e.value)

      };
      const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
          return {
            ...styles,
            // backgroundColor: isDisabled ? 'red' : "blue",
            // color: '#FFF',
            // cursor: isDisabled ? 'not-allowed' : 'default',
            fontSize:"0.8rem"
           
          };
        },
     
      };

    return (
        <EntDivContainer width={width}>
       
            <TitleDiv>
             <span>{title}</span>
             {showList &&
                <Coselect
                            name="mailType"
                            // value={email?.mailType || ""}
                            onChange={(e)=>typeACChangeHandler(e)}
                            placeholder={"Choisir"}
                            noOptionsMessage={() => "Aucun résultat"}
                           // value={eventsList.find(item => item.value == profile)}
                            value={eventsList.find(item => item.value == profileForGetUser)}
                            styles={colourStyles}
                            // suggestions={emailTypesSuggestions}
                            // onSelect={onSelectTypeACHandler}
                            // isLoading={!email?.mailType? true:false}
                            
                       
                         
                            options={eventsList}
                        />}
            </TitleDiv>
            <EntContainer>
                {!noNavList&&
                <NavTabsUl>
                    {
                        navTabsList.map((t, index) => (
                    <NavTabsLi key={t.value}>
                        <NavTabsLink 
                                    active={+(tab === t.value)} 
                                    onClick={() => changeTab(t.value)}
                                    to={`#`}
                        > 
                                    {t.label}
                        </NavTabsLink>
                    </NavTabsLi>
                        ))
                    }
                    {/* <Selectdiv>


                    </Selectdiv> */}
                </NavTabsUl> 
        }      
            <EntContent>
            <LITableContainer>
                <Table>   
                    <THead>
                    <tr>
                        {columns?.map(column => (
                            <TH 
                                key={column.field} 
                        
                                scope="col" 
                                width={column.width}
                                id={column.field} 
                                title={column.title} 
                                
                                >
                                <THContainer>
                                    <span>
                                    {column.title}
                                    </span>
                                </THContainer>  
                            </TH>
                        ))}
                    </tr>
                    </THead>
                    <TBody>
                    { (tab=="top"?dataTop:dataFlop).map((row,index) => (
                        <TR key={row.id} style={{cursor: "default"}} >
                            <TD>{haveList?row.first_name:row.name}</TD>                       
                            <TD >{haveList?row.last_name:row.code}</TD>
                            <TD>{haveList?row.total:row.nbrData}</TD>
                        </TR>
                    ))

                    }
                    </TBody>
            </Table>
            {
                columns?.length === 0  && 
                <EmptyResultMessageDiv> {emptyListMessage} </EmptyResultMessageDiv>
            }
        </LITableContainer>
    </EntContent>
 </EntContainer>
 </EntDivContainer>
    )
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    userLevel : state.auth.userLevel,
    login : state.auth.login
  })
  const mapDispatchToProps = dispatch => ({
  })
  export default connect(mapStateToProps, mapDispatchToProps) (TopAndFlop)
  