import React, { useCallback, useEffect, useState } from 'react';
import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import TableBody from '../../components/EmailsSettings/TableBody/TableBody';
import TableActions from '../../components/EmailsSettings/TableActions/TableActions';
import TableHead from '../../components/EmailsSettings/TableHead/TableHead';
import TableFilters from '../../components/EmailsSettings/TableFilters/TableFilters';
 import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { getNotyfObject ,getLabelWithKey} from '../../shared/utility';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { NoContent, SearchInput, SearchInputContainer, SpinnerContainer, Table, TableAction, TBody } from '../../styles/Table.styled';
 import { SIBody, SIContainer, SITableContainer, BtnErrContainer,DivTest,BtnHd } from './EmailsSettings.styled';
import SelectPeriod from '../../components/UI/SelectPeriod/SelectPeriod';
import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
  

function EmailsSettings(props) {
    let {
        userLevel,
        userType, 
        userProfile, 
    } = props;
    const [messagesToggleOn, setMessagesToggleOn] = useState(false);
    const [density, setDensity] = useState("2.25rem")  
    let location = useLocation();
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const [active, setActive] = useState(false);
    const [isNone, setIsNone] = useState(true); 
    const [quickFilter, setQuickFilter] = useState(null); 
    const navTitre = "emailSettings";  
    const [data, setData] = useState([]); 
    const [loading, setLoading] = useState(false); 
    const [count, setCount] = useState(0); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [pageSize, setPageSize] = useState(100); 
    const [filtering, setFiltering] = useState(false); 
    const [showFilters, setShowFilters] = useState(false); 
    const [filtersQuery, setFiltersQuery] = useState(null);   
    const [typeFilter, setTypeFilter] = useState(null);
    const [eventFilter, setEventFilter] = useState(null);
    const [sortQuery, setSortQuery] = useState("type");  
    const [reverse, setReverse] = useState( {
        type: false
    });    

    const { t } = useTranslation(); 
 
    const initialFiltersQuery = {
      type: "", event: "", title: "", fr_subject: "", orderBy: "type"
    } 

    const columns = [ 
      {title: 'Type', field: 'type', width: "80px"},
      {title: 'Evènement' , field: 'event', width: "150px"},  
      {title: 'Sujet', field: 'fr_subject', width: "300px"} ,
      {title: 'Modifier', field: '', width: "40px"}  
    ] 
 
    useEffect(() => {   
        refreshContent(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [pageSize])  
   
    const refreshContent = (currentPageP, filtersP, sortQueryP, reverseP, pageSizeP) => { 
      let newFilters = {
          pageSize: pageSize
      }
      if(filtersP){
          for (const key in filtersP) {
              if(filtersP[key])
                  newFilters = {
                      ...newFilters,
                      [key]: filtersP[key]
                  }
          }
      } 
      if(sortQueryP){
          newFilters = {
              ...newFilters,
              orderBy: sortQueryP
          }
      }
      if(reverseP){
          newFilters = {
              ...newFilters,
              reverse: reverseP[sortQueryP]
          }
      }   
      setLoading(true); 
      Axios.get('/settingsemail?page='+currentPageP,{  params : {...newFilters} }    
      ).then(res => {
          let data = res.data?.settingsemail
          let countItems = res.data?.nbItems
          if(data){  
            setData(data)
            setCount(countItems)
          } else{ 
            setData([])
            setCount(0)
          }
      }).finally(() => {
          setLoading(false);
      })
    }   
  
    useEffect(() => {
        let newFilters = {
            ...initialFiltersQuery
        }
        setShowFilters(false)
        setFiltersQuery(newFilters)        
    }, [pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        filtersList.push("documentType");
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            handleReset()
        }
    }, [showFilters]); 

    const handleShowFilters = () => {
      setShowFilters(!showFilters)
    } 
    
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const handleChangePage = useCallback((newPage) => {
        setCurrentPage(newPage);
        refreshContent(newPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [filtersQuery, reverse, sortQuery, pageSize]);

    const handleSort = useCallback((sort, newReverse) => {  
        refreshContent(currentPage, filtersQuery, sort, newReverse, pageSize) 
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const handleApplyFiltering = useCallback((filters) => {  
       refreshContent(1, filters, sortQuery, reverse, pageSize)
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

  

    const handleReset = useCallback(() => {
        let newFilters = {
            ...initialFiltersQuery
        }
        setQuickFilter(null)
        setSortQuery("type")
        setReverse({ type: false })
        setFiltersQuery(newFilters);
        setCurrentPage(1);
        refreshContent(1, newFilters, "type", { type: false }, pageSize) 
    }, [initialFiltersQuery])

    const filtersChangeHandler = (newFilters) => {
        const filtersTemp = {
            ...newFilters,
            quickCreationDate: null,
            quickSearch: null
        } 
        setQuickFilter(null)
        setFiltersQuery(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }  

    const quickSearchChange = (quickSearchValue) => {
        let filtersTemp = {
            ...initialFiltersQuery,
            quickSearch: quickSearchValue
        }
        setQuickFilter('quickSearch')
        setFiltersQuery(filtersTemp)
        handleApplyFiltering(filtersTemp);
    }


    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("invoiceList:noInvoices")}</span>
        </NoContent>
    );

    if (!loading && data?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (
            <TableBody
                columns={columns}
                data={data}
                formatDate={formatDate}  
                userLevel={userLevel}
                userType={userType}
                userProfile={userProfile}
            />
        )
    }

    const BtnClickHandler = () => {
     setActive(!active)
     setIsNone(false)
    }
 
    return (
        <SIContainer>
            <TitleHelmet title={"Transdev | " + t("menu:invoices")} />
            <Header>
                <h5 style={{marginLeft: "105px"}}>Ici, je peux consulter la liste et modifier les mails types</h5>
                <HeaderActions>
                    {/* <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        <SearchIcon />
                    </SearchInputContainer> */}
                    <TableActions
                        filterState={showFilters}
                        showFilters={handleShowFilters}
                        filtering={filtering}
                        sortQuery={sortQuery}
                        reverse={reverse}
                        filters={filtersQuery}
                        resetData={handleReset}
                        formatDate={formatDate} 
                        userLevel={userLevel}
                        userType={userType}
                    /> 
                </HeaderActions>
            </Header> 
            
            <SIBody>
            <TablePagination navTitre={navTitre}   
              showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handleChangePage}
                        totalElement={count}
                        //perPage={pageSize!=null ? (typeof(pageSize)==="string" ? parseInt(pageSize):pageSize):pageSize}
                        perPage={pageSize}
                        perPageChange={setPageSize}
                    />
                <SITableContainer> 
                    <Table>
                        <TableHead
                            columns={columns}
                            sortQuery={sortQuery}
                            reverse={reverse}
                            setSortQuery={setSortQuery}
                            setReverse={setReverse}
                            getData={handleSort} 
                            filters={filtersQuery}
                            
                        /> 
                        <TBody height={density}>
                            {showFilters && <TableFilters 
                                show={showFilters}
                                formatDate={formatDate}
                                filters={filtersQuery}
                                filtersChange={filtersChangeHandler}  
                                setTypeFilter={setTypeFilter}
                                setEventFilter={setEventFilter}
                               
                                applyFilters={handleApplyFiltering}
                                eventFilter={eventFilter}
                                typeFilter={typeFilter} 
                                userLevel={userLevel}
                                userType={userType}
                                userProfile={userProfile}                            
                            />}
                            {tBody}
                        </TBody>
                    </Table>
                  
                    {tSpinner}
                    <TablePagination  navTitre={navTitre}  
                        currentPage={currentPage}
                        pageChange={handleChangePage}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setPageSize}
                    />
                     
                </SITableContainer>
            </SIBody>
       
        </SIContainer>
    )
}
const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token, 
  
    userLevel: state.auth.userLevel,
    userType: state.auth.userType,
    userProfile: state.auth.profile
})
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
      
})
export default connect(mapStateToProps, mapDispatchToProps)(EmailsSettings)
