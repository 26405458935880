import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HelpIcon from '@mui/icons-material/Help';
// import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import { CardContainer, CardHeader, CardInfos, CardIcon, Option, SelectFilter, CardPercentage, ListContainer, CardPercentageIcon, CardPercentageValue, CardTitle, CardBody, CardValue } from './Card.Styled';
import { useTranslation } from 'react-i18next';
import { getProfileWithKey } from '../../../shared/utility';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
function CardSupplier({ icon, isIncreasing, percentage, title, value, list, currencies, clickHandler,message ,isUserProfile,width, tooltip}) {
    const {t} = useTranslation();
    const [valueOfList, setValueOfList] = useState(null);
    const [somInvoice, setSomInvoice] = useState(0);
    const [totalInvoice, setTotalInvoice] = useState(0);
    const [optionValue, setOptionValue] = useState(null);

    const getSymbolWithKey = (key) => {
        const status = {
            EUR: "€",
            USD: "$",
            GBP: "£",
            DKK: "kr",
        }
        return status[key] ? status[key] : key;
    }
    const getLbelWithValue = (value) => {
        if(value){
                if (value > 1000) {
                    return (value / 1000).toFixed(1) + "k " + getSymbolWithKey(valueOfList);
                } else {
                    return (value / 1).toFixed(1) + getSymbolWithKey(valueOfList);
                }
       }else
    //    return "0"+getSymbolWithKey(valueOfList);
        return "0";
    }
    
    let op = (
        Object.values(currencies).map((item, index) => {
            return <Option key={index} value={item.currency}>{isUserProfile ? getProfileWithKey(item.currency) : item.currency}</Option>
    }))

    useEffect(() => {
        if(currencies?.length>0){
         let op = (
             Object.values(currencies).map((item, index) => {
                 return <Option key={index} value={item.currency}>{item.currency}</Option>
         }))
         setOptionValue(op)
         setValueOfList(currencies[0].currency)
        }
    }, [currencies])

    useEffect(() => {
        if (list) {
            let u =Object.values(value).find(item => valueOfList === (isUserProfile ? item.profile : item.currency));
            setTotalInvoice(u?.total ? u?.total : 0)
            setSomInvoice(getLbelWithValue(u?.somme) ? getLbelWithValue(u?.somme) : 0)
        } else {
            setTotalInvoice(value)
        }

    }, [value, valueOfList])

    const inputChangeHandler = (e) => {
       let totalItem=value.filter(item => item.profile === e.target.value)
       console.log(totalItem)
        console.log(e.target.value)      
          setValueOfList(e.target.value)
    }

    
    return (
        <CardContainer onClick={() => {if(clickHandler) clickHandler()}} style={{width:width ?width : "30%", minWidth:width ?width : "30%", cursor: clickHandler ? "pointer" : "inherit"}}>
            <CardHeader>
                <CardInfos>
                    <CardIcon>
                        {icon}
                    </CardIcon>
                    {
                        percentage && (
                            <CardPercentage isIncreasing={isIncreasing}>
                                <CardPercentageIcon isIncreasing={isIncreasing}>
                                    {
                                        isIncreasing ?
                                            <ArrowUpwardIcon style={{ fontSize: "0.8rem" }} />
                                            :
                                            <ArrowDownwardIcon style={{ fontSize: "0.8rem" }} />
                                    }
                                </CardPercentageIcon>
                                <CardPercentageValue>
                                    {isIncreasing ? '+' : '-'}
                                    {percentage}
                                    <span>%</span>
                                </CardPercentageValue>
                            </CardPercentage>
                        )
                    }   

                    <ListContainer>

                    {currencies.length>0 && list && (
                        <SelectFilter
                            onChange={(e) => inputChangeHandler(e)}
                            onClick={e => e.stopPropagation()}
                            value={valueOfList || ""}
                            name="valueOfList"
                            id="valueOfList"
                            style={{ width: "100px" }}

                        >
                            { op }
                        </SelectFilter>
                    )}
                    </ListContainer>
                </CardInfos>
                <CardTitle>
                    {title} {tooltip && <CTooltip title={tooltip}><HelpIcon/></CTooltip>}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <CardValue>
                    {message==null? 
                        <p>{totalInvoice}&nbsp;{t("invoiceList:invoice", "invoice").toLowerCase()}{+totalInvoice>1?"s":null}&nbsp;|&nbsp;{somInvoice} {t("global:ttc", "including taxes")}</p>
                        :
                        <p>{totalInvoice} {message}{+totalInvoice>1?"s":null}</p> 
                    }
                    {/* {totalInvoice}&nbsp;factures&nbsp;|&nbsp;{somInvoice} TTC */}
                </CardValue>
            </CardBody>
        </CardContainer>
    );
}

export default CardSupplier;
