import React, { useEffect, useState } from 'react'
import './TablePagination.css';
import Pagination from "react-js-pagination";
import { useTranslation } from 'react-i18next';
import { LabelDensityAndColumns, PerPageGroup, PerPageLabel, PerPageSelect, TPContainer } from './TablePagination.styled';
import { BtnOutlineAction } from '../../../styles/Common';
import ListeMenu from '../DensityAndColumns/ListeMenu'
import ShowColumns from '../DensityAndColumns/ShowColumns';
import SaveIcon from '@mui/icons-material/Save';
import { CTooltip } from '../CTooltip/CTooltip';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import CustomInputModal from '../CustomInputModal/CustomInputModal';
import CustomConfirmModal from '../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal';
import Axios from '../../../axios-proas';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { SelectWrap, SelectContainer, SelectValue, SelectIcon, SelectOptions, SelectOption } from '../Select/Select.styled';
import { getNotyfObject } from '../../../shared/utility';
import { useLocation } from 'react-router-dom';

function TablePagination(props) {
    const { t } = useTranslation();
    const [showSaveConfigModal, setShowSaveConfigModal] = useState(false)
    const { currentPage, pageChange, totalElement, perPage, perPageChange, setDensity, columns, userType, navTitre, loadColumns, setLoadColumns, showAll, setShowAll, filters, tableProfile, setTableProfile, tableType } = props;
    const pageNumbers = [];
    const pageElement = perPage || 10;
    const [tableProfiles, setTableProfiles] = useState([])
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [showDeleteProfileModal, setShowDeleteProfileModal] = useState(false);
    const notyf = getNotyfObject();
    const location = useLocation();
    const showInvoiceTooltip = location.pathname === "/fournisseurInvoices";

    for (let i = 1; i <= Math.ceil(totalElement / pageElement); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        if (tableType)
            Axios.get("usertableprofile/" + tableType + "/perType").then(response => {
                setTableProfiles([{value: null, label: "-- "+t("tablePagination:chooseProfile", "Please choose a table profile")+" --"}].concat(response.data?.map(d => {return {value: d.id, label: d.label, profileValue: d.value}})));
            })
    }, [tableType])

    const handleMenuItemClick = (a, b, i) => {
      updateMenuItem(a, navTitre, b)
    };
  
    const handleMenuItemClickShowAll = show => {
        if (show !== undefined)
            updateMenuItem("all", navTitre, show)
        else
            updateMenuItem("all", navTitre, !showAll)
    }
  
    const updateMenuItem = (field, navTitre, bool) => {
      Axios.put('/user//navColumns', { field: field, navTitre: navTitre, bool: bool }
      ).then(response => {
        if (response.data.success) {
        }
      })
      if (setLoadColumns)
        setLoadColumns(!loadColumns);
    }

    const handleClickTableProfile = (newValue) => {
        setIsSelectOpen(false);
        const newTableProfile = tableProfiles.find(p => p.value === newValue);
        setTableProfile(newTableProfile)
        if (!newTableProfile.value)
            handleMenuItemClickShowAll(true);
    }

    return (
        <TPContainer>
            {
                <PerPageGroup>
                    {perPageChange && <><PerPageLabel htmlFor="perPage">{t("global:row", 'Row')}s</PerPageLabel>
                    <CTooltip title={t("tablePagination:rowNumberTooltip", "Defines the number of rows displayed per page")}>
                        <PerPageSelect
                            id="perPage"
                            value={perPage}
                            onChange={(e) => perPageChange(e.target.value)}
                        >
                            <option value="2">2</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </PerPageSelect>
                    </CTooltip></>}
                
                    {setDensity ?
                        <ListeMenu setDensity={setDensity} /> : null}
                    {columns ?
                        <ShowColumns columns={columns} showAll={showAll} handleMenuItemClick={handleMenuItemClick} handleMenuItemClickShowAll={handleMenuItemClickShowAll}/> : null}
                    {setTableProfile && <><CTooltip title={t("tablePagination:saveProfile", "Lets you save the current columns/filters configuration")}>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <BtnOutlineAction
                                border="#fff"
                                onClick={() => setShowSaveConfigModal(true)}
                                size="small"
                                sx={{ ml: 4 }}>
                                <SaveIcon fontSize="small" style={{color:"#1e1e1e"}} />
                                <LabelDensityAndColumns>{t("global:save", "Save")}</LabelDensityAndColumns>
                            </BtnOutlineAction>
                        </Box>
                    </CTooltip>
                    <SelectWrap>
                        <SelectContainer style={{marginLeft : "16px"}} onClick={() => setIsSelectOpen(!isSelectOpen)}>
                            <SelectValue>
                                {tableProfile?.label || "-- "+t("tablePagination:chooseProfile", "Please choose a table profile")+" --"}
                            </SelectValue>
                            <SelectIcon>
                                {
                                    isSelectOpen ? <KeyboardArrowUpIcon  /> 
                                    : <KeyboardArrowDownIcon  />
                                }
                            </SelectIcon>
                        </SelectContainer>
                        {
                            isSelectOpen && (
                                <SelectOptions style={{marginLeft : "16px", width: "max-content"}}>
                                    {
                                        tableProfiles.map((item, index) => (
                                            <li className="selectWithDeleteButton_optionContainer" key={index}>
                                                <span className="selectWithDeleteButton_option" onClick={() => handleClickTableProfile(item.value)}>{item.label}</span>
                                                {item.value && <CTooltip title={t("clickToDelete:chooseProfile", "Click to delete the table profile")}><CloseIcon className="selectWithDeleteButton_deleteButton" onClick={() => setShowDeleteProfileModal(item)} /></CTooltip>}
                                            </li>
                                        )
                                    )}
                                </SelectOptions>
                            )
                        }
                    </SelectWrap></>}
                </PerPageGroup>
            }

            <nav className="usersMAnag__paginationContainer">
                <div className="usersMAnag__paginationList">
                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={pageElement}
                        totalItemsCount={totalElement}
                        pageRangeDisplayed={5}
                        onChange={(page) => pageChange(page)}
                        innerClass={"usersMAnag__pagination"}
                        itemClass={"usersMAnag__page_item"}
                        linkClass={"usersMAnag__page_link"}
                        activeClass={"usersMAnag__active"}
                        disabledClass={"usersMAnag__disabled"}
                    />
                    <CTooltip title={showInvoiceTooltip && userType === "owner" ? "Total des factures hors err99, err01, sans date de facture et post-datées" : ""}><span className="usersMAnag__pagination__total">{totalElement} {t('global:results', "result(s)")} </span></CTooltip>
                </div>

            </nav>
            <CustomInputModal 
                show={showSaveConfigModal && !tableProfile?.value}
                handleClose={() => setShowSaveConfigModal(false)}
                title={t("tablePagination:createProfileModalTitle", "Creation of table configuration")}
                text={t("tablePagination:createProfileModalText", "Please enter a name for your table configuration profile.")}
                okCallback={input => new Promise((resolve, reject) => {
                    const value = {columns : {}, filters: filters};
                    columns?.forEach(c => value.columns[c.field] = c.show);
                    const newProfile = {tableType: tableType, label: input, value: JSON.stringify(value)};
                    Axios.post("usertableprofile", newProfile).then(response => {
                        setTableProfiles([...tableProfiles, response.data])
                        setTableProfile(response.data)
                        notyf.success(t("tablePagination:createProfileModalSuccess", "Table profile created successfully"))
                        resolve(response)
                    }).catch(err => {
                        notyf.error(t("tablePagination:createProfileModalFailure", "Failed to create the table profile"))
                        reject(err)
                    })
                })}/>
            <CustomConfirmModal 
                show={showSaveConfigModal && tableProfile?.value}
                handleClose={() => setShowSaveConfigModal(false)}
                text={t("tablePagination:overwriteProfileModalText", "Do you really want to overwrite this table profile with the chosen configuration?")}
                okCallback={() => new Promise((resolve, reject) => {
                    const value = {columns : {}, filters: filters};
                    columns.forEach(c => value.columns[c.field] = c.show);
                    const newProfile = {value: JSON.stringify(value)};
                    Axios.put("usertableprofile/" + tableProfile.value, newProfile).then(response => {
                        const tempProfiles = [...tableProfiles];
                        tempProfiles.find(p => p.value === tableProfile.value).profileValue = newProfile.value;
                        setTableProfiles(tempProfiles);
                        notyf.success(t("tablePagination:overwriteProfileModalSuccess", "Table profile saved successfully"))
                        resolve(response)
                    }).catch(err => {
                        notyf.error(t("tablePagination:overwriteProfileModalFailure", "Failed to save the table profile"))
                        reject(err)
                    })
                })}/>
            <CustomConfirmModal 
                show={showDeleteProfileModal}
                handleClose={() => setShowDeleteProfileModal(false)}
                text={t("tablePagination:deleteProfileModalText", "Do you really want to delete this table profile?")}
                okCallback={() => new Promise((resolve, reject) => {
                    Axios.delete("usertableprofile/" + showDeleteProfileModal.value).then(response => {
                        const tempProfiles = [...tableProfiles];
                        const index = tempProfiles.findIndex(p => p.value === showDeleteProfileModal.value);
                        tempProfiles.splice(index, 1);
                        setTableProfiles(tempProfiles);
                        if (tableProfile?.value === showDeleteProfileModal.value)
                            handleClickTableProfile(null);
                        notyf.success(t("tablePagination:deleteProfileModalSuccess", "Table profile deleted successfully"))
                        resolve(response)
                    }).catch(err => {
                        notyf.error(t("tablePagination:deleteProfileModalFailure", "Failed to delete the table profile"))
                        reject(err)
                    })
                })}/>
        </TPContainer>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType
})
const mapDispatchToProps = dispatch => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(TablePagination)
