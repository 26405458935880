import React, { useEffect, useRef, useState } from 'react';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { CardContainer, CardHeader, CardInfos, CardIcon, CardTitle, CardBody, CardValue, ListContainer, SelectFilter } from './Card.Styled';
import Axios from '../../../axios-proas'
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

function CardErr00() {
    const [totalInvoice, setTotalInvoice] = useState(0);
    const [filterDate, setFilterDate] = useState("");
    const [csvData, setCsvData] = useState(null);
    const csvRef = useRef(null);

    const handleFilterDateChange = period => {
        const currentDate = new Date();
        let periodTemp = "";
        switch (period) {
            case "year":
                const yearFirstDay = new Date(currentDate.getFullYear(), 0, 1).getTime();
                const yearLastDay = new Date(currentDate.getFullYear() + 1, 0 , 1).getTime() - 1;
                periodTemp = yearFirstDay + ":" + yearLastDay;
                break;
            case "month":
                const monthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime();
                const monthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1 , 1).getTime() - 1;
                periodTemp = monthFirstDay + ":" + monthLastDay;
                break;
            case "lastMonth":
                const lastMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).getTime();
                const lastMonthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() , 1).getTime() - 1;
                periodTemp = lastMonthFirstDay + ":" + lastMonthLastDay;
                break;
            default:
                periodTemp = "";
        }
        setFilterDate(periodTemp);
    }
   
    useEffect(() => {
        Axios.get("invoice//err00?period="+filterDate).then(response => {
            setTotalInvoice(response.data.result);
        })
    }, [filterDate])

    const exportCsv = () => {
        Axios.get("invoice//exportErr00?period="+filterDate).then(response => {
            const csvDataTemp = [];
            const responseTemp = response.data.map(row => {
                let statusLabel = row.statusLabel;
                if (row?.permanentRejectErr04x && +row?.permanentRejectErr04x && row?.status == "14")
                    statusLabel = "Clôturé err04x";
                else if (row?.permanentReject && +row?.permanentReject && row?.status == "14")
                    statusLabel = "Clôturé";
                else if (row?.rejectType?.includes("err51") && row?.status == "14")
                    statusLabel = "Doublon";
                else if (row?.rejectType?.includes("err52") && row?.status == "14")
                    statusLabel = "Faux doublon";
                return {
                    ...row,
                    statusLabel: statusLabel
                }
            })
            responseTemp.forEach(row => {
                if (row.modifications) {
                    const modifications = row.modifications.split("\r\n");
                    modifications?.filter(m => m && !m.startsWith("supplierName") && !m.startsWith("clientName")).forEach((m, index) => {
                        //if (index === 0)
                            csvDataTemp.push({...row, modifications: m});
                        //else
                            //csvDataTemp.push({modifications: m});
                    })
                } else
                    csvDataTemp.push(row);
            })
            setCsvData(csvDataTemp);
        })
    }
   
    useEffect(() => {
        if (csvData) {
            csvRef.current.link.click();
            setCsvData(null);
        }
    }, [csvData])
    
    return (
        <CardContainer style={{width: "47%"}}>
            <CardHeader>
                <CardInfos style={{justifyContent: "space-between"}}>
                    <CTooltip title="Toutes les erreurs 00 sur la période renseignée (sur la base de la date d'envoi par AGSRM)"><CardIcon>
                        <SyncProblemIcon style={{color: "#f00"}} />
                    </CardIcon></CTooltip>
                    <ListContainer>
                        <SelectFilter
                            onChange={(e) => handleFilterDateChange(e.target.value)}
                            style={{ width: "50%" }}
                        >
                            <option value="all">Tout</option>
                            <option value="year">Année précédente</option>
                            <option value="month">Mois en cours</option>
                            <option value="lastMonth">Mois précédent</option>
                        </SelectFilter>
                    </ListContainer>
                </CardInfos>
                <CardTitle>Erreurs 00</CardTitle>
            </CardHeader>
            <CardBody>
                <CardValue style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                    <p>{totalInvoice}&nbsp;facture{+totalInvoice>1?"s":null}</p>
                    <CTooltip title="Exporter le détail des factures concernées dans la période sélectionnée">
                        <div>
                            <Button variant="danger" onClick={exportCsv} disabled={+totalInvoice === 0} style={{padding: "6px 8px", fontSize: "12px"}}>
                                Exporter CSV
                            </Button>
                        </div>
                    </CTooltip>
                </CardValue>
            </CardBody>
            <CSVLink
                ref={csvRef}
                data={csvData?.map(d => [d.suCode, d.clientName, d.supplierCode, d.supplierName, d.rejectType, d.originalRejectType, d.channel, d.documentType, d.number, d.invoiceDate, d.statusLabel, d.statusChangeDate, d.comments, d.region, d.inclusiveTaxAmount, d.exclusiveTaxAmount, d.currency, d.creationDate, d.documentCode, d.internalNumber,d.modifications]) || []}
                filename={"err00.csv"}
                headers={["Code SU","Nom entité","Code tiers","Fournisseur","Type d'erreur","Type d'erreur d'origine","Type de dépôt","Type","N° facture","Date facture","Statut","Date changement statut","Commentaires","Région","Montant TTC","Montant HT","Devise","Date d'acquisition","Code document","Numéro de facture interne","Commentaire final"]}
                separator={";"}>
            </CSVLink>
        </CardContainer>
    );
}

export default CardErr00;
