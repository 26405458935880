import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TimeLine from './Timeline/TimeLine';
import Exchanges from './Exchanges/Exchanges';
import Axios from '../../../../axios-proas'

import { useTranslation } from 'react-i18next';
import { NavTabsLi, NavTabsLink, NavTabsUl } from '../../../../containers/Entity/Entity.styled';
import { ISContainer, ISNavContainer, ISNavContent } from './InvoiceSide.styled';


function InvoiceSide(props) {
    const {
        tabSideActive,
        setTabSideActive,
        invoiceData,
        userType,
        userProfile,
        statuses,
        width
    } = props
    const {t} = useTranslation(); 
    const [contacts, setContacts] = useState([]); 

    useEffect(() => {
        setTabSideActive("echanges");
        if (invoiceData?.supplierUid)
            Axios.get("entity/"+invoiceData?.supplierUid+"/users?active=1").then(response => {
                setContacts(response.data);
            })
    }, [invoiceData?.invoiceUid])
  
    return (
        <ISContainer width={width}>  
            <ISNavContainer>
                <NavTabsUl>
                    <NavTabsLi>
                        <NavTabsLink 
                            active={+(tabSideActive === "echanges")} 
                            onClick={() => setTabSideActive("echanges")}
                            to="#"
                            >
                                {t('invoiceDetails:dialogue', "Dialogue")} 
                        </NavTabsLink>
                    </NavTabsLi>
                       <NavTabsLi>
                        <NavTabsLink 
                            active={+(tabSideActive === "timeline")} 
                            onClick={() => setTabSideActive("timeline")}
                            to="#"
                        >
                                {t("mandat:timeline", "TimeLine")} 
                        </NavTabsLink>
                    </NavTabsLi>
                    
                </NavTabsUl>
            </ISNavContainer>
            <ISNavContent>
                {tabSideActive === "timeline" && 
                        <TimeLine statuses={statuses} rejectTypes={invoiceData?.rejectTypes} invoiceUid={invoiceData?.invoiceUid} />
                }   
                {tabSideActive === "echanges" && 
                    <>
                        <Exchanges userType={userType} userProfile={userProfile} invoiceUid={invoiceData?.invoiceUid} contacts={contacts}/>
                    </>
                }
            </ISNavContent>
        </ISContainer>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    userProfile: state.auth.profile,
    tabSideActive: state.invoiceDetail.tabSideActive,
    invoiceData: state.invoiceDetail.invoiceData,
})
const mapDispatchToProps = dispatch => ({
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    setInvoiceData: (invoiceData) => dispatch(actions.invoiceDataSuccess(invoiceData))
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSide)
