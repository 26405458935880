import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { LabelDensityAndColumns } from '../TablePagination/TablePagination.styled';
import { BtnOutlineAction } from '../../../styles/Common'
import { CTooltip } from '../CTooltip/CTooltip';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { useTranslation } from 'react-i18next';

export default function ShowColumns({ columns, showAll, handleMenuItemClick, handleMenuItemClickShowAll }) {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <React.Fragment>

      <CTooltip title={t("global:columnsTooltip", "Defines the columns to display in the table")}>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <BtnOutlineAction
            border="#fff"
            onClick={handleClick}
            size="small"
            sx={{ ml: 4 }}>
            {showAll?<ViewColumnIcon fontSize="small" style={{color:"#1e1e1e"}} />:<ViewColumnOutlinedIcon fontSize="small" style={{color:"#1e1e1e"}} />}
            <LabelDensityAndColumns>{t("global:columns", "Columns")}</LabelDensityAndColumns>
          </BtnOutlineAction>
        </Box>
      </CTooltip>


      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
            // maxHeight: '300px',
            // overflow: 'auto'
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
         <MenuItem
          onClick={() => handleMenuItemClickShowAll()}
        >
          <ListItemIcon>
            {
              showAll ? (
                <ToggleOnIcon
                  style={{ marginLeft: '0.25rem', color: "red", cursor: 'pointer' }}
                  fontSize="small"
                  name="edi"

                />
              ) :
                <ToggleOffIcon
                  style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                  fontSize="small"
                  name="edi"
                />
            }
          </ListItemIcon>
          <LabelDensityAndColumns>{t("global:displayAll", "Display all")}</LabelDensityAndColumns>
        </MenuItem>
        {columns.map((row, index) => (
          <MenuItem
            key={row.field}
            onClick={() => row.show ? handleMenuItemClick(row.field, false, index) : handleMenuItemClick(row.field, true, index)}
          >
            <ListItemIcon>
              {
                row.show ? (
                  <ToggleOnIcon
                    style={{ marginLeft: '0.25rem', color: "red", cursor: 'pointer' }}
                    fontSize="small"
                    name="edi"
                  />
                ) :
                  <ToggleOffIcon
                    style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                    fontSize="small"
                    name="edi"
                  />
              }
            </ListItemIcon>
            <LabelDensityAndColumns>{row.title}</LabelDensityAndColumns>
          </MenuItem>
        ))}

       

      </Menu>

    </React.Fragment>
  );
}
