import React, { useEffect, useState } from 'react'
import './RejectsPerSupplierDetailModal.css'
import SearchIcon from '@mui/icons-material/Search';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../axios-proas';
import { formatDate, formatType, formatChannel } from '../../../../shared/utility';

function RejectsPerSupplierDetailModal({handleClose, show}) {
    const [data, setData] = useState([]);
    const [dataCopy, setDataCopy] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const {t} = useTranslation();

    useEffect(() => {
        if (show) {
            Axios.get("reject/"+show+"/rejectsPerSupplier").then(response => {
                setData(response.data);
                setDataCopy(response.data);
            })
        } else {
            setData([]);
            setDataCopy([]);
        }
        setSearchQuery("");
    }, [show])

    useEffect(() => {
        if (data) {
            const dataTemp = [...data];
            const upperQuery = searchQuery.toUpperCase();
            setDataCopy(dataTemp.filter(d => d.code?.toUpperCase()?.includes(upperQuery) || 
                d.number?.toUpperCase()?.includes(upperQuery) || 
                formatChannel(d.channel)?.toUpperCase()?.includes(upperQuery) ||
                formatType(d.documentType)?.toUpperCase()?.includes(upperQuery) ||
                formatDate(+d.issuingDate)?.includes(upperQuery) ||
                d.compostingNumber?.toUpperCase()?.includes(upperQuery) || 
                d.rejectType?.toUpperCase()?.includes(upperQuery)
            ));
        }
    }, [searchQuery])

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
            <Modal.Header closeButton>
                <Modal.Title>{"Détails des factures et rejets du fournisseur " + show}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="suppliersListModal__entities_container">
                    <div className="suppliersListModal__entities_header">
                        <div className=" input-group input-group-sm">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Rechercher"  
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                value={searchQuery || ''}
                            />
                            <div className="input-group-append" >
                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>  
                    </div>
                    <div className="suppliersListModal__entities_list">
                        <table className="table suppliersListModal__entities_table">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Code SU</th>
                                    <th scope="col">Numéro facture</th>
                                    <th scope="col">Type de dépôt</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Date facture</th>
                                    <th scope="col">Numéro compostage</th>
                                    <th scope="col">Type d'erreur si rejet</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataCopy?.length > 0 ? dataCopy.map(d => {
                                        return <tr key={d.id}>
                                            <td>{d.code}</td>
                                            <td>{d.number}</td>
                                            <td>{formatChannel(d.channel)}</td>
                                            <td>{formatType(d.documentType)}</td>
                                            <td>{formatDate(+d.issuingDate)}</td>
                                            <td>{d.compostingNumber}</td>
                                            <td>{d.rejectType}</td>
                                        </tr>;
                                    }) : <tr style={{whiteSpace: "nowrap", textAlign: "center"}}>
                                            <td colSpan={7} style={{fontSize:"20px"}}>Aucune ligne à afficher</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:close', "Fermer")} 
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default RejectsPerSupplierDetailModal
