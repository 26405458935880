import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
export const IDContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const IDHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;

export const RJContainer = styled.div`
   
    display: flex;
    position: relative;
    margin-bottom: 1rem;
   
`;
export const Label = styled.label`
 
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: pre-wrap;
    color: ${({color,theme}) =>color?color: theme.colors.secondary};
`;
export const TRJContainer = styled.div`
   
   display:  ${({isActive,theme}) => isActive ? "flex" :"none"};
    width:380px ;
    height:550px ;
    background-color:#fff ;
    top:58px;
    position: absolute;
    margin-bottom: 1rem;
    /* border: 1px solid #FF0000; */
    box-shadow: 0px 0px 1px 0px #ff0000;
    right:0px ;
    &:before {
        content: '';
        position: absolute;
        top: -15px;
        right:7px ;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        transform: rotate(90deg);
        border-color: transparent #fff transparent transparent;
    } 
`;
export const IDHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 2rem;
    margin-left: 120px;

    @media ${({theme}) => theme.device.laptop} {
        margin-right: 1rem;
        margin-left: 120px;
    }
`;

export const IDHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const IDHeaderTitle = styled.h4`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${({theme}) => theme.colors.text};

    @media ${({theme}) => theme.device.laptop} {
        font-size: 1rem;
    }
`

export const IDProgressLineWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: -7px;
`

export const IDInvoiceDate = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 0 1rem 1.5rem;
    color: ${({theme}) => theme.colors.secondary};

    @media ${({theme}) => theme.device.laptop} {
        padding: 0 0 1.6rem 1rem;
    }
`

export const IDInvoiceDateLabel = styled.h4`
    font-size: 1.2rem;
    margin: 0 0.25rem 0 0;
    white-space: nowrap;

    @media ${({theme}) => theme.device.laptop} {
        font-size: 0.9rem;
    }
`

export const IDInvoiceDateValue = styled.h4`
    font-size: 1.2rem;
    margin: 0;
    white-space: nowrap;
    @media ${({theme}) => theme.device.laptop} {
        font-size: 0.9rem;
    }
`


export const IDHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`

export const IDBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${({accessToken}) => accessToken ? 'center' : 'space-between'};
    align-items: flex-start;
    margin-top: 2rem;
`

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
   
  
`
export const FormDiv = styled.div`
    width: 100%;
    display: flex;
    margin: 12px;
    flex-direction: column;
    background-color:#fff ;
   // height:100% ;
    //border: 1px solid #850000;
`
export const H1 = styled.span`
    width:52px;
    margin-top:-22px;
    margin-left:5px;
    background:white;
`

export const FCmntDiv = styled.div`
    width: 96%;
    display: flex;
    margin: 5px;
    flex-direction:column ;
  
`
export const BtnsContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 15px;
    flex-direction: column;
   justify-content:space-between ;
  
`

export const CorcDiv = styled.div`
    width: 97%;
    display: flex;
    margin-top: 12px;
    flex-direction: column;
   // height:100% ;

`
export const ComntDiv = styled.div`
    width: 100%;
    display: flex;
    margin: 5px;
    flex-direction: column;
   // height:100% ;

`
export const FormGroup = styled.div`
   width:100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin-left:7px ;
   align-items: start;
    padding-bottom: 0.31rem;
    border-bottom: 0.5px solid #F5FBFF;
    &:last-child {
        margin-bottom: 10px;
    }
    &:first-child {
        margin-top: 10px;
    }
 

`
export const GroupBtns = styled.div`
   width:100%;
    display: flex;

    /* justify-content: space-between; */
    margin-left:7px ;
   align-items: center;
    padding-bottom: 0.31rem;
    border-bottom: 0.5px solid #F5FBFF;
 
 

`
export const InputCh = styled.input`
   
   margin: 4px;
   // height:100% ;

`
export const FormInput = styled.input`
    /* flex: 1; */
    border: 1px solid #3391aa;
    outline: none;
    /* width: 150px;
    text-align: right; */
    margin-left: 22px;
    margin-bottom:7px ;
    font-size:14px ;
    width: 350px ;
    height:26px ;
`;
export const BtnAction = styled.button`  
    display: flex;
    justify-content: center ;
    align-items: center;
    height:${({height}) => height ? height : "50px"};
    min-height:${({minHeight}) => minHeight ? minHeight : ""};
  
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border: 2px solid ${({bg}) => bg ? bg : '#3391aa'};
    border-radius: 16px;
    color: ${({color}) => color || "#ff0000"};
    cursor: pointer;
    font-size: 0.9rem;
     background-color: #FFFFFF;

    & span {
        color: ${({color}) => color || 'black'};
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.btnHover};
        color:#ff0000; 
        border:2px solid #ff0000;
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

   export const BtnActionlink = styled.span`
    display: flex;
    justify-content: center ;
    align-items: center;
    /* height: 50px; */
    margin-right: 18px;
    color: ${({color, theme}) => color ? color : "1e1e1e"};
    margin-top: 6px;
    height: 25px;
    font-size: 17px;
   border-bottom: 1px solid #3391aa;
    /* border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    border-radius: 30px;
    color: ${({color, theme}) => color ? color : theme.colors.primary};
    cursor: pointer;
    font-size: 14px;
    background-color: #FFFFFF;
    text-decoration: none; */

  

    &:hover {
        color: ${({theme, hover}) => hover ? hover : "#ff0000"};
        border-bottom: 1px solid #ff0000;
    }
`;
export const DPContainer = styled.div`
    /* border: 1px solid #3391aa; */
    outline: none;
   flex: 1;
    /* width: 150px;
    text-align: right; */
    margin-left: 22px;
    margin-bottom: 0.5rem;

    & input {
        padding: 0.5rem !important;
        color: ${({ theme }) => theme.colors.secondary};
        width: 211px;
    }

    & button {
        color: ${({ theme }) => theme.colors.secondary};
    }

    & fieldset {
        border: 1px solid #3391aa;
    }

    & label {
        //color: ${({ theme }) => theme.colors.secondary};
        border: 1px solid #3391aa;
    }

    /* &:focus-within {
        & input {
            color: ${({ theme }) => theme.colors.primary};
        }
        & button {
            color: ${({ theme }) => theme.colors.primary};
        }
    } */
`