import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { ACHContainer, ACHIcon, ACHValue } from './Attachment.styled';
import DownloadIcon from '@mui/icons-material/Download';

function Attachment({attachment, deleteAttachement, getAttachmentPdf, canDownloadAttachement, canDeleteAttachement}) {
    // console.log("attachment",attachment)
    return (
        <ACHContainer style={{maxWidth: "100%" }}>
            <ACHValue> {attachment.attachment} </ACHValue>
            {
               
               canDeleteAttachement &&
               <ACHIcon>
                    <CloseIcon 
                        style={{color: "red", fontSize: '0.9rem'}}
                        onClick={() => deleteAttachement(attachment.uid)} 
                    />
                </ACHIcon>
            }
            {
                !attachment?.new &&
                    <ACHIcon>
                        <DownloadIcon 
                            style={{color:"#00B894",fontSize: '0.9rem'}} 
                            onClick={() => getAttachmentPdf(attachment.uid)}
                        />
                    </ACHIcon>
            }
            
        </ACHContainer>
    )
}

export default Attachment
