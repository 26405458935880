import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';  
import {getNotyfObject } from '../../../shared/utility'
import { connect } from 'react-redux';
import FileUploaderBtn from './FileUploaderBtn/FileUploaderBtn';
import MandatoryMarker from '../../../components/UI/MandatoryMarker';

function AddCategoryModal({fieldColumnNames, categories, handleClose, show, categoryAddHandler}) {
    const {t} = useTranslation();
    const notyf = getNotyfObject()
    const [category, setCategory] = useState({labelFr: "", labelEn: ""});

    useEffect(() => {
        setCategory({labelFr: "", labelEn: ""});
    }, [show])

    const itemExists = () => { 
        let exists = false
        let doubleField = ''
        categories.forEach(cat => {
            if (cat.labelFr === category.labelFr /*|| cat.labelEn === category.labelEn*/)
                exists = true;
        });     
        if(exists)
            notyf.error("Une catégorie existe déjà avec ce nom")  
        
        return exists
    }

    const inputChangeHandler = event => {
        setCategory({ ...category, [event.target.name]: event.target.value })
    }

    const addClickHandler = () => {
        const empty = category.labelFr === null || category.labelFr.length == 0;
        if (empty) {
            notyf.open({ 
                duration: 4000,
                dismissible: true,
                type: 'warning',
                background: 'orange',
                message: t("default:fillAllInputsMessage")
              });
            return
        } else if(!itemExists()){
            categoryAddHandler(category)
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton> 
                <Modal.Title>Ajouter une nouvelle catégorie de question</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <div className= "form_twoElements_container"> 
                        <div className= "form_element_container" style={{width:"100%"}}> 
                            <label htmlFor="labelFr">Libellé <MandatoryMarker/></label>
                            <input
                                id="labelFr"
                                type="text"
                                name="labelFr"
                                className="form-control" 
                                value = {category.labelFr || ""}  
                                maxLength={"128"}
                                onChange={(e) => inputChangeHandler(e)} 
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="danger"
                        style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} 
                        onClick={() => addClickHandler()}>
                    Valider
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({ 
    role: state.auth.role
})
const mapDispatchToProps = dispatch => ({
})  
export default connect(mapStateToProps, mapDispatchToProps)(AddCategoryModal)
