import React, {useEffect, useMemo, useState} from 'react';
import { ThemeProvider } from 'styled-components';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import {HelmetProvider} from 'react-helmet-async';
import GlobalStyles from './styles/Global';
import theme from './styles/theme';
import darkTheme from './styles/DarkTheme';
import clientTheme from './styles/clientTheme';
import supplierTheme from './styles/supplierTheme'
import Auth from './containers/Auth/Auth';
import ResetPassword from './components/Signin/ResetPassword/ResetPassword';
import ForgotPassword from './components/Signin/ForgottenPassword/ForgotPassword';
import OwnerRoot from './containers/AccesRoots/OwnerRoot/OwnerRoot';
import SupplierRoot from './containers/AccesRoots/SupplierRoot/SupplierRoot';
import ClientRoot from './containers/AccesRoots/ClientRoot/ClientRoot';
import InvoiceDetail from './containers/InvoiceDetail/InvoiceDetail';
import InvoiceToken from './containers/InvoiceToken/InvoiceToken';
import CampainToken from './containers/CampainToken/CampainToken';
import Axios from "./axios-proas";
import UserService from './services/UserService';


function AppSSO(props) {
  const {currentTheme, newprofile, onTryAutoSignup, onAuthSSO, isAuthenticated, userType, userLevel, authRedirectPath, userUid, userProfile} = props;
  let location = useLocation();
  const navigate = useNavigate();
  let routes;

  useEffect(() => {
    if(!isAuthenticated && UserService.getUsername()) {
      onAuthSSO(UserService.getUsername());
    }
  }, [UserService.getUsername()])

  const getTheme = () => {
    if(currentTheme === "dark") {
      return darkTheme;
    }
    switch (userType) {
      case "client":
        return  clientTheme;
      case "supplier":
        return theme;
      case "owner":
        return theme;
      default:
        return theme;
    }
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <HelmetProvider>
        <GlobalStyles />
        <div className="app">
          {routes}
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    currentTheme: state.app.theme,
    isAuthenticated: state.auth.token !== null,
    loading : state.auth.loading,
    userType: state.auth.userType,
    userLevel: state.auth.userLevel,
    userProfile: state.auth.profile,
    userUid : state.auth.userUid,
    authRedirectPath: state.auth.authRedirectPath,
    newprofile: state.auth.newprofile
  }
}

const mapDispatchProps = dispatch => {
  return {
    onAuthSSO: (email) => dispatch(actions.authSSO(email)),
    onTryAutoSignup: (profile) => dispatch(actions.authCheckState(profile))
  }
}
export default connect(mapStateToProps, mapDispatchProps)(AppSSO);
