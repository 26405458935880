import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import {getNotyfObject, uuidv4} from '../../../shared/utility'
import Axios from '../../../axios-proas';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function StatutoryAuditorsModal({handleClose, show, auditorsInit}) {
    const [auditors, setAuditors] = useState(auditorsInit);
    const notyf = getNotyfObject();

    useEffect(() => {
        setAuditors(auditorsInit)
    }, [auditorsInit])
    
    const saveForm = () => {
        Axios.post("statutoryauditor", auditors).then(response => {
            if (response.data.success) {
                notyf.success("Succès de la sauvegarde");
                handleClose();
            } else {
                notyf.error("Erreur lors de la sauvegarde");
            }
        }).catch(() => {
            notyf.error("Échec de la sauvegarde");
        })
    }

    const handleInputChange = (event, uid) => {
        const key = event.target.name;
        const tempAuditors = [...auditors];
        const index = tempAuditors.findIndex(a => a.uid == uid);
        tempAuditors[index][key] = event.target.value;
        setAuditors(tempAuditors);
    }

    const handleAddAuditor = () => {
        const tempAuditors = [...auditors];
        tempAuditors.push({uid: uuidv4()});
        setAuditors(tempAuditors);
    }

    const handleDeleteAuditor = uid => {
        const tempAuditors = [...auditors];
        const index = tempAuditors.findIndex(a => a.uid == uid);
        if(tempAuditors[index].id)
            tempAuditors[index].toDelete = true;
        else
            tempAuditors.splice(index, 1);
        setAuditors(tempAuditors);
    }

    return (
        <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
            <Modal.Header closeButton>
                <Modal.Title>Commissaires aux comptes</Modal.Title>
            </Modal.Header>
            <form onSubmit={e => {e.preventDefault(); saveForm()}}>
                <Modal.Body className="statutoryAuditor__body">
                    <table style={{width: "700px"}}>
                        <thead>
                            <tr>
                                <td style={{width:"40%"}}>Nom et prénom</td>
                                <td style={{width:"40%"}}>Adresse email</td>
                                <td style={{width:"20%"}}>Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {auditors?.filter(a => !a.toDelete)?.map(a => 
                                <tr key={a.uid}>
                                    <td><input type="text" name="name" maxLength={64} className="form-control" onChange={e => handleInputChange(e, a.uid)} value={a.name || ""} autoFocus autoComplete='off' required /></td>
                                    <td><input type="email" name="email" maxLength={64} className="form-control" onChange={e => handleInputChange(e, a.uid)} value={a.email || ""} autoFocus autoComplete='off' required /></td>
                                    <td style={{textAlign: "center"}}>
                                        <CTooltip title="Supprimer le commissaire aux comptes">
                                            <Button variant="light" onClick={() => handleDeleteAuditor(a.uid)}>
                                                <DeleteForeverIcon style={{color: "red"}}/>
                                            </Button>
                                        </CTooltip>
                                    </td>
                                </tr>)}
                            <tr>
                                <td colSpan="2"></td>
                                <td style={{textAlign: "center"}}>
                                    <CTooltip title="Ajouter un nouveau commissaire aux comptes">
                                        <Button variant="light" onClick={handleAddAuditor}>+</Button>
                                    </CTooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button variant="danger" type="submit">
                        Sauvegarder
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default StatutoryAuditorsModal
