import React, { useCallback, useEffect, useRef, useState } from "react"
import TableActions from '../../components/NonEngagingCorrelationTable/TableActions/TableActions';
import TableHead from '../../components/NonEngagingCorrelationTable/TableHead/TableHead';
import TableBody from '../../components/NonEngagingCorrelationTable/TableBody/TableBody';
import TableFilters from '../../components/NonEngagingCorrelationTable/TableFilters/TableFilters';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import Axios from "../../axios-proas";
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions } from '../../styles/Common';
import { NoContent, SearchInput, SearchInputContainer, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { DContainer, DBody, DTableContainer } from './NonEngagingCorrelationTable.styled';
import { Spinner } from 'react-bootstrap';
import { getNotyfObject } from "../../shared/utility";
import CustomConfirmModal from "../FAQ/CustomConfirmModal/CustomConfirmModal";
import NonEngagingModal from "../../components/NonEngagingCorrelationTable/NonEngagingModal/NonEngagingModal";

function NonEngagingCorrelationTable(props) {
    const {
        loading,
        nonEngagingData,
        count,
        currentPage,
        pageSize,
        getNonEngagingPage,
        setNonEngagingPage,
        setNonEngagingPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userProfile,
        updateShowFilters,
        setNonEngagingFilters,
        setSortQuery,
        setReverse,
        setNonEngagingQuickFilter,
        selectedRows,
        setSelectedRows,
        selectedAllRows,
        setSelectedAllRows
    } = props;
    const { t } = useTranslation();
    const notyf = getNotyfObject();
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showNonEngagingModal, setShowNonEngagingModal] = useState(false)
    const navTitre = "nonEngaging";

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{profile:userProfile, navTitre:navTitre}})
            .then(response => {
                let tempColumns = response?.data?.columns
                if (!tempColumns)
                    tempColumns = {suCode: 1, supplierCode: 1, contractRef: 1, element1: 1, element2: 1, element3: 1, element4: 1, element4Percentage: 1, element5: 1, element6: 1};
                let columnsCopy = [
                    { title: t("invoiceList:entityCode", "Code SU"), field: 'suCode', width: "100px", show: tempColumns.suCode },
                    { title: t("supplier:supplierCodefournisseur", "Code tiers"), field: 'supplierCode', width: "100px", show: tempColumns.supplierCode },
                    { title: "Référence contrat", field: 'contractRef', width: "100px", show: tempColumns.contractRef },
                    { title: "Élément 1", field: 'element1', width: "80px", show: tempColumns.element1 },
                    { title: "Élément 2", field: 'element2', width: "80px", show: tempColumns.element2 },
                    { title: "Élément 3", field: 'element3', width: "80px", show: tempColumns.element3 },
                    { title: "Élément 4", field: 'element4', width: "80px", show: tempColumns.element4 },
                    { title: "% élément 4", field: 'element4Percentage', width: "80px", show: tempColumns.element4Percentage },
                    { title: "Élément 5", field: 'element5', width: "80px", show: tempColumns.element5 },
                    { title: "Élément 6", field: 'element6', width: "80px", show: tempColumns.element6 }
                ];
                setColumns(columnsCopy)
                columnsCopy.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns])

    useEffect(() => {
        getNonEngagingPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);
    
    const deleteLineHandler = id => {
        return new Promise((resolve, reject) => {
            Axios.delete('/nonengaging/' + id).then(response => {
                if (response?.data?.success) {
                    notyf.success("Succès de la suppression de la ligne")
                    handleRefresh();
                    resolve(response);
                } else {
                    notyf.error("Erreur lors de la suppression de la ligne");
                    reject(false);
                }
            }).catch(() => {
                notyf.error("Échec de la suppression de la ligne");
                reject(false);
            })
        })
    }

    const handleExportXlsx = () => {
        const newFilters = {};
        if (filtersQuery)
            for (const key in filtersQuery)
                if(filtersQuery[key])
                    newFilters[key]= filtersQuery[key]
        
        if (sortQuery)
            newFilters.orderBy = sortQuery;

        if (reverse)
            newFilters.reverse = reverse[sortQuery]

        const selectedIds = selectedRows ? Object.keys(selectedRows).filter(r => selectedRows[r]) : [];
        if (!selectedAllRows && selectedIds.length > 0)
            newFilters.selectedIds = selectedIds.join(",");
        
        Axios.get('/nonengaging//extractXlsx', {params: newFilters, 
                                                responseType: 'arraybuffer',
                                                headers: {
                                                    'Content-Type': 'multipart/form-data',
                                                }}
        ).then(response => {
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                const downloadUrl = URL.createObjectURL(blob)
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = "nonengageables.xlsx";
                document.body.appendChild(a);
                a.click();
            } else {
                notyf.error("Une erreur s'est produite")
            }
        }).catch(err => {
            notyf.error("Erreur lors de l'extraction")
        });
    }
    
    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getNonEngagingPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setNonEngagingPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getNonEngagingPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setNonEngagingFilters(null);
        setNonEngagingPage(1)
        getNonEngagingPage(1, null, sortQuery, reverse, pageSize)
    }

    const handleRefresh = useCallback(() => {
        getNonEngagingPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const quickSearchChange = (quickSearchValue) => {
        let filtersTemp = {
            ...filtersQuery,
            quickSearch: quickSearchValue
        }
        setNonEngagingQuickFilter('quickSearch')
        setNonEngagingFilters(filtersTemp)
        if (quickSearchValue?.length >= 3)
            handleApplyFiltering(filtersTemp);
        else if (quickSearchValue?.length < 3 && filtersQuery?.quickSearch?.length >= 3) {
            filtersTemp.quickSearch = "";
            handleApplyFiltering(filtersTemp);
        }
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : <NoContent><span>Aucune ligne de correspondance à afficher</span></NoContent>;

    if (!loading && nonEngagingData?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            columns={columns}
            nonEngagingData={nonEngagingData}
            setShowDeleteModal={setShowDeleteModal}
            setShowNonEngagingModal={setShowNonEngagingModal}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows} />)
    }

    return (
        <DContainer>
            <TitleHelmet title={"Transdev | Table de correspondance flux non engageables"} />
            <Header>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        <SearchIcon />
                    </SearchInputContainer>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        setShowNonEngagingModal={setShowNonEngagingModal}
                        exportXlsx={handleExportXlsx}
                        refreshTable={handleRefresh}
                        selectedRows={selectedRows}
                    />

                </HeaderActions>
            </Header>
            <DBody>
                <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                    currentPage={currentPage}
                    pageChange={handlePageChange}
                    totalElement={count}
                    perPage={pageSize}
                    perPageChange={setNonEngagingPageSize}
                    filters={filtersQuery}
                />
                <DTableContainer>
                 
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            filters={filtersQuery}
                            selectedAllRows={selectedAllRows}
                            setSelectedAllRows={setSelectedAllRows}
                        />
                        <TBody height={density}>
                            {showFilters && <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                filters={filtersQuery}
                                filtersChange={setNonEngagingFilters}
                                applyFilters={handleApplyFiltering}
                            />}

                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                </DTableContainer>
            </DBody>
            <CustomConfirmModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                okCallback={() => deleteLineHandler(showDeleteModal)}
                text={`Êtes-vous sûr de vouloir supprimer cette ligne ? Cette action est irréversible`} />
            <NonEngagingModal
                lineData={showNonEngagingModal}
                handleClose={() => setShowNonEngagingModal(false)}
                refreshTable={handleRefresh} />
        </DContainer>
    )
}
const mapStateToProps = (state) => ({
    loading: state.nonEngaging.loading,
    nonEngagingData: state.nonEngaging.data,
    count: state.nonEngaging.count,
    currentPage: state.nonEngaging.currentPage,
    pageSize: state.nonEngaging.pageSize,
    sortQuery: state.nonEngaging.sortQuery,
    reverse: state.nonEngaging.reverse,
    filtersQuery: state.nonEngaging.filtersQuery,
    showFilters: state.nonEngaging.showFilters,
    userProfile: state.auth.profile,
    selectedRows: state.nonEngaging.selectedRows,
    selectedAllRows: state.nonEngaging.selectedAllRows
})
const mapDispatchToProps = dispatch => ({
    getNonEngagingPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.nonEngagingGetPage(page, filters, sort, reverse, pageSize)),
    setNonEngagingPage: (page) => dispatch(actions.nonEngagingSetPage(page)),
    setNonEngagingPageSize: (pageSize) => dispatch(actions.nonEngagingSetPageSize(pageSize)),
    setNonEngagingQuickFilter: (page) => dispatch(actions.nonEngagingSetPage(page)),
    setSortQuery: (sortQ) => dispatch(actions.nonEngagingSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.nonEngagingSetReverseSort(reverseS)),
    setNonEngagingFilters: (filters) => dispatch(actions.nonEngagingSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.nonEngagingUpdateShowFilters(show)),
    setSelectedRows: (rowId, selected) => dispatch(actions.nonEngagingSelectRow(rowId, selected)),
    setSelectedAllRows: (selectedAll) => dispatch(actions.nonEngagingSelectAllRows(selectedAll))
})
export default connect(mapStateToProps, mapDispatchToProps)(NonEngagingCorrelationTable)

