import React, { useState } from 'react'
import { connect } from 'react-redux';
import './Auth.css';
import * as actions from '../../store/actions';
import Signin from '../../components/Signin/Signin';
import Spinner from '../../components/UI/Spinner/Spinner';
import UserService from '../../services/UserService';

const Auth = props => {
    const {error} = props;
    // const [isSSO, setIsSSO] = useState(false);
    const [authForm, setAuthForm] = useState({
        email: '',
        password: '' 
    })

    // if(UserService.getUsername()) {
    //     setIsSSO(true);
    // }

    const inputChangeHandler = (e) => {
        const name = e.target.name;
        const value = name === "email" ? e.target.value.toLowerCase() : e.target.value;
        setAuthForm({...authForm,[name]: value})
    }

    const submitHandler = e => {
        e.preventDefault();
        props.onAuth(authForm.email, authForm.password)
    }


    // let nbtries = localStorage.getItem('nbtries')
    // let mail = localStorage.getItem('mail')
    // if(mail == authForm.email){
    //     if(nbtries != null){
    //         if(nbtries > 2){
    //             notyf.error("ur account is blocked")
    //             setNbTriesError("ur account is blocked")
    //         }else{
    //             localStorage.setItem("nbtries", +nbtries+1)
    //             props.onAuth(authForm.email, authForm.password)
                
    //             setNbTriesError((2-nbtries) + " more attempt(s)")
    //         }
    //     }else{
    //         localStorage.setItem("nbtries", 1)
    //         localStorage.setItem("mail", authForm.email)
    //         props.onAuth(authForm.email, authForm.password)
    //     }
    // }else{
    //     localStorage.setItem("nbtries", 1)
    //     localStorage.setItem("mail", authForm.email)
    //     props.onAuth(authForm.email, authForm.password)
    //     setNbTriesError("2 more attempt(s)")
    // }

 
    return (
        <>
            {props.loading ? 
                <Spinner /> : 
                <Signin
                    changed={(e) => inputChangeHandler(e)}
                    submited={(e) => submitHandler(e)}
                    signinError={error}
                    authForm={authForm}
                     />}
        </>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated : state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    }
} 

const mapDispatchToProps = dispatch => {
    return {
        onAuth : (email, password) => dispatch(actions.auth(email, password)),
        onAuthSSO: (email) => dispatch(actions.authSSO(email)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
