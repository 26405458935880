import React from "react";
import {BndColor} from "./NewInfoband.styled";
import {EIFormLabel, InputCheckbox} from "../../NewDocumentation/NewDocumentation.styled";

function BandCouleurs({bandeau,setBandeau}) {

  const inputChangeHandler2 = (e) => {
    let value = e.target.value;
    let docTemp = {
      ...bandeau,
      couleur: value,
    };
    setBandeau(docTemp);
  };

  return (
    <>
      <EIFormLabel
        style={{ marginRight: "17%" }}
        for="lienc"
        className="accountRequest__canalItemLabel"
      >
        Couleur :
      </EIFormLabel>
      <div style={{display: "flex"}}>
        <InputCheckbox
          type="radio"
          style={{ marginRight: "10px" }}
          id="doc"
          name="web1"
          value="#ff0000"
          onChange={inputChangeHandler2}
          checked={bandeau.couleur == "#ff0000"}
        />
        <BndColor color={"#ff0000"}/> 
      </div>
      <div style={{display: "flex"}}>
        <InputCheckbox
          type="radio"
          style={{ marginRight: "10px" }}
          id="doc"
          name="web1"
          value="#920024"
          onChange={inputChangeHandler2}
          checked={bandeau.couleur == "#920024"}
        />
        <BndColor color={"#920024"}/> 
      </div>
      <div style={{display: "flex"}}>
        <InputCheckbox
          type="radio"
          style={{ marginRight: "10px" }}
          id="doc"
          name="web1"
          value="#3c0c44"
          onChange={inputChangeHandler2}
          checked={bandeau.couleur == "#3c0c44"}
        />
        <BndColor color={"#3c0c44"}/> 
      </div>
      <div style={{display: "flex"}}>
        <InputCheckbox
          type="radio"
          style={{ marginRight: "10px" }}
          id="doc"
          name="web1"
          value="#3391aa"
          onChange={inputChangeHandler2}
          checked={bandeau.couleur == "#3391aa"}
        />
        <BndColor color={"#3391aa"}/> 
      </div>
      <div style={{display: "flex"}} >
        <InputCheckbox
          type="radio"
          style={{ marginRight: "10px" }}
          id="lien"
          value="#ff7841"
          name="web1"
          onChange={inputChangeHandler2}
          checked={bandeau.couleur === "#ff7841"}
        />

      <BndColor color={"#ff7841"}/> 
      </div>

      <div style={{display: "flex"}}>
        <InputCheckbox
          type="radio"
          style={{ marginRight: "10px" }}
          id="doc"
          name="web1"
          value="#f2b999"
          onChange={inputChangeHandler2}
          checked={bandeau.couleur == "#f2b999"}
        />
        <BndColor color={"#f2b999"}/> 
      </div>
    </>
  );
}

export default BandCouleurs;
