import * as actionTypes from './actionTypes';
import Axios from '../../axios-proas';
const dateNew=new Date().getTime()
export const bandeauStart = () => ({
    type: actionTypes.BANDEAU_START
})

export const bandeauSetPage = (page) => ({
    type: actionTypes.BANDEAU_SET_PAGE,
    currentPage: page
})

export const bandeauSetPageSize = (pageSize) => ({
    type: actionTypes.BANDEAU_SET_PAGESIZE,
    pageSize: pageSize
})

export const bandeauSuccess = (data, count) => ({
    type: actionTypes.BANDEAU_SUCCESS,
    data: data,
    count: count
});

export const bandeauFail = (error) => ({
    type: actionTypes.BANDEAU_FAIL,
    error: error
})

export const bandeauGetPage = (page, filters, sort, reverse, pageSize = 100) => {
    let newFilters = {
        page: page,
        pageSize: pageSize
    }
    if(filters){ 
        for (const key in filters) {
            if(filters[key])
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    if(sort){
        newFilters = {
            ...newFilters,
            orderBy: sort
        }
    }
    if(reverse){
        newFilters = {
            ...newFilters,
            reverse: reverse[sort]
        }
    }
    //console.log(sort)
    return dispatch => {
        dispatch(bandeauStart());
        Axios.get('/user//allBandeaux', {
            params: {...newFilters, date: dateNew}
        })
        .then(response => {
            dispatch(bandeauSuccess(response.data.result, response.data.count))
        }).catch(err => {
            dispatch(bandeauFail(err.response?.data?.error))
        })
    }
}


export const bandeauSetFilterQuery = (filtersQuery) => ({
    type: actionTypes.BANDEAU_SET_FILTERSQUERY,
    filters: filtersQuery,
    filtering: bandeauUpdateFiltering(filtersQuery) 
});

export const bandeauUpdateFiltering = (filtersQuery) => {
    let filter = false;
    if(filtersQuery){
        for(const key in filtersQuery){
            if(filtersQuery[key]){
                filter = true
            }
        }
    }
    return filter
}

export const bandeauUpdateShowFilters = (show) => ({
    type: actionTypes.BANDEAU_UPDATE_SHOWFILTERS,
    showFilters: show
});

export const bandeauSetSortQuery = (sortQuery) => ({
    type: actionTypes.BANDEAU_SET_SORTQUERY,
    sortQuery: sortQuery
})

export const bandeauSetReverseSort = (reverse) => ({
    type: actionTypes.BANDEAU_SET_REVERSESORT,
    reverse: reverse
})