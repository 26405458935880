import styled from 'styled-components';

export const ISContainer = styled.div`
width:${props => props.width ? props.width : '30%'};
 
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 18px;
    padding: 2rem 1.5rem;
    height: 800px;
    display: flex;
    flex-direction: column;
`

export const ISNavContainer = styled.div`
    width: 100%;
    margin-bottom: 2rem;
`

export const ISNavContent = styled.div`
    width: 100%;
    // height: 90%;
    // flex: 1;
`