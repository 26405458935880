import React from 'react';
import Axios from "../../axios-proas";
import { useTranslation } from 'react-i18next';
import { getNotyfObject } from '../../shared/utility';

function Download(props) {
    const {fileName, url, clicked} = props;
    const notyf = getNotyfObject();
    const {t} = useTranslation();

    const clickedHandler = (e) => {
        e.preventDefault()
        Axios.get(url).then(response => {
            const linkSource = 'data:application/octet-stream;base64,'+response.data.content;
            const link = document.createElement("a");
            link.href = linkSource;
            link.download = fileName;
            link.click();
            if (clicked)
                clicked();
        }).catch(err => notyf.error(t("invoiceList:cantDownload")))
    }

    return <a href="#" onClick={(e) => clickedHandler(e)}>{fileName}</a>;
}

export default Download
