import React, { useCallback, useRef, useState } from 'react'
import Axios from '../../../axios-proas';
import FilterListIcon from '@mui/icons-material/FilterList';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ReplayIcon from '@mui/icons-material/Replay';
import { CSVLink } from "react-csv";
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { TableAction, TableActionLink, TAContainer, SearchInput, SearchInputContainer, } from '../../../styles/Table.styled';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import Select from '../../../components/UI/Select/Select';
import { getProfileWithKey, getRoleWithKey } from '../../../shared/utility';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableActions(props) {
    const {updateShowFilters, filterState,filtersQuery, sortQuery,reverse,selectedUsers,currentPage,pageSize, entityUid, userProfile, userType, userLevel, options, value, onChange,entityFilter ,resetData,setUsersQuickFilter,setUsersFilters,getUsersPage} = props;
    const [csvData, setCsvData] = useState();
    const csvBtn = useRef();
    const {t} = useTranslation();
    const theme = useTheme();

    const showFilters = () => {
        updateShowFilters(!filterState)
    }

    const csvHeaders = entityFilter === "owner" ? [
        {label: 'Prénom', key: 'first_name'},
        {label: 'Nom', key: 'last_name'},
        {label: 'Email', key: 'login'},
        {label: 'Actif', key: 'active'},
        {label: 'Rôle', key: 'level'},
        {label: 'Profil', key: 'profile'},
        {label: 'Clients', key: 'entities'},
        {label: 'Pôle/région', key: 'regions'}
    ] : [
        {label: 'Prénom', key: 'first_name'},
        {label: 'Nom', key: 'last_name'},
        {label: 'Email', key: 'login'},
        {label: 'Actif', key: 'active'},
        {label: 'Rôle', key: 'level'},
        {label: 'Fournisseurs', key: 'entities'},
        {label: 'Code Fournisseurs', key: 'entitycodes'}
    ];

    const exportSelectionCsv = useCallback(() => {
        let csvDataTemp = null;
        Axios.get('/user//csvCompressedDocument', {
            params: {
                selection: selectedUsers.toString()
            }
        })
            .then((response) => {
                csvDataTemp = response.data.content.map(row => {
                    return {
                        ...row,
                        profile: row.profile ? getProfileWithKey(row.profile) : null,
                        level: getRoleWithKey(row.level),
                    } 
                })
                setCsvData(csvDataTemp);
                csvBtn.current.link.click();
        }).catch(err => console.log(err.data))
    },[selectedUsers])


    const showCsvExport = () => {
        return selectedUsers.length > 0
    }

    const handleApplyFiltering = useCallback((filters) => {
        getUsersPage(1,filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const quickSearchChange = (quickSearchValue) => {
        let filtersTemp = {
            ...filtersQuery,
            quickSearch: quickSearchValue
        }
        setUsersQuickFilter('quickSearch')
        setUsersFilters(filtersTemp)
        if (quickSearchValue?.length >= 3)
            handleApplyFiltering(filtersTemp);
        else if (quickSearchValue?.length < 3 && filtersQuery.quickSearch?.length >= 3) {
            filtersTemp.quickSearch = "";
            handleApplyFiltering(filtersTemp);
        }
    }
    return (
        <TAContainer>
             <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder={t("global:search", "Search")+"..."}
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)} 
                        />
                        <SearchIcon />
                    </SearchInputContainer>
            {
                (userType== "supplier" && userLevel=="admin") && 
                    <div style={{display: "-webkit-box",
                        display: "-webkit-flex",
                        display: "-ms-flexbox",
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                        marginLeft: "1rem",
                        cursor: "pointer",
                        fontSize: "0.9rem"}}>
                            <Select
                                value={value} 
                                onChange={onChange}
                                options={options}
                            />
                    </div>
            }
            <TableAction title={t('global:resetFilters', "Reset filters")} onClick={() => resetData()}>
                {/* <span>{t("global:Rest")} </span> */}
            <ReplayIcon />
            </TableAction>
            <TableAction active={filterState} onClick={() => showFilters()} >
                <span>{t('global:filters', "Filters")}</span>
                <FilterListIcon   />
            </TableAction>
            <CTooltip title={showCsvExport() ? "" : t("tableActions:pleaseSelectOneForExport", "Please select at least one row in order to perform the export")}>
                <TableAction onClick={() => {if(showCsvExport()) exportSelectionCsv()}} disabled={!showCsvExport()}>
                    <span style={{color: showCsvExport() ? "black" : "#e0e0e0"}}>{t("global:csvExport", "CSV export")}</span>
                    <ImportExportIcon />
                </TableAction>
            </CTooltip>
            {userLevel === "admin" && (userProfile === "ADMIN" || userType === entityFilter) && (
            
      
            <TableActionLink 
                    color={theme.colors.primary} 
                    to="/userForm"
                    state={{entityUid: entityUid, entityFilter: entityFilter}}
                    >
                    <span>{t('tableActions:addUser',"Ajouter un utilisateur" )}</span>
                    <AddIcon />
            </TableActionLink>
            )
            }

                {csvData ? (<CSVLink
                    ref={csvBtn}
                    data={csvData}
                    filename={"Utilisateurs.csv"}
                    headers={csvHeaders}
                    separator={";"}>
                </CSVLink>) : null}
            
                
             
        </TAContainer>
    )
}

const mapStateToProps = (state) => ({
    selectedUsers: state.users.selectedUsers,
    filterState: state.users.showFilters,
    userLevel: state.auth.userLevel,
    userProfile: state.auth.profile,
    currentPage: state.users.currentPage,
    pageSize: state.users.pageSize, 
    filtersQuery: state.users.filtersQuery,
    filtering: state.users.filtering,   
    sortQuery: state.users.sortQuery,
    reverse: state.users.reverse,     
})
const mapDispatchToProps = dispatch => ({
    updateShowFilters: (show) => dispatch(actions.usersUpdateShowFilters(show)),
    setUsersFilters: (filters) => dispatch(actions.usersSetFilterQuery(filters)),
    setUsersQuickFilter: (quickFilterField) => dispatch(actions.usersSetQuickFilter(quickFilterField)),
    getUsersPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.usersGetPage(page, filters, sort, reverse, pageSize)),
})
export default connect(mapStateToProps, mapDispatchToProps) (TableActions)




