import React, { useRef, useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { TableAction, TAContainer } from '../../../styles/Table.styled.js';
import { CTooltip } from '../../UI/CTooltip/CTooltip.js';
import { getNotyfObject } from '../../../shared/utility.js';
import Axios from "../../../axios-proas";
import '../../UI/Spinner/Spinner.css';

function TableActions(props) {
    const {resetFilters, setShowFilters, showFilters, setShowNonEngagingModal, exportXlsx, refreshTable, selectedRows} = props;
    const {t} = useTranslation();
    const uploader = useRef();
    const notyf = getNotyfObject();
    const [importing, setImporting] = useState(false);

    const hasSelection = () => {
        return selectedRows ? Object.keys(selectedRows).filter(r => selectedRows[r]).length > 0 : false;
    }

    const handleXlsxImport = (file) => {
        if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const formData = new FormData();
            formData.append('file', file);
            setImporting(true);
            Axios.post("nonengaging//importXlsx", formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(response => {
                if (response.data.success) {
                    refreshTable();
                    notyf.success("Succès de l'import : "+response.data.counterUpdated+" ligne(s) mise(s) à jour, "+response.data.counterInserted+" ligne(s) ajoutée(s)");
                }
            }).catch((err) => {
                notyf.error("Échec de l'import" + (err.response.data.shownError ? " - " +err.response.data.shownError : ""));
            }).finally(() => {
                setImporting(false);
                uploader.current.value = "";
            })
        } else {
            notyf.error("Échec de l'import : le fichier n'est pas un XLSX");
            uploader.current.value = "";
        }
    }

    return (
        <TAContainer>
            <TableAction title="Réinitialiser les filtres" onClick={() => resetFilters()}>
                <ReplayIcon />
            </TableAction>
            <TableAction onClick={() => setShowFilters(!showFilters)}>
                <span> {t("global:filters")}</span>
                <FilterListIcon/>
            </TableAction>
            <CTooltip title={"Exporter en format Excel " + (hasSelection() ? "votre sélection" : "le résultat de votre recherche")}><TableAction onClick={() => exportXlsx()}>
                <span>Exporter</span>
            </TableAction></CTooltip>
            <CTooltip title={importing ? "Import en cours" : "Permet d'importer des lignes en masse avec un fichier au même format que le fichier d'export"}>
                <TableAction onClick={() => {if(!importing) uploader.current.click()}}>
                    {importing ? <div className="loader" style={{fontSize: "3px"}}></div> : <span>Importer</span>}
                </TableAction>
            </CTooltip>
            <input type="file" style={{display:'none'}} ref={uploader} onChange={(e) => handleXlsxImport(e.target.files[0])} accept=".xlsx" /> 
            <TableAction onClick={() => setShowNonEngagingModal({})}>
                <span>Ajouter une ligne</span>
                <AddIcon />
            </TableAction>
        </TAContainer>
    )
}

export default TableActions
