import React, { useState, useEffect } from 'react'
import { EntContainer, Table, EntContent, EntDivContainer, TitleDiv, THead, LITableContainer } from './PendingAndTreatedRejectsPerManager.styled'
import { TBody, TH, TR, TD, THContainer } from '../../../styles/Table.styled'
import Axios from '../../../axios-proas';
import { formatDate } from '../../../shared/utility';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip'
import DateRange from '../../../components/UI/DateRange/DateRange';
import RejectsPerManagerDetailModal from './RejectsPerManagerDetailModal/RejectsPerManagerDetailModal';

function PendingAndTreatedRejectsPerManager() {
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [columnSort, setColumnSort] = useState("login");
    const [reverse, setReverse] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    const [showLineDetails, setShowLineDetails] = useState(false);

    useEffect(() => {
        if (fromDate && toDate)
            Axios.get("reject/"+fromDate+":"+toDate+"/pendingAndTreatedRejectsPerManager").then(response => {
                setData(response.data);
            })
        else
            setData([]);
    }, [fromDate, toDate])

    const compare = (d1, d2, reverse) => {
        if (isNaN(d1) || isNaN(d2)) {
            if (!reverse && d1 < d2 || reverse && d1 > d2)
                return -1;
            if (!reverse && d1 > d2 || reverse && d1 < d2)
                return 1;
            return 0;
        } else {
            if (!reverse && +d1 < +d2 || reverse && +d1 > +d2)
                return -1;
            if (!reverse && +d1 > +d2 || reverse && +d1 < +d2)
                return 1;
            return 0;
        }
    }

    useEffect(() => {
        const dataTemp = [...data];
        dataTemp.sort((d1, d2) => compare(d1[columnSort], d2[columnSort], reverse));
        setData(dataTemp);
    }, [columnSort, reverse])

    const sort = column => {
        if (columnSort === column)
            setReverse(!reverse);
        else {
            setReverse(false);
            setColumnSort(column);
        }
    }

    const dateChangeHandler = (date) => {
        setFromDate(date.startDate.getTime());
        setToDate(date.endDate.getTime());
    }

    return (
        <EntDivContainer>
            <TitleDiv>
                <CTooltip title={<div>Contient les rejets de type :<ul><li>err01</li><li>err02</li><li>err03</li><li>err04</li><li>err04a</li><li>err04b</li><li>err05</li><li>err06</li><li>err07</li><li>err08</li><li>err09</li><li>err10</li><li>err11</li><li>err12</li><li>err51</li><li>err52</li><li>err99</li></ul></div>}><span>{"Rejets en cours et traités par responsable"}</span></CTooltip>
            </TitleDiv>
            <table>
                <tbody>
                    <tr>
                        <td>Du</td>
                        <td><input type="text" className="form-control" defaultValue={formatDate(fromDate)} onClick={() => setShowDateRange(true)} /></td>
                        <td>au</td>
                        <td><input type="text" className="form-control" defaultValue={formatDate(toDate)} onClick={() => setShowDateRange(true)} /></td>
                    </tr>
                </tbody>
            </table>
            
            <DateRange
                title="Choisir une période"
                show={showDateRange} 
                format={formatDate} 
                clicked={() => setShowDateRange(false)} 
                changed={date => dateChangeHandler(date)} 
                field="period"
            />
            <EntContainer>
                <EntContent>
                    <LITableContainer style={{overflow: "auto", height: "307px"}}>
                        <Table>
                            <THead>
                                <tr>
                                    <TH scope="col" width={"40%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }} onClick={() => sort("login")}>
                                        <THContainer>
                                            <span>Gestionnaire</span>
                                            {"login" === columnSort ? 
                                                (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                null
                                            }
                                        </THContainer>
                                    </TH>
                                    <TH scope="col" width={"30%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }} onClick={() => sort("pending")}>
                                        <THContainer>
                                            <span>Rejets en cours</span>
                                            {"pending" === columnSort ? 
                                                (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                null
                                            }
                                        </THContainer>
                                    </TH>
                                    <TH scope="col" width={"30%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }} onClick={() => sort("treated")}>
                                        <THContainer>
                                            <span>Rejets clôturés</span>
                                            {"treated" === columnSort ? 
                                                (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                null
                                            }
                                        </THContainer>
                                    </TH>
                                </tr>
                            </THead>
                            <TBody>
                                {data?.length > 0 ? data?.map(row => {
                                    return <TR key={row.login} onClick={() => setShowLineDetails(row.login)}>
                                        <TD><CTooltip title={row.login || ""}><span>{row.login}</span></CTooltip></TD>
                                        <TD><CTooltip title={row.pending || ""}><span>{row.pending}</span></CTooltip></TD>
                                        <TD><CTooltip title={row.treated || ""}><span>{row.treated}</span></CTooltip></TD>
                                    </TR>
                                }) :
                                <tr>
                                    <td colSpan={3} style={{textAlign: "center", padding:"40px 0", fontSize: "large"}}>Veuillez choisir une date de début et une date de fin</td>
                                </tr>
                                }
                            </TBody>
                        </Table>
                    </LITableContainer>
                </EntContent>
            </EntContainer>
            <RejectsPerManagerDetailModal show={showLineDetails} handleClose={() => setShowLineDetails(false)} period={fromDate+":"+toDate} />
        </EntDivContainer>
    )
}

export default PendingAndTreatedRejectsPerManager
