import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { TEContainer, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';

function FutureValidatedEvent({eventData, validated}) {
    const {t} = useTranslation();

    return (
        <>
            <TEContainer>
                <TEHeader>
                    <TEIcon borderBold={true} bdColor={validated ? "#00B894" : "orange"} color={validated ? "#00B894" : "orange"} >
                        {validated ? <CheckIcon /> : <ClearIcon />}
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={validated ? "#00B894" : "orange"}>{"Facture post-datée " + (validated ? "validée" : "invalidée")}</TETitle>
                        <TETime>
                            {eventData?.user ? (eventData?.user?.first_name || eventData?.user?.last_name ? (eventData?.user?.first_name || "") + " " + (eventData?.user?.last_name || "").trim() : eventData?.user?.login) + "\r\n" : (eventData?.noUser ? getLabelWithKey(eventData?.noUser) + "\r\n" : null)}
                            {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
            </TEContainer>
        </>
    )
}

export default FutureValidatedEvent