import styled from 'styled-components/macro';

export const TableErrDiv = styled.div`
   margin:4px ;
   overflow-y:auto ;
`

export const TableErrContainer = styled.ul`
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    width: ${({width}) => width};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    margin-right:7px ;
`

export const TD = styled.td`
    padding: 0.25rem;

    & > span {
        display: block;
        width: 100%;
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`
export const TBody = styled.tbody`
    width: 100%;
    font-size: 0.9rem;
    color: ${({theme}) => theme.colors.text};

    tr {
        border-bottom: 0.0rem solid white;
    }
& TD:first-child {
   text-align:start ;
}
    & ${TD} {
        width: 100%;
        vertical-align: middle;
        height: ${({height}) => height ? height : '100%'};
        text-align: center;
    }
`;
