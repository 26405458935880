import * as actionTypes from './actionTypes';
import Axios from '../../axios-proas';

export const auditTrailStart = () => ({
    type: actionTypes.AUDIT_TRAIL_START
})

export const auditTrailSetPage = (page) => ({
    type: actionTypes.AUDIT_TRAIL_SET_PAGE,
    currentPage: page
})

export const auditTrailSetPageSize = (pageSize) => ({
    type: actionTypes.AUDIT_TRAIL_SET_PAGESIZE,
    pageSize: pageSize
})

export const auditTrailSuccess = (data, count) => ({
    type: actionTypes.AUDIT_TRAIL_SUCCESS,
    data: data,
    count: count
});

export const auditTrailFail = (error) => ({
    type: actionTypes.AUDIT_TRAIL_FAIL,
    error: error
})

export const auditTrailGetPage = (page, filters, pageSize) => {
    let newFilters = {
        page: page,
        pageSize: pageSize
    }
    if(filters){ 
        for (const key in filters) {
            if(filters[key])
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    //console.log(sort)
    return dispatch => {
        dispatch(auditTrailStart());
        Axios.get('/audittrail', {
            params: {...newFilters}
        })
        .then(response => {
            dispatch(auditTrailSuccess(response.data.result, response.data.count))
        }).catch(err => {
            dispatch(auditTrailFail(err.response?.data?.error))
        })
    }
}

export const auditTrailSetFilterQuery = (filtersQuery) => ({
    type: actionTypes.AUDIT_TRAIL_SET_FILTERSQUERY,
    filters: filtersQuery,
    filtering: auditTrailUpdateFiltering(filtersQuery) 
});

export const auditTrailUpdateFiltering = (filtersQuery) => {
    let filter = false;
    if(filtersQuery){
        for(const key in filtersQuery){
            if(filtersQuery[key]){
                filter = true
            }
        }
    }
    return filter
}