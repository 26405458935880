import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import "../Select.css"
import { BtnOutlineAction, Header, HeaderActions } from '../../../styles/Common';
import Axios from '../../../axios-proas'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTheme } from 'styled-components';
import EntitiesComponent from '../../../components/UI/EntitiesComponent/EntitiesComponent';
import { CFBody, CFContainer, CFFormGroup, CFFormSection, CFSection, FormInput, FormLabel } from "./NewGroupingForm.styled";
import { useNavigate } from "react-router-dom";
import { getNotyfObject } from '../../../shared/utility';
import MandatoryMarker from '../../../components/UI/MandatoryMarker';
function NewGroupingForm({
        entitiesData,
        getEntitiesPage
    }) {
    const { t } = useTranslation();
    const theme = useTheme()
    let notyf = getNotyfObject();
    // const [lastAction, setLastAction] = React.useState(null);
    const [errors, setErrors] = useState([])
    const [selectValue, setSelectValue] = useState([]);
    const [dataForSelect, setDataForSelect] = useState([]);
    const [dataForSelectCopie, setDataForSelectCopie] = useState([]);
    const [groupingEntities, setGroupingEntities] = useState([]);
    const navigate = useNavigate();
    const [titleRegroupement, setTitleRegroupement] = useState("");


    useEffect(() => {
        getEntitiesPage(1,null,null,null,100)
        console.log(entitiesData)
        let li = [];
        entitiesData?.map((item, i) => {
            let p = {
                value: item.uid,
                label: item.code + " (" + item.name + ")"
            };
            li = li.concat(p);
        });
        setDataForSelect(li);
        setDataForSelectCopie(li);
    }, []);

    const typeACChangeHandler = (e) => {
        console.log(e)
        setSelectValue(e);
    };

    const checkFormValidity = () => {
        let errors = []
        if(titleRegroupement == "")
            errors.push(t("masterData:e", "Le champ 'Nom du groupe' est vide")) ;
        if( groupingEntities.length == 0)
            errors.push(t("masterData:e", "Aucune entité n'a été sélectionnée !"));
        
        return errors;
    }


    const titleChangeHandler = (e) => {
        setTitleRegroupement(e.target.value);
    }


    const deleteEntityclickHandler = (entity) => {
        // check current entities
        let entitiesTmp = [...groupingEntities]
        let entitiesTmpIndex = entitiesTmp.findIndex(ent => ent.uid === entity.uid);
        if (entitiesTmpIndex >= 0) {
          entitiesTmp.splice(entitiesTmpIndex, 1)
          setGroupingEntities(entitiesTmp)
        }
      }
    
      const addEntityclickHandler = (newEntities) => {
        let entitiesTmp = [...groupingEntities]
        newEntities.forEach(entity => {
            // check current entities
            let entitiesTmpIndex = entitiesTmp.findIndex(ent => ent.uid === entity.uid);
            if (entitiesTmpIndex == -1) {
              entitiesTmp.push(entity)
            }
          });
          setGroupingEntities(entitiesTmp)
    
      }


    const addRegroupment = () => {
        // console.log(selectValue)
        let errors = checkFormValidity()
        if(errors.length == 0){
            setErrors([])
            Axios.post("/regroupement", { groupingEntities, titleRegroupement }).then((res) => {
                console.log(res);
                notyf.success(t("default:us", "Le groupe '"+titleRegroupement+"' a bien été enregistré"))
                navigate(-1)
            }).catch((err) => {
                console.log(err);
            });
        }else 
            setErrors(errors)
    }

    return (
        <>
            <CFContainer>
                <Header>
                    <HeaderActions>
                        <BtnOutlineAction
                            border={theme.colors.success}
                            color={theme.colors.success}
                            onClick={addRegroupment}
                            >
                            <span>Valider</span>
                        </BtnOutlineAction>
                    </HeaderActions>
                </Header>

                <CFBody>
                    {
                        errors && errors.length > 0 ? (
                            <div className="alert alert-danger mt-2" role="alert">
                                <ul>
                                    {
                                        errors.map(error => (<li key={error}>{error}</li>))
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                    <CFSection>
                        <CFFormSection>
                            <CFFormGroup style={{ marginBottom: "2.2rem" }}>
                                <FormLabel htmlFor="titleg">Nom de groupe<MandatoryMarker/> :</FormLabel>
                                <FormInput
                                    id="titleg"
                                    type="text"
                                    placeHolder="Titre"
                                    onChange={e => titleChangeHandler(e)}
                                    maxLength={255}
                                    name="title"
                                />
                            </CFFormGroup>

                            {/* <Select
                                style={{ height: "fit-content" }}
                                name="mailType"
                                // value={email?.mailType || ""}
                                onChange={(e) => typeACChangeHandler(e)}
                                placeholder={"Choisir une entité"}
                                noOptionsMessage={() => "Aucun résultat"}
                                // suggestions={emailTypesSuggestions}
                                // onSelect={onSelectTypeACHandler}
                                // isLoading={!email?.mailType? true:false}
                                isClearable={true}
                                isRtl={false}
                                isMulti
                                options={dataForSelect}
                            /> */}

                            <EntitiesComponent
                                show={true}
                                label={"Liste des entités"}
                                modalTitle={"Associer des sociétés au regroupement"}
                                entities={groupingEntities}
                                deleteEntity={deleteEntityclickHandler}
                                addEntity={addEntityclickHandler}
                                entitiesType={"owner"}
                                isMandatory={true}
                            />

                        </CFFormSection>
                    </CFSection>
                </CFBody>
            </CFContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    entitiesData: state.entities.data
})
const mapDispatchToProps = dispatch => ({
    getEntitiesPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.entitiesGetPage(page, null, null, null, 100))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupingForm)