import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { TH, THContainer, THead } from '../../../styles/Table.styled';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableHead(props) {
    const {columns, columnClickHandler, reverse, entityFilter, suppliersFilters} = props;
    
    return (
        <THead>
            <tr>
                {columns.filter(row=>row.show==true).map(column => (  
                    <TH 
                        className={suppliersFilters?.[column.field] && 'filtered'}
                        key={column.field} 
                        width={column.width}
                        scope="col" 
                        id={column.field} 
                        onClick={() => columnClickHandler(column.field, reverse)}
                    >
                        <CTooltip title={column.title}>
                            <THContainer>
                                <span>
                                    {column.title}
                                </span>
                                <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none'}} />
                                <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none'}}/>
                            </THContainer>   
                        </CTooltip>  
                    </TH>
                ))}
                {
                    entityFilter != "client" ? 
                        <TH key={"column1453"} style={{width: "14%"}}>
                            <CTooltip title={"Coordonnées des contacts fournisseurs"}>
                                <THContainer>
                                    <span>Utilisateur</span>
                                </THContainer>
                            </CTooltip>
                        </TH>
                        :null
                }
            </tr>
        </THead>
    )
}

export default TableHead
