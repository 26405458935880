import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    quickFilter: null,
    sortQuery: 'creationDate',
    reverse: {
        name: false
    },
    selectedRows: null,
    selectedAllRows: false
}

const unknownInvoiceStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const unknownInvoiceSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false
})

const unknownInvoiceFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const unknownInvoiceSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage

})
const unknownInvoiceSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const unknownInvoiceSetSortQuery = (state, action) => updateObject(state,{
    sortQuery: action.sortQuery
})

const unknownInvoiceSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: {...action.reverse}
    }
}

const unknownInvoiceUpdateShowFilters = (state, action) => {
    return updateObject(state, {showFilters: action.showFilters})
}

const unknownInvoiceSetFiltersQuery = (state, action) => updateObject(state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)

const unknownInvoiceSetQuickFilter = (state, action) => updateObject(state, {
    quickFilter : action.quickFilter
})

const unknownInvoiceSelectRow = (state, action) => {
    let newSelectedRows = {...state.selectedRows, [action.rowId]: action.selected}
    return updateObject(state, {
        selectedRows: newSelectedRows
    })
}

const unknownInvoiceSelectAllRows = (state, action) => {
    let data = state.data;
    let newSelectedRows = {}
    data?.map(row => {
        newSelectedRows = {...newSelectedRows, [row.uid]: action.selectedAll}
    })
    return updateObject(state, {
        selectedAllRows: action.selectedAll,
        selectedRows: newSelectedRows
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.UNKNOWN_INVOICES_START: return unknownInvoiceStart(state, action)
        case actionTypes.UNKNOWN_INVOICES_SUCCESS: return unknownInvoiceSuccess(state, action)
        case actionTypes.UNKNOWN_INVOICES_FAIL: return unknownInvoiceFail(state, action)
        case actionTypes.UNKNOWN_INVOICES_SET_PAGE: return unknownInvoiceSetPage(state, action)
        case actionTypes.UNKNOWN_INVOICES_SET_PAGESIZE: return unknownInvoiceSetPageSize(state, action)
        case actionTypes.UNKNOWN_INVOICES_SET_FILTERSQUERY: return unknownInvoiceSetFiltersQuery(state, action)
        case actionTypes.UNKNOWN_INVOICES_SET_SORTQUERY: return unknownInvoiceSetSortQuery(state, action)
        case actionTypes.UNKNOWN_INVOICES_SET_REVERSESORT: return unknownInvoiceSetReverseSort(state, action)
        case actionTypes.UNKNOWN_INVOICES_UPDATE_SHOWFILTERS: return unknownInvoiceUpdateShowFilters(state, action)
        case actionTypes.UNKNOWN_INVOICES_SET_QUICKFILTER: return unknownInvoiceSetQuickFilter(state, action)
        case actionTypes.UNKNOWN_INVOICES_SELECT_ROW: return unknownInvoiceSelectRow(state, action)
        case actionTypes.UNKNOWN_INVOICES_SELECT_ALL_ROWS: return unknownInvoiceSelectAllRows(state, action)
        default:
            return state
    }
}
export default reducer