import React, {useState, useCallback, useEffect} from 'react'
import Axios from '../../axios-proas';
import { useTranslation } from 'react-i18next';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { SGBody, SGCol, SGColTitle, SGContainer, SGFormGroup, SGSection } from './Settings.styled';
import { BtnOutlineAction, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common';
import { useTheme } from 'styled-components';
import { getNotyfObject } from '../../shared/utility';

function Settings() {
    const [settings, setSettings] = useState(null);
    const [updated , setUpdated] = useState(false);
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        Axios.get('/settings').then(response => {
            let tsettings = response.data.tsettings;
            let tsettingsMail = response.data.tsettingsMail;
            let newTSettings = {}
            let newTSettingsM = {}
            tsettings.map(row => {
                let key = row.key;
                let value = row.value
                newTSettings = {
                    ...newTSettings,
                    [key]: value
                }
            })
            tsettingsMail.map(row => {
                let key = row.key;
                let fr_value = row.fr_value;
                let en_value = row.en_value;
                let de_value = row.de_value;
                let it_value = row.it_value;
                let es_value = row.es_value;
                newTSettingsM = {
                    ...newTSettingsM,
                    [key]: {
                        de_value,
                        en_value,
                        fr_value,
                        it_value,
                        es_value
                    }
                }
            })
            setSettings({
                tsettings : newTSettings, 
                tsettingsMail:newTSettingsM
            })
        })
        
    }, [updated])

    const inputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let tsettingsNew = settings?.tsettings ? {
            ...settings.tsettings,
            [name] : value
        } : {[name] : value }
        setSettings({
            ...settings,
            tsettings: tsettingsNew 
        })
    }

    const editSettings = useCallback((e,settings, newLogo) => {
        e.preventDefault()
        Axios.put('/settings//settings', settings).then(response => {
            setUpdated(true)
            notyf.success(t("settings:savedSettings"));
        })
    }, [settings])


    return (
        <>
            <SGContainer>
                <Header>
                    <HeaderActions>
                        <BtnOutlineAction
                            color={theme.colors.success}
                            border={theme.colors.success}
                            onClick={(e) => editSettings(e,settings)}
                        >
                            <span>{t("global:save")}</span>
                        </BtnOutlineAction>
                    </HeaderActions>
                </Header>

                <SGBody>
                    <SGSection>
                        <SGCol>
                            <SGColTitle>Configuration du serveur SMTP</SGColTitle>
                            <SGFormGroup>
                                <FormLabel htmlFor="smtpLogin" >{t("settings:smtpLogin")} </FormLabel>
                                <FormInput 
                                    id="smtpLogin"
                                    type="text" 
                                    placeHolder={t("settings:smtpLogin")} 
                                    value={settings?.tsettings.smtpLogin || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="smtpLogin" 
                                />
                            </SGFormGroup>
                            <SGFormGroup>
                                <FormLabel htmlFor="smtpPassword" >{t("settings:smtpPassword")} </FormLabel>
                                <FormInput 
                                    id="smtpPassword"
                                    type="password" 
                                    placeHolder={t("settings:smtpPassword")} 
                                    value={settings?.tsettings.smtpPassword || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="smtpPassword" 
                                />
                            </SGFormGroup>
                            <SGFormGroup>
                                <FormLabel htmlFor="smtpPort" >{t("settings:smtpPort")} </FormLabel>
                                <FormInput 
                                    id="smtpPort"
                                    type="text" 
                                    placeHolder={t("settings:smtpPort")} 
                                    value={settings?.tsettings.smtpPort || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="smtpPort" 
                                />
                            </SGFormGroup>
                            <SGFormGroup>
                                <FormLabel htmlFor="smtpHost" >{t("settings:smtpHost")} </FormLabel>
                                <FormInput 
                                    id="smtpHost"
                                    type="text" 
                                    placeHolder={t("settings:smtpHost")} 
                                    value={settings?.tsettings.smtpHost || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="smtpHost" 
                                />
                            </SGFormGroup>
                            <SGFormGroup>
                                <FormLabel htmlFor="emailFrom" >{t("settings:sourceEmail")} </FormLabel>
                                <FormInput 
                                    id="emailFrom"
                                    type="text" 
                                    placeHolder={t("settings:sourceEmail")} 
                                    value={settings?.tsettings.emailFrom || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="emailFrom" 
                                />
                            </SGFormGroup>
                        </SGCol>
                        <SGCol>
                            <SGColTitle>{t("settings:manageReminders")}</SGColTitle>
                            <SGFormGroup>
                                <FormLabel htmlFor="reminder" >{t("settings:reminders")} </FormLabel>
                                <FormInput 
                                    id="reminder"
                                    type="text" 
                                    placeHolder={t("settings:reminders")} 
                                    value={settings?.tsettings.reminder || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="reminder" 
                                />
                            </SGFormGroup>
                        </SGCol>
                    </SGSection>
                </SGBody>
            </SGContainer>
    </>
    )
}

export default Settings
