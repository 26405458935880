import React, { useCallback, useEffect, useState } from 'react'
import './GroupUserModal.css'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function GroupUserModal({handleClose, show, addGroupsUser, userGroups,deleteGroup,groupsCopie , groupsToDelete }) {
    const [groups , setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState(userGroups ? userGroups : []);
    const [searchQuery, setSearchQuery] = useState(null)
    const [selectAll, setSelectAll] = useState(null)
    const {t} = useTranslation();
    const [entitiesToDeleteCopie,setEntitiesToDeleteCopie] = useState(groupsToDelete ? groupsToDelete : [])
    const getGroupsList = useCallback(() => {
        let filter = {
            title: searchQuery,
            pageSize: 9999999
        }
        Axios.get('/regroupement', {
            params: filter
        })
        .then(res => {
            setGroups(res.data?.result)
        })
    }, [searchQuery])

    useEffect(() => {
        getGroupsList()
    }, [searchQuery])
    

    // const selectGroupff = group => {
    //     let groupIndex = selectedGroups.findIndex(row => row.uid === group.uid);
    //     let selectedTemp = [...selectedGroups];
    //     if(groupIndex >= 0){

    //         selectedTemp.splice(groupIndex, 1)
    //         let tmp = userGroups.filter(row => row.uid === group.uid);
        
    //         // if(tmp.length > 0){
    //         //     deleteGroup(tmp[0])
    //         // }
    //     }
    //     else 
    //         selectedTemp = [...selectedGroups, group];
    //     setSelectedGroups(selectedTemp);
    // }

    const selectGroup = (entity) => {
        let entityIndex = selectedGroups.findIndex(row => row.uid === entity.uid);
        let entityDeleteIndex = entitiesToDeleteCopie.findIndex(row => row=== entity.uid);
        let selectedTemp = [...selectedGroups];
        let entitiesToDeleteTemp = [...entitiesToDeleteCopie];
        if(entityIndex >= 0){
            selectedTemp.splice(entityIndex,1)
            entitiesToDeleteTemp=[...entitiesToDeleteCopie,entity.uid]
        }
        else {
            selectedTemp = [...selectedGroups, entity];
            if(entityDeleteIndex >= 0)
                entitiesToDeleteTemp.splice(entityDeleteIndex,1)
        }
           
            setSelectedGroups(selectedTemp);
            setEntitiesToDeleteCopie(entitiesToDeleteTemp)
    }

    const selectAllGroups = () => {
        let selectedTemp = [...selectedGroups];
        if (selectAll)
            groups.forEach(g => {
                if (!selectedTemp?.find(s => s.uid === g.uid))
                    selectedTemp.push({uid: g.uid, title: g.title, new: true})
            })
        setSelectedGroups(selectedTemp)
    }

    useEffect(() => {
        if (selectAll !== null)
            selectAllGroups()
    }, [selectAll])

    const addClickHandler = () => {

        addGroupsUser(selectedGroups,entitiesToDeleteCopie)
        handleClose()
    }

    const existingEntity = (entity) => {
        return groupsCopie?.findIndex(row => row.uid === entity.uid) >= 0
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>Associer des groupements d'entité à l'utilisateur</Modal.Title>
                </Modal.Header>
                <Modal.Body className="groupUserModal__body" style={{minWidth: "40rem"}}>
                    <div className="groupUserModal__header">
                        <div class=" input-group input-group-sm">
                            <input 
                                type="text" 
                                class="form-control" 
                                placeholder={t('masterData:search',"Rechercher")}  
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                value={searchQuery || ''}
                            />
                            <div class="input-group-append" >
                                <span class="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                    </div>
                    <div className="groupUserModal_items bg-white">
                        <table class="table groupUserModal__table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col" style={{width: "10%"}}>
                                        <div className="columnHead__container" style={{justifyContent: "center"}}>
                                            <div className="columnHead__title">
                                                <CheckIcon onClick={() => setSelectAll(selectAll === null ? true : !selectAll)} />
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" style={{width: "90%"}}>Nom </th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    groups?.length > 0 ? groups.map(group => (
                                        <tr>
                                            <td>
                                                <div className="productsModal__itemIcon" onClick={() => selectGroup({uid : group.uid, title : group.title, new : !existingEntity(group)})}>
                                                    {
                                                        selectedGroups.some(row => row.uid === group.uid) ? (
                                                            <ThumbUpIcon style={{cursor: "pointer", color: "rgb(86,41,111)"}} />
                                                        ) : <AddIcon style={{cursor: "pointer", color: "#f00"}} />
                                                    }
                                                </div>
                                            </td>
                                            <td>{group.title} </td>
                                        </tr>
                                    )) : <div style={{whiteSpace: "nowrap"}}>
                                            Aucune entreprise à afficher
                                        </div>
                                }
                            </tbody>
                        </table>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:associate', "Associer")} 
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({ 
})
const mapDispatchToProps = dispatch => ({
})  
export default connect(mapStateToProps, mapDispatchToProps)(GroupUserModal)
