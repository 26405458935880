import React, { useMemo,useState } from 'react';
import { formatDate, getNotyfObject } from '../../../shared/utility';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import StatusProgressLine from '../StatusProgressLine/StatusProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink } from './CampaignItem.styled';
import GroupIcon from '@mui/icons-material/Group';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal'
import Axios from '../../../axios-proas'
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';

function BandeauItem({bandeau,getBandeau}) {
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
  
     const [confirmationModalShow, setConfirmationModalShow] = useState(false);
     const notyf = getNotyfObject();
     
    let status = null;
    const currentDate = new Date().getTime();
    if (currentDate < +bandeau?.startDate)
        status = "scheduled";
    else if (currentDate >= +bandeau?.startDate && currentDate <= +bandeau?.endDate)
        status = "inProgress";
    else if (currentDate > +bandeau?.endDate)
        status = "completed";

     const deleteCompagneClickHandler = (Uid) => {
        
        Axios.delete(`user/${Uid}/deleteBandeau`).then(response => {
            getBandeau()
           console.log(Uid)
           setConfirmationModalShow(false)
            notyf.success("Le bandeau a bien été supprimé")
        }).catch(response => {
           
            notyf.error("generatedErrorMessage")
        })
    }
    return (
        <>
        <CIContainer>
            <CITitle>
                {bandeau?.title}
            </CITitle>
            <CIContent>
                <CIData>
                    <DateProgressLine
                        creationDate={+bandeau?.creationDate}
                        campaignDate={+bandeau?.startDate}
                        endDate={+bandeau?.endDate}
                    />
                        <StatusProgressLine
                        startDate={bandeau?.startDate}
                        endDate={+bandeau?.endDate}
                    />
                    {status !== "scheduled" && <CTooltip title={bandeau.readTotal + " utilisateur(s) ont lu ce bandeau"}>
                        <div>
                            <span style={{cursor:"pointer",marginTop:"6px"}}>{bandeau.readTotal}</span>
                            <VisibilityIcon style={{margin:"8px",cursor:"pointer"}}/>
                        </div>
                    </CTooltip>}
                </CIData>
                <CIActions>
                    {/* <CIUsersLink 
                        to={`#`}  
                    >
                            {campaign?.recipients?.split(',').length} 
                        <GroupIcon style={{fontSize: "1.2rem", marginLeft: "2px"}} />
                    </CIUsersLink> */}

                    <CIDeleteBtn   onClick={() => setConfirmationModalShow(true)}
                                    color="#ff0000"
                                    border="#FF0000">
                        Supprimer
                    </CIDeleteBtn>
                    
                
                    <CIUsersLink
                        style={{marginLeft: "5px"}}
                        color="#fff"
                        border="#FFF"
                        to={"/newInfoband/"+bandeau?.uid}
                      
                        > Voir  <ArrowRightIcon/>
                    
                     </CIUsersLink>
                   
                      
                </CIActions>
            </CIContent>
           
        </CIContainer>
         <ConfirmationModal 
         show={confirmationModalShow}
         modalClosed={() => setConfirmationModalShow(false)}
         confirm={()=>deleteCompagneClickHandler(bandeau?.uid)}
         cancel={() => setConfirmationModalShow(false)}
         title={"Confirmation de suppression"}
         message={`Êtes-vous sûr de vouloir supprimer ce bandeau ?`}
/>
</>
    );
}

export default BandeauItem;
