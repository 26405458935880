import React, { useEffect, useState } from 'react'
import MessageIcon from '@mui/icons-material/Message';
import {formatDate, getTimeDate} from '../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, CloseDiv,TEPreview, TETime, TETitle,AllContainer } from './Timeline.styled';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
function ExchangesEvent({isCreator, eventData, isLast,deleteMessage,userUid}) {
    const [showContent, setShowContent] = useState(true)
    const {t} = useTranslation();
    const theme = useTheme()

    const [eventReferences, setEventReferences] = useState({
        CALL_EXCHANGE_EVENT : {icon:<PhoneCallbackIcon />, title: t("invoiceDetails:CALL_EXCHANGE_EVENT", "Incoming call")},
        MAIL_EXCHANGE_EVENT : {icon:<EmailIcon /> , title: t("invoiceDetails:MAIL_EXCHANGE_EVENT", "Incoming email")},
        OTHER_EXCHANGE_EVENT : {icon:<ArrowBackIcon /> , title: t("invoiceDetails:OTHER_EXCHANGE_EVENT", "Other")},
        CALL_OUT_EXCHANGE_EVENT : {icon:<PhoneForwardedIcon />, title: t("invoiceDetails:CALL_OUT_EXCHANGE_EVENT", "Outgoing call")},
        MAIL_OUT_EXCHANGE_EVENT : {icon:<EmailIcon />, title: t("invoiceDetails:MAIL_OUT_EXCHANGE_EVENT", "Outgoing email")},
        OTHER_OUT_EXCHANGE_EVENT : {icon:<ArrowForwardIcon />, title: t("invoiceDetails:OTHER_OUT_EXCHANGE_EVENT", "Other")}
    })
    // const [eventReferences, setEventReferences] = useState({
    //     CALL_EXCHANGE_EVENT : "Appel entrant",
    //     MAIL_EXCHANGE_EVENT : "Email entrant",
    //     OTHER_EXCHANGE_EVENT : "Autre",
    //     CALL_OUT_EXCHANGE_EVENT : "Appel sortant",
    //     MAIL_OUT_EXCHANGE_EVENT : "Email sortant",
    //     OTHER_OUT_EXCHANGE_EVENT : "Autre"
    // })

    useEffect(() => {
        // if(isLast)
        //     setShowContent(true)
        // else
        //     setShowContent(false)
    }, [isLast])

    return (
        <AllContainer>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={false} >
                        {
                            eventReferences[eventData?.type]?.icon
                        }
                    </TEIcon>
                    <TEPreview>
                        <TETitle >
                            {
                                eventReferences[eventData?.type]?.title
                            }
                        </TETitle>
                        <TETime style={{fontSize: "0.7rem"}}>
                            <span>{formatDate(+eventData?.creationDate)} {t("global:at", "at")} {getTimeDate(+eventData?.creationDate)}</span><br/>
                            <span style={{float:"right"}}>
                                {(
                                    eventData?.user?.first_name 
                                    || eventData?.user?.last_name 
                                    ? 
                                    (
                                        eventData?.user?.first_name 
                                        ? eventData?.user?.first_name + ' ' 
                                        : null
                                    ) + (
                                        eventData?.user?.last_name ? 
                                            eventData?.user?.last_name 
                                        : null
                                        ) : eventData?.user?.login 
                                )}
                            </span>
                            
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    showContent && (
                        <TEContent>
                            <MessageWrapper>
                                {/* <MessageFrom>
                                    {t("default:message", "Message de ")}  
                                    {(
                                        eventData?.user?.first_name 
                                        || eventData?.user?.last_name 
                                        ? 
                                        (
                                            eventData?.user?.first_name 
                                            ? eventData?.user?.first_name + ' ' 
                                            : null
                                        ) + (
                                            eventData?.user?.last_name ? 
                                                eventData?.user?.last_name 
                                            : null
                                            ) : eventData?.user?.login 
                                    )}
                                </MessageFrom> */}
                                <MessageContent>
                                    {eventData?.message}
                                </MessageContent>
                            </MessageWrapper>
                       
                        </TEContent>
                    )
                }
              
            </TEContainer>
            {userUid === eventData?.user?.userUid && (
                                                 <CloseDiv>
                                                   <CloseIcon style={{ "cursor" : "pointer",color:"red" }} onClick={() => deleteMessage(eventData?.uid)} />  
                                                </CloseDiv>
                                            )}
        </AllContainer>
    )
}

export default ExchangesEvent