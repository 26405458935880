import styled from 'styled-components/macro';
import { NavLink as LinkN, Link } from 'react-router-dom';

export const NavBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 320px; 
    /* min-width: 320px; */
    /* width: fit-content; */
    /* height: calc(100vh - 45px); */
    height: 100vh;
    /* padding: 2rem 1rem; */
    position: sticky;
    z-index: 800;
    top: 0;
    border-right: 1px solid #E3E6E6;
    background-color:#ff0000 ;

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        /* width: 240px;
        min-width: 240px; */
    }
`
export const NavHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    padding-top: 5px;
    padding-bottom: 23px;
   // border-bottom: 1px solid #E3E6E6;
`

export const NavLogoSmall = styled(Link)`
    display: flex;
    width: 27px;
    max-height: 83px;
    
    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        max-height: 40px;
    }
`
export const NavLogo = styled(Link)`
    display: flex;
    width: 220px; 
    max-height: 40px;

    & img {
        width: 190px;
        height: 100%;
        object-fit: contain;
        
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        max-height: 40px;
    }
`
// export const IconShow = styled.div`
//      display: flex;
//     width: 25px;
//     height: 25px;
//     align-items: center;
//     align-self: center;
//     border-radius: 50%;
   
//     background: #E9E9F2 0% 0% no-repeat padding-box;
// `

export const ReduceIcon = styled.div`
    display: flex;
    width: 25px;
    height: 25px;
    align-items: center;
    align-self: center;
    position: absolute;
    color: #000000;
    border-radius: 50%;
    margin-left: 95%;
  
    cursor: pointer;
    border-radius: 50%;
 
    border: 0.5px solid #E9E9F2;
    background: #FFF; 
    & svg {
        font-size: 0.9rem;
        margin-left: 4px;
    }   
`;

export const NavMenu = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    flex: 1;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    overflow-y: auto;

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        font-size: 0.8rem;
    }
`

export const NavItem = styled.li`
    margin: 0;
    width: 100%;
    margin-bottom: 0.5rem;
    border-right: 3px solid ${({isActive, theme}) => isActive ? '#fff' : '#ff0000'};
    color:red;
   
`
export const DivGrouping = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding-right: 10px;
`

export const NavIcon = styled.div`
    margin-bottom: 2px;
    width: 28px;
    height: 28px;

    & svg {
            width: 28px;
            height: 25px;
        }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        & svg {
            width: 28px;
            height: 25px;
        }
    }
`

export const NavCounter = styled.span`
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 22px;
    height: 22px;
    padding: 0;
    margin-right: 10px;
    padding: 0 10px;
    background: #fff;
    color: red;
    text-align: center;
`

export const NavLink = styled(LinkN)`
    /* width: 100%; */
    display: flex;
    align-items: center;
    
    min-height: 48px;
    height: fit-content;
    margin-right:11px;
    margin-left:2px ;
    /* background-color: #2174B9; */
    border-radius: 4px ;
    color: ${({theme}) => "#fff"};
    text-decoration: none;
    padding: 0 0 0 30px;

    & span {
        margin-left: 1rem;
        font-weight: 700;
    }

    &:hover {
        background: #fff 0% 0% no-repeat padding-box;
        color: #ff0000;
        transition: all 0.5s ease-in-out;
        /* border-right: 2px solid ${({isActive, theme}) => isActive ? theme.colors.primary : '#fff'}; */
    }

    &.${({activeclassname}) => activeclassname} {
        & ${NavIcon} {
           // color: ${({theme}) =>  theme.colors.primary};
           color: #ff0000;

            & path {
               // fill: ${({theme}) =>  theme.colors.primary};
                fill: #ff0000;
            }
        }
        & ${NavCounter} {
            background: red;
           color: #fff;
          
        }
        background: #fff 0% 0% no-repeat padding-box;
        color: #ff0000;
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        height: 40px;

        & span {
            margin-left: 1rem;
        }
    }
`




export const NavBtn = styled.div`
    display: flex;
    align-items: center;
    
    min-height: 48px;
    height: fit-content;
    margin-left:2px ;
    /* background-color: #2174B9; */
    border-radius: 4px ;
    opacity: 1;
    color: ${({isActive, theme}) => isActive ? "#fff" : "#fff"};
    text-decoration: none;
    padding: 0 0 0 30px;
    cursor: pointer;
    margin-right:11px;
    & ${NavIcon} {
      color: ${({isActive, theme}) => isActive ? "#fff" : "#fff"};
        /* color: #000; */
 
        & path {
            fill: ${({isActive, theme}) => isActive ?"#fff" : "#fff"};
            /* fill: #000; */
        }
    }
    & span {
        font-weight: 700;
        margin-left: 1rem;
    }

    &:hover {
        background: #fff 0% 0% no-repeat padding-box;
        color: #ff0000;
        transition: all 0.5s ease-in-out;
        & ${NavIcon} {
        color: #ff0000 ;
        & path {
            fill:  #ff0000 ;
            }
 
  }
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        height: 40px;

        & span {
            margin-left: 1rem;
        }
    }
`


export const NavFooter = styled.div`
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: black;
    width: 100%;
    font-size: 14px;
    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        font-size: 14px;
    }
`