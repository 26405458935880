import * as actionTypes from './actionTypes';
import Axios from '../../axios-proas';

export const lastEventsStart = () => ({
    type: actionTypes.LASTEVENTS_START
})



export const lastEventsSetPageSize = (pageSize) => ({
    type: actionTypes.LASTEVENTS_SET_PAGESIZE,
    pageSize: pageSize
})



export const lastEventsSuccess = (data, count) => ({
    type: actionTypes.LASTEVENTS_SUCCESS,
    data: data,
    count: count,
  
});

export const lastEventsFail = (error) => ({
    type: actionTypes.LASTEVENTS_FAIL,
    error: error
})

export const lastEventsGetPage = (filters,pageSize,entity,suppliers, groups) => {
    let newFilters = {
        ...filters,
        pageSize,
        entity,
        suppliers,
        groups
    }
    return dispatch => {
        dispatch(lastEventsStart());
        Axios.get('/invoiceevent//lasteEventsStatus', {
            params: {...newFilters}
        })
        .then(response => {
            dispatch(lastEventsSuccess(response.data.events, 10))
        }).catch(err => {
            dispatch(lastEventsFail(err.response?.data?.error))
        })
    }
}




