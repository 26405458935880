import React,{useState} from 'react';
import { SubNavContainer, SubNavItem, SubNavLink,SubNavLinkItem} from './SubNav.styled.js';
function SubNav({menuItems,activeLink}) {
   // const [activeLink, setActiveLink] = useState(localStorage.getItem('activeNavLink') ? localStorage.getItem('activeNavLink') : '');

    // const navBtnClickHandler = (navTo) => {
     
    //         localStorage.setItem('activeNavLink', navTo);
    //         setActiveLink(navTo)
    //     }
    

  return (
      <>
        <SubNavContainer>
            <SubNavItem>
                {
                    menuItems.map((item, index) => (
                        <SubNavLinkItem isActive={activeLink === item.path} key={index} >
                        <SubNavLink
                           
                            key={index}
                            to={item.path}
                            activeclassname="active"
                        >
                            {item.label}
                        </SubNavLink>
                        </SubNavLinkItem>
                ))}
            </SubNavItem>
        </SubNavContainer>
      </>
  );
}

export default SubNav;
