import React from 'react'
import InvoiceDetail from '../InvoiceDetail/InvoiceDetail'
import CampainDetail from '../../components/CampaignToken/CampainDetail/CampainDetail'
import { CTBody, CTContainer } from './CampainToken.styled'

function CampainToken({statuses}) {

  console.log("CampainToken")
  return (
    <CTContainer>
        <CTBody>
             <CampainDetail   /> 
        </CTBody>
    </CTContainer>
  )
}

export default CampainToken