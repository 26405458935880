import React, {useCallback} from 'react'
import Axios from '../../../axios-proas';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';

import { SupplierLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { ELDItems, ELDItemsContainer, ELDItemsListContainer, ELDVal, ELDValue, EntitiesListDetail, TD,TR,TD2 } from '../../../styles/Table.styled';

function TableBody(props) {
    const {regroupementData, userRole,setEntitydetail,entityOwnerDetail,columns,userLevel, setShowDeleteConfirmModal} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleBoxChecked = (event, row) => {
        Axios.put('/clientbusiness/'+row.uid+'/receivesAttachment').then(response => {
            event.target.checked = response.data;
            row.receivesAttachment = event.target.checked;
        }).catch(error => {
            console.error(error);
        })
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])


    const getTokenVisualisation = (uid) => {
        Axios.get('/clientbusiness/'+uid+'/userTokenVisualisation')
            .then(response => {
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                //window.location.reload(false);
                //Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('loginVisualisation', response.data.login)
                navigate("/userVisualisation")
            })
    }
    
    const getDematType =(client) => {
        var dematExpression = new Array()
        if(client?.edi == 1) dematExpression.push('EDI'); 
        if(client?.chorus == 1) dematExpression.push('CHORUS');
        if(client?.paper == 1) dematExpression.push('PAPER');
        if(client?.sendMail == 1) dematExpression.push('EMAIL');
        if(client?.accessPortal == 1) dematExpression.push('Accès portail'); 
        return dematExpression
    }
    


    return (
        <>
            {
                regroupementData ? regroupementData.map((row,index) => (
                    <TR key={row.id} onClick={() => navigate("/groupingDetail/"+row?.uid)}>
                        <CTooltip title={row.code || ""}><TD style={{display: !columns.filter(column => column?.field=="creationDate")[0]?.show == true && 'none'}}>{formatDate(+row.creationDate)}</TD></CTooltip>
                        <CTooltip title={row.name || ""}>
                            <TD style={{display: !columns.filter(column => column?.field=="title")[0]?.show == true && 'none'}}>
                                {/* <SupplierLink 
                                    to={`/entity/${row?.title}`}
                                > */}
                                    {row.title}
                                {/* </SupplierLink> */}
                            </TD>
                        </CTooltip>
                        <CTooltip title={row.zipCode || ""}>
                            <TD2 style={{display: !columns.filter(column => column?.field=="entities")[0]?.show == true && 'none'}}>
                                <EntitiesListDetail>
                                    <ELDValue>{<span>{row.allEntities}</span>}</ELDValue>
                                    <ELDItemsContainer> 
                                        <ELDItemsListContainer>
                                            <ELDItems>
                                                {
                                                    row.entities.map(entity =>(        
                                                        <ELDVal key={entity.id}  >
                                                            {entity.name}
                                                        </ELDVal>
                                                    ))
                                                }    
                                            </ELDItems>
                                        </ELDItemsListContainer>
                                    </ELDItemsContainer>
                                </EntitiesListDetail>
                            </TD2> 
                        </CTooltip>
                        <TD style={{display: !columns.filter(column => column?.field=="title")[0]?.show == true && 'none'}}>
                            <CTooltip title="Supprimer"><DeleteIcon onClick={e => {e.stopPropagation();setShowDeleteConfirmModal(row)}} style={{color: "red"}} /></CTooltip>
                        </TD>
                    </TR>
                )) : null
            }
        </>
    )
}

export default TableBody
