import styled from 'styled-components/macro';
import Select from 'react-select';
export const SIContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`
export const SIBody = styled.div`
    width: 100%;
    height: 100%;
`
export const SITableContainer = styled.div`
    width: 100%;
    height:calc(100vh - 244px);
    overflow: auto;
    display: flex;
    flex-direction: column;
`

export const Coselect = styled(Select)`
    border: none;
    border-style: none;
    outline: none;
    /* min-width: 170px; */
    flex: 1;
    text-align: centre;
    margin-left: 10px;

& .select__option {
//background-color: ${({theme}) => "red"} !important;
/* border-color: ${({theme}) => theme.colors.secondary} !important; */
}

& .select__control--is-focused {
 color:  ${({theme}) => "#000"} !important;
 
box-sizing: border-box;
}
& .select__control {
    border-style: none;
    display: flex;
    margin-bottom: -2px;
    align-self: end;
    align-items: end;
    border:1px solid ${({theme}) => theme.colors.primary};
}
& .select__control :hover{
  
    border:1px solid ${({theme}) => theme.colors.primary};
}

& .select__value-container{
    display: flex;
    align-items: end;
   
    
}
& .select__single-value{
    color:${({theme}) => theme.colors.text};
}
& .select__input-container{
    
    padding-top: 2px;
    visibility: visible;
    color: hsl(0, 0%, 20%);
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
}
/* background-color: red;
border-color: red;
box-sizing: border-box; */
& .select__clear-indicator {
visibility: hidden;
}

`;

export const ESContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const ESBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const ESContent = styled.div`
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
`

export const ESForm = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.9rem;
    margin-bottom: 2rem;
`

export const ESCol = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 20%;
    margin-bottom: 0.5rem;

    @media ${({theme}) => theme.device.laptop} {
        padding-right: 5%;
    }
`

export const ToggleInput = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ESView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 5px;
    height: 850px;
    max-height: 850px;

    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }
`
export const ESViewSmal = styled.div`
  width: 100%;
    display: flex;
    flex-direction: column;
   
    border: 1px solid #D9E1E7CC; 
    border-radius: 5px;
    height: 70px;
    max-height: 70px;
    margin-Bottom:5px;
    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }
`


export const ESViewHeader = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #F5FBFF;
    margin-bottom: 1rem;

`

export const ESSubject = styled.div`
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
`

export const ESViewActions = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
`

export const ESViewActionsBtn = styled.div`
    height: 70px;
    display: flex;
    margin:3px;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
`


export const ESTitle = styled.h4`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #000000;
    margin-bottom: 2rem;
`