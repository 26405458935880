import React, { useCallback, useEffect, useState } from 'react'
import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import TableActions from '../../components/UsersManagTable/TableActions/TableActions';
import TableHead from '../../components/UsersManagTable/TableHead/TableHead';
import TableBody from '../../components/UsersManagTable/TableBody/TableBody';
import TableFilters from '../../components/UsersManagTable/TableFilters/TableFilters';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { getNotyfObject } from '../../shared/utility';
import { useLocation, useParams } from 'react-router-dom';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { Header, HeaderActions } from '../../styles/Common';
import { UMBody, UMContainer } from './UsersManagement.styled';

function UsersManagement(props) {
    let {
        userType, userLevel,
        userUid,
        loading, 
        usersData, 
        count, 
        currentPage, 
        pageSize,
        setUsersPageSize,
        setUsersPage, 
        getUsersPage, 
        setUsersFilters, 
        filtersQuery, 
        sortQuery, 
        reverse, 
        setSortQuery, 
        setReverse, 
        updateShowFilters, 
        showFilters,
        selectedUsers,
        selectUserRow,
        unselectUserRow,
        selectedAllRows,
        selectAllUsersRows,
        emptySelectedUsers,
        entityFilter,
        notShowAction,
        isContacts,
        
        setUsersQuickFilter
    } = props;
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    let location = useLocation();
    let {uid: entityUid} = useParams();
    const [regions, setRegions] = useState([])
    const [options, setOptions] = useState([])
    const [optionValue, setOptionValue] = useState('all');
    const [tableProfile, setTableProfile] = useState(null);
    const [density, setDensity] = useState("2.25rem")
    // const entityUid = location.state?.entityUid;

    let columns = [];

    if(entityFilter === "owner") {
        columns = [
            {title: 'Prénom', field: 'first_name'},
            {title: 'Nom', field: 'last_name'},
            {title: 'Email', field: 'login', width: "260px"},
            {title: 'Actif', field: 'active', width: "100px"},
            {title: 'Rôle', field: 'level'},
            {title: 'Profil', field: 'profile'},
            {title: "Entités", field: 'entities'},
            {title: 'Pôle/région', field: 'region'} 
        ]
    } else {
        columns = [
            {title: 'Prénom', field: 'first_name'},
            {title: 'Nom', field: 'last_name'},
            {title: 'Email', field: 'login', width: "260px"},
            {title: 'Actif', field: 'active', width: "100px"},
            {title: 'Rôle', field: 'level'},
            {title: 'Nom Fournisseurs', field: 'entities'},
            {title: 'Code Tiers', field: 'entitycodes'},
        ]
    
    }

    useEffect(() => {
        Axios.get('/region').then(response => {
            setRegions(response.data[0])
        }).catch(err => {
            console.log(err);
        })
        Axios.get('/user//entities').then(response => {
            let optionsTmp =  []
            optionsTmp.push({value:'all', label:"-- "+t("home:chooseSupplier", "Select a supplier")+" --"})
            response?.data?.entities?.map((item, index) => { 
                optionsTmp.push({value:item?.uid, label:item?.name + " " + item?.city})
            })
            setOptions(optionsTmp)
            // console.log(response?.data?.entities)
        }).catch(err => {
            console.log(err);
        })
    } ,[])

    useEffect(() => {
        updateShowFilters(false)
        let filtersTemp
        if(entityUid)
            filtersTemp = {
                entityUid
            }
        else
            filtersTemp = {
                entityFilter:isContacts?"supplier": userType,
                isContacts:isContacts
            }
        setUsersFilters(filtersTemp)
        setUsersPage(1)
        getUsersPage(1, filtersTemp, sortQuery, reverse, pageSize);
    }, [getUsersPage, entityUid, pageSize,isContacts])

    useEffect(() => {
        if (tableProfile?.profileValue) {
            const parsedProfile = JSON.parse(tableProfile.profileValue);

            const newFilters = {...parsedProfile.filters};
            getUsersPage(1, newFilters, sortQuery, reverse, pageSize);
            setUsersPage(1)
            setUsersFilters(newFilters);
        } else if (tableProfile)
            handleReset()
    }, [tableProfile])

    const isEmptyFilters = () => {
        const filtersList = columns.map(column => column.field);
        let empty = true;
        filtersList.forEach(filter => {
            if(filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if(!showFilters && !isEmpty) {
            handleReset()
        }
    }, [showFilters]);

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setUsersPage(newPage);
        getUsersPage(newPage, filtersQuery, sortQuery, reverse, pageSize);
    }, [filtersQuery, reverse, sortQuery, pageSize]);

    const handleSort = useCallback((sort,newReverse) => {
        getUsersPage(currentPage, filtersQuery, sort, newReverse, pageSize)
    }, [currentPage,filtersQuery, sortQuery, reverse, pageSize])

    const handleApplyFiltering = useCallback((filters) => {
        getUsersPage(1,filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);


    const handleReset = useCallback(() =>{
        let filtersTemp
        if(entityUid)
            filtersTemp = {
                entityUid
            }
        else
            filtersTemp = {
                entityFilter:isContacts?"supplier": userType,
                isContacts:isContacts
            }
        setSortQuery("first_name")
        setReverse({first_name: false})
        setUsersFilters(filtersTemp);
        setUsersPage(1);
        emptySelectedUsers()
        getUsersPage(1, filtersTemp, "first_name", {first_name: false}, pageSize);
    }, [])

    const enableUser = (userUid) => {
        Axios.put(`user/${userUid}/enableUser`).then(response => {
            if (response.data.hasOwnProperty('success')) {
                notyf.success(t("userList:activated", "L'utilisateur a été activé"));
                getUsersPage(currentPage, filtersQuery, sortQuery, reverse, pageSize);
            }
        }).catch(response => {
            notyf.error(t("global:errorOccurred", "An error has occurred"))
        })
    }

    const disableUser = (userUid) => {
        Axios.put(`user/${userUid}/disableUser`).then(response => {
            if (response.data.hasOwnProperty('success')) {
                notyf.success(t("userList:deactivated", "L'utilisateur a été désactivé"));
                getUsersPage(currentPage, filtersQuery, sortQuery, reverse, pageSize);
            }
        }).catch(response => {
            notyf.error(t("global:errorOccurred", "An error has occurred"))
        })
    }

    const selectChange = (value) => {
        let newFilters = null
        if(value !== "all")
            newFilters = {
                ...filtersQuery,
                selectedEntity: value
            }
        else
            newFilters = {
                ...filtersQuery,
                selectedEntity: null
            }
        

        setOptionValue(value)
        setSortQuery("first_name")
        setReverse({first_name: false})
        setUsersFilters(newFilters);
        setUsersPage(1);
        emptySelectedUsers()
        getUsersPage(1, newFilters, "first_name", {first_name: false}, pageSize)
    }

    let tBody = null;
    let tSpinner = loading  ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
        ) : (
            <NoContent>
                <span>Aucun utilisateur à afficher</span>
            </NoContent>
        )

    if(!loading && usersData?.length > 0){
        tSpinner = null;
        tBody = (
            <TableBody 
                columns={columns} 
                data={usersData} 
                formatDate={formatDate} 
                selectedUsers={selectedUsers}
                selectUserRow={selectUserRow}
                unselectUserRow={unselectUserRow}
                enableUser={enableUser}
                disableUser={disableUser}
                userType={userType}
                userLevel={userLevel}
                userUid={userUid}
                regions={regions}
                entityFilter={entityFilter}
            />
            )
    }
    return (
        <UMContainer>
            <TitleHelmet title={"Transdev | " + t('menu:userManagement')} />

            {!notShowAction &&<Header>
                <HeaderActions>
                    <TableActions  
                            entityFilter={entityFilter} 
                            userType={userType} 
                            resetData={handleReset}
                            options={options} 
                            value={options.find(option => option.value === optionValue)?.label} 
                            onChange={selectChange} />
                </HeaderActions>
            </Header> }


            {/* {
                options.length > 0 && 
                    <Select 
                        options={options} 
                        value={optionValue} 
                        onChange={selectChange} 
                    />
            } */}

  
            
                <UMBody>
                    <TablePagination 
                                currentPage={currentPage} 
                                pageChange={handleChangePage} 
                                totalElement={count} 
                                perPage={pageSize}
                                perPageChange={setUsersPageSize}
                                filters={filtersQuery}
                                tableProfile={tableProfile}
                                setDensity={setDensity}
                                //setTableProfile={setTableProfile}
                                tableType="USER"
                            />
                  
                    <Table>
                        <TableHead 
                            columns={columns} 
                            sortQuery={sortQuery} 
                            reverse={reverse} 
                            setSortQuery={setSortQuery} 
                            setReverse={setReverse} 
                            getData={handleSort}
                            selectedAllRows={selectedAllRows}
                            setSelectedAllRows={selectAllUsersRows}
                            userType={userType}
                            userLevel={userLevel}
                            filters={filtersQuery} 
                            />
                        <TBody height={density}>
                            <TableFilters 
                                userType={userType}
                                show={showFilters} 
                                formatDate={formatDate} 
                                filters={filtersQuery} 
                                filtersChange={setUsersFilters}
                                applyFilters={handleApplyFiltering}
                                regions={regions}
                                entityFilter={entityFilter}
                                />
                            {tBody}
                        </TBody>            
                    </Table>
                    {tSpinner}
                    <TablePagination 
                        currentPage={currentPage} 
                        pageChange={handleChangePage} 
                        totalElement={count} 
                        perPage={pageSize}
                        perPageChange={setUsersPageSize}
                    />	 
                </UMBody>
        </UMContainer>
    )
}
const mapStateToProps = (state) => ({
    isAuth : state.auth.token !== null,
    token: state.auth.token,
    loading: state.users.loading,
    usersData : state.users.data,
    count: state.users.count,
    currentPage: state.users.currentPage,
    pageSize: state.users.pageSize,
    filtersQuery: state.users.filtersQuery,
    filtering: state.users.filtering,
    sortQuery: state.users.sortQuery,
    reverse: state.users.reverse,
    selectedUsers: state.users.selectedUsers,
    showFilters: state.users.showFilters,
    selectedAllRows: state.users.selectedAllRows,
    userType : state.auth.userType,
    userLevel : state.auth.userLevel,
    userUid : state.auth.userUid
}) 
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getUsersPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.usersGetPage(page, filters, sort, reverse, pageSize)),
    setUsersPage: (page) => dispatch(actions.usersSetPage(page)),
    setUsersPageSize: (pageSize) => dispatch(actions.usersSetPageSize(pageSize)),
    setUsersFilters: (filters) => dispatch(actions.usersSetFilterQuery(filters)),
    setSortQuery: (sortQ) => dispatch(actions.usersSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.usersSetReverseSort(reverseS)),
    setUsersQuickFilter: (quickFilterField) => dispatch(actions.usersSetQuickFilter(quickFilterField)),
    updateShowFilters: (show) => dispatch(actions.usersUpdateShowFilters(show)),
    getDocumentFile: (uid, type) => dispatch(actions.invoiceGetDocumentFile(uid, type)),
    selectAllUsersRows: (selectAll) => dispatch(actions.setSelectedAllRows(selectAll)),
    selectUserRow: (uid) => dispatch(actions.selectUser(uid)),
    unselectUserRow: (uid) => dispatch(actions.unselectUser(uid)),
    emptySelectedUsers : () => dispatch(actions.emptySelectedUsers())
})
export default connect(mapStateToProps, mapDispatchToProps) (UsersManagement)
