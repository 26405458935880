import React, { useEffect, useRef, useState } from 'react';
import Axios from '../../../axios-proas';
import { connect } from 'react-redux';
import { TableErrContainer,TableErrDiv,TD,TBody, } from './Rejets.styled';
import { THead,Table,TH,THContainer, RejectLink } from '../../../styles/Table.styled';
import {CTooltip} from '../../../components/UI/CTooltip/CTooltip'
import { CSVLink } from "react-csv";
import "./Rejets.css" 
import { getErrorLabel, getNotyfObject } from '../../../shared/utility';

function Rejets({width}) {
    const [rejectsData, setRejectsData] = useState([]);
    const csvBtn = useRef();
    const notyf = getNotyfObject();

    useEffect(() => {
        Axios.get("/reject//RejectsByType").then(res => {
            setRejectsData(res.data)         
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    } , [])

    const exportSelectionCsv = () => {
        csvBtn.current.link.click();
    }

    const csvHeaders2 = [
        {label: 'Type rejet', key: 'label'},
        {label: 'En cours', key: 'en_cours'},
        {label: 'Dont lot du jour (Date J-1)', key: 'en_cours_j_1'},
        {label: 'Traité J-1', key: 'traite_j_1'},
        {label: 'Rejets à prendre en compte', key: 'prendre_en_compte'}
    ]
    const csvHeaders = [
        {label: 'Type rejet', key: 'label'},
        {label: 'En cours', key: 'en_cours'},
        {label: 'Dont lot du jour (Date J-1)', key: 'lot_jour'},
        {label: 'Traité J-1', key: 'traite'},
        {label: 'Rejets à prendre en compte', key: 'rejets'}
    ]
    
    const is99Code = (code) => {
        return "err99" === code
    }
    let body=(  
        rejectsData?.map(row => (
            <tr className="trHover" key={row.label} style={{borderBottom:"1px solid #809FB8",position:"relative"}}>
                <TD>
                    <CTooltip title={getErrorLabel(row?.label) || ""}><RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectTracking"} state={{errorFilter : row?.label}}>{row.label}</RejectLink></CTooltip>
                </TD>
                <TD >
                    <RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectPending"} state={{errorFilter : row?.label}}>{row.en_cours}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectPending"} state={{errorFilter : row?.label, fromYesterday: true}}>{row.en_cours_j_1}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectArchives"} state={{errorFilter : row?.label, fromYesterday: true}}>{row.traite_j_1}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectTracking"} state={{errorFilter : row?.label}}>{row.prendre_en_compte}</RejectLink>
                </TD>
            </tr>
        ))
    )
    
    let head=(
        <THead style={{fontSize: "12px", height: "fit-content"}}>
            <tr style={{borderBottom:"1px solid #809FB8"}}>
                <TH key={"column64"} width="15%" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="Type rejet">
                            <span>
                                {"Type rejet"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH key={"column54"} width="15%" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="En cours">
                            <span>
                                {"En cours"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH key={"column604"} width="25%" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="Dont lot du jour (Date J-1)">
                            <span>
                                {"Dont lot du jour (Date J-1)"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH key={"column6540"} width="15%" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="Traité J-1">
                            <span>
                                {"Traité J-1"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH key={"column6454"} width="30%" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="Rejets à prendre en compte">
                            <span>
                                {"Rejets à prendre en compte"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                    
            </tr>
        </THead>
    )

    return (
        <TableErrContainer width={width}>
            {rejectsData ? (<CSVLink
                ref={csvBtn}
                data={rejectsData}
                filename={"Rejets.csv"}
                headers={csvHeaders2}
                separator=";">
            </CSVLink>) : null}
            <TableErrDiv>
                <div style={{margin:"15px"}}>
                    {/* < Btn2Hd>
                            <CloseOutlinedIcon onClick={BtnClickHandler} style={{fontSize: "1.5rem", color:"#fff"}} />
                    </ Btn2Hd> */}
                    <Table>
                    {head}
                        <TBody style={{fontSize: "12px"}}>
                            {body}
                        </TBody>
                    </Table>
                </div>
            </TableErrDiv>
            <div style={{width:"100%",display: "flex", justifyContent: "center", padding: "11px"}}>
                <button type="button" style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} className="btn btn-danger" onClick={()=>exportSelectionCsv()}>Export Excel</button>
            </div>
            {/* <CTooltip title="Export CSV">
                <ImportExportIcon  style={{cursor: "pointer"}}/>
            </CTooltip> */}
     </TableErrContainer>
    )
}
const mapStateToProps = (state) => ({

})
const mapDispatchToProps = dispatch => ({
  
})
export default connect(mapStateToProps, mapDispatchToProps)(Rejets)
