import styled from 'styled-components/macro';
import { Link } from 'react-router-dom'

export const IVWrapper = styled.div`
    width: 100%;
    flex-direction:row ;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
`
export const IVContainer = styled.div`
    width:  55%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const IVContainer2 = styled.div`
    width:  42%;
    display: flex;
    justify-content: center;
    height:fit-content;
 
`;
export const EntHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    /* border-bottom: 4px solid #F5FBFF; */
`;
export const XmlDiv = styled.div`
     
     padding: 1px;
     width: 100%;
    height: 600px;
    overflow: auto;
`;

export const IVDownloadContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

export const IVDownloadIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;

    &:hover {
        color: ${({theme}) => theme.colors.primary};
    }
`

export const CIContainer = styled.div`
    width: 100%;
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
    background-color: #FFFFFF;
`

export const CITitle = styled.h5`
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
`

export const CIContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

`

export const CIData = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`

export const CIActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`

export const CIUsersLink = styled(Link)`
    background-color: ${({theme}) => theme.colors.greenLight};
    color: ${({theme}) => theme.colors.success};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.125rem 1.2rem;
    text-decoration: none;
    border-radius: 8px;
    width: fit-content;

    &:hover {
        color: ${({theme}) => theme.colors.success};
    }
`

export const CIDeleteBtn = styled.button`
    display: flex;
    justify-content: center ;
    align-items: center;
    margin-left: 1rem;
    /* padding: 0.5rem; */
    border: 1px solid ${({border, theme}) => border ? border : theme.colors.redLight};
    border-radius: 8px;
    color: ${({color, theme}) => color || theme.colors.redLight};
    cursor: pointer;
    font-size: 0.9rem;
    background-color: #FFFFFF;

    & span {
        color: ${({color, theme}) => color || theme.colors.redLight};
    }

    &:hover {
        background-color: ${({color,theme}) =>color || theme.colors.redLight};
        color: #FFFFFF;
    }
`;
