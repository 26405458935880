import React, { useEffect, useState } from 'react';
import Axios from '../../../axios-proas';
import { connect } from 'react-redux';
import { TableErrContainer,TableErrDiv,TD,TBody, } from './Import.styled';
import { Table, RejectLink } from '../../../styles/Table.styled';
import {CTooltip} from '../../../components/UI/CTooltip/CTooltip'
import { getErrorLabel, getImportCategoryWithKey } from '../../../shared/utility';
import "./Import.css";

function ImportAdmin({width}) {
    const startDate = new Date(1669849200000);
    const [proasImportInfo, setProasImportInfo] = useState(null);
    const [monthYear, setMonthYear] = useState(null);
    const colsToDisplay = [
        "mailrecu",
        "rejetmail",
        "totalmail",
        "facturemail",
        "facturepapier",
        "sommerecues",
        "facturesTraitees",
        "codeerr01",
        "codeerr02",
        "codeerr03",
        "codeerr04",
        "codeerr05",
        "codeerr06",
        "codeerr07",
        "codeerr08",
        "codeerr09",
        "codeerr10",
        "codeerr11",
        "codeerr12",
        "sommeerreurs",
        "factureenvoyee",
        "factureenerreuratraiterpartransdev",
        "HSpapier"
    ]

    const generateMonthOptions = () => {
        const formatMonth = (date) => {
            if(date.getMonth() < 9)
                return "0"+(date.getMonth() + 1);
            else
                return date.getMonth() + 1;
        }
        const options = [];
        const endDate = new Date().getTime();
        let date = startDate;
        
        do {
            options.push(formatMonth(date)+"/"+date.getFullYear());
            date.setMonth(date.getMonth()+1);
        } while(date.getTime() < endDate)
        return options;
    }

    const options = generateMonthOptions();

    useEffect(() => {
        Axios.get('/proasimportinfo/'+monthYear+'/perMonth').then((res) => {
            setProasImportInfo({
                mailrecu: res.data.reduce((sum, value) => sum + (+value.mailrecu), 0),
                rejetmail: res.data.reduce((sum, value) => sum + (+value.rejetmail), 0),
                totalmail: res.data.reduce((sum, value) => sum + (+value.totalmail), 0),
                facturemail: res.data.reduce((sum, value) => sum + (+value.facturemail), 0),
                facturepapier: res.data.reduce((sum, value) => sum + (+value.facturepapier), 0),
                sommerecues: res.data.reduce((sum, value) => sum + (+value.sommerecues), 0),
                facturesTraitees: res.data.reduce((sum, value) => sum + (+value.facturesTraitees), 0),
                codeerr01: res.data.reduce((sum, value) => sum + (+value.codeerr01), 0),
                codeerr02: res.data.reduce((sum, value) => sum + (+value.codeerr02), 0),
                codeerr03: res.data.reduce((sum, value) => sum + (+value.codeerr03), 0),
                codeerr04: res.data.reduce((sum, value) => sum + (+value.codeerr04), 0),
                codeerr05: res.data.reduce((sum, value) => sum + (+value.codeerr05), 0),
                codeerr06: res.data.reduce((sum, value) => sum + (+value.codeerr06), 0),
                codeerr07: res.data.reduce((sum, value) => sum + (+value.codeerr07), 0),
                codeerr08: res.data.reduce((sum, value) => sum + (+value.codeerr08), 0),
                codeerr09: res.data.reduce((sum, value) => sum + (+value.codeerr09), 0),
                codeerr10: res.data.reduce((sum, value) => sum + (+value.codeerr10), 0),
                codeerr11: res.data.reduce((sum, value) => sum + (+value.codeerr11), 0),
                codeerr12: res.data.reduce((sum, value) => sum + (+value.codeerr12), 0),
                sommeerreurs: res.data.reduce((sum, value) => sum + (+value.sommeerreurs), 0),
                factureenvoyee: res.data.reduce((sum, value) => sum + (+value.factureenvoyee), 0),
                factureenerreuratraiterpartransdev: res.data.reduce((sum, value) => sum + (+value.factureenerreuratraiterpartransdev), 0),
                HSpapier: res.data.reduce((sum, value) => sum + (+value.HSpapier), 0)
            }); 
        }).catch(err => {
            notyf.error(err.message);
        })
    }, [monthYear])
    
    const body = proasImportInfo ? (
        colsToDisplay?.map(col => (
            <tr className="trHover importTableRow" key={col}>
                <TD className="importTableLabel">
                    <CTooltip title={col?.includes("codeerr") ? getErrorLabel(col.replace("code", "")) : (getImportCategoryWithKey(col) || "")}>
                        <span>{getImportCategoryWithKey(col)}</span>
                    </CTooltip>
                </TD>
                <TD className="importTableValue">
                    <CTooltip title={proasImportInfo?.[col] || ""}>
                        <span>{proasImportInfo?.[col]}</span>
                    </CTooltip>
                </TD>
            </tr>
        ))
    ) : null;

    return (
        <TableErrContainer width={width} style={{height: "fit-content", width: "100%"}}>
            <CTooltip title={"Ce KPI permet de contrôler la facturation AGSRM"}>
                <div className="importHeader">Somme des imports AGSRM {monthYear ? "("+monthYear?.replace("-", "/") +")" : ""}</div>
            </CTooltip>
            <TableErrDiv style={{maxHeight: "fit-content"}}>
                {monthYear && <div style={{margin:"15px"}}>
                    <Table>
                        <TBody style={{fontSize: "12px"}}>
                            {body}
                        </TBody>
                    </Table>
                </div>}
                <select className="form-control" style={{width: "60%", margin:"20px auto"}} value={monthYear} onChange={e => setMonthYear(e.target.value)}>
                    <option value="">-- Choisissez un mois --</option>
                    {options?.map(o => <option key={o} value={o.replace("/", "-")}>{o}</option>)}
                </select>
            </TableErrDiv>
     </TableErrContainer>
    )
}
const mapStateToProps = (state) => ({

})
const mapDispatchToProps = dispatch => ({
  
})
export default connect(mapStateToProps, mapDispatchToProps)(ImportAdmin)
