import React, { useEffect, useRef, useState } from 'react';
import Axios from '../../../axios-proas';
import { TableErrContainer, TableErrDiv, TD, TBody, TitleDiv } from './DatedRejects.styled';
import { THead, Table, TH, THContainer, RejectLink } from '../../../styles/Table.styled';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip'
import { CSVLink } from 'react-csv';
import { getErrorLabel, getNotyfObject } from '../../../shared/utility';

function DatedRejects() {
    const [rejectsData, setRejectsData] = useState([]);
    const [dateType, setDateType] = useState("lastActionDate");
    const csvBtn = useRef();
    const notyf = getNotyfObject();

    useEffect(() => {
        Axios.get("/reject/"+dateType+"/datedRejectsPerType").then(res => {
            const tempData = res.data?.map(d => ({...d, total: +d.to7+(+d.from7To15)+(+d.from15To30)+(+d.from30)+(+d.toTreat)}));
            tempData.push({
                rejectType: "Total",
                total: tempData.reduce((sum, value) => sum + (+value.total), 0),
                to7: tempData.reduce((sum, value) => sum + (+value.to7), 0),
                from7To15: tempData.reduce((sum, value) => sum + (+value.from7To15), 0),
                from15To30: tempData.reduce((sum, value) => sum + (+value.from15To30), 0),
                from30: tempData.reduce((sum, value) => sum + (+value.from30), 0),
                toTreat: tempData.reduce((sum, value) => sum + (+value.toTreat), 0)
            })
            setRejectsData(tempData);
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    } , [dateType])

    const exportSelectionCsv = () => {
        csvBtn.current.link.click();
    }

    const getDateRange = type => {
        const fromDate = new Date(new Date().toISOString().substring(0, 10) + "T00:00:00");
        const toDate = new Date(new Date().toISOString().substring(0, 10) + "T00:00:00");
        switch (type) {
            case "to7":
                fromDate.setDate(fromDate.getDate() - 7);
                return fromDate.getTime()+":"+toDate.getTime();
            case "from7To15":
                fromDate.setDate(fromDate.getDate() - 15);
                toDate.setDate(toDate.getDate() - 8);
                return fromDate.getTime()+":"+toDate.getTime();
            case "from15To30":
                fromDate.setDate(fromDate.getDate() - 30);
                toDate.setDate(toDate.getDate() - 16);
                return fromDate.getTime()+":"+toDate.getTime();
            case "from30":
                toDate.setDate(toDate.getDate() - 31);
                return new Date("2022-12-01T00:00:00").getTime()+":"+toDate.getTime();
        }
    }
    
    const csvHeaders = [
        {label: 'Type rejet', key: 'rejectType'},
        {label: 'Total rejets non traités', key: 'total'},
        {label: 'Rejets en cours 0-7 jrs', key: 'to7'},
        {label: 'Rejets en cours 8-15 jrs', key: 'from7To15'},
        {label: 'Rejets en cours 16-30 jrs', key: 'from15To30'},
        {label: 'Rejets en cours > 30 jrs', key: 'from30'},
        {label: 'Rejets à traiter', key: 'toTreat'}
    ]

    let body=(  
        rejectsData?.filter((row, index) => index+1 != rejectsData.length).map(row => (
            <tr className="trHover" key={row.rejectType} style={{borderBottom:"1px solid #809FB8", position:"relative"}}>
                <TD>
                    <CTooltip title={getErrorLabel(row?.rejectType) || ""}><RejectLink to={row.rejectType === "err51" ? "/reject51" : row.rejectType === "err99" ? "/reject99":"/rejectTracking"} state={{errorFilter : row?.rejectType}}>{row.rejectType}</RejectLink></CTooltip>
                </TD>
                <TD >
                    {row.total}
                </TD>
                <TD >
                    <RejectLink to={row.rejectType === "err51" ? "/reject51" : row.rejectType === "err99" ? "/reject99":"/rejectPending"} state={{errorFilter : row?.rejectType, [dateType]: getDateRange("to7"), processingStatus: "PENDING"}}>{row.to7}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.rejectType === "err51" ? "/reject51" : row.rejectType === "err99" ? "/reject99":"/rejectPending"} state={{errorFilter : row?.rejectType, [dateType]: getDateRange("from7To15"), processingStatus: "PENDING"}}>{row.from7To15}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.rejectType === "err51" ? "/reject51" : row.rejectType === "err99" ? "/reject99":"/rejectPending"} state={{errorFilter : row?.rejectType, [dateType]: getDateRange("from15To30"), processingStatus: "PENDING"}}>{row.from15To30}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.rejectType === "err51" ? "/reject51" : row.rejectType === "err99" ? "/reject99":"/rejectPending"} state={{errorFilter : row?.rejectType, [dateType]: getDateRange("from30"), processingStatus: "PENDING"}}>{row.from30}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.rejectType === "err51" ? "/reject51" : row.rejectType === "err99" ? "/reject99":"/rejectTracking"} state={{errorFilter : row?.rejectType, processingStatus: "TO_TREAT"}}>{row.toTreat}</RejectLink>
                </TD>
            </tr>
        ))
    )
    
    let head=(
        <THead style={{fontSize: "12px", height: "fit-content"}}>
            <tr style={{borderBottom:"1px solid #809FB8"}}>
                <TH width="15%" scope="col" rowSpan={2} style={{borderRight: "solid 1px white"}}>
                    <THContainer style={{justifyContent: "center"}}>
                        <CTooltip title="Type rejet">
                            <span style={{whiteSpace: "break-spaces"}}>Type rejet</span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH width="15%" scope="col" rowSpan={2} style={{borderRight: "solid 1px white"}}>
                    <THContainer style={{justifyContent: "center"}}>
                        <CTooltip title="Total rejets non traités">
                            <span style={{whiteSpace: "break-spaces"}}>Total rejets non traités</span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH width="40%" scope="col" colSpan={4} style={{borderRight: "solid 1px white", borderBottom: "solid 1px white"}}>
                    <THContainer style={{justifyContent: "center"}}>
                        <CTooltip title="Rejets en cours">
                            <span>Rejets en cours</span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH width="15%" scope="col" rowSpan={2} style={{borderLeft: "solid 1px white", borderRight: "solid 1px white"}}>
                    <THContainer style={{justifyContent: "center"}}>
                        <CTooltip title="Rejets à traiter">
                            <span style={{whiteSpace: "break-spaces"}}>Rejets à traiter</span>
                        </CTooltip>
                    </THContainer>
                </TH>
            </tr>
            <tr style={{borderBottom:"1px solid #809FB8"}}>
                <TH scope="col" style={{borderRight: "solid 1px white"}}>
                    <THContainer style={{justifyContent: "center"}}>
                        <CTooltip title="Rejets en cours 0-7 jrs">
                            <span>0-7 jrs</span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH scope="col" style={{borderRight: "solid 1px white"}}>
                    <THContainer style={{justifyContent: "center"}}>
                        <CTooltip title="Rejets en cours 8-15 jrs">
                            <span>8-15 jrs</span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH scope="col" style={{borderRight: "solid 1px white"}}>
                    <THContainer style={{justifyContent: "center"}}>
                        <CTooltip title="Rejets en cours 16-30 jrs">
                            <span>16-30 jrs</span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH scope="col" style={{borderRight: "solid 1px white"}}>
                    <THContainer style={{justifyContent: "center"}}>
                        <CTooltip title="Rejets en cours &gt; 30 jrs">
                            <span>&gt; 30 jrs</span>
                        </CTooltip>
                    </THContainer>
                </TH>
            </tr>
        </THead>
    )

    return (
        <TableErrContainer width={"37%"}>
            <TitleDiv><span>Récapitulatif des rejets</span></TitleDiv>
            {rejectsData ? (<CSVLink
                ref={csvBtn}
                data={rejectsData}
                filename={"Rejets.csv"}
                headers={csvHeaders}
                separator=";">
            </CSVLink>) : null}
            <TableErrDiv>
                <div style={{margin:"0 15px"}}>
                    <Table>
                        {head}
                        <TBody style={{fontSize: "12px"}}>
                            {body}
                            <tr className="trHover" style={{borderBottom:"1px solid #809FB8", position:"relative"}}>
                                <TD>Total</TD>
                                <TD>{rejectsData[rejectsData.length-1]?.total}</TD>
                                <TD>{rejectsData[rejectsData.length-1]?.to7}</TD>
                                <TD>{rejectsData[rejectsData.length-1]?.from7To15}</TD>
                                <TD>{rejectsData[rejectsData.length-1]?.from15To30}</TD>
                                <TD>{rejectsData[rejectsData.length-1]?.from30}</TD>
                                <TD>{rejectsData[rejectsData.length-1]?.toTreat}</TD>
                            </tr>
                        </TBody>
                    </Table>
                </div>
                <select className="form-control" style={{width: "60%", margin:"20px auto"}} value={dateType} onChange={e => setDateType(e.target.value)}>
                    <option value="lastActionDate">Par date de dernière action</option>
                    <option value="issuingDate">Par date de facture</option>
                </select>
            </TableErrDiv>
            <div style={{width:"100%",display: "flex", justifyContent: "center", padding: "11px"}}>
                <button type="button" style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} className="btn btn-danger" onClick={()=>exportSelectionCsv()}>Export Excel</button>
            </div>
        </TableErrContainer>
    )
}
export default DatedRejects
