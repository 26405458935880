import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TablePagination from '../../../components/UI/TablePagination/TablePagination';
import TableActions from '../../../components/EreportingTable/TableActions/TableActions';
import TableHead from '../../../components/EreportingTable/TableHead/TableHead';
import TableBody from '../../../components/EreportingTable/TableBody/TableBody';
import TableFilters from '../../../components/EreportingTable/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Spinner } from 'react-bootstrap';
import TitleHelmet from '../../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../../styles/Common';
import { NoContent, SpinnerContainer, Table, TBody } from '../../../styles/Table.styled';
import { RFContainer, RFBody, RFTableContainer } from './LastEvents.styled';
import Axios from '../../../axios-proas';

import _ from 'lodash';


function LastEvent(props) {
    const {
        userType,
        entityFilter, 
        setEntitydetail, 
        loading, 
        EventsData, 
        counts, 
        pageSize, 
        getReportingPage,
        entity,
        suppliers,
        groups,
        statuses
     
        
    } = props;


    const initialFiltersState = {
         page:1
    }
    
    // const reverseTable = {
    //     'type' : false ,'issuingDate': false,'exclusiveTaxAmountTotal': false,
    //     'invoicingEndDate': false,'invoicingStartDate': false
    // };
    const [filters, setFilters] = useState(initialFiltersState);
    const [showFilters, setShowFilters] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [reverse, setReverse] = useState([]);

    const {t} = useTranslation();
    // exclusiveTaxAmountTotal invoicingEndDate invoicingStartDate  type issuingDate'
    const[density,setDensity]=useState("2.25rem")
    const [showAll, setShowAll] = useState();
    const [loadColumns, setLoadColumns] = useState(false)
    const navTitre = "events";
    const[columns,setColumns]=useState([])

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{navTitre:navTitre}})
            .then(response => {
                let tempColumns = response?.data?.columns
                if (tempColumns === null)
                    tempColumns = {statusDate: 1, supplierName: 1, invoiceNumber: 1, supplierCode: 1, clientName: 1, clientCode: 1, status: 1};
                let columnsCopy = [
                    {title: t("Date de changement de statut"), field: 'statusDate',show:tempColumns.statusDate}, 
                    {title: "Nom fournisseur", field: 'supplierName',show:tempColumns.supplierName},    
                    {title: "Numéro de facture", field: 'invoiceNumber',show:tempColumns.invoiceNumber},  
                    {title: t("Code tiers"), field: 'supplierCode', show:tempColumns.supplierCode},
                    {title: "Nom entité ", field: 'clientName',show:tempColumns.clientName},    
                    {title: "Code entité ", field: 'clientCode',show:tempColumns.clientCode},
                    {title: t("Statut"), field: 'status', show:tempColumns.status}
                ]
                setColumns(columnsCopy)
                columnsCopy.filter( column => column?.show == 0 ).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns])

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;
    }, [])

    useEffect(() => {    
        getReportingPage(filters,pageSize,entity,suppliers, groups);
      
    }, [filters,pageSize,entity,suppliers, groups])



    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse); 

        setReverse(newReverse)
        let key = field;
        let newFilters = {
            ...filters,
            orderBy: field,
            reverse: reverse[field],
            
        }
        setFilters(newFilters)

    }

    const handlePageChange = useCallback((newPage) => {
        setCurrentPage(newPage);
        let newFilters = {
            ...filters,
            page: newPage,
           
        }
        setFilters(newFilters)
    }, [currentPage]);

    

    const resetFilters = () => {
        setFilters(initialFiltersState)
        setCurrentPage(1)
        getReportingPage(initialFiltersState,pageSize,entity,suppliers, groups)
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value,
           
        }
        setFilters(newFilters)
        //getReportingPage(newFilters)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
        ) : (
            <NoContent>
                <span>Aucun évènement à afficher</span>
            </NoContent>
        ) 

    if(!loading && EventsData?.length > 0){
        tSpinner = null;
        tBody = ( <TableBody 
                        columns={columns}
                        setEntitydetail={setEntitydetail} 
                        Data={EventsData} 
                        formatDate={formatDate}
                        entityFilter={entityFilter}
                        userType={userType}
                    />)
    }
    
    return (
        <RFContainer>
             
            <TitleHelmet title={"Transdev | " + t("Reporting")}  />
            <RFBody>
                <h4>Derniers évènements</h4>
                <RFTableContainer>
                    
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage} 
                        pageChange={handlePageChange} 
                        totalElement={counts} 
                        perPage={pageSize}
                        perPageChange={null}
                        navTitre="events"
                    />
                    
                    <Table >
                        <TableHead 
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler} 
                            entityFilter={entityFilter}
                            userType={userType}
                        />
                        <TBody height= {density}>
                            <TableFilters 
                                columns={columns}
                                showFilters={showFilters}
                                reportingFilters={filters}
                                filtersChange={setFilters}
                                statuses={statuses} 
                                // amountBTTotal={amountBTTotal}
                                //applyFilters={handleApplyFiltering}
                                entityFilter={entityFilter}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                </RFTableContainer>
            </RFBody>
        </RFContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.lastEvents.loading,
    EventsData: state.lastEvents.data,
    counts: state.lastEvents.count,
    pageSize: state.lastEvents.pageSize,
    userType: state.auth.userType,
   
    
    
})
const mapDispatchToProps = dispatch => ({
    getReportingPage: (filters,pageSize,entity,suppliers, groups) => dispatch(actions.lastEventsGetPage(filters,pageSize,entity,suppliers, groups)),
    

})
export default connect(mapStateToProps, mapDispatchToProps) (LastEvent)
