import React, { useCallback, useEffect, useRef, useState } from 'react'
import Axios from '../../../axios-proas';
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import { CSVLink } from "react-csv";
import { useTranslation } from 'react-i18next';
import { getNotyfObject, formatChannel, formatType } from '../../../shared/utility';
import { TableAction, TAContainer } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import Export from '../../UI/Export/Export';

function TableActions(props) {
    const {showFilters, filterState, resetData, getCompressedDoc, selectedRows, formatDate, filters, sortQuery, reverse, getStatusLabel, entityFilter, tableCount, userType} = props;
    const {t, i18n} = useTranslation();
    const [csvData, setCsvData] = useState();
    const [csvHeaders, setCsvHeaders] = useState();
    const csvBtn = useRef();
    let notyf = getNotyfObject();
    const theme = useTheme();

    useEffect(() => {
        const headersTemp = [];
        if (userType === "supplier") {
            headersTemp.push({label: t("entitiesList:entityName", "Entity"), key: 'clientName'})
            headersTemp.push({label: t("supplier:supplierCodefournisseur", "Supplier code"), key: 'supplierCode'})
            headersTemp.push({label: t("invoiceList:supplier", "Supplier"), key: 'supplierName'})
        } else {
            headersTemp.push({label: "Code SU", key: 'clientCode'});
            headersTemp.push({label: "Nom entité", key: 'clientName'});
            headersTemp.push({label: "Code tiers", key: 'supplierCode'});
            headersTemp.push({label: t("invoiceList:supplier"), key: 'supplierName'});
            headersTemp.push({label: "Type de dépôt", key: 'channel'});
        }
        headersTemp.push({label: t("invoiceList:docType"), key: 'documentType'});
        headersTemp.push({label: t("invoiceList:number"), key: 'number'});
        headersTemp.push({label: t("invoiceList:issuingDate"), key: 'issuingDate'});
        headersTemp.push({label: t("invoiceList:status"), key: 'statusLabel'});
        headersTemp.push({label: t("invoiceList:statusDate"), key: 'statusDate'});
        headersTemp.push({label: t("invoiceList:comments", "Comments"), key: 'unreadMessages'});
        headersTemp.push({label: t("invoiceList:region", "Region"), key: 'region'});
        headersTemp.push({label: t("invoiceList:inclusiveAmount"), key: 'inclusiveTaxAmount'});
        headersTemp.push({label: t("invoiceList:exclusiveAmount"), key: 'exclusiveTaxAmount'});
        headersTemp.push({label: t("invoiceList:currency"), key: 'currency'});
        if (userType === "owner") {
            headersTemp.push({label: "Date d'acquisition", key: 'creationDate'});
            headersTemp.push({label: t("invoiceList:documentCode", "Code document"), key: 'documentCode'});
            headersTemp.push({label: t("invoiceList:internalNumber", "Numéro de facture interne"), key: 'internalNumber'});
        }

        setCsvHeaders(headersTemp);
    }, [userType, i18n.language])

    const exportSelectionZip = (selectedRows) => {
        let selectedIds = [];
        for (const key in selectedRows) {
            
            if (selectedRows[key])
                selectedIds.push(key) 
        }
        getCompressedDoc(selectedIds, filters)
    }
    const exportCsv = useCallback((selectedRows, filters) => {
        const selectedIds = [];
        for (const key in selectedRows)
            if (selectedRows[key])
                selectedIds.push(key)
        
        const newFilters = {};
        if(filters)
            for (const key in filters)
                if(filters[key])
                    newFilters[key] = filters[key];
        
        if(sortQuery)
            newFilters.orderBy = sortQuery;
        
        if(reverse)
            newFilters.reverse = reverse[sortQuery];
        
        Axios.get('/invoice//csvCompressedDocument' + (selectedIds.length > 0 ? '?selection='+selectedIds : ""), {params: newFilters})
        .then((response) => {
       
            const csvDataTemp = response.data.content.map(row => {
                    return {
                        ...row,
                        issuingDate: formatDate(+row.issuingDate),
                        creationDate: formatDate(+row.creationDate),
                        statusLabel : getStatusLabel(row),
                        dueDate: formatDate(+row.dueDate),
                        readStatus: row.firstAccessDate !== null ? t("global:yes") : t("global:no"),
                        channel: formatChannel(row.channel),
                        documentType:formatType(row.documentType),
                        statusDate: formatDate(+row.statusDate),
                        creationDate: row.creationDate ? formatDate(+row.creationDate) : null,
                        unreadMessages: unreadMessages ? formatDate(+row.unreadMessages) : ""

                    } 
                })
         
                setCsvData(csvDataTemp);
                csvBtn.current.link.click();
        }).catch(err => notyf.error("Une erreur s'est produite"))
    },[selectedRows, filters])
    
    const createEReporting = useCallback((selectedRows) => {
        const selectedIds = [];
        for (const key in selectedRows)
            if (selectedRows[key])
                selectedIds.push(key)
        Axios.post('/ereporting/',{
            type: entityFilter === "supplier" ? 'RECEIVED' : 'SENT',
            selectedIds
        })
        .then((response) => {
            let success = response?.data?.hasOwnProperty('success') ? response.data.success : false;
            if(success)
                notyf.success(t("ereporting:success", "E-Reporting créé avec succès"));
            else
                notyf.error(t("ereporting:error", "Une erreur s'est produite"));
        }).catch(err => notyf.error("Une erreur s'est produite"))
    },[selectedRows])

    const showExportZip = (selectedRows) => {
        for (const key in selectedRows) {
            if (selectedRows[key])
                return true
        }
        return false
    }

    return (
        <TAContainer>
              <TableAction title={t('global:resetFilters', "Reset filters")}  onClick={() => resetData()}>
                {/* <span>{t("global:Rest")} </span> */}
                <ReplayIcon />
            </TableAction>
            <TableAction active={filterState} onClick={() => showFilters()}>
                <span>{t("global:filters")} </span>
                <FilterListIcon />
            </TableAction>
            <Export
                exportPdf={() => exportSelectionZip(selectedRows)}
                exportCsv={() => exportCsv(selectedRows, filters)}
                hasSelection={showExportZip(selectedRows)}
                tableCount={tableCount}
            />
            {csvData ? (<CSVLink
                ref={csvBtn}
                data={csvData}
                filename={"documentsCsv - " +new Date().toISOString().replace(":", "")+ ".csv"}
                headers={csvHeaders}
                separator={";"}>
            </CSVLink>) : null}
        </TAContainer>
    )
}

export default TableActions
