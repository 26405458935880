import React, { useMemo, useState,useEffect } from 'react'
import TextEditor from '../../components/CampaignForm/TextEditor/TextEditor';
import CampaignLogs from '../../components/CampaignForm/CampaignLogs/CampaignLogs';
import Recipient from '../../components/CampaignForm/Recipient/Recipient';
import RepertoireModal from '../../components/CampaignForm/RepertoireModal/RepertoireModal';
import Axios from '../../axios-proas'
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Attachment from '../../components/CampaignForm/Attachment/Attachment';
import frLocale from "date-fns/locale/fr";
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useNavigate, useLocation , useParams} from 'react-router-dom';
import { convertFileToBase64, getNotyfObject } from '../../shared/utility';
import Spinner from 'react-bootstrap/Spinner'
import { CTooltip } from '../../components/UI/CTooltip/CTooltip';
import { AddRecipientsBtn, AttachementsContainer, CFBody, CFContainer, CFEditorWrapper, CFFormGroup, CFFormSection, CFFormWrapper, CFHeader, CFHeaderActions, CFHeaderTitle, CFHeaderWrapper, CFSection, CFTitle, DPContainer, ToggleIcon, ToggleLabel } from './CampaignForm.styled';
import { BtnOutlineAction, BtnOutlineLink, FormInput, FormLabel } from '../../styles/Common';
import { useTranslation } from 'react-i18next';
import NavTabs from '../../components/UI/NavTabs/NavTabs';
import { useTheme } from 'styled-components';
import FileUploaderBtn from '../../components/UI/FileUploaderBtn/FileUploaderBtn';
import MandatoryMarker from '../../components/UI/MandatoryMarker';

function CampaignForm() {
    let {uid} = useParams(); 
    let notyf = getNotyfObject();
    let location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const theme = useTheme()
    const currentDate = new Date();

    const [selectedStartDate, setSelectedStartDate] = React.useState(currentDate);
    const [startDateError, setStartDateError] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(new Date().setFullYear(new Date().getFullYear() , 12, 0 )));
    const [endDateError, setEndDateError] = useState(false);
    const [selectedFirstRecallDate, setSelectedFirstRecallDate] = useState(null);
    const [firstRecallDateError, setFirstRecallDateError] = useState(false);
    const [selectedSecondRecallDate, setSelectedSecondRecallDate] = useState(null);
    const [secondRecallDateError, setSecondRecallDateError] = useState(false);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [showRepertoireModal, setShowRepertoireModal] = useState(false)
    const [showAttachmentModal, setShowAttachmentModal] = useState(false)
    const [allSuppliersSelected, setAllSuppliersSelected] = useState(false)
    const [recipientsCount, setRecipientsCount] = useState()
    const [entitiesTemp, setEntitiesTemp] = useState([])
    const [campaign, setCampaign] = useState({
        campaignDate: selectedStartDate?.getTime(),
        // endDate: selectedEndDate?.getTime(),
        attachments: [],
        recipients: [],
        title: '',
        text: '',
        toRead : 0,
        toRelaunch : 0,
        toSign : 0
    });
    
    const [attachmentFileData, setAttachmentFileData] = useState([]);
    const [campaignData, setCampaignData] = useState([]);

    const [campaignErrors, setCampaignErrors] = useState([]);
    const [campaignLogs, setCampaignLogs] = useState([]);
    const [createCampaignLoading, setCreateCampaignLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("parametres");
    const tabsList = useMemo(() => 
    uid ? 
        [
            {title: t("global:parametres", "Paramètres"), value: "parametres"},
            {title: t("global:logs", "Logs"), value: "logs"}
            // {title: t("global:launch", "Lancement"), value: "launch"}
        ]
    : 
        [
            {title: t("global:parametres", "Paramètres"), value: "parametres"}
        ]
    , [t]);

    useEffect(() => { 
        if(uid !=null){  
            Axios.get("campaign/"+uid+"/campaignByUid").then(response => {
                setCampaignData(response.data.result)
                setAttachmentFileData(response?.data?.attachement)
                setAllSuppliersSelected(+response.data.result.allSuppliers == 1)
                setRecipientsCount(response.data.count)
            }).catch(response => {
                notyf.error("Une erreur s'est produite")
            })
        }
    }, [])

    const formatEntities = (entities) => {
        return entities.split(',')
    }
   
    const tabClickHandler = (tab) => {
        setActiveTab(tab);
    }

    const handleStartDateChange = (date) => {
        if(date!=null){
        if (date?.toString() === "Invalid Date"){
            setStartDateError(true)
        } else 
        if(date.getFullYear <= 1900 || date.getFullYear > 2100){
            setStartDateError(true)
        }else {
            setStartDateError(false)
        }
        setSelectedStartDate(date);
        if(!uid){
            setCampaign({
                ...campaign,
                campaignDate : date.getTime()
            })
        }else 
            setCampaignData({
                ...campaignData,
                campaignDate : date.getTime()
            })
        }
    };

    const handleEndDateChange = (date) => {
        if(date!=null){
        if (date?.toString() === "Invalid Date"){
            setEndDateError(true)
        } else 
        if(date < selectedStartDate || date.getFullYear > 2100 ){
            setEndDateError(true)
        }else {
            setEndDateError(false)
        }
        setSelectedEndDate(date);
        if(!uid){
            setCampaign({
                ...campaign,
                endDate : date.getTime()
            })
        }else 
            setCampaignData({
                ...campaignData,
                endDate : date.getTime()
            })
        }
    };

    const handleFirstRecallDateChange = (date) => {
        if (date!=null) {
            if (date?.toString() === "Invalid Date"){
                setFirstRecallDateError(true)
            } else 
            if(date.getFullYear <= 1900 || date.getFullYear > 2100){
                setFirstRecallDateError(true)
            } else {
                setFirstRecallDateError(false)
            }
            setSelectedFirstRecallDate(date);
            if(!uid){
                setCampaign({
                    ...campaign,
                    firstRecallDate : date.getTime()
                })
            } else 
                setCampaignData({
                    ...campaignData,
                    firstRecallDate : date.getTime()
                })
            } else {
                setSelectedFirstRecallDate(null);
                if(!uid){
                    setCampaign({
                        ...campaign,
                        firstRecallDate : null
                    })
                } else 
                    setCampaignData({
                        ...campaignData,
                        firstRecallDate : null
                    })
        }
    };

    const handleSecondRecallDateChange = (date) => {
        if (date!=null) {
            if (date?.toString() === "Invalid Date"){
                setSecondRecallDateError(true)
            } else 
            if(date.getFullYear <= 1900 || date.getFullYear > 2100){
                setSecondRecallDateError(true)
            } else {
                setSecondRecallDateError(false)
            }
            setSelectedSecondRecallDate(date);
            if(!uid){
                setCampaign({
                    ...campaign,
                    secondRecallDate : date.getTime()
                })
            } else 
                setCampaignData({
                    ...campaignData,
                    secondRecallDate : date.getTime()
                })
        } else {
            setSelectedSecondRecallDate(null);
            if(!uid){
                setCampaign({
                    ...campaign,
                    secondRecallDate : null
                })
            } else 
                setCampaignData({
                    ...campaignData,
                    secondRecallDate : null
                })
        }
    };

    const generateMaxEndDate = () => {
        let startDate = new Date( uid ? campaignData?.endDate : selectedStartDate);
        return new Date( startDate.setFullYear(startDate.getFullYear() , 12, 0 ))
    }


    // const addRecipientsHandler = (newRecipients) => {

    //     const allClients = newRecipients.find(row => row.uid === "allClients")
    //     const allSuppliers = newRecipients.find(row => row.uid === "allSuppliers");
        

    //     let campaignRecipientsTemp = 
    //             !uid ? (campaign?.recipients ? [...campaign?.recipients] : [] ) 
    //                 : (campaignData?.recipients ? [...campaignData?.recipients] : [] )


    //     const newRecipientsClients = newRecipients.filter(row => row.type === "client")
    //     const newRecipientsSuppliers = newRecipients.filter(row => row.type === "supplier")
    //     const recipientsAllClientsIndex = campaignRecipientsTemp.findIndex(row => row.uid === "allClients")
    //     const recipientsAllSuppliersIndex = campaignRecipientsTemp.findIndex(row => row.uid === "allSuppliers")

    //     if(allClients){
    //         campaignRecipientsTemp = campaignRecipientsTemp.filter(row => row.type !== "client");
    //         if(recipientsAllClientsIndex < 0)
    //             campaignRecipientsTemp.push(allClients)
    //     }else {
    //         if(newRecipientsClients.length > 0){
    //             if(recipientsAllClientsIndex >= 0)
    //                 campaignRecipientsTemp.splice(recipientsAllClientsIndex, 1)
    //             let recipientsClient = newRecipients.filter(row => row.type === "client")
    //             recipientsClient.map(row => {
    //                 let existAlready = campaignRecipientsTemp.find(elmnt => elmnt.uid === row.uid)
    //                 if(!existAlready)
    //                     campaignRecipientsTemp.push(row)
    //             })
    //         }
    //     }
    //     if(allSuppliers){
    //         //campaignRecipientsTemp = campaignRecipientsTemp.filter(row => row.type !== "supplier");
    //         if(recipientsAllSuppliersIndex < 0){
    //             campaignRecipientsTemp = []
    //             campaignRecipientsTemp.push(allSuppliers)
    //         }
    //     }else {
    //         if(newRecipientsSuppliers.length > 0){

    //             if(recipientsAllSuppliersIndex >= 0)
    //                 campaignRecipientsTemp.splice(recipientsAllSuppliersIndex, 1)
    //             let recipientsSupplier = newRecipients.filter(row => row.type === "supplier")
    //             recipientsSupplier.map(row => {
    //                 let existAlready = campaignRecipientsTemp.find(elmnt => elmnt.uid === row.uid)
    //                 if(!existAlready)
    //                     campaignRecipientsTemp.push(row)
    //             })
    //         }
    //     }

    //     // console.log(campaignRecipientsTemp)
    //     // setSelectedEntities(campaignRecipientsTemp)
    //     if(!uid)
    //         setCampaign({
    //             ...campaign,
    //             recipients: campaignRecipientsTemp
    //         })
    //     else 
    //         setCampaignData({
    //             ...campaignData,
    //             recipients: campaignRecipientsTemp
    //         })
        
        
    // }

    // const deleteRecipientHandler = (recipientUid) => {
    //     let recipientsTemp = !uid ? [...campaign?.recipients] : [...campaignData?.recipients];
    //     let recipientIndex = recipientsTemp.findIndex(row => row.uid === recipientUid);
    //     if(recipientUid == "allSuppliers") setAllSuppliersSelected(false)
    //     if(recipientIndex >= 0)
    //         recipientsTemp.splice(recipientIndex, 1)

    //     // setSelectedEntities(recipientsTemp)
    //     if(!uid)
    //         setCampaign({
    //             ...campaign,
    //             recipients: recipientsTemp
    //         })
    //     else 
    //         setCampaignData({
    //             ...campaignData,
    //             recipients: recipientsTemp
    //         })
    // }

    const addAttachmentHandler = (attachment) => {
        let attachmentsTemp = !uid ? [...campaign?.attachments, attachment] : [...campaignData?.attachments, attachment] ;
        if(!uid)
            setCampaign({
                ...campaign,
                attachments: attachmentsTemp
            })
        else 
            setCampaignData({
                ...campaignData,
                attachments: attachmentsTemp
            })
    }

    const deleteAttachmentHandler = (attachmentName) => {

        let attachmentsTemp = !uid ? [...campaign?.attachments] : [...campaignData?.attachments] ;
        let attachmentIndex = attachmentsTemp.findIndex(row => row.uid === attachmentName);
        if(attachmentIndex >= 0)
            attachmentsTemp.splice(attachmentIndex, 1)
        if(!uid)
            setCampaign({
                ...campaign,
                attachments: attachmentsTemp
            });
        else 
            setCampaignData({
                ...campaignData,
                attachments: attachmentsTemp
            });
    }

    const optionChangeHandler = (name, value) => {
        // const name = e.target.name;
        // const value = e.target.value;
        if(!uid)
            setCampaign({
                ...campaign,
                [name]: value
            })
        else 
            setCampaignData({
                ...campaignData,
                [name]: value
            })
    }

    const setCampaignRecipients = (recipients) => {
        if(!uid)
            setCampaign({
                ...campaign,
                recipients: recipients
            })
        else 
            setCampaignData({
                ...campaignData,
                recipients: recipients
            })
    } 

    const fileChangeHandler = (e) => {
        let file = e.target.files[0]
        let fileB64 = "";
        if(file){
            let existFile = !uid ? campaign?.attachments.find(row => row.attachment === file.name) : campaignData?.attachments.find(row => row.attachment === file.name) 
            if(existFile){
                notyf.error("Un fichier avec le même nom a déjà été ajouté")
            }else {
                if(file.size > 5242880)
                    notyf.error("La taille du fichier ne doit pas dépasser 5 MO")
                
                convertFileToBase64(file).then(result => {
                    fileB64 = result;
                    addAttachmentHandler({
                        attachment: file.name,
                        content: fileB64,
                        new:true
                    })
                }).catch(err => {
                    notyf.error("Une erreur s'est produite, veuillez réessayer")
                    console.log(err)
                })
                //console.log(file)
            }
        }
    }

    const titleChangeHandler = (e) => {
        const value = e.target.value
        if( !uid )
            setCampaign({
                ...campaign,
                title: value
            })
        else 
            setCampaignData({
                ...campaignData,
                title: value
            })
    }

    const addTextHandler = (text) => {
        console.log("addTextHandler")
        if(!uid)
            setCampaign({
                ...campaign,
                text: text
            })
        else 
            setCampaignData({
                ...campaignData,
                content: text
            })
    }

    const checkCampaignForm = () => {
        let errors = []
        if(startDateError)
            errors.push("Date de début de campagne invalide")
        if(endDateError)
            errors.push("Date de fin de campagne invalide")
        if(firstRecallDateError)
            errors.push("Date de première relance invalide")
        if(secondRecallDateError)
            errors.push("Date de seconde relance invalide")
        if(!uid){
            if(campaign?.recipients.length === 0)
                errors.push("Veuillez désigner au moins un destinataire")
            if(campaign?.title.length === 0)
                errors.push("Veuillez saisir le titre")
            if(campaign?.text.length === 0)
                errors.push("Veuillez saisir le contenu de la campagne")
        }else{
            if(campaignData?.recipients.length === 0)
                errors.push("Veuillez désigner au moins un destinataire")
            if(campaignData?.title.length === 0)
                errors.push("Veuillez saisir le titre")
            if(campaignData?.content.length === 0)
                errors.push("Veuillez saisir le contenu de la campagne")
        }
        return errors;
    }

    const createCampaign = () => {
        setCreateCampaignLoading(true)
        Axios.post('campaign', campaign).then(response => {
            if (response?.data?.success) {
                notyf.success("La campagne a bien été créée")
                // setCampaign({
                //     startDate: selectedStartDate.getTime(),
                //     endDate: selectedEndDate.getTime(),
                //     attachments: [],
                //     recipients: [],
                //     title: '',
                //     text: ''
                // })
                navigate(-1);
            } else
                notyf.error("Erreur lors de la création de la campagne");
        }).catch(response => {
            notyf.error("Échec lors de la création de la campagne")
        }).finally(() => {
            setCreateCampaignLoading(false)
        })
    }

    const createCampaignClickHandler = () => {
        let errors = checkCampaignForm()
        if(errors.length > 0)
            setCampaignErrors(errors)
        else{
            setCampaignErrors([]);
            createCampaign()
        }
    }

    const updateCampaign = () => {
        setCreateCampaignLoading(true)
        Axios.put('campaign', campaignData).then(response => {
            if (response?.data?.success) {
                notyf.success("La campagne a bien été modifiée")
                navigate(-1);
            } else
                notyf.error("Erreur lors de la modification de la campagne");
        }).catch(response => {
            notyf.error("Échec lors de la modification de la campagne")
        }).finally(() => {
            setCreateCampaignLoading(false)
        })
    }

    const updateCampaignClickHandler = () => {
        let errors = checkCampaignForm()
        if(errors.length > 0)
            setCampaignErrors(errors)
        else{
            setCampaignErrors([]);
            updateCampaign()
        }
    }
    
    const getAttachmentPdf = (invoiceUid) => {
     
        Axios.get(`/campaign/${invoiceUid}/attachmentPdf`)
        .then(response => {
            
            const linkSource = 'data:application/octet-stream;base64,'+response.data.pdfFile;
            const link = document.createElement("a");
            link.href = linkSource;
            link.download = response.data.attachmentName;
            link.click();
            if (clicked) clicked();

        }).catch(err => {
            // notyf.error('Une erreur s\'est produite !')
           
        })
    }
           
    return (
        <>  
            <CFContainer>
                <CFHeaderWrapper>
                    <CFHeader>
                        <CFHeaderTitle style={{marginLeft: "95px"}}>
                            {!uid ? t("menu:newCampaign", "Nouvelle campagne") : t("menu:newCampaign", "Edition campagne") } 
                        </CFHeaderTitle>
                        <CFHeaderActions>
                            { createCampaignLoading ?
                                <Spinner animation="border" variant="primary" />
                                : !uid ? (
                                    <BtnOutlineAction
                                        color={theme.colors.primary}
                                        onClick={() => createCampaignClickHandler()}
                                    >
                                        <span>Valider</span> 
                                    </BtnOutlineAction>
                                ) : 
                                    
                                    activeTab != "logs" && campaignData?.status !== "COMPLETED" && 
                                        <BtnOutlineAction
                                            color={theme.colors.primary}
                                            onClick={() => updateCampaignClickHandler()}
                                        >
                                            <span>Valider</span> 
                                        </BtnOutlineAction>
                                    
                            }
                        </CFHeaderActions>
                    </CFHeader>
                    <NavTabs
                        tabsList={tabsList}
                        activeTab={activeTab}
                        onTabClick={tabClickHandler}
                    />
                </CFHeaderWrapper>

                <CFBody>
                {
                    activeTab == "parametres" ? 
                        <>
                            
                            {campaignErrors && campaignErrors.length > 0 ? (
                                <div className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                                    <ul>
                                        {
                                            campaignErrors.map((error, index) => (<li key={index}>{error}</li>))
                                        }
                                    </ul>
                                </div>
                                ) : null
                            }
                            <CFSection>
                                <CFEditorWrapper>
                                    <CFTitle>{campaign?.title || campaignData?.title || ''}</CFTitle>
                                    <TextEditor 
                                        content={campaign?.text || campaignData?.content || ""}
                                        disabled={campaignData?.status !== "SCHEDULED" && campaignData?.id}
                                        addTextHandler={addTextHandler}
                                    />
                                </CFEditorWrapper>
                                <CFFormWrapper>
                                    <CFFormSection>
                                        <CFFormGroup>
                                            <FormLabel htmlFor="title" >Titre<MandatoryMarker /> </FormLabel>
                                            <FormInput 
                                                id="title"
                                                type="text" 
                                                placeHolder="Titre" 
                                                value={campaignData?.title||campaign?.title||""} 
                                                onChange={e => titleChangeHandler(e)} 
                                                maxLength={255}
                                                disabled={campaignData?.status !== "SCHEDULED" && campaignData?.id}
                                                name="title" 
                                            />
                                        </CFFormGroup>
                                        <CFFormGroup style={{marginBottom: "10px"}}>
                                            <FormLabel>Dates</FormLabel>
                                        </CFFormGroup>
                                        <CFFormGroup style={{justifyContent: "inherit"}}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                                <CTooltip title="Date début campagne">
                                                    <DPContainer>
                                                        <DatePicker
                                                            id="date-picker-dialog"
                                                            label={<>Date début campagne<MandatoryMarker/></>}
                                                            format="dd/MM/yyyy"
                                                            minDate={currentDate}
                                                            value={uid ? new Date(+campaignData?.campaignDate) : selectedStartDate}
                                                            // maxDate={uid ?new Date(+campaignData?.endDate) : selectedEndDate}
                                                            disabled={campaignData?.status !== "SCHEDULED" && campaignData?.id}
                                                            onChange={handleStartDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            invalidDateMessage="Format de date invalide"
                                                            maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                                            minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                                            renderInput={(params) => <TextField {...params} />}
                                                            // open={true} control open dialog state
                                                        />
                                                    </DPContainer>
                                                </CTooltip>
                                                {/* <DPContainer>
                                                    <DatePicker
                                                        id="date-picker-dialog"
                                                        label="Date fin campagne"
                                                        format="dd/MM/yyyy"
                                                        value={uid ?new Date(+campaignData?.endDate) : selectedEndDate} 
                                                        onChange={handleEndDateChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        minDate={uid ? new Date(+campaignData?.campaignDate) : selectedStartDate}
                                                        //maxDate={generateMaxEndDate()}
                                                        invalidDateMessage="Format de date invalide"
                                                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />  
                                                </DPContainer> */}
                                                {(campaignData?.campaignDate || selectedStartDate) && <CTooltip title="Date première relance">
                                                    <DPContainer>
                                                        <DatePicker
                                                            id="date-picker-dialog"
                                                            label="Date première relance"
                                                            format="dd/MM/yyyy"
                                                            minDate={new Date(+campaignData?.campaignDate)}
                                                            value={uid ? (campaignData?.firstRecallDate ? new Date(+campaignData?.firstRecallDate) : null) : selectedFirstRecallDate}
                                                            disabled={campaignData?.status === "COMPLETED" && campaignData?.id}
                                                            onChange={handleFirstRecallDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            invalidDateMessage="Format de date invalide"
                                                            minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </DPContainer>
                                                </CTooltip>}
                                                {(campaignData?.firstRecallDate || selectedFirstRecallDate) && <CTooltip title="Date deuxième relance">
                                                    <DPContainer>
                                                        <DatePicker
                                                            id="date-picker-dialog"
                                                            label="Date deuxième relance"
                                                            format="dd/MM/yyyy"
                                                            minDate={new Date(+campaignData?.firstRecallDate)}
                                                            value={uid ? (campaignData?.secondRecallDate ? new Date(+campaignData?.secondRecallDate) : null) : selectedSecondRecallDate}
                                                            disabled={campaignData?.status === "COMPLETED" && campaignData?.id}
                                                            onChange={handleSecondRecallDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            invalidDateMessage="Format de date invalide"
                                                            minDateMessage="La date ne doit pas être antérieure à la date de première relance"
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </DPContainer>
                                                </CTooltip>}
                                            </LocalizationProvider>
                                        </CFFormGroup>
                                        {/* <CFFormGroup>
                                            <ToggleLabel active={uid?+campaignData?.toRead:+campaign?.toRead} >Option de lecture</ToggleLabel>
                                            <ToggleIcon active={uid?+campaignData?.toRead:+campaign?.toRead}>
                                                {
                                                    
                                                    +campaign?.toRead || +campaignData?.toRead ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("toRead", 0)}
                                                        /> :
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("toRead", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CFFormGroup> */}
                                        {/* <CFFormGroup>
                                            <ToggleLabel active={uid?+campaignData?.toRelaunch:+campaign?.toRelaunch} >Relance si non lecture</ToggleLabel>
                                            <ToggleIcon active={uid?+campaignData?.toRelaunch:+campaign?.toRelaunch}>
                                                {
                                                    +campaign?.toRelaunch || +campaignData?.toRelaunch ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("toRelaunch", 0)}
                                                        /> : 
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("toRelaunch", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CFFormGroup> */}
                                        {/* <CFFormGroup>
                                            <ToggleLabel active={uid?+campaignData.toSign: +campaign?.toSign}>Signature électronique </ToggleLabel>
                                            <ToggleIcon active={uid?+campaignData.toSign: +campaign?.toSign}>
                                                {
                                                    +campaign?.toSign || +campaignData?.toSign ? 
                                                        <ToggleOnIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("toSign", 0)}
                                                        /> : 
                                                        <ToggleOffIcon 
                                                            fontSize="small"
                                                            onClick={() => optionChangeHandler("toSign", 1)}
                                                        />
                                                }
                                            </ToggleIcon>
                                        </CFFormGroup> */}
                                        {/* { !uid ? */}
                                            <CFFormGroup>
                                                <FormLabel>Pièces jointes</FormLabel>
                                                <AttachementsContainer>
                                                    {
                                                        campaign?.attachments?.length > 0 ?
                                                            campaign?.attachments?.map((attachment, index) => (
                                                                <Attachment
                                                                    key={index}
                                                                    attachment={attachment}
                                                                    deleteAttachement={deleteAttachmentHandler}
                                                                    getAttachmentPdf={getAttachmentPdf}
                                                                    canDownloadAttachement={false}
                                                                    canDeleteAttachement={true}
                                                                />
                                                            ))
                                                        : campaignData?.attachments?.length > 0 ?
                                                            campaignData?.attachments?.map((attachment, index) => (
                                                                <Attachment
                                                                    key={index}
                                                                    attachment={attachment}
                                                                    deleteAttachement={deleteAttachmentHandler}
                                                                    getAttachmentPdf={getAttachmentPdf}
                                                                    canDownloadAttachement={true}
                                                                    canDeleteAttachement={true}
                                                                />
                                                            )) :null
                                                    }
                                                </AttachementsContainer>
                                            </CFFormGroup>
                                        
                                            {/* <>
                                                <FormLabel >Pièces jointes</FormLabel>
                                                <ContentPiecesOrDest>
                                                    { 
                                                        attachmentFileData?.map((entity, index) =>(      
                                                            <DivGlp key={index}  >
                                                                <DivLabel  onClick={() => getAttachmentPdf(entity.uid)}>
                                                                        {entity.attachment}
                                                                </DivLabel>
                                                            </DivGlp>
                                                        )) 
                                                    }
                                                </ContentPiecesOrDest>
                                            </>  */}
                                        
                                        {/* {!uid && */}
                                            {(campaignData?.status === "SCHEDULED" || !campaignData?.id) && <CFFormGroup>
                                                {/* <FormLabel ></FormLabel> */}
                                                <FileUploaderBtn
                                                    btnLabel="Importer"
                                                    handleChange={fileChangeHandler}
                                                    name="file"
                                                    icon={<CloudUploadIcon style={{fontSize: '1.2rem', marginLeft: '0.5rem'}} />}
                                                />
                                            </CFFormGroup>}
                                        {/* } */}

                                    </CFFormSection>
                                        <CFFormSection>
                                            <CFFormGroup>
                                                <FormLabel >Sélection des contacts </FormLabel>
                                            </CFFormGroup>
                                            <CFFormGroup>
                                                {/* <FormLabel ></FormLabel> */}
                                                <AddRecipientsBtn onClick={() => setShowRepertoireModal(true)}>
                                                    {
                                                        !uid ? 
                                                            <span>Ajouter des destinataires</span>
                                                        : (campaignData?.status !== "SCHEDULED" && campaignData?.id ? <span>Consulter les destinataires</span>
                                                        : <span>Modifier les destinataires</span>)
                                                    }
                                                    <PersonAddIcon style={{fontSize: '1.2rem', marginLeft: '0.5rem'}} />
                                                </AddRecipientsBtn>
                                                {
                                                    showRepertoireModal ? ( 
                                                        <RepertoireModal 
                                                            tempEntities={!uid ? campaign?.recipients : campaignData?.recipients}
                                                            // setSelectedEntities={setSelectedEntities}
                                                            campaignUid={uid}
                                                            setCampaignRecipients={setCampaignRecipients}
                                                            // deletedEntities={deletedEntities}
                                                            // setDeletedEntities={setDeletedEntities}
                                                            show={showRepertoireModal}
                                                            disabled={campaignData?.status !== "SCHEDULED" && campaignData?.id && campaignData?.id}
                                                            handleClose={() => setShowRepertoireModal(false)}
                                                            // addRecipientsHandler={addRecipientsHandler}
                                                            // deleteRecipientHandler={deleteRecipientHandler}
                                                            allSuppliersSelected={allSuppliersSelected}
                                                            setAllSuppliersSelected={setAllSuppliersSelected}
                                                        /> 
                                                    )
                                                    : null 
                                                }
                                            </CFFormGroup>
                                          
                                            
                                            <CFFormGroup>
                                                <FormLabel >Destinataires</FormLabel>
                                                <AttachementsContainer>
                                                    {uid ?
                                                        campaignData?.recipients?.length > 0 &&
                                                        //     campaign.recipients.map((recipient, index) => (
                                                        //         <Recipient 
                                                        //             key={index}
                                                        //             recipient={recipient} 
                                                        //             deleteRecipient={deleteRecipientHandler}
                                                        //         />
                                                        //     ))

                                                            (campaignData?.recipients[0]?.uid === "allSuppliers"  ? <b>Tous</b> : <b>{campaignData?.recipients?.length==0 ? "Aucun" : campaignData?.recipients?.length}</b>)
                                                            
                                                        :  
                                                            // campaignData?.recipients?.length > 0 ? 
                                                            //     campaignData.recipients.map((recipient, index) => (
                                                            //         <Recipient 
                                                            //             key={index}
                                                            //             recipient={recipient} 
                                                            //             deleteRecipient={deleteRecipientHandler}
                                                            //         />
                                                            //     )):null
                                                            (campaign?.recipients[0]?.uid == "allSuppliers" ? <b>Tous</b> : <b>{campaign?.recipients?.length==0 ? "Aucun" : campaign?.recipients?.length}</b>)
                                                            
                                                    }
                                                </AttachementsContainer>
                                            </CFFormGroup>
                                            
                                        </CFFormSection>
                                    {/* :
                                        <CFFormSection>
                                            <FormLabel >Destinataires</FormLabel>
                                            <ContentPiecesOrDest>
                                                { 
                                                    campaignData?.destinataire? formatEntities(campaignData?.destinataire)?.map((entity,index) => (      
                                                        <DivGlp key={index}  >
                                                            <DivLabel>
                                                                    {entity}
                                                            </DivLabel>
                                                        </DivGlp>
                                                    )) :null
                                                }
                                            </ContentPiecesOrDest> 
                                        </CFFormSection>
                                    } */}
                                </CFFormWrapper>
                            </CFSection>
                        </>
                    :
                        uid ? 
                            <CampaignLogs
                                campaignUid={uid}                                    
                                campaignLogs={campaignLogs}
                            /> : null
                }
                </CFBody>
            </CFContainer>
        </>
    )
}

export default CampaignForm
