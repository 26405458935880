import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const RFContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const RFBody = styled.div`
    width: 100%;
    height: 100%;
`

export const RFTableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
   /* height:calc(100vh - 234px); */
`
export const HeaderActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;

    @media ${({theme}) => theme.device.laptop} {
        /* flex-direction: column; */
    }
`
export const SupplierLink = styled(Link)`
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
`

export const SupplierUsersLink = styled(Link)`
    background-color: #1e1e1e;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.01rem 1rem;
    text-decoration: none;
    border-radius: 8px;
    width: fit-content;

    &:hover {
        background-color: #FFFFFF;
        color: #1e1e1e;
        border:1px solid #1e1e1e;
    }
`