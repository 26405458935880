import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    sortQuery: 'unreadMessages',
    reverse: {
        unreadMessages: false
    },
    file: null,
    selectedRows: null,
    selectedAllRows: false,
    importErrorsCount: 0,
    quickFilter: null
}

const rejectSetSortQuery = (state, action) => {
    return updateObject(state,{sortQuery: action.sortQuery})
}

const rejectSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: {...action.reverse}
    }
    //return updateObject(state, {reverse: action.reverse})
}

const rejectSetQuickFilter = (state, action) => updateObject(state, {
    quickFilter : action.quickFilter
})

const rejectUpdateShowFilters = (state, action) => {
    return updateObject(state, {showFilters: action.showFilters})
}

const rejectSetFiltersQuery = (state, action) =>{
    return updateObject(
    state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)
} 

const rejectStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const rejectSuccess = (state, action) => {
    const selectedRows = state.selectedRows && action.data ? {...state.selectedRows} : null; 
    const page = action.page;
    const selectedAllRows = !selectedRows || action.data.find(d => !selectedRows[page=="codaRefused"?d.invoiceUid:d.uid]) ? false : true;
    
    return updateObject(state, {
        data: action.data,
        count: action.count,
        error: null,
        loading: false,
        selectedAllRows: selectedAllRows,
        selectedRows: selectedRows
    })
}

const rejectFail = (state, action) => {
    return updateObject(state, {
        data: [],
        error: action.error,
        loading: false,
        count: 0,
        currentPage: 1,
        filtering: false
    })
}

const rejectSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const rejectSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const rejectGetDocumentFile = (state, action) => updateObject(state, {
    file: action.file
})

const rejectSelectRow = (state, action) => {
    let newSelectedRows = {...state.selectedRows, [action.rowId]: action.selected}
    const page = action.page;
    const selectedAllRows = state.data.find(d => !newSelectedRows[page=="codaRefused"?d.invoiceUid:d.uid]) ? false : true;
    return updateObject(state, {
        selectedAllRows: selectedAllRows,
        selectedRows: newSelectedRows
    })
}

const rejectSelectAllRows = (state, action) => {
    let data = state.data;
    let page=action.page;
    let newSelectedRows = {...state.selectedRows}
    data?.map(row => {
        newSelectedRows = {...newSelectedRows, [page=="codaRefused"?row.invoiceUid:row.uid]: (row.rejectType === "err01" && action.selectedAll ? "err01" : action.selectedAll)}
        // newSelectedRows = {...newSelectedRows, [row.uid]: action.selectedAll}
    })
    const selectedAllRows = data.find(d => !newSelectedRows[page=="codaRefused"?d.invoiceUid:d.uid]) ? false : true;
    return updateObject(state, {
        selectedAllRows: selectedAllRows,
        selectedRows: newSelectedRows
    })
}

const rejectSetImportErrorsCount = (state, action) => updateObject(state, {
    importErrorsCount: action.importErrorsCount
})

const rejectSetUnreadSuccess = (state, action) => {
    const newData = [...state.data];
    newData.find(d => d.invoiceUid === action.uid).unreadMessages = 1;
    return updateObject(state, {
        data: newData
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.REJECT_START: return rejectStart(state, action)
        case actionTypes.REJECT_SUCCESS: return rejectSuccess(state, action)
        case actionTypes.REJECT_FAIL: return rejectFail(state, action)
        case actionTypes.REJECT_SET_PAGE: return rejectSetPage(state, action)
        case actionTypes.REJECT_SET_PAGESIZE: return rejectSetPageSize(state, action)
        case actionTypes.REJECT_SET_FILTERSQUERY: return rejectSetFiltersQuery(state, action)
        case actionTypes.REJECT_SET_SORTQUERY: return rejectSetSortQuery(state, action)
        case actionTypes.REJECT_SET_REVERSESORT: return rejectSetReverseSort(state, action)
        case actionTypes.REJECT_UPDATE_SHOWFILTERS: return rejectUpdateShowFilters(state, action)
        case actionTypes.REJECT_GET_DOCUMENT_FILE: return rejectGetDocumentFile(state, action)
        case actionTypes.REJECT_SELECT_ROW: return rejectSelectRow(state, action)
        case actionTypes.REJECT_SELECT_ALL_ROWS: return rejectSelectAllRows(state, action)
        case actionTypes.REJECT_SET_IMPORT_ERRORS_COUNT: return rejectSetImportErrorsCount(state, action)
        case actionTypes.REJECT_SET_QUICKFILTER: return rejectSetQuickFilter(state, action)
        case actionTypes.REJECT_SET_UNREAD_SUCCESS: return rejectSetUnreadSuccess(state, action)
        default:
            return state
    }
}
export default reducer