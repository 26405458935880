import React, { useEffect, useState } from 'react'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';

function RVCAEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={true} bdColor={theme.colors.warning} color={theme.colors.warning} >
                        <AutorenewIcon />
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={theme.colors.warning}>
                            RVCA
                        </TETitle>
                        <TETime>
                            {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    showContent && (
                        <TEContent>
                            {
                                eventData?.comment && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("default:comment", "Commentaire : ")}
                                        </MessageFrom>
                                        <br />
                                        <MessageContent>
                                            {eventData?.comment?.slice(0, 128).split("\n").map(c => <><span>{c}</span><br/></>)}
                                            {eventData?.comment?.length > 128 ? '...' : null}
                                        </MessageContent>
                                    </MessageWrapper>
                                )
                            }
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default RVCAEvent