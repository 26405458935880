import React, { useEffect, useState } from 'react';
import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import { TableErrContainer,Btn2Hd,TableErrDiv,TD,TBody, } from './RejectTracking.styled';
import { THead,Table,TH,THContainer, RejectLink } from '../../styles/Table.styled';
import {CTooltip} from '../../components/UI/CTooltip/CTooltip'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { getErrorLabel } from '../../shared/utility';
function Rejets({BtnClickHandler,isActive,isNone}) {
    const [rejectsData, setRejectsData] = useState([]);

    useEffect(() => {
        Axios.get("/reject//RejectsByType").then(res => {
            setRejectsData(res.data)
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    } , [])

    const is99Code = (code) => {
        return "err99" === code
    }

    let body=(  
        rejectsData.map(row => (
            <tr key={row.label} style={{borderBottom:"1px solid #809FB8"}}>
                <TD>
                    <CTooltip title={getErrorLabel(row?.label) || ""}><RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectTracking"} state={{errorFilter : row?.label}}>{row.label}</RejectLink></CTooltip>
                </TD>
                <TD >
                    <RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectPending"} state={{errorFilter : row?.label}}>{row.en_cours}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectPending"} state={{errorFilter : row?.label, fromYesterday: true}}>{row.en_cours_j_1}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectArchives"} state={{errorFilter : row?.label, fromYesterday: true}}>{row.traite_j_1}</RejectLink>
                </TD>
                <TD >
                    <RejectLink to={row.label === "err51" ? "/reject51" : is99Code(row.label) ? "/reject99":"/rejectTracking"} state={{errorFilter : row?.label}}>{row.prendre_en_compte}</RejectLink>
                </TD>
            </tr>
        ))
    )

    let head=(
        <THead>
            <tr style={{borderBottom:"1px solid #809FB8", background: "rgb(86,41,111)"}}>
                <TH key={"column64"} width="50px" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="Type rejet">
                            <span>
                                {"Type rejet"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH key={"column54"} width="50px" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="En cours">
                            <span>
                                {"En cours"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH key={"column604"} width="50px" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="Dont lot du jour (Date J-1)">
                            <span>
                                {"Dont lot du jour (Date J-1)"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH key={"column6540"} width="50px" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="Traité J-1">
                            <span>
                                {"Traité J-1"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
                <TH key={"column6454"} width="50px" scope="col">
                    <THContainer style={{justifyContent: "start"}}>
                        <CTooltip title="Rejets à prendre en compte">
                            <span>
                                {"Rejets à prendre en compte"}
                            </span>
                        </CTooltip>
                    </THContainer>
                </TH>
            </tr>
        </THead>
    )

    return (
        <TableErrContainer isActive={isActive} isNone={isNone}>
          
            <TableErrDiv>
            <div style={{margin:"15px"}}>
            
                <Btn2Hd onClick={BtnClickHandler} style={{cursor:"pointer"}}>
                    <CloseOutlinedIcon style={{fontSize: "1.5rem", color:"#fff"}} />
                </Btn2Hd>
                    
                <Table>
                {head}
                    <TBody >
                        {body}
                    </TBody>
                </Table>
             </div>
            </TableErrDiv>
        
     </TableErrContainer>
    )
}
const mapStateToProps = (state) => ({

})
const mapDispatchToProps = dispatch => ({
  
})
export default connect(mapStateToProps, mapDispatchToProps)(Rejets)
