import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    filtersQuery: {
    }
}

const auditTrailStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const auditTrailSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count,
    error: null,
    loading: false
})

const auditTrailFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const auditTrailSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const auditTrailSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const auditTrailSetFilterQuery = (state, action) => updateObject(state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.AUDIT_TRAIL_START: return auditTrailStart(state, action)
        case actionTypes.AUDIT_TRAIL_SUCCESS: return auditTrailSuccess(state, action)
        case actionTypes.AUDIT_TRAIL_FAIL: return auditTrailFail(state, action)
        case actionTypes.AUDIT_TRAIL_SET_PAGE: return auditTrailSetPage(state, action)
        case actionTypes.AUDIT_TRAIL_SET_PAGESIZE: return auditTrailSetPageSize(state, action)
        case actionTypes.AUDIT_TRAIL_SET_FILTERSQUERY: return auditTrailSetFilterQuery(state, action)
        default:
            return state
    }
}
export default reducer