const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

const theme = {
    colors: {
        body: "#F5FBFF",
        text: "#000000",
        content: "#ffffff",
        sendBtn : "#6C5CE7",
        cancelBtn : "#809FB8",
        btnBorder : "#D9E1E7",
        btnHover : '#F1F4F9',
        primary : "#1e1e1e",
        secondary : "#1e1e1e",
        success : "#00B894",
        danger : '#EE5A5A',
        warning : '#EECC5A',
        redLight : "#EE5A5A66",
        greenLight : "#55EFC466",
        orangeLight : "#EECC5A66",
        grey : "#808080"

    },
    status: {
        background: {
            "200" : "#EECC5A66",
            "201" : "#55EFC466",
            "202" : "#EE5A5A66",
            "203" : "#F5FBFF",
            "204" : "#F1F4F9",
            "205" : "#D9E1E7",
            "206": "#EE5A5A66",
            "207": "#EE5A5A66",
            "208": "#EE5A5A66",
            "209" : "#F1F4F9",
            "210" : "#D9E1E7",
            "211": "#EE5A5A66",
            "212": "#EE5A5A66",
            "213": "#EE5A5A66"
        },
        text: {
            "200" : "#8ccfff",
            "201" : "#00B894",
            "202" : "#8cf0ff",
            "203" : "#0090FF",
            "204" : "#56296f",
            "205" : "#6ade7e",
            "206": "#ff0000",
            "207": "orange",
            "208": "#be4f62",
            "209" : "#99B2C6",
            "210" : "red",
            "211": "green",
            "212": "#568203",
            "213": "#EE5A5A",
            "err51": "#ffe6e6"
        }
    },
    device : {
        mobileS: `(min-width: ${size.mobileS})`,
        mobileM: `(min-width: ${size.mobileM})`,
        mobileL: `(min-width: ${size.mobileL})`,
        tablet: `(min-width: ${size.tablet})`,
        laptop: `(min-width: ${size.laptop})`,
        laptopL: `(min-width: ${size.laptopL})`,
        desktop: `(min-width: ${size.desktop})`,
        desktopL: `(min-width: ${size.desktop})`
    },
    size : {
        mobileS: '320px',
        mobileM: '375px',
        mobileL: '425px',
        tablet: '768px',
        laptop: '1024px',
        laptopL: '1440px',
        desktop: '2560px'
    }
}

export default theme;