import React from 'react'
import { formatDate, showColumn } from '../../../shared/utility'
import { TD, TR } from '../../../styles/Table.styled';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableBody(props) {
    const {accountingAuditData, columns, setShowRemindModal, selectedRows, setSelectedRows} = props;
    const {t} = useTranslation();

    const getSelectedRowValue = (id) => {
        if (selectedRows)
            return selectedRows[id] || false
        else
            return false
    }

    const selectRowHandler = (e) => {
        const id = e.target.name;
        const val = e.target.checked;
        setSelectedRows(id, val)
    }
   
    return (
        <>
            {
                accountingAuditData ? accountingAuditData.map(row => (
                    <TR key={row.suCode+"-"+row.supplierCode} style={{cursor: "default"}}>
                        <TD>
                           {row?.id && <input type="checkbox" name={row?.id} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row?.id)} className="form-check-input" />}
                        </TD>
                        <CTooltip title={row.clientName || ""}><TD style={{display: !showColumn(columns,"suCode") && 'none'}}>
                            {row.suCode}
                        </TD></CTooltip>
                        <CTooltip title={row.supplierName || ""}><TD style={{display: !showColumn(columns,"supplierCode") && 'none'}}>
                            {row.supplierCode}
                        </TD></CTooltip>
                        <CTooltip title={row.contacts || ""}><TD style={{display: !showColumn(columns,"contacts") && 'none'}}>
                            {row.contacts}
                        </TD></CTooltip>
                        <TD style={{display: !showColumn(columns,"thresholdDate") && 'none'}}>
                            {row.id ? formatDate(+row?.thresholdDate) : "Aucune campagne de circularisation lancée"}
                        </TD>
                        <TD style={{display: !showColumn(columns,"sentDate") && 'none'}}>
                            {row.id ? (row?.sentDate ? formatDate(+row?.sentDate) : "Aucun mail envoyé") : "Aucune campagne de circularisation lancée"}
                        </TD>
                        <TD style={{display: !showColumn(columns,"statutoryAuditorEmail") && 'none'}}>
                            {row.id ? row.statutoryAuditorEmail : "Aucune campagne de circularisation lancée"}
                        </TD>
                        <TD>
                            {row.id && <RefreshIcon style={{color: "red", cursor: "pointer"}} onClick={() => setShowRemindModal({[row.id]: true})} />}
                        </TD>
                    </TR>
                )) : null
            }
        </>
    )
}

export default TableBody

