import React, { useCallback, useEffect,useState } from 'react';

import TablePagination from '../../../components/UI/TablePagination/TablePagination';
import TableActions from './TableActions/TableActions';
import TableHead from './TableHead/TableHead';
import TableBody from './TableBody/TableBody';
import TableFilters from './TableFilters/TableFilters';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import TitleHelmet from '../../../components/UI/TitleHelmet/TitleHelmet';
import Axios from '../../../axios-proas'
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../../styles/Common';
import { NoContent, SpinnerContainer, Table, TBody } from '../../../styles/Table.styled';
import { RFContainer, RFBody, RFTableContainer } from './CampaignLogs.styled';
import { Spinner } from 'react-bootstrap';

function CampaignLogs(props) {
    const {
        campaignUid,
        loading, 
        campaignLogsData, 
        count, 
        currentPage, 
        pageSize, 
        getcampaignLogsPage, 
        setcampaignLogsPage, 
        setcampaignLogsPageSize, 
        sortQuery, 
        reverse, 
        filtersQuery, 
        showFilters, 
        userRole, 
        updateShowFilters, 
        setcampaignLogsFilters, 
        setSortQuery, 
        setReverse,
    } = props;
    const {t} = useTranslation();
    const {state} = useLocation();
    const userType = localStorage.getItem('type');
    const[density,setDensity]=useState("2.25rem")
    const [showAll, setShowAll] = useState();
    const [loadColumns, setLoadColumns] = useState(false)
    const navTitre = "campaignLogs";
    const[columns,setColumns]=useState([])

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{navTitre:navTitre}})
            .then(response => {
                let tempColumns = response?.data?.columns
                if (tempColumns === null)
                    tempColumns = {creationDate: 1, title: 1, entities: 1};
                let columnsCopy = [

                    {title: t("refClient:cr", "Date création"), field: 'creationDate', show:tempColumns.creationDate, width:"250px"},
                    {title: t("supplier:sta", "Type log"), field: 'logType', show:tempColumns.logType, width:"200px"},
                    {title: t("refClient:ch", "Message"), field: 'message', show:tempColumns.message}

                ]
                setColumns(columnsCopy)
                columnsCopy.filter( column => column?.show == 0 ).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns])

    useEffect(() => {
        let filtersTemp = {
            ...filtersQuery,
            campaignUid: campaignUid
        }
        let filtersProps = null;
        if(state?.filtersProps){
            filtersProps = state.filtersProps;
            filtersTemp = {
                ...filtersQuery,
                ...filtersProps
            }
            setcampaignLogsFilters(filtersTemp);
        }
        getcampaignLogsPage(currentPage, filtersTemp, sortQuery, reverse, pageSize)
        return () => {
            resetFilters();
        }
    }, [state, currentPage, pageSize])

    const isEmptyFilters = () => {
        if(filtersQuery === null )
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if(filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }
    
    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if(!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getcampaignLogsPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setcampaignLogsPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getcampaignLogsPage(1,filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery]);

    const resetFilters = () => {
        setcampaignLogsFilters(null);
        setcampaignLogsPage(1)
        getcampaignLogsPage(1, null, sortQuery, reverse, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
        ) : <NoContent>
                <span>{t("clientList:nocampaignLogs", "Aucun log à afficher")}</span> 
            </NoContent>;

    if(!loading && campaignLogsData?.length > 0){
        tSpinner = null;
        tBody = ( <TableBody columns={columns} campaignLogsData={campaignLogsData} userRole={userRole} />)
    }

    return (
        <RFContainer>
            <TitleHelmet title={"Transdev| " + t("Campaign Logs")} />
            <Header>
                    <HeaderActions>
                        <TableActions 
                            resetFilters={resetFilters}
                            showFilters={showFilters}
                            setShowFilters={updateShowFilters}
                            userType={userType}
                        />
                    </HeaderActions>
                </Header>  
                <RFBody>
                    <RFTableContainer>
                        <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                            currentPage={currentPage} 
                            pageChange={handlePageChange} 
                            totalElement={count} 
                            perPage={pageSize}
                            perPageChange={setcampaignLogsPageSize}
                        />
                       <Table>
                           <TableHead 
                                columns={columns}
                                reverse={reverse}
                                columnClickHandler={columnClickHandler}
                                userRole={userRole}
                            />
                            <TBody height={density}>
                                    <TableFilters 
                                        columns={columns}
                                        showFilters={showFilters}
                                        campaignLogsFilters={filtersQuery}
                                        filtersChange={setcampaignLogsFilters}
                                        applyFilters={handleApplyFiltering}
                                    />
                                    {tBody}
                            </TBody> 
                        </Table>
                         {tSpinner}
                        <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                            currentPage={currentPage} 
                            pageChange={handlePageChange} 
                            totalElement={count} 
                            perPage={pageSize}
                            perPageChange={setcampaignLogsPageSize}
                        />
                    </RFTableContainer>
                </RFBody>
            </RFContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.campaignLogs.loading,
    campaignLogsData: state.campaignLogs.data,
    count: state.campaignLogs.count,
    currentPage: state.campaignLogs.currentPage,
    pageSize: state.campaignLogs.pageSize,
    sortQuery: state.campaignLogs.sortQuery,
    reverse: state.campaignLogs.reverse,
    filtersQuery: state.campaignLogs.filtersQuery,
    showFilters: state.campaignLogs.showFilters,
    userRole: state.auth.role
})
const mapDispatchToProps = dispatch => ({
    getcampaignLogsPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.campaignLogsGetPage(page, filters, sort, reverse, pageSize)),
    setcampaignLogsPage: (page) => dispatch(actions.campaignLogsSetPage(page)),
    setcampaignLogsPageSize: (pageSize) => dispatch(actions.campaignLogsSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.campaignLogsSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.campaignLogsSetReverseSort(reverseS)),
    setcampaignLogsFilters: (filters) => dispatch(actions.campaignLogsSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.campaignLogsUpdateShowFilters(show)),
})
export default connect(mapStateToProps, mapDispatchToProps) (CampaignLogs)
