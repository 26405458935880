import React, { useState } from 'react';
import './GroupComponent.css';
import GroupUserModal from '../GroupUserModal/GroupUserModal';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';


function GroupComponent({groups, deleteGroup, addGroup,groupsCopie , groupsToDelete, disabled}) {
    const [showGroupModal, setShowGroupModal] = useState(false);
    return (
        <div className="groupComponent__container" style={{display: "block"}}>
            <div className="groupComponent__groupList_header">
                <label htmlFor="last_name">Regroupements d'entités</label>
            </div>
            {
                <>
                    <div className="groupComponent__groupList_container">
                        {
                            groups?.length  > 0 ? groups.map(group => (
                                <div className="groupComponent_pillElement_container" key={group.uid}>
                                    <div className="groupComponent__pillElement_infos">
                                        <BusinessIcon className="groupComponent__pillElement_icon" fontSize="small" />
                                        <div className="groupComponent__pillElement_label">{group.title}</div>
                                    </div>
                                    {!disabled && <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteGroup(group)} />}
                                    </div> 
                            )) : null
                        }
                    </div>
                    {!disabled && <div className="groupComponent__groupList_addBtn">
                        <button 
                            type="button" 
                            className="btn btn-secondary btn-sm"
                            onClick={() => setShowGroupModal(true)}
                        >
                            Associer 
                        </button>
                    </div>}
                </>
            }
            {showGroupModal ? (
                    <GroupUserModal
                        handleClose = {() => setShowGroupModal(false)}
                        show = {showGroupModal}
                        addGroupsUser={addGroup}
                        userGroups={groups}
                        deleteGroup={deleteGroup}
                        groupsCopie={groupsCopie}
                        groupsToDelete={groupsToDelete}
                    />
                ) : null}
        </div>  
    )
}

export default GroupComponent
