import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {  CIDeleteBtn  } from '../CategoryModal.styled';

function ConfirmDelete({handleClose, show,item, deleteCategoryHandler}) {
    const {t} = useTranslation();

    const updateClickHandler = () => {         
        deleteCategoryHandler(item)
        handleClose();
    }

    const cancelClickHandler = () => { 
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                       Voulez vous vraiment supprimer cette catégorie de documentation ?
                    </p>
                </Modal.Body>
                <Modal.Footer>
               

                <CIDeleteBtn style={{fontSize: "1.3rem"}}
                             onClick={cancelClickHandler}
                              >
                             {t('default:cancel', "Annuler")} 
                </CIDeleteBtn>

                <CIDeleteBtn    style={{fontSize: "1.3rem"}}
                                onClick={()=>updateClickHandler()}
                                color="#809FB8" border="#809FB8">
                             {t('default:confirm', "Confirmer")}
                </CIDeleteBtn>

                   

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmDelete
