 
import React, {useEffect, useState} from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './NonEngagingModal.css'
import MandatoryMarker from '../../UI/MandatoryMarker';
import Axios from "../../../axios-proas";
import { getNotyfObject } from '../../../shared/utility';

function NonEngagingModal({lineData, handleClose, refreshTable}) {
    const {t} = useTranslation();
    const notyf = getNotyfObject();
    const [line, setLine] = useState(lineData);

    useEffect(() => {
        setLine(lineData);
    }, [lineData])

    const inputChangeHandler = (e) => {
        const key = e.target.name;
        const tempLine = {
            ...line,
            [key]: e.target.value
        }
        setLine(tempLine)
    }

    const addLineHandler = () => {
        Axios.post("nonengaging", line).then(response => {
            if (response.data.success) {
                handleClose();
                notyf.success("Succès de la création de la ligne");
                refreshTable();
            } else {
                notyf.error("Erreur lors de la création de la ligne");
            }
        }).catch(err => {
            notyf.error("Échec de la création de la ligne" + (err.response.data.shownError ? " : " +err.response.data.shownError : ""));
        })
    }

    const editLineHandler = () => {
        Axios.put("nonengaging/"+line.id, line).then(response => {
            if (response.data.success) {
                handleClose();
                notyf.success("Succès de la modification de la ligne");
                refreshTable();
            } else {
                notyf.error("Erreur lors de la modification de la ligne");
            }
        }).catch(err => {
            notyf.error("Échec de la modification de la ligne" + (err.response.data.shownError ? " : " +err.response.data.shownError : ""));
        })
    }

    return (
        <>
            <Modal show={lineData} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{lineData?.id ? "Édition" : "Création"} de ligne de table de correspondance</Modal.Title>
                </Modal.Header>
                    <form onSubmit={e => {e.preventDefault(); if (line.id) editLineHandler(); else addLineHandler()}}>
                        <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                            <table style={{width: "100%", borderCollapse: "separate", borderSpacing: "0 20px"}}>
                                <tbody>
                                <tr>
                                    <td style={{width: "40%"}}>Code SU <MandatoryMarker/></td>
                                    <td style={{width: "60%"}}>
                                        <input type="text" placeholder="Code SU" className="form-control" name="suCode" maxLength={12} value={line.suCode} onChange={inputChangeHandler} disabled={line?.id} required/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Code tiers <MandatoryMarker/></td>
                                    <td>
                                        <input type="text" placeholder="Code tiers" className="form-control" name="supplierCode" maxLength={13} value={line.supplierCode} onChange={inputChangeHandler} disabled={line?.id} required/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Référence contrat</td>
                                    <td>
                                        <input type="text" placeholder="Référence contrat" className="form-control" name="contractRef" maxLength={50} value={line.contractRef} onChange={inputChangeHandler}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Élément 1 <MandatoryMarker/></td>
                                    <td>
                                        <input type="text" placeholder="Élément 1" className="form-control" name="element1" maxLength={12} value={line.element1} onChange={inputChangeHandler} required/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Élément 2 <MandatoryMarker/></td>
                                    <td>
                                        <input type="text" placeholder="Élément 2" className="form-control" name="element2" maxLength={12} value={line.element2} onChange={inputChangeHandler} required/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Élément 3 <MandatoryMarker/></td>
                                    <td>
                                        <input type="text" placeholder="Élément 3" className="form-control" name="element3" maxLength={12} value={line.element3} onChange={inputChangeHandler} required/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Élément 4 <MandatoryMarker/></td>
                                    <td>
                                        <input type="text" placeholder="Élément 4" className="form-control" name="element4" maxLength={12} value={line.element4} onChange={inputChangeHandler} required/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pourcentage élément 4 <MandatoryMarker/></td>
                                    <td>
                                        <input type="number" placeholder="Pourcentage élément 4" className="form-control" min={0.01} max={100} step={0.01} name="element4Percentage" value={line.element4Percentage} onChange={inputChangeHandler} required/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Élément 5</td>
                                    <td>
                                        <input type="text" placeholder="Élément 5" className="form-control" name="element5" maxLength={12} value={line.element5} onChange={inputChangeHandler}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Élément 6</td>
                                    <td>
                                        <input type="text" placeholder="Élément 6" className="form-control" name="element6" maxLength={12} value={line.element6} onChange={inputChangeHandler}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                {t('default:cancel', "Annuler")} 
                            </Button>
                            <Button variant="danger" type="submit" style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} /*onClick={() => {if (line.id) editLineHandler(); else addLineHandler()}}*/>
                                {t("global:validate")}
                            </Button>
                        </Modal.Footer>
                    </form>
            </Modal>
        </>
    )
}

export default NonEngagingModal

