import React, { useState } from 'react';
import './EntitiesComponent.css';
import RegionModal from '../EntityUserModal/RegionModal';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';


function RegionComponent({show, label, regions, deleteEntity, addEntity, entitiesType, userType, userUid,regionsToDelete,  regionsCopie, disabled}) {
    
    const [showEntityModal, setShowEntityModal] = useState(false);

    return (
        <div className="entitiesComponent__container" style={{display: show ? "block" : "none"}}>
            <div className="entitiesComponent__entitiesList_header">
                <label htmlFor="last_name">{label}</label>
            </div>
            {
                <>
                    <div className="entitiesComponent__entitiesList_container">
                        {
                            regions?.length  > 0 ? regions.map(entity => (
                                <div className="entitiesComponent_pillElement_container" key={entity.uid}>
                                    <div className="entitiesComponent__pillElement_infos">
                                        <BusinessIcon className="entitiesComponent__pillElement_icon" fontSize="small" />
                                        <div className="entitiesComponent__pillElement_label">{entity.name}</div>
                                    </div>
                                    {
                                    !userUid && !disabled ? (
                                    <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteEntity(entity)} />
                                    ) : null
                                    }
                                        
                                    </div> 
                            )) : null
                        }
                    </div>
                    {!disabled && <div className="entitiesComponent__entitiesList_addBtn">
                        <button 
                            type="button" 
                            className="btn btn-secondary btn-sm"
                            onClick={() => setShowEntityModal(true)}
                        >
                            Ajouter 
                        </button>
                    </div>}
                </>
            }
            {showEntityModal ? (
                    <RegionModal
                        handleClose = {() => setShowEntityModal(false)}
                        show = {showEntityModal}
                        addEntitiesUser={addEntity}
                        userEntities={regions}
                        entitiesType={entitiesType}
                        deleteEntity={deleteEntity}
                        regionsToDelete={regionsToDelete}
                          regionsCopie={regionsCopie}
                    />
                ) : null}
        </div>  
    )
}

export default RegionComponent
