import React, { useCallback, useEffect, useState } from 'react';
import TableActions from '../../components/EntitiesManager/TableActions/TableActions';
import TableHead from '../../components/EntitiesManager/TableHead/TableHead';
import TableBody from '../../components/EntitiesManager/TableBody/TableBody';
import TableFilters from '../../components/EntitiesManager/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import SearchIcon from '@mui/icons-material/Search';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { NoContent, SearchInput, SearchInputContainer, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import { EMBody, EMContainer, EMTableContainer } from './EntitiesManager.styled';
import { Spinner } from 'react-bootstrap';
import Axios from '../../axios-proas'
import { filter } from 'lodash';

function EntitiesManager(props) {
    const {
        loading,
        entitiesData,
        count,
        currentPage,
        pageSize,
        getEntitiesPage,
        setEntitiesPage,
        setEntitiesPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userRole,
        updateShowFilters,
        entityOwnerDetail,
        setEntitydetail,
        setEntitiesFilters,
        setSortQuery,
        setReverse,
        userUid,
        userLevel,
        userType,
        setEntitiesQuickFilter
    } = props;
    const { t } = useTranslation();
    const [showInformationModal, setShowInformationModal] = useState(false);
    const navTitre = "entitiesManager";
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const [tableProfile, setTableProfile] = useState(null);

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{navTitre:navTitre}})
            .then(response => {
                let tempColums = response?.data?.columns
                let columnsCopy = [
                    { title: t("entitiesList:entityCode"), field: 'code', show: tempColums.code },
                    { title: t("entitiesList:entityName"), field: 'name', show: tempColums.name },
                    { title: t("entitiesList:codePostal"), field: 'zipCode', show: tempColums.zipCode },
                    { title: t("entitiesList:city"), field: 'city', show: tempColums.city },     
                    // { title: t("entitiesList:creationDate"), field: 'creationDate', show: tempColums.creationDate },
                    { title: "Date début P2P", field: 'startP2P', show: tempColums.startP2P },
                    { title: "Période comptable début P2P", field: 'startP2PAccounting', show: tempColums.startP2PAccounting },
                    { title: "Période fin P2P", field: 'endP2P', show: tempColums.endP2P },
                    { title: "TUP vers société", field: 'tupEntity', show: tempColums.tupEntity },
                    { title: "Début TUP", field: 'tupStart', show: tempColums.tupStart },
                    { title: "Fin TUP", field: 'tupEnd', show: tempColums.tupEnd },
                    { title: "Société fusionnée", field: 'fusionEntity', show: tempColums.fusionEntity },
                    { title: "Début fusion", field: 'fusionStart', show: tempColums.fusionStart }
                ]
                setColumns(columnsCopy)
                columnsCopy.filter( column => column?.show == 0 ).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns])

    useEffect(() => {
        localStorage.setItem("origin", "owner")
        getEntitiesPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    useEffect(() => {
        if (tableProfile?.profileValue) {
            const parsedProfile = JSON.parse(tableProfile.profileValue);
            const columnsTemp = [...columns];
            columnsTemp.forEach(c => {
                c.show = parsedProfile?.columns[c.field];
            })
            setColumns(columnsTemp);
            columnsTemp.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);

            const newFilters = {...parsedProfile.filters};
            getEntitiesPage(1, newFilters, sortQuery, reverse, pageSize);
            setEntitiesPage(1)
            setEntitiesFilters(newFilters);
        } else if (tableProfile)
            resetFilters()
    }, [tableProfile])


    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getEntitiesPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setEntitiesPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getEntitiesPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setEntitiesFilters(null);
        setEntitiesPage(1)
        getEntitiesPage(1, null, sortQuery, reverse, pageSize)
    }

    const quickSearchChange = (quickSearchValue) => {
        let filtersTemp = {
            ...filtersQuery,
            quickSearch: quickSearchValue
        }
        setEntitiesQuickFilter('quickSearch')
        setEntitiesFilters(filtersTemp)
        if (quickSearchValue?.length >= 3)
            handleApplyFiltering(filtersTemp);
        else if (quickSearchValue?.length < 3 && filtersQuery?.quickSearch?.length >= 3) {
            filtersTemp.quickSearch = "";
            handleApplyFiltering(filtersTemp);
        }
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>Aucune entité à afficher</span>
        </NoContent>
    );

    if (!loading && entitiesData?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            userLevel={userLevel}
            columns={columns}
            clientsData={entitiesData}
            userRole={userRole}
            setEntitydetail={setEntitydetail}
            entityOwnerDetail={entityOwnerDetail}
        />)
    }
const showRegroupment=()=>{
    setShowInformationModal(true)
}
    return (
        <EMContainer>
            <TitleHelmet title={"Transdev | " + t("menu:entities")} />
            <Header>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        <SearchIcon />
                    </SearchInputContainer>
                    <TableActions
                        showRegroupment={showRegroupment}
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        userLevel={userLevel}
                        userType={userType}
                    />
                </HeaderActions>
            </Header>
            <EMBody>

 
         <TablePagination setDensity={setDensity} columns={columns.filter(column => column!==false)} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setEntitiesPageSize}
                        filters={filtersQuery}
                        tableProfile={tableProfile}
                        setTableProfile={setTableProfile}
                        tableType="ENTITY"
                    />
                <EMTableContainer>
                   
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                            entitiesFilters={filtersQuery}
                        />
                        <TBody height={density}>
                            <TableFilters
                              userLevel={userLevel}
                                columns={columns}
                                showFilters={showFilters}
                                entitiesFilters={filtersQuery}
                                filtersChange={setEntitiesFilters}
                                applyFilters={handleApplyFiltering}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                
                </EMTableContainer>
            </EMBody>
        </EMContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.entities.loading,
    entitiesData: state.entities.data,
    count: state.entities.count,
    currentPage: state.entities.currentPage,
    pageSize: state.entities.pageSize,
    sortQuery: state.entities.sortQuery,
    reverse: state.entities.reverse,
    filtersQuery: state.entities.filtersQuery,
    showFilters: state.entities.showFilters,
    userRole: state.auth.role,
    entityOwnerDetail: state.clients.entity,
    userUid: state.auth.userUid,
    userLevel: state.auth.userLevel,
    userType : state.auth.userType,

})
const mapDispatchToProps = dispatch => ({
    getEntitiesPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.entitiesGetPage(page, filters, sort, reverse, pageSize)),
    setEntitiesPage: (page) => dispatch(actions.entitiesSetPage(page)),
    setEntitiesPageSize: (pageSize) => dispatch(actions.entitiesSetPageSize(pageSize)),
    setEntitiesQuickFilter: (quickFilterField) => dispatch(actions.entitiesSetQuickFilter(quickFilterField)),
    setSortQuery: (sortQ) => dispatch(actions.entitiesSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.entitiesSetReverseSort(reverseS)),
    setEntitiesFilters: (filters) => dispatch(actions.entitiesSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.entitiesUpdateShowFilters(show)),
    setEntitydetail: (entity) => dispatch(actions.setEntitydetail(entity)),
})
export default connect(mapStateToProps, mapDispatchToProps)(EntitiesManager)
