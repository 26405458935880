import React from 'react'
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip';
import "./QuestionCategoryCard.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function QuestionCategoryCard({category, clicked, selected, userProfile, setShowDeleteCategoryModal}) {

    return (
        <div className={"questionCategoryCard_body" + (selected ? " questionCategoryCard_body_selected" : "")} onClick={clicked}>
            {userProfile === "ADMIN" &&
                <div className="questionCategoryCard_deleteIcon">
                    <CTooltip title="Supprimer la catégorie"><DeleteForeverIcon className="deleteCategoryIcon" onClick={e => {e.stopPropagation(); setShowDeleteCategoryModal(category)}} /></CTooltip>
                </div>}
            <span className="questionCategoryCard_label">{category.labelFr}</span>
        </div>
    )
}

export default QuestionCategoryCard
