import styled from 'styled-components/macro';
import { slideInDown, slideOutUp, slideInLeft,slideOutLeft,slideOutRight,slideInRight } from "react-animations";
import { keyframes } from "styled-components";
const slideInAnimation = keyframes`${slideInRight}`;
const slideOutAnimation = keyframes`${slideOutRight}`;
import Select from 'react-select';
import { Link} from 'react-router-dom';
 

export const SIContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden; 
`

export const SIBody = styled.div`
    width: 100%;
    height: 100%;
`

export const SITableContainer = styled.div`
    width: 100%;
    height:calc(100vh - 244px);
    overflow: auto;
    display: flex;
    flex-direction: column;
`
export const TableErrDiv = styled.div`
   margin:4px ;
   height:310px ;
   overflow-y:auto ;
`



export const TableErrContainer = styled.ul`
    position: absolute;
    top: 19px;
    z-index: 200;
    background-color: #ffffff;
   
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 600px;
    opacity:1;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    border-top: 2px solid rgb(86,41,111);
    border-bottom:2px solid rgb(86,41,111);
    border-left:2px solid rgb(86,41,111);
    margin-right:7px ;
 
    animation:1s ${({isActive}) => isActive ? slideInAnimation : slideOutAnimation} forwards ;
  
  display:  ${({isNone,theme}) => isNone ? "None" :"flex"}; 
    

/* transition: width 2s, height 2s, background-color 2s,opacity 3s;

animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: infinite; */
    /* &:before {
        content: '';
        position: absolute;
        top: 150px;
        left: -15px;
        right:0px ;
        width: 10px;
        height: 0;
        border-style: solid;
        border-width: 10px 15px 10px 0;
        transform: rotate(-360deg);
        border-color: transparent #ffccff transparent transparent;
    } */

   
`








export const BtnHd = styled.div`
width: 41px;
padding: 5px;

position:absolute ;
top:151px;
border-radius: 50px 0px 0px 50px ;
background-color: rgb(86,41,111);
& svg {
    margin:5px ;
}
`




export const BtnErrContainer = styled.div`
 color: red;
    display: flex;
    justify-content: end;
    position: relative;
    left: 7px;
    //slideOutAnimation
  
       
      
        & ${BtnHd}{
            display:${({isActive,theme}) => isActive & "none"};
           

        }

    
   
`

export const DivTest = styled.div`
        display: flex;
        width: 100%;
        justify-content: end;
        position: sticky;
        top: 130px;
        z-index: 100;
  
   
`
export const Btn2Hd = styled.div`
     position:absolute; 
        top:131px;
        left:-36px;
        width: 35px;
        padding: 5px;
        background-color: rgb(86,41,111);
        border-radius: 50px 0px 0px 50px ;
& svg {
    margin:5px ;
}
        

   
`
// &:hover ~ ${RadioButtonLabel} {
//     background: #1E85D9;
//     border: 1px solid #2196F3;
//     &::after {
//         content: '';
//        display: flex;
//        width: 10px;
//       height: 10px;
//       margin: 3px;
//        -moz-border-radius: 7.5px;
//        -webkit-border-radius: 7.5px;
//        border-radius: 7.5px;
//        background-color: #fff;
//     }
//   }
export const TD = styled.td`
    padding: 0.25rem;

    & > span {
        display: block;
        width: 100%;
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`
export const TBody = styled.tbody`
    width: 100%;
    font-size: 0.9rem;
    color: ${({theme}) => theme.colors.text};

    tr {
        border-bottom: 0.0rem solid white;
    }
& TD:first-child {
   text-align:start ;
}
    & ${TD} {
        width: 100%;
        vertical-align: middle;
        height: ${({height}) => height ? height : '100%'};
        text-align: center;
    }
`;

export const wrap = styled.div`
 position:relative; 
 width:200px;
 overflow: hidden;
`
// .wrap {
//     position:relative; 
//     width:200px;
//     overflow: hidden;
//   }
export const RejectNumber = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: ${({status, theme}) => theme.status.background[status] || '#FFFFFF'};
 
    border-radius: 8px;

    & > span {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &:hover {
        color:#000 ;
    }
`

export const AutoCompleteSelect = styled(Select)`
    border: none;
    border-style: none;
    outline: none;
    /* min-width: 170px; */
    flex: 1;
    text-align: centre;
    margin-left: 10px;

& .select__option {
//background-color: ${({theme}) => "red"} !important;
/* border-color: ${({theme}) => theme.colors.secondary} !important; */
}

& .select__control--is-focused {
 color:  ${({theme}) => "#000"} !important;
 
box-sizing: border-box;
}
& .select__control {
    border-style: none;
    display: flex;
    margin-bottom: -2px;
    align-self: end;
    align-items: end;
    border:1px solid ${({theme}) => theme.colors.primary};
}
& .select__control :hover{
  
    border:1px solid ${({theme}) => theme.colors.primary};
}

& .select__value-container{
    display: flex;
    align-items: end;
   
    
}
& .select__single-value{
    color:${({theme}) => theme.colors.text};
}
& .select__input-container{
    
    padding-top: 2px;
    visibility: visible;
    color: hsl(0, 0%, 20%);
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
}
/* background-color: red;
border-color: red;
box-sizing: border-box; */
& .select__clear-indicator {
visibility: hidden;
}

`;