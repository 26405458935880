import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
import { TableAction, TAContainer } from '../../../styles/Table.styled.js';
import '../../UI/Spinner/Spinner.css';

function TableActions(props) {
    const {resetFilters, setShowFilters, showFilters, setShowNewAuditModal, exportCsv, selectedRows, setShowRemindModal} = props;
    const {t} = useTranslation();

    const hasSelection = () => {
        return selectedRows ? Object.keys(selectedRows).filter(r => selectedRows[r]).length > 0 : false;
    }

    return (
        <TAContainer>
            <TableAction title="Réinitialiser les filtres" onClick={() => resetFilters()}>
                <ReplayIcon />
            </TableAction>
            <TableAction onClick={() => setShowFilters(!showFilters)}>
                <span> {t("global:filters")}</span>
                <FilterListIcon/>
            </TableAction>
            {/* <CTooltip title="Permet d'exporter en format CSV les résultats affichés sur l'écran"><TableAction onClick={() => exportCsv()}>
                <span>Exporter les lignes affichées</span>
            </TableAction></CTooltip> */}
            {hasSelection() && <TableAction onClick={() => setShowRemindModal({...selectedRows})}>
                <span>Relancer les fournisseurs sélectionnés</span>
            </TableAction>}
            <TableAction onClick={() => setShowNewAuditModal(true)}>
                <span>Nouvelle campagne</span>
            </TableAction>
        </TAContainer>
    )
}

export default TableActions
