import React from 'react';
import styled from 'styled-components';

const UploadButton = styled.button`
    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 0.125rem 1rem;
    border-radius: 8px;
    background-color: white;
    font-size: 0.8rem;
    display: flex;
    border: ${({border, theme}) => border ? "1px solid theme.colors.primary" : "none"};
    &:hover {
        background-color: ${props => props.theme.colors.primary};
        color: #ffffff;
    }
`;

const FileUploaderBtn = ({btnLabel, name, handleChange, icon ,border}) => {
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <UploadButton style={{border: "1px solid"}} border={border} onClick={handleClick}>
        <span>
            {btnLabel || null}
        </span>
        {icon || null}
      </UploadButton>
      <input 
        type="file"
        id="uploader"
        ref={hiddenFileInput}
        onChange={(e) => handleChange(e)}
        style={{display:'none'}} 
        accept="application/pdf"
        name={name}
      /> 
    </>
  );
};
export default FileUploaderBtn;