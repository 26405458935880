import React from 'react'
import { InfoBandPanel, InfoBandPanelHeading, Cleardiv,InfoBandPanelTitle } from './InfoBand.styled';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ClearIcon from '@mui/icons-material/Clear';
import Axios from '../../axios-proas'
import VisibilityIcon from '@mui/icons-material/Visibility';
import {CTooltip} from '../../components/UI/CTooltip/CTooltip';
import { useTranslation } from 'react-i18next';

function InfoBand({bandeaux, userProfile,setBandeaux}) {
    const { i18n } = useTranslation(); 

    const setAsRead=(id)=>{
      Axios.post('/user/'+id+'/setAsRead',{bandeauId:id})
      .then(res=>{
      }).catch(err=>{
        console.error(err)
      }
      )
      //setBandeauxState(bandeaux.filter(x=> x.id!==id))
      let b=bandeaux.filter(x=> x.id==id)
      let b2={...b[0],read:true}
      setBandeaux([...bandeaux.filter(x=> x.id!==id),b2])
    }
  return (
      <InfoBandPanel>
      {bandeaux?.filter(x=>x.read==false).map((bandeau, index) => {
        return (
          <InfoBandPanelHeading couleur={bandeau?.couleur}  key={index} >
            <InfoBandPanelTitle >
             {bandeau?.icone=="error" ? 
                <ErrorOutlineIcon style={{marginRight:"25px"}}/>:
                bandeau?.icone=="warning"?
                <WarningAmberIcon style={{marginRight:"25px"}}/>:null
               
              } 
                <p>{i18n.language == "en" ? bandeau.contentEN : bandeau.contenu}</p>
              </InfoBandPanelTitle>
              <Cleardiv>
                {userProfile === "ADMIN" && <><CTooltip title={bandeau.readTotal + " utilisateur(s) ont lu ce bandeau"}>
                    <span style={{cursor:"pointer",marginTop:"6px"}}>{bandeau.readTotal}</span>
                </CTooltip>
                <CTooltip title={bandeau.readTotal + " utilisateur(s) ont lu ce bandeau"}>
                   <VisibilityIcon style={{margin:"8px",cursor:"pointer"}}/>
                </CTooltip></>}
                {userProfile !== "ADMIN" && <CTooltip title={"Marquer comme lu"}>
                    <ClearIcon style={{cursor:"pointer",marginTop:"8px"}} onClick={e => setAsRead(bandeau.id)}/>
                </CTooltip>}
            
             </Cleardiv>
          </InfoBandPanelHeading>
        )
      })

      }
    </InfoBandPanel>
  )
    
    
}

export default InfoBand






