import React, { useCallback, useEffect, useState } from 'react'
import './EntityUserModal.css'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CTooltip } from '../CTooltip/CTooltip';

function EntityUserModal({handleClose, show, addEntitiesUser, userEntities, entitiesType, title, entitiesCopie,entitiesToDelete, hasToHaveOneOrMore }) {
    const [entities , setEntities] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState(userEntities ? userEntities : []);

    const [searchQuery, setSearchQuery] = useState(null)
    const [selectAll, setSelectAll] = useState(null)
    const {t} = useTranslation();

    const getEntitiesList = useCallback(() => {
        let filter = {
            searchQuery: searchQuery,
            type : entitiesType,
            pageSize: entitiesType === "owner" ? 99999 : 100
        }
        Axios.get('/entity//modalEntities', {
            params: filter
        })
        .then(res => {
            setEntities(res.data?.result)
        })
    }, [searchQuery])

    useEffect(() => {
        getEntitiesList()
    }, [searchQuery])
    const [entitiesToDeleteCopie,setEntitiesToDeleteCopie] = useState(entitiesToDelete ? entitiesToDelete : [])

    const SelectEntity = (entity) => {
        let entityIndex = selectedEntities.findIndex(row => row.uid === entity.uid);
        let entityDeleteIndex = entitiesToDeleteCopie.findIndex(row => row=== entity.uid);
        let selectedTemp = [...selectedEntities];
        let entitiesToDeleteTemp = [...entitiesToDeleteCopie];
        if(entityIndex >= 0){
            if (!(hasToHaveOneOrMore && selectedEntities?.length <= 1)) {
                selectedTemp.splice(entityIndex,1)
                entitiesToDeleteTemp=[...entitiesToDeleteCopie,entity.uid]
            }
        }
        else {
            selectedTemp = [...selectedEntities, entity];
            if(entityDeleteIndex >= 0)
                entitiesToDeleteTemp.splice(entityDeleteIndex,1)
        }
            
        setSelectedEntities(selectedTemp);
        setEntitiesToDeleteCopie(entitiesToDeleteTemp)
    }

    const selectAllEntities = () => {
        let selectedTemp = [...selectedEntities];
        if (selectAll)
            entities.forEach(e => {
                if (!selectedTemp?.find(s => s.uid === e.uid))
                    selectedTemp.push({uid: e.uid, name: e.name, city: e.city, new: true})
            })
        else
            selectedTemp = [];
        setSelectedEntities(selectedTemp)
    }

    useEffect(() => {
        if (selectAll !== null)
            selectAllEntities()
    }, [selectAll])


 
    const addClickHandler = () => {
    //  let p=[];
    //  entitiesCopie.map(row =>{      
    //         let tp=selectedEntities.filter(r => r.uid === row.uid);
    //         console.log(tp.length)
    //           if(tp.length===0){
    //             p.push(row.uid);
    //        }        
    //   });
        addEntitiesUser(selectedEntities,entitiesToDeleteCopie)
      handleClose()
    }

    const existingEntity = (entity) => {
        return entitiesCopie?.findIndex(row => row.uid === entity.uid) >= 0
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{title} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="entityUserModal__body" style={{minWidth: "40rem"}}>
                    <div className="entityUserModal__header">
                        <div className=" input-group input-group-sm">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t('global:search',"Search")}  
                                aria-label={t('global:search',"Search")} 
                                aria-describedby="basic-addon2" 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                value={searchQuery || ''}
                            />
                            <div className="input-group-append" >
                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                        <p style={{marginTop: "20px"}}>{t("user:clickToSelect", "Click on the \"+\" icons to select the entities to link to the user")}</p>
                    </div>
                    <div className="entityUserModal_items bg-white">
                        <table className="table entityUserModal__table">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" style={{width: "50px"}}>
                                        <CTooltip title={t("user:clickToSelectAll", "Click to select all the entities")}>
                                            <div className="columnHead__container" style={{justifyContent: "center", cursor: "pointer"}}>
                                                <div className="columnHead__title">
                                                    <CheckIcon onClick={() => setSelectAll(selectAll === null ? true : !selectAll)} />
                                                </div>
                                            </div>
                                        </CTooltip>
                                    </th>
                                    <th scope="col">{t('supplier:entityName', "Name")} </th>
                                    <th scope="col">{entitiesType === "owner" ? "Code SU" : "Code"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    entities?.length > 0 ? entities.map(entity => (
                                        <tr key={entity.id}>
                                            <td>
                                                <div className="productsModal__itemIcon" 
                                                onClick={() => SelectEntity({uid : entity.uid, name : entity.name, city:entity.city,new : !existingEntity(entity)})}>
                                                    {
                                                        selectedEntities.some(row => row.uid === entity.uid) ? (
                                                            <CTooltip title={hasToHaveOneOrMore && selectedEntities?.length <= 1 ? (entitiesType === "supplier" ? t("user:atLeastOneSupplier", "At least one supplier must be selected") : t("user:atLeastOneEntity", "At least one entity must be selected")) : ""}>
                                                                <ThumbUpIcon style={{cursor: "pointer", color: (hasToHaveOneOrMore && selectedEntities?.length <= 1 ? "grey" : "rgb(86,41,111)")}} />
                                                            </CTooltip>
                                                        ) : <AddIcon style={{cursor: "pointer", color: "#f00"}} />
                                                    }
                                                </div>
                                            </td>
                                            <td>{entity.name} </td>
                                            <td>{entity.code} </td>
                                        </tr>
                                    )) : <div style={{whiteSpace: "nowrap"}}>
                                            {t('supplier:noSuppliers', "No entities to display")}  
                                        </div>
                                }
                            </tbody>
                        </table>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('global:cancel', "Cancel")} 
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('global:associate', "Link entity")} 
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({ 
})
const mapDispatchToProps = dispatch => ({
})  
export default connect(mapStateToProps, mapDispatchToProps)(EntityUserModal)
