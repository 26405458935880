
import styled from 'styled-components/macro';
import Select from 'react-select';
export const CFContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Rvcadiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    padding: 10px;

`;
export const FormLabel = styled.label`
  
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: pre-wrap;
    color: ${({color,theme}) =>color?color: theme.colors.secondary};
`;

export const FormInput = styled.input`
    /* flex: 1; */
    border: none;
    outline: none;
    font-size: 15px;
    color: #6c7b89;
    flex: 1;

    /* width: 170px; */
    // text-align: right;
    &::placeholder {
        font-size: 12px;
    }
`;

export const FormTextArea = styled.textarea`
    /* flex: 1; */
    outline: none;
    font-size: 15px;
    color: #6c7b89;
    flex: 1;
    /* width: 170px; */
    // text-align: right;
`;

export const CFFormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 0.2rem;
    border-bottom: 2px solid #F5FBFF;
`;
export const CFBody = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    flex-direction: column;
`
export const CFSection = styled.div`
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
`
export const CFFormSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`

export const EventsItem = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.7rem 0;
`
export const EventColor = styled.div`
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 50%;
    background-color: ${props => props.color};
    margin-right: 0.5rem;
`

export const EventValue = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: calc(100% - 84px);
`

export const EventVal = styled.h4`
    font-size: 0.9rem;
    font-weight: bold;
    margin: 0;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;
`

export const EventMessage = styled.h6`
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
    color: ${({theme}) => theme.colors.secondary};
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;
`
export const EventDate = styled.h6`
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
    color: ${({theme}) => theme.colors.secondary};
`

export const EventAction = styled.div`
    display: flex;
    align-items: center;
    color: #707070;
    cursor: pointer;

    & span {
        font-size: 0.9rem;
    }

    &:hover {
        color: ${({theme}) => theme.colors.secondary};
    }
`;
export const Coselect = styled(Select)`
    border: none;
    border-style: none;
    outline: none;
    /* min-width: 170px; */
    flex: 1;
    text-align: centre;
    margin-left: 10px;
    max-width: 95%;
& .select__option {
//background-color: ${({theme}) => "red"} !important;
/* border-color: ${({theme}) => theme.colors.secondary} !important; */
}

& .select__control--is-focused {
 color:  ${({theme}) => "#000"} !important;
 
box-sizing: border-box;
}
& .select__control {
    border-style: none;
    display: flex;
    margin-bottom: -2px;
    align-self: end;
    align-items: end;
   
    border:1px solid ${({theme}) => theme.colors.primary};
}
& .select__control :hover{
  
    border:1px solid ${({theme}) => theme.colors.primary};
}

& .select__value-container{
    display: flex;
    align-items: end;
   
    
}
& .select__single-value{
    color:${({theme}) => theme.colors.text};
}
& .select__input-container{
    
    padding-top: 2px;
    visibility: visible;
    color: hsl(0, 0%, 20%);
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
    height:60px ;
}
/* background-color: red;
border-color: red;
box-sizing: border-box; */
& .select__clear-indicator {
visibility: hidden;
}

`;

export const DPContainer = styled.div`
    /* border: 1px solid #3391aa; */
    outline: none;
   flex: 1;
    /* width: 150px;
    text-align: right; */
    margin-left: 22px;
    margin-bottom: 0.5rem;

    & input {
        padding: 0.5rem !important;
        color: ${({ theme }) => theme.colors.secondary};
        width: 211px;
    }

    & button {
        color: ${({ theme }) => theme.colors.secondary};
    }

    & fieldset {
        border: 1px solid #3391aa;
    }

    & label {
        //color: ${({ theme }) => theme.colors.secondary};
        border: 1px solid #3391aa;
    }

    /* &:focus-within {
        & input {
            color: ${({ theme }) => theme.colors.primary};
        }
        & button {
            color: ${({ theme }) => theme.colors.primary};
        }
    } */
`;
export const InputCh = styled.input`
   
   margin: 4px;
   // height:100% ;

`
