import React, {useCallback, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../../styles/Table.styled';
import DateFilter from '../../../UI/DateFilter/DateFilter';
import {showColumn} from '../../../../shared/utility'
function TableFilters(props) {
    const {showFilters, campaignLogsFilters, filtersChange, applyFilters,columns} = props;
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const {t} = useTranslation();

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...campaignLogsFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters)
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])

    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    let [dateInputValue,setDateInputValue] = useState({
        creationDate: campaignLogsFilters?.creationDate ? convertDate(campaignLogsFilters.creationDate) : ''
    });

    const getCreationdate = () => {
        return campaignLogsFilters?.creationDate ? convertDate(campaignLogsFilters.creationDate) : [];
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...campaignLogsFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...campaignLogsFilters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }
   
    return (
        <>
            <tr className="tableFilters" style={{display: showFilters ? 'table-row' : 'none'}}>
                <TD style={{display: !showColumn(columns,"creationDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationdate()[0] || ''}
                            to={getCreationdate()[1] || ''}
                            name="creationDate"
                            activeReset={campaignLogsFilters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD  style={{display: !showColumn(columns,"message") && 'none'}}>
                      <FilterContainer>
                   
                        <InputFilter 
                         placeholder={t("invoiceList:ne", "Message")}
                        onChange={(e) => inputChangeHandler(e)} 
                        value={campaignLogsFilters?.message || ''} 
                        name="message"
                        type="text" 
                        autoComplete="off" />
                  
                     </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"logType") && 'none'}} >
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={campaignLogsFilters?.logType || ''} 
                        name="logType" 
                    >
                        <Option value=""></Option>
                        <Option value="INFO">Information</Option>
                        <Option value="WARNING">Notice</Option>
                        <Option value="ERROR">Erreur</Option>
                    </SelectFilter>
                    </FilterContainer>
                </TD>
            </tr>
        </>
    )
}

export default TableFilters
