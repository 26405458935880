import React, { useMemo,useState } from 'react';
import { formatDate, getNotyfObject } from '../../../shared/utility';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import StatusProgressLine from '../StatusProgressLine/StatusProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CIPreviewBtn, CITitle, CIUsersLink } from './CampaignItem.styled';
import GroupIcon from '@mui/icons-material/Group';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal'
import Axios from '../../../axios-proas'
import EmailPreviewModal from '../../EmailsSettings/TableBody/EmailPreviewModal/EmailPreviewModal';
function CampaignItem({campaign,getCampaigns}) {
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
     console.log(campaign)
     const [confirmationModalShow, setConfirmationModalShow] = useState(false);
     const [showPreviewModal, setShowPreviewModal] = useState(false);
     const notyf = getNotyfObject();

     const deleteCompagneClickHandler = (Uid) => {
        
        Axios.delete(`campaign/${Uid}/deleteCompagne`).then(response => {
            getCampaigns()
           console.log(Uid)
           setConfirmationModalShow(false)
            notyf.success("La campagne a bien été supprimée")
        }).catch(response => {
           
            notyf.error("generatedErrorMessage")
        })
    }
    return (
        <>
        <CIContainer>
            <CITitle>
                {campaign?.title}
            </CITitle>
            <CIContent>
                <CIData>
                        <DateProgressLine
                            creationDate={+campaign?.creationDate}
                            campaignDate={+campaign?.campaignDate}
                            endDate={+campaign?.endDate}
                            firstRecallDate={+campaign?.firstRecallDate}
                            secondRecallDate={+campaign?.secondRecallDate}
                        />
                        <StatusProgressLine
                            status={campaign?.status}
                        />
                </CIData>
                <CIActions>
                    <CIUsersLink 
                        to={`#`}  
                    >
                            {campaign?.countEntities} 
                        <GroupIcon style={{fontSize: "1.2rem", marginLeft: "2px"}} />
                    </CIUsersLink>

                    <CIDeleteBtn   onClick={() => setConfirmationModalShow(true)}
                                    color="#ff0000"
                                    border="#FF0000">
                        Supprimer
                    </CIDeleteBtn>
                    <CIPreviewBtn
                        style={{marginLeft: "5px"}}
                        color="#1c80d1" 
                        border="#1c80d1"
                        onClick={() => setShowPreviewModal(true)}
                        >Prévisualiser
                    </CIPreviewBtn>
                    <CIUsersLink
                        style={{marginLeft: "5px"}}
                        color="#fff"
                        border="#FFF"
                        to={"/newCampaign/"+campaign?.uid}
                      
                        > Voir  <ArrowRightIcon/>
                    
                     </CIUsersLink>
                   
                      
                </CIActions>
            </CIContent>
           
        </CIContainer>
         <ConfirmationModal 
         show={confirmationModalShow}
         modalClosed={() => setConfirmationModalShow(false)}
         confirm={()=>deleteCompagneClickHandler(campaign?.uid)}
         cancel={() => setConfirmationModalShow(false)}
         title={"Confirmation de suppression"}
         message={`Êtes-vous sûr de vouloir supprimer cette campagne ?`}
/>
        <EmailPreviewModal
            show={showPreviewModal}
            handleClose={() => setShowPreviewModal(false)}
            convertedContent={campaign?.content}
            event={null}
        />
</>
    );
}

export default CampaignItem;
