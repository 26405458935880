import React, { useEffect, useState } from 'react';
import Axios from '../../../axios-proas';
import { connect } from 'react-redux';
import { TableErrContainer,TableErrDiv,TD,TBody, } from './Import.styled';
import { Table, RejectLink } from '../../../styles/Table.styled';
import {CTooltip} from '../../../components/UI/CTooltip/CTooltip'
import { getErrorLabel, getImportCategoryWithKey } from '../../../shared/utility';
import "./Import.css";

function Import({width}) {
    const [proasImportInfo, setProasImportInfo] = useState(null);
    const colsToDisplay = [
        "mailrecu",
        "rejetmail",
        "totalmail",
        "facturemail",
        "facturepapier",
        "sommerecues",
        "facturesTraitees",
        // "delta",
        "codeerr01",
        "codeerr02",
        "codeerr03",
        "codeerr04",
        "codeerr05",
        "codeerr06",
        "codeerr07",
        "codeerr08",
        "codeerr09",
        "codeerr10",
        "codeerr11",
        "codeerr12",
        "sommeerreurs",
        "factureenvoyee",
        "factureenerreuratraiterpartransdev",
        "HSpapier",
        // "factureenerreurrenvoyee" 
    ]

    useEffect(() => {
        Axios.get('/proasImportInfo').then((res) => {
            setProasImportInfo(res.data); 
        }).catch(err => {
            notyf.error(err.message);
        })
    }, [])
    
    const body = proasImportInfo ? (
        colsToDisplay?.map(col => (
            <tr className="trHover importTableRow" key={col} style={col === "delta" ? {color: proasImportInfo?.sommetraites >= Math.min(3000, proasImportInfo?.sommerecues) ? "green" : "orange"} : null}>
                {/*col !== "delta" ? */<><TD className="importTableLabel">
                    <CTooltip title={col?.includes("codeerr") ? getErrorLabel(col.replace("code", "")) : (getImportCategoryWithKey(col) || "")}>
                        {col?.includes("codeerr") ? <RejectLink to="/rejectTracking" state={{errorFilter : col.replace("code", "")}}>{getImportCategoryWithKey(col)}</RejectLink> : 
                        <span>{getImportCategoryWithKey(col)}</span>}
                    </CTooltip>
                </TD>
                <TD className="importTableValue">
                    <CTooltip title={proasImportInfo?.[col] || ""}>
                        <span>{proasImportInfo?.[col]}</span>
                    </CTooltip>
                </TD></>/* : 
                <><TD className="importTableLabel">
                    <CTooltip title="Delta de traitement : Nombre de factures traitées soustrait au nombre total de factures reçues (dans la limite de 3 000 factures par jour)"><span>Delta de traitement</span></CTooltip>
                </TD>
                <TD className="importTableValue">
                    <CTooltip title={proasImportInfo?.sommerecues - proasImportInfo?.sommetraites}><span >{proasImportInfo?.sommerecues - proasImportInfo?.sommetraites}</span></CTooltip>
                </TD></>*/}
            </tr>
        ))
    ) : null;

    return (
        <TableErrContainer width={width} >
            
            {/* {data2 ? (<CSVLink
                    ref={csvBtn}
                    data={data2}
                    filename={"Rejets.csv"}
                    headers={csvHeaders2}
                    separator=";">
                </CSVLink>) : null} */}
            <CTooltip title={"Dernier Import AGSRM (" + proasImportInfo?.receptionDate?.substring(8,10) + "/" + proasImportInfo?.receptionDate?.substring(5,7) + "/" + proasImportInfo?.receptionDate?.substring(0,4) + ")"}>
                <div className="importHeader">Dernier Import AGSRM ({proasImportInfo?.receptionDate?.substring(8,10) + "/" + proasImportInfo?.receptionDate?.substring(5,7) + "/" + proasImportInfo?.receptionDate?.substring(0,4)})</div>
            </CTooltip>
            <TableErrDiv>
                <div style={{margin:"15px"}}>
                
                    {/* < Btn2Hd>
                            <CloseOutlinedIcon onClick={BtnClickHandler} style={{fontSize: "1.5rem", color:"#fff"}} />
                    </ Btn2Hd> */}
                        
                    <Table>
                 
                        <TBody style={{fontSize: "12px"}}>
                            {body}
                        </TBody>
                    </Table>
                </div>
            </TableErrDiv>
            {/* <div style={{width:"100%",display: "flex", justifyContent: "center", padding: "11px"}}>
                <button type="button" style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} className="btn btn-danger" onClick={()=>exportSelectionCsv(data2)}>Export Excel</button>
            </div> */}
            {/* <CTooltip title="Export CSV">
                <ImportExportIcon  style={{cursor: "pointer"}}/>
            </CTooltip> */}
     </TableErrContainer>
    )
}
const mapStateToProps = (state) => ({

})
const mapDispatchToProps = dispatch => ({
  
})
export default connect(mapStateToProps, mapDispatchToProps)(Import)
