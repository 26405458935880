import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TablePagination from '../../../components/UI/TablePagination/TablePagination';
import TableActions from '../../../components/SuppliersTable/TableActions/TableActions';
import TableHead from '../../../components/SuppliersTable/TableHead/TableHead';
import TableBody from '../../../components/SuppliersTable/TableBody/TableBody';
import TableFilters from '../../../components/SuppliersTable/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Spinner } from 'react-bootstrap';
import TitleHelmet from '../../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { Header, HeaderInfos, HeaderLocation, HeaderTitle } from '../../../styles/Common';
import { NoContent, SpinnerContainer, Table, TBody } from '../../../styles/Table.styled';
import { RFContainer, RFBody, RFTableContainer,HeaderActions } from './ReferentielFournisseur.Styled';
import Axios from "../../../axios-proas";


import _ from 'lodash';


function ReferentielFournisseur(props) {
    const {
        userType,
        entityFilter,
        searchField,
        setSearchField,
        setEntitydetail,
        loading,
        suppliersData,
        count,
        currentPage,
        pageSize,
        getSuppliersPage,
        setSuppliersPageSize,
        getCustomersPage,
        setSuppliersPage,
        sortQuery, reverse,
        filtersQuery,
        showFilters,
        updateShowFilters,
        setSuppliersFilters,
        setSortQuery,
        setReverse
    } = props;
    const { t } = useTranslation();
    const { state } = useLocation();
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const navTitre = "referentielFournisseur";

    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([
    ])

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{navTitre:navTitre}})
            .then(response => {
                let tempColums = response?.data?.columns               
                let columnsCopy = [
                    { title: t("supplier:supplierCodefournisseur"), field: 'code', show: tempColums.code, width: "14%" },
                    { title: t("supplier:supplierNamefournisseur"), field: 'name', show: tempColums.name },
                    { title: t("supplier:vta"), field: 'vat', show: tempColums.vat, width: "10%" },
                    { title: "SIREN", field: 'taxNumber1', show: tempColums.taxNumber1, width: "14%" },
                    { title: "SIRET", field: 'taxNumber2', show: tempColums.taxNumber2, width: "14%" },
                    { title: t("supplier:status", "Statut"), field: 'active', show: tempColums.active, width: "8%" },
                    { title: t("supplier:creationDate"), field: 'creationDate', show: tempColums.creationDate, width: "10%" }
                ];
                setColumns(columnsCopy)
                columnsCopy.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns])

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])
console.log(filtersQuery)
    useEffect(() => {
        localStorage.setItem("origin", "supplier")
        let filtersTemp = {
            ...filtersQuery,
            type: entityFilter
        }
        let filtersProps = null;
        if (state?.filtersProps) {
            filtersProps = state.filtersProps;
            filtersTemp = {
                ...filtersQuery,
                ...filtersProps
            }
            setSuppliersFilters(filtersTemp);
        }
        (entityFilter == "client") || (filtersTemp?.type && (filtersTemp?.type == "client")) ?
            getCustomersPage(currentPage, filtersTemp, sortQuery, reverse, searchField)
            : getSuppliersPage(currentPage, filtersTemp, sortQuery, reverse, searchField, pageSize)
    }, [state, currentPage, entityFilter, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }
    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        entityFilter == "client" ?
            getCustomersPage(currentPage, filtersQuery, field, newReverse, searchField) :
            getSuppliersPage(currentPage, filtersQuery, field, newReverse, searchField, pageSize)

    }

    const handlePageChange = useCallback((newPage) => {
        setSuppliersPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        entityFilter == "client" ?
            getCustomersPage(1, filters, sortQuery, reverse, searchField) :
            getSuppliersPage(1, filters, sortQuery, reverse, searchField, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, searchField]);

    const resetFilters = () => {
        setSuppliersFilters(null);
        setSuppliersPage(1)
        entityFilter == "client" ?
            getCustomersPage(1, null, sortQuery, reverse, searchField) :
            getSuppliersPage(1, null, sortQuery, reverse, searchField, pageSize)
    }

    const findSupplier = (e) => {
        console.log(e.target.value)
        setSearchField(e.target.value)
        entityFilter == "client" ?
            getCustomersPage(1, null, sortQuery, reverse, e.target.value) :
            getSuppliersPage(1, null, sortQuery, reverse, e.target.value, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("supplier:noSuppliers")}</span>
        </NoContent>
    )

    if (!loading && suppliersData?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            columns={columns}
            setEntitydetail={setEntitydetail}
            suppliersData={suppliersData}
            formatDate={formatDate}
            entityFilter={entityFilter}
            userType={userType}
        />)
    }

    return (
        <RFContainer>

            
    

                <HeaderActions>
                    <h4>Fournisseurs ne s'étant jamais connectés</h4>
                {/* <span>Liste des fournisseurs non connectés </span> */}

                        <TableActions
                            resetFilters={resetFilters}
                            showFilters={showFilters}
                            setShowFilters={updateShowFilters}
                            entityFilter={entityFilter}
                            userType={userType}
                        />
              
                </HeaderActions>
     
            <RFBody>
            <div style={{position:"relative"}}>
            <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setSuppliersPageSize}
                    />
                     {/* <div style={{position:"absolute",top:"-35px",left:"89%"}}>
                      <TableActions
                            resetFilters={resetFilters}
                            showFilters={showFilters}
                            setShowFilters={updateShowFilters}
                            entityFilter={entityFilter}
                            userType={userType}
                        />
                 </div>  */}
                    </div> 
                   
                <RFTableContainer>


                    <Table >
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            entityFilter={entityFilter}
                            userType={userType}
                            suppliersFilters={filtersQuery}
                            />
                        <TBody height={density}>
                            <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                suppliersFilters={filtersQuery}
                                filtersChange={setSuppliersFilters}
                                applyFilters={handleApplyFiltering}
                                entityFilter={entityFilter}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                
                </RFTableContainer>
            </RFBody>
        </RFContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.noConnectedSuppliers.loading,
    suppliersData: state.noConnectedSuppliers.data,
    count: state.noConnectedSuppliers.count,
    searchField: state.noConnectedSuppliers.searchInput,
    currentPage: state.noConnectedSuppliers.currentPage,
    pageSize: state.noConnectedSuppliers.pageSize,
    sortQuery: state.noConnectedSuppliers.sortQuery,
    reverse: state.noConnectedSuppliers.reverse,
    filtersQuery: state.noConnectedSuppliers.filtersQuery,
    showFilters: state.noConnectedSuppliers.showFilters,
    userType: state.auth.userType
})
const mapDispatchToProps = dispatch => ({
    getSuppliersPage: (page, filters, sort, reverse, searchField, pageSize) => dispatch(actions.noConnectedSuppliersGetPage(page, filters, sort, reverse, searchField, pageSize)),
    getCustomersPage: (page, filters, sort, reverse, searchField) => dispatch(actions.nccustomersGetPage(page, filters, sort, reverse, searchField)),
    setSuppliersPage: (page) => dispatch(actions.ncsuppliersSetPage(page)),
    setSuppliersPageSize: (pageSize) => dispatch(actions.ncsuppliersSetPageSize(pageSize)),
    setSearchField: (field) => dispatch(actions.ncsuppliersSetSearchInput(field)),
    setSortQuery: (sortQ) => dispatch(actions.ncsuppliersSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.ncsuppliersSetReverseSort(reverseS)),
    setSuppliersFilters: (filters) => dispatch(actions.ncsuppliersSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.ncsuppliersUpdateShowFilters(show)),
    setEntitydetail: (entity) => dispatch(actions.setEntitydetail(entity)),

})
export default connect(mapStateToProps, mapDispatchToProps)(ReferentielFournisseur)
