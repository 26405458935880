import React, { useState } from 'react'
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from 'react-date-range';
import "./DatePicker.css"
import { Button, Modal } from 'react-bootstrap';
import { fr, enGB, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

function DatePicker(props) {

    const {t} = useTranslation();

    const [date, setDate] = useState(props.date);

    const currentLanguageCode = localStorage.getItem('i18nextLng');
    let locale = fr;
    switch(currentLanguageCode) {
        case "fr": locale = fr; break;
        case "en": locale = enGB; break;
        case "de": locale = de; break;
    }

    function handleSelect(date) {
        setDate(date);
    }
    const handleApply = (e) => {
        e.preventDefault();
        props.changed({
            date: date,
            field: props.field
        })
        props.clicked();
    }
    
    return (
        <>
            <Modal show={props.show} onHide={props.clicked} dialogClassName="modal_w_mc" contentClassName="modal_w_mc">
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Calendar onChange={handleSelect} date={date} locale={locale} color={"#56296f"} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.clicked}>
                        {t('default:cancel', "Annuler")} 
                    </Button>
                    <Button variant="danger" onClick={(e) => handleApply(e)}>
                        {t("global:validate")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
        
    )
}

export default DatePicker
