import React, {useState, useRef,useEffect} from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { TEContainer } from './TextEditor.styled';
import htmlToDraft from 'html-to-draftjs';
function TextEditor({addTextHandler, content, disabled}) {
    
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
	
    const editorBlurHandler = (event, editorState) => {
        
        if(editorState.getCurrentContent().hasText()){
            addTextHandler(draftToHtml(convertToRaw(editorState.getCurrentContent())))
            console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }else 
            addTextHandler('')
    }


    function convertContentToDraft(html) {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return editorState;
        } else {
            return EditorState.createEmpty();
        }
    }

    useEffect(() => {
        setEditorState(convertContentToDraft(content))
    }, [content])	
	return (
            <TEContainer>
                <Editor
                    editorState={editorState}
                    toolbarClassName="textEditor_toolbar"
                    wrapperClassName="textEditor_wrapper"
                    editorClassName="textEditor_editor"
                    onEditorStateChange={(newEditorState) => setEditorState(newEditorState) }
                    readOnly={disabled}
                    localization={{
                        locale: 'fr',
                    }}
                    onBlur={(event, editorState) => editorBlurHandler(event, editorState)}
                />
                {/* <textarea
                    disabled
                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                ></textarea> */}
            </TEContainer>
        );
}

export default TextEditor
