import React, { useEffect, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip';

function QRContainer({question, getTranslatedField, searchQuestion}) {
    const [showAnswer, setShowAnswer] = useState(false);

    useEffect(() => {
        setShowAnswer(searchQuestion ? true : false);
    }, [searchQuestion])

    return (
        <div className="questionAnswer_form_container">
            <div className="form_element_container" style={{paddingRight: "5px", border: "1px solid #e7e7e7", borderRadius: "4px", cursor: "pointer"}} onClick={() => setShowAnswer(!showAnswer)}>
                <div className="form_twoElements_container" style={{borderLeft: "10px red solid", alignItems: "center"}}>
                    <div style={{ padding: "10px" }}>
                        <span style={{ width: "100%", fontWeight: "bold" }}>{getTranslatedField(question, "question") || ""}</span>
                    </div>
                    <CTooltip title={showAnswer ? "Cacher la réponse" : "Montrer la réponse"}>
                        {showAnswer ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </CTooltip>
                </div>
            </div>
            {showAnswer && <div className="form_element_container" style={{marginLeft: "10px", padding: "10px", border: "1px solid #e7e7e7", borderRadius: "4px"}}>
                <span style={{ width: "100%" }}>{getTranslatedField(question, "answer") || ""}</span>
            </div>}
        </div>
    )
}

export default QRContainer