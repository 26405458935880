import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { TH, THContainer, THead } from '../../../styles/Table.styled';
import { useTranslation } from 'react-i18next';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableHead(props) {
    const {columns, columnClickHandler, reverse, userRole, entitiesFilters} = props;
    const {t} = useTranslation();
    
    return (
        <THead>
            <tr>
                {columns.filter(row=>row.show==true).map(column => (
                    <TH 
                        className={entitiesFilters?.[column.field] && 'filtered'}
                        key={column.field} 
                        width={column.width}
                        scope="col" 
                        id={column.field} 
                        onClick={() => columnClickHandler(column.field, reverse)}
                    >
                        <CTooltip title={column.title}>
                            <THContainer>
                                <span>
                                    {column.title}
                                </span>
                                <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none'}} />
                                <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none'}}/>
                            </THContainer>
                        </CTooltip>
                    </TH>
                ))}
                {userRole === "admin" && 
                    <TH key={"column1493"}>
                        <THContainer>
                            <span>Utilisateur</span>
                        </THContainer>
                    </TH>
                }
            </tr>
        </THead>
    )
}

export default TableHead
