import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner'
import { Input } from '@mui/material';

// handleClose DOIT RETOURNER UNE PROMISE
function CustomInputModal({handleClose, show, text, title, okCallback, loading}) {
    const {t} = useTranslation();
    const [inputValue, setInputValue] = useState(null);

    const confirmClickHandler = () => {         
        okCallback(inputValue).finally(() => {
            closeModal()
        })
    }

    const closeModal = () => {
        if(!loading){
            setInputValue(null);
            handleClose()
        }
    }

    return (
        <>
            <Modal show={show} onHide={closeModal} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>{text}</p>
                    <Input value={inputValue || ""} onChange={e => setInputValue(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    {t('global:cancel', "Cancel")} 
                </Button>
                {
                    loading ? 
                        <Spinner animation="border" variant="primary" />
                    :
                        <Button variant="danger" 
                            style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} 
                            onClick={() => confirmClickHandler()}>                     
                            {t('global:save', "Save")}
                        </Button>
                }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CustomInputModal
