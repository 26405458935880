import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';

function RejectInvoiceUpdateEvent({eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    const getStatusColor = () => {
        switch(eventData?.snapshot) {
            default:
                return theme.colors.warning;
        }
    }

    const getStatusLabel= () => {
        switch(eventData?.snapshot) {
            default:
                return "Modification facture";
            break;            
        }
    }

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={true} bdColor={getStatusColor()} color={getStatusColor()} >
                        <CheckIcon />
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={getStatusColor()}>
                            {getStatusLabel()}
                        </TETitle>
                        <TETime>
                            {eventData?.user ? (eventData?.user?.first_name || eventData?.user?.last_name ? (eventData?.user?.first_name || "") + " " + (eventData?.user?.last_name || "").trim() : eventData?.user?.login) + "\r\n" : (eventData?.noUser ? getLabelWithKey(eventData?.noUser) + "\r\n" : null)}
                            {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    showContent && (
                        <TEContent>
                            {
                                eventData?.comment && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("default:comment", "Commentaire : ")}
                                        </MessageFrom>
                                        <MessageContent>
                                            {eventData?.comment}
                                        </MessageContent>
                                    </MessageWrapper>
                                )
                            }
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default RejectInvoiceUpdateEvent