import React from 'react'
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from 'react-router-dom';
import { TD, TR } from '../../../styles/Table.styled';
import { SupplierLink, SupplierUsersLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';
import { getChannelWithKey, getEntityStatusWithKey,showColumn } from '../../../shared/utility';

function TableBody(props) {
    const {suppliersData, formatDate, entityFilter,setEntitydetail,columns} = props;
    const navigate = useNavigate();

    return (
        <>
            {
                suppliersData ? suppliersData.map((row,index) => (
                    <TR key={row?.id} onClick={() => navigate("/entity/"+row.uid+"?type=supplier",{state:{entityType: 'supplier'}})}>
                        <TD style={{display:  !showColumn(columns,"code") && 'none'}}>
                            {row?.code}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"name") && 'none'}}>
                            {row.name}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"vat")&& 'none'}}> 
                           {row?.vat} 
                        </TD>
                        <TD style={{display:  !showColumn(columns,"taxNumber1")&& 'none'}}> 
                           {row?.taxNumber1} 
                        </TD>
                        <TD style={{display:  !showColumn(columns,"taxNumber2")&& 'none'}}> 
                           {row?.taxNumber2} 
                        </TD>
                        <TD style={{display:  !showColumn(columns,"active") && 'none'}}>
                            { (row?.active=='1' ? 'Actif' : 'Inactif' )}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"creationDate") && 'none'}}>
                            {formatDate(+row?.creationDate)}
                        </TD>
                        <TD >
                            <SupplierUsersLink 
                                to={`/entity/${row?.uid}?type=supplier`}
                                state={{tab: 'contacts', entityType: 'supplier'}}  
                                onClick={e => e.stopPropagation()}  
                            >
                                {row?.totalUsers}
                                <GroupIcon style={{fontSize: "1rem", marginLeft: "2px"}} />
                            </SupplierUsersLink>
                        </TD>
                    </TR>
                )) : null
            }
        </>
    )
}

export default TableBody
