import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { TH, THContainer, THead } from '../../../styles/Table.styled';
import {CTooltip} from '../../UI/CTooltip/CTooltip';
function TableHead(props) {
    const {
        columns, 
        reverse, 
        setSortQuery, 
        setReverse, 
        getData, 
        selectedAllRows,
        setSelectedAllRows,
        userType,
        userLevel,
        filters
    } = props;
    const {t} = useTranslation();
    const columnClickHandler = (field) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getData(field,newReverse);
    }

    const selectAllRowsHandler = (e) => {
        //console.log(selectedAllRows)
        setSelectedAllRows(!selectedAllRows);
    }

    return (
        <>
            <THead>
                    <tr>
                        <TH key={"column123"} scope="col" width="50px" >
                            <CTooltip title={t("userList:selectAll", "Select all users")}>
                                <THContainer style={{justifyContent: "center"}}>
                                    <CheckIcon onClick={(e) => selectAllRowsHandler(e)} />
                                </THContainer>
                            </CTooltip>
                        </TH>
                        {columns.map(column => (
                            <TH 
                                key={column.field} 
                                className={filters?.[column.field] && 'filtered'}
                                scope="col" 
                                id={column.field}
                                width={column.width} 
                                onClick={() => columnClickHandler(column.field)}
                            >
                                <CTooltip title={t(`userList:${column.field}`, column.title)}>
                                    <THContainer>
                                        <span>
                                            {t(`userList:${column.field}`, column.title)}
                                        </span>
                                        <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none'}} />
                                        <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none'}}/>
                                    </THContainer>
                                </CTooltip> 
                            </TH>
                            
                        ))}
                        {(userType === "owner" || (userType === "supplier" && userLevel == "admin")) && <TH key={"column1453"} scope="col" width="100px" >
                            <CTooltip title={t("global:activate", "Activate")}>
                                <THContainer style={{cursor: "default"}}>
                                    <span>{t("global:activate", "Activate")} </span>
                                </THContainer>
                            </CTooltip> 
                        </TH>}
                    </tr>
            </THead>
        </>
    )
}

export default TableHead
