import React, { useCallback, useEffect, useState } from 'react'
import './NewAuditModal.css'
import SearchIcon from '@mui/icons-material/Search';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EntitiesTable from './EntitiesTable/EntitiesTable';
import CustomConfirmModal from '../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal';
import { formatDate, getNotyfObject } from '../../../shared/utility';
import DatePicker from '../../UI/DatePicker/DatePicker';

function NewAuditModal({handleClose, show, owner, refreshTable, auditors}) {
    const defaultDate = new Date(new Date().getFullYear() - 1 +"/12/31").getTime();
    const [auditForm, setAuditForm] = useState({owner: owner, suppliers: [], thresholdDate: defaultDate});
    const [entities, setEntities] = useState([]);
    const [entitiesCopy, setEntitiesCopy] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("")
    const [allSuppliersSelected, setAllSuppliersSelected] = useState(false)
    const [showDateRange, setShowDateRange] = useState(false);
    const {t} = useTranslation();
    const notyf = getNotyfObject();

    const getEntitiesList = useCallback(() => {
        Axios.get('/accountingaudit/'+owner+'/suppliers', {params: {thresholdDate: auditForm.thresholdDate}}).then(response => {
            setEntities(response.data)
            setEntitiesCopy(response.data)
        })
    }, [allSuppliersSelected, owner, auditForm.thresholdDate])

    useEffect(() => {
        if (show)
            getEntitiesList();
        else
            setAuditForm({owner: owner, suppliers: [], thresholdDate: defaultDate});
        setSearchQuery("");
    }, [show, owner])

    useEffect(() => {
        getEntitiesList();
        setAuditForm({...auditForm, suppliers: []});
    }, [auditForm.thresholdDate])

    useEffect(() => {
        if (entitiesCopy) {
            const entitiesTemp = [...entitiesCopy];
            const upperQuery = searchQuery.toString().toUpperCase();
            setEntities(entitiesTemp.filter(e => e.name?.toUpperCase()?.includes(upperQuery) || e.code?.toUpperCase()?.includes(upperQuery) || e.address?.toUpperCase()?.includes(upperQuery)));
        }
    }, [searchQuery])

    const selectEntity = (entity) => {
        const selectedTemp = [...auditForm.suppliers];

        const allSuppliersIndex = selectedTemp.findIndex(row => row.uid === "allSuppliers");
        if (allSuppliersIndex>=0)
            selectedTemp.splice(allSuppliersIndex,1)
        
        const entityIndex = selectedTemp.findIndex(row => row.uid === entity.uid);
        if (entityIndex >= 0)
            deleteRecipientHandler(entity.uid)
        else
            addRecipientsHandler([entity])
    }

    const addRecipientsHandler = (newRecipients) => {
        const allSuppliers = newRecipients.find(row => row.uid === "allSuppliers");
        let selectedSuppliersTemp = [...auditForm.suppliers]
        const recipientsAllSuppliersIndex = selectedSuppliersTemp.findIndex(row => row.uid === "allSuppliers")
        if (allSuppliers) {
            if (recipientsAllSuppliersIndex < 0) {
                selectedSuppliersTemp = []
                selectedSuppliersTemp.push(allSuppliers)
            }
        } else {
            if (newRecipients.length > 0) {
                if (recipientsAllSuppliersIndex >= 0)
                    selectedSuppliersTemp.splice(recipientsAllSuppliersIndex, 1)
                newRecipients.map(row => {
                    const existAlready = selectedSuppliersTemp.find(elmnt => elmnt.uid === row.uid)
                    if(!existAlready)
                        selectedSuppliersTemp.push(row)
                })
            }
        }
        setAuditForm({...auditForm, suppliers: selectedSuppliersTemp})
    }

    const deleteRecipientHandler = (recipientUid) => {
        let recipientsTemp = [...auditForm.suppliers]
        let recipientIndex = recipientsTemp.findIndex(row => row.uid === recipientUid);
        if(recipientUid == "allSuppliers") setAllSuppliersSelected(false)
        if(recipientIndex >= 0)
            recipientsTemp.splice(recipientIndex, 1)

        setAuditForm({...auditForm, suppliers: recipientsTemp})
    }

    const selectAllSuppliers = (selected) => {
        let selectAllSuppliers = {
            name: "Tous",
            uid: "allSuppliers",
            type: "allSuppliers"
        }
        if(selected){
            addRecipientsHandler([selectAllSuppliers])
            setAllSuppliersSelected(true)
        }else {
            deleteRecipientHandler(selectAllSuppliers.uid)
            setAllSuppliersSelected(false)
        }
    }

    const confirmRequest = () => {
        return new Promise((resolve, reject) => {
            Axios.post(`/accountingaudit`, auditForm).then(response => {
                if (response.data.success) {
                    notyf.success("Campagne de circularisation lancée avec succès");
                    handleClose();
                    refreshTable();
                    resolve(true);
                } else {
                    notyf.error("Erreur lors du lancement de la campagne de circularisation");
                    reject(false);
                }
            }).catch(() => {
                notyf.error("Échec du lancement de la campagne de circularisation");
                reject(false);
            })
        })
    }

    const dateChangeHandler = date => {
        setAuditForm({...auditForm, thresholdDate: date.date.getTime()});
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>Campagne de circularisation</Modal.Title>
                </Modal.Header>
                <Modal.Body className="newAuditModal__body">
                    <div className="newAuditModal__date_email">
                        <div className="newAuditModal__field_div">
                            <h5>Date</h5>
                            <input type="text" className="form-control inputReadonly" value={formatDate(auditForm.thresholdDate)} onClick={() => setShowDateRange(true)} readOnly={true} />
                        </div>
                        <div className="newAuditModal__field_div">
                            <h5>Commissaire aux comptes</h5>
                            <select className="form-control inputReadonly" value={auditForm.statutoryAuditor || ""} onChange={e => setAuditForm({...auditForm, statutoryAuditor: e.target.value})}>
                                <option value="">-- Choisissez un commissaire aux comptes --</option>
                                {auditors.map(a => <option value={a.uid}>{a.name} - {a.email}</option>)}
                            </select>
                        </div>
                    </div>
                    <div>
                        <h5>Destinataires</h5>
                        {/* <div className="newAuditModal__entitiesHeader_container">
                            {
                                <div className="newAuditModal__selection_container">
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="supplierSelection"
                                            id="supplierSelection"
                                            checked = {!(auditForm.suppliers[0]?.uid === "allSuppliers")}
                                            onChange={(e) => selectAllSuppliers(false)}
                                        />
                                        <label className="form-check-label" htmlFor="supplierSelection">Sélection</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="allSuppliers"
                                            id="allSuppliers" 
                                            checked = {auditForm.suppliers[0]?.uid === "allSuppliers"}
                                            onChange={(e) => selectAllSuppliers(true)}
                                        />
                                        <label className="form-check-label" htmlFor="allSuppliers"> 
                                            Tous
                                        </label>
                                    </div>
                                </div>
                            }
                        </div> */}
                        <div className="newAuditModal__entities_container">
                            { !(auditForm.suppliers[0]?.uid === "allSuppliers") &&
                                <>
                                    <div className="newAuditModal__entities_header">
                                        <div className=" input-group input-group-sm">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Rechercher"  
                                                onChange={(e) => setSearchQuery(e.target.value)} 
                                                value={searchQuery || ''}
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                                            </div>
                                        </div>  
                                    </div>
                                
                                    <div className="newAuditModal__entities_list">
                                        <EntitiesTable
                                            entities={entities}
                                            selectedEntities={auditForm.suppliers}
                                            selectEntity={selectEntity}
                                            ownerUid={owner}
                                            thresholdDate={auditForm.thresholdDate}
                                        />
                                    </div>
                                </>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('default:cancel', "Annuler")} 
                    </Button>
                    <Button variant="danger" onClick={() => setShowConfirmModal(true)} disabled={!auditForm.suppliers.length || !auditForm.thresholdDate || !auditForm.statutoryAuditor}>
                        Envoyer la campagne de circularisation 
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomConfirmModal 
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                text={`Êtes-vous sûr de vouloir envoyer la requête de circularisation aux fournisseurs sélectionnés ?`}
                okCallback={confirmRequest}
            />
            <DatePicker
                title="Choisir une date"
                show={showDateRange} 
                format={formatDate} 
                clicked={() => setShowDateRange(false)} 
                changed={date => dateChangeHandler(date)} 
                date={new Date(+auditForm.thresholdDate)}
                field="thresholdDate"
            />
        </>
    )
}


export default NewAuditModal
