import React, { useCallback, useEffect, useState } from "react";

import Axios from "../../axios-proas";

import EntitiesComponent from "../../components/UI/EntitiesComponent/EntitiesComponent";
import MandatoryMarker from '../../components/UI/MandatoryMarker';
import { formatDate, getNotyfObject } from "../../shared/utility";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DoneIcon from '@mui/icons-material/Done';
import HelpIcon from '@mui/icons-material/Help';

import frLocale from "date-fns/locale/fr";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import {
  Content,
  ATContainer,
  ATCol6,
  ATRow,
  ATBody,
  ATFType,
  ATRightColumn,
  ATLeftColumn,
  ATFormHeader,
  ATFormHeaderTitle,
  ATFormStepDoneIcon,
  ATFormStepHeaderTitle,
  ATFormStepNumber
} from "./AuditTrailForm.styled";
import {
  HeaderTitle,
  FormGroup,
  FormInput,
  FormLabel,
} from "../../styles/Common";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { DPContainer } from "../CampaignForm/CampaignForm.styled";
import { Button } from "react-bootstrap";
import { CTooltip } from "../../components/UI/CTooltip/CTooltip";

function AuditTrailForm() {
  const notyf = getNotyfObject();
  const { uid } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState(null);
  const [options, setOptions] = useState(null);
  const [startIssuingDateError, setStartIssuingDateError] = useState(false);
  const [endIssuingDateError, setEndIssuingDateError] = useState(false);
  const [startIssuingDate, setStartIssuingDate] = useState(null);
  const [endIssuingDate, setEndIssuingDate] = useState(null);
  const [errors, setErrors] = useState(null);
  const [auditForm, setAuditForm] = useState({});
  const [auditFormCopy, setAuditFormCopy] = useState(null);

  useEffect(() => {
    switch(type) {
      case "form":
        setAuditForm({
          number: null,
          suppliers: [],
          entities: [],
          new: true,
          issuingDateStart: startIssuingDate?.getTime(),
          issuingDateEnd: endIssuingDate?.getTime()
        });
        break;
      case "month":
      default:
        setAuditForm({new: true});
    }
  }, [type])

  useEffect(() => {
    if (uid != null) {
      Axios.get("audittrail/" + uid + "/object").then((response) => {
          const result = { ...response.data, new: false };
          setType(result.type);
          setAuditForm(result);
          setAuditFormCopy(result);
          setStartIssuingDate(new Date(+result?.issuingDateStart));
          setEndIssuingDate(new Date(+result?.issuingDateEnd));
      }).catch((response) => {
          notyf.error("Une erreur s'est produite");
        });
    } else {
      Axios.get("audittrail//options").then((response) => {
          let optionsTemp = response?.data?.options;
          if (optionsTemp) {
            setOptions(optionsTemp);
            setStartIssuingDate(new Date(+optionsTemp?.issuingDateMin));
            setEndIssuingDate(new Date(+optionsTemp?.issuingDateMax));
          } else {
            notyf.error("Une erreur s'est produite");
          }
        }).catch((response) => {
          notyf.error("Une erreur s'est produite");
        });
    }
  }, []);

  const generateMonthOptions = useCallback(() => {
      const formatMonth = (date) => {
          if(date.getMonth() < 9)
              return "0"+(date.getMonth() + 1);
          else
              return date.getMonth() + 1;
      }
      const months = [];
      const endDate = new Date().getTime();
      const date = new Date(1669849200000);
      
      do {
          months.push(formatMonth(date)+"/"+date.getFullYear());
          date.setMonth(date.getMonth()+1);
      } while(date.getTime() < endDate)
      return months;
  }, [])

  const months = generateMonthOptions();

  //issuing date
  const handleStartIssuingDateChange = (date) => {
    if (date != null) {
      if (date.toString() === "Invalid Date") {
        setStartIssuingDateError(true);
      } else {
        if (
          date.getFullYear <= 1900 ||
          date.getFullYear > 2100 ||
          date.getFullYear >= 1000 && date.getTime() > auditForm.issuingDateEnd
        ) {
          notyf.error(
            "La date de début doit être inférieure ou égale à la date de fin"
          );
        } else {
          setStartIssuingDateError(false);
          setStartIssuingDate(date);
          setAuditForm({
            ...auditForm,
            issuingDateStart: date.getTime(),
          });
        }
      }
    }
  };

  const handleEndIssuingDateChange = (date) => {
    if (date != null) {
      if (date.toString() === "Invalid Date") {
        setEndIssuingDateError(true);
      } else {
        if (
          date.getFullYear >= 1000 && date.getTime() < auditForm.issuingDateStart ||
          date.getFullYear > 2100
        ) {
          notyf.error(
            "La date de fin doit être supérieure ou égale à la date de début"
          );
        } else {
          setEndIssuingDateError(false);
          setEndIssuingDate(date);
          setAuditForm({
            ...auditForm,
            issuingDateEnd: date.getTime(),
          });
        }
      }
    }
  };

  const generateMaxEndIssuingDate = () => {
    let startDate = new Date(auditForm.issuingDateStart);
    if (options?.issuingDateMax) return new Date(+options?.issuingDateMax);
    else return new Date(startDate.setFullYear(startDate.getFullYear(), 12, 0));
  };

  const invoiceNumberChangeHandler = (e) => {
    const field = e.target.name;

    const value = e.target.value;
    setAuditForm({
      ...auditForm,
      [field]: value,
    });
  };

  const checkExtractionValidity = () => {
    let errorsTemp = [];
    if (startIssuingDateError || endIssuingDateError) {
      errorsTemp.push(
        `La Date de Fin ne peut être supérieure à la Date de Début, Veuillez saisir une date de facture entre ${formatDate(
          +options.issuingDateMin
        )} et ${formatDate(+options.issuingDateMax)}`
      );
    }
    
    if (
      !auditForm.title ||
      auditForm.title == null ||
      auditForm.title == "" ||
      auditForm.title == " "
    ) {
      errorsTemp.push("Le titre est obligatoire");
    }
    return errorsTemp;
  };

  const createAudit = () => {
    Axios.post("audittrail", {...auditForm, type: type})
      .then((response) => {
        if (response?.data?.success) {
          notyf.success("La demande de PAF a bien été créée");
          navigate("/auditTrail");
        } else if (parseInt(response?.data?.invoices) === 0) {
          setErrors(["Aucune facture trouvée avec ces filtres"]);
        }
      })
      .catch(err => {
        notyf.error(err.response.data.error);
      });
  };

  const submitAuditFormClickHandler = () => {
    let errorsTemp = checkExtractionValidity();
    if (errorsTemp.length > 0) {
      setErrors(errorsTemp);
    } else {
      setErrors([])
      createAudit()
    }
  };

  const entitiesChangeHandler = (entitiesList) => {
    let entityList = [];
    if (Array.isArray(entitiesList))
      entityList = entitiesList;
    else 
      entityList.push(entitiesList);
    const formTemp = {
      ...auditForm,
      entities: entityList,
    };
    setAuditForm(formTemp);
  };

  const deleteEntityclickHandler = (entity) => {
    let formTemp = { ...auditForm };
    if (!auditForm?.new) {
      formTemp = {
        ...formTemp,
        entitiesToDelete: formTemp?.entitiesToDelete
          ? [...formTemp?.entitiesToDelete, entity.uid]
          : [entity.uid],
      };
    }
    const entities = [...auditForm?.entities];
    const index = auditForm?.entities.findIndex(
      (ent) => ent.uid === entity.uid
    );
    if (index >= 0) {
      entities.splice(index, 1);
    }
    setAuditForm({
      ...formTemp,
      entities: entities,
    });
  };

  const supplierChangeHandler = (suppliersList) => {
    let supplierList = [];
    if (Array.isArray(suppliersList))
      supplierList = suppliersList;
    else 
      supplierList.push(suppliersList);
    const formTemp = {
      ...auditForm,
      suppliers: supplierList,
    };
    setAuditForm(formTemp);
  };

  const deleteSupplierClickHandler = (supplier) => {
    let formTemp = { ...auditForm };
    if (!auditForm?.new) {
      formTemp = {
        ...formTemp,
        suppliersToDelete: formTemp?.suppliersToDelete
          ? [...formTemp?.suppliersToDelete, supplier.uid]
          : [supplier.uid],
      };
    }
    const suppliers = [...auditForm?.suppliers];
    const index = auditForm?.suppliers.findIndex(
      (ent) => ent.uid === supplier.uid
    );
    if (index >= 0) {
      suppliers.splice(index, 1);
    }
    setAuditForm({
      ...formTemp,
      suppliers: suppliers,
    });
  };

  return (
    <ATContainer>
      <ATBody>
        {errors && errors.length > 0 ? (
          <HeaderTitle
            className="alert alert-danger mt-2 campaignForm__errors_container"
            role="alert"
          >
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </HeaderTitle>
        ) : null}
        <ATFormHeader>
            <ATFormHeaderTitle>Je crée ma demande de PAF</ATFormHeaderTitle>
        </ATFormHeader>
        <ATRow>
          <ATLeftColumn>
            {
              type ? 
                <ATFormStepDoneIcon><DoneIcon /> </ATFormStepDoneIcon>
                :
                <ATFormStepNumber>1</ATFormStepNumber>
            }
            <ATFormStepHeaderTitle>
              Je choisis le type de demande
            </ATFormStepHeaderTitle>
          </ATLeftColumn>
          <ATRightColumn style={{width: "50%", margin: "auto"}}>
            <ATFType onClick={() => uid ? null : setType("month")} className={type == 'month' ? "selected_type" : null}>
              <CalendarTodayIcon />
              <p>Par mois</p>
            </ATFType>
            <ATFType onClick={() => uid ? null : setType("form")} className={type == 'form' ? "selected_type" : null}>
              <ListAltIcon />
              <p>Par critères</p>
            </ATFType>
          </ATRightColumn>
        </ATRow>
        {type == "month" && <ATRow>
          <ATLeftColumn>
            {
            auditForm.monthYear && auditForm.title ? 
                <ATFormStepDoneIcon><DoneIcon /> </ATFormStepDoneIcon>
                :
                <ATFormStepNumber>2</ATFormStepNumber>
            }
            <ATFormStepHeaderTitle>
              Je saisis un titre et un mois pour ma demande
            </ATFormStepHeaderTitle>
          </ATLeftColumn>
          <ATRightColumn>
            <ATCol6 style={{margin: "auto"}}>
              <Content>
                <FormGroup className="mb-4">
                  <FormLabel htmlFor="title" >Titre<MandatoryMarker /> <CTooltip title={"Nom donné à votre demande"}><HelpIcon/></CTooltip></FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                    name="title"
                    value={auditForm.title || ""}
                    onChange={(e) => invoiceNumberChangeHandler(e)}
                    disabled={uid}
                  />
                </FormGroup>
                <select className="form-control" style={{width: "60%", margin:"20px auto"}} value={auditForm.monthYear || ""} onChange={e => setAuditForm({...auditForm, monthYear: e.target.value})} disabled={uid}>
                    <option value="">-- Choisissez un mois --</option>
                    {months?.map(o => <option key={o} value={o.replace("/", "-")}>{o}</option>)}
                </select>
              </Content>
            </ATCol6>
          </ATRightColumn>
        </ATRow>}
        {type == "form" && <ATRow>
          <ATLeftColumn>
            {
            auditForm.title ? 
                <ATFormStepDoneIcon><DoneIcon /> </ATFormStepDoneIcon>
                :
                <ATFormStepNumber>2</ATFormStepNumber>
            }
            <ATFormStepHeaderTitle>
              Je remplis le formulaire (limite de 50 000 factures)
            </ATFormStepHeaderTitle>
          </ATLeftColumn>
          <ATRightColumn>
            <ATCol6>
              <Content>
                <FormGroup className="mb-4">
                  <FormLabel htmlFor="title" >Titre<MandatoryMarker /> <CTooltip title={"Nom donné à votre demande"}><HelpIcon/></CTooltip></FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                    name="title"
                    value={auditForm.title || ""}
                    onChange={(e) => invoiceNumberChangeHandler(e)}
                    disabled={uid}
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <FormLabel htmlFor="name">Période de facturation <CTooltip title={"Prend les factures dont la date est comprise dans la période choisie"}><HelpIcon/></CTooltip></FormLabel>
                </FormGroup>
                <FormGroup className="mb-4">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={frLocale}
                  >
                    <DPContainer>
                      <DatePicker
                        //margin="normal"
                        className="extractionForm__datepicker"
                        id="date-picker-dialog"
                        label="Début"
                        format="dd/MM/yyyy"
                        minDate={new Date(+options?.issuingDateMin)}
                        maxDate={
                          auditForm?.issuingDateStart
                            ? auditForm?.issuingDateEnd
                            : generateMaxEndIssuingDate()
                        }
                        value={startIssuingDate}
                        onChange={handleStartIssuingDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        invalidDateMessage="Format de date invalide"
                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                        renderInput={(params) => <TextField {...params} />}
                        disabled={uid ? true : false}
                      />
                    </DPContainer>
                    <DPContainer>
                      <DatePicker
                        //margin="normal"
                        className="extractionForm__datepicker"
                        id="date-picker-dialog"
                        label="Fin"
                        format="dd/MM/yyyy"
                        value={endIssuingDate}
                        onChange={handleEndIssuingDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        minDate={
                          auditForm?.issuingDateStart
                            ? auditForm?.issuingDateStart
                            : new Date(+options?.issuingDateMin)
                        }
                        maxDate={generateMaxEndIssuingDate()}
                        invalidDateMessage="Format de date invalide"
                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                        renderInput={(params) => <TextField {...params} />}
                        disabled={uid ? true : false}
                      />
                    </DPContainer>
                  </LocalizationProvider>
                </FormGroup>
                <FormGroup className="mb-4">
                  <FormLabel htmlFor="number" >Numéro de facture <CTooltip title={"Numéro d'une facture à rechercher en particulier (un seul numéro de facture autorisé, optionnel)"}><HelpIcon/></CTooltip></FormLabel>
                  <FormInput
                    id="number"
                    type="text"
                    name="number"
                    value={auditForm.number || ""}
                    onChange={(e) => invoiceNumberChangeHandler(e)}
                    disabled={uid}
                  />
                </FormGroup>
              </Content>
            </ATCol6>
            <ATCol6>
              <div>
                <EntitiesComponent
                  show={true}
                  label={<CTooltip title={"List des entités Transdev facturées à considérer pour la recherche (optionnel)"}><div>Entités <HelpIcon/></div></CTooltip>}
                  modalTitle={"Choisir des sociétés Transdev"}
                  entities={auditForm?.entities}
                  deleteEntity={deleteEntityclickHandler}
                  addEntity={entitiesChangeHandler}
                  entitiesType={"owner"}
                  userType={""}
                  isMandatory={false}
                  disabled={uid}
                />
              </div>
              <div>
                <EntitiesComponent
                  show={true} 
                  label={<CTooltip title={"Liste des fournisseurs à considérer pour la recherche (optionnel)"}><div>Fournisseurs <HelpIcon/></div></CTooltip>} 
                  modalTitle={"Choisir des fournisseurs"}
                  entities={auditForm?.suppliers} 
                  deleteEntity={deleteSupplierClickHandler} 
                  addEntity={supplierChangeHandler}
                  entitiesType={"supplier"}
                  userType={""}
                  isMandatory={false}
                  disabled={uid}
                />
              </div>
            </ATCol6>
          </ATRightColumn>
        </ATRow>}
        {!uid && auditForm.title && (type == "form" || auditForm.monthYear) ? <ATRow>
          <ATLeftColumn>
            <ATFormStepNumber>3</ATFormStepNumber>
            <ATFormStepHeaderTitle>
              Je clique sur le bouton pour valider ma demande
            </ATFormStepHeaderTitle>
          </ATLeftColumn>
          <ATRightColumn>
            <Button variant="danger" style={{margin: "auto"}} onClick={() => submitAuditFormClickHandler()}>
              Confirmer la demande
            </Button>
          </ATRightColumn>
        </ATRow> : null}
      </ATBody>
    </ATContainer>
  );
}

export default AuditTrailForm;
