import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import "../Select.css"
import { BtnOutlineAction, Header, HeaderActions } from '../../../styles/Common';
import Axios from '../../../axios-proas'
import { connect } from 'react-redux';
import { checkValidity, getNotyfObject } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';
import { useTheme } from 'styled-components';
import { useParams } from "react-router-dom";
import EntitiesComponent from '../../../components/UI/EntitiesComponent/EntitiesComponent';
import { CFBody, CFContainer, CFFormGroup, CFFormSection, CFSection, FormInput, FormLabel } from "../NewGrouping/NewGroupingForm.styled";

function GroupingDetail() {
  const { t } = useTranslation();
  const theme = useTheme()
  let { uid } = useParams();
  const [groupingEntities, setGroupingEntities] = useState([]);
  const [groupingDetail, setGroupingDetail] = useState()
  const [entitiesToDelete, setEntitiesToDelete] = useState([])
  const [entitiesToAdd, setEntitiesToAdd] = useState([])
  const [groupingTitle, setGroupingTitle] = useState("")
  const [errors, setErrors] = useState([])
  let notyf = getNotyfObject();

  useEffect(() => {
    //console.log(uid)
    Axios.get('/regroupement/' + uid + '/getRegroupementByRef')
      .then(response => {
        setGroupingEntities(response?.data?.groupingEntitiesList)
        setGroupingDetail(response?.data?.groupingDetail)
        setGroupingTitle(response?.data?.groupingDetail?.title)
      })
  }, [])



  const checkFormValidity = () => {
    let errors = []
    if(groupingTitle == ""){
        errors.push(t("masterData:e", "Le champ 'Nom du groupe' est vide")) ;
    }
    if( groupingEntities.length == 0){
        errors.push(t("masterData:e", "Aucune entité n'a été sélectionnée !"));
    }
    return errors;
  }


  const deleteEntityclickHandler = (entity) => {

    // console.log(entity)
    // check entities To Add
    let entitiesToAddTmp = entitiesToAdd
    let entitiesToAddIndex = entitiesToAddTmp.findIndex(ent => ent.uid === entity.uid);
    if (entitiesToAddIndex >= 0) {
      entitiesToAddTmp.splice(entitiesToAddIndex, 1)
      setEntitiesToAdd(entitiesToAddTmp)
    }

    // check entities to delete
    let entitiesToDeleteTmp = entitiesToDelete
    let entitiesToDeleteIndex = entitiesToDeleteTmp.findIndex(ent => ent.uid === entity.uid);
    console.log(entitiesToDeleteIndex)
    if (entitiesToDeleteIndex == -1) {
      entitiesToDeleteTmp.push(entity)
      setEntitiesToDelete(entitiesToDeleteTmp)
    }
    console.log(entitiesToDeleteTmp)

    // check current entities
    let entitiesTmp = [...groupingEntities]
    let entitiesTmpIndex = entitiesTmp.findIndex(ent => ent.uid === entity.uid);
    if (entitiesTmpIndex >= 0) {
      console.log(" >=0 ")
      entitiesTmp.splice(entitiesTmpIndex, 1)
      console.log(entitiesTmp)
      setGroupingEntities(entitiesTmp)
    }
  }

  const addEntityclickHandler = (newEntities) => {
    // console.log(entitiesToAdd)

    let entitiesToDeleteTmp = entitiesToDelete
    let entitiesToAddTmp = entitiesToDelete
    let entitiesTmp = [...groupingEntities]

    newEntities.forEach(entity => {
        let entitiesToDeleteIndex = entitiesToDeleteTmp.findIndex(ent => ent.uid === entity.uid);
        if (entitiesToDeleteIndex >= 0) {
          entitiesToDeleteTmp.splice(entitiesToDeleteIndex, 1)
        }
        // check entities to delete
        let entitiesToAddIndex = entitiesToAddTmp.findIndex(ent => ent.uid === entity.uid);
        if (entitiesToAddIndex == -1) {
          entitiesToAddTmp.push(entity)
        }
        // check current entities
        let entitiesTmpIndex = entitiesTmp.findIndex(ent => ent.uid === entity.uid);
        if (entitiesTmpIndex == -1) {
          entitiesTmp.push(entity)
        }
      });
      setEntitiesToDelete(entitiesToDeleteTmp)
      setEntitiesToAdd(entitiesToAddTmp)
      setGroupingEntities(entitiesTmp)

  }


  const updateRegroupment = () => {
    // if()
    let errors = checkFormValidity()
    if(errors.length == 0){
      setErrors([])
      Axios.put('/regroupement', {groupingEntities: groupingEntities, title:groupingTitle, uid:groupingDetail?.uid})
        .then(response => {
          // setGroupingEntities(response?.data?.groupingEntitiesList)
          // setGroupingDetail(response?.data?.groupingDetail)
          notyf.success(t("default:us", "Groupe a bien été modifié"))
        })
    }
    else 
      setErrors(errors)
  }



  return (

    <CFContainer>
      <Header>
        <HeaderActions>
          <BtnOutlineAction
            border={theme.colors.success}
            color={theme.colors.success}
            onClick={updateRegroupment}
          >
            <span>Valider</span>
          </BtnOutlineAction>
        </HeaderActions>
      </Header>

      <CFBody>
        {
          errors && errors.length > 0 ? (
              <div className="alert alert-danger mt-2" role="alert">
                  <ul>
                      {
                          errors.map(error => (<li key={error}>{error}</li>))
                      }
                  </ul>
              </div>
          ) : null
        }
        <CFSection>
          <CFFormSection>
            <CFFormGroup style={{ marginBottom: "2.2rem" }}>
              <FormLabel htmlFor="titleg">Nom de groupe : </FormLabel>
              <FormInput
                id="titleg"
                type="text"
                placeHolder="Titre"
                value={groupingTitle || "" }
                onChange={e => setGroupingTitle(e.target.value)}
                maxLength={255}
                name="title"
              />
            </CFFormGroup>

            <EntitiesComponent
              show={true}
              label={"Liste des entités"}
              modalTitle={"Sociétés associées au regroupement"}
              entities={groupingEntities}
              deleteEntity={deleteEntityclickHandler}
              addEntity={addEntityclickHandler}
              entitiesType={"owner"}
              isMandatory={true}
            />
          </CFFormSection>
        </CFSection>
      </CFBody>
    </CFContainer>


  )
}

export default GroupingDetail