import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { getNotyfObject } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../../axios-proas'
import Spinner from 'react-bootstrap/Spinner'
import MessageEvent from './TimelineCards/MessageEvent/MessageEvent';
import StatusEvent from './TimelineCards/StatusEvent/StatusEvent';
import { TLContainer } from './Timeline.styled';
import RVCAEvent from './TimelineCards/RVCAEvent/RVCAEvent';
import ReturnRVCAEvent from './TimelineCards/ReturnRVCAEvent/ReturnRVCAEvent';
import RejectStatusEvent from './TimelineCards/RejectStatusEvent/RejectStatusEvent';
import RejectRebornEvent from './TimelineCards/RejectRebornEvent/RejectRebornEvent';
import RejectErr00Event from './TimelineCards/RejectErr00Event/RejectErr00Event';
import RejectWaitingCNoteEvent from './TimelineCards/RejectWaitingCNoteEvent/RejectWaitingCNoteEvent';
import RejectInvoiceUpdateEvent from './TimelineCards/RejectInvoiceUpdateEvent/RejectInvoiceUpdateEvent';
import CodaRefusedRequalificationEvent from './TimelineCards/CodaRefusedRequalificationEvent/CodaRefusedRequalificationEvent';
import CodaRefusedArchivedEvent from './TimelineCards/CodaRefusedArchivedEvent/CodaRefusedArchivedEvent';
import RejectRebornRequestEvent from './TimelineCards/RejectRebornRequestEvent/RejectRebornRequestEvent';
import FutureValidatedEvent from './TimelineCards/FutureValidatedEvent/FutureValidatedEvent';
import RejectRebornRefusalEvent from './TimelineCards/RejectRebornRefusalEvent/RejectRebornRefusalEvent';
import CodaRefusedUnarchivedEvent from './TimelineCards/CodaRefusedUnarchivedEvent/CodaRefusedUnarchivedEvent';
import CodaRefusedUnarchivingRequest from './TimelineCards/CodaRefusedUnarchivingRequest/CodaRefusedUnarchivingRequest';
import CodaRefusedUnarchivingDenial from './TimelineCards/CodaRefusedUnarchivingDenial/CodaRefusedUnarchivingDenial';


function TimeLine(props) {
    const {
        userUid,
        userType,
        statuses,
        rejectTypes,
        invoiceUid
    } = props 
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    const [timelineData, setTimelineData] = useState(null);
    const [loading , setLoading] = useState(false)

    const getTimelineData = () => {
        setLoading(true)
        Axios.get("invoiceevent", {
            params: {
                invoice: invoiceUid,
                orderBy: "creationDate",
                reverse: 1
            }
        }).then(response => {
            setLoading(false)
            setTimelineData(response?.data?.events)
        }).catch(response => {
            setLoading(false)
            notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    }

    useEffect(() => {
        getTimelineData()
    }, [invoiceUid])

    let timelineJsx = null;

    if(loading) 
        timelineJsx = (<Spinner />)
    else if (timelineData && timelineData?.length > 0){
        timelineJsx = timelineData?.map((timelineEvent, index) => {
                var eventJsx = null;
                let isLast = index === 0
                let isCreator = userUid === timelineEvent?.user?.userUid;
                switch (timelineEvent?.type) {
                    case "STEP_STATUS":
                        eventJsx = (<StatusEvent
                                        userType={userType}
                                        statuses={statuses}
                                        eventData={timelineEvent}
                                        isCreator={isCreator}
                                        isLast={isLast}
                                        rejectTypes={rejectTypes}
                                        key={timelineEvent.id}
                                    />)
                        break;
                    case "USER_CHAT_EVENT":
                        eventJsx = (<MessageEvent
                                        eventData={timelineEvent}
                                        isLast={isLast}
                                        key={timelineEvent.id}
                                    />)
                        break;
                    case "RVCA":
                        eventJsx = (<RVCAEvent
                                eventData={timelineEvent}
                                isCreator={isCreator}
                                isLast={isLast}
                                key={timelineEvent.id}
                            />)
                        break;
                    case "RETURN_RVCA":
                        eventJsx = (<ReturnRVCAEvent
                                eventData={timelineEvent}
                                isCreator={isCreator}
                                isLast={isLast}
                                key={timelineEvent.id}
                            />)
                        break;
                    case "REJECT_STATUS":
                        eventJsx = (<RejectStatusEvent
                                    eventData={timelineEvent}
                                    isLast={isLast}
                                    key={timelineEvent.id}
                                />)
                        break;
                    case "REJECT_REBORN":
                        eventJsx = (<RejectRebornEvent
                            eventData={timelineEvent}
                            isLast={isLast}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REJECT_REBORN_REQUEST":
                        eventJsx = (<RejectRebornRequestEvent
                            eventData={timelineEvent}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REJECT_REBORN_REFUSAL":
                        eventJsx = (<RejectRebornRefusalEvent
                            eventData={timelineEvent}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REJECT_ERROR_00":
                        eventJsx = (<RejectErr00Event
                            userType={userType}
                            statuses={statuses}
                            eventData={timelineEvent}
                            isCreator={isCreator}
                            isLast={isLast}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REJECT_WAITING_CNOTE":
                        eventJsx = (<RejectWaitingCNoteEvent
                            userType={userType}
                            statuses={statuses}
                            eventData={timelineEvent}
                            isCreator={isCreator}
                            isLast={isLast}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REJECT_INVOICE_UPDATE":
                        eventJsx = (<RejectInvoiceUpdateEvent
                            eventData={timelineEvent}
                            isLast={isLast}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REFUSAL_REQUALIF":
                        eventJsx = (<CodaRefusedRequalificationEvent
                            eventData={timelineEvent}
                            isCreator={isCreator}
                            isLast={isLast}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REFUSAL_ARCHIVED":
                        eventJsx = (<CodaRefusedArchivedEvent
                            eventData={timelineEvent}
                            isCreator={isCreator}
                            isLast={isLast}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REFUSAL_UNARCHIVED":
                        eventJsx = (<CodaRefusedUnarchivedEvent
                            eventData={timelineEvent}
                            isLast={isLast}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REFUSAL_UNARCHIVED_REQUEST":
                        eventJsx = (<CodaRefusedUnarchivingRequest
                            eventData={timelineEvent}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "REFUSAL_UNARCHIVED_DENIAL":
                        eventJsx = (<CodaRefusedUnarchivingDenial
                            eventData={timelineEvent}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "FUTURE_VALIDATED":
                        eventJsx = (<FutureValidatedEvent
                            eventData={timelineEvent}
                            validated={true}
                            key={timelineEvent.id}
                        />)
                        break;
                    case "FUTURE_INVALIDATED":
                        eventJsx = (<FutureValidatedEvent
                            eventData={timelineEvent}
                            validated={false}
                            key={timelineEvent.id}
                        />)
                        break;
                    default:
                        break;
                }
               return eventJsx
            })
    }

    return ( 
        <TLContainer>
            {timelineJsx}
        </TLContainer>
    )
}
const mapStateToProps = (state) => ({
    userUid : state.auth.userUid,
    userType: state.auth.userType,
    // loading: state.mandat.loadingTimeline,
    // timelineData: state.mandat.timelineData,
    // error: state.mandat.errorTimeline,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid
})
const mapDispatchToProps = dispatch => ({
    // getMandatTimelineEvents: (mandatUid) => dispatch(actions.getMandatTimelineEvents(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status))
})
export default connect(mapStateToProps, mapDispatchToProps)(TimeLine)