import styled from 'styled-components';

export const BarChartContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #D9E1E7CC;
    border-radius: 16px;
    padding: 1rem 2rem;
    margin-bottom: 0.5rem;

    & canvas {
        width: 100% !important;
    }

    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: 100%;
    }
`

export const SelectDiv = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding-top: 16px;
    border-top : 1px #dcdcdc solid;
`