import styled from 'styled-components';

export const IPQContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const IPQInitialFilters = styled.div`
    width: 70%;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
`

export const IPQInitialFilter  = styled.div`
    width: 200px;
    text-align: center;
    height: 140px;
    border: 2px solid red;
    border-radius: 10px;
    box-shadow: 4px 4px 3px #bbb;
    cursor: pointer;
    padding: 20px;
    transition: 0.2s;

    & svg {
        height: 32px;
        width: 32px;
        color: red;
    }

    & p {
        margin-top: 12px;
        font-size: 20px;
    }

    &:hover {
        background-color: #eee;
    }
`