import React, { useCallback, useEffect, useState } from 'react'
import './RepertoireModal.css'
import SearchIcon from '@mui/icons-material/Search';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import EntitiesTable from './EntitiesTable/EntitiesTable';

function RepertoireModal({tempEntities,setCampaignRecipients, handleClose, show, allSuppliersSelected, setAllSuppliersSelected, campaignUid, disabled}) {
    const [entities , setEntities] = useState([]);
    const [entitiesType, setEntitiesType] = useState('supplier');
    const [TEntities, setTEntities] = useState([])
    const [searchQuery, setSearchQuery] = useState(null)
    const [allClients, setAllClients] = useState(false);
    const {t} = useTranslation();

    const getEntitiesList = useCallback(() => {
        let filter = {
            searchQuery: searchQuery,
            type : entitiesType,
            campaignUid: campaignUid
        }
        Axios.get('/entity//modalCampagniesEntities', {
            params: filter
        })
        .then(res => {
            setEntities(res.data?.result)
        })
    }, [searchQuery, entitiesType, allSuppliersSelected])

    useEffect(() => {
        getEntitiesList()
    }, [searchQuery, entitiesType])

    useEffect(() => {
        setTEntities(tempEntities)
    }, [tempEntities])

    const selectEntity = (entity) => {
        let selectedTemp = [...TEntities];

        let allSuppliersIndex = selectedTemp.findIndex(row => row.uid === "allSuppliers");
        if(allSuppliersIndex>=0) selectedTemp.splice(allSuppliersIndex,1)
        
        let entityIndex = selectedTemp.findIndex(row => row.uid === entity.uid);
        if(entityIndex >= 0){
            deleteRecipientHandler(entity.uid)
        } else {
            addRecipientsHandler([entity])
        }
        

    }

    const addRecipientsHandler = (newRecipients) => {

        const allClients = newRecipients.find(row => row.uid === "allClients")
        const allSuppliers = newRecipients.find(row => row.uid === "allSuppliers");
        

        let campaignRecipientsTemp = [...TEntities]


        const newRecipientsClients = newRecipients.filter(row => row.type === "client")
        const newRecipientsSuppliers = newRecipients.filter(row => row.type === "supplier")
        const recipientsAllClientsIndex = campaignRecipientsTemp.findIndex(row => row.uid === "allClients")
        const recipientsAllSuppliersIndex = campaignRecipientsTemp.findIndex(row => row.uid === "allSuppliers")

        if(allClients){
            campaignRecipientsTemp = campaignRecipientsTemp.filter(row => row.type !== "client");
            if(recipientsAllClientsIndex < 0)
                campaignRecipientsTemp.push(allClients)
        }else {
            if(newRecipientsClients.length > 0){
                if(recipientsAllClientsIndex >= 0)
                    campaignRecipientsTemp.splice(recipientsAllClientsIndex, 1)
                let recipientsClient = newRecipients.filter(row => row.type === "client")
                recipientsClient.map(row => {
                    let existAlready = campaignRecipientsTemp.find(elmnt => elmnt.uid === row.uid)
                    if(!existAlready)
                        campaignRecipientsTemp.push(row)
                })
            }
        }
        if(allSuppliers){
            //campaignRecipientsTemp = campaignRecipientsTemp.filter(row => row.type !== "supplier");
            if(recipientsAllSuppliersIndex < 0){
                campaignRecipientsTemp = []
                campaignRecipientsTemp.push(allSuppliers)
            }
        }else {
            if(newRecipientsSuppliers.length > 0){

                if(recipientsAllSuppliersIndex >= 0)
                    campaignRecipientsTemp.splice(recipientsAllSuppliersIndex, 1)
                let recipientsSupplier = newRecipients.filter(row => row.type === "supplier")
                recipientsSupplier.map(row => {
                    let existAlready = campaignRecipientsTemp.find(elmnt => elmnt.uid === row.uid)
                    if(!existAlready)
                        campaignRecipientsTemp.push(row)
                })
            }
        }
        setTEntities(campaignRecipientsTemp)
        
        
    }

    const deleteRecipientHandler = (recipientUid) => {
        let recipientsTemp = [...TEntities]
        let recipientIndex = recipientsTemp.findIndex(row => row.uid === recipientUid);
        if(recipientUid == "allSuppliers") setAllSuppliersSelected(false)
        if(recipientIndex >= 0)
            recipientsTemp.splice(recipientIndex, 1)

        setTEntities(recipientsTemp)
    }

    const selectAllClients = (selected) => {
        let selectedEntitiesTemp = [...selectedEntities];
        if(selected){
            let selectAllClients = {
                name: "Tous les clients",
                uid: "allClients",
                type: "allClients"
            }
            selectedEntitiesTemp.push(selectAllClients);
            setAllClients(true)
        }else {
            let allClientsIndex = selectedEntitiesTemp.findIndex(row => row.uid === "allClients")
            if(allClientsIndex >= 0)
                selectedEntitiesTemp.splice(allClientsIndex, 1)
            setAllClients(false)
        }
        setSelectedEntities(selectedEntitiesTemp);
    }

    const selectAllSuppliers = (selected) => {
        let selectAllSuppliers = {
            name: "Tous",
            uid: "allSuppliers",
            type: "allSuppliers"
        }
        if(selected){
            addRecipientsHandler([selectAllSuppliers])
            setAllSuppliersSelected(true)
        }else {
            deleteRecipientHandler(selectAllSuppliers.uid)
            setAllSuppliersSelected(false)
        }
    }

    // const selectAllEntities = (e) => {
    //     let selectedTemp = []
    //     if (e.target.checked) {
    //         selectedTemp = selectedEntities.map(entity => ({uid : entity.uid, commercial_name: entity.commercial_name, new: true}))
    //     }
    //     setSelectedEntities(selectedTemp)
    // }

    const addClickHandler = () => {
        setCampaignRecipients(TEntities)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{!campaignUid ? "Ajouter des destinataires" : (disabled ? "Consulter les destinataires" : "Modifier les destinataires")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="repertoireModal__body" >
                    <div className="repertoireModal__entitiesHeader_container">
                        {/* <div className="repertoireModal__entityType">
                            <label htmlFor="entityType">Type : </label>
                            <select 
                                className="form-control form-select" 
                                value={entitiesType}
                                onChange={(e) => setEntitiesType(e.target.value)}
                            >
                                <option value="client">Client</option>
                                <option value="supplier">Fournisseur</option>
                            </select>
                        </div> */}
                        {
                            entitiesType === 'client' ? (
                                <div className="repertoireModal__selection_container">
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="clientSelection"
                                            id="clientSelection"
                                            checked = {!allClients}
                                            disabled={disabled}
                                            onChange={(e) => selectAllClients(false)}
                                        />
                                        <label className="form-check-label" htmlFor="clientSelection">Sélection</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="allClients"
                                            id="allClients" 
                                            checked = {allClients}
                                            disabled={disabled}
                                            onChange={(e) => selectAllClients(true)}
                                        />
                                        <label className="form-check-label" htmlFor="allClients"> 
                                            Tous
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                <div className="repertoireModal__selection_container">
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="supplierSelection"
                                            id="supplierSelection"
                                            checked = {!(TEntities[0]?.uid === "allSuppliers")}
                                            disabled={disabled}
                                            onChange={(e) => selectAllSuppliers(false)}
                                        />
                                        <label className="form-check-label" htmlFor="supplierSelection">Sélection</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="allSuppliers"
                                            id="allSuppliers" 
                                            checked = {TEntities[0]?.uid === "allSuppliers"}
                                            disabled={disabled}
                                            onChange={(e) => selectAllSuppliers(true)}
                                        />
                                        <label className="form-check-label" htmlFor="allSuppliers"> 
                                            Tous
                                        </label>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="repertoireModal__entities_container">
                        { !(TEntities[0]?.uid === "allSuppliers") &&
                            <>
                            <div className="repertoireModal__entities_header">
                                <div className=" input-group input-group-sm">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Rechercher"  
                                        onChange={(e) => setSearchQuery(e.target.value)} 
                                        value={searchQuery || ''}
                                    />
                                    <div className="input-group-append" >
                                        <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between align-items-center">
                                    <p className="productsModal_totalItems">{selectedEntities?.length} entreprise(s) séléctionnée(s)</p>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="selectAllRegies" onChange={(e) => selectAllEntities(e)} />
                                        <label className="form-check-label" htmlFor="selectAllRegies" >
                                            Selectionner tout
                                        </label>
                                    </div>
                                </div> */}   
                            </div>
                        
                            <div className="repertoireModal__entities_list">
                                {
                                    (entitiesType === 'client' && !allClients ) ?
                                        <EntitiesTable
                                            entities={entities}
                                            selectedEntities={TEntities}
                                            selectEntity={selectEntity}
                                            disabled={disabled}
                                            type="client"
                                        />
                                    : null
                                }
                                {
                                    (entitiesType === 'supplier' && !allSuppliersSelected ) ?
                                        <EntitiesTable
                                            entities={entities}
                                            selectedEntities={TEntities}
                                            selectEntity={selectEntity}
                                            disabled={disabled}
                                            type="supplier"
                                        />
                                    : null
                                }
                            </div>
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                {!disabled && <Button variant="primary" onClick={() => addClickHandler()}>
                    Sauvegarder la sélection 
                </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default RepertoireModal
