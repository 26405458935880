import React, { useEffect, useMemo,useState } from 'react';
import { formatDate } from '../../../shared/utility';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SIProgressLine, SIProgressLineBar, SIProgressLineItem, SIProgressLineItemTitle } from './StatusProgressLine.styled';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

function StatusProgressLine({startDate,endDate}) {
    console.log(startDate,endDate)
    const [date, setDate] = useState(new Date().getTime());
    console.log(date);
    const theme = useTheme();
    const {t} = useTranslation()
    const statusList = useMemo(() => [
        {title: t("campaignList:scheduled", "Programmé"), value: "scheduled"},
        {title: t("campaignList:inProgress", "En cours"), value: "inProgress"},
        {title: t("campaignList:completed", "Terminé"), value: "completed"},
    ], [t]);

    const getStatusColor = (value, index) => {
        //const statusIndex = statusList.findIndex(item => item.value.toUpperCase() === status.toUpperCase())
switch(value) {
    case "scheduled":
        if(date < startDate)
        return '#ff0000';
        else
        return '#ffc107';
      
    case "inProgress":
        if(date >= startDate && date <= endDate)
        return "#ff0000";
        else
        return "#ffc107";

    case "completed":
        if(date > endDate)
        return "#ff0000";
        else
        return "#ffc107";
    default:
        return theme.colors.secondary;

}


    }
const [status, setStatus] = useState(null);
    useEffect(() => {
        var dat=new Date().getTime()
                if(dat < startDate)
                setStatus('scheduled')
           
                if(dat >= startDate && dat <= endDate)
                setStatus('inProgress')
              
                if(dat > endDate)
                setStatus('completed');
               
        


    }, [startDate,endDate])
    const showProgressLineBar = (value, index) => {
        // const statusIndex = statusList.findIndex(item => item.value.toUpperCase() === status.toUpperCase());
        // return index <= statusIndex;
        if(value === status)
        return true;
        else
        return false;
    }
    
    
  return (
    <SIProgressLine>
        {
        //  statusList.filter((item,index)=>index<2)
            statusList.map((item, index) => (
              item.value===status&&
                <SIProgressLineItem 
                    key={index}
                >
                    <SIProgressLineItemTitle
                    >
                        <span>
                            {item.title} 
                        </span>
                        <CheckCircleOutlineIcon fontSize="small" />
                    </SIProgressLineItemTitle>
                </SIProgressLineItem>
                
            ))
        }
    </SIProgressLine>
  );
}

export default StatusProgressLine;
