import React, { useEffect, useMemo, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {  EntInfosContainer} from './Infos.styled'
import { EIFormCol, EIFormContainer, EntFormItem, EntFormList, EntFormSection } from '../../../containers/Entity/Entity.styled';
import EntitiesDropDown from '../EntitiesDropDown/EntitiesDropDown';
import { FormECILabel, FormECItem, FormECIValue, FormECLabel, FormECListContainer, FormEntCanal, FormGroup, FormInput, FormLabel, FormSelect } from '../../../styles/Common';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

function Infos({entity, handleInputChange, entityUid,entitiesType, entities, addEntity, deleteEntity, canalList, canalChangeHandler, supplierCanalChangeHandler, supplierCanalList}) {
    const theme = useTheme();
    const { t } = useTranslation();
    
    const showEntitiesDropDown = () => {
        if(entity.hasOwnProperty("type") && entity.type !== "owner")
            return true;
        return false;
    };

    const showInoiceEmail = () => {
        let showCases = ['EMAIL', 'PORTAL'];
        if(showCases.includes(entity.channel))
            return true;
        return false;
    };

  return (
      <>
        <EntInfosContainer>
            {/* {
                showEntitiesDropDown() &&
                    <EntFormSection>
                        <EntitiesDropDown 
                            entitiesType={entitiesType}
                            userEntities={entities}
                            addEntitiesUser={addEntity}
                        />
                        <EntFormList>
                            {
                                entities?.length  > 0 ? entities.map(entity => (
                                    <EntFormItem key={entity.uid} >
                                        <span>{entity.name}</span>
                                        <CloseIcon style={{fontSize: "0.7rem", cursor: "pointer"}} onClick={() => deleteEntity(entity)} />
                                    </EntFormItem>
                                )) : null
                            }
                        </EntFormList>
                    </EntFormSection>
            } */}
            <EIFormContainer>
                <EIFormCol>
                    <FormGroup>
                        <FormLabel htmlFor="name" >NOM</FormLabel>
                        <FormInput 
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Nom"
                            value={entity?.name || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="code" >Code</FormLabel>
                        <FormInput 
                            id="code"
                            type="text"
                            name="code"
                            placeholder="Code"
                            value={entity?.code || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="siret" >Siret</FormLabel>
                        <FormInput 
                            id="siret"
                            type="text"
                            name="siret"
                            placeholder="Siret"
                            value={(entity?.type === "supplier" ? entity?.taxNumber2 : entity?.siret) || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="siren" >Siren</FormLabel>
                        <FormInput 
                            id="siren"
                            type="text"
                            name="siren"
                            placeholder="Siren"
                            value={(entity?.type === "supplier" ? entity?.taxNumber1 : entity?.siren) || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="vat" >N° TVA</FormLabel>
                        <FormInput 
                            id="vat"
                            type="text"
                            name="vat"
                            placeholder="N° TVA"
                            value={entity?.vat || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="telephone" >Téléphone</FormLabel>
                        <FormInput 
                            id="telephone"
                            type="text"
                            name="telephone"
                            placeholder="Téléphone"
                            value={entity?.telephone || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="address" >Adresse</FormLabel>
                        <FormInput 
                            id="address"
                            type="text"
                            name="address"
                            placeholder="Adresse"
                            value={entity?.address || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>    
                </EIFormCol>

                <EIFormCol>
                    <FormGroup>
                        <FormLabel htmlFor="addressComplement" >Adresse complément</FormLabel>
                        <FormInput 
                            id="addressComplement"
                            type="text"
                            name="addressComplement"
                            placeholder="Adresse complément"
                            value={entity?.addressComplement || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="zipCode" >Code postal</FormLabel>
                        <FormInput 
                            id="zipCode"
                            type="text"
                            name="zipCode"
                            placeholder="Code postal"
                            value={entity?.zipCode || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="city" >Ville</FormLabel>
                        <FormInput 
                            id="city"
                            type="text"
                            name="city"
                            placeholder="Ville"
                            value={entity?.city || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="countryCode" >Pays</FormLabel>
                        <FormSelect 
                            id="countryCode"
                            name="citcountryCodey"
                            value={entity?.countryCode || ""}
                            onChange={handleInputChange}
                            disabled={entityUid?true:false}
                        >
                            <option value="fr">FRANCE</option>
                        </FormSelect>
                    </FormGroup>
                    {
                        entity?.type !== "owner" && (
                            <>
                                <FormGroup>
                                    <FormLabel htmlFor="lang" >Langue</FormLabel>
                                    <FormSelect 
                                        id="lang"
                                        name="lang"
                                        value={entity?.lang || ""}
                                        onChange={handleInputChange}
                                        disabled={entityUid?true:false}
                                    >
                                        <option value="fr">Français</option>
                                        <option value="en">Anglais</option>
                                    </FormSelect>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="entityAdmin" >Contact administrateur</FormLabel>
                                    {
                                        entity?.id ? (
                                            <FormSelect 
                                                id="adminContact"
                                                name="adminContact"
                                                value={entity?.adminContact || ""}
                                                onChange={handleInputChange}
                                                disabled={entityUid?true:false}
                                            >
                                                {
                                                    entity?.admins && entity?.admins.map(admin => (
                                                        <option key={admin.id} value={admin.uid}>{admin.login}</option>
                                                    ))
                                                }
                                            </FormSelect>
                                        ) : (
                                            <FormInput 
                                                id="entityAdmin"
                                                type="text"
                                                name="entityAdmin"
                                                placeholder="Contact administrateur"
                                                value={entity?.entityAdmin || ""}
                                                onChange={handleInputChange}
                                            />
                                        )
                                    }
                                </FormGroup>
                            </>
                        )
                    }

                </EIFormCol>
            </EIFormContainer>
        </EntInfosContainer>
      </>
  );
}

export default Infos;
