import React from 'react'
import GroupIcon from '@mui/icons-material/Group';
import { TD, TR } from '../../../styles/Table.styled';
import { SupplierLink, SupplierUsersLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';
import { getEreportingTypeeWithKey,showColumn } from '../../../shared/utility';
import ArticleIcon from '@mui/icons-material/Article';
import {formatDate, getTimeDate} from '../../../shared/utility'
import { useNavigate } from 'react-router-dom';
function TableBody(props) {
    const {Data, formatDate, entityFilter,setEntitydetail,columns} = props;
    const navigate = useNavigate();
    const formatNumber = (number) => {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: 2 }).format(number).replaceAll('.',' ')
    }
    return (
        <>   

            {
                Data ? Data.map((row,index) => (
                    <TR key={row.id} onClick={() => navigate("/invoiceDetail/"+row.data.invoiceUid)}>
                        {/* <TD style={{display:  !showColumn(columns,"type") && 'none'}}>
                            {(row.type)}
                        </TD> */}
                        <TD style={{display:  !showColumn(columns,"statusDate") && 'none'}}>
                       
                        {formatDate(+row.creationDate) + " " + getTimeDate(+row.creationDate) }
                            {/* <div className="tableBody__companyName">
                                <SupplierLink 
                                    to={`/entity/${row.uid}`}
                                    state={{entityType: 'supplier'}}
                                >
                                    {row.name} 
                                </SupplierLink>
                            </div> */}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"supplierName")&& 'none'}}> 
                           {row?.data?.supplierName} 
                        </TD>
                        <TD style={{display:  !showColumn(columns,"invoiceNumber") && 'none', cursor: "default"}} onClick={e => e.stopPropagation()}>
                            {row.data?.number?row.data?.number:null}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"supplierCode") && 'none'}}>
                           {row.data?.supplierCode}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"clientName") && 'none'}}>
                           {row.data?.entityName}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"clientCode") && 'none'}}>
                           {row.data?.entityCode}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"status") && 'none'}}>
                           {row.data?.label}
                        </TD>
                     
                    </TR>
                )) : null
            }
        </>
    )
}

export default TableBody
