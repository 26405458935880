import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './FutureCalendar.css'
import Axios from '../../../axios-proas'
import { Calendar } from 'react-date-range';
import { fr } from 'date-fns/locale';
import { getNotyfObject } from '../../../shared/utility';

function FutureCalendar({tableRef}) {
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate();
    const notyf = getNotyfObject();

    useEffect(() => {
        Axios.get("/invoice//postdatedChart", {params: {period: "all"}}).then(response => {
            setData(response.data)
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
            console.error(res)
        })
    },[])

    const handleSelectDate = date => {
        setSelectedDate(date);
        navigate("/futureInvoices", { replace : false, state : {issuingDate: date.getTime() + ":" + date.getTime()} })
            if (tableRef)
                tableRef.current.scrollIntoView();
    }

    const formatDate = date => {
        const newDate = new Date(+date);
        const day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
        const month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return newDate.getFullYear() + "-" + month + "-" + day;
    }

    const renderDayHandler = date => {
        const counter = data?.find(d => d.invoiceMonth === formatDate(date))?.invoicesCount;
        return <span>{date.getDate()}{counter && <span className="invoiceCounter">{counter}</span>}</span>;
    }

    return (
        <div style={{width: "50%", margin: "auto", display: "inline-table"}}>
            <Calendar
                date={selectedDate}
                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                className="futureCalendar"
                locale={fr}
                color={"#56296f"}
                onChange={handleSelectDate}
                dayContentRenderer={renderDayHandler}
            />
        </div>
    )
}

export default FutureCalendar
