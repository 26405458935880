import * as actionTypes from './actionTypes';
import Axios from '../../axios-proas';

export const ncsuppliersStart = () => ({
    type: actionTypes.NCSUPPLIERS_START
})

export const ncsuppliersSetPage = (page) => ({
    type: actionTypes.NCSUPPLIERS_SET_PAGE,
    currentPage: page
})

export const ncsuppliersSetPageSize = (pageSize) => ({
    type: actionTypes.NCSUPPLIERS_SET_PAGESIZE,
    pageSize: pageSize
})

export const ncsuppliersSetSearchInput = (inputValue) => ({
    type: actionTypes.NCSUPPLIERS_SEARCH_INPUT,
    searchInput: inputValue
})



export const ncsuppliersSuccess = (data, count) => ({
    type: actionTypes.NCSUPPLIERS_SUCCESS,
    data: data,
    count: count
});

export const ncsuppliersFail = (error) => ({
    type: actionTypes.NCSUPPLIERS_FAIL,
    error: error
})



export const noConnectedSuppliersGetPage = (page, filters, sort, reverse, searchField, pageSize = 100) => {
    let newFilters = {
        page: page,
        pageSize
    }
    if(filters){ 
        for (const key in filters) {
            if(filters[key])
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    if(sort){
        newFilters = {
            ...newFilters,
            orderBy: sort
        }
    }
    if(searchField){
        newFilters = {
            ...newFilters,
            searchInput: searchField
        }
    }
    if(reverse){
        newFilters = {
            ...newFilters,
            reverse: reverse[sort]
        }
    }
    return dispatch => {
        dispatch(ncsuppliersStart());
        Axios.get('/entity//supplierNoconnected', {
            params: {...newFilters}
        })
        .then(response => {
            dispatch(ncsuppliersSuccess(response.data.result, response.data.count))
        }).catch(err => {
            dispatch(ncsuppliersFail(err.response?.data?.error))
        })
    }
}


export const nccustomersGetPage = (page, filters, sort, reverse, searchField) => {
    let newFilters = {
        page: page
    }
    if(filters){ 
        for (const key in filters) {
            if(filters[key])
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    if(sort){
        newFilters = {
            ...newFilters,
            orderBy: sort
        }
    }
    if(searchField){
        newFilters = {
            ...newFilters,
            searchInput: searchField
        }
    }
    if(reverse){
        newFilters = {
            ...newFilters,
            reverse: reverse[sort]
        }
    }
    return dispatch => {
        dispatch(ncsuppliersStart());
        Axios.get('/entity?type=client', {
            params: {...newFilters}
        })
        .then(response => {
            dispatch(ncsuppliersSuccess(response.data.result, response.data.count))
        }).catch(err => {
            dispatch(ncsuppliersFail(err.response?.data?.error))
        })
    }
}

export const ncsuppliersSetFilterQuery = (filtersQuery) => ({
    type: actionTypes.NCSUPPLIERS_SET_FILTERSQUERY,
    filters: filtersQuery,
    filtering: ncsuppliersUpdateFiltering(filtersQuery) 
});

export const ncsuppliersUpdateFiltering = (filtersQuery) => {
    let filter = false;
    if(filtersQuery){
        for(const key in filtersQuery){
            if(filtersQuery[key]){
                filter = true
            }
        }
    }
    return filter
}

export const ncsuppliersUpdateShowFilters = (show) => ({
    type: actionTypes.NCSUPPLIERS_UPDATE_SHOWFILTERS,
    showFilters: show
});

export const ncsuppliersSetSortQuery = (sortQuery) => ({
    type: actionTypes.NCSUPPLIERS_SET_SORTQUERY,
    sortQuery: sortQuery
})

export const ncsuppliersSetReverseSort = (reverse) => ({
    type: actionTypes.NCSUPPLIERS_SET_REVERSESORT,
    reverse: reverse
})