import React, { useCallback, useEffect, useState } from 'react';

import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import TableBody from '../../components/RejectTable/TableBody/TableBody';
import TableActions from '../../components/RejectTable/TableActions/TableActions';
import TableHead from '../../components/RejectTable/TableHead/TableHead';
import TableFilters from '../../components/RejectTable/TableFilters/TableFilters';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { NoContent, SearchInput, SearchInputContainer, SpinnerContainer, Table, TableAction, TBody } from '../../styles/Table.styled';
import { SIBody, SIContainer, SITableContainer, BtnErrContainer,DivTest,BtnHd } from './RejectTracking.styled';
import SelectPeriod from '../../components/UI/SelectPeriod/SelectPeriod';
import Rejets from '../RejectTracking/Rejets';
import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import InformationInvoice from '../../components/RejectTable/TableBody/InformationInvoice';

function Reject9xTracking(props) {
    let {
        userLevel,
        userType,
        token,
        loading,
        count,
        currentPage,  
        pageSize,
        rejectsData,
        setRejectPage,
        setRejectPageSize,
        getRejectsPage,
        setRejectFilters,
        filtersQuery,
        quickFilter,
        setRejectQuickFilter,
        filtering,
        getCompressedDoc,
        sortQuery,
        reverse,
        setSortQuery,
        setReverse,
        updateShowFilters,
        showFilters,
        selectedRows,
        setSelectedRows,
        selectedAllRows,
        setSelectedAllRows,
        userProfile,
        page,
		showOnly,
        search,
        invoiceSetSearch,
        invoice,
        statuses
    } = props;

    let location=useLocation();
    const [messagesToggleOn, setMessagesToggleOn] = useState(false);
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    const [tableProfile, setTableProfile] = useState(null);
       
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const [active, setActive] = useState(false);
    const [isNone, setIsNone] = useState(true);
    const [invoiceData, setInvoiceData] = useState(null);
    const [showInformationModal, setShowInformationModal] = useState(false);
    
    const navTitre = "rejectTracking";

    const { t } = useTranslation();

    const getFromYesterdayFilter = () => {
        const toDate = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate())).getTime() - 1;
        const fromDate = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 1)).getTime();
        return fromDate+":"+toDate;
    }

    const initialFiltersQuery = {
        visualisation: false,
        rejectType: location?.state?.errorFilter ? location?.state?.errorFilter : null,
        archibaldDate: location?.state?.fromYesterday ? getFromYesterdayFilter() : null,
        lastActionDate: location?.state?.lastActionDate ? location?.state?.lastActionDate : null,
        issuingDate: location?.state?.issuingDate ? location?.state?.issuingDate : null,
        processingStatus: location?.state?.processingStatus ? location?.state?.processingStatus : null,
        type: page,
        show : showOnly
    }

    useEffect(() => {
        if (initialFiltersQuery?.archibaldDate || initialFiltersQuery?.lastActionDate || initialFiltersQuery?.issuingDate || initialFiltersQuery?.processingStatus)
            handleReset();
    }, [initialFiltersQuery?.archibaldDate, initialFiltersQuery?.lastActionDate, initialFiltersQuery?.issuingDate, initialFiltersQuery?.processingStatus]);

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{profile:userProfile, navTitre:navTitre}})
            .then(response => {
                let tempColums = response?.data?.columns
                let columnsCopy = [
                    // { title: "Statut traitement", field: 'processingStatus', width: "100px", show: tempColums.processingStatus },
                    { title: "Type d'échec", field: 'rejectType', width: "80px", show: tempColums.rejectType },
                    { title: "Type d’échec d'origine", field: 'originalRejectType', width: "80px", show: tempColums.originalRejectType },
                    { title: "Date d'envoi AGSRM", field: 'archibaldDate', width: "100px", show: tempColums.archibaldDate },
                    { title: "Date dernière action", field: 'lastActionDate', width: "100px", show: tempColums.lastActionDate },
                    { title: "Action/évènement", field: 'lastActionAuthor', width: "80px", show: tempColums.lastActionAuthor },
                    { title: "Type de dépôt", field: 'channel', width: "80px", show: tempColums.channel },
                    { title: "Code SU", field: 'suCode', width: "90px", show: tempColums.suCode },
                    { title: "Nom entité", field: 'ownerName', width: "80px", show: tempColums.ownerName },
                    { title: "Code tiers", field: 'supplierCode', width: "100px", show: tempColums.supplierCode },
                    { title: "Nom fournisseur", field: 'supplierName', width: "100px", show: tempColums.supplierName },
                    { title: "N° de facture", field: 'number', width: "170px", show: tempColums.number },
                    { title: "Type", field: 'documentType', width: "70px", show: tempColums.documentType },
                    { title: "Montant TTC", field: 'inclusiveTaxAmount', width: "100px", show: tempColums.inclusiveTaxAmount },
                    { title: "Montant HT", field: 'exclusiveTaxAmount', width: "100px", show: tempColums.exclusiveTaxAmount },
                    { title: "N° de cde", field: 'orderNumber', width: "80px", show: tempColums.orderNumber },
                    { title: "Date facture", field: 'issuingDate', width: "80px", show: tempColums.issuingDate },
                    { title: "N° de compostage", field: 'compostingNumber', width: "100px", show: tempColums.compostingNumber },
                    { title: "Commentaires", field: 'unreadMessages', width: "60px", show: tempColums.unreadMessages },
                    { title: "Date d'échéance", field: 'dueDate', width: "60px", show: tempColums.dueDate },
                    { title: "Statut intégration IVM", field: 'ivmStatus', width: "60px", show: tempColums.ivmStatus },
                    { title: "Chrono-endossage", field: 'chronoCode', width: "60px", show: tempColums.chronoCode }
                ];
                setColumns(columnsCopy.filter(c => c))
                columnsCopy.filter(column => column && column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);
            })

    }, [loadColumns, location.pathname])

    useEffect(() => {
        if (tableProfile?.profileValue) {
            const parsedProfile = JSON.parse(tableProfile.profileValue);
            const columnsTemp = [...columns];
            columnsTemp.forEach(c => {
                c.show = parsedProfile?.columns[c.field];
            })
            setColumns(columnsTemp);
            columnsTemp.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);

            const filtersTemp = Object.assign({...initialFiltersQuery}, parsedProfile.filters);
            filtersChangeHandler(filtersTemp)
            handleApplyFiltering({
                ...filtersTemp,
                quickCreationDate: null,
                quickSearch: null
            })
            setRejectPage(1);
        } else if (tableProfile)
            handleReset();
    }, [tableProfile])

    useEffect(() => {
        if (!location?.state?.errorFilter) {
       
            //     console.log(location.pathname)
            //     let newFilters = {
            //         ...filtersQuery,
            //         quickSearch: search?.hasOwnProperty(location.pathname)?search[location.pathname]:null,
            //         ...initialFiltersQuery
            //     }
            //   //  updateShowFilters(false)
            //     setRejectFilters(newFilters)
            ///////////////////////////////////////////////////////////////////////

            let newFilters =null;
            if(filtersQuery?.hasOwnProperty(location.pathname)){
                newFilters = {
                    ...filtersQuery,
                    
                }
            }else{
                newFilters = {
                    ...filtersQuery,
                    [location.pathname]:{ 
                    quickSearch: search?.hasOwnProperty(location.pathname)?search[location.pathname]:null,
                    ...initialFiltersQuery
                }
                }
            }

            // updateShowFilters(false)
            setRejectFilters(newFilters)
            ///////////////////////////////////////////////////////////////////////
            getRejectsPage(currentPage, newFilters[location.pathname] , sortQuery, reverse, pageSize);
        }
    }, [getRejectsPage, pageSize, page])

const isEmptyFilters = () => {
    if (!filtersQuery?.hasOwnProperty(location.pathname))
        return true;
    let filtersList = columns.map(column => column.field)
    filtersList.push("documentType");
    let empty = true;
    filtersList.forEach(filter => {
        if (filtersQuery?.[location.pathname][filter])
            empty = false;
    })
    return Object.keys(filtersQuery?.[location.pathname]).length === 0 || empty;
}

useEffect(() => {
    const isEmpty = isEmptyFilters();
    if (showFilters?.hasOwnProperty(location.pathname)&&!showFilters[location.pathname]&& !isEmpty) {
        handleReset()
    }
}, [showFilters]);

    // const handleShowFilters = () => {
    //     updateShowFilters(!showFilters)
    // }

    const handleShowFilters = () => {
        let showFilter={
            ...showFilters,
            [location.pathname]:!showFilters[location.pathname]
        }
        updateShowFilters(showFilter)
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setRejectPage(newPage);
        getRejectsPage(newPage, filtersQuery?.[location.pathname], sortQuery, reverse, pageSize);
    }, [filtersQuery, reverse, sortQuery, pageSize]);

    const handleSort = useCallback((sort, newReverse) => {
        getRejectsPage(currentPage, filtersQuery?.[location.pathname], sort, newReverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const handleApplyFiltering = useCallback((filters) => {
        getRejectsPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const handleRefresh = useCallback(() => {
        getRejectsPage(currentPage, filtersQuery?.[location.pathname], sortQuery, reverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const messagesToggleClickHandler = () => {
        let newValue = !messagesToggleOn;
        setMessagesToggleOn(newValue);
        let filtersTemp = {
            ...initialFiltersQuery,
            unreadMessages: (newValue ? 'on' : 'off')
        }
        setRejectFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const handleReset = useCallback(() => {
     
        let newFilters = {
            ...initialFiltersQuery
        }
        let filterLocation={
            ...filtersQuery,
            [location.pathname]:newFilters,
        }
        setRejectQuickFilter(null)
        setSortQuery("id")
        setReverse({ id: false })
        setRejectFilters(filterLocation);
        setRejectPage(1);
        getRejectsPage(1, newFilters, "id", { id: false }, pageSize);
    }, [initialFiltersQuery])

    const filtersChangeHandler = (newFilters) => {
        const filtersTemp = {
            ...newFilters,
            quickCreationDate: null,
            quickSearch: null
        }
        let  filterLocation={
            ...filtersQuery,
            [location.pathname]:filtersTemp,
        }
        setRejectQuickFilter(null)
        setRejectFilters(filterLocation)
    }

    const quickFilterChangeHandler = (value) => {
        switch (value) {
            case 'thisYear':
                thisYearClickHandler()
                break;
            case 'thisMonth':
                thisMonthClickHandler()
                break;
            case 'lastMonth':
                lastMonthClickHandler()
                break;
            default:
                resetQuickFilter()
                break;
        }
    }

    const resetQuickFilter = () => {
        setRejectQuickFilter(null)
        setRejectFilters(initialFiltersQuery)
        handleApplyFiltering(initialFiltersQuery)
    }

    const thisMonthClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuery,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setRejectQuickFilter('thisMonth')
        setRejectFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const lastMonthClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuery,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setRejectQuickFilter('lastMonth')
        setRejectFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const thisYearClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), 0, 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear() + 1, 0, 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuery,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setRejectQuickFilter('thisYear')
        setRejectFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const quickDateIntervalChangeHandler = (date) => {
        let filtersTemp = {
            ...initialFiltersQuery,
            quickCreationDate: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        setRejectQuickFilter('quickDateInterval')
        setRejectFilters(filtersTemp)
        handleApplyFiltering(filtersTemp);
    }

    const quickSearchChange = (quickSearchValue) => {
     
        // let filtersTemp2 = {
        //     ...search,
        //     [location.pathname]:quickSearchValue,
        // }

        // let filtersTemp = {
        //     ...initialFiltersQuery,
        //     quickSearch: filtersTemp2[location.pathname]
        // }
        // invoiceSetSearch(filtersTemp2)
        // setRejectQuickFilter('quickSearch')
        // setRejectFilters(filtersTemp)
        // handleApplyFiltering(filtersTemp);

        /////////////////////////////////////////////////////////
        let filtersTemp2 = {
            ...search,
            [location.pathname]:quickSearchValue,
        }

        let filtersTemp = {
            ...initialFiltersQuery,
            quickSearch: filtersTemp2[location.pathname]
        }
        let filterLocation={
            ...filtersQuery,
            [location.pathname]:filtersTemp,
        }
        invoiceSetSearch(filtersTemp2)


        setRejectQuickFilter('quickSearch')
        setRejectFilters(filterLocation)
        if (quickSearchValue?.length >= 3)
            handleApplyFiltering(filterLocation[location.pathname]);
        else if (quickSearchValue?.length < 3 && search?.[location.pathname]?.length >= 3) {
            filterLocation[location.pathname].quickSearch = "";
            handleApplyFiltering(filterLocation[location.pathname]);
        }


    }


    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("invoiceList:noInvoices")}</span>
        </NoContent>
    );

    if (!loading && rejectsData?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (
            <TableBody
                columns={columns}
                data={rejectsData}
                formatDate={formatDate}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                userLevel={userLevel}
                userType={userType}
                userProfile={userProfile}
                isCodaRefused={page === "codaRefused"}
                refreshTable={handleRefresh} 
                page={page}
                setInvoiceData={setInvoiceData}
                setShowInformationModal={setShowInformationModal}
            />
        )
    }

    const BtnClickHandler = () => {
     setActive(!active)
     setIsNone(false)
    }

    return (
        <SIContainer>
            <TitleHelmet title={"Transdev | " + t("menu:invoices")} />
            <Header>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.hasOwnProperty(location.pathname)&& filtersQuery?.[location.pathname]?.quickSearch|| ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                     {filtersQuery?.hasOwnProperty(location.pathname)&& filtersQuery?.[location.pathname]?.quickSearch!==null? 
                                        <CloseIcon onClick={()=>quickSearchChange(null)}/>:
                                        <SearchIcon />}
                    </SearchInputContainer>
                    <TableActions
                         filterState={showFilters?.hasOwnProperty(location.pathname)&&showFilters[location.pathname]==true?true:false}
                        showFilters={handleShowFilters}
                        filtering={filtering}
                        sortQuery={sortQuery}
                        reverse={reverse}
                        filters={filtersQuery?.[location.pathname]}
                        resetData={handleReset}
                        formatDate={formatDate}
                        selectedRows={selectedRows}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={setSelectedAllRows}
                        userLevel={userLevel}
                        getCompressedDoc={getCompressedDoc}
                        userType={userType}
                        refreshTable={handleRefresh} 
                    />

                </HeaderActions>
            </Header>
  
            {/* <DivTest>
                <BtnErrContainer isActive={active}>
                    <BtnHd isActive={active} onClick={() => BtnClickHandler()} style={{cursor:"pointer"}}>
                        <FormatAlignJustifyOutlinedIcon style={{fontSize: "1.5rem", color:"#fff"}}/>
                 </BtnHd>
                <Rejets BtnClickHandler={BtnClickHandler} isActive={active} isNone={isNone}/>
                </BtnErrContainer>
            </DivTest> */}
            
            <SIBody>
            <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handleChangePage}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setRejectPageSize}
                        filters={filtersQuery?.[location.pathname]}
                        tableProfile={tableProfile}
                        setTableProfile={setTableProfile}
                        tableType="REJECT"
                    />
                <SITableContainer>
               
              
                    <Table>
                        <TableHead
                            columns={columns}
                            sortQuery={sortQuery}
                            reverse={reverse}
                            setSortQuery={setSortQuery}
                            setReverse={setReverse}
                            getData={handleSort}
                            selectedRows={selectedRows}
                            selectedAllRows={selectedAllRows}
                            setSelectedAllRows={setSelectedAllRows}
                            filters={filtersQuery}
                        />
                        <TBody height={density}>
                        {showFilters[location.pathname] && <TableFilters
                                columns={columns}
                                show={showFilters[location.pathname]}
                                formatDate={formatDate}
                                filters={filtersQuery?.[location.pathname]}
                                filtersChange={(filters) => filtersChangeHandler({...filters, quickCreationDate: null, quickSearch: null})}
                                applyFilters={handleApplyFiltering}
                                userLevel={userLevel}
                                userType={userType}
                                userProfile={userProfile}
                                isCodaRefused={page === "codaRefused"}
                            />}
                            {tBody}
                        </TBody>
                    </Table>
                  
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handleChangePage}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setRejectPageSize}
                    />
                     
                </SITableContainer>
            </SIBody>
            <InformationInvoice 
                show={showInformationModal}
                modalClosed={() => setShowInformationModal(false)}
                // invoiceData={invoiceData}
                rejectUid={invoiceData?.uid}
                userProfile={userProfile}
                refreshTable={handleRefresh}
                invoice={invoice}
                statuses={statuses}
            />
        </SIContainer>
    )
}
const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    loading: state.reject.loading,
    count: state.reject.count,
    currentPage: state.reject.currentPage,
    pageSize: state.reject.pageSize,
    filtersQuery: state.reject.filtersQuery,
    quickFilter: state.reject.quickFilter,
    filtering: state.reject.filtering,
    sortQuery: state.reject.sortQuery,
    reverse: state.reject.reverse,
    rejectsData: state.reject.data,
    showFilters: state.reject.showFilters,
    selectedRows: state.reject.selectedRows,
    selectedAllRows: state.reject.selectedAllRows,
    userLevel: state.auth.userLevel,
    userType: state.auth.userType,
    userProfile: state.auth.profile,
    search: state.invoice.search,
    invoice: state.invoiceDetail.invoiceData,
})
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getRejectsPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.rejectGetPage(page, filters, sort, reverse, pageSize)),
    setRejectPage: (page) => dispatch(actions.rejectSetPage(page)),
    setRejectPageSize: (pageSize) => dispatch(actions.rejectSetPageSize(pageSize)),
    setRejectFilters: (filters) => dispatch(actions.rejectSetFiltersQuery(filters)),
    setRejectQuickFilter: (quickFilterField) => dispatch(actions.rejectSetQuickFilter(quickFilterField)),
    setSortQuery: (sortQ) => dispatch(actions.rejectSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.rejectSetReverseSort(reverseS)),
    updateShowFilters: (show) => dispatch(actions.rejectUpdateShowFilters(show)),
    getDocumentFile: (uid) => dispatch(actions.rejectGetDocumentFile(uid)),
    setSelectedRows: (rowId, selceted) => dispatch(actions.rejectSelectRow(rowId, selceted)),
    setSelectedAllRows: (selectedAll) => dispatch(actions.rejectSelectAllRows(selectedAll)),
    invoiceSetSearch: (search) => dispatch(actions.invoiceSetSearch(search)),
    getCompressedDoc : (selection, filters) => dispatch(actions.invoiceGetCompressedDocument(selection, filters, true))
})
export default connect(mapStateToProps, mapDispatchToProps)(Reject9xTracking)

