import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const EMContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const EMBody = styled.div`
    width: 100%;
    height: 100%;
`

export const EMTableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
   height:calc(100vh - 234px);
`
