import styled from 'styled-components/macro';

export const SIContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const SIBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const SITableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
`
export const TableErrDiv = styled.div`
   margin:4px ;
   max-height: 60vh ;
   overflow-y:auto ;
`

export const TitleDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom:15px ;
`;

export const TableErrContainer = styled.ul`
    width: ${({width}) => width};
    background-color: #ffffff;
    padding: 10px 0;
    margin: 0;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    margin-right:7px ;
    height: fit-content;
`



export const BtnHd = styled.div`
width: 41px;
padding: 5px;

position:absolute ;
top:151px;
border-radius: 50px 0px 0px 50px ;
background-color: #ff0000;
& svg {
    margin:5px ;
}
`




export const BtnErrContainer = styled.div`
 color: red;
    display: flex;
     width: 40px;
     height: 40px;
    justify-content: end;
    position: relative;
    left: 39px;
    //slideOutAnimation
  
       
      
        & ${BtnHd}{
            display:${({isActive,theme}) => isActive & "none"};
           

        }

    
   
`

export const DivTest = styled.div`
        display: flex;
        width: 100%;
        justify-content: end;
        position: sticky;
        top: 130px;
  
   
`
export const Btn2Hd = styled.div`
     position:absolute; 
        top:131px;
        left:-36px;
        width: 35px;
        padding: 5px;
        background-color: #ff0000;
        border-radius: 50px 0px 0px 50px ;
& svg {
    margin:5px ;
}
        

   
`
// &:hover ~ ${RadioButtonLabel} {
//     background: #1E85D9;
//     border: 1px solid #2196F3;
//     &::after {
//         content: '';
//        display: flex;
//        width: 10px;
//       height: 10px;
//       margin: 3px;
//        -moz-border-radius: 7.5px;
//        -webkit-border-radius: 7.5px;
//        border-radius: 7.5px;
//        background-color: #fff;
//     }
//   }
export const TD = styled.td`
    padding: 0.25rem;

    & > span {
        display: block;
        width: 100%;
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`
export const TBody = styled.tbody`
    width: 100%;
    font-size: 0.9rem;
    color: ${({theme}) => theme.colors.text};

    tr {
        border-bottom: 0.0rem solid white;
    }
& TD:first-child {
   text-align:start ;
}
    & ${TD} {
        width: 100%;
        vertical-align: middle;
        height: ${({height}) => height ? height : '100%'};
        text-align: center;
    }
`;
