import styled from 'styled-components/macro';

export const CTContainer = styled.div`
    overflow: auto;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    background-color: ${({theme}) => theme.colors.body};
`;

export const CTBody = styled.div`
    /* width: 100%; */
    overflow: auto;
    margin: 2rem;
    padding: 3rem 2rem;
    background-color: ${({theme}) => theme.colors.content};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
    color: ${({theme}) => theme.colors.text};
`;