import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTranslation } from 'react-i18next';
import { TH, THContainer, THead } from '../../../../styles/Table.styled';

function TableHead(props) {
    const {columns, columnClickHandler, reverse, userRole} = props;
    const {t} = useTranslation();
    
    return (
        <THead>
            <tr>
                {columns.filter(row=>row.show==true).map(column => (
                    <TH key={column.field} 
                        scope="col"  
                        width= {column?.width} 
                        onClick={() => columnClickHandler(column.field, reverse)}>
                      <THContainer>
                           <span>
                                {column.title}
                            </span>
                            <div className="columnHead__sort">
                                <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] == true ? 'block' : 'none' }} />
                                <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] == false ? 'block' : 'none' }}/>
                                
                            </div>
                        </THContainer>     
                    </TH>
                ))}
                {userRole === "admin" &&
                 <TH scope="col" id="visualiser" style={{borderTop: "none"}} className="columnHead">
                        <THContainer>
                         <span>{t("clientList:visualisation")}</span>
                        </THContainer>
                </TH>}
            </tr>
            </THead>
    )
}

export default TableHead
