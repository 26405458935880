import React from 'react'
import { showColumn } from '../../../shared/utility'
import { TD, TR } from '../../../styles/Table.styled';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableBody(props) {
    const {nonEngagingData, columns, setShowDeleteModal, setShowNonEngagingModal, selectedRows, setSelectedRows} = props;
    const {t} = useTranslation();

    const isRespectingRules = row => {
        return +row.rule2 && 
        row.suCode && row.supplierCode && row.element1 && row.element2 && row.element3 && row.element4 && row.element4Percentage && // rule 3
        0 < +row.element4Percentage && +row.element4Percentage <= 100 && // rule 4
        +row.rule6;
    }

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }

    const getErrorReasons = row => {
        const errors = [];
        if (!+row.rule2)
            errors.push("La somme des pourcentages d'élément 4 doit être égale à 100 pour le triplet Code SU / Code tiers / Référence contrat");
        if(!row.suCode)
            errors.push("Le code SU est un champ obligatoire");
        if(!row.supplierCode)
            errors.push("Le code tiers est un champ obligatoire");
        if(!row.element1)
            errors.push("L'élément 1 est un champ obligatoire");
        if(!row.element2)
            errors.push("L'élément 2 est un champ obligatoire");
        if(!row.element3)
            errors.push("L'élément 3 est un champ obligatoire");
        if(!row.element4)
            errors.push("L'élément 4 est un champ obligatoire");
        if(!row.element4Percentage)
            errors.push("Le pourcentage d'élément 4 est un champ obligatoire");
        if (!(0 < +row.element4Percentage && +row.element4Percentage <= 100))
            errors.push("Le pourcentage d'élément 4 doit être compris entre 0 et 100");
        if (!+row.rule6)
            errors.push("Si un couple Code SU / Code tiers est géré avec au moins un contrat, alors chaque ligne doit être gérée avec un contrat. Sinon, aucune ligne de ce couple ne peut comporter de contrat");
        return errors;
    }
   
    return (
        <>
            {
                nonEngagingData ? nonEngagingData.map(row => (
                    <CTooltip key={row.id} title={isRespectingRules(row) ? "" : <ul>{getErrorReasons(row).map(r => <li key={r}>{r}</li>)}</ul>}>
                        <TR onClick={() => setShowNonEngagingModal(row)} style={!isRespectingRules(row) ? {backgroundColor : "orange"} : null}>
                            <TD>
                                <input type="checkbox" name={row?.id} onClick={e => e.stopPropagation()} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row?.id)}  className="form-check-input"  id="exampleCheck1" />
                            </TD>
                            <CTooltip title={row.clientName || ""}><TD style={{display: !showColumn(columns,"suCode") && 'none'}}>
                                {row.suCode}
                            </TD></CTooltip>
                            <CTooltip title={row.supplierName || ""}><TD style={{display: !showColumn(columns,"supplierCode") && 'none'}}>
                                {row.supplierCode}
                            </TD></CTooltip>
                            <TD style={{display: !showColumn(columns,"contractRef") && 'none'}}>
                                {row.contractRef}
                            </TD>
                            <TD style={{display: !showColumn(columns,"element1") && 'none'}}>
                                {row.element1}
                            </TD>
                            <TD style={{display: !showColumn(columns,"element2") && 'none'}}>
                                {row.element2}
                            </TD>
                            <TD style={{display: !showColumn(columns,"element3") && 'none'}}>
                                {row.element3}
                            </TD>
                            <TD style={{display: !showColumn(columns,"element4") && 'none'}}>
                                {row.element4}
                            </TD>
                            <TD style={{display: !showColumn(columns,"element4Percentage") && 'none'}}>
                                {row.element4Percentage}
                            </TD>
                            <TD style={{display: !showColumn(columns,"element5") && 'none'}}>
                                {row.element5}
                            </TD>
                            <TD style={{display: !showColumn(columns,"element6") && 'none'}}>
                                {row.element6}
                            </TD>
                            <TD>
                                <DeleteForeverIcon onClick={(e) => {e.stopPropagation();setShowDeleteModal(row.id)}} />
                            </TD>
                        </TR>
                    </CTooltip>
                )) : null
            }
        </>
    )
}

export default TableBody

