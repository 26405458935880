import React, { useState, useEffect, useRef } from 'react'
import Axios from '../../../../../axios-proas'
import { connect } from 'react-redux';
import { getNotyfObject, formatDate, getTimeDate } from "../../../../../shared/utility";
import { useTranslation } from 'react-i18next';
import { MCContainer, MCMessagesContainer, Message, MessageAvatar, MessageContent, MessageDate, MessagesContainer, MessageValue, MsgInput, MsgInputContainer, MsgSendBtn, MsgInputDiv } from './Exchanges.styled';
import SendIcon from '@mui/icons-material/Send';
import { Spinner } from 'react-bootstrap';
import { NoContent } from '../../../../../styles/Table.styled';
import './Exchanges.css'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear';
import ExchangesEvent from './ExchangesEvent/ExchangesEvent'
import { ETRadiosContainer } from '../../../../EmailsSettings/EmailTemplate/EmailTemplate.styled'
import { FormLabel, FormRadio, FormRadioWrapper, RadioButtonLabel, BtnOutlineAction } from '../../../../../styles/Common'
import { useTheme } from 'styled-components';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUploaderBtnNoHover from '../../../../UI/FileUploaderBtnNoHover/FileUploaderBtnNoHover';
import { CTooltip } from '../../../../UI/CTooltip/CTooltip';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import CustomConfirmModal from '../../../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal';
import EmailPreviewModal from '../../../../EmailsSettings/TableBody/EmailPreviewModal/EmailPreviewModal';
import { Coselect } from '../../../../../containers/Home/Home.styled';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

function Exchanges(props) {
    const { t } = useTranslation();
    const theme = useTheme()
    const uid = props.invoiceUid;
    const {
        userUid
    } = props
    const [exchange, setExchange] = useState({
        type: "",
        message: "",
        inOut: "in"
    })
    const [isVisible, setIsVisible] = useState(props.userType === "supplier");
    const messagesRef = useRef();
    const messageInputRef = useRef();
    const notyf = getNotyfObject();
    const [messagesLoading, setMessagesLoading] = useState(false)
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [showExchangeBlock, setShowExchangeBlock] = useState(false)
    const [eventReferences, setEventReferences] = useState({
        CALL_EXCHANGE_EVENT : {in: "CALL_EXCHANGE_EVENT", out:"CALL_OUT_EXCHANGE_EVENT"},
        MAIL_EXCHANGE_EVENT : {in: "MAIL_EXCHANGE_EVENT", out:"MAIL_OUT_EXCHANGE_EVENT"},
        OTHER_EXCHANGE_EVENT : {in: "OTHER_EXCHANGE_EVENT", out:"OTHER_OUT_EXCHANGE_EVENT"},
        CALL_OUT_EXCHANGE_EVENT : {in: "CALL_EXCHANGE_EVENT", out:"CALL_OUT_EXCHANGE_EVENT"},
        MAIL_OUT_EXCHANGE_EVENT : {in: "MAIL_EXCHANGE_EVENT", out:"MAIL_OUT_EXCHANGE_EVENT"},
        OTHER_OUT_EXCHANGE_EVENT : {in: "OTHER_EXCHANGE_EVENT", out:"OTHER_OUT_EXCHANGE_EVENT"}
    })
    const [showDeleteCommentConfirmModal, setShowDeleteCommentConfirmModal] = useState(false);
    const [rejectMailBlocks, setRejectMailBlocks] = useState([])
    const [transdevContacts, setTransdevContacts] = useState([])
    const [messageRecipients, setMessageRecipients] = useState([])
    const [mailToSend, setMailToSend] = useState(null);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showAddExchangeConfirmModal, setShowAddExchangeConfirmModal] = useState(false);
    const [addExchangeLoading, setAddExchangeLoading] = useState(false);
    
    const loadMessages = () => {
        setMessagesLoading(true)
        Axios.get("invoiceevent", {
            params: {
                invoice: uid,
                type: "USER_CHAT_EVENT",
                orderBy: "creationDate",
                reverse: 1
            }
        }).then(response => {
            setMessagesLoading(false)
            setMessages(response?.data?.events)
            messagesRef?.current?.scrollTo({
                top: messagesRef.current.scrollHeight,
                left: 0
            })
        }).catch(response => {
            setMessagesLoading(false)
            notyf.error(t("global:errorOccurred", "An error has occurred!"));
        })
    }

    useEffect(() => {

        if (uid) {
            loadMessages();
            //     // const interval = setInterval(() => {
            //     //     loadMessages();
            //     // }, 60000);
            //     // return () => clearInterval(interval);

        }
    }, [uid])

    useEffect(() => {
        if (messageInputRef?.current) {
            messageInputRef.current.style.height = 'auto';
            messageInputRef.current.style.height = messageInputRef.current.scrollHeight + 'px';
        }
    }, [message])

    useEffect(() => {
        Axios.get("settingsemail//rejectMailsToSendManually").then((response) => {
            setRejectMailBlocks(response.data.emails);
            setTransdevContacts(response.data.contacts);
       }).catch(() => {
          notyf.error("Une erreur s'est produite");
        });
    }, []);

    useEffect(() => {
        if (exchange?.type !== "MAIL_OUT_EXCHANGE_EVENT" && mailToSend)
            setMailToSend(null);
    }, [exchange]);

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const handleContactsChange = e => {
        setMailToSend({...mailToSend, contacts: e})
    }

    const handleMessageRecipientsChange = e => {
        setMessageRecipients(e);
    }

    const handleChangeReadStatus = (uid, read) => {
        Axios.put("invoiceevent/"+uid+"/read", read).then(response => {
            if (response.data.success) {
                const messagesTemp = [...messages];
                messagesTemp.find(m => m.uid == uid)[response.data.key] = read;
                setMessages(messagesTemp);
            }
        }).catch(err => {
            notyf.error("Erreur")
        })
    }

    const downloadClickHandler = (pdfFile,Name) => {
        //console.log(props);
        let pdfFileName=Name.split('/').pop()
    
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
            let byteCharacters = atob(pdfFile);
            let byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {type: 'application/pdf'});
            window.navigator.msSaveOrOpenBlob(blob, pdfFileName || "mandat.pdf");
        }
        else { // much easier if not IE
            //________Direct Download_____
            let blob = new Blob([pdfFile], { type: 'application/pdf' })
            const downloadUrl = URL.createObjectURL(blob)
            let a = document.createElement("a"); 
            a.href = "data:application/pdf;base64," + pdfFile;
            a.download = pdfFileName || "mandat.pdf";
            document.body.appendChild(a);
            a.click();

            //__________Visualize In The Browser _____
            // const blob = dataURItoBlob(data);
            // const url = URL.createObjectURL(blob);

            // // to open the PDF in a new window
            // window.open(url, '_blank');
        }
    }
    const exchangeTypeHandler = (event) => {
        let exchangeType = event?.target?.value
        setExchange({ ...exchange, type: exchangeType })
    }

    const exchangeMessageHandler = (event) => {
        let echangeMessage = event?.target?.value
        setExchange({ ...exchange, message: echangeMessage })
    }

    const addExchangeEvent = () => {
        return new Promise((resolve, reject) => {
            setAddExchangeLoading(true);
            Axios.post('/invoiceevent',
                {
                    invoiceUid: uid,
                    message: exchange?.message,
                    type: exchange?.type,
                    mail: mailToSend,
                    isVisible: true,
                }).then(response => {
                    loadMessages()
                    setExchange({...exchange, 
                        type:"", 
                        message:"",
                        inOut: "in"
                    })
                    setShowExchangeBlock(!showExchangeBlock)
                    resolve(response)
                }).catch(err => {
                    notyf.error("Une erreur s'est produite")
                    reject(err);
                }).finally(() => {
                    setAddExchangeLoading(false);
                })
        })
    }

    const eventType = (e) => {
        let inOut = e?.target?.value

        setExchange({ ...exchange, 
            type: eventReferences[exchange?.type][inOut],
            inOut: inOut 
        })
    }

    const generateAvatar = (data) => {
        if (data && data?.first_name && data?.last_name) {
            return data?.first_name.charAt(0).toUpperCase() + data?.last_name.charAt(0).toUpperCase()
        } else if (data && data?.login) {
            return data?.login.charAt(0).toUpperCase()
        } else {
            return "?";
        }
    }

    const generateUserName = (data) => {
        if (data && data?.first_name && data?.last_name) {
            return data?.first_name + " " + data?.last_name
        } else if (data && data?.login) {
            return data?.login
        } else
            return t("invoiceList:unknown", "Unknown");
    }

    const changeMailBlockHandler = (event) => {
        const mail = event?.target?.value;
        setMailToSend(rejectMailBlocks.find(m => mail === m.id));
    }

const postMessage = () => {
    if(((message && message.trim()!='') || (file)) && !sendingMessage) {
        setSendingMessage(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('invoiceUid', uid);
        formData.append('message', message);
        formData.append('type', "USER_CHAT_EVENT");
        formData.append('isVisible', isVisible);
        formData.append('messageRecipients', JSON.stringify(messageRecipients));
     
        Axios.post("/invoiceevent", formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(response => { 
            loadMessages();
            setMessage("");
            setFile(null);
            setFileName(null);
            setUrl('');
            setMessageRecipients([]);
        }).catch(err => {
           
        }).finally(() => {
            setSendingMessage(false);
        })
    }
}
            



    // const postMessage = () => {
    //     if (message)
    //         Axios.post("invoiceevent", {
    //             invoiceUid: uid,
    //             message: message,
    //             type: "USER_CHAT_EVENT"
    //         }).then(response => {
    //             loadMessages();
    //             setMessage("");
    //         })
    // }

    const messagesScrollUp = () => {

        messagesRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })

    }

    const [file, setFile] = useState("")
    const [fileName, setFileName] = useState(null)
    const [url, setUrl] = useState('');
    const addfile = e => {
        if (e?.target?.files && e?.target?.files[0] && e?.target?.name === "file"){
            setUrl(URL.createObjectURL(e.target.files[0]))
            setFile(e.target.files[0])
            setFileName(e.target.files[0].name);
        }
    }
    const removeFile = e => {
        setFile(null);
        setFileName(null);
        setUrl('');
    }

    const handleSetVisible = (e) => {
        setIsVisible(e?.target?.checked)
    }

    const deleteMessage = () => {
        return new Promise((resolve, reject) => {
            if (showDeleteCommentConfirmModal)
                Axios.delete(`/invoiceevent/${showDeleteCommentConfirmModal}/deleteComment`).then(response => {
                    if (response.data.success) {
                        notyf.success("Message supprimé avec succès");
                        loadMessages();
                        resolve(true);
                    } else {
                        notyf.error("Erreur lors de la suppression du message");
                        reject(false);
                    }
                }).catch(() => {
                    notyf.error("Échec de la suppression du message");
                    reject(false);
                })
            else
                reject("No uid given")
        })
    }

    const showTypeNewMessage = () => {
        if(props.userProfile !== "REJECT_MANAGER" && (props.invoiceData?.rejectTypes === "err51" || props.invoiceData?.permanentReject === "1"))
            return false
        return true
    }
      

    return (
        <MCContainer>
            {props.userProfile !== 'ADMIN' &&<>
             <div style={{ display: 'flex' }}>
            
                { showTypeNewMessage() ? 
                    <MsgInputContainer onSubmit={e => {e.preventDefault(); postMessage()}}>
                    <MsgInputDiv>
                        <MsgInput
                            type="text"
                            placeholder={t('invoiceDetails:typeYourMessage', "Type your message")}
                            value={message}
                            onChange={handleMessageChange}
                            ref={messageInputRef}
                            maxLength="2048"
                            disabled={sendingMessage}
                        />
                        <div onClick={() => addfile()}>
                    
                        <FileUploaderBtnNoHover
                            border={false}
                            icon={<AttachFileIcon/>}
                            handleChange={addfile}
                            name="file"
                            isDisabled={sendingMessage}
                        />
                        
                        </div>
                        {sendingMessage ? <CTooltip title={t("invoiceDetails:pleaseWait", "Please wait while your message is being sent...")}>
                                <Spinner animation="border" role="status"style={{color:"#EE5A5A66"}}/>
                            </CTooltip> : 
                            <CTooltip title={t("invoiceDetails:clickToSend", "Click to send your message")}><MsgSendBtn onClick={() => postMessage()}>
                                <SendIcon />
                            </MsgSendBtn></CTooltip>}
                    </MsgInputDiv>
                    {props.userType!='supplier' && <><MsgInputDiv>
                        <FormLabel style={{fontSize: "small"}}>Visible aux fournisseurs :</FormLabel>
                        <FormCheckInput
                            onChange={handleSetVisible}
                            checked={isVisible}
                        />
                    </MsgInputDiv>
                    <Coselect styles={{control: baseStyles => ({...baseStyles, marginBottom: "12px"})}}
                                        placeholder={"-- Choisir un ou des contacts destinataires Transdev (optionnel) --"}
                                        options={transdevContacts?.map(c => ({value: c.login, label: generateUserName(c)}))} 
                                        value={messageRecipients || []} 
                                        isMulti={true}
                                        noOptionsMessage={() => "Aucun résultat"}
                                        onChange={(e)=> handleMessageRecipientsChange(e)}></Coselect></>}
                    {isVisible && props.userType === "owner" && <MsgInputDiv style={{display: "block"}}>
                        <FormLabel style={{fontSize: "small", fontStyle: "italic"}}>{"Fournisseurs notifiés :" + (props.contacts?.length > 0 ? "" : " aucun")}</FormLabel>
                        <ul>
                            {props.contacts?.map(c => (<li key={c.login} style={{fontSize: "small", fontStyle: "italic"}}>{generateUserName(c)}</li>))}
                        </ul>
                    </MsgInputDiv>}
                    </MsgInputContainer> 
                  : null
                }
                {
                    props.userType === 'owner' && (props.userProfile === 'REJECT_MANAGER') &&
                    (!showExchangeBlock ?
                        <AddIcon className="addEvent" onClick={e => setShowExchangeBlock(!showExchangeBlock)} />
                        : <ClearIcon className="addEvent" onClick={e => setShowExchangeBlock(!showExchangeBlock)} />)
                }
            </div>
            </>}
            {
                fileName ?
                (
                    <div className="fileUploaded"><ClearIcon style={{ "cursor" : "pointer", color: "red"}} onClick={e => removeFile()}/> { fileName }</div>
                )
                :
                null
            }
            {
                showExchangeBlock &&
                <div className={'exchangesBlock'}>
                    <div className="form-group" style={{ marginBottom: "5px" }}>
                        <select className="form-select" onChange={e => exchangeTypeHandler(e)}>
                            <option key="0" value="">--- Sélectionner un événement --- </option>
                            <option key="1" value="CALL_EXCHANGE_EVENT">Appel téléphonique</option>
                            <option key="2" value="MAIL_EXCHANGE_EVENT">Email</option>
                            <option key="3" value="OTHER_EXCHANGE_EVENT">Autre</option>
                        </select>
                    </div>
                    {
                        exchange?.type != "" ?
                            <>
                                <div style={{ margin: "10px 0px" }}>
                                    <ETRadiosContainer>
                                        <FormRadioWrapper>
                                            <FormRadio
                                                type="radio"
                                                style={{ marginRight: "10px" }}
                                                value="in"
                                                name="headerImagePosition"
                                                onChange={e => eventType(e)}
                                                checked={exchange?.inOut == "in"}
                                            />
                                            <RadioButtonLabel />
                                            <FormLabel htmlFor="left">{t("template:Gauch", "Entrant(e)")}</FormLabel>
                                        </FormRadioWrapper>
                                        <FormRadioWrapper>
                                            <FormRadio
                                                type="radio"
                                                style={{ marginRight: "10px" }}
                                                value="out"
                                                name="headerImagePosition"
                                                onChange={e => eventType(e)}
                                                checked={exchange?.inOut == "out"}
                                            />
                                            <RadioButtonLabel />
                                            <FormLabel htmlFor="center"> {t("template:Sortant(e)", 'Sortant(e)')}</FormLabel>
                                        </FormRadioWrapper>
                                    </ETRadiosContainer>

                                </div>
                                <div className="form-group" style={{ marginBottom: "16px" }}>
                                    {/* <input type="text" className="form-control" placeholder="Commentaire" onChange={e => exchangeMessageHandler(e)} /> */}
                                        <textarea className="form-control" rows="3" maxLength={1000} placeholder="Commentaire" onChange={e => exchangeMessageHandler(e)}/>
                                </div>
                                {exchange?.type === "MAIL_OUT_EXCHANGE_EVENT" && rejectMailBlocks?.filter(b => +b.manualSending && b.event?.toLowerCase()?.includes(props.invoiceData?.rejectTypes)).length > 0 && <div className="form-group" style={{ marginBottom: "16px" }}>
                                    <CTooltip title={mailToSend?.fr_subject || ""}><select className="form-select" style={{ marginBottom: "16px" }} onChange={e => changeMailBlockHandler(e)}>
                                        <option value="">--- Sélectionner un mail --- </option>
                                        {rejectMailBlocks?.filter(b => +b.manualSending && b.event?.toLowerCase()?.includes(props.invoiceData?.rejectTypes))?.map(b => <option key={b.id} value={b.id}>{b.event} - {b.fr_subject}</option>)}
                                    </select></CTooltip>
                                    {mailToSend && (!+mailToSend?.toSupplier ? <Coselect
                                        placeholder={"-- Choisir un ou des contacts Transdev --"}
                                        options={transdevContacts?.map(c => ({value: c.login, label: generateUserName(c)}))} 
                                        value={mailToSend?.contacts || []} 
                                        isMulti={true}
                                        noOptionsMessage={() => "Aucun résultat"}
                                        onChange={(e)=> handleContactsChange(e)}></Coselect> :
                                        <MsgInputDiv style={{display: "block"}}>
                                            <FormLabel style={{fontSize: "small", fontStyle: "italic"}}>{"Fournisseurs notifiés :" + (props.contacts?.length > 0 ? "" : " aucun")}</FormLabel>
                                            <ul>
                                                {props.contacts?.map(c => (<li key={c.login} style={{fontSize: "small", fontStyle: "italic"}}>{generateUserName(c)}</li>))}
                                            </ul>
                                        </MsgInputDiv>
                                    )}
                                </div>}
                                {mailToSend && <BtnOutlineAction style={{float: "left", marginLeft: 0}}
                                        color={theme.colors.danger}
                                        border={theme.colors.danger}
                                        onClick={() => setShowPreviewModal(true)}
                                    >Prévisualiser mail
                                </BtnOutlineAction>}
                                <BtnOutlineAction style={{float: "right"}}
                                    color={theme.colors.danger}
                                    border={theme.colors.danger}
                                    onClick={() => {if (mailToSend) setShowAddExchangeConfirmModal(true); else addExchangeEvent()}}
                                    disabled={exchange?.message == "" || (rejectMailBlocks?.filter(b => b.event?.toLowerCase()?.includes(props.invoiceData?.rejectTypes)).length > 0 && exchange?.type === "MAIL_OUT_EXCHANGE_EVENT" && mailToSend && exchange?.type === "MAIL_OUT_EXCHANGE_EVENT" && !+mailToSend?.toSupplier && (!mailToSend?.contacts || mailToSend?.contacts?.length === 0))}
                                >
                                    Valider
                                </BtnOutlineAction>
                                {/* <div style={{ width: "100%" }}>
                                    <button type="button" class="btn btn-outline-info" disabled={exchange?.message == ""} style={{ float: "right" }} onClick={addExchangeEvent}>Ajouter</button>
                                </div> */}
                            </> : null
                    }

                </div>

            }


            <MCMessagesContainer ref={messagesRef} style={{ marginTop: "30px",height: "500px" }}>
                {

                    <>
                        {
                            messages.length === 0 ? (
                                <NoContent>
                                    <p>{t('invoiceDetails:noMessages', "No message to display")} </p>
                                </NoContent>
                            ) : null
                        }
                        {/* <ScrollToUpBtn>
                            <ArrowUpwardIcon onClick={messagesScrollUp} />
                        </ScrollToUpBtn> */}
                        <MessagesContainer >
                            {
                                messages?.map((m, index) => (
                                    "USER_CHAT_EVENT" == m?.type ?  
                                        <Message
                                            key={m.id}
                                            isMine={userUid === m?.user?.userUid}
                                        >
                                            {
                                                userUid !== m?.user?.userUid && (
                                                    <MessageAvatar>
                                                        {generateAvatar(m?.user)}
                                                    </MessageAvatar>
                                                )
                                            }
                                            {/*userUid === m?.user?.userUid && (
                                                <div>
                                                    <CloseIcon style={{ "cursor" : "pointer",color:"red" }} onClick={() => setShowDeleteCommentConfirmModal(m?.uid)} />  
                                                </div>
                                            )*/}
                                            <MessageContent isMine={userUid === m?.user?.userUid} style={{backgroundColor: +m?.visible ? null : "#fff8e6", maxWidth: props.userType == "owner" && m.type == "USER_CHAT_EVENT" ? "calc(100% - 74px - 1rem)" : null}}>
                                                <MessageValue>
                                                    {m?.message}
                                                    {m?.pdfFile ? 
                                                        (<>
                                                            {  m?.message && m?.message !='' ? <br /> : null }
                                                            <FileOpenIcon style={{ "cursor" : "pointer" }} onClick={() => downloadClickHandler(m?.pdfFile, m?.docFile)} /> 
                                                             <span style={{ "cursor" : "pointer" }} onClick={() => downloadClickHandler(m?.pdfFile, m?.docFile)}>{m.docFile.replace(/^.*[\\\/]/, '')}</span>
                                                        </>)
                                                        :
                                                        null
                                                    }
                                                
                                                </MessageValue>
                                                <MessageDate isMine={userUid === m?.user?.userUid} >
                                                    {generateUserName(m?.user) + " - " + formatDate(parseInt(m?.creationDate)) } {t("global:at", "at")} {getTimeDate(+m?.creationDate)} {+m?.visible ? "" : " (interne)"}
                                                </MessageDate>
                                           
                                            </MessageContent>
                                            {props.userType == "owner" && m.type == "USER_CHAT_EVENT" && 
                                                (m?.user?.type == "owner" && (m?.read == null || +m.read) || m?.user?.type != "owner" && +m.readByReceiver ? 
                                                    <CTooltip title="Cliquez pour marquer comme non lu"><MarkunreadMailboxOutlinedIcon style={{color: "red", cursor: "pointer"}} onClick={() => handleChangeReadStatus(m.uid, 0)} /></CTooltip> : 
                                                    <CTooltip title="Cliquez pour marquer comme lu"><MarkunreadMailboxIcon style={{color: "red", cursor: "pointer"}} onClick={() => handleChangeReadStatus(m.uid, 1)} /></CTooltip>)
                                            }
                                         
                                        </Message>
                                        : <ExchangesEvent
                                            key={m.id}
                                            eventData={m}
                                            deleteMessage={setShowDeleteCommentConfirmModal}
                                            isLast={index === 0} 
                                            userUid={userUid}/>


                                ))
                            }
                        </MessagesContainer>
                    </>
                }
            </MCMessagesContainer>
            <CustomConfirmModal 
                show={showDeleteCommentConfirmModal}
                handleClose={() => setShowDeleteCommentConfirmModal(false)}
                text={`Êtes-vous sûr de vouloir supprimer ce commentaire ? Cette action est irréversible.`}
                okCallback={deleteMessage}/>
            <EmailPreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(false)}
                convertedContent={mailToSend?.fr_value}
                event={null}
            />
            <CustomConfirmModal 
                show={showAddExchangeConfirmModal}
                handleClose={() => setShowAddExchangeConfirmModal(false)}
                text={`Êtes-vous sûr de vouloir valider cet échange ? Le mail sélectionné sera envoyé aux contacts `+(+mailToSend?.toSupplier ? `actifs du fournisseur` : `Transdev sélectionnés`)+`, avec le PDF de la facture en pièce jointe.`}
                loading={addExchangeLoading}
                okCallback={addExchangeEvent}/>
        </MCContainer>
    )
}

const mapStateToProps = (state) => ({
    invoiceData: state.invoiceDetail.invoiceData,
    userUid: state.auth.userUid
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(Exchanges)
