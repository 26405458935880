//____________APP Actions____________
export const APP_SET_THEME = 'APP_SET_THEME';

//_______Authentication Actions_______
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const AUTH_CHANGE_PROFILE = 'AUTH_CHANGE_PROFILE';
export const AUTH_SET_TIMEOUT = 'AUTH_SET_TIMEOUT';


//________Invoice Actions__________
export const INVOICE_START = 'INVOICE_START';
export const INVOICE_SUCCESS = 'INVOICE_SUCCESS';
export const INVOICE_FAIL = 'INVOICE_FAIL';
export const INVOICE_GET_ALL = 'INVOICE_GET_ALL';
export const INVOICE_SET_PAGE = 'INVOICE_SET_PAGE';
export const INVOICE_SET_PAGESIZE = 'INVOICE_SET_PAGESIZE';
export const INVOICE_SET_FILTERSQUERY = 'INVOICE_SET_FILTERSQUERY';
export const INVOICE_SET_QUICKFILTER = 'INVOICE_SET_QUICKFILTER';
export const INVOICE_UPDATE_FILTERING = 'INVOICE_UPDATE_FILTERING';
export const INVOICE_SET_SORTQUERY = 'INVOICE_SET_SORTQUERY';
export const INVOICE_SET_REVERSESORT = 'INVOICE_SET_REVERSESORT';
export const INVOICE_UPDATE_SHOWFILTERS = 'INVOICE_UPDATE_SHOW_FILTERS';
export const INVOICE_GET_DOCUMENT_FILE = 'INVOICE_GET_DOCUMENT_FILE';
export const INVOICE_SELECT_ROW = 'INVOICE_SELECT_ROW';
export const INVOICE_SELECT_ALL_ROWS = 'INVOICE_SELECT_ALL_ROWS';
export const INVOICE_GET_COMPRESSED_DOCUMENT = 'INVOICE_GET_COMPRESSED_DOCUMENT';
export const INVOICE_SET_IMPORT_ERRORS_COUNT = 'INVOICE_SET_IMPORT_ERRORS_COUNT';
export const INVOICE_SET_SEARCH='INVOICE_SET_SEARCH';
export const INVOICE_SET_COUNTERS='INVOICE_SET_COUNTERS';
export const INVOICE_SET_UNREAD_SUCCESS='INVOICE_SET_UNREAD_SUCCESS';
//----------------invoice home client--------
export const INVOICECLIENT_START = 'INVOICECLIENT_START';
export const INVOICECLIENT_SUCCESS = 'INVOICECLIENT_SUCCESS';
export const INVOICECLIENT_FAIL = 'INVOICECLIENT_FAIL';
export const INVOICECLIENT_GET_ALL = 'INVOICECLIENT_GET_ALL';
export const INVOICECLIENT_SET_PAGE = 'INVOICECLIENT_SET_PAGE';
export const INVOICECLIENT_SET_PAGESIZE = 'INVOICECLIENT_SET_PAGESIZE';
export const INVOICECLIENT_SET_FILTERSQUERY = 'INVOICECLIENT_SET_FILTERSQUERY';
export const INVOICECLIENT_SET_QUICKFILTER = 'INVOICECLIENT_SET_QUICKFILTER';
export const INVOICECLIENT_UPDATE_FILTERING = 'INVOICECLIENT_UPDATE_FILTERING';
export const INVOICECLIENT_SET_SORTQUERY = 'INVOICECLIENT_SET_SORTQUERY';
export const INVOICECLIENT_SET_REVERSESORT = 'INVOICECLIENT_SET_REVERSESORT';
export const INVOICECLIENT_UPDATE_SHOWFILTERS = 'INVOICECLIENT_UPDATE_SHOW_FILTERS';
export const INVOICECLIENT_GET_DOCUMENT_FILE = 'INVOICECLIENT_GET_DOCUMENT_FILE';
export const INVOICECLIENT_SELECT_ROW = 'INVOICECLIENT_SELECT_ROW';
export const INVOICECLIENT_SELECT_ALL_ROWS = 'INVOICECLIENT_SELECT_ALL_ROWS';
export const INVOICECLIENT_GET_COMPRESSED_DOCUMENT = 'INVOICECLIENT_GET_COMPRESSED_DOCUMENT';
export const INVOICECLIENT_SET_IMPORT_ERRORS_COUNT = 'INVOICECLIENT_SET_IMPORT_ERRORS_COUNT';
//____________Clients Actions_________
export const CLIENTS_START = 'CLIENTS_START';
export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS';
export const CLIENTS_FAIL = 'CLIENTS_FAIL';
export const CLIENTS_GET_ALL = 'CLIENTS_GET_ALL';
export const CLIENTS_SET_PAGE = 'CLIENTS_SET_PAGE';
export const CLIENTS_SET_PAGESIZE = 'CLIENTS_SET_PAGESIZE';
export const CLIENTS_SET_FILTERSQUERY = 'CLIENTS_SET_FILTERSQUERY';
export const CLIENTS_UPDATE_FILTERING = 'CLIENTS_UPDATE_FILTERING';
export const CLIENTS_SET_SORTQUERY = 'CLIENTS_SET_SORTQUERY';
export const CLIENTS_SET_REVERSESORT = 'CLIENTS_SET_REVERSESORT';
export const CLIENTS_UPDATE_SHOWFILTERS = 'CLIENTS_UPDATE_SHOW_FILTERS';

//____________Documentation Actions_________
export const DOCUMENTATION_START = 'DOCUMENTATION_START';
export const DOCUMENTATION_SUCCESS = 'DOCUMENTATION_SUCCESS';
export const DOCUMENTATION_FAIL = 'DOCUMENTATION_FAIL';
export const DOCUMENTATION_GET_ALL = 'DOCUMENTATION_GET_ALL';
export const DOCUMENTATION_SET_PAGE = 'DOCUMENTATION_SET_PAGE';
export const DOCUMENTATION_SET_PAGESIZE = 'DOCUMENTATION_SET_PAGESIZE';
export const DOCUMENTATION_SET_FILTERSQUERY = 'DOCUMENTATION_SET_FILTERSQUERY';
export const DOCUMENTATION_UPDATE_FILTERING = 'DOCUMENTATION_UPDATE_FILTERING';
export const DOCUMENTATION_SET_SORTQUERY = 'DOCUMENTATION_SET_SORTQUERY';
export const DOCUMENTATION_SET_REVERSESORT = 'DOCUMENTATION_SET_REVERSESORT';
export const DOCUMENTATION_UPDATE_SHOWFILTERS = 'DOCUMENTATION_UPDATE_SHOWFILTERS';
export const DOCUMENTATION_SET_QUICKFILTER = 'DOCUMENTATION_SET_QUICKFILTER';

//____________Entities Actions_________
export const ENTITIES_START = 'ENTITIES_START';
export const ENTITIES_SUCCESS = 'ENTITIES_SUCCESS';
export const ENTITIES_FAIL = 'ENTITIES_FAIL';
export const ENTITIES_GET_ALL = 'ENTITIES_GET_ALL';
export const ENTITIES_SET_PAGE = 'ENTITIES_SET_PAGE';
export const ENTITIES_SET_PAGESIZE = 'ENTITIES_SET_PAGESIZE';
export const ENTITIES_SET_FILTERSQUERY = 'ENTITIES_SET_FILTERSQUERY';
export const ENTITIES_UPDATE_FILTERING = 'ENTITIES_UPDATE_FILTERING';
export const ENTITIES_SET_SORTQUERY = 'ENTITIES_SET_SORTQUERY';
export const ENTITIES_SET_REVERSESORT = 'ENTITIES_SET_REVERSESORT';
export const ENTITIES_UPDATE_SHOWFILTERS = 'ENTITIES_UPDATE_SHOWFILTERS';
export const ENTITIES_SET_QUICKFILTER = 'ENTITIES_SET_QUICKFILTER';
//____________Regroupement Actions_________
export const REGROUPEMENT_START = 'REGROUPEMENT_START';
export const REGROUPEMENT_SUCCESS = 'REGROUPEMENT_SUCCESS';
export const REGROUPEMENT_FAIL = 'REGROUPEMENT_FAIL';
export const REGROUPEMENT_GET_ALL = 'REGROUPEMENT_GET_ALL';
export const REGROUPEMENT_SET_PAGE = 'REGROUPEMENT_SET_PAGE';
export const REGROUPEMENT_SET_PAGESIZE = 'REGROUPEMENT_SET_PAGESIZE';
export const REGROUPEMENT_SET_FILTERSQUERY = 'REGROUPEMENT_SET_FILTERSQUERY';
export const REGROUPEMENT_UPDATE_FILTERING = 'REGROUPEMENT_UPDATE_FILTERING';
export const REGROUPEMENT_SET_SORTQUERY = 'REGROUPEMENT_SET_SORTQUERY';
export const REGROUPEMENT_SET_REVERSESORT = 'REGROUPEMENT_SET_REVERSESORT';
export const REGROUPEMENT_UPDATE_SHOWFILTERS = 'REGROUPEMENT_UPDATE_SHOWFILTERS';

//____________Campaigns Actions_________
export const CAMPAIGNS_START = 'CAMPAIGNS_START';
export const CAMPAIGNS_SUCCESS = 'CAMPAIGNS_SUCCESS';
export const CAMPAIGNS_FAIL = 'CAMPAIGNS_FAIL';
export const CAMPAIGNS_GET_ALL = 'CAMPAIGNS_GET_ALL';
export const CAMPAIGNS_SET_PAGE = 'CAMPAIGNS_SET_PAGE';
export const CAMPAIGNS_SET_PAGESIZE = 'CAMPAIGNS_SET_PAGESIZE';
export const CAMPAIGNS_SET_FILTERSQUERY = 'CAMPAIGNS_SET_FILTERSQUERY';
export const CAMPAIGNS_UPDATE_FILTERING = 'CAMPAIGNS_UPDATE_FILTERING';
export const CAMPAIGNS_SET_SORTQUERY = 'CAMPAIGNS_SET_SORTQUERY';
export const CAMPAIGNS_SET_REVERSESORT = 'CAMPAIGNS_SET_REVERSESORT';
export const CAMPAIGNS_UPDATE_SHOWFILTERS = 'CAMPAIGNS_UPDATE_SHOWFILTERS';

//____________CampaignLogs Actions_________
export const CAMPAIGN_LOGS_START = 'CAMPAIGN_LOGS_START';
export const CAMPAIGN_LOGS_SUCCESS = 'CAMPAIGN_LOGS_SUCCESS';
export const CAMPAIGN_LOGS_FAIL = 'CAMPAIGN_LOGS_FAIL';
export const CAMPAIGN_LOGS_GET_ALL = 'CAMPAIGN_LOGS_GET_ALL';
export const CAMPAIGN_LOGS_SET_PAGE = 'CAMPAIGN_LOGS_SET_PAGE';
export const CAMPAIGN_LOGS_SET_PAGESIZE = 'CAMPAIGN_LOGS_SET_PAGESIZE';
export const CAMPAIGN_LOGS_SET_FILTERSQUERY = 'CAMPAIGN_LOGS_SET_FILTERSQUERY';
export const CAMPAIGN_LOGS_UPDATE_FILTERING = 'CAMPAIGN_LOGS_UPDATE_FILTERING';
export const CAMPAIGN_LOGS_SET_SORTQUERY = 'CAMPAIGN_LOGS_SET_SORTQUERY';
export const CAMPAIGN_LOGS_SET_REVERSESORT = 'CAMPAIGN_LOGS_SET_REVERSESORT';
export const CAMPAIGN_LOGS_UPDATE_SHOWFILTERS = 'CAMPAIGN_LOGS_UPDATE_SHOWFILTERS';
///////////////BANDEAU////////////////////

///////////////BANDEAU////////////////////
export const BANDEAU_START = 'BANDEAU_START';
export const BANDEAU_SUCCESS = 'BANDEAU_SUCCESS';
export const BANDEAU_FAIL = 'BANDEAU_FAIL';
export const BANDEAU_GET_ALL = 'BANDEAU_GET_ALL';
export const BANDEAU_SET_PAGE = 'BANDEAU_SET_PAGE';
export const BANDEAU_SET_PAGESIZE = 'BANDEAU_SET_PAGESIZE';
export const BANDEAU_SET_FILTERSQUERY = 'BANDEAU_SET_FILTERSQUERY';
export const BANDEAU_UPDATE_FILTERING = 'BANDEAU_UPDATE_FILTERING';
export const BANDEAU_SET_SORTQUERY = 'BANDEAU_SET_SORTQUERY';
export const BANDEAU_SET_REVERSESORT = 'BANDEAU_SET_REVERSESORT';
export const BANDEAU_UPDATE_SHOWFILTERS = 'BANDEAU_UPDATE_SHOWFILTERS';
//____________Suppliers Actions_________
export const SUPPLIERS_START = 'SUPPLIERS_START';
export const SUPPLIERS_SUCCESS = 'SUPPLIERS_SUCCESS';
export const SUPPLIERS_FAIL = 'SUPPLIERS_FAIL';
export const SUPPLIERS_GET_ALL = 'SUPPLIERS_GET_ALL';
export const SUPPLIERS_SET_PAGE = 'SUPPLIERS_SET_PAGE';
export const SUPPLIERS_SET_PAGESIZE = 'SUPPLIERS_SET_PAGESIZE';
export const SUPPLIERS_SET_FILTERSQUERY = 'SUPPLIERS_SET_FILTERSQUERY';
export const SUPPLIERS_UPDATE_FILTERING = 'SUPPLIERS_UPDATE_FILTERING';
export const SUPPLIERS_SET_SORTQUERY = 'SUPPLIERS_SET_SORTQUERY';
export const SUPPLIERS_SET_REVERSESORT = 'SUPPLIERS_SET_REVERSESORT';
export const SUPPLIERS_UPDATE_SHOWFILTERS = 'SUPPLIERS_UPDATE_SHOW_FILTERS';
export const SUPPLIERS_SEARCH_INPUT = 'SUPPLIERS_SEARCH_INPUT';
export const SUPPLIERS_SET_QUICKFILTER = 'SUPPLIERS_SET_QUICKFILTER';
//____________noConnectedSuppliers Actions_________
export const NCSUPPLIERS_START = 'NCSUPPLIERS_START';
export const NCSUPPLIERS_SUCCESS = 'NCSUPPLIERS_SUCCESS';
export const NCSUPPLIERS_FAIL = 'NCSUPPLIERS_FAIL';
export const NCSUPPLIERS_GET_ALL = 'NCSUPPLIERS_GET_ALL';
export const NCSUPPLIERS_SET_PAGE = 'NCSUPPLIERS_SET_PAGE';
export const NCSUPPLIERS_SET_PAGESIZE = 'NCSUPPLIERS_SET_PAGESIZE';
export const NCSUPPLIERS_SET_FILTERSQUERY = 'NCSUPPLIERS_SET_FILTERSQUERY';
export const NCSUPPLIERS_UPDATE_FILTERING = 'NCSUPPLIERS_UPDATE_FILTERING';
export const NCSUPPLIERS_SET_SORTQUERY = 'NCSUPPLIERS_SET_SORTQUERY';
export const NCSUPPLIERS_SET_REVERSESORT = 'NCSUPPLIERS_SET_REVERSESORT';
export const NCSUPPLIERS_UPDATE_SHOWFILTERS = 'NCSUPPLIERS_UPDATE_SHOW_FILTERS';
export const NCSUPPLIERS_SEARCH_INPUT = 'NCSUPPLIERS_SEARCH_INPUT';

//____________reporting Actions_________
export const REPORTING_START = 'REPORTING_START';
export const REPORTING_SUCCESS = 'REPORTING_SUCCESS';
export const REPORTING_FAIL = 'REPORTING_FAIL';
export const REPORTING_SET_PAGESIZE = 'REPORTING_SET_PAGESIZE';
////------------------lastinvoices------------------//
export const LASTEVENTS_START = 'LASTEVENTS_START';
export const LASTEVENTS_SUCCESS = 'LASTEVENTS_SUCCESS';
export const LASTEVENTS_FAIL = 'LASTEVENTS_FAIL';
export const LASTEVENTS_SET_PAGESIZE = 'LASTEVENTS_SET_PAGESIZE';


//___________Logs Actions_____________
export const LOGS_START = 'LOGS_START';
export const LOGS_SUCCESS = 'LOGS_SUCCESS';
export const LOGS_FAIL = 'LOGS_FAIL';
export const LOGS_SET_PAGE = 'LOGS_SET_PAGE';
export const LOGS_SET_FILTERSQUERY = 'LOGS_SET_FILTERSQUERY';
export const LOGS_UPDATE_FILTERING = 'LOGS_UPDATE_FILTERING';
export const LOGS_SET_SORTQUERY = 'LOGS_SET_SORTQUERY';
export const LOGS_SET_REVERSESORT = 'LOGS_SET_REVERSESORT';
export const LOGS_UPDATE_SHOWFILTERS = 'LOGS_UPDATE_SHOW_FILTERS';

//____________Users Actions_______________
export const USERS_START = 'USERS_START';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAIL = 'USERS_FAIL';
export const USERS_GET_ALL = 'USERS_GET_ALL';
export const USERS_SET_PAGE = 'USERS_SET_PAGE';
export const USERS_SET_PAGESIZE = 'USERS_SET_PAGESIZE';
export const USERS_SET_FILTERSQUERY = 'USERS_SET_FILTERSQUERY';
export const USERS_UPDATE_FILTERING = 'USERS_UPDATE_FILTERING';
export const USERS_SET_SORTQUERY = 'USERS_SET_SORTQUERY';
export const USERS_SET_REVERSESORT = 'USERS_SET_REVERSESORT';
export const USERS_UPDATE_SHOWFILTERS = 'USERS_UPDATE_SHOW_FILTERS';
export const USERS_SET_SELECTEDALLROWS = 'USERS_SET_SELECTEDALLROWS';
export const USERS_SELECT_USER = 'USERS_SELECT_USER';
export const USERS_UNSELECT_USER = 'USERS_UNSELECT_USER';
export const USERS_EMPTY_SELECTEDUSERS = 'USERS_EMPTY_SELECTEDUSERS';
export const USERS_SET_QUICKFILTER = 'USERS_SET_QUICKFILTER';
export const USERS_SET_BANDEAUX = 'USERS_SET_BANDEAUX';

//____________Extraction Actions_______________
export const EXTRACTION_START = 'EXTRACTION_START';
export const EXTRACTION_SUCCESS = 'EXTRACTION_SUCCESS';
export const EXTRACTION_FAIL = 'EXTRACTION_FAIL';
export const EXTRACTION_GET_ALL = 'EXTRACTION_GET_ALL';
export const EXTRACTION_SET_PAGE = 'EXTRACTION_SET_PAGE';
export const EXTRACTION_SET_PAGESIZE = 'EXTRACTION_SET_PAGESIZE';
export const EXTRACTION_SET_FILTERSQUERY = 'EXTRACTION_SET_FILTERSQUERY';
export const EXTRACTION_UPDATE_FILTERING = 'EXTRACTION_UPDATE_FILTERING';
export const EXTRACTION_SET_SORTQUERY = 'EXTRACTION_SET_SORTQUERY';
export const EXTRACTION_SET_REVERSESORT = 'EXTRACTION_SET_REVERSESORT';
export const EXTRACTION_UPDATE_SHOWFILTERS = 'EXTRACTION_UPDATE_SHOWFILTERS';
export const EXTRACTION_SET_SELECTEDALLROWS = 'EXTRACTION_SET_SELECTEDALLROWS';
export const EXTRACTION_SELECT_EXTRACTION = 'EXTRACTION_SELECT_EXTRACTION';
export const EXTRACTION_UNSELECT_EXTRACTION = 'EXTRACTION_UNSELECT_EXTRACTION';
export const EXTRACTION_EMPTY_SELECTEDEXTRACTIONS = 'EXTRACTION_EMPTY_SELECTEDEXTRACTIONS';

//-----------------INVOICEDETAIL ACTIONS-----------------
export const INVOICEDETAIL_DATA_START = 'INVOICEDETAIL_DATA_START'
export const INVOICEDETAIL_DATA_SUCCESS = 'INVOICEDETAIL_DATA_SUCCESS'
export const INVOICEDETAIL_DATA_FAIL = 'INVOICEDETAIL_DATA_FAIL'
export const INVOICEDETAIL_PDF_START = 'INVOICEDETAIL_PDF_START'
export const INVOICEDETAIL_PDF_SUCCESS = 'INVOICEDETAIL_PDF_SUCCESS'
export const INVOICEDETAIL_PDF_FAIL = 'INVOICEDETAIL_PDF_FAIL'
export const INVOICEDETAIL_EVENTS_START = 'INVOICEDETAIL_EVENTS_START'
export const INVOICEDETAIL_EVENTS_SUCCESS = 'INVOICEDETAIL_EVENTS_SUCCESS'
export const INVOICEDETAIL_EVENTS_FAIL = 'INVOICEDETAIL_EVENTS_FAIL'
export const INVOICEDETAIL_MSGS_START = 'INVOICEDETAIL_MSGS_START'
export const INVOICEDETAIL_MSGS_SUCCESS = 'INVOICEDETAIL_MSGS_SUCCESS'
export const INVOICEDETAIL_MSGS_FAIL = 'INVOICEDETAIL_MSGS_FAIL'
export const INVOICEDETAIL_SET_TAB_SIDEACTIVE = 'INVOICEDETAIL_SET_TAB_SIDEACTIVE'
export const INVOICEDETAIL_RESET_TO_INITIAL_STATE = 'INVOICEDETAIL_RESET_TO_INITIAL_STATE'
export const INVOICEDETAIL_SET_ENTITY_FILTER = 'INVOICEDETAIL_SET_ENTITY_FILTER'
export const ENTITYDETAIL_SET_ENTITY_SUCCESS = 'ENTITYDETAIL_SET_ENTITY_SUCCESS'
export const ENTITYDETAIL_GET_ENTITY_SUCCESS = 'ENTITYDETAIL_GET_ENTITY_SUCCESS'

//________Reject Actions__________
export const REJECT_START = 'REJECT_START';
export const REJECT_SUCCESS = 'REJECT_SUCCESS';
export const REJECT_FAIL = 'REJECT_FAIL';
export const REJECT_GET_ALL = 'REJECT_GET_ALL';
export const REJECT_SET_PAGE = 'REJECT_SET_PAGE';
export const REJECT_SET_PAGESIZE = 'REJECT_SET_PAGESIZE';
export const REJECT_SET_FILTERSQUERY = 'REJECT_SET_FILTERSQUERY';
export const REJECT_SET_QUICKFILTER = 'REJECT_SET_QUICKFILTER';
export const REJECT_UPDATE_FILTERING = 'REJECT_UPDATE_FILTERING';
export const REJECT_SET_SORTQUERY = 'REJECT_SET_SORTQUERY';
export const REJECT_SET_REVERSESORT = 'REJECT_SET_REVERSESORT';
export const REJECT_UPDATE_SHOWFILTERS = 'REJECT_UPDATE_SHOW_FILTERS';
export const REJECT_GET_DOCUMENT_FILE = 'REJECT_GET_DOCUMENT_FILE';
export const REJECT_SELECT_ROW = 'REJECT_SELECT_ROW';
export const REJECT_SELECT_ALL_ROWS = 'REJECT_SELECT_ALL_ROWS';
export const REJECT_SET_IMPORT_ERRORS_COUNT = 'REJECT_SET_IMPORT_ERRORS_COUNT';
export const REJECT_SET_UNREAD_SUCCESS='REJECT_SET_UNREAD_SUCCESS';

//____________Non engaging table Actions_________
export const NON_ENGAGING_START = 'NON_ENGAGING_START';
export const NON_ENGAGING_SUCCESS = 'NON_ENGAGING_SUCCESS';
export const NON_ENGAGING_FAIL = 'NON_ENGAGING_FAIL';
export const NON_ENGAGING_GET_ALL = 'NON_ENGAGING_GET_ALL';
export const NON_ENGAGING_SET_PAGE = 'NON_ENGAGING_SET_PAGE';
export const NON_ENGAGING_SET_PAGESIZE = 'NON_ENGAGING_SET_PAGESIZE';
export const NON_ENGAGING_SET_FILTERSQUERY = 'NON_ENGAGING_SET_FILTERSQUERY';
export const NON_ENGAGING_UPDATE_FILTERING = 'NON_ENGAGING_UPDATE_FILTERING';
export const NON_ENGAGING_SET_SORTQUERY = 'NON_ENGAGING_SET_SORTQUERY';
export const NON_ENGAGING_SET_REVERSESORT = 'NON_ENGAGING_SET_REVERSESORT';
export const NON_ENGAGING_UPDATE_SHOWFILTERS = 'NON_ENGAGING_UPDATE_SHOWFILTERS';
export const NON_ENGAGING_SET_QUICKFILTER = 'NON_ENGAGING_SET_QUICKFILTER';
export const NON_ENGAGING_SELECT_ROW = 'NON_ENGAGING_SELECT_ROW';
export const NON_ENGAGING_SELECT_ALL_ROWS = 'NON_ENGAGING_SELECT_ALL_ROWS';

//____________Unknown invoices table Actions_________
export const UNKNOWN_INVOICES_START = 'UNKNOWN_INVOICES_START';
export const UNKNOWN_INVOICES_SUCCESS = 'UNKNOWN_INVOICES_SUCCESS';
export const UNKNOWN_INVOICES_FAIL = 'UNKNOWN_INVOICES_FAIL';
export const UNKNOWN_INVOICES_GET_ALL = 'UNKNOWN_INVOICES_GET_ALL';
export const UNKNOWN_INVOICES_SET_PAGE = 'UNKNOWN_INVOICES_SET_PAGE';
export const UNKNOWN_INVOICES_SET_PAGESIZE = 'UNKNOWN_INVOICES_SET_PAGESIZE';
export const UNKNOWN_INVOICES_SET_FILTERSQUERY = 'UNKNOWN_INVOICES_SET_FILTERSQUERY';
export const UNKNOWN_INVOICES_UPDATE_FILTERING = 'UNKNOWN_INVOICES_UPDATE_FILTERING';
export const UNKNOWN_INVOICES_SET_SORTQUERY = 'UNKNOWN_INVOICES_SET_SORTQUERY';
export const UNKNOWN_INVOICES_SET_REVERSESORT = 'UNKNOWN_INVOICES_SET_REVERSESORT';
export const UNKNOWN_INVOICES_UPDATE_SHOWFILTERS = 'UNKNOWN_INVOICES_UPDATE_SHOWFILTERS';
export const UNKNOWN_INVOICES_SET_QUICKFILTER = 'UNKNOWN_INVOICES_SET_QUICKFILTER';
export const UNKNOWN_INVOICES_SELECT_ROW = 'UNKNOWN_INVOICES_SELECT_ROW';
export const UNKNOWN_INVOICES_SELECT_ALL_ROWS = 'UNKNOWN_INVOICES_SELECT_ALL_ROWS';

//____________Accounting audit table Actions_________
export const ACCOUNTING_AUDIT_START = 'ACCOUNTING_AUDIT_START';
export const ACCOUNTING_AUDIT_SUCCESS = 'ACCOUNTING_AUDIT_SUCCESS';
export const ACCOUNTING_AUDIT_FAIL = 'ACCOUNTING_AUDIT_FAIL';
export const ACCOUNTING_AUDIT_GET_ALL = 'ACCOUNTING_AUDIT_GET_ALL';
export const ACCOUNTING_AUDIT_SET_PAGE = 'ACCOUNTING_AUDIT_SET_PAGE';
export const ACCOUNTING_AUDIT_SET_PAGESIZE = 'ACCOUNTING_AUDIT_SET_PAGESIZE';
export const ACCOUNTING_AUDIT_SET_FILTERSQUERY = 'ACCOUNTING_AUDIT_SET_FILTERSQUERY';
export const ACCOUNTING_AUDIT_UPDATE_FILTERING = 'ACCOUNTING_AUDIT_UPDATE_FILTERING';
export const ACCOUNTING_AUDIT_SET_SORTQUERY = 'ACCOUNTING_AUDIT_SET_SORTQUERY';
export const ACCOUNTING_AUDIT_SET_REVERSESORT = 'ACCOUNTING_AUDIT_SET_REVERSESORT';
export const ACCOUNTING_AUDIT_UPDATE_SHOWFILTERS = 'ACCOUNTING_AUDIT_UPDATE_SHOWFILTERS';
export const ACCOUNTING_AUDIT_SET_QUICKFILTER = 'ACCOUNTING_AUDIT_SET_QUICKFILTER';
export const ACCOUNTING_AUDIT_SELECT_ROW = 'ACCOUNTING_AUDIT_SELECT_ROW';
export const ACCOUNTING_AUDIT_SELECT_ALL_ROWS = 'ACCOUNTING_AUDIT_SELECT_ALL_ROWS';

//____________Audit trail Actions_______________
export const AUDIT_TRAIL_START = 'AUDIT_TRAIL_START';
export const AUDIT_TRAIL_SUCCESS = 'AUDIT_TRAIL_SUCCESS';
export const AUDIT_TRAIL_FAIL = 'AUDIT_TRAIL_FAIL';
export const AUDIT_TRAIL_GET_ALL = 'AUDIT_TRAIL_GET_ALL';
export const AUDIT_TRAIL_SET_PAGE = 'AUDIT_TRAIL_SET_PAGE';
export const AUDIT_TRAIL_SET_PAGESIZE = 'AUDIT_TRAIL_SET_PAGESIZE';
export const AUDIT_TRAIL_SET_FILTERSQUERY = 'AUDIT_TRAIL_SET_FILTERSQUERY';
export const AUDIT_TRAIL_UPDATE_FILTERING = 'AUDIT_TRAIL_UPDATE_FILTERING';