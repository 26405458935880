import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux';
import Axios from '../../../axios-proas';
import { getErrorLabel } from '../../../shared/utility';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { IDProgressLineContainer, IDProgressLineItem, IDProgressLineLabel, PLAction } from './IDProgressLine.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from 'styled-components';

function IDProgressLine(props) { 
    const {
        userType,
        invoiceUid,
        statuses,
        status,
        isCodaRefused,
        invoiceData
    } = props
    
    const {t} = useTranslation();
    const theme = useTheme()
    const [statusList, setStatusList] = useState([]);
    const [statusHistory, setStatusHistory] = useState([]);

    const statusData = useMemo(() => statuses?.find(s => {
        if (userType === "supplier" && !+invoiceData?.permanentReject && statuses?.find(s2 => s2.id == status)?.aifeCode == "213") 
            return s.aifeCode == statusHistory[statusHistory.length - 1]?.aifeCode;
        else
            return s.id == status;
    }), [statuses, status, statusHistory])
    const dangerStatus = useMemo(() => [ '207', '210', '213'], [])

    useEffect(() => {
        Axios.get("invoice/"+invoiceUid+"/statusHistory").then(response => {
            setStatusHistory(response.data)
        })
    }, [])

    useEffect(() => {
        let statusListTmp = [];
        if (statusData && statusHistory.length > 0) {
            switch (statusData.aifeCode.toString()) {
                case '200': // Déposée
                case '202': // Recue par la PDP
                case '203': // Mise à disposition
                case '204': // Prise en charge
                case '205': // Approuvée
                case '211': // Paiement transmis
                    statusListTmp = statuses?.filter(s => ['202', '203', '204', '205', '211'].filter(c => c).includes(s.aifeCode.toString())).map(s => {return {label: s.label, value: s.id}})
                    break;
                case '207': // En litige
                    statusListTmp = statuses?.filter(s => ['202', '203', '204', '207'].includes(s.aifeCode.toString())).map(s => {return {label: s.label, value: s.id}})
                    const status205 = statuses?.find(s => s.aifeCode == '205')
                    statusListTmp.push({label: status205.label, value: status205.id});
                    const status211 = statuses?.find(s => s.aifeCode == '211')
                    statusListTmp.push({label: status211.label, value: status211.id});
                    break;
                case '210': // Refusée
                    statusListTmp = statuses?.filter(s => ['202', '203', '204', '207', '210'].includes(s.aifeCode)).map(s => {return {label: s.label, value: s.id}})
                    break;
                case '213': // Rejetée
                    statusListTmp = statuses?.filter(s => s.aifeCode == '202').map(s => {return {label: s.label, value: s.id}})

                    if (isCodaRefused==0)
                        statusListTmp.push({label: statusData.label + (userType === "owner" && invoiceData.rejectTypes ? " ("+invoiceData.rejectTypes + " - " + getErrorLabel(invoiceData.rejectTypes) + ")" : ""), value: statusData.id})
                    else
                        statusListTmp.push({label: statusData.label + (userType === "owner" ? " (refus CODA)" : ""), value: statusData.id})
                    
                    if(!+invoiceData?.permanentReject)
                        statusListTmp = statusListTmp.concat(statuses?.filter(s => ['203', '204', '205', '211'].includes(s.aifeCode.toString())).map(s => {return {label: s.label, value: s.id}}))
                    break;
                default:
            }
            if (statusHistory[0]?.aifeCode == "200")
                statusListTmp.unshift({label: statusHistory[0].label, value: statusHistory[0].id});
        }
        setStatusList(statusListTmp)
    }, [statusData, statusHistory])
    let actionButton = null;

    const getStatusColors = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value == statusData.id)
        if(dangerStatus.includes(statusData.aifeCode.toString())) {
            if(value == statusData.id) 
                return [theme.colors.danger, theme.colors.danger]
            if(index < statusIndex)
                return [theme.colors.success, theme.colors.success]
            if(index == (statusIndex + 1))
                return [theme.colors.seconday, theme.colors.seconday]
        }
            
        if(value == statusData.id) 
            // return [theme.colors.seconday, theme.colors.warning]
            return [theme.colors.success, theme.colors.success]
        if(index < statusIndex)
            return [theme.colors.success, theme.colors.success]
        // if(index === (statusIndex + 1))
        //     return [theme.colors.seconday, theme.colors.warning]
        return [null, null]
    }

    // a supprimer
    // if((userType === "client" || userType === "owner") && !accessToken && !['ERROR', 'REJECTED', 'REFUSED'].includes(status) ) {
    //     actionButton = (
    //         <PLAction onClick={() => setShowStatusModal(true)} >
    //             <span>
    //                 {"(Modifier le statut)"}
    //             </span>
    //             <EditIcon style={{fontSize: "1.25rem"}}/>
    //         </PLAction>
    //     )
    // }  

    return ( 
        <IDProgressLineContainer>
            {
                statusList.map((item, index) => (
                    <IDProgressLineItem 
                        key={item.value}
                        color={getStatusColors(item.value, index)[0]} 
                        border={getStatusColors(item.value, index)[1]} 
                    >
                        <IDProgressLineLabel>{t("statuses:"+item.label, item.label)}</IDProgressLineLabel>
                        <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
                    </IDProgressLineItem>
                ))
            } 
            {actionButton} 
        </IDProgressLineContainer>
        )      
    }
    
const mapStateToProps = (state) => ({
    invoiceData: state.invoiceDetail.invoiceData,
    userType: state.auth.userType
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(IDProgressLine)
