import React, { useEffect, useState } from 'react';
import { HeaderContainer, HeaderTitle, HeaderLinks, HLang, Hlink, HTheme } from './Header.styled';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import LanguageList from '../LanguageList/LanguageList';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Select from './Select/Select';
import Axios from '../../axios-proas';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import { getProfileWithKey, getRoleWithKey } from '../../shared/utility';

function Header(props) {
    const { logout, login, setAppTheme, changeProfile, newprofile, currentTheme, userType, userLevel, pageTitle } = props;
    const [profile, setProfile] = useState(newprofile != null ? newprofile.profile : localStorage.getItem('profile'));
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const [user, setUser] = useState("");

    const { t } = useTranslation();

    const toggleTheme = () => {
        if (currentTheme === 'light') {
            setAppTheme('dark');
        } else {
            setAppTheme('light');
        }
    }
    const selectChangeProfile = (profile) => {
        //localStorage.setItem('profile', value)
        //setProfile(value)
        setProfile(profile.profile);
        changeProfile(profile)
        navigate('/')
    }

    const getReadableProfile = (userProfile, userLevel) => {
        if (userProfile === "SUPPLIER")
            return t("user:role", "Role") + " " + t("user:"+userLevel)?.toLowerCase();
        else if (userProfile === "REJECT_MANAGER") {
            switch(userLevel) {
                case "admin":
                    return "Administrateur conformité";
                case "standard":
                    return "Gestionnaire conformité";
            }
        } else
            return getProfileWithKey(userProfile) + (userProfile !== "ADMIN" ? " " + getRoleWithKey(userLevel)?.toLowerCase() : "");
    }

    useEffect(() => {
        getprofiles()
    }, [userType]);

    const getprofiles = () => {
        Axios.get('/user//profilesforuser').then(response => {
            let li = [];
            setUser(response?.data?.userName)
            response.data.profiles.map((item, i) => {
                let p = {
                    value: item,
                    label: getReadableProfile(item.profile, item.level)
                };
                li = li.concat(p);
            });
            setOptions(li)
        }).catch(err => {
            setAlert("authentication:invalidToken");
        })
    }

    //     let li=[];
    //     k.map((item,i)=>{  
    //         let p={
    //             value: item.uid,
    //             label: item.code + " (" + item.name + ")",
    //         };
    //         li = li.concat(p);            
    //     });  
    // setDataForSelect(li)
    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{pageTitle}</HeaderTitle>
                <div style={{ display: "flex" }}>
                    <HeaderLinks>
                        {
                            // userType == "owner" &&
                            // <Hlink to="/FAQ" >
                            //     <HelpOutlineIcon
                            //         style={{ fontSize: "1rem" }}
                            //     />
                            // </Hlink>
                        }
                        <Hlink to="#" >
                            <PeopleOutlineIcon
                                style={{ fontSize: "0.9rem" }}
                            />
                            <span>{user} [{login}]</span>
                        </Hlink>
                        
                        <Hlink to="/editPassword" >
                            <LockIcon
                                style={{ fontSize: "0.9rem" }}
                            />
                            <span>{t("global:changePwd")}</span>
                        </Hlink>
                
                    <Select 
                            options={options} 
                            value={getReadableProfile(profile, userLevel)} 
                            onChange={selectChangeProfile} 
                            disabled={!(options?.length > 1)}
                    />
                    <div style={{marginRight:"5px"}}></div>
                <Hlink 
                    to={{pathname: "/"}}
                   
                    onClick={() => logout()}
                >
                    <LogoutIcon 
                        style={{fontSize: "0.9rem"}}
                    />
                    <span>{t("global:logout")}</span>
                </Hlink>
            </HeaderLinks>
            {/* <HTheme onClick={() => toggleTheme()} isActive={currentTheme === "dark"} >
                {
                    currentTheme === "dark" ?
                    <ToggleOffIcon />
                    :
                    <ToggleOnIcon />
                }
                <span>Mode nuit</span>
            </HTheme> */}
                    {userType === "supplier" && <HLang>
                        <LanguageList />
                    </HLang>}
                </div>
            </HeaderContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        currentTheme: state.app.theme,
        isAuthenticated: state.auth.token !== null,
        login: state.auth.login,
        userType: state.auth.userType,
        userLevel: state.auth.userLevel,
        newprofile: state.auth.newprofile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAppTheme: (theme) => dispatch(actions.appSetTheme(theme)),
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        logout: () => dispatch(actions.logout()),
        changeProfile: (profile) => dispatch(actions.changeProfile(profile))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
