import * as actionTypes from './actionTypes';
import Axios from '../../axios-proas';
import { useTranslation } from 'react-i18next';
import { getNotyfObject } from '../../shared/utility';
import { invoiceSetCounters } from './invoice';
const notyf = getNotyfObject();

export const rejectStart = () => {
    return {
        type: actionTypes.REJECT_START
    }
}

export const rejectSetPage = (page) => ({
    type: actionTypes.REJECT_SET_PAGE,
    currentPage: page
})

export const rejectSetPageSize = (pageSize) => ({
    type: actionTypes.REJECT_SET_PAGESIZE,
    pageSize: pageSize
})

export const rejectSuccess = (data, count, amountBTTotal, amountATTotal, page) => {
    return {
        type: actionTypes.REJECT_SUCCESS,
        data: data,
        amountBTTotal: amountBTTotal,
        amountATTotal: amountATTotal,
        count: count,
        page: page
    }
}

export const rejectFail = (error) => {
    return {
        type: actionTypes.REJECT_FAIL,
        error: error
    }
}

export const rejectGetAll = () => {
    return dispatch => {
        dispatch(rejectStart());
        Axios.get('/reject/')
            .then(response => {
                dispatch(rejectSuccess(response.data[0], response.data[1]))
            })
            .catch(err => {
                dispatch(rejectFail(err.response.data.message))
            })
    }
}

export const rejectGetPage = (page, filters, sort, reverse, pageSize = 100, pageType) => {
    let newFilters = {
        pageSize: pageSize
    }
    if(filters){
        for (const key in filters) {
            if(filters[key] !== null && filters[key] !== undefined && filters[key] !== "")
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    if(sort){
        newFilters = {
            ...newFilters,
            orderBy: sort
        }
    }
    if(reverse){
        newFilters = {
            ...newFilters,
            reverse: reverse[sort]
        }
    }
    return dispatch => {
        dispatch(rejectStart());
        Axios.get('/reject?page='+page, {
            params: {...newFilters}
        }).then(response => {
            if (filters.type === "codaRefused" && !filters.codaRefusedArchived)
                dispatch(invoiceSetCounters({codaRefused: response?.data?.count}));
            
            dispatch(rejectSuccess(response?.data?.result, response.status === 204 ? 0 : response?.data?.count, response?.data?.amountBTTotal, response?.data?.amountATTotal, pageType))
        }).catch(err => {
            dispatch(rejectFail(err?.response?.data?.message))
        })
    }
}

export const rejectSetFiltersQuery = (filtersQuery) => {
    return {
        type: actionTypes.REJECT_SET_FILTERSQUERY,
        filters: filtersQuery,
        filtering: rejectUpdateFiltering(filtersQuery)
    }
}


export const rejectUpdateFiltering = (filtersQuery) => {
    let filter = false;
    if(filtersQuery){
        for(const key in filtersQuery){
            if(filtersQuery[key]){
                filter = true
            }
        }
    }
    return filter
}

export const rejectSetQuickFilter = (quickFilterField) => {
    return {
        type : actionTypes.REJECT_SET_QUICKFILTER,
        quickFilter : quickFilterField
    }
}

export const rejectUpdateShowFilters = (show) => {
    return {
        type: actionTypes.REJECT_UPDATE_SHOWFILTERS,
        showFilters: show
    }
}

export const rejectSetSortQuery = (sortQuery) => {
    return {
        type: actionTypes.REJECT_SET_SORTQUERY,
        sortQuery: sortQuery
    }
}

export const rejectSetReverseSort = (reverse) => {
    return {
        type: actionTypes.REJECT_SET_REVERSESORT,
        reverse: reverse
    }
}

export const rejectGetDocSuccess = (fileB64) => {
    return {
        type: actionTypes.REJECT_GET_DOCUMENT_FILE,
        file: fileB64
    }
} 

export const rejectGetDocumentFile = (uid) => {
    return dispatch => {
        Axios.get('/reject/'+uid+'/documentFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            if(response.status === 200){
                let blob = new Blob([response.data], { type: response.request.getResponseHeader("Content-Type") })
                            let filename =""
                            const disposition = response.request.getResponseHeader('Content-Disposition')
                            if (disposition && disposition.indexOf('attachment') !== -1) {
                                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                var matches = filenameRegex.exec(disposition);
                                if (matches!== null && matches[1]) { 
                                filename = matches[1].replace(/['"]/g, '');
                                }
                            }
                            const downloadUrl = URL.createObjectURL(blob)
                            let a = document.createElement("a"); 
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
            }else {
                notyf.error("document non trouvé");
            }
            
        }).catch(err => {
            notyf.error('Une erreur s\'est produite !')
        })
    }
    
}
// var blob = new Blob([xmlFile], { type: 'text/plain' })


// //const downloadUrl = URL.createObjectURL(blob)
// let a = document.createElement("a"); 
// //a.setAttribute('href', window.URL.createObjectURL(blob));
// a.href = window.URL.createObjectURL(blob) ;
// a.download = tab=="xml"?xmlFileName:ediFileName || "facture.xml";
// a.dataset.downloadurl = ['', a.download, a.href].join(':');
// document.body.appendChild(a);
// a.click();


export const rejectSelectRow = (rowId, selected, page) => {
    return {
        type: actionTypes.REJECT_SELECT_ROW,
        rowId: rowId,
        selected: selected,
        page: page
    }
}

export const rejectSelectAllRows = (selectedAll,page) => {
    return {
        type: actionTypes.REJECT_SELECT_ALL_ROWS,
        selectedAll: selectedAll,
        page: page
    }
}

export const rejectSetImportErrorsCount = count => {
    return {
        type: actionTypes.REJECT_SET_IMPORT_ERRORS_COUNT,
        importErrorsCount: count
    }
}

export const rejectSetUnread = (uid) => {
    return dispatch => {  
        const currentLanguageCode = localStorage.getItem('i18nextLng');
        Axios.put('/invoice/'+uid+'/unread').then(response => {
            if (response.data.success) {
                notyf.success(currentLanguageCode == "fr" ? 'Facture marquée comme non-lue' : "Invoice marked as unread")
                dispatch(rejectSetUnreadSuccess(uid))
            } else
                notyf.error(currentLanguageCode == "fr" ? 'Cette facture n\'a aucun commentaire à marquer comme non-lu' : "This invoice has no comment to mark as unread");
        }).catch(err => {
            notyf.error(currentLanguageCode == "fr" ? 'Une erreur s\'est produite' : "An error has occurred")
            return false;
        })
    }
}

export const rejectSetUnreadSuccess = (uid) => {
    return {
        type: actionTypes.REJECT_SET_UNREAD_SUCCESS,
        uid: uid
    }
}

