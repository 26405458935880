import React, {useEffect, useState} from 'react'
import './FAQuestions.css'
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import DownloadIcon from '@mui/icons-material/Download';
import { connect } from 'react-redux';
import Axios from '../../../axios-proas'
import AddLinkIcon from '@mui/icons-material/AddLink';
function FAQuestions({width, questionsResponses, inputChangeHandler,categoriesFilter}) {
    const {t, i18n} = useTranslation();

    const getTranslatedField = (object, field) => {
        const suffix = i18n.language === "fr" ? "Fr" : "En";
        return object ? object?.[field+suffix] : "";
    }

    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
    }

    const [categoriesDoc, setCategoriesDoc] = useState([])
    let questionResponseJsx = questionsResponses?.filter(c => c?.questionsResponses.length > 0)?.map(cat => {
        return (
            <div className="accordion-item" key={cat?.id}>
                <h2 className="accordion-header" id={cat?.id}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" id={"collapseButton" + cat?.id} data-bs-target={"#collapse"+cat?.id} aria-expanded="false" aria-controls={"collapse" + cat?.id}>
                        {getTranslatedField(cat, "label")}
                    </button>
                </h2>
                <div id={"collapse" + cat?.id} className="accordion-collapse collapse" aria-labelledby={cat?.id} data-bs-parent="#categories" >
                    {
                        cat?.questionsResponses.length > 0 && 
                            <div className="accordion-body row" style={{paddingTop: "4px",paddingBottom: "0px"}}>
                                <div className="col-sm-6" style={{background: "aliceblue"}}>{t("home:questions", "QUESTIONS").toUpperCase()}</div>
                                <div className="col-sm-6" style={{background: "aliceblue"}}>{t("home:answers", "ANSWERS").toUpperCase()}</div> 
                            </div>
                    }
                    {
                        cat?.questionsResponses?.map(QR => {
                            return (
                                <div key={QR.id}>
                                    <div className="accordion-body row" style={{paddingBottom: "0px"}}>
                                        <div className="col-sm-6">{getTranslatedField(QR, "question")}</div>
                                        <div className="col-sm-6">{getTranslatedField(QR, "answer")}</div> 
                                    </div>
                                    <hr style={{margin: "0px"}}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    })
    useEffect(() => {
        Axios.get('/documentation//categories',{params:{categoriesFilter}}).then(response => {
            setCategoriesDoc(response?.data?.categories)
            response?.data?.categories?.forEach(c => {
                const button = document.getElementById("collapseButtonD"+c.id);
                const cat = document.getElementById("collapseD"+c.id);
                if (categoriesFilter) {
                    button?.classList.remove("collapsed");
                    cat?.classList.add("show");
                } else {
                    button?.classList.add("collapsed");
                    cat?.classList.remove("show");
                }
            })
        })
    }, [categoriesFilter])

    const handleDocumentationDownload = (uid,lien,docType) => {
        if(!lien){
            Axios.get('/documentation/' + uid + '/download',
                {
                    params: {
                        entity: null,
                        typeEntity: "supplier"
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        let pdfFileName=response.data.fileName
                        let pdfFile=response.data.pdfFile
                    
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
                            let byteCharacters = atob(pdfFile);
                            let byteNumbers = new Array(byteCharacters.length);
                            for (var i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            let byteArray = new Uint8Array(byteNumbers);
                            let blob = new Blob([byteArray], {type: 'application/pdf'});
                            window.navigator.msSaveOrOpenBlob(blob, pdfFileName || "mandat.pdf");
                        }
                        else {
                            let blob = new Blob([pdfFile], { type: 'application/pdf' })
                            const downloadUrl = URL.createObjectURL(blob)
                            let a = document.createElement("a"); 
                            a.href = "data:application/pdf;base64," + pdfFile;
                            a.download = pdfFileName || "mandat.pdf";
                            document.body.appendChild(a);
                            a.click();

                        }

                    } else {
                        notyf.error("document non trouvé");
                    }
                }).catch(err => {
                    notyf.error(t("global:errorOccurred", "An error has occurred"))
                })
        }
    }
    return (
        <>
            <div style={{width:width, border: '1px solid #D9E1E7CC', padding: '7px 10px', borderRadius: '6px', color:'#809FB8'}}>
                <div style={{display:'flex'}}>
                    <ul className="nav nav-tabs" style={{position:"relative"}}>
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#home">FAQ</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#Documentation">Documentation</a>
                        </li>
                    </ul>
                    <input type="text" className="form-control" placeholder={t("global:search", "Search")} aria-describedby="button-addon2" style={{color:"rgb(66, 65, 65)", height:"fit-content", margin: "5px 0 0 20px", width: "50%"}} onChange={e => inputChangeHandler(e.target.value)}/>
               </div>
                <div id="myTabContent" className="tab-content tabContent-scroll">
                    <div className="tab-pane fade active show" id="home">
                        <div className="accordion" id="categories">
                            {questionResponseJsx?.length > 0 ? questionResponseJsx : <p style={{textAlign: "center", marginTop: "2rem"}}>{t("global:noMatchFound", "No match found")}</p>}
                        </div>
                    </div>
                    <div className="tab-pane fade show" id="Documentation">
                        {categoriesDoc?.filter(c => c.documentations?.length > 0)?.length > 0 ? categoriesDoc?.filter(c => c.documentations?.length > 0).map((cat,index) => {

                        return(
                        <div className="accordion-item" key={cat.id}>
                            
                            <h2 className="accordion-header" id={index}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" id={"collapseButtonD"+index} data-bs-target={"#collapseD"+cat.id} aria-expanded="false" aria-controls={"collapseD"}>
                                   {cat?.name}
                                </button>
                            </h2>
                            <div id={"collapseD"+cat.id} className="accordion-collapse collapse" aria-labelledby={1} data-bs-parent="#Documentation" >
                            {cat?.documentations?.map((doc,i) => {
                                return(
                                   
                                    <div className="documentation" key={doc.id}>
                                        {doc.lien? 
                                        <>
                                        <span style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap"}}><a href={doc.lien} target="_blank"><span>{doc.docName}</span></a></span>
                                        
                                            <AddLinkIcon className="downloadIcon" onClick={e => handleDocumentationDownload(doc.uid,doc.lien,doc.documentationType)}/>
                                        </>:
                                        <>
                                            <span>{doc.docName}</span>
                                            <DownloadIcon className="downloadIcon" onClick={e => handleDocumentationDownload(doc.uid,doc.lien,doc.documentationType)}/>
                                        </>}
                                       
                                        </div>
                               
                                )})}
                                 </div>
                           
                        </div>
                        )
                    }) : <p style={{textAlign: "center", marginTop: "2rem"}}>{t("global:noMatchFound", "No match found")}</p>}
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => ({

})
const mapDispatchToProps = dispatch => ({
 
})
export default connect(mapStateToProps, mapDispatchToProps)(FAQuestions)

