import React, { useState, useEffect, useRef } from 'react'
import { EntContainer, Table, EntContent, EntDivContainer, TitleDiv, THead, LITableContainer } from './RejectsPerSupplierKPI.styled'
import { TBody, TH, TR, TD, THContainer } from '../../../styles/Table.styled'
import Axios from '../../../axios-proas';
import { formatDate } from '../../../shared/utility';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { CSVLink } from 'react-csv';
import RejectsPerSupplierDetailModal from "./RejectsPerSupplierDetailModal/RejectsPerSupplierDetailModal";

function RejectsPerSupplierKPI() {
    const [data, setData] = useState([]);
    const [dataCopy, setDataCopy] = useState([]);
    const [columnSort, setColumnSort] = useState("ratio");
    const [reverse, setReverse] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [showLineDetails, setShowLineDetails] = useState(false);
    const csvBtn = useRef(null);

    useEffect(() => {
        Axios.get("reject//rejectsPerSupplier").then(response => {
            setData(response.data);
            setDataCopy(response.data);
        })
    }, [])

    useEffect(() => {
        if (data) {
            const dataTemp = [...data];
            const upperQuery = searchQuery.toUpperCase();
            dataTemp.sort((d1, d2) => compare(d1[columnSort], d2[columnSort], reverse));
            setDataCopy(dataTemp.filter(d => d.supplierCode.toUpperCase().includes(upperQuery)));
        }
    }, [searchQuery])

    const compare = (d1, d2, reverse) => {
        if (isNaN(d1) || isNaN(d2)) {
            if (!reverse && d1 < d2 || reverse && d1 > d2)
                return -1;
            if (!reverse && d1 > d2 || reverse && d1 < d2)
                return 1;
            return 0;
        } else {
            if (!reverse && +d1 < +d2 || reverse && +d1 > +d2)
                return -1;
            if (!reverse && +d1 > +d2 || reverse && +d1 < +d2)
                return 1;
            return 0;
        }
    }

    useEffect(() => {
        const dataTemp = [...dataCopy];
        dataTemp.sort((d1, d2) => compare(d1[columnSort], d2[columnSort], reverse));
        setDataCopy(dataTemp);
    }, [columnSort, reverse])

    const sort = column => {
        if (columnSort === column)
            setReverse(!reverse);
        else {
            setReverse(false);
            setColumnSort(column);
        }
    }

    return (
        <EntDivContainer>
            <TitleDiv>
                <span>
                    {"Rejets par fournisseur - "}
                    <CTooltip title="Calcul effectué une fois par mois, le premier jour du mois">
                        <span>{" Date de calcul : "+ formatDate(data?.[0]?.updateDate ? +data?.[0]?.updateDate : new Date().getTime())}</span>
                    </CTooltip>
                </span>
            </TitleDiv>
            <input type="text" className="form-control" placeholder="Rechercher" onChange={e => setSearchQuery(e.target.value)} value={searchQuery}/>
            <EntContainer>
                <EntContent>
                    <LITableContainer style={{overflow: "auto", height: "307px"}}>
                        <Table>
                            <THead>
                                <tr>
                                    <CTooltip title={"Code fournisseur"}>
                                        <TH scope="col" width={"25%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }} onClick={() => sort("supplierCode")}>
                                            <THContainer>
                                                <span>Code fournisseur</span>
                                                {"supplierCode" === columnSort ? 
                                                    (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                    null
                                                }
                                            </THContainer>
                                        </TH>
                                    </CTooltip>
                                    <CTooltip title={"Nombre de rejets"}>
                                        <TH scope="col" width={"25%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }} onClick={() => sort("rejects")}>
                                            <THContainer>
                                                <span>Nombre de rejets</span>
                                                {"rejects" === columnSort ? 
                                                    (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                    null
                                                }
                                            </THContainer>
                                        </TH>
                                    </CTooltip>
                                    <CTooltip title={"Nombre de factures"}>
                                        <TH scope="col" width={"25%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }} onClick={() => sort("invoices")}>
                                            <THContainer>
                                                <span>Nombre de factures</span>
                                                {"invoices" === columnSort ? 
                                                    (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                    null
                                                }
                                            </THContainer>
                                        </TH>
                                    </CTooltip>
                                    <CTooltip title={"Rapport rejet par facture"}>
                                        <TH scope="col" width={"25%"} style={{ position: "sticky", backgroundColor: "red", top: 0 }} onClick={() => sort("ratio")}>
                                            <THContainer>
                                                <span>Rapport rejet par facture</span>
                                                {"ratio" === columnSort ? 
                                                    (reverse ? <ArrowUpwardIcon style={{fontSize: 20}} /> : <ArrowDownwardIcon style={{fontSize: 20}}/>) :
                                                    null
                                                }
                                            </THContainer>
                                        </TH>
                                    </CTooltip>
                                </tr>
                            </THead>
                            <TBody>
                                {dataCopy?.map(row => {
                                    return <TR key={row.supplierCode} onClick={() => setShowLineDetails(row.supplierCode)}>
                                        <CTooltip title={row.supplierCode + " " + row.supplierName || ""}><TD>{row.supplierCode}</TD></CTooltip>
                                        <TD>{row.rejects}</TD>
                                        <TD>{row.invoices}</TD>
                                        <TD>{(row.ratio*100).toFixed(2)+"%"}</TD>
                                    </TR>
                                })}
                            </TBody>
                        </Table>
                    </LITableContainer>
                </EntContent>
                {dataCopy ? (<CSVLink
                    ref={csvBtn}
                    data={dataCopy?.map(d => ({...d, ratio: (d.ratio*100).toFixed(2)+"%"}))}
                    filename={"Rejets par fournisseur.csv"}
                    headers={[
                        {label: 'Code tiers', key: 'supplierCode'},
                        {label: 'Fournisseur', key: 'supplierName'},
                        {label: 'Nombre de rejets', key: 'rejects'},
                        {label: 'Nombre de factures', key: 'invoices'},
                        {label: 'Rapport rejet par facture', key: 'ratio'}
                    ]}
                    separator=";">
                </CSVLink>) : null}
                <div style={{width:"100%",display: "flex", justifyContent: "center", padding: "11px"}}>
                    <button type="button" style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} className="btn btn-danger" onClick={() => csvBtn.current.link.click()}>Export Excel</button>
                </div>
            </EntContainer>
            <RejectsPerSupplierDetailModal show={showLineDetails} handleClose={() => setShowLineDetails(false)} />
        </EntDivContainer>
    )
}

export default RejectsPerSupplierKPI
