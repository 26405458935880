import styled from 'styled-components';

export const EXContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: ${({cursor, theme}) => cursor ? cursor : "pointer"};
`;

export const EXBody = styled.div`
    width: 100%;
    height: 100%;
`

export const EXListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const PaginationDiv = styled.div`
 display: flex;
justify-content: end;
margin-bottom:25px;
`;

