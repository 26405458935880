import React, { useCallback, useEffect, useState } from 'react';
import Axios from '../../../axios-proas';
import LOGO from '../../../assets/images/transdev_logo.png';
import { Link, useNavigate } from 'react-router-dom';
import './SupportContact.css'
import { useTranslation } from 'react-i18next';
import {RSTBody, RSTitle,RST} from '../ResetPassword/ResetPassword.Styled'
import {FormGroup, BtnOutlineLink, BtnAction, FormLabel} from '../../../styles/Common'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import MandatoryMarker from "../../UI/MandatoryMarker";
import LanguageList from '../../LanguageList/LanguageList';
import { Spinner } from 'react-bootstrap';

function SupportContact() {
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState(null);
    const [form, setForm] = useState({});
    const [sending, setSending] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        loadCaptchaEnginge(10);
    }, [])

    const submitHandler = (e) => {
        e.preventDefault();
        sendContactMail();
    }
    const sendContactMail = useCallback(() => { 
        if (form.login !== form.loginConfirm) {
            setError("authentication:pleaseConfirmEmail");
            setForm({...form, captcha: ""});
        } else if (validateCaptcha(form?.captcha)) {
            setError(null)
            setSending(true);
            Axios.post('/user//sendContactMail', form).then(response => {
                setAlert("authentication:messageSuccess")
            }).catch(error => {
                setError("authentication:messageError");
            }).finally(() => {
                setSending(false);
            })
        } else {
            setError("authentication:captchaError")
            setForm({...form, captcha: ""})
        }
    }, [form])

    let content = (<>
       
        <RSTBody>
            <div className="signin_language_container">
                <LanguageList color="red" />
            </div>
            <RST >
                
            <BtnOutlineLink 
                
                to={"/" }
                color="#fff"
                border="#fff">
                <img src={LOGO} alt="logo" style={{maxWidth:"200px"}} />
            </BtnOutlineLink>
            </RST>
            {
                error && error.length > 0 ? (
                    <RSTitle className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                        <ul>
                            {
                             <span > {t(error)} </span>
                            }
                        </ul>
                    </RSTitle>
                ) : null
            }

            <form onSubmit={e => submitHandler(e)} style={{width: "100%"}}>
                <FormGroup style={{alignItems: "center"}}>
                    <FormLabel htmlFor='login'>{t("authentication:enterEmail", "Enter your email address")} <MandatoryMarker/></FormLabel>
                    <input id='login' className="form-control" style={{width: "50%"}} type="email" name="login" onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} maxLength={64} value={form.login || ""} required autoFocus />
                </FormGroup>
                <FormGroup style={{alignItems: "center"}}>
                    <FormLabel htmlFor='loginConfirm'>{t("authentication:confirmEmail", "Confirm your email address")} <MandatoryMarker/></FormLabel>
                    <input id='loginConfirm' className="form-control" style={{width: "50%"}} type="email" name="loginConfirm" onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} maxLength={64} value={form.loginConfirm || ""} onPaste={e => {e.preventDefault(); return false}} required autoFocus autoComplete='off' />
                </FormGroup>
                <FormGroup style={{alignItems: "center"}}>
                    <FormLabel htmlFor='name'>{t("authentication:names", "Last name and first name")} <MandatoryMarker/></FormLabel>
                    <input id='name' className="form-control" style={{width: "50%"}} type="text" name="name" onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} maxLength={64} value={form.name || ""} required autoFocus />
                </FormGroup>
                <FormGroup style={{alignItems: "center"}}>
                    <FormLabel htmlFor='siret'>{t("authentication:siret", "Vendor VAT number")} <MandatoryMarker/></FormLabel>
                    <input id='siret' className="form-control" style={{width: "50%"}} type="text" name="siret" onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} maxLength={32} value={form.siret || ""} required autoFocus />
                </FormGroup>
                <FormGroup style={{alignItems: "center"}}>
                    <FormLabel htmlFor='subject'>{t("global:subject", "Subject")} <MandatoryMarker/></FormLabel>
                    <input id='subject' className="form-control" style={{width: "50%"}} type="text" name="subject" onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} maxLength={256} value={form.subject || ""} required autoFocus />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor='message'>{t("authentication:yourMessage", "Your message (2048 characters max)")} <MandatoryMarker/></FormLabel>
                </FormGroup>
                <textarea id='message' type="text" className='form-control' rows="6" name="message" style={{width:"100%", marginBottom: "20px"}} onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} maxLength={2048} required autoFocus />
                <FormGroup>
                    <FormLabel>Captcha <MandatoryMarker/></FormLabel>
                </FormGroup>
                <FormGroup style={{alignItems: "flex-start"}}>
                    <LoadCanvasTemplate reloadText={t("authentication:resetCaptcha", "Reset the Captcha")} />
                    <input id='captcha' className="form-control smallPlaceholder" style={{width: "50%"}} type="text" name="captcha" onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} maxLength={100} value={form.captcha || ""} placeholder={t("authentication:pleaseInputLeftCharacters", "Please input the characters seen on the left side")}  required autoFocus />
                </FormGroup>
                <FormGroup>
                    <BtnAction  color="#83abcb"
                                border="#83abcb" 
                                style={{marginTop: "20px"}}
                                onClick={() => navigate("/")} >
                                    {t("global:back", "Back")}
                    </BtnAction>
                    {sending ? <Spinner animation="border" style={{color:"#EE5A5A66"}}/> :
                        <BtnAction  color="#83abcb"
                                    type="submit"
                                    border="#83abcb" 
                                    style={{marginTop: "20px"}}
                                    >
                                        {t("global:send", "Send")}
                    </BtnAction>}
                </FormGroup>
            </form>
        </RSTBody>
    </>)


    if(alert) {
        content = (<div style={{textAlign: "center"}}>
            <RSTBody>
                <h4>{t(alert)}</h4>
                <Link to="/">{t("authentication:backToLoginPage", "Go back to the login page")}</Link>
            </RSTBody>
        </div>)
    }

    return (
        <div className="forgotPassword__container" style={{paddingTop:"100px", marginBottom: "100px"}}>
            {content}
        </div>
    )
}

export default SupportContact
