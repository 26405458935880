import React, { useCallback, useEffect, useState } from "react"
import TableActions from '../../components/UnknownInvoices/TableActions/TableActions';
import TableHead from '../../components/UnknownInvoices/TableHead/TableHead';
import TableBody from '../../components/UnknownInvoices/TableBody/TableBody';
import TableFilters from '../../components/UnknownInvoices/TableFilters/TableFilters';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import Axios from "../../axios-proas";
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions } from '../../styles/Common';
import { NoContent, SearchInput, SearchInputContainer, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { DContainer, DBody, DTableContainer } from './UnknownInvoices.styled';
import { Spinner } from 'react-bootstrap';
import { getNotyfObject } from "../../shared/utility";
import CustomConfirmModal from "../FAQ/CustomConfirmModal/CustomConfirmModal";

function UnknownInvoices(props) {
    const {
        loading,
        unknownInvoiceData,
        count,
        currentPage,
        pageSize,
        getUnknownInvoicePage,
        setUnknownInvoicePage,
        setUnknownInvoicePageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userProfile,
        updateShowFilters,
        setUnknownInvoiceFilters,
        setSortQuery,
        setReverse,
        setUnknownInvoiceQuickFilter,
        unknownInvoicesArchived,
        selectedRows,
        setSelectedRows,
        selectedAllRows,
        setSelectedAllRows
    } = props;
    const { t } = useTranslation();
    const notyf = getNotyfObject();
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const [tableProfile, setTableProfile] = useState(null);
    const [showClearModal, setShowClearModal] = useState(false)
    const navTitre = "unknownInvoice";

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{profile:userProfile, navTitre:navTitre}})
            .then(response => {
                let tempColumns = response?.data?.columns
                if (!tempColumns)
                    tempColumns = {suCode: 1, supplierCode: 1, number: 1, xmlFileName: 1, statusCode: 1, statusDate: 1, dueDate: 1};
                let columnsCopy = [
                    { title: t("invoiceList:entityCode", "Code SU"), field: 'suCode', width: "10%", show: tempColumns.suCode },
                    { title: t("supplier:supplierCodefournisseur", "Code tiers"), field: 'supplierCode', width: "12%", show: tempColumns.supplierCode },
                    { title: "Numéro facture", field: 'number', width: "16%", show: tempColumns.number },
                    { title: "Nom de fichier XML", field: 'xmlFileName', show: tempColumns.xmlFileName },
                    { title: "Code AIFE", field: 'statusCode', width: "10%", show: tempColumns.statusCode },
                    { title: "Date changement de statut", field: 'statusDate', width: "16%", show: tempColumns.statusDate },
                    { title: "Date d'échéance calculée", field: 'dueDate', width: "16%", show: tempColumns.dueDate }
                ];
                setColumns(columnsCopy)
                columnsCopy.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns])

    useEffect(() => {
        const filtersTemp = {...filtersQuery, unknownInvoicesArchived: unknownInvoicesArchived};
        setUnknownInvoiceFilters(filtersTemp)
        getUnknownInvoicePage(currentPage, filtersTemp, sortQuery, reverse, pageSize)
    }, [currentPage, pageSize, unknownInvoicesArchived])

    useEffect(() => {
        setSelectedRows(null);
        setSelectedAllRows(false);
    }, [unknownInvoicesArchived])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    useEffect(() => {
        if (tableProfile?.profileValue) {
            const parsedProfile = JSON.parse(tableProfile.profileValue);
            const columnsTemp = [...columns];
            columnsTemp.forEach(c => {
                c.show = parsedProfile?.columns[c.field];
            })
            setColumns(columnsTemp);
            columnsTemp.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);

            const newFilters = {...parsedProfile.filters};
            getUnknownInvoicePage(1, newFilters, sortQuery, reverse, pageSize);
            setUnknownInvoicePage(1)
            setUnknownInvoiceFilters(newFilters);
        } else if (tableProfile)
            resetFilters()
    }, [tableProfile])
    
    const clearLineHandler = uid => {
        return new Promise((resolve, reject) => {
            Axios.put('/unknowninvoice/' + uid + '/clear').then(response => {
                if (response?.data?.success) {
                    notyf.success("Succès du soldage de la ligne")
                    handleRefresh();
                    resolve(response);
                } else {
                    notyf.error("Erreur lors du soldage de la ligne");
                    reject(false);
                }
            }).catch(() => {
                notyf.error("Échec du soldage de la ligne");
                reject(false);
            })
        })
    }
    
    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getUnknownInvoicePage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setUnknownInvoicePage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getUnknownInvoicePage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setUnknownInvoiceFilters(null);
        setUnknownInvoicePage(1)
        getUnknownInvoicePage(1, null, sortQuery, reverse, pageSize)
    }

    const handleRefresh = useCallback(() => {
        getUnknownInvoicePage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const quickSearchChange = (quickSearchValue) => {
        let filtersTemp = {
            ...filtersQuery,
            quickSearch: quickSearchValue
        }
        setUnknownInvoiceQuickFilter('quickSearch')
        setUnknownInvoiceFilters(filtersTemp)
        if (quickSearchValue?.length >= 3)
            handleApplyFiltering(filtersTemp);
        else if (quickSearchValue?.length < 3 && filtersQuery?.quickSearch?.length >= 3) {
            filtersTemp.quickSearch = "";
            handleApplyFiltering(filtersTemp);
        }
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : <NoContent><span>Aucune facture à afficher</span></NoContent>;

    if (!loading && unknownInvoiceData?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            columns={columns}
            unknownInvoiceData={unknownInvoiceData}
            setShowClearModal={setShowClearModal}
            unknownInvoicesArchived={unknownInvoicesArchived}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows} />)
    }

    return (
        <DContainer>
            <TitleHelmet title={"Transdev | Remontée de statut de factures inconnues"} />
            <Header>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        <SearchIcon />
                    </SearchInputContainer>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        selectedRows={selectedRows}
                        userProfile={userProfile}
                        unknownInvoicesArchived={unknownInvoicesArchived}
                        handleRefresh={handleRefresh}
                    />

                </HeaderActions>
            </Header>
            <DBody>
                <TablePagination setDensity={setDensity} columns={columns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                    currentPage={currentPage}
                    pageChange={handlePageChange}
                    totalElement={count}
                    perPage={pageSize}
                    perPageChange={setUnknownInvoicePageSize}
                    filters={filtersQuery}
                    tableProfile={tableProfile}
                    setTableProfile={setTableProfile}
                    tableType="UNKNOWN"
                />
                <DTableContainer>
                 
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            filters={filtersQuery}
                            unknownInvoicesArchived={unknownInvoicesArchived}
                            selectedRows={selectedRows}
                            selectedAllRows={selectedAllRows}
                            setSelectedAllRows={setSelectedAllRows}
                        />
                        <TBody height={density}>
                            {showFilters && <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                filters={filtersQuery}
                                filtersChange={setUnknownInvoiceFilters}
                                applyFilters={handleApplyFiltering}
                                unknownInvoicesArchived={unknownInvoicesArchived}
                            />}
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                </DTableContainer>
            </DBody>
            <CustomConfirmModal
                show={showClearModal}
                handleClose={() => setShowClearModal(false)}
                okCallback={() => clearLineHandler(showClearModal)}
                text={`Êtes-vous sûr de vouloir solder cette ligne ? Elle disparaitra du tableau`} />
        </DContainer>
    )
}
const mapStateToProps = (state) => ({
    loading: state.unknownInvoice.loading,
    unknownInvoiceData: state.unknownInvoice.data,
    count: state.unknownInvoice.count,
    currentPage: state.unknownInvoice.currentPage,
    pageSize: state.unknownInvoice.pageSize,
    sortQuery: state.unknownInvoice.sortQuery,
    reverse: state.unknownInvoice.reverse,
    filtersQuery: state.unknownInvoice.filtersQuery,
    showFilters: state.unknownInvoice.showFilters,
    userProfile: state.auth.profile,
    selectedRows: state.unknownInvoice.selectedRows,
    selectedAllRows: state.unknownInvoice.selectedAllRows
})
const mapDispatchToProps = dispatch => ({
    getUnknownInvoicePage: (page, filters, sort, reverse, pageSize) => dispatch(actions.unknownInvoiceGetPage(page, filters, sort, reverse, pageSize)),
    setUnknownInvoicePage: (page) => dispatch(actions.unknownInvoiceSetPage(page)),
    setUnknownInvoicePageSize: (pageSize) => dispatch(actions.unknownInvoiceSetPageSize(pageSize)),
    setUnknownInvoiceQuickFilter: (page) => dispatch(actions.unknownInvoiceSetQuickFilter(page)),
    setSortQuery: (sortQ) => dispatch(actions.unknownInvoiceSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.unknownInvoiceSetReverseSort(reverseS)),
    setUnknownInvoiceFilters: (filters) => dispatch(actions.unknownInvoiceSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.unknownInvoiceUpdateShowFilters(show)),
    setSelectedRows: (rowId, selected) => dispatch(actions.unknownInvoiceSelectRow(rowId, selected)),
    setSelectedAllRows: (selectedAll) => dispatch(actions.unknownInvoiceSelectAllRows(selectedAll))
})
export default connect(mapStateToProps, mapDispatchToProps)(UnknownInvoices)

