import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    amountBTTotal: null,
    amountATTotal: null,
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    sortQuery: 'unreadMessages',
    reverse: {
        unreadMessages: false
    },
    file: null,
    selectedRows: null,
    selectedAllRows: false,
    importErrorsCount: 0,
    quickFilter: null,
    search: null,
    counters: {postdated: 0, codaRefused: 0, litigation: 0, unknown: 0, reject: 0, reject9x: 0}
}

const invoiceSetSortQuery = (state, action) => {
    return updateObject(state,{sortQuery: action.sortQuery})
}

const invoiceSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: {...action.reverse}
    }
    //return updateObject(state, {reverse: action.reverse})
}

const invoiceSetQuickFilter = (state, action) => updateObject(state, {
    quickFilter : action.quickFilter
})
const invoiceSetSearch = (state, action) => updateObject(state, {
    search : action.search
})


const invoiceUpdateShowFilters = (state, action) => {
    return updateObject(state, {showFilters: action.showFilters})
}

const invoiceSetFiltersQuery = (state, action) =>{
    return updateObject(
    state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)
} 

const invoiceStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const invoiceSuccess = (state, action) => {
    const selectedRows = state.selectedRows && action.data ? {...state.selectedRows} : null; 
    const selectedAllRows = !selectedRows || action.data.find(d => !selectedRows[d.uid]) ? false : true;
    
    return updateObject(state, {
        data: action.data,
        amountBTTotal: action.amountBTTotal,
        amountATTotal: action.amountATTotal,
        count: action.count,
        error: null,
        loading: false,
        selectedAllRows: selectedAllRows,
        selectedRows: selectedRows
    })
}

const invoiceFail = (state, action) => {
    return updateObject(state, {
        data: [],
        error: action.error,
        loading: false,
        count: 0,
        currentPage: 1,
        filtering: false
    })
}

const invoiceSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const invoiceSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const invoiceGetDocumentFile = (state, action) => updateObject(state, {
    file: action.file
})

const invoiceSelectRow = (state, action) => {
    let newSelectedRows = {...state.selectedRows, [action.rowId]: action.selected}
    const selectedAllRows = state.data.find(d => !newSelectedRows[d.uid]) ? false : true;
    return updateObject(state, {
        selectedAllRows: selectedAllRows,
        selectedRows: newSelectedRows
    })
}

const invoiceSelectAllRows = (state, action) => {
    let data = state.data;
    let newSelectedRows = {...state.selectedRows}
    data?.map(row => {
        newSelectedRows = {...newSelectedRows, [row.uid]: action.selectedAll}
    })
    const selectedAllRows = data.find(d => !newSelectedRows[d.uid]) ? false : true;
    return updateObject(state, {
        selectedAllRows: selectedAllRows,
        selectedRows: newSelectedRows
    })
}

const invoiceSetImportErrorsCount = (state, action) => updateObject(state, {
    importErrorsCount: action.importErrorsCount
})

const invoiceSetCounters = (state, action) => updateObject(state, {
    counters: {...state.counters, ...action.counters}
})

const invoiceSetUnreadSuccess = (state, action) => {
    const newData = [...state.data];
    newData.find(d => d.uid === action.uid).unreadMessages = 1;
    return updateObject(state, {
        data: newData
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.INVOICE_START: return invoiceStart(state, action)
        case actionTypes.INVOICE_SUCCESS: return invoiceSuccess(state, action)
        case actionTypes.INVOICE_FAIL: return invoiceFail(state, action)
        case actionTypes.INVOICE_SET_PAGE: return invoiceSetPage(state, action)
        case actionTypes.INVOICE_SET_PAGESIZE: return invoiceSetPageSize(state, action)
        case actionTypes.INVOICE_SET_FILTERSQUERY: return invoiceSetFiltersQuery(state, action)
        case actionTypes.INVOICE_SET_SORTQUERY: return invoiceSetSortQuery(state, action)
        case actionTypes.INVOICE_SET_REVERSESORT: return invoiceSetReverseSort(state, action)
        case actionTypes.INVOICE_UPDATE_SHOWFILTERS: return invoiceUpdateShowFilters(state, action)
        case actionTypes.INVOICE_GET_DOCUMENT_FILE: return invoiceGetDocumentFile(state, action)
        case actionTypes.INVOICE_SELECT_ROW: return invoiceSelectRow(state, action)
        case actionTypes.INVOICE_SELECT_ALL_ROWS: return invoiceSelectAllRows(state, action)
        case actionTypes.INVOICE_SET_IMPORT_ERRORS_COUNT: return invoiceSetImportErrorsCount(state, action)
        case actionTypes.INVOICE_SET_QUICKFILTER: return invoiceSetQuickFilter(state, action)
        case actionTypes.INVOICE_SET_SEARCH: return invoiceSetSearch(state, action)
        case actionTypes.INVOICE_SET_COUNTERS: return invoiceSetCounters(state, action)
        case actionTypes.INVOICE_SET_UNREAD_SUCCESS: return invoiceSetUnreadSuccess(state, action)
        default:
            return state
    }
}
export default reducer