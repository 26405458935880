import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import {formatDate, getTimeDate, getLabelWithKey} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';

function RejectStatusEvent({eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    const getStatusColor = () => {
        switch(eventData?.snapshot) {
            case 'TREATED':
            case 'TREATED_AND_SENT':
                return theme.colors.success;
            case 'TREATED_AND_CLOSED':
                return theme.colors.danger;
            case 'TO_TREAT':
            case 'PENDING':
            default:
                return theme.colors.warning;
        }
    }

    const getStatusLabel= () => {
        switch(eventData?.snapshot) {
            case 'TO_TREAT':
                return "Rejet à traiter";
            case 'PENDING':
                return "Rejet en cours";
            case 'TREATED':
            case 'TREATED_AND_CLOSED':
                return "Rejet clôturé";
            case 'TREATED_AND_SENT':
                return "Rejet clôturé / envoi CODA";
            default:
                if (eventData?.snapshot?.includes('ERR_TYPE_CHANGE'))
                    return "Passage manuel en rejet " + eventData.snapshot.replace('ERR_TYPE_CHANGE_', '');
                else if (eventData?.snapshot?.includes('SU_CODE_CHANGE'))
                    return "Changement de code SU vers " + eventData.snapshot.replace('SU_CODE_CHANGE_', '');
        }
    }

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={true} bdColor={getStatusColor()} color={getStatusColor()} >
                        <CheckIcon />
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={getStatusColor()}>
                            {getStatusLabel()}
                        </TETitle>
                        <TETime>
                            {eventData?.user ? (eventData?.user?.first_name || eventData?.user?.last_name ? (eventData?.user?.first_name || "") + " " + (eventData?.user?.last_name || "").trim() : eventData?.user?.login) + "\r\n" : (eventData?.noUser ? t("invoiceDetails:" + getLabelWithKey(eventData?.noUser)) + "\r\n" : null)}
                            {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    showContent && (
                        <TEContent>
                            {
                                eventData?.comment && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("default:comment", "Commentaire : ")}
                                        </MessageFrom>
                                        <MessageContent>
                                            {eventData?.comment?.slice(0, 128)}
                                            {eventData?.comment?.length > 128 ? '...' : null}
                                        </MessageContent>
                                    </MessageWrapper>
                                )
                            }
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default RejectStatusEvent