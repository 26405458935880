import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import { THead, TH, THContainer} from '../../../styles/Table.styled'
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { useLocation } from 'react-router-dom';

function TableHead(props) {
    const {columns, reverse,  page, setSortQuery, setReverse, getData, selectedAllRows, setSelectedAllRows, filters} = props;
    
    const location = useLocation();
    const columnClickHandler = (field) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getData(field,newReverse);
    }

    const selectAllRowsHandler = (e) => {
        setSelectedAllRows(!selectedAllRows,page);
    }

    return (
        <>
            <THead>
                    <tr>
                        <TH key={"column654"} width="50px" scope="col">
                            <CTooltip title={"Sélectionner tous les rejets"}>
                                <THContainer style={{justifyContent: "start"}}>
                                    <CheckIcon onClick={(e) => selectAllRowsHandler(e)} />
                                </THContainer>
                            </CTooltip>
                        </TH>
                       
                        {columns.filter(row=>row.show==true).map(column => (
                            <TH 
                                key={column.field} 
                                className={filters?.[location.pathname]?.[column.field] && 'filtered'}
                                scope="col" 
                                width={column.width}
                                id={column.field} 
                                onClick={() => columnClickHandler(column.field)}
                            >
                                <CTooltip title={column.title}>
                                    <THContainer>
                                        <span>
                                            {column.title}
                                        </span>
                                        <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none'}} />
                                        <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none'}}/>
                                    </THContainer>  
                                </CTooltip>  
                            </TH>
                            
                        ))}
                    </tr>
            </THead>
        </>
    )
}

export default TableHead
