import React, { useEffect,useRef, useState } from "react";
import { connect } from "react-redux";
import PdfViewer from "./PdfViewer/PdfViewer";
import { useParams } from "react-router-dom";
import { getNotyfObject } from "../../../shared/utility";
import Spinner from 'react-bootstrap/Spinner'
import { IVContainer, EntHeaderWrapper, XmlDiv, IVWrapper,IVContainer2, IVDownloadContainer, IVDownloadIcon } from "./DocumentationView.styled";
import Axios from "../../../axios-proas";
import { useNavigate, useLocation } from 'react-router-dom';
import DownloadIcon from "@mui/icons-material/Download";
import ConfirmDelete from './DeleteDocumentationModal/ConfirmDelete';
import { useTheme } from "styled-components";
import { useTranslation } from 'react-i18next';
import {NDContainer,DocVisu,NoContent,NDCol6,NDrow, ISContainer,EIFormGroup,EIFormLabel,Input,EIFormGroupChecked,ToggleDiv,InputCheckbox,EntitiesContainer}from '../../../containers/NewDocumentation/NewDocumentation.styled'
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle,BtnOutlineAction, BtnAction } from '../../../styles/Common'
import { Link } from 'react-router-dom';
import { TableAction, TableActionLink, TAContainer,SelectFilter } from '../../../styles/Table.styled.js';
import EastIcon from '@mui/icons-material/East';
import NorthIcon from '@mui/icons-material/North';
import FileUploaderBtn from '../../../components/UI/FileUploaderBtn/FileUploaderBtn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip';
import MandatoryMarker from "../../UI/MandatoryMarker";
import { Viewer, Worker } from "@react-pdf-viewer/core";

function DocumentationView({ userLevel, userProfile}) {    
  const [tab, setTab] = useState();
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileName, setPdfFileName] = useState(null);
  const [url, setUrl] = useState("");
  const [documentation,setDocumentation]=useState()
  const [listEntityUid,setListEntityUid]=useState()
  const [categories, setCategories] = useState()
  const theme = useTheme();
  const {t} = useTranslation();
  const [showModal,setShowModal]=useState(false)
  const [categoryName, setCategoryName] = useState("")
  const [categoriesUpdates, setCategoriesUpdates] = useState(false)
  const [visible, setVisible] = useState(false)
  let { uid, token } = useParams();
  const [showError, setShowError] = useState(false)
  const notyf = getNotyfObject();
  const [showNewCategoryInputField, setShowNewCategoryInputField] = useState(false);
  const customTheme = {
    attributeKeyColor: "#FF0000",
    attributeValueColor: "#000FF",
    overflowBreak: true,
  };
  const uploader = useRef(null);
  const entRef = useRef();

  const navigate = useNavigate();
  let location = useLocation()

  const changeTab = (tab) => {
    setTab(tab);
  };

  const downloadClickHandler = (tab) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE workaround
      if (tab == "xml") {
        var byteCharacters = atob(xmlFile);
      } else {
        var byteCharacters = atob(ediFile);
      }

      let byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: "text/plain" });
      window.navigator.msSaveOrOpenBlob(  
        blob,
        tab == "xml" ? xmlFileName : ediFileName || "facture.xml"
      );
    } else {
      // much easier if not IE
      //________Direct Download_____
      var blob = null;
      switch (tab) {
        case "xml":
          blob = new Blob([xmlFile], { type: "text/plain" });
          break;
        case "edi":
          blob = new Blob([ediFile], { type: "" });
          break;
        case "chorus":
          blob = new Blob([chorusFile], { type: "text/plain" });
          break;
        default:
          break;
      }

      //const downloadUrl = URL.createObjectURL(blob)
      let a = document.createElement("a");
      //a.setAttribute('href', window.URL.createObjectURL(blob));
      a.href = window.URL.createObjectURL(blob);
      a.download =
        tab == "xml"
          ? xmlFileName
          : tab == "edi"
          ? ediFileName
          : chorusFileName || "facture.xml";
      a.dataset.downloadurl = ["", a.download, a.href].join(":");
      document.body.appendChild(a);
      a.click();

      //__________Visualize In The Browser _____
      // const blob = dataURItoBlob(data);
      // const url = URL.createObjectURL(blob);

      // // to open the PDF in a new window
      // window.open(url, '_blank');
    }
  };

  const handleDocumentationDownload = (uid, entity, typeEntity) => {
    Axios.get('/documentation/'+uid+'/download',
    {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: {
            entity: entity,
            typeEntity : typeEntity
        }
    })
    .then(response => {
        if(response.status === 200){
          let name=response.data.docName
          setPdfFile(response.data?.pdfFile)
          setPdfFileName(name+".pdf")
          setDocumentation(response.data)
          setVisible(response.data?.visible)
          var listUid=  response.data?.suppliers?.map(function(numbe) {
            return  numbe.uid
          })
          setListEntityUid(listUid)

        }else {
            notyf.error("document non trouvé");
        }
    }).catch(err => {
        notyf.error('Une erreur s\'est produite !')
    })
}

useEffect(()=>{
    handleDocumentationDownload(location.state?.uid, location.state?.entity, location.state?.type)
},[location])



useEffect(() => {
  entRef?.current?.scrollTo({
      bottom : 0,
      left: 0
  })
}, [])

    const updateVisible=()=>{
        if(userProfile === "ADMIN")
            if(visible) {
                setVisible(false)
                let docTempp = {
                    ...documentation,
                    visible: 0     
                }
                setDocumentation(docTempp)
            } else {
                setVisible(true)
                let docTemp = {
                    ...documentation,
                    visible: 1 
                }
                setDocumentation(docTemp)
            }
    }


  const inputChangeHandler = (e) => {
    const name = e.target.name;
    let value =  e.target.value;
    
    console.log(e.target)
 
    let docTemp = {
        ...documentation,
        [name]: value
    }
    setDocumentation(docTemp)
}




const entitiesRadioChangeHandler = (name, value) => {
  let docTemp = {
      ...documentation,
      [name]: +value
  }
  setDocumentation(docTemp)
}
const deleteSupplierClickHandler = (supplier) => {
  let docTemp = {...documentation}
  if(documentation?.id && !supplier?.new ){
      docTemp = {
          ...docTemp,
          suppliersToDelete : docTemp?.suppliersToDelete ? [...docTemp?.suppliersToDelete, supplier.uid] : [supplier.uid]
      }
  }
  let docSuppliers = [...documentation?.suppliers]
  let supplierIndex = documentation?.suppliers.findIndex(ent => ent.uid === supplier.uid);
  if(supplierIndex >=0 ){
      docSuppliers.splice(supplierIndex, 1)
  }
  setDocumentation({
      ...docTemp,
      suppliers: docSuppliers
  })
}

  const handleFileChange = (e) => {
    if (e?.target?.files[0]?.type == "application/pdf") {
      if (e?.target.files && e?.target.files[0]) {
        setUrl(URL.createObjectURL(e.target.files[0]));
      }
      let tmpDoc = {
        ...documentation,
        formData: e?.target.files[0],
        fileName: e?.target.files[0].name,
        fileType: e?.target.files[0].type,
      };
      setDocumentation(tmpDoc);
    } else if(e.target.files.length > 0) {
      setUrl("");
      notyf.error("Le format PDF est attendu");
    }
  };

const suppliersChangeHandler = (suppliersList) => {
  console.log(suppliersList)
  let docTemp = {
      ...documentation,
      suppliers :[...documentation.suppliers, ...suppliersList.filter(item => !listEntityUid.includes(item.uid)) ],
      clients : null,
  }
  var listUid=  docTemp?.suppliers?.map(function(numbe) {
    return  numbe.uid
})
console.log(listUid)
setListEntityUid(listUid)
  setDocumentation(docTemp)
 
  setIfClient(false); 
}



  const addDocumentationclickHandler = () => {
    const formData = new FormData();
    formData.append('file', documentation.formData);
    formData.append("data", JSON.stringify({...documentation}))
  
    Axios.post("documentation//editDocumentation", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
        if (response?.data.success == true){
            notyf.success("Documentation sauvegardée avec succès");
        } else {
            notyf.error("Échec de la sauvegarde de la documentation");
        }
      
    })
        

  }


useEffect(() => {
 
  entRef?.current?.scrollTo({
      bottom : 0,
      left: 0
  })
  Axios.get('/documentation//categories').then(response => {
      setCategories( response?.data?.categories)
  })
}, [categoriesUpdates])


let documentLien=null;
if(documentation?.documentationType=="lien"){
  documentLien= <div className="form-group">
                  <a href={documentation?.lien} target="_blank">Visiter le lien</a>
                </div>
}


  let admView = null;

  if (!pdfFile) admView = <spinner />;
  else {
    if(url)
    admView = <DocVisu>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
      <Viewer fileUrl={url} />
    </DocVisu>
    else if (pdfFile )
      admView = (
        <>
            <PdfViewer  pdfFile={pdfFile} pdfFileName={pdfFileName} />
        </>
      );
    else
      admView = (
        <>
          <div>
            {" "}
       
              <h5>Cette facture ne contient pas de PDF </h5>
      
          </div>
        </>
      );
  }

  const deleteDocumentationHandler = (documantationRef) => {
    Axios.put('/documentation/' + documantationRef + '/deleteDocumentation').then(response => {
        if (response?.data?.success) {
          navigate(-1)
        } else {
            // e.preventDefault()
            notyf.error("Une erreur s\'est produite !")
        }
    })
  }

  const chekedDocumentation = () => {
    if (userLevel=="admin" && userProfile=="ADMIN"){
        if((documentation?.docName && documentation.docName!="") && (documentation?.category && documentation.category!="")&& ((documentation?.fileName && documentation.fileName!="") ||(documentation?.lien && documentation?.lien !="")) ){
            return false
        }else {
            return true
        }
      }else {
          return true
      }    
}

  const addCategoriesHandle = (categoryName) => {
    Axios.put('/documentation//addCategory', { categoryName: categoryName }).then(response => {
        if (response?.data?.success) {
            setCategoriesUpdates(!categoriesUpdates)
        } else if (response?.data?.alreadyExist) {
            notyf.error('Cette catégorie existe déjà!')
        } else {
            notyf.error("Une erreur s\'est produite !")
        }
    })
  }

  const addCategorie = (category) => {
    if(category !== ""){
        addCategoriesHandle(category)
    }else 
        setShowError(true)
  } 

  return (
    <div>
       <Header>          
          <HeaderActions>
              {userProfile=="ADMIN" && <><BtnAction
                  style={{border:"1px solid #f00"}}
                  onClick={() => setShowModal(true)}
                >
                  <span>Supprimer</span>
              </BtnAction>
              <BtnAction
                  bg="#55EFC466"
                  disabled={chekedDocumentation()}
                  onClick={() => addDocumentationclickHandler()}
                  >
                  <span>Sauvegarder les modifications</span>
              </BtnAction></>}  
          </HeaderActions>
      </Header>
      {
        showModal?(
            <ConfirmDelete handleClose={()=>setShowModal(false)}
              show={showModal}
              item={documentation?.uid}
              deleteDocumentationHandler={deleteDocumentationHandler}
            />
        ):null
      }
      <IVWrapper>
      <IVContainer >{ pdfFile? admView: documentLien}</IVContainer>
    
      <IVContainer2>   
                            <ISContainer>
                                <EIFormGroup style={{justifyContent: 'space-between'}}>
                                    <EIFormLabel htmlFor="first_name">Nom du document* :</EIFormLabel>
                                    <Input 
                                       placeHolder="Nom du document"
                                        id="first_name"
                                        name="docName" 
                                        type="text"
                                        color="#06152B" 
                                        autoComplete="off"
                                        value={documentation?.docName || ""}
                                        onChange={(e) => inputChangeHandler(e)} 
                                        disabled={userLevel=="admin" && userProfile=="ADMIN"?false:true}
                                    />
                                </EIFormGroup>
                                <EIFormGroup style={{justifyContent: 'space-between'}}>
                                    <EIFormLabel htmlFor="first_name">Catégorie* :</EIFormLabel>
                                    <SelectFilter style={{width:"300px"}}
                                        name="category" 
                                        value={documentation?.category || ""}
                                        onChange={(e) => inputChangeHandler(e)} 
                                        disabled={userLevel=="admin" && userProfile=="ADMIN"?false:true}
                                    >   
                                        <option value={-1}></option>
                                        {
                                            categories?.map((category, index) => {
                                                return <option key={index} value={category?.id}>{category?.name} </option>
                                            })
                                        }


                                    </SelectFilter>
                                    {
                                      !showNewCategoryInputField && (userLevel=="admin" && userProfile=="ADMIN") ? 
                                        <CTooltip title="Nouvelle catégorie" style={{  fontSize: "20px"}}>
                                          <EastIcon style={{cursor: "pointer"}} onClick={(e) => setShowNewCategoryInputField(true)} />
                                        </CTooltip>
                                      : 
                                      <CTooltip title="Nouvelle catégorie" style={{  fontSize: "20px"}}>
                                          <NorthIcon style={{cursor: "pointer"}} onClick={(e) => setShowNewCategoryInputField(false)} />
                                        </CTooltip>

                                    }
                                </EIFormGroup>
                                {
                                    showNewCategoryInputField ? 
                                      <div class="input-group mb-3">
                                          <input type="text" className="form-control" value ={categoryName} style={{height: "34px"}} onChange={(e) => {setCategoryName(e.target.value);setShowError(false)}}/>
                                          {/* <CTooltip title="Ajouter" style={{  fontSize: "20px"}}>
                                            <AddIcon onClick={(e) => addCategorie(categoryName)} className="addCatBotton"/> 
                                          </CTooltip> */}
                                          <BtnAction bg="#55EFC466"
                                              style={{height: "34px"}}
                                              onClick={() => {addCategorie(categoryName);}}
                                            >
                                              <span>Ajouter</span>
                                          </BtnAction>
                                      </div> : null
                                }
                                {
                                  (showError && showNewCategoryInputField) && 
                                      <div> 
                                          <p style={{marginLeft:"10px", fontSize: "12px",color:"red"}}>Veuillez saisir un nom de catégorie</p>
                                      </div>
                                }
                                <EIFormGroup style={{justifyContent: 'space-between'}}>
                                    <EIFormLabel  htmlFor="visible" className="accountRequest__canalItemLabel">Visible aux fournisseurs</EIFormLabel>
                                    <ToggleDiv>
                                        {
                                        documentation?.visible=="1"? (
                                            <ToggleOnIcon 
                                                style={{marginLeft: '0.25rem', color: userProfile=="ADMIN" ? theme.colors.success : "#b8edb7", cursor: 'pointer'}} 
                                                fontSize="small"
                                                name="edi"
                                                id="visible"
                                                onClick={() => updateVisible()}
                                            />   
                                        ) : 
                                            <ToggleOffIcon 
                                                style={{marginLeft: '0.25rem', color: userProfile=="ADMIN" ? "black" : "grey", cursor: 'pointer'}} 
                                                fontSize="small"
                                                name="edi"
                                                onClick={() => updateVisible()}
                                            />
                                    
                                        }
                                  </ToggleDiv>
                               </EIFormGroup>
                            
                                    {/* {documentation?.fileName &&
                                        <EIFormGroup>
                                              <EIFormLabel htmlFor="first_name">{t('Fichier')}</EIFormLabel>
                                                <Input 
                                                    placeHolder={t('documentDetails:name')} 
                                                    name="docName" 
                                                    color="#06152B"
                                                    type="text" 
                                                    autoComplete="off"
                                                    value={documentation?.fileName || ""}
                                                />
                                        </EIFormGroup>
                                    } */}
                                   
                              {documentation?.documentationType === "fichier" && userProfile === "ADMIN" && (
                                <>
                                  <EIFormGroup>
                                    <FileUploaderBtn
                                        btnLabel="Choisir un fichier"
                                        handleChange={handleFileChange}
                                        name="filename"
                                        ref={uploader}
                                    />
                                  </EIFormGroup>
                                  <p style={{fontSize: "10px"}}><MandatoryMarker /> Seuls les documents de type PDF sont acceptés</p>
                                </>
                              )}
                              {documentation?.documentationType === "lien" &&( 
                                <EIFormGroup>
                                    <EIFormLabel htmlFor="liens">{"Lien :"}</EIFormLabel>
                                        <Input 
                                            placeHolder={"Copier le lien ici"} 
                                            id="liens"
                                            name="lien" 
                                            color="#06152B"
                                            type="text" 
                                            autoComplete="off"
                                            value={documentation?.lien || ""}
                                            disabled={userLevel=="admin" && userProfile=="ADMIN"?false:true}
                                            // onChange={(e) => inputChangeHandler(e)} 
                                        />
                                </EIFormGroup>
                              )}
                             </ISContainer>
                                
                                
                            </IVContainer2> 
        
      
    </IVWrapper>
    </div>
  );
}


const mapStateToProps = (state) => ({
 
  userRole: state.auth.role,
  userType: state.auth.userType,
  userLevel: state.auth.userLevel,
  userProfile: state.auth.profile,
  userUid: state.auth.userUid
})




const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentationView);
