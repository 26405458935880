import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  ADDisabledTD,
  CFBody,
  CFContainer,
  CFFormSection,
  CFSection,
  FormInput,
} from "./AmountDetailModal.styled";
import Axios from "../../../axios-proas";
import { BtnAction, GroupBtns } from "../../InvoiceDetail/InvoiceBody/InvoiceSide/TraitementForm/InvoiceDetail.styled";
import { FormSelect } from "../../../styles/Common";
import { TableAction } from "../../../styles/Table.styled";

function AmountDetailModal({show, modalClosed, rejectData, saveFormHandler, roundNumber}) {
  const [taxCodes, setTaxCodes] = useState([]);
  const [taxDetails, setTaxDetails] = useState([]);
  const [totalTaxValue, setTotalTaxValue] = useState(null);

  useEffect(() => {
    if (show) {
      if (taxCodes?.length === 0)
        Axios.get("nonengagingtax?usedForControls=1&orderBy=code").then(response => {
          setTaxCodes(response.data?.result);
        })
      
      if (rejectData && rejectData.taxDetails)
        setTaxDetails([...rejectData?.taxDetails])
    }
  }, [rejectData, show])

  useEffect(() => {
    setTotalTaxValue(roundNumber(taxDetails.map(td => roundNumber(getTaxRateFromCode(td.taxCode) * td.taxValue / 100)).reduce((sum, value) => sum + value, 0)))
  }, [taxDetails])

  const getTaxRateFromCode = taxCode => {
    const taxRate = taxCodes.find(t => t.code == taxCode);
    return taxRate?.rate ? +taxRate.rate : 0;
  }

  const handleInputChange = (event, index) => {
    const key = event.target.name;
    const taxDetailsTemp = [...taxDetails];
    const value = key == "taxValue" ? event.target.value?.replace(",", ".") : event.target.value;
    taxDetailsTemp[index][key] = value;
    if(key === "taxCode" && getTaxRateFromCode(value) == 0)
      taxDetailsTemp[index].taxValue = '0';
    setTaxDetails(taxDetailsTemp);
  }

  const handleAddLine = () => {
    const taxDetailsTemp = [...taxDetails];
    taxDetailsTemp.push({taxValue: '0'});
    setTaxDetails(taxDetailsTemp);
  }

  const handleDeleteLine = index => {
    const taxDetailsTemp = [...taxDetails];
    taxDetailsTemp.splice(index, 1);
    setTaxDetails(taxDetailsTemp);
  }

  const isFormValid = () => {
    return taxDetails.filter(td => !td.taxValue || !td.taxCode).length === 0;
  }

  return (
    <Modal show={show} onHide={modalClosed} dialogClassName="dialogModal modalMarginTop" contentClassName="modal_w_mc">
      <Modal.Header closeButton>
        <Modal.Title>Montants par taxe</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ADMCreationModal__body">
        <CFContainer>
          <CFBody>
            <CFSection>
              <CFFormSection>
                <form onSubmit={e => {e.preventDefault(); saveFormHandler(taxDetails, totalTaxValue); modalClosed()}}>
                  <table>
                    <thead>
                      <tr style={{borderBottom: "solid grey 1px"}}>
                        <th style={{width: "80px"}}>Ligne</th>
                        <th style={{width: "150px"}}>Montant HT</th>
                        <th>Code taxe</th>
                        <th style={{width: "150px"}}>Taux TVA (%)</th>
                        <th style={{width: "150px"}}>Montant taxe</th>
                        <th style={{width: "150px"}}>Supprimer ligne</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taxDetails?.map((td, index) => (<tr key={td.id || td.taxCode + td.taxValue + index}>
                        <ADDisabledTD>{index + 1}</ADDisabledTD>
                        <td>
                          <FormInput
                            type="text"
                            name="taxValue"
                            pattern="-?\d*(\.(\d)*)?"
                            autoFocus="autoFocus"
                            value={td.taxValue}
                            onChange={e => handleInputChange(e, index)}
                            style={{textAlign: "right", color: "black", fontSize: "1em"}}
                            required
                          />
                        </td>
                        <td>
                          <FormSelect name="taxCode" value={td.taxCode} onChange={e => handleInputChange(e, index)}>
                            <option value="">-- Choisissez un code taxe --</option>
                            {taxCodes?.map(t => (<option key={t.id} value={t.code}>{t.code + " (" + t.description + ")"}</option>))}
                          </FormSelect>
                        </td>
                        <ADDisabledTD>{getTaxRateFromCode(td.taxCode)}</ADDisabledTD>
                        <ADDisabledTD>{roundNumber(getTaxRateFromCode(td.taxCode) * td.taxValue / 100)}</ADDisabledTD>
                        <td>
                          <TableAction title="Supprimer la ligne" style={{width: "fit-content", margin: "auto"}} onClick={() => handleDeleteLine(index)}>
                            <DeleteForeverIcon />
                          </TableAction>
                        </td>
                      </tr>))}
                      <tr style={{borderTop: "solid grey 1px"}}>
                        <td>Total</td>
                        <ADDisabledTD>{roundNumber(taxDetails.map(td => +td.taxValue).reduce((sum, value) => sum + value, 0))}</ADDisabledTD>
                        <td></td>
                        <td></td>
                        <ADDisabledTD>{totalTaxValue}</ADDisabledTD>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <TableAction title="Ajouter une ligne" style={{width: "fit-content", margin: "auto"}} onClick={handleAddLine}>
                            <AddIcon style={{marginRight: "10px"}}/>
                            Ajouter une ligne
                          </TableAction>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <GroupBtns style={{margin: "40px auto 0 auto"}}>
                    <BtnAction
                      type="submit"
                      bg={"#ff0000"}
                      color={"#ff0000"}
                      disabled={!isFormValid()}
                      >Sauvegarder
                    </BtnAction>
                    <BtnAction
                      bg={"#ff0000"}
                      color={"#ff0000"}
                      onClick={() => modalClosed()}
                      >Annuler
                    </BtnAction>
                  </GroupBtns>
                </form>
              </CFFormSection>
            </CFSection>
          </CFBody>
        </CFContainer>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  userUid: state.auth.userUid
})
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps) (AmountDetailModal);
