import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize : 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    sortQuery: 'creationDate',
    reverse: {
        creationDate: false
    }
}

const campaignLogsStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const campaignLogsSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false
})

const campaignLogsFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const campaignLogsSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const campaignLogsSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const campaignLogsSetSortQuery = (state, action) => updateObject(state,{
    sortQuery: action.sortQuery
})

const campaignLogsSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: {...action.reverse}
    }
}

const campaignLogsUpdateShowFilters = (state, action) => {
    return updateObject(state, {showFilters: action.showFilters})
}

const campaignLogsSetFiltersQuery = (state, action) => updateObject(state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CAMPAIGN_LOGS_START: return campaignLogsStart(state, action)
        case actionTypes.CAMPAIGN_LOGS_SUCCESS: return campaignLogsSuccess(state, action)
        case actionTypes.CAMPAIGN_LOGS_FAIL: return campaignLogsFail(state, action)
        case actionTypes.CAMPAIGN_LOGS_SET_PAGE: return campaignLogsSetPage(state, action)
        case actionTypes.CAMPAIGN_LOGS_SET_PAGESIZE: return campaignLogsSetPageSize(state, action)
        case actionTypes.CAMPAIGN_LOGS_SET_FILTERSQUERY: return campaignLogsSetFiltersQuery(state, action)
        case actionTypes.CAMPAIGN_LOGS_SET_SORTQUERY: return campaignLogsSetSortQuery(state, action)
        case actionTypes.CAMPAIGN_LOGS_SET_REVERSESORT: return campaignLogsSetReverseSort(state, action)
        case actionTypes.CAMPAIGN_LOGS_UPDATE_SHOWFILTERS: return campaignLogsUpdateShowFilters(state, action)
        default:
            return state
    }
}
export default reducer