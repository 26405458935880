import React, { useEffect, useMemo, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavHeader, NavLogo,LogoContainer, ReduceIcon, NavMenu, NavItem, NavLink, NavFooter, NavIcon, NavBtn,IconShow } from './SmallNavBar.styled';
import logo from '../../assets/images/logoSmallTrans.png';

import SubNav from './SubNav/SubNav';
import { useNavigate, useLocation } from 'react-router-dom';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import SaveIcon from '@mui/icons-material/Save';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { CTooltip } from '../UI/CTooltip/CTooltip'
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { connect } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { NavCounter } from '../NavBar/NavBar.styled';

function SmallNavBar({toggleNav, items,activeLink,userType}) {
    console.log(activeLink)
    const {t} = useTranslation();
    const [activeNav, setActiveNav] = useState(localStorage.getItem('activeNav') ? localStorage.getItem('activeNav') : '');
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const navigate = useNavigate();
    let location = useLocation()

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key].find(p => value.includes(p.path)));
    }
    function getKeyByPath(array, pathValue) {
        for (let index = 0; index < array.length; index++) {
            const item = array[index];
            if(item.subMenu.find(e => pathValue.includes(e.path)))  
                return item.key;
        }
        return null
    }
    //reset active nav when changing route
    useEffect(() => {
        setActiveSubMenu(null);
        let btnItems = items.filter(item => !item?.isLink)
        getKeyByPath(btnItems, location.pathname) ? setActiveNav(getKeyByPath(btnItems, location.pathname)) : setActiveNav('');
    }, [location.pathname, items])

    const navBtnClickHandler = (navTo) => {
        setActiveSubMenu(navTo)
        // const isActive = activeNav === navTo;
        // if(!isActive){
        //     localStorage.setItem('activeNav', navTo);
        //     setActiveNav(navTo);
        //     navigate(menuItems[navTo][0].path);
        // }
    }

  return (
      <>
        <NavBarContainer>
            <NavHeader>

               <LogoContainer>
                    <NavLogo to={{pathname: "/"}}>
                    {userType=="client"?
                                <img src={logo} alt="logo" />:
                                <img src={logo} alt="logo" />
                         }
                       
                    </NavLogo>
                </LogoContainer>
{/* 
                <ReduceIcon to={{pathname: "/"}}>
                        <img src={logo} alt="logo" />

                </ReduceIcon> */}
                <IconShow onClick={toggleNav}>
                <ArrowBackIosNewIcon style={{color:"#2196F3"}} />
                </IconShow>
            </NavHeader>
            <NavMenu>
                {
                    (items && items.length > 0) && items.map((item, index) => {
                        if(item?.isLink)
                            return (
                                <NavItem isActive={activeLink === item.pathname} key={index}>
                                    <CTooltip title={<div><div>{item.label}</div>{item.tooltip ? <div>{item.tooltip}</div> : null}</div>} placement="right">
                                        <NavLink  to={item.pathname} activeclassname="active" >
                                            <NavIcon>
                                            {item.icon}
                                            </NavIcon>
                                            {item.counter !== undefined && <NavCounter style={{position:"absolute", top: "26px", right: "14px", width: "22px"}}>
                                                {item.counter}
                                            </NavCounter>}
                                        </NavLink>
                                    </CTooltip>
                                </NavItem>
                            )
                        else
                            return (
                                <NavItem isActive={activeNav === item.key} key={index}>
                                    <CTooltip title={item.label} placement="right">
                                        <NavBtn isActive={activeNav === item.key} onClick={() => navBtnClickHandler(item.key)}>
                                            <NavIcon>{item.icon}</NavIcon>
                                        </NavBtn>
                                    </CTooltip>
                                    {
                                        activeSubMenu === item.key && <SubNav menuItems={item.subMenu.filter(s => s.show === undefined || s.show)} close={() => setActiveSubMenu(null)} />
                                    }
                                </NavItem>
                            )
                    })
                }
            </NavMenu>
            <NavFooter>
               
                {/* <p>
                    {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)}
                </p> */}
            </NavFooter>
        </NavBarContainer>
      </>
  );
}


const mapStateToProps = state => {
    return {
   
      userType: state.auth.userType,
    
 
    }
  }
  
  const mapDispatchProps = dispatch => {
    return {
     // onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
  }
  export default connect(mapStateToProps, mapDispatchProps)(SmallNavBar);