import React, {useEffect, useState} from 'react';
import Axios from '../../../axios-proas';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { EventsContainer, EventsHeader, EventsTitle, EventsList, EventsItem, EventColor, EventValue, EventDate, EventAction, EventVal, EventMessage, EventFilterItem } from './Events.styled';
import {formatDate, getTimeDate} from '../../../shared/utility'
import { useNavigate} from 'react-router-dom';
import { useTheme } from 'styled-components';
import Spinner from 'react-bootstrap/Spinner'
import EventsFilter from './EventsFilter/EntityFilter';
import { connect } from 'react-redux';
// const eventsList = [
//     {color: theme.colors.success , value: 'Fournisseur 1', date: '5 minuites'},
//     {color: theme.colors.warning, value: 'Fournisseur 2', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Fournisseur 3', date: '5 minuites'},
//     {color: theme.colors.warning, value: 'Fournisseur 4', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Campagne 1', date: '5 minuites'},
//     {color: theme.colors.danger, value: 'Transdev', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Transdev', date: '5 minuites'},
// ]
function Events({userType,profile,userProfile}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [eventsList, setEventsList] = useState([]);
    const [loading , setLoading] = useState(false)
    const [filter, setFilter] = useState(null)
    const [filteredEvents, setFilteredEvents] = useState()
const getEventEntityForManager= () => {
    setLoading(true)
    Axios.get("/user//userEventsForRejetManger").then(res => {
        const eventsData = res.data?.events ? res.data.events : [];
        setEventsList(eventsData);
        setFilteredEvents(eventsData);
    }).catch(res => {
        notyf.error("Une erreur s'est produite!");
    }).finally(() => {
        setLoading(false)
    })
}
    const getEventEntity = () => {
        setLoading(true)
        Axios.get("/user//userEvents").then(res => {
            const eventsData = res.data?.events ? res.data.events : [];
            setEventsList(eventsData);
            setFilteredEvents(eventsData);
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        }).finally(() => {
            setLoading(false)
        })
    }

    const getuserTypeEvents = () => {
        setLoading(true)
        Axios.get("/user//userTypeEvents").then(res => {
            const eventsData = res.data?.events ? res.data.events : [];
            setEventsList(eventsData);
            setFilteredEvents(eventsData);
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if(userType === 'client' || userType === 'supplier')
            getuserTypeEvents()
        else{
            if(profile=="REJECT_MANAGER"){
                getEventEntityForManager()
            }else
            getEventEntity();
        }
         
    }, [userProfile])

    const getInvoiceEventColor = (event) => {
        if(event.hasOwnProperty('data')){
        switch (event?.data.aifeCode) {
            case '206':
            case '208':
                return theme.colors.warning;
            case '207':
            case '210':
            case '213':
                return theme.colors.danger;
            default:
                return theme.colors.success;
        }
    }else
    return theme.colors.success;
    }

    const getEntityEventColor = (event) => {
        switch (event?.type) {
            case "STEP":
                switch (event?.message) {
                    case "INPROGRESS":
                        return theme.colors.warning;
                    case "ACTIVE":
                        return theme.colors.success;
                    default:
                        return theme.colors.warning;
                }
            case "DOCS":
                return theme.colors.warning;
            case "ERROR_FIELD":
                return theme.colors.danger;
            default:
                return theme.colors.warning;
        }
    }

    const getInvoiceEventTitle = (eventTmp) => {
        if(userType === 'client'){
            return eventTmp?.data?.clientName ? eventTmp.data.clientName : eventTmp?.user?.entityName;
        }else{
       return eventTmp?.data?.supplierName ? eventTmp.data.supplierName : (eventTmp?.user?.entityName ? eventTmp.user.entityName : "Entité inconnue");
        }
 
        
    }

    const getInvoiceEventMessage = (event) => {
        if(event.hasOwnProperty('data')){
            return event.data.label;
        }else{
            switch (event?.type) {
                case "USER_CHAT_EVENT":
                    return "Echange par message"
                case "MAIL_EXCHANGE_EVENT":
                    return "Echange par mail";
                case "CALL_EXCHANGE_EVENT":
                    return "Echange par téléphone";
                case "OTHER_EXCHANGE_EVENT":
                    return "Echange";
                case "CALL_OUT_EXCHANGE_EVENT":
                    return "Echange par téléphone";
                case "OTHER_OUT_EXCHANGE_EVENT":
                    return "Echange ";
                case "MAIL_OUT_EXCHANGE_EVENT":
                    return "Echange par mail";
                default:
                    


            }
        }
     
    
        // switch (event?.snapshot) {
        //     case "IMPORTED":
        //         return event?.message ? `Facture [${event?.message}] déposée` : 'Facture déposée';
        //     case "INTEGRATED":
        //         return event?.message ? `Facture [${event?.message}] intégrée` : 'Facture intégrée';
        //     case "ERROR":
        //         return event?.message ? `Facture [${event?.message}] erronée` : 'Facture erronée';
        //     case "PAID":
        //         return event?.message ? `Facture [${event?.message}] encaissée` : 'Facture encaissée';
        //     case "LITIGATION":
        //         return "Litige";
        //     default:
        //         return "";
        // }
    }

    const getEntityEventMessage = (event) => {
        switch (event?.message) {
            case "email":
                return `Champs email erroné`;
            case "INPROGRESS":
                return `Demande de validation`;
            case "ACTIVE":
                return  `Fournisseur activé`;
            case "DOCUMENT":
                return  `Nouveau document ${event.snapshot}`;
            default:
                return "";
        }
    }

    const updateFilterHandler = (newFitler) => {
        let filterTemp = null;
        switch (newFitler) {
            case "invoice":
                if (filter === "entity" ) {
                    filterTemp = null;
                } else if (filter === "invoice" || filter === null) {
                    filterTemp = "entity";
                } 
                break;
            case "entity":
                if (filter === "invoice" ) {
                    filterTemp = null;
                } else if (filter === "entity" || filter === null) {
                    filterTemp = "invoice";
                } 
                break;
            default:
                filterTemp = null;
                break;
        }
        let filteredEventsTemp = filterTemp ? eventsList.filter(event => event?.eventType === filterTemp) : eventsList;
        setFilter(filterTemp)
        setFilteredEvents(filteredEventsTemp)
    }
const navigateToEntity=(uid,type)=>{
    if( type === "DOCS"){
        localStorage.setItem("show", "document");
    }
    navigate("/entity/"+uid)
}

    let eventsJsx = null;

    if(loading) 
        eventsJsx = (<Spinner />)
    else if (filteredEvents && filteredEvents?.length > 0){
        eventsJsx = filteredEvents?.map((eventTmp, index) => {
                var eventJsx = null;
                let isLast = index === 0
                let color = theme.colors.warning;
                let title = "";
                let message = "";
                let date = "";
                let action = null;

                switch (eventTmp?.eventType) {
                    case "invoice":
                        color = getInvoiceEventColor(eventTmp);
                        // title = eventTmp?.data?.supplierName ? eventTmp?.data?.supplierName : eventTmp?.user?.entityName;
                        title = getInvoiceEventTitle(eventTmp);
                        message = getInvoiceEventMessage(eventTmp);
                        date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);
                        action = (eventTmp.hasOwnProperty("data") && eventTmp?.data ) ? (
                            <EventAction   onClick={() => navigate("/invoiceDetail/"+eventTmp?.data?.invoiceUid)}>
                                <span>voir</span>
                                <ArrowForwardIosIcon />
                            </EventAction>
                        ) : (
                            <EventAction   onClick={() => navigate("/invoiceDetail/"+eventTmp?.invoiceUid)}>
                                <span>voir</span>
                                <ArrowForwardIosIcon />
                            </EventAction>
                        );
                        break;
                    case "entity":
                        color = getEntityEventColor(eventTmp);
                        title = eventTmp?.name;
                        message = getEntityEventMessage(eventTmp);
                        date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);
                        
                        action = (
                            <EventAction   onClick={() => navigateToEntity(eventTmp.entityUid,eventTmp.type)}>
                                <span>voir</span>
                                <ArrowForwardIosIcon />
                            </EventAction>
                        );
                        break;
                    default:
                        break;
                }  
               return (
                    <EventsItem key={index}>
                        <EventColor color={color} />
                        <EventValue>
                            <EventVal>
                                {title}
                            </EventVal>
                            <EventMessage title={message} >
                                {message}
                            </EventMessage>
                            <EventDate>
                                {date}
                            </EventDate>
                        </EventValue>
                        {action}
                    </EventsItem>
               )
            })
    }
  return (
      <>
        <EventsContainer>
            <EventsHeader>
                <EventsTitle>
                    Évènements
                </EventsTitle>
                <EventsFilter
                    filter={filter}
                    updateFilter={updateFilterHandler}
                />
            </EventsHeader>
            <EventsList>
                {
                    eventsJsx
                }
            </EventsList>  
        </EventsContainer>
      </>
  );
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    login : state.auth.login,
  profile : state.auth.profile,
  })
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (Events)
