import React from 'react'
import { showColumn, formatDate, getTimeDate } from '../../../shared/utility'
import { TD, TR } from '../../../styles/Table.styled';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableBody(props) {
    const {unknownInvoiceData, columns, selectedRows, setSelectedRows, setShowClearModal, unknownInvoicesArchived} = props;
    const {t} = useTranslation();

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }
   
    return (
        <>
            {
                unknownInvoiceData ? unknownInvoiceData.map(row => (
                    <TR key={row.id} style={{cursor: "default"}}>
                        <TD>
                            <input type="checkbox" name={row?.uid} onClick={e => e.stopPropagation()} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row?.uid)}  className="form-check-input"  id="exampleCheck1" />
                        </TD>
                        <CTooltip title={row.suCode}><TD style={{display: !showColumn(columns,"suCode") && 'none'}}>
                            {row.suCode}
                        </TD></CTooltip>
                        <CTooltip title={row.supplierCode}><TD style={{display: !showColumn(columns,"supplierCode") && 'none'}}>
                            {row.supplierCode}
                        </TD></CTooltip>
                        <CTooltip title={row.contractRef}><TD style={{display: !showColumn(columns,"contractRef") && 'none'}}>
                            {row.contractRef}
                        </TD></CTooltip>
                        <CTooltip title={row.number}><TD style={{display: !showColumn(columns,"number") && 'none'}}>
                            {row.number}
                        </TD></CTooltip>
                        <CTooltip title={row.xmlFileName}><TD style={{display: !showColumn(columns,"xmlFileName") && 'none'}}>
                            {row.xmlFileName}
                        </TD></CTooltip>
                        <CTooltip title={row.statusCode}><TD style={{display: !showColumn(columns,"statusCode") && 'none'}}>
                            {row.statusCode}
                        </TD></CTooltip>
                        <CTooltip title={formatDate(+row.statusDate) + " " + getTimeDate(+row.statusDate)}><TD style={{display: !showColumn(columns,"statusDate") && 'none'}}>
                            {formatDate(+row.statusDate) + " " + getTimeDate(+row.statusDate)}
                        </TD></CTooltip>
                        <CTooltip title={formatDate(+row.dueDate)}><TD style={{display: !showColumn(columns,"dueDate") && 'none'}}>
                            {formatDate(+row.dueDate)}
                        </TD></CTooltip>
                        {!unknownInvoicesArchived && <TD>
                            <CTooltip title="Cliquer pour solder la ligne. Elle disparaitra du tableau"><CheckIcon onClick={() => setShowClearModal(row.uid)} style={{cursor: "pointer"}} /></CTooltip>
                        </TD>}
                    </TR>
                )) : null
            }
        </>
    )
}

export default TableBody

