import styled from 'styled-components/macro';

export const InfoBandPanel = styled.div`
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    max-height: 180px;
    overflow: auto;
`

export const InfoBandPanelHeading = styled.div`
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-bottom: 5px;
    color: white;
    background-color:${({couleur}) => couleur?couleur:"white"};
    display: flex;
`

export const InfoBandPanelTitle = styled.h3`
    margin: 8px;
    width: 97%;
    font-size: 16px;
    color: inherit;
    display : flex;
`
export const Cleardiv = styled.div`

    display: flex;
`
