import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    quickFilter: null,
    sortQuery: 'creationDate',
    reverse: {
        name: false
    },
    selectedRows: null,
    selectedAllRows: false
}

const accountingAuditStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const accountingAuditSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false
})

const accountingAuditFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const accountingAuditSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage

})
const accountingAuditSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const accountingAuditSetSortQuery = (state, action) => updateObject(state,{
    sortQuery: action.sortQuery
})

const accountingAuditSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: {...action.reverse}
    }
}

const accountingAuditUpdateShowFilters = (state, action) => {
    return updateObject(state, {showFilters: action.showFilters})
}

const accountingAuditSetFiltersQuery = (state, action) => updateObject(state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)

const accountingAuditSetQuickFilter = (state, action) => updateObject(state, {
    quickFilter : action.quickFilter
})

const accountingSelectRow = (state, action) => {
    const newSelectedRows = {...state.selectedRows, [action.rowId]: action.selected}
    return updateObject(state, {
        selectedRows: newSelectedRows,
        selectedAllRows: !action.selected ? false : state.selectedAllRows
    })
}

const accountingSelectAllRows = (state, action) => {
    const data = state.data;
    let newSelectedRows = {}
    data?.filter(row => row.id).map(row => {
        newSelectedRows = {...newSelectedRows, [row.id]: action.selectedAll}
    })
    return updateObject(state, {
        selectedAllRows: action.selectedAll,
        selectedRows: newSelectedRows
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.ACCOUNTING_AUDIT_START: return accountingAuditStart(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SUCCESS: return accountingAuditSuccess(state, action)
        case actionTypes.ACCOUNTING_AUDIT_FAIL: return accountingAuditFail(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SET_PAGE: return accountingAuditSetPage(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SET_PAGESIZE: return accountingAuditSetPageSize(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SET_FILTERSQUERY: return accountingAuditSetFiltersQuery(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SET_SORTQUERY: return accountingAuditSetSortQuery(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SET_REVERSESORT: return accountingAuditSetReverseSort(state, action)
        case actionTypes.ACCOUNTING_AUDIT_UPDATE_SHOWFILTERS: return accountingAuditUpdateShowFilters(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SET_QUICKFILTER: return accountingAuditSetQuickFilter(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SELECT_ROW: return accountingSelectRow(state, action)
        case actionTypes.ACCOUNTING_AUDIT_SELECT_ALL_ROWS: return accountingSelectAllRows(state, action)
        default:
            return state
    }
}
export default reducer