import styled from 'styled-components/macro';
export const ListContainer = styled.div`
display: flex;
    width: 100%;
    justify-content: end;`

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    min-width: 30%;

    height: fit-content;
    border: 1px solid #D9E1E7CC;
    border-radius: 16px;
    padding: 1rem 2rem;
    margin-bottom: 0.5rem;
    min-height: 160px;

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        padding: 1rem 1rem;
    }
    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: 100%;
    }
`
export const CardSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: fit-content;
    height: auto;
    border: 1px solid #D9E1E7CC;
    border-radius: 16px;
    padding: 1rem 2rem;
    margin-bottom: 0.5rem;

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        padding: 1rem 1rem;
    }
    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: 100%;
    }
`

export const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const CardInfos = styled.div`
    display: flex; 
    width: 100%;
    margin-bottom: 0.5rem;
`

export const CardIcon = styled.div`
    margin: 0 1rem;
`

export const CardPercentage = styled.div`
    display: flex;
    align-items: center;
    color: ${({isIncreasing, theme}) => isIncreasing ? theme.colors.success : theme.colors.danger};
`

export const DPercentage= styled.div`
      display: flex;
      width: 100%;
      justify-content: end;
   
`
export const CardPercentageIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    border-radius: 50%;
    transform: rotate(45deg);
    background-color: ${({isIncreasing, theme}) => isIncreasing ? theme.colors.greenLight : theme.colors.redLight};
`

export const CardPercentageValue = styled.h4`
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0 0 0 0.5rem;

    & span {
        margin-left: 00.25rem;
    }
`

export const CardTitle = styled.h3`
    font-size: 1.2rem;
    font-weight: bold;
    color: ${({theme}) => theme.colors.secondary};
`

export const CardBody = styled.div`
    display: flex;
    width: 100%;
`

export const CardValue = styled.h2`
    font-size: 1.1rem;
    color: ${({theme}) => theme.colors.text};
    margin: 0;
`

export const SelectFilter = styled.select`
    width: 70px;
    height: 100%;
    border: none;
    border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    outline: none;
    color: black;
    font-size: 0.9rem;
    background-color: #FFFFFF;
    cursor: pointer;

    &:hover {
        border-bottom: 2px solid ${({theme}) => "#FF0000"};
    }
`

export const Option = styled.option`
    width: 100%;
`