import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner'

// okCallback DOIT RETOURNER UNE PROMISE
function CustomConfirmModal({handleClose, show, text, okCallback, koCallback, loading}) {
    const {t} = useTranslation();

    const confirmClickHandler = () => {         
        okCallback().finally(() => {
            handleClose();
        })
    }

    const refuseClickHandler = () => {
        if (koCallback)
            koCallback().finally(() => {
                handleClose();
            })
    }

    const closeModal = () => {
        if(!loading){
            handleClose()
        }
    }

    return (
        <>
            <Modal show={show} onHide={closeModal} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('global:alert', "Alert")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                        {text}
                        
                    </p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    {t('global:cancel', "Cancel")} 
                </Button>
                {
                    loading ? 
                        <Spinner animation="border" variant="primary" />
                    :
                        <>
                            {koCallback && <Button variant="danger" 
                                onClick={() => refuseClickHandler()}>
                                Refuser
                            </Button>}<Button variant="danger" 
                                style={{color:"rgb(245, 79, 79)", backgroundColor:"white"}} 
                                onClick={() => confirmClickHandler()}>                     
                                {t('global:confirm', "Confirm")}
                            </Button>
                        </>
                }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CustomConfirmModal
