import React, { useCallback, useEffect, useState, useRef, useMemo } from "react"
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { BtnIcon, BtnOutlineAction, BtnOutlineLink , SearchInput, SearchInputContainer  } from "../../styles/Common";
import { CampBody, CampContainer, CampHeader, CampHeaderActions, CampHeaderTitle, CampHeaderWrapper, CampListWrapper } from "./InfoBands.styled";
import { NavTabsLi, NavTabsLink, NavTabsUl } from "../Entity/Entity.styled";
import CampaignItem from "../../components/Bandeau/BandeauItem/BandeauItem";
import { Spinner } from "react-bootstrap";
import { NoContent, SpinnerContainer } from "../../styles/Table.styled";
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import SearchIcon from '@mui/icons-material/Search';

function InfoBands(props) {
    const {
        loading,
        bandeauxData,
        count,
        currentPage,
        pageSize,
        setBandeauPageSize,
        getBandeauPage,
        setBandeauPage,
        sortQuery,
        reverse,
        filtersQuery,
        setBandeauFilters,
    } = props;
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState("all");
    
    const tabsList = useMemo(() => [
        {title: t("campaignList:scduled", "Tous"), value: "all"},
        {title: t("campaignList:scheduled", "Programmés"), value: "scheduled"},
        {title: t("infobandsList:inProgress", "En cours"), value: "inProgress"},
        {title: t("infobandsList:completed", "Terminés"), value: "completed"},
    ], [t]);
    
    
    useEffect(() => {
        getBandeau()
        //getCampaignsPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, pageSize])

    const getBandeau=()=>{
        getBandeauPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }
    const handlePageChange = useCallback((newPage) => {
        setBandeauPage(newPage);
    }, [currentPage]);

    const tabChangeHandler = (newValue) => {
        setActiveTab(newValue);
        let newFilters = {
            ...filtersQuery,
            status : newValue !== "all" ? newValue : null
        }
        setBandeauFilters(newFilters);
        handleApplyFiltering(newFilters);
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filtersQuery,
            [key]: e.target.value
        }
        setBandeauFilters(newFilters);
        handleApplyFiltering(newFilters);
    }
    const handleApplyFiltering = useCallback((filters) => {
        getBandeauPage(1,filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
        ) : <NoContent>{t("infobandsList:noBand", "Aucun bandeau informatif à afficher")}</NoContent>;


    if(!loading && bandeauxData?.length > 0){
        tSpinner = null;
        tBody = ( 
            <CampListWrapper>
                {
                    bandeauxData?.length > 0 ? bandeauxData.map((bandeau, index) => (
                        <CampaignItem
                        bandeau={bandeau}
                        getBandeau={getBandeau}
                            
                        />
                    )) : null
                }
            </CampListWrapper>
        )
    }
    return (
        <>
            <CampContainer>
                <TitleHelmet title={"Transdev | " + t("menu:infobands", "Bandeaux informatifs")} />
                <CampHeaderWrapper>
                    <CampHeader>
                        <CampHeaderActions>
                        <SearchInputContainer >
                        <SearchInput
                        style={{padding:"0.3rem"}}
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="title"
                            value={filtersQuery?.title || ''} 
                            onChange={(e) => inputChangeHandler(e)}
                            
                        />
                        <SearchIcon/>
                    </SearchInputContainer>

                            <BtnOutlineLink
                                color="black"
                                to="/newInfoband"
                            >
                                <span>Nouveau bandeau</span> 
                                <BtnIcon>
                                    <AddIcon fontSize="small" />
                                </BtnIcon>
                            </BtnOutlineLink>
                        </CampHeaderActions>
                    </CampHeader>

                    <NavTabsUl>
                        {
                            tabsList.map((tab, index) => {
                                return (
                                    <NavTabsLi key={index}>
                                        <NavTabsLink 
                                            onClick={() => tabChangeHandler(tab.value)}
                                            active={tab.value === activeTab}
                                            to={`#${tab.value}`}
                                        >
                                            {tab.title}
                                        </NavTabsLink>
                                    </NavTabsLi>
                                )
                            })
                        }
                    </NavTabsUl>
                </CampHeaderWrapper>

                <CampBody>
                    <TablePagination
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setBandeauPageSize}
                    />
                    {tBody}
                    {tSpinner}
                    <TablePagination
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setBandeauPageSize}
                    />
                </CampBody>
               
            </CampContainer>
           
        </>
    )
}

const mapStateToProps = (state) => ({
     loading: state.bandeau.loading,
    bandeauxData: state.bandeau.data,
    count: state.bandeau.count,
    currentPage: state.bandeau.currentPage,
    pageSize: state.bandeau.pageSize,
    sortQuery: state.bandeau.sortQuery,
    reverse: state.bandeau.reverse,
    filtersQuery: state.bandeau.filtersQuery,
    showFilters: state.bandeau.showFilters,
    userRole: state.auth.role
})
const mapDispatchToProps = dispatch => ({
    getBandeauPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.bandeauGetPage(page, filters, sort, reverse, pageSize)),
    setBandeauPage: (page) => dispatch(actions.bandeauSetPage(page)),
    setBandeauPageSize: (pageSize) => dispatch(actions.bandeauSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.bandeauSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.bandeauSetReverseSort(reverseS)),
    setBandeauFilters: (filters) => dispatch(actions.bandeauSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.bandeauUpdateShowFilters(show)),
})
export default connect(mapStateToProps, mapDispatchToProps) (InfoBands)

