import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import { useNavigate, useLocation, useParams } from "react-router-dom";

function TableActions(props) {
    const {resetFilters, setShowFilters, showFilters, userLevel,showRegroupment,userType} = props;
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <TAContainer>
            <TableAction title="Réinitialiser les filtres" onClick={() => resetFilters()}>
                {/* <span>{t("global:Rest")} </span> */}
                <ReplayIcon />
            </TableAction>
            <TableAction active={showFilters} onClick={() => setShowFilters(!showFilters)}>
                <span>{t("global:filters")} </span>
                <FilterListIcon />
            </TableAction>
            {userLevel === "admin" && userType === "owner" && 
                <TableAction  onClick={() => navigate("/newGrouping")}>
                    <span>{"Ajouter un regroupement"} </span>  
                    <AddIcon />   
                </TableAction>
             }
            {/* {userLevel === "admin" && <TableActionLink 
                color={theme.colors.primary} 
                border={theme.colors.primary} 
                to="/entity"
                state={{
                    entityFilter: "owner"
                }} 
            >
                <span>{t('tableAction:add',`Ajouter`)}</span>
                <AddIcon />
            </TableActionLink>} */}
        </TAContainer>
    )
}

export default TableActions
