import React, { useCallback, useState } from 'react'
import { formatDate,showColumn } from '../../../shared/utility';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';

import { useTranslation } from 'react-i18next';
import DateFilter from '../../UI/DateFilter/DateFilter';
import AmountFilter from '../../UI/AmountFilter/AmountFilter';
import Axios from '../../../axios-proas'
function TableFilters(props) {
    const {t} = useTranslation();
    const {showFilters, reportingFilters, filtersChange,columns,amountBTTotal,statuses} = props;
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const [amountAT, setAmountAT] = useState(null)
    const [rangeShow, setRangeShow] = useState(false);
    const [dateInputValue,setDateInputValue] = useState({
        creationDate: reportingFilters?.creationDate ? convertDate(reportingFilters.creationDate) : ''
    });

    function convertDate (date){
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    const closeModalHandler = () => {
        setShow(false);
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...reportingFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
       // applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...reportingFilters,
            [field]: null
        }
        filtersChange(newFilters)
       // applyFilters(newFilters);
    }

    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...reportingFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
       // applyFilters(newFilters)
    }

    const getCreationdate = () => {
        return reportingFilters?.statusDate ? convertDate(reportingFilters.statusDate) : [];
    }
    const getInvoicingEndDate = () => {
        return reportingFilters?.invoicingEndDate ? convertDate(reportingFilters.invoicingEndDate) : [];
    }
    const getInvoicingStartDate  = () => {
        return reportingFilters?.invoicingStartDate ? convertDate(reportingFilters.invoicingStartDate) : [];
    }
 // exclusiveTaxAmountTotal invoicingEndDate invoicingStartDate  type issuingDate'
 const amountAtChanged = (startval, endval) => {
     console.log("cvghvhvb")
    setAmountAT({
        start: startval,
        end: endval
    })
    let newFilters = {
        ...reportingFilters,
        exclusiveTaxAmountTotal: startval + ':' + endval
    };
    filtersChange(newFilters);
    //applyFilters(newFilters);
}

const closeAmountModal = () => {
    setRangeShow(false);
}
const rangeATModalHandler = () =>{
    setRangeShow(true);
    getAmountATValues();
}

const getAmountATValues = () => {
    Axios.get("ereporting//amountATValuesReporting").then(response => {
        setAmountAT({
          start: +response.data.minAmountAT,
           end: +response.data.maxAmountAT
     })
  })

}
const getAmountAT = (field) => {
    if(reportingFilters?.exclusiveTaxAmountTotal){
        let amount = reportingFilters?.exclusiveTaxAmountTotal.split(":");
        if (field === "start")
            return amount[0]
        else return amount[1]  
    }else{
        return '';
    }   
}
const amountATReset = () => {
    let newFilters = {
        ...reportingFilters,
        exclusiveTaxAmountTotal: null
    };
    filtersChange(newFilters);   
    //applyFilters(newFilters);
}
    return (
        <>
            <tr className="tableFilters" style={{display: showFilters ? 'table-row' : 'none'}}>
                
              
                <TD style={{display: !showColumn(columns,"statusDate") && 'none'}}>
                <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationdate()[0] || ''}
                            to={getCreationdate()[1] || ''}
                            name="statusDate"
                            activeReset={reportingFilters?.statusDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.statusDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"supplierName") && 'none'}}>
           
                            <FilterContainer>
                                <InputFilter 
                                    placeholder={t("invoiceList:supplier", "Fournisseur")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={reportingFilters?.supplierName || ''} 
                                    name="supplierName" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                      
                </TD>
                <TD style={{display: !showColumn(columns,"invoiceNumber") && 'none'}}>
                <FilterContainer>
                                <InputFilter 
                                    placeholder={t("Numéro facture")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={reportingFilters?.Numerofacture || ''} 
                                    name="Numerofacture" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                        
                </TD>
                <TD style={{display: !showColumn(columns,"supplierCode") && 'none'}}>
                <FilterContainer>
                                <InputFilter 
                                    placeholder={t("Code tiers")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={reportingFilters?.supplierCode || ''} 
                                    name="supplierCode" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"clientName") && 'none'}}>
                <FilterContainer>
                                <InputFilter 
                                    placeholder={t("Nom entité")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={reportingFilters?.clientName || ''} 
                                    name="clientName" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"clientCode") && 'none'}}>
                <FilterContainer>
                                <InputFilter 
                                    placeholder={t("Code entité")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={reportingFilters?.clientCode || ''} 
                                    name="clientCode" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                </TD>
                <TD>
                <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={reportingFilters?.status || ''} 
                        name="status" 
                        >
                        <Option value="">Statut</Option>
                        {statuses?.concat([{id: "err51", label: "Doublon"}, {id: "err52", label: "Faux doublon"}]).map(s => <Option value={s.id}>{s.label}</Option>)}
                    </SelectFilter>
                    </FilterContainer>
                </TD>
                
            </tr>
        </>
    )
}

export default TableFilters
