import React from 'react'
import { TBody, Table, TH, TR, TD, THContainer } from '../../../styles/Table.styled'
import { EntContainer, EntDivContainer, THead, TitleDiv } from './AdminInfoBands.styled'
import { formatDate } from '../../../shared/utility'
import { CTooltip } from '../../UI/CTooltip/CTooltip'

function AdminInfoBands({infoBands}) {
  return (infoBands?.length > 0 ? <EntDivContainer>
        <TitleDiv>
            <span>Bandeaux en cours</span>
        </TitleDiv>
        <EntContainer>
            <Table>
                <THead>
                    <tr>
                        <TH scope="col" width={"55%"}>
                            <THContainer>
                                Titre
                            </THContainer>
                        </TH>
                        <TH scope="col" width={"15%"}>
                            <THContainer>
                                Date de début
                            </THContainer>
                        </TH>
                        <TH scope="col" width={"15%"}>
                            <THContainer>
                                Date de fin
                            </THContainer>
                        </TH>
                        <TH scope="col" width={"15%"}>
                            <THContainer>
                                Nombre de lectures
                            </THContainer>
                        </TH>
                    </tr>
                </THead>
                <TBody>
                    {infoBands?.filter(x=>x.read==false).map(infoBand => {
                        return (
                        <CTooltip key={infoBand.id} title={infoBand.contenu || ""}>
                            <TR style={{cursor: "default"}}>
                                <TD>{infoBand.title}</TD>
                                <TD>{formatDate(+infoBand.startDate)}</TD>
                                <TD>{formatDate(+infoBand.endDate)}</TD>
                                <TD>{infoBand.readTotal}</TD>
                            </TR>
                        </CTooltip>
                        )
                    })
                    }
                </TBody>
            </Table>
        </EntContainer>
    </EntDivContainer> : null)
}

export default AdminInfoBands