 
import React, {useState,useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink } from '../../../Extraction/ExtractionItem/ExtractionItem.styled';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { CTooltip } from '../../../UI/CTooltip/CTooltip';
import SaveIcon from '@mui/icons-material/Save';
import ConfirmDelete from './DeleteCategoryModal/ConfirmDelete';
import './CategoryModal.css'

function CategoryModal({show, modalClosed, title, message, confirm, cancel, categories, addCategoriesHandle, deleteCategoryHandle, saveCategoryHandle, setCategoriesUpdates}) {

    const {t} = useTranslation();
    const [categoryName, setCategoryName] = useState("")
    const [categoriesLocal, setCategoriesLocal] = useState(null) 
    const [categoryId2Delete, setCategoryId2Delete] = useState(null) 
    const [showModal,setShowModal]=useState(false)
    const [showError, setShowError] = useState(false)
    
    useEffect(() => { 
        setCategoriesLocal( JSON.parse(JSON.stringify(categories))) 
        setCategoryName("")
    }, [categories]) 

    const changeHandle = (e, category,i) => { 
        let categoriesLocalTemp = [...categoriesLocal]
        categoriesLocalTemp[i].name =  e.target.value 
        setCategoriesLocal(categoriesLocalTemp); 
    }

    const deleteHandle = (id) => {
        setCategoryId2Delete(id)
        setShowModal(true)
    }

    const addCategorie = (category) => {
        if(category !== ""){
            addCategoriesHandle(category)
        }else 
            setShowError(true)
    } 

    return (
        <>
            <Modal show={show} onHide={modalClosed} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <div>
                        <div>
                            <div class="input-group mb-3">
                                <input type="text" placeholder="Nom catégorie *" className="form-control" value ={categoryName} onChange={(e) => {setCategoryName(e.target.value);setShowError(false)}}/>
                                <CTooltip title="Sauvegarder"   style={{  fontSize: "20px"}}>
                                <AddIcon onClick={(e) => addCategorie(categoryName)} className="addCatBotton"/>
                                </CTooltip>
                            </div>
                        </div>
                        
                        {
                            showError && 
                                <div> 
                                    <p style={{marginLeft:"10px", fontSize: "12px",color:"red"}}>Veuillez saisir un nom de catégorie</p>
                                </div>
                        }

                        <div className="container">
                            {
                                categoriesLocal?.length > 0 ? 
                                    categoriesLocal?.map((category,i) =>  {
                                        return <div className="categoryElm" key={category.id}>    
                                                <input type="text" className="form-control categoryLineInput" name="name" 
                                                    value={category?.name} onChange={e =>changeHandle(e, category,i)}/>
                                                <div>
                                                { 
                                                    categories.find(c => c.id === category.id && c.name === category.name) === undefined && 
                                                    <CTooltip title="Sauvegarder" style={{fontSize:"20px"}}>
                                                        <SaveIcon className="saveCatBotton" onClick={e => saveCategoryHandle(category)}/>
                                                    </CTooltip>  
                                                }
                                                {   +category?.nbReferences == 0 &&
                                                    <CTooltip title="Supprimer" style={{fontSize: "20px"}}>                                                        
                                                        <ClearIcon className="deleteCategory" onClick={e => deleteHandle(category?.id)}/>
                                                    </CTooltip> 
                                                }
                                                </div>
                                               </div>
                                    })
                                : "Aucune catégorie à afficher"
                            }
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            {
                showModal?(
                    <ConfirmDelete handleClose={()=>setShowModal(false)}
                    show={showModal}
                    item={categoryId2Delete}
                    deleteCategoryHandler={deleteCategoryHandle}
                    />
                ):null
            }
        </>
    )
}

export default CategoryModal

