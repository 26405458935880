import React, { useEffect, useMemo, useState } from 'react';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle } from './ExtractionItem.styled';
//en premiere
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Spinner from 'react-bootstrap/Spinner'
//en derniere 

import {BtnOutlineLink} from '../../../styles/Common';
import ConfirmDelete from '../ExtractionModal/ConfirmDelete';
import Axios from '../../../axios-proas'
import {getLabelWithKey,getNotyfObject} from '../../../shared/utility';
function ExtractionItem({data,deleteExtraction,setCursor,cursor, refreshItem}) {
    const [showModal,setShowModal]=useState(false)
    const [refreshInterval, setRefreshInterval]=useState(null)
    const statutExtraction=useMemo(()=>[
        "ERROR",
        "PROGRESS",
        "WAITING"
    ],[]);
    let notyf = getNotyfObject();
    // console.log("campagnItem")

    // const formatCombinedDate = (date) => {
    //     if(date){
    //         const [start, end] = date.split(':')
    //         let startDate = formatDate(+start)
    //         let endDate = formatDate(+end)
    //         return `${startDate}-${endDate}`
    //     }else 
    //         return ''
    // }

    useEffect(() => {
        clearInterval(refreshInterval);
        if (data.status !== "DONE")
            setRefreshInterval(setInterval(() => {
                refreshItem(data.uid)
            }, 1000))
    }, [data.status])

    const formatCombinedDateStart= (date) => {
        if(date){
            const [start, end] = date.split(':')
            let startDate =+start
           
            return startDate
        }else 
            return ''
    }
    const formatCombinedDateEnd = (date) => {
        if(date){
            const [start, end] = date.split(':')
           // let startDate = formatDate(+start)
            let endDate = +end
            return endDate
        }else   
            return ''
    }
  
    const downloadExtraction =() => {
        setCursor("wait")
        if (data.uid != null) {
          Axios.get("extraction/" + data.uid + "/DownloadextractionByUid",{
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
          })
            .then((response) => {
                if(response.status === 200){
                    let blob = new Blob([response.data], { type: 'application/zip' })
                    const downloadUrl = URL.createObjectURL(blob)
                    let a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = "extraction.zip";
                    document.body.appendChild(a);
                    a.click();
                    setCursor("pointer")
                }else {
                    console.log("Archive vide ou non valide");
                    notyf.error("Une erreur s'est produite")
                    setCursor("pointer")
                }
            })
            .catch((response) => {
                console.log("Une erreur s'est produite");
                notyf.error("Une erreur s'est produite")
                setCursor("pointer")
            });
        }
    }
    // const deleteExtraction =(item)=>{
    //     console.log(item)
    //     Axios.delete('extraction/'+item+'/deleteExtractionByUid').then(response => {
            
    //     }).catch(response => {
    //         notyf.error("Une erreur s'est produite")
    //     })

    // }

  
    return (
        <CIContainer cursor={cursor}>
            <div style={{display:"flex",width: "600PX",justifyContent: "space-between"}}>
            <CITitle>
                Extraction : {(data?.titel)} 
            </CITitle>
            <CITitle>
               {getLabelWithKey(data?.status)}
            </CITitle>
            </div>
          
            <CIContent>
                <CIData>
                        <DateProgressLine
                            statut={data?.status}
                            titel={data?.titel}
                            creationDate={+data?.creationDate}
                            campaignDate={formatCombinedDateStart(data?.issuingDate)}
                            endDate={formatCombinedDateEnd(data?.issuingDate)}
                        />
                     
                </CIData>
                <CIActions>
                    <Spinner/>
                    {statutExtraction.includes(data.status)?( <Spinner  animation="border" role="status"style={{color:"#EE5A5A66"}}/>):(
                      
                        <CIDeleteBtn
                        cursor={cursor}
                        color="#1c80d1" 
                        border="#1c80d1"
                        onClick={() => downloadExtraction()} >
                        Télécharger
                    </CIDeleteBtn>
                    )}
                    
                    
                    <CIDeleteBtn onClick={() => setShowModal(true)}
                                color="#ff0000" 
                                border="#ff0000" >
                        Supprimer
                    </CIDeleteBtn>

                    {
                        showModal?(
                            <ConfirmDelete handleClose={()=>setShowModal(false)} 
                                           show={showModal}
                                           item={data?.uid}
                                           deleteExtraction={deleteExtraction}/>
                        ):null
                    }
                    <BtnOutlineLink
                        style={{height: "25px"}}
                        color="#06152B"
                        border="#06152B"
                        to={"/extractionForm/"+data.uid}
                        state= {{extractionData: data}}
                        >
                        Modifier  <ArrowRightIcon/>
                    
                    </BtnOutlineLink>
                 

                    
                </CIActions>
            </CIContent>
        </CIContainer>
    );
}

export default ExtractionItem;
