import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    sortQuery: 'title',
    reverse: {
        title: false
    }
}

const regroupementStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const regroupementSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false
})

const regroupementFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const regroupementSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const regroupementSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const regroupementSetSortQuery = (state, action) => updateObject(state,{
    sortQuery: action.sortQuery
})

const regroupementSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: {...action.reverse}
    }
}

const regroupementUpdateShowFilters = (state, action) => {
    return updateObject(state, {showFilters: action.showFilters})
}

const regroupementSetFiltersQuery = (state, action) => updateObject(state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)

const reducer = (state = initialState, action) => {
    switch(action.type) { 
        case actionTypes.REGROUPEMENT_START: return regroupementStart(state, action)
        case actionTypes.REGROUPEMENT_SUCCESS: return regroupementSuccess(state, action)
        case actionTypes.REGROUPEMENT_FAIL: return regroupementFail(state, action)
        case actionTypes.REGROUPEMENT_SET_PAGE: return regroupementSetPage(state, action)
        case actionTypes.REGROUPEMENT_SET_PAGESIZE: return regroupementSetPageSize(state, action)
        case actionTypes.REGROUPEMENT_SET_FILTERSQUERY: return regroupementSetFiltersQuery(state, action)
        case actionTypes.REGROUPEMENT_SET_SORTQUERY: return regroupementSetSortQuery(state, action)
        case actionTypes.REGROUPEMENT_SET_REVERSESORT: return regroupementSetReverseSort(state, action)
        case actionTypes.REGROUPEMENT_UPDATE_SHOWFILTERS: return regroupementUpdateShowFilters(state, action)
        default:
            return state
    }
}
export default reducer