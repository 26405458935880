import React, { useEffect, useState } from 'react';
import Axios from '../../axios-proas'
import DescriptionIcon from '@mui/icons-material/Description';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';
import Select from '../../components/UI/Select/Select';
import Card from '../../components/Home/Card/CardSupplier';
import { getNotyfObject, formatDateEur, formatDate } from '../../shared/utility';
import { useNavigate } from 'react-router-dom';
import { HomeSBody, HomeSCards, HomeSContainer, HomeSHeader, HomeSStats } from './HomeSupplier.styled';
import { HeaderAction } from '../../styles/Common';
import InvoicesPerStatus from '../../components/HomeSupplier/InvoicesPerStatus/InvoicesPerStatus';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FAQuestions from './FAQuestions/FAQuestions'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InfoBand from '../../components/InfoBand/InfoBand';
import DateRange from '../../components/UI/DateRange/DateRange';
import * as actions from '../../store/actions/index';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';

function HomeSupplier({userType, statuses, userProfile, bandeaux, updateBandeaux}) {
    const {t} = useTranslation();
    const notyf = getNotyfObject()
    const navigate = useNavigate();
    const [period, setPeriod] = useState('');
    const [categoriesFilter, setCategoriesFilter] = useState("");
    const [upCategories, setUpCategories] = useState(false);
    const [questionsResponses, setQuestionsResponses] = useState()
    const [sentInvoicesCount, setSentInvoicesCount] = useState(0);
    const [uploadedDocsCount, setUploadedDocsCount] = useState(0);
    const [invoicedEntitiesCount, setInvoicedEntitiesCount] = useState(0);
    const [paidInvoices, setPaidInvoices] = useState(0);
    const [notPaidInvoices , setNotPaidInvoices] = useState(0);
    const [litigationInvoices, setLitigationInvoices] = useState(0);
    const [showDateRange, setShowDateRange] = useState(false);
    const [listpaidInvoices, setListpaidInvoices] = useState(false);
    const [listnotPaidInvoices , setListnotPaidInvoices] = useState(false);
    const [listlitigationInvoices, setListlitigationInvoices] = useState(false);
    const [listsentInvoicesCount, setListsentInvoicesCount] = useState(false);
    const defaultTime = new Date();
    defaultTime.setFullYear(defaultTime.getFullYear()-1);
    const [startDate , setStartDate] = useState(formatDateEur(defaultTime.getTime()));
    const [endDate , setEndDate] = useState(formatDateEur(new Date().getTime()));
    const [value, setValue] = useState('annee');
    const [value2, setValue2] = useState('');
    const [bandeauxState, setBandeauxState] = useState([]);
    const [entityForUserSupplier, setEntityForUserSupplier] = useState([{label: '-- '+t("home:chooseEntity", "Select an entity")+' --', value: ''}]);
    const options = [
        {label: t("home:thisYear", 'This year'), value: 'annee'},
        {label: t("home:thisMonth", 'This month'), value: 'mois'},
        {label: t("global:other", 'Other'), value: 'other'}
    ];
    
    const interval=(date)=>{
        return formatDateEur(date)
    }

    const selectChange = (value) => {
        const Landing = new Date();
        const dateNow=Landing.getTime();
        setValue(value);
        switch (value) {
            case "annee":
                Landing.setFullYear(Landing.getFullYear()-1);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            case "mois":
                Landing.setDate(Landing.getDate()-30);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            case "other":
                setShowDateRange(true)
                break;
            default:
                break;
        }
    }

    const selectChangeSupplier = (value) => {
        setValue2(value);
    }

    const dateChangeHandler = (date) => {
        setStartDate(interval(date.startDate.getTime()))
        setEndDate(interval(date.endDate.getTime()))
    }

    const formatDateFr = date => {
        return formatDate(new Date(date));
    }

    const getQuestionsCategories = (filter) => {
        Axios.get("/questioncategory//categories", {params:{filter: filter}}
        ).then((response) => {
            setQuestionsResponses(response?.data?.categories)
            response?.data?.categories?.forEach(c => {
                const button = document.getElementById("collapseButton"+c.id);
                const cat = document.getElementById("collapse"+c.id);
                if (categoriesFilter) {
                    button?.classList.remove("collapsed");
                    cat?.classList.add("show");
                } else {
                    button?.classList.add("collapsed");
                    cat?.classList.remove("show");
                }
            })
        }).catch(res => {
          notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    };

    const inputChangeHandler = (value) => {
        setCategoriesFilter(value)
        setUpCategories(!upCategories)
    }
    
    useEffect(() => {   
        getQuestionsCategories(categoriesFilter)
    }, [upCategories])

    useEffect(() => {
        getAllBandeauxInProgress(); 
    }, [])

    const getSentInvoicesCount = () => {
        Axios.get("/invoice//supplierSentInvoices", {
            params: {
                period : value,
                endDate: endDate,
                startDate: startDate,
                entityFilter:value2
            },
        }).then((response) => {
            let count = response?.data?.totalDocs ? response.data.totalDocs : 0;
            setSentInvoicesCount(count);
            // listpaidInvoices listnotPaidInvoices listlitigationInvoices listsentInvoicesCount
            setListsentInvoicesCount(response?.data?.currencies)
        }).catch(res => {
          notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    };

    const getUploadedInvoicesCount = () => {
        Axios.get("/invoiceupload//uploadedInvoicesCount", {
            params: {
                period : period
            },
        }).then((response) => {
            let count = response?.data?.totalDocs ? response.data.totalDocs : 0;
            setUploadedDocsCount(count);
        }).catch(res => {
          notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    };

    const getInvoicedEntitiesCount = () => {
        Axios.get("/invoice//supplierEntitiesCount", {
            params: {
                period : period
            },
        }).then((response) => {
            let count = response?.data?.totalEntities ? response.data.totalEntities : 0;
            setInvoicedEntitiesCount(count);
        }).catch(res => {
          notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    };

    let dateNew=new Date().getTime();

    const getAllBandeauxInProgress = () => {
        Axios.get('/user/'+dateNew+'/AllBandeauxInProgress').then((response) => {
            const DataTemp = response?.data?.bandeaux.map(row => {
                return {
                    ...row,
                    read: bandeaux?.find(b => b.uid === row.uid)?.read? bandeaux?.find(b => b.uid === row.uid)?.read : false,
                } 
            })
            setBandeauxState(DataTemp);
            updateBandeaux(DataTemp);
        }).catch(res => {
          notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    };
    

    useEffect(() => {
        getNotPaidInvoices()
        getLitigationInvoices()
        getPaidInvoices()
        getEntityForUserSupplier();
        getSentInvoicesCount();
        // getUploadedInvoicesCount();
        // getInvoicedEntitiesCount();
        getQuestionsCategories("");
    }, [value,value2,startDate,endDate]);

    const getIdStatus=(code)=>{
        return (statuses?.find(s => s.aifeCode == code)?.id);
    }

    const getEntityForUserSupplier = () => {
        Axios.get("/entity//entityForUserSupplier", {
            params: {
                region : ''
            },
        }).then((response) => {
            let li=[];
            response?.data?.entity?.map((item,i)=>{  
                let p={
                    value: item.uid,
                        label: item.name +" "+item.city,
                        city: "("+item.city+")",
                };
                li = li.concat(p);            
            });  

            setEntityForUserSupplier([{label: "-- " + t('home:chooseSupplier', "Select a supplier") + " --", value: ''}, ...li]);
        }).catch(res => {
        notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    }

    const getPaidInvoices = () => {
        Axios.get("/invoice//paidInvoicesF", {
          params: {
            period: value,
            endDate: endDate,
            startDate: startDate,
            entityFilter:value2
          },
        })
          .then((res) => {
            if (res?.data?.count) {
              setPaidInvoices(res.data.count);
              setListpaidInvoices(res.data.currencies)
            }
          })
          .catch((res) => {
            notyf.error(t("global:errorOccurred", "An error has occurred"));
          });
      };

    const getNotPaidInvoices = () => {
        Axios.get("/invoice//notPaidInvoicesF",{
            params: {
                period:value,
                endDate:endDate ,
                startDate:startDate,
                entityFilter:value2
               }
        }).then(res => {
            if(res?.data?.count){
                setNotPaidInvoices(res.data.count)
                setListnotPaidInvoices(res.data.currencies)
            }
        }).catch(res => {
            notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    }

    const getLitigationInvoices = () => {
        Axios.get("/invoice//litigationInvoicesF",{
            params: {
                period:value,
                endDate:endDate ,
                startDate:startDate,
                entityFilter:value2
               }
        }).then(res => {
            if(res?.data?.count){
                setLitigationInvoices(res.data.count)
                setListlitigationInvoices(res.data.currencies)
            }
        }).catch(res => {
            notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    }


  return (
      <>
        <HomeSContainer >
            <TitleHelmet title={"Transdev | " + t("menu:home", "Home")}  />
            {bandeaux.length > 0 &&<InfoBand setBandeauxState={setBandeauxState} bandeauxState={bandeauxState} setBandeaux={updateBandeaux} bandeaux={bandeaux} userProfile={userProfile}/>}          
            <HomeSHeader>
                {/* <HeaderActions>
                </HeaderActions> */}
                <HeaderAction>
                </HeaderAction>
                <HeaderAction style={{display:"flex"}}>
                    {entityForUserSupplier?.length > 2 && <Select 
                        width={true}
                        options={entityForUserSupplier} 
                        value={entityForUserSupplier?.find(option => option.value === value2)?.label } 
                        onChange={selectChangeSupplier} 
                    />}
                    <Select 
                        options={options} 
                        value={value !== "other" ? options.find(option => option.value === value)?.label : "Du " + formatDateFr(startDate) + " au " + formatDateFr(endDate)} 
                        onChange={selectChange} 
                    />
                </HeaderAction>
            </HomeSHeader>
            <HomeSBody>
                <div style={{width:"100%"}}>
                    <HomeSCards>
                        <Card width="47%"
                            icon={<DescriptionIcon style={{color: "#f00"}} />} 
                            title={t("home:issuedInvoices", "Issued invoices")}
                            tooltip={t("home:issuedInvoicesTooltip", "Total of invoices issued to Transdev")}
                            value={sentInvoicesCount}
                            currencies={listsentInvoicesCount}
                            list={true}
                            clickHandler={() => {navigate("/invoices", { replace : false })}} 
                        />
                        <Card width="47%"
                            icon={<CheckIcon style={{ color: "green" }} />}
                            title={t("home:paidInvoices", "Paid invoices")}
                            tooltip={t("home:paidInvoicesTooltip", "Total of invoices which status is \"Payment order transmitted\"")}
                            value={paidInvoices}
                            currencies={listpaidInvoices}
                            list={true}
                            clickHandler={() => {navigate("/invoices", { replace : false, state : {statusCodeFilter: getIdStatus("211")} })}} 
                        />
                        <Card width="47%"
                            icon={<HourglassEmptyIcon style={{color: "#f00"}} />} 
                            title={t("home:waitingInvoices", "Invoices waiting to be paid")} 
                            tooltip={t("home:waitingInvoicesTooltip", "Total of invoices which status is \"Approved\"")}
                            value={notPaidInvoices}
                            currencies={listnotPaidInvoices}
                            list={true}
                            clickHandler={() => {navigate("/invoices", { replace : false, state : {statusCodeFilter: getIdStatus("205")} })}} 
                        />
                        <Card width="47%"
                            icon={<ReportProblemIcon style={{color: "#F9B959"}} />} 
                            title={t("home:litigationInvoices", "Litigation invoices")}
                            tooltip={t("home:litigationInvoicesTooltip", "Total of invoices which status is \"Litigation\"")}
                            value={litigationInvoices}
                            currencies={listlitigationInvoices}
                            list={true}
                            clickHandler={() => {navigate("/invoices", { replace : false, state : {statusCodeFilter: getIdStatus("207")} })}} 
                        />
                    </HomeSCards>
                    <HomeSStats>
                        <InvoicesPerStatus period={period} userType={userType} startDate={startDate} endDate={endDate} entityFilter={value2} />
                        <FAQuestions width={"49%"} questionsResponses={questionsResponses} categoriesFilter={categoriesFilter} inputChangeHandler={inputChangeHandler} />
                    </HomeSStats>
                </div>
                {/* <Events /> */}
            </HomeSBody>
                <DateRange
                    title="Choisir une période pour les KPIs"
                    show={showDateRange} 
                    format={formatDateEur} 
                    clicked={() => setShowDateRange(false)} 
                    changed={date => dateChangeHandler(date)} 
                    field="kpiPeriod"
                />
        </HomeSContainer>
      </>
  );
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    login : state.auth.login,
    userProfile : state.auth.profile,
    bandeaux : state.users.bandeaux
})

const mapDispatchToProps = dispatch => ({
    updateBandeaux: (a) => dispatch(actions.updateBandeaux(a))
})
export default connect(mapStateToProps, mapDispatchToProps) (HomeSupplier)
