import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { CTooltip } from '../../UI/CTooltip/CTooltip'
import { useTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { getLabelWithKey ,getEventTooltipWithKey} from '../../../shared/utility';
import EmailPreviewModal from "./EmailPreviewModal/EmailPreviewModal";
import { TD, TR } from '../../../styles/Table.styled';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

function TableBody(props) {
    const {data} = props;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const [viewingEmail, setViewingEmail] = useState(false);
    const [viewedEmail, setViewedEmail] = useState(null);   

    const lineClickHandle = (row) => { 
        setViewingEmail(true)
        setViewedEmail(row)
    }

    return (
        <>           
            { 
                data.map((row,index) => (
                    <TR key={index}  >  
                        <TD onClick={() => {lineClickHandle(row)}}>
                            <span>{getLabelWithKey(row.type)}</span>
                        </TD>
                        <TD onClick={() => {lineClickHandle(row)}}>
                            <CTooltip title={getEventTooltipWithKey(row.event) || ""}>
                            <span>{getLabelWithKey(row.event)}</span>
                            </CTooltip>  
                        </TD>
                       
                        <TD onClick={() => {lineClickHandle(row)}}>
                            <CTooltip title={row.fr_subject}>
                                <span>{row.fr_subject}</span>
                            </CTooltip>
                        </TD> 
                        <TD> 
                            {row?.id ? (
                                <CTooltip title="Modifier">  
                                    <EditIcon 
                                        onClick={() => navigate("/emailsContentSettingsDetail/"+row.uid)}
                                        // style={{fontSize: "1rem"}}
                                        style={{fontSize: "1.2rem", cursor: "pointer"}}
                                        />  
                                </CTooltip>
                            ):( <CTooltip title="Ajouter"> 
                                    <AddIcon 
                                        onClick={() => navigate("/addEmailsContentSettingsDetail/" +row.eventId)}
                                        style={{fontSize: "1.2rem", cursor: "pointer"}}
                                        />  
                                </CTooltip> 
                            )}
                        </TD>
                    </TR>
                ))
            }
            {   viewingEmail &&  
                    <EmailPreviewModal   
                        show = {viewingEmail}
                        handleClose={()=>setViewingEmail(false)}
                        convertedContent={viewedEmail?.fr_value || ""}
                        event ={viewedEmail?.event}/>
                             
            }
        </>
    )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = dispatch => ({
    getDocumentFile: (uid) => dispatch(actions.rejectGetDocumentFile(uid))
    
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
