import React, { useEffect, useState } from "react";

import Axios from "../../axios-proas";

import Grid from "@mui/material/Grid";

import DropDownInfo from "../../components/ExtractionForm/DropDownInfo/DropDownInfo";
import EntitiesComponent from "../../components/UI/EntitiesComponent/EntitiesComponent";
import RegionComponent from "../../components/UI/EntitiesComponent/RegionComponent";
import MandatoryMarker from '../../components/UI/MandatoryMarker';
import { formatDate, formatNumber, getNotyfObject } from "../../shared/utility";
import Spinner from "react-bootstrap/Spinner";

import frLocale from "date-fns/locale/fr";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import {
  CFFormGroup,
  SousContent,
  Content,
  EFContainer,
  EFCol6,
  EntFormItem,
  EntFormList,
  EntFormSection,
  EFRow,
  EFBody,
} from "./ExtractionForm.Styled";
import {
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  HeaderTitle,
  BtnOutlineAction,
  BtnAction,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  FormIntervalLabel,
  FormIntervalValues,
  FormIntervalStart,
  FormInterval,
  FormIntervalEnd,
} from "../../styles/Common";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { DPContainer } from "../CampaignForm/CampaignForm.styled";
import { deAT } from "date-fns/locale";
import { compareAsc } from "date-fns";
import GroupComponent from "../../components/UI/GroupComponent/GroupComponent";

function ExtractionForm() {
  let notyf = getNotyfObject();
  const navigate = useNavigate();
  const [options, setOptions] = useState(null);
  const currentDate = new Date();
  const [startIssuingDateError, setStartIssuingDateError] = useState(false);
  const [endIssuingDateError, setEndIssuingDateError] = useState(false);
  const [startCreationDateError, setStartCreationDateError] = useState(false);
  const [endCreationDateError, setEndCreationDateError] = useState(false);
  const [startIssuingDate, setStartIssuingDate] = useState(null);
  const [endIssuingDate, setEndIssuingDate] = useState(null);
  const [errors, setErrors] = useState(null);
  const [createEctractionLoading, setCreateExtractionLoading] = useState(false);
  const [extractionData, setExtractionData] = useState(null);
  const [extractionCopie, setExtractionCopie] = useState(null);
  const [groupsCopie, setGroupsCopie] = useState([]);

  let location = useLocation();
  let { uid } = useParams();
  const [extraction, setExtraction] = useState({});

  useEffect(() => {
    Axios.get("extraction//extractionOptions")
      .then((response) => {
        let optionsTemp = response?.data?.options;
        setOptions(optionsTemp);
      })
      .catch((response) => {
        notyf.error("Une erreur s'est produite");
      });

    if (uid != null) {
      Axios.get("extraction/" + uid + "/extractionByUid")
        .then((response) => {
          let k = { ...response.data.result, new: false };
          setExtraction(k);
          setExtractionCopie(k);
          setGroupsCopie(k.groups)
          setStartIssuingDate(new Date(+k?.issuingDateStart));
          setEndIssuingDate(new Date(+k?.issuingDateEnd));
          Axios.get("extraction//extractionOptions")
            .then((response) => {
              let optionsTemp = response?.data?.options;
              if (optionsTemp) {
                setOptions(optionsTemp);
              } else {
                console.log("hello");
                notyf.error("Une erreur s'est produite");
              }
            })
            .catch((response) => {
              notyf.error("Une erreur s'est produite");
            });
        })
        .catch((response) => {
          notyf.error("Une erreur s'est produite");
        });
    } else {
      Axios.get("extraction//extractionOptions")
        .then((response) => {
          let optionsTemp = response?.data?.options;
          if (optionsTemp) {
            setOptions(optionsTemp);
            setStartIssuingDate(new Date(+optionsTemp?.issuingDateMin));
            setEndIssuingDate(new Date(+optionsTemp?.issuingDateMAx));
            setExtraction({
              invoiceType: "recieved",
              numberStart: null,
              numberEnd: null,
              exclusiveTaxAmountStart: null,
              exclusiveTaxAmountEnd: null,
              inclusiveTaxAmountStart: null,
              inclusiveTaxAmountEnd: null,
              supplierCode: null,
              supplierName: null,
              supplierVAT: null,
              supplierSiret: null,
              clientCode: null,
              clientName: null,
              clientVAT: null,
              clientSiret: null,
              new: true,
              issuingDateStart: +optionsTemp?.issuingDateMin,
              issuingDateEnd: +optionsTemp?.issuingDateMAx,
              creationDateStart: +optionsTemp?.creationDateMin,
              creationDateEnd: +optionsTemp?.creationDateMAx,
              format: "ALL"
            });
          } else {
            notyf.error("Une erreur s'est produite");
          }
        })
        .catch((response) => {
          notyf.error("Une erreur s'est produite");
        });
    }
  }, []);

  //issuing date
  const handleStartIssuingDateChange = (date) => {
    if (date != null) {
      console.log(extraction.issuingDateEnd);
      console.log(date.getTime());
      if (date.toString() === "Invalid Date") {
        setStartIssuingDateError(true);
      } else {
        if (
          date.getFullYear <= 1900 ||
          date.getFullYear > 2100 ||
          date.getFullYear >= 1000 && date.getTime() > extraction.issuingDateEnd
        ) {
          notyf.error(
            "La date de début doit être inférieure ou égale à la date de fin"
          );
        } else {
          setStartIssuingDateError(false);
          setStartIssuingDate(date);
          setExtraction({
            ...extraction,
            issuingDateStart: date.getTime(),
          });
        }
      }
    }
  };

  const handleEndIssuingDateChange = (date) => {
    if (date != null) {
      console.log(extraction.issuingDateStart);
      console.log(date.getTime());
      if (date.toString() === "Invalid Date") {
        setEndIssuingDateError(true);
      } else {
        if (
          date.getFullYear >= 1000 && date.getTime() < extraction.issuingDateStart ||
          date.getFullYear > 2100
        ) {
          notyf.error(
            "La date de fin doit être supérieure ou égale à la date de début"
          );
        } else {
          setEndIssuingDateError(false);
          setEndIssuingDate(date);
          setExtraction({
            ...extraction,
            issuingDateEnd: date.getTime(),
          });
        }
      }
    }
  };

  const generateMaxEndIssuingDate = () => {
    let startDate = new Date(extraction.issuingDateStart);
    if (options?.issuingDateMAx) return new Date(+options?.issuingDateMAx);
    else return new Date(startDate.setFullYear(startDate.getFullYear(), 12, 0));
  };

  //creation date
  const handleStartCreationDateChange = (date) => {
    if (date.toString() === "Invalid Date") {
      setStartCreationDateError(true);
    } else if (date.getFullYear <= 1900 || date.getFullYear > 2100) {
      setStartCreationDateError(true);
    } else {
      setStartCreationDateError(false);
    }
    // setSelectedStartCreationDate(date);
    setExtraction({
      ...extraction,
      creationDateStart: date.getTime(),
    });
  };

  const handleEndCreationDateChange = (date) => {
    if (date.toString() === "Invalid Date") {
      setEndCreationDateError(true);
    } else if (
      date.getTime() < extraction.creationDateStart ||
      date.getFullYear > 2100
    ) {
      setEndCreationDateError(true);
    } else {
      setEndCreationDateError(false);
    }
    // setSelectedEndCreationDate(date);
    setExtraction({
      ...extraction,
      creationDateEnd: date.getTime(),
    });
  };

  const generateMaxEndCreationDate = () => {
    let startDate = new Date(extraction.creationDateStart);
    if (options?.creationDateMAx) return new Date(+options?.creationDateMAx);
    else return new Date(startDate.setFullYear(startDate.getFullYear(), 12, 0));
  };

  const invoiceTypeChangeHandler = (type) => {
    setExtraction({
      ...extraction,
      invoiceType: type,
    });
  };

  const invoiceNumberChangeHandler = (e) => {
    const field = e.target.name;

    const value = e.target.value;
    setExtraction({
      ...extraction,
      [field]: value,
    });
  };

  const amountChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setExtraction({
      ...extraction,
      [name]: value,
    });
    // if(parseFloat(value) < parseFloat(options.exclusiveAmountMin) || parseFloat(value) > parseFloat(options.exclusiveAmountMAx) ) {
    //     setExclusiveAmountError(`Le montant doit étre entre ${options.exclusiveAmountMin} et ${options.exclusiveAmountMAx}`);
    // }
  };

  const entityFilterChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setExtraction({
      ...extraction,
      [name]: value,
    });
  };

  const checkExtractionValidity = () => {
    console.log(extraction);
    let errorsTemp = [];
    if (startIssuingDateError || endIssuingDateError) {
      errorsTemp.push(
        `La Date de Fin ne peut être supérieure à la Date de Début, Veuillez saisir une date de facture entre ${formatDate(
          +options.issuingDateMin
        )} et ${formatDate(+options.issuingDateMAx)}`
      );
    }
    if (startCreationDateError || endCreationDateError) {
      errorsTemp.push(
        `Veuillez saisir une date de facture entre ${formatDate(
          +options.creationDateMin
        )} et ${formatDate(+options.creationDateMAx)}`
      );
    }
    if (
      (extraction.exclusiveTaxAmountStart &&
        !extraction.exclusiveTaxAmountEnd) ||
      (!extraction.exclusiveTaxAmountStart && extraction.exclusiveTaxAmountEnd)
    ) {
      errorsTemp.push(
        "Vous devez saisir une valeur minimale et maximale pour le champ montant HT"
      );
    }
    if (
      !extraction.titel ||
      extraction.titel == null ||
      extraction.titel == "" ||
      extraction.titel == " "
    ) {
      errorsTemp.push("Le titre est obligatoire");
    }
    if (
      extraction.exclusiveTaxAmountStart &&
      (parseFloat(extraction.exclusiveTaxAmountStart) <
        parseFloat(options.exclusiveAmountMin) ||
        parseFloat(extraction.exclusiveTaxAmountStart) >
          parseFloat(options.exclusiveAmountMAx) ||
        parseFloat(extraction.exclusiveTaxAmountEnd) <
          parseFloat(extraction.exclusiveTaxAmountStart) ||
        parseFloat(extraction.exclusiveTaxAmountEnd) >
          parseFloat(options.exclusiveAmountMAx))
    ) {
      errorsTemp.push(
        `Le montant HT doit être compris entre ${options.exclusiveAmountMin} et ${options.exclusiveAmountMAx}`
      );
    }
    // if(extraction.exclusiveTaxAmountEnd
    //     && (
    //         parseFloat(extraction.exclusiveTaxAmountEnd) < parseFloat(extraction.exclusiveTaxAmountStart)
    //         || parseFloat(extraction.exclusiveTaxAmountEnd) > parseFloat(options.exclusiveAmountMAx)
    //     )
    // ) {
    //     errorsTemp.push(`Le montant HT doit étre entre ${options.exclusiveAmountMin} et ${options.exclusiveAmountMAx}`);
    // }
    if (
      (extraction.inclusiveTaxAmountStart &&
        !extraction.inclusiveTaxAmountEnd) ||
      (!extraction.inclusiveTaxAmountStart && extraction.inclusiveTaxAmountEnd)
    ) {
      errorsTemp.push(
        "Vous devez saisir une valeur minimale et maximale pour le champ montant HT"
      );
    }
    if (
      extraction.inclusiveTaxAmountStart &&
      (parseFloat(extraction.inclusiveTaxAmountStart) <
        parseFloat(options.inclusiveAmountMin) ||
        parseFloat(extraction.inclusiveTaxAmountStart) >
          parseFloat(options.inclusiveAmountMax) ||
        parseFloat(extraction.inclusiveTaxAmountEnd) <
          parseFloat(extraction.inclusiveTaxAmountStart) ||
        parseFloat(extraction.inclusiveTaxAmountEnd) >
          parseFloat(options.inclusiveAmountMax))
    ) {
      errorsTemp.push(
        `Le montant TTC doit étre entre ${options.inclusiveAmountMin} et ${options.inclusiveAmountMax}`
      );
    }
    return errorsTemp;
  };

  const createExtraction = () => {
    setCreateExtractionLoading(true);
    Axios.post("extraction", extraction)
      .then((response) => {
        setCreateExtractionLoading(false);
        if (response?.data?.success) {
          notyf.success("La demande d'extraction a bien été créée");
          navigate("/extraction");
        } else if (parseInt(response?.data?.invoices) === 0) {
          setErrors(["Aucune facture trouvée avec ces filtres"]);
        }
      })
      .catch((response) => {
        notyf.error("Une erreur s'est produite");
        setCreateExtractionLoading(false);
      });
  };

  const updateExtraction = (id) => {
    Axios.put("extraction/" + id + "/extraction", extraction)
      .then((response) => {
        notyf.success("Extraction actualisée");
        setExtractionCopie(extraction);
      })
      .catch((response) => {
        notyf.error("Une erreur s'est produite");
      });
  };

  const submitExtractionClickHandler = () => {
    let errorsTemp = checkExtractionValidity();
    if (errorsTemp.length > 0) {
      setErrors(errorsTemp);
    } else {
       setErrors([])
      createExtraction()
    }
  };

  const updateExtractionClickHandler = () => {
    let errorsTemp = checkExtractionValidity();
    if (errorsTemp.length > 0) {
      setErrors(errorsTemp);
    } else {
      setErrors([]);
      if (extractionCopie != extraction) {
        updateExtraction(extraction.uid);
      } else {
        notyf.error("Une erreur s'est produite");
      }
    }
  };

  const entitiesChangeHandler = (entitiesList) => {
    let entityList = [];
    if (Array.isArray(entitiesList)) entityList = entitiesList;
    else entityList.push(entitiesList);
    let userTemp = {
      ...extraction,
      entities: entityList,
    };
    setExtraction(userTemp);
  };

  const deleteEntityclickHandler = (entity) => {
    let userTemp = { ...extraction };
    if (!extraction?.new) {
      userTemp = {
        ...userTemp,
        entitiesToDelete: userTemp?.entitiesToDelete
          ? [...userTemp?.entitiesToDelete, entity.uid]
          : [entity.uid],
      };
    }
    let userEntities = [...extraction?.entities];
    let entityIndex = extraction?.entities.findIndex(
      (ent) => ent.uid === entity.uid
    );
    if (entityIndex >= 0) {
      userEntities.splice(entityIndex, 1);
    }
    setExtraction({
      ...userTemp,
      entities: userEntities,
    });
  };

  const regionChangeHandler = (entitiesList) => {
    let regionList = [];
    if (Array.isArray(entitiesList)) regionList = entitiesList;
    else regionList.push(entitiesList);
    let userTemp = {
      ...extraction,
      regions: regionList,
    };
    setExtraction(userTemp);
  };


  
  const deleteRegionclickHandler = (entity) => {
    let userTemp = { ...extraction };
    if (!extraction?.new) {
      userTemp = {
        ...userTemp,
        regionToDelete: userTemp?.regionToDelete
          ? [...userTemp?.regionToDelete, entity.uid]
          : [entity.uid],
      };
    }
    let userEntities = [...extraction?.regions];
    let entityIndex = extraction?.regions.findIndex(
      (ent) => ent.uid === entity.uid
    );
    if (entityIndex >= 0) {
      userEntities.splice(entityIndex, 1);
    }
    setExtraction({
      ...userTemp,
      regions: userEntities,
    });
  };

  const groupChangeHandler = (groupsList,p) => {
      const extractionTemp = {
          ...extraction,
          groups : groupsList,
          groupsToDelete : p
      }
      setExtraction(extractionTemp)
  }

  const deleteGroupClickHandler = (group) => {
      const extractionTemp = {...extraction}
      if(extraction?.id && !group?.new )
        extractionTemp.groupsToDelete = extractionTemp?.groupsToDelete ? [...extractionTemp?.groupsToDelete, group.uid] : [group.uid]

      const extractionGroups = extraction.groups;
      const groupIndex = extractionGroups.findIndex(g => g.uid === group.uid);
      if (groupIndex >=0 ){
        extractionGroups.splice(groupIndex, 1)
      }
      setExtraction(extractionTemp)
  }

  return (
    <EFContainer>
      <Header>
        <HeaderActions>
          {extraction?.id ? (
            <BtnOutlineAction
              disabled={extractionCopie == extraction}
              border="#2174B9"
              color="#2174B9"
              onClick={() => updateExtractionClickHandler(extraction?.id)}
            >
              <span>Valider</span>
            </BtnOutlineAction>
          ) : (
            <BtnOutlineAction
              border="#2174B9"
              color="#2174B9"
              onClick={() => submitExtractionClickHandler()}
            >
              <span>Valider</span>
            </BtnOutlineAction>
          )}
          {/* <BtnOutlineAction
                    border="red"
                    color="red"
                    onClick={() => history.goBack()}
                >
                    <span>Annuler</span>
                </BtnOutlineAction>       */}
        </HeaderActions>
      </Header>
      <EFBody>
        {errors && errors.length > 0 ? (
          <HeaderTitle
            className="alert alert-danger mt-2 campaignForm__errors_container"
            role="alert"
          >
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </HeaderTitle>
        ) : null}
        <EFRow>
          <EFCol6>
            <Content>
              {/* <FormGroup className="mb-4">
                            <FormLabel htmlFor="family" >Type de facture</FormLabel>
                            <FormSelect 
                                id="family"
                                name="family"
                                value={extractionData?.invoiceType || extraction.invoiceType || ""}
                                onChange={(e) => invoiceTypeChangeHandler(e.target.value)}
                            >
                                    <option value="sent">Émises</option>
                                    <option value="recieved">Reçues</option>
                            </FormSelect>
                        </FormGroup> */}
              <FormGroup className="mb-4">
              <FormLabel htmlFor="title" >Titre<MandatoryMarker /> </FormLabel>
               
                <FormInput
                  id="title"
                                        // style={{textAlign: 'end'}}
                  type="text"
                  name="titel"
                  value={extractionData?.titel || extraction.titel || ""}
                  onChange={(e) => invoiceNumberChangeHandler(e)}
                />
              </FormGroup>
              <FormGroup className="mb-4">
                <div >Format<MandatoryMarker /> </div>
                <FormLabel>
                  <FormInput
                    type="radio"
                    name="format"
                    value="ALL"
                    checked={extraction?.format === "ALL"}
                    onChange={() => setExtraction({...extraction, format: "ALL"})}
                  /> PDF + CSV
                </FormLabel>
                <FormLabel>
                  <FormInput
                    type="radio"
                    name="format"
                    value="PDF"
                    checked={extraction?.format === "PDF"}
                    onChange={() => setExtraction({...extraction, format: "PDF"})}
                  /> PDF
                </FormLabel>
                <FormLabel>
                  <FormInput
                    type="radio"
                    name="format"
                    value="CSV"
                    checked={extraction?.format === "CSV"}
                    onChange={() => setExtraction({...extraction, format: "CSV"})}
                  /> CSV
                </FormLabel>
              </FormGroup>
              {/* <FormInterval>
                <FormIntervalLabel>
                  Numéro(s) de facture
                  <DropDownInfo
                    min={options?.invoiceNumberMin || ""}
                    max={options?.issuingDateMAx || ""}
                  />
                </FormIntervalLabel>
                <FormIntervalValues>
                  <FormIntervalStart>
                    <FormLabel className="mb-2" htmlFor="numberStart">
                      Début
                    </FormLabel>
                    <FormInput
                      id="numberStart"
                      style={{ textAlign: "start" }}
                      type="text"
                      name="numberStart"
                      value={
                        extractionData?.numberStart ||
                        extraction.numberStart ||
                        ""
                      }
                      onChange={(e) => invoiceNumberChangeHandler(e)}
                    />
                  </FormIntervalStart>
                  <FormIntervalEnd>
                    <FormLabel className="mb-2" htmlFor="numberEnd">
                      Fin
                    </FormLabel>
                    <FormInput
                      id="numberEnd"
                      style={{ textAlign: "start" }}
                      name="numberEnd"
                      value={
                        extractionData?.numberEnd || extraction.numberEnd || ""
                      }
                      onChange={(e) => invoiceNumberChangeHandler(e)}
                    />
                  </FormIntervalEnd>
                </FormIntervalValues>
              </FormInterval> */}
              <FormGroup className="mb-4">
                <FormLabel htmlFor="name">Date de la facture</FormLabel>
              </FormGroup>
              <FormGroup className="mb-4">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={frLocale}
                >
                  <DPContainer>
                    <DatePicker
                      //margin="normal"
                      className="extractionForm__datepicker"
                      id="date-picker-dialog"
                      label="Début"
                      format="dd/MM/yyyy"
                      minDate={new Date(+options?.issuingDateMin)}
                      maxDate={
                        extraction?.issuingDateStart
                          ? extraction?.issuingDateEnd
                          : generateMaxEndIssuingDate()
                      }
                      value={startIssuingDate}
                      onChange={handleStartIssuingDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      invalidDateMessage="Format de date invalide"
                      maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                      minDateMessage="La date ne doit pas être antérieure à la date minimale"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </DPContainer>
                  <DPContainer>
                    <DatePicker
                      //margin="normal"
                      className="extractionForm__datepicker"
                      id="date-picker-dialog"
                      label="Fin"
                      format="dd/MM/yyyy"
                      value={endIssuingDate}
                      onChange={handleEndIssuingDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      minDate={
                        extraction?.issuingDateStart
                          ? extraction?.issuingDateStart
                          : new Date(+options?.issuingDateMin)
                      }
                      maxDate={generateMaxEndIssuingDate()}
                      invalidDateMessage="Format de date invalide"
                      maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                      minDateMessage="La date ne doit pas être antérieure à la date minimale"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </DPContainer>
                </LocalizationProvider>
              </FormGroup>
              {/* <FormGroup className="mb-4">
                                <FormLabel htmlFor="name" >Date de la constitution</FormLabel>         
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                <DPContainer>
                                    <DatePicker
                                        //margin="normal"
                                        className="extractionForm__datepicker"
                                        id="date-picker-dialog"
                                        label="Début"
                                        format="dd/MM/yyyy"
                                        minDate={new Date(+options?.creationDateMin) || currentDate}
                                        maxDate={generateMaxEndCreationDate()}
                                        value={extractionData?.uid ? new Date(+extractionData?.creationDateStart) : new Date(extraction.creationDateStart)}
                                        onChange={handleStartCreationDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        invalidDateMessage="Format de date invalide"
                                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </DPContainer>
                                <DPContainer>
                                    <DatePicker
                                        //margin="normal"
                                        className="extractionForm__datepicker"
                                        id="date-picker-dialog"
                                        label="Fin"
                                        format="dd/MM/yyyy"
                                        value={extractionData?.uid ? new Date(+extractionData?.creationDateEnd):new Date(extraction.creationDateEnd)}
                                        onChange={handleEndCreationDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        minDate={new Date(extraction.creationDateStart)}
                                        maxDate={generateMaxEndCreationDate()}
                                        invalidDateMessage="Format de date invalide"
                                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </DPContainer>
                            </LocalizationProvider>
                        </FormGroup> */}
              <FormInterval>
                <FormIntervalLabel>
                  Montant HT
                  <DropDownInfo
                    min={
                      formatNumber(parseFloat(+options?.exclusiveAmountMin)) ||
                      0
                    }
                    max={
                      formatNumber(parseFloat(+options?.exclusiveAmountMAx)) ||
                      0
                    }
                  />
                </FormIntervalLabel>
                <FormIntervalValues>
                  <FormIntervalStart>
                    <FormLabel
                      className="mb-2"
                      htmlFor="exclusiveTaxAmountStart"
                    >
                      Minimum
                    </FormLabel>
                    <FormInput
                      id="exclusiveTaxAmountStart"
                      style={{ textAlign: "start" }}
                      type="number"
                      name="exclusiveTaxAmountStart"
                      value={
                        extractionData?.exclusiveTaxAmountStart ||
                        extraction.exclusiveTaxAmountStart
                      }
                      onChange={(e) => amountChangeHandler(e)}
                      step="any"
                    />
                  </FormIntervalStart>
                  <FormIntervalEnd>
                    <FormLabel className="mb-2" htmlFor="exclusiveTaxAmountEnd">
                      Maximum
                    </FormLabel>
                    <FormInput
                      id="exclusiveTaxAmountEnd"
                      style={{ textAlign: "start" }}
                      type="number"
                      name="exclusiveTaxAmountEnd"
                      value={
                        extractionData?.exclusiveTaxAmountEnd ||
                        extraction.exclusiveTaxAmountEnd
                      }
                      onChange={(e) => amountChangeHandler(e)}
                      step="any"
                    />
                  </FormIntervalEnd>
                </FormIntervalValues>
              </FormInterval>
              <FormInterval>
                <FormIntervalLabel>
                  Montant TTC
                  <DropDownInfo
                    min={
                      formatNumber(parseFloat(options?.inclusiveAmountMin)) || 0
                    }
                    max={
                      formatNumber(parseFloat(options?.inclusiveAmountMax)) || 0
                    }
                  />
                </FormIntervalLabel>
                <FormIntervalValues>
                  <FormIntervalStart>
                    <FormLabel
                      className="mb-2"
                      htmlFor="inclusiveTaxAmountStart"
                    >
                      Minimum
                    </FormLabel>
                    <FormInput
                      id="inclusiveTaxAmountStart"
                      type="number"
                      style={{ textAlign: "start" }}
                      name="inclusiveTaxAmountStart"
                      value={
                        extractionData?.inclusiveTaxAmountStart ||
                        extraction.inclusiveTaxAmountStart
                      }
                      onChange={(e) => amountChangeHandler(e)}
                      step="any"
                    />
                  </FormIntervalStart>
                  <FormIntervalEnd>
                    <FormLabel className="mb-2" htmlFor="inclusiveTaxAmountEnd">
                      Maximum
                    </FormLabel>
                    <FormInput
                      id="inclusiveTaxAmountEnd"
                      type="number"
                      style={{ textAlign: "start" }}
                      name="inclusiveTaxAmountEnd"
                      value={
                        extractionData?.inclusiveTaxAmountEnd ||
                        extraction.inclusiveTaxAmountEnd
                      }
                      onChange={(e) => amountChangeHandler(e)}
                      step="any"
                    />
                  </FormIntervalEnd>
                </FormIntervalValues>
              </FormInterval>
            </Content>
          </EFCol6>
          <EFCol6>
            <SousContent>
              <FormGroup>
                <FormLabel>Fournisseur</FormLabel>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="supplierCode">Code</FormLabel>
                <FormInput
                  id="supplierCode"
                  type="text"
                  name="supplierCode"
                  value={
                    extractionData?.supplierCode || extraction?.supplierCode
                  }
                  onChange={(e) => entityFilterChangeHandler(e)}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="supplierName">Nom</FormLabel>
                <FormInput
                  id="supplierName"
                  type="text"
                  name="supplierName"
                  value={
                    extractionData?.supplierName || extraction?.supplierName
                  }
                  onChange={(e) => entityFilterChangeHandler(e)}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="supplierVAT">Numéro TVA</FormLabel>
                <FormInput
                  id="supplierVAT"
                  type="text"
                  name="supplierVAT"
                  value={extractionData?.supplierVAT || extraction.supplierVAT}
                  onChange={(e) => entityFilterChangeHandler(e)}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="supplierSiret">Numéro SIRET</FormLabel>
                <FormInput
                  id="supplierSiret"
                  type="text"
                  name="supplierSiret"
                  value={
                    extractionData?.supplierSiret || extraction.supplierSiret
                  }
                  onChange={(e) => entityFilterChangeHandler(e)}
                />
              </FormGroup>
            </SousContent>

            <div>
              <RegionComponent
                show={true}
                label={"Régions"}
                regions={extraction?.regions}
                deleteEntity={deleteRegionclickHandler}
                addEntity={regionChangeHandler}
                entitiesType={"owner"}
                userType={""}
              />
            </div>

            <div>
              <EntitiesComponent
                show={true}
                label={"Entités"}
                modalTitle={"Sélectionner des sociétés Transdev"}
                entities={extraction?.entities}
                deleteEntity={deleteEntityclickHandler}
                addEntity={entitiesChangeHandler}
                entitiesType={"owner"}
                userType={""}
                isMandatory={false}
              />
            </div>

            <div>
              <GroupComponent
                groups={extraction?.groups} 
                deleteGroup={deleteGroupClickHandler} 
                addGroup={groupChangeHandler}
                groupsCopie={groupsCopie}
                groupsToDelete={extraction?.groupsToDelete}
              />
            </div>
          </EFCol6>
        </EFRow>
      </EFBody>
    </EFContainer>
    //
    //
    //             <div className="extractionForm__entityFilter_container">
    //                 <div className="extractionForm__entityFilter_title">Gestionnaire Achats</div>
    //                 <div className="extractionForm__entityFilter_filters">
    //                     <input type="text" name="clientCode" value={extraction.clientCode} onChange={(e) => entityFilterChangeHandler(e)} className="form-control" placeholder="Identifiant EDI" />
    //                     <input type="text" name="clientName" value={extraction.clientName} onChange={(e) => entityFilterChangeHandler(e)} className="form-control" placeholder="Nom" />
    //                     <input type="text" name="clientVAT" value={extraction.clientVAT} onChange={(e) => entityFilterChangeHandler(e)} className="form-control" placeholder="Numéro TVA" />
    //                     <input type="text" name="clientSiret" value={extraction.clientSiret} onChange={(e) => entityFilterChangeHandler(e)} className="form-control" placeholder="Numéro Siret" />
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="extractionForm__footer">
    //             <div className="extractionForm__btn_container">
    //                 {
    //                     createEctractionLoading ? (
    //                         <div style={{
    //                             width: "100%",
    //                             display: "flex",
    //                             justifyContent: "center"
    //                         }}>
    //                             <Spinner animation="border" variant="primary" />
    //                         </div>
    //                     )
    //                     : <button className="btn btn-outline-primary" onClick={() => submitExtractionClickHandler()} >Demander</button>
    //                 }
    //                 <button className="btn btn-secondary" onClick={() => history.goBack()} >Annuler</button>
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
}

export default ExtractionForm;
