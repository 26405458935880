import styled from 'styled-components';

export const SIContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const SIBody = styled.div`
    width: 100%;
    height: 100%;
`

export const SITableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
   
    height:calc(100vh - 234px);
`

export const InvoiceNumber = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    // justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
 
    border-radius: 8px;

    & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`