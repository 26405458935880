import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ExportWrap, ExportContainer, ExportValue, ExportIcon, ExportOptions, ExportOption } from './Export.styled';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { getNotyfObject } from '../../../shared/utility';
import { useTranslation } from 'react-i18next';

function Export({exportPdf, exportCsv, hasSelection, tableCount}) {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const notyf = getNotyfObject();

    const divRef = useRef();
    //console.log(closeModal)
    const clickOutSideHandler = e => {
        if(divRef.current.contains(e.target)) {
            // inside the div
            return
        }
        // outside click
        setIsOpen(false)
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    const exportPdfClick = () => {
        setIsOpen(false);
        if (hasSelection || tableCount == undefined || tableCount <= 100)
            exportPdf();
        else
            notyf.error(t("invoiceList:limitPdf", "The limit of rows you can export in PDF format is 100"));
    }

    const exportCsvClick = () => {
        setIsOpen(false);
        if (hasSelection || tableCount == undefined || tableCount <= 20000)
            exportCsv();
        else
            notyf.error(t("invoiceList:limitCsv", "The limit of rows you can export in CSV format is 20,000"));
    } 

    return (
        <ExportWrap ref={divRef}>
            <CTooltip title={hasSelection ? t("invoiceList:exportSelection", "Export your selection") : t("invoiceList:exportResult", "Export your search result")} placement="left">
                <ExportContainer onClick={() => setIsOpen(!isOpen)}>
                    <ExportValue>
                        {t("invoiceList:export", "Export")}
                    </ExportValue>
                    <ExportIcon>
                        {
                            isOpen ? <KeyboardArrowUpIcon  /> 
                            : <KeyboardArrowDownIcon  />
                        }
                    </ExportIcon>
                </ExportContainer>
            </CTooltip>
            {
                isOpen && (
                    <ExportOptions>
                        <ExportOption
                            onClick={() => exportPdfClick()}
                        >
                            PDF
                        </ExportOption>
                        <ExportOption
                            onClick={() => exportCsvClick()}
                        >
                            CSV
                        </ExportOption>
                    </ExportOptions>
                )
            }
        </ExportWrap>
    );
}

export default Export;
