import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const DocVisu  = styled.div`
    width: 99%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
    height: 850px;
    max-height: 850px;
`;
export const Content  = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #D9E1E7CC;
    justify-content: center;
    font-size: 0.9rem;
    border-radius: 18px;
    color: ${({theme}) => theme.colors.secondary};
    padding: 3rem 2rem;
`;

export const SousContent  = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    align-items: center;
    border: 1px solid #D9E1E7CC;
    justify-content: center;
    font-size: 1rem;
    border-radius: 18px;
    color: ${({theme}) => theme.colors.secondary};
    
`;
export const ATContainer  = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
   
`

export const ATBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`
export const ATRow = styled.div`
    display: flex;
    margin-bottom: 50px;
`
export const ATLeftColumn = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
`
export const ATRightColumn = styled.div`
    width: 75%;
    display: flex;
    justify-content: space-between;
`

export const ATCol6  = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const ATFType  = styled.div`
    width: 200px;
    text-align: center;
    height: 120px;
    border: 2px solid red;
    border-radius: 10px;
    box-shadow: 4px 4px 3px #bbb;
    cursor: pointer;
    padding: 20px;
    transition: 0.2s;

    & svg {
        height: 32px;
        width: 32px;
        color: red;
    }

    & p {
        margin-top: 12px;
        font-size: 20px;
    }

    &:hover {
        background-color: #eee;
    }

    &.selected_type {
        background-color: #eee;
    }
`

export const ATFormHeader = styled.div`
    margin-bottom: 2rem;
    text-align: center;
`

export const ATFormHeaderTitle = styled.h5`
    font-weight: 600;
`

export const ATFormStepNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: red;
    color: #FFFFFF;
    margin-right: 1rem;
`

export const ATFormStep = styled.div`
    margin-bottom: 1.5rem;
    padding: 16px;
    border-radius: 10px;
    border: 1px #d0d0d0 solid;
    background-color: ${({disabled}) => disabled ? "#e0e0e0" : ""};
    & ${ATFormStepNumber} {
        background-color: ${({disabled}) => disabled ? "grey" : ""};
    }
`

export const ATFormStepHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`

export const ATFormStepHeaderTitle = styled.div`
    font-weight: 600;
    max-width: 80%;
`

export const ATFormStepDoneIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: rgba(77, 202, 118, 1);
    color: #FFFFFF;
    margin-right: 1rem;
`

export const ATFormStepBody = styled.div`
    display: block;
    align-items: center;
    margin-left: 53px;
`

