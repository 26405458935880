import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { THead, TH, THContainer} from '../../../styles/Table.styled'
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableHead(props) {

    
    const {columns, reverse, setSortQuery, setReverse, getData, filters, } = props;
    
    const columnClickHandler = (field) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getData(field,newReverse);
    }

    const selectAllRowsHandler = (e) => {
        setSelectedAllRows(!selectedAllRows);
    }

   
    return (
        <>
            <THead>
                    <tr> 
                        {columns.map(column => (
                            <TH 
                                key={column.field} 
                                className={filters?.[column.field] && 'filtered'}
                                scope="col" 
                                width={column.width}
                                id={column.field} 
                                onClick={column.field!='' ? () => columnClickHandler(column.field) : null}
                            >
                                <CTooltip title={column.title}>
                                    <THContainer>
                                        <span>
                                            {column.title}
                                        </span>
                                        <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none'}} />
                                        <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none'}}/>
                                    </THContainer>  
                                </CTooltip>  
                            </TH>
                            
                        ))}
                    </tr>
            </THead>
        </>
    )
}

export default TableHead
