import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import AppSSO from './AppSSO';
import './i18n';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import appReducer from './store/reducers/app';
import authReducer from './store/reducers/auth';
import invoiceReducer from './store/reducers/invoice'
import clientsReducer from './store/reducers/clients'
import campaignLogsReducer from './store/reducers/campaignLogs'
import noConnectedSuppliersReducer from './store/reducers/noConnectedSuppliers'
import suppliersReducer from './store/reducers/suppliers'
import documentationReducer from './store/reducers/documentation'
import entitiesReducer from './store/reducers/entities'
import campaignsReducer from './store/reducers/campaigns'
import bandeauReducer from './store/reducers/bandeau'
import lastEventsReducer from './store/reducers/lastEvents'
import logsReducer from './store/reducers/logs'
import usersReducer from './store/reducers/users'
import invoiceDetailReducer from './store/reducers/invoiceDetail'
import extractionReducer from './store/reducers/extraction'
import reportingReducer from './store/reducers/reporting'
import invoiceHomeClientsReducer from './store/reducers/invoiceHomeClients.js'
import rejectReducer from './store/reducers/reject.js'
import regroupementReducer from './store/reducers/regroupement.js'
import nonEngagingReducer from './store/reducers/nonEngaging'
import unknownInvoiceReducer from './store/reducers/unknownInvoice'
import accountingAuditReducer from './store/reducers/accountingAudit'
import auditTrailReducer from './store/reducers/auditTrail'
import UserService from './services/UserService';

import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  invoice: invoiceReducer,
  clients: clientsReducer,
  campaignLogs: campaignLogsReducer,
  suppliers: suppliersReducer,
  logs: logsReducer,
  users: usersReducer,
  invoiceDetail: invoiceDetailReducer,
  documentation: documentationReducer,
  campaigns: campaignsReducer,
  entities: entitiesReducer,
  extraction : extractionReducer,
  reporting:reportingReducer,
  invoiceHomeClients:invoiceHomeClientsReducer,
  reject: rejectReducer,
  bandeau: bandeauReducer,
  lastEvents: lastEventsReducer,
  noConnectedSuppliers: noConnectedSuppliersReducer,
  regroupement: regroupementReducer,
  nonEngaging: nonEngagingReducer,
  accountingAudit: accountingAuditReducer,
  unknownInvoice: unknownInvoiceReducer,
  auditTrail: auditTrailReducer
})
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
))

const ssoURL = 'sso.transdev.dematrust.com';

let hostname = window.location.hostname;

if(hostname==ssoURL) {

  const renderApp = () => ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={(<div>loading ...</div>)}>
        <React.StrictMode>
          <BrowserRouter >
            <AppSSO />
          </BrowserRouter>
        </React.StrictMode>
      </Suspense>
    </Provider>,
    document.getElementById('root')
  );
  UserService.initKeycloak(renderApp);

} else {

  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={(<div>loading ...</div>)}>
        <React.StrictMode>
          <BrowserRouter >
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </Suspense>
    </Provider>,
    document.getElementById('root')
  );

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
