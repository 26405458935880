import styled from 'styled-components';

export const UIContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
`

export const UIBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
`

export const UISection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const UICol = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
`

export const UIFormContainer = styled.div`
    margin: 1rem;
`

export const UIFormHeader = styled.div`
    margin-bottom: 2rem;
`

export const UIFormHeaderTitle = styled.h5`
    font-weight: 600;
`

export const UIFormStepNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    min-width: 37px;
    min-height: 37px;
    border-radius: 50%;
    background-color: red;
    color: #FFFFFF;
    margin-right: 1rem;
`

export const UIFormSubStepNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-color: red;
    color: #FFFFFF;
    margin-right: 1rem;
`

export const UIFormStep = styled.div`
    margin-bottom: 1.5rem;
    padding: 16px;
    border-radius: 10px;
    border: 1px #d0d0d0 solid;
    background-color: ${({disabled}) => disabled ? "#e0e0e0" : ""};
    & ${UIFormStepNumber} {
        background-color: ${({disabled}) => disabled ? "grey" : ""};
    }
    & ${UIFormSubStepNumber} {
        background-color: ${({disabled}) => disabled ? "grey" : ""};
    }
`

export const UIFormStepHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`

export const UIFormStepHeaderTitle = styled.div`
    font-weight: 600;
`

export const UIFormStepDoneIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    min-width: 37px;
    min-height: 37px;
    border-radius: 50%;
    background-color: rgba(77, 202, 118, 1);
    color: #FFFFFF;
    margin-right: 1rem;
`

export const UIFormSubStepDoneIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-color: rgba(77, 202, 118, 1);
    color: #FFFFFF;
    margin-right: 1rem;
`

export const UIFormStepBody = styled.div`
    display: block;
    align-items: center;
    margin-left: 53px;
`

export const DocVisu = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
    height: 850px;
    max-height: 850px;
`
export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
`;
export const ETRadiosContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1;
`


export const FormRadioWrapper = styled.div`
    display: flex;
    align-items: baseline;
    position: relative;
    border-radius: 2px;
`


export const FormRadio = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #1E85D9;
    border: 1px solid #2196F3;
    &::after {
        content: '';
       display: flex;
       width: 10px;
      height: 10px;
      margin: 3px;
       -moz-border-radius: 7.5px;
       -webkit-border-radius: 7.5px;
       border-radius: 7.5px;
       background-color: #fff;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: #2196F3;
    border: 1px solid #2196F3;
    &::after {
        content: '';
       display: flex;
       width: 10px;
      height: 10px;
      margin: 3px;
       -moz-border-radius: 7.5px;
       -webkit-border-radius: 7.5px;
       border-radius: 7.5px;
       background-color: #fff;
    }
  }
`;
export const FormGroup = styled.div`
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
 
    padding-bottom: 0.25rem;
    border-bottom: 2px solid transparent;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

`;
export const FormSubGroup = styled.div`
    width: calc(80% - 40px);
    align-items: baseline;
    flex-wrap: wrap;
    display: block;
 
    padding-bottom: 0.25rem;
    border-bottom: 2px solid transparent;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

`