import * as actionTypes from './actionTypes';
import Axios from '../../axios-proas';

export const accountingAuditStart = () => ({
    type: actionTypes.ACCOUNTING_AUDIT_START
})

export const accountingAuditSetPage = (page) => ({
    type: actionTypes.ACCOUNTING_AUDIT_SET_PAGE,
    currentPage: page
})

export const accountingAuditSetPageSize = (pageSize) => ({
    type: actionTypes.ACCOUNTING_AUDIT_SET_PAGESIZE,
    pageSize: pageSize
})

export const accountingAuditSuccess = (data, count) => ({
    type: actionTypes.ACCOUNTING_AUDIT_SUCCESS,
    data: data,
    count: count
});

export const accountingAuditFail = (error) => ({
    type: actionTypes.ACCOUNTING_AUDIT_FAIL,
    error: error
})

export const accountingAuditGetPage = (page, filters, sort, reverse, pageSize = 100) => {
    let newFilters = {
        page: page,
        pageSize: pageSize
    }
    if(filters){ 
        for (const key in filters) {
            if(filters[key])
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    if(sort){
        newFilters = {
            ...newFilters,
            orderBy: sort
        }
    }
    if(reverse){
        newFilters = {
            ...newFilters,
            reverse: reverse[sort]
        }
    }
    return dispatch => {
        dispatch(accountingAuditStart());
        Axios.get('/accountingaudit', {
            params: {...newFilters}
        })
        .then(response => {
            dispatch(accountingAuditSuccess(response.data.result, response.data.count))
        }).catch(err => {
            dispatch(accountingAuditFail(err.response?.data?.error))
        })
    }
}


export const accountingAuditSetFilterQuery = (filtersQuery) => ({
    type: actionTypes.ACCOUNTING_AUDIT_SET_FILTERSQUERY,
    filters: filtersQuery,
    filtering: accountingAuditUpdateFiltering(filtersQuery) 
});

export const accountingAuditUpdateFiltering = (filtersQuery) => {
    let filter = false;
    if(filtersQuery){
        for(const key in filtersQuery){
            if(filtersQuery[key]){
                filter = true
            }
        }
    }
    return filter
}

export const accountingAuditUpdateShowFilters = (show) => ({
    type: actionTypes.ACCOUNTING_AUDIT_UPDATE_SHOWFILTERS,
    showFilters: show
});

export const accountingAuditSetSortQuery = (sortQuery) => ({
    type: actionTypes.ACCOUNTING_AUDIT_SET_SORTQUERY,
    sortQuery: sortQuery
})

export const accountingAuditSetReverseSort = (reverse) => ({
    type: actionTypes.ACCOUNTING_AUDIT_SET_REVERSESORT,
    reverse: reverse
})

export const accountingAuditSetQuickFilter = (quickFilterField) => {
    return {
        type : actionTypes.ACCOUNTING_AUDIT_SET_QUICKFILTER,
        quickFilter : quickFilterField
    }
}

export const accountingAuditSelectRow = (rowId, selected) => {
    return {
        type: actionTypes.ACCOUNTING_AUDIT_SELECT_ROW,
        rowId: rowId,
        selected: selected
    }
}

export const accountingAuditSelectAllRows = (selectedAll) => {
    return {
        type: actionTypes.ACCOUNTING_AUDIT_SELECT_ALL_ROWS,
        selectedAll: selectedAll
    }
}