// import React, {useState, useEffect} from 'react';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import { CardContainer, CardHeader, CardInfos, CardIcon, CardPercentage, CardPercentageIcon, CardPercentageValue, CardTitle, CardBody, CardValue, ListContainer, SelectFilter, Option } from './Card.Styled';
// import { isArray } from 'lodash';

// function Card({ icon, isIncreasing, percentage, title, value, currency, clickHandler }) {
//     const [currencyStatus, setCurrencyStatus] = useState()
//     const [label, setLabel] = useState()
//     const [ options, setOptions] = useState()
//     useEffect(() => {
//         if(Array.isArray(value)){
//             setCurrencyStatus(true)
//             let op = (
//                 value?.map((item, index) => {
//                     return <Option key={index} value={item.currency}>{item.currency}</Option>
//             }))
//             setOptions(op)
//             setLabel(value[0].nbFactures + " factures | " + value[0].total)
//         }else {
//             setLabel(value)
//             setCurrencyStatus(false)
//         }

//     }, [value])
    

//     const handleCurrencyChange = (e) => {
//         let currency = e?.target?.value
//         if(currency== "EUR") setLabel(value[0].nbFactures + " factures | " + value[0].total)
//         else setLabel(value[1].nbFactures + " factures | " + value[1].total)
//     }

//     return (
//         <CardContainer onClick={() => {if(clickHandler) clickHandler()}} style={{cursor: clickHandler ? "pointer" : "inherit"}}>
//             <CardHeader>
//                 <CardInfos>
//                     <CardIcon>
//                         {icon}
//                     </CardIcon>
//                     {
//                         percentage && (
//                             <CardPercentage isIncreasing={isIncreasing}>
//                                 <CardPercentageIcon isIncreasing={isIncreasing}>
//                                     {
//                                         isIncreasing ?
//                                             <ArrowUpwardIcon style={{ fontSize: "0.8rem" }} />
//                                             :
//                                             <ArrowDownwardIcon style={{ fontSize: "0.8rem" }} />
//                                     }
//                                 </CardPercentageIcon>
//                                 <CardPercentageValue>
//                                     {isIncreasing ? '+' : '-'}
//                                     {percentage}
//                                     <span>%</span>
//                                 </CardPercentageValue>
//                             </CardPercentage>
//                         )
//                     }
//                     {
//                         currencyStatus &&
//                             <ListContainer>
//                                 <SelectFilter
//                                     onChange={(e) => handleCurrencyChange(e)}
//                                     onClick={e => e.stopPropagation()}
//                                     name="channel"
//                                 >
//                                     {options}
//                                 </SelectFilter>
//                             </ListContainer>
//                     }
//                 </CardInfos>
//                 <CardTitle>
//                     {title}
//                 </CardTitle>
//             </CardHeader>
//             <CardBody>
//                 <CardValue>
//                     {label}
//                 </CardValue>
//             </CardBody>
//         </CardContainer>
//     );
// }

// export default Card;







import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import { CardContainer, CardHeader, CardInfos, CardIcon, Option, SelectFilter, CardPercentage, ListContainer, CardPercentageIcon, CardPercentageValue, CardTitle, CardBody, CardValue } from './Card.Styled';
import { CAMPAIGNS_UPDATE_SHOWFILTERS } from '../../../store/actions/actionTypes';
import { useNavigate } from 'react-router-dom';
function Card({ icon, isIncreasing, percentage, title, value, list, currencies, navigateTo, clickFilters, message ,isUserProfile,width}) {
    const [valueOfList, setValueOfList] = useState(null);
    const [somInvoice, setSomInvoice] = useState(0);
    const [optionValue, setOptionValue] = useState(null);
    const [totalInvoice, setTotalInvoice] = useState(0);
    const navigate = useNavigate();
    const getSymbolWithKey = (key) => {
        const status = {
            EUR: "€",
            USD: "$",
            GBP: "£",
            DKK: "kr",
        }
        return status[key] ? status[key] : key;
    }
    const getLbelWithValue = (value) => {
        if(value){
                if (value > 1000) {
                    return (value / 1000).toFixed(1) + "K " + getSymbolWithKey(valueOfList);
                } else {
                    return (value / 1).toFixed(1) + getSymbolWithKey(valueOfList);
                }
       }else
        //  return "0"+getSymbolWithKey(valueOfList);
        return "0";

    }
   
useEffect(() => {
   if(currencies?.length>0){
    let op = (
        Object.values(currencies).map((item, index) => {
            return <Option key={index} value={item.currency}>{item.currency}</Option>
    }))
    setOptionValue(op)
    setValueOfList(currencies[0].currency)
   }
}, [currencies])
    useEffect(() => {
        if (list && value) {
            let u =Object.values(value).filter(item => item.currency === valueOfList);
            setTotalInvoice(u[0]?.total ? u[0]?.total : 0)
            setSomInvoice(getLbelWithValue(u[0]?.somme) ? getLbelWithValue(u[0]?.somme) : 0)
        }else
        setTotalInvoice(value)

    }, [value, valueOfList])

    const inputChangeHandler = (e) => {
        setValueOfList(e.target.value)
    }

    
    return (
        <CardContainer onClick={() => {if(navigateTo) navigate(navigateTo, { replace : false, state : clickFilters ? {...clickFilters, currency: valueOfList} : null })}} style={{width:width ?width : "30%", minWidth:width ?width : "30%", cursor: navigateTo ? "pointer" : "inherit"}}>
            <CardHeader>
                <CardInfos>
                    <CardIcon>
                        {icon}
                    </CardIcon>
                    {
                        percentage && (
                            <CardPercentage isIncreasing={isIncreasing}>
                                <CardPercentageIcon isIncreasing={isIncreasing}>
                                    {
                                        isIncreasing ?
                                            <ArrowUpwardIcon style={{ fontSize: "0.8rem" }} />
                                            :
                                            <ArrowDownwardIcon style={{ fontSize: "0.8rem" }} />
                                    }
                                </CardPercentageIcon>
                                <CardPercentageValue>
                                    {isIncreasing ? '+' : '-'}
                                    {percentage}
                                    <span>%</span>
                                </CardPercentageValue>
                            </CardPercentage>
                        )
                    }
                    <ListContainer>
                        {currencies?.length>0 && list && (
                            <SelectFilter
                                onChange={(e) => inputChangeHandler(e)}
                                onClick={e => e.stopPropagation()}
                                value={valueOfList}
                                name="valueOfList"
                                id="valueOfList"
                                style={{ width: "100px" }}

                            >
                        {optionValue}
                            

                            </SelectFilter>
                        )}
                    </ListContainer>
                </CardInfos>
                <CardTitle>
                    {title}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <CardValue>
                    {message==null? <p> {totalInvoice}&nbsp;facture{+totalInvoice>1?"s":null}&nbsp;|&nbsp;{somInvoice} TTC</p>:
                    <p>{totalInvoice} &nbsp;{message}{+totalInvoice>1?"s":null}</p> 
                    
                    }
                    {/* {totalInvoice}&nbsp;factures&nbsp;|&nbsp;{somInvoice} TTC */}
                </CardValue>
            </CardBody>
        </CardContainer>
    );
}

export default Card;
