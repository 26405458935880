import styled from 'styled-components';

export const IDProgressLineContainer = styled.ul`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const IDProgressLineItem = styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0.5rem 1rem 0.5rem;
    background-color: white;
    color: ${({color, theme}) => color ? color : theme.colors.secondary};
    border-bottom: ${({border}) => border ? `10px solid ${border}` : 'none'};
`

export const IDProgressLineLabel = styled.h4`
    font-size: 0.7rem;
    margin: 0;
    margin-right: 0.25rem;
    padding: 0;
    white-space: nowrap;

    @media ${({theme}) => theme.device.laptop} {
        font-size: 0.9rem;
    }
`

export const PLAction = styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${({theme}) => theme.colors.secondary};
    padding: 0 1.5rem 1rem 1.5rem;
    cursor: pointer;
    font-size: 1.2rem;
    white-space: nowrap;

    &:hover {
        color: ${({theme}) => theme.colors.primary};
    }

    @media ${({theme}) => theme.device.laptop} {
        font-size: 0.9rem;
    }
    `