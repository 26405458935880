import React, { useCallback, useEffect, useState, useRef } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import CategoryModal from './CategoryModal/CategoryModal';
import { Link } from 'react-router-dom';
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled.js';


function TableActions(props) {
    const {resetFilters, setShowFilters, showFilters, userType, userProfile, categories, addCategoriesHandle, deleteCategoryHandle, setCategoriesUpdates, saveCategoryHandle} = props;
    const [showCategories, setShowCategories] = useState(false)
    const {t} = useTranslation();
    const retour=()=>{
        setShowCategories(false)
        navigate("/");
    }
    return (
        <TAContainer>
            <TableAction title="Réinitialiser les filtres" onClick={() => resetFilters()}>
            {/* <span>{t("global:Rest")} </span> */}
            <ReplayIcon />
            </TableAction>
                <TableAction onClick={() => setShowFilters(!showFilters)}>
         
                    <span> {t("global:filters")}</span>
                    <FilterListIcon/>
                </TableAction>
                {
                    userType == "owner" && userProfile == "ADMIN" ? 
                    <>
                        <TableAction onClick={() => setShowCategories(!showCategories)}>
                            <span> {t("documentList:doCategory")}</span>
                            {/* <ExpandMoreIcon/> */}
                        </TableAction>
                        <TableActionLink  to= "/addDocumentation">
                            <span>{t('tableAction:addDocument',`Ajouter un document`)} </span>
                                    <AddIcon />
                        </TableActionLink >
                    </> : null
                }

            <CategoryModal 
                show={showCategories}
                modalClosed={() => retour(false)}
                confirm={() => retour(false)}
                cancel={() => retour(false)}
                title={"Catégories de document"}
                message={"messages"}
                categories={categories}
                addCategoriesHandle={addCategoriesHandle}
                deleteCategoryHandle={deleteCategoryHandle}
                saveCategoryHandle={saveCategoryHandle}
                setCategoriesUpdates={setCategoriesUpdates}
            />
                
           
        </TAContainer>
    )
}

export default TableActions
