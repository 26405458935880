import styled from 'styled-components/macro';
import { NavLink as LinkN, Link } from 'react-router-dom';

export const NavBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* width: 320px; */
    /* min-width: 320px; */
    width: 84px;
    /* height: calc(100vh - 45px); */
    height: 100vh;
    /* padding: 2rem 1rem; */
    position: sticky;
    top: 0;
    z-index: 800;
    background-color: #ff0000 ;
 

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        /* width: 240px;
        min-width: 240px; */
    }
`

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 60px ;
    height: 100%;
    margin: 0.5rem;
    margin-left: 9px;
`;
export const NavLogo = styled(Link)`
    display: flex;
    width: 55px;
    max-height: 83px;
    
    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        max-height: 40px;
    }
`
export const NavHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 23px;
    margin-bottom: 2rem;
    /* border-bottom: 1px solid #E1E1E2; */
    padding-top: 6px;
`
export const ReduceIcon = styled.div`
    display: flex;
    width: 61px;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;

    & svg {
        //color: #fff;
        width: 2rem;
        height: 2rem;
        fill: ${({theme}) => "#ff0000"};
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    flex: 1;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 0.9rem;

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        font-size: 0.8rem;
    }
`

export const NavItem = styled.li`
    margin: 0;
    width: fit-content;
    margin-bottom: 0.5rem;
    position: relative;
    padding-right:13px;
    margin-left: 13px;
    border-right: 2px solid ${({isActive, theme}) => isActive ? '#fff' : '#ff0000'};
`

export const NavIcon = styled.div`
    margin-bottom: 6px;

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        /* & svg {
            width: 20px;
        } */
    }
`

export const NavLink = styled(LinkN)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-right:0.5rem;
    /* background-color: #2174B9; */
    border-radius: 4%;
    color: ${({theme}) => "#fff"};
    text-decoration: none;

    & span {
        font-weight: 500;
        margin-left: 1rem;
    }

    &:hover {
        background: #fff 0% 0% no-repeat padding-box;
        /* color: ${({theme}) => theme.colors.primary}; */
        color:#ff0000 ;
        box-shadow: 0px 3px 6px #00000029;
        transition: all 0.5s ease-in-out;
    }

    &.${({activeclassname}) => activeclassname} {
        background: #fff 0% 0% no-repeat padding-box;
        & ${NavIcon} {
            /* color: ${({theme}) =>  theme.colors.primary}; */
            color:#ff0000 ;

            & path {
                /* fill: ${({theme}) =>  theme.colors.primary}; */
                fill:#ff0000 ;
            }
        }
        box-shadow: 0px 3px 6px #00000029;
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        /* height: 40px; */
    }
`

export const NavBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    margin-right:0.5rem;
    height: 48px;
 
    border-radius: 4px;
    color: ${({isActive, theme}) => isActive ? "#ff0000" :  "#fff"};
    text-decoration: none;
    cursor: pointer;
    box-shadow: ${({isActive, theme}) => isActive ? "0px 3px 6px #00000029" : "none"};
    background: ${({isActive, theme}) => isActive ? "#fff 0% 0% no-repeat padding-box":"#ff0000"};
    & span {
        font-weight: 500;
        margin-left: 1rem;
    }

    & path {
        fill: ${({isActive, theme}) => isActive ? "#ff0000" : "#fff" };
    }

    &:hover {
        background: #fff 0% 0% no-repeat padding-box;
        color: #ff0000;
        box-shadow: 0px 3px 6px #00000029;
        transition: all 0.5s ease-in-out;
        & ${NavIcon} {
         
        & path {
            /* fill:${({theme}) => theme.colors.primary}; */
            fill:#ff0000; ;
            }
          }
       }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        /* height: 40px; */
    }
`
 export const IconShowb = styled.div`
     display: flex;
    width: 25px;
    height: 25px;
    align-items: center;
    align-self: center;
    border-radius: 50%;
     margin-left: 12px;
     border: 0.5px solid #E9E9F2;
     background: #FFF; 
   
     cursor: pointer;
` 
export const IconShow = styled.div`
    display: flex;
    width: 25px;
    height: 25px;
    align-items: center;
    align-self: center;
    position: absolute;
    color: #000000;
    border-radius: 50%;
    margin-left: 87%;
  
    cursor: pointer;
    border-radius: 50%;
 
    border: 0.5px solid #E9E9F2;
    background: #FFF; 
    & svg {
        font-size: 0.9rem;
        margin-left: 5px;
        transform: rotate(180deg);
    }   
`;





export const NavFooter = styled.div`
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: black;
    width: 100%;
    font-size: 14px;
    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        font-size: 14px;
    }
`

