import React, { useState } from 'react'
import Axios from '../../../axios-proas';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import {formatDate,getNotyfObject,showColumn} from '../../../shared/utility'
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { ELDItems, ELDItemsContainer, ELDItemsListContainer, ELDVal, ELDValue, EntitiesListDetail, TD, TR } from '../../../styles/Table.styled';
import {DocDawnload,EntityComponent} from '../../../containers/Documentation/Documentation.Styled'
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmDelete from '../DocumentationVisualisation/DeleteDocumentationModal/ConfirmDelete';
function TableBody(props) {
    const {handleDocumentationVisibility, handleDocumentationDownload, documentationData, userRole, userLevel,userProfile,columns,resetFilters} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [visibility, setVisibility] = useState()
    const notyf = getNotyfObject();
   const [entitiesArray,setEntitiesArray]=useState(false)
    const [showModal,setShowModal]=useState(false)
    const [uidRow,setUidRow]=useState(null)  
    const ShowEntities=(a)=> {
  
        let array =documentationData.filter(b=>b.id==a)[0].entities
        let words = array.split(',')
        setEntitiesArray(words)
        var x = document.getElementById(a);
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }

    const formatEntities = (entities) => {
        return entities ? entities.split(',') : [];
    }
    

    const handleReadDocumentation = () => {

    }
    const deleteDocumentationHandler = (documantationRef) => {
        Axios.put('/documentation/' + documantationRef + '/deleteDocumentation').then(response => {
            if (response?.data?.success) {
                notyf.success("Succès de la suppression de la documentation")
                resetFilters()
            } else {
                // e.preventDefault()
                notyf.error("Erreur lors de la suppression de la documentation");
                reject(false);
            }
        }).catch(() => {
            notyf.error("Échec de la suppression de la documentation");
            reject(false);
        })
    }
   
    return (
        <>
            {
                documentationData ? documentationData.map((row,index) => (
                    <TR key={row.id} onClick={userLevel=="owner" ? () => navigate("/DocumentationView",{state:{uid:row.uid, entity:row.entity, type:"owner"}})  
                    :() => navigate("/DocumentationView",{state:{uid:row.uid, entity:row.entity, type:"others"}}) }>
                        <TD style={{display: !showColumn(columns,"visible") && 'none'}}>

                            {
                                userLevel == "owner" ?
                                    (
                                        row.visible == 1 ?  
                                        <CTooltip title={userProfile === "ADMIN" ? "Pour modifier la visibilité, cliquez sur cette icône" : ""}>
                                            <VisibilityIcon className="documentation_visibility" onClick={e => {e.stopPropagation(); if (userProfile === "ADMIN") handleDocumentationVisibility(row.uid, row.entity, 0)}} />
                                        </CTooltip>
                                        :   <CTooltip title={userProfile === "ADMIN" ? "Pour modifier la visibilité, cliquez sur cette icône" : ""}>
                                                <VisibilityOffIcon className="documentation_visibility" onClick={e => {e.stopPropagation(); if (userProfile === "ADMIN") handleDocumentationVisibility(row.uid, row.entity, 1)}}/>
                                            </CTooltip>
                                    ):

                                    (
                                        row.firstAccessDate != null  ?
                                            <VisibilityIcon />
                                            :<VisibilityOffIcon />)
                            }
                        </TD>
                        <TD style={{display: !showColumn(columns,"creationDate") && 'none'}}>
                            {formatDate(+row.creationDate)}
                        </TD>
                        <TD style={{display: !showColumn(columns,"docName") && 'none'}}>
                        <DocDawnload 
                        
                        
                            //    onClick={
                            //     userLevel=="owner" ?
                            //         () => handleDocumentationDownload(row.uid, row.entity, "owner")
                            //         : () => handleDocumentationDownload(row.uid, row.entity, "others") 
                            //     } 
                            >
                                {row.docName}
                            </DocDawnload>
                                {/* <span className="documentation_link" 
                                        onClick={
                                            userLevel=="owner" ?
                                                () => handleDocumentationDownload(row.uid, row.entity, "owner")
                                                : () => handleDocumentationDownload(row.uid, row.entity, "others") 
                                            }
                                        > {row.docName}</span> */}
                            
                        </TD>

                        <TD style={{display: !showColumn(columns,"docType") && 'none'}}>
                            <span>{row.docType}</span> 
                        </TD>
                        <TD style={{display: !showColumn(columns,"name") && 'none'}}>
                            <span>{row.name}</span> 
                        </TD>
                        {   
                            userLevel == "owner" ?  
                                <>
                                    <TD style={{display: !showColumn(columns,"entitiesType") && 'none'}}>
                                        <span>{t('documentList:'+row.entitiesType)}</span> 
                                    </TD>
                                    <TD style={{display: !showColumn(columns,"entitiesList") && 'none'}}>
                                        <EntitiesListDetail>
                                            <ELDValue>
                                                {row.entities}
                                            </ELDValue>
                                            <ELDItemsContainer> 
                                                <ELDItemsListContainer>
                                                    <ELDItems>
                                                        {
                                                            formatEntities(row.entities).map(entity =>(        
                                                                <ELDVal key={entity}  >
                                                                    {entity}
                                                                </ELDVal>
                                                            ))
                                                        }    
                                                    </ELDItems>
                                                </ELDItemsListContainer>
                                            </ELDItemsContainer>
                                        </EntitiesListDetail>
                                    </TD> 
                                    {userProfile === "ADMIN" &&
                                    <TD>
                                        <DeleteForeverIcon  onClick={(e) => {e.stopPropagation();setUidRow(row.uid);setShowModal(true)}} />
                                    </TD>
                          }
        
                                </>
                            :   null 
                        }
                        
                    </TR>
                )) : null
            }
            {
        showModal?(
            <ConfirmDelete handleClose={()=>setShowModal(false)}
              show={showModal}
              item={uidRow}
              deleteDocumentationHandler={deleteDocumentationHandler}
            />
        ):null
      }
        </>
    )
}

export default TableBody

