import styled from 'styled-components/macro';

export const SelectWrap = styled.div`
    /* width: 100%; */
    height: 100%;
`;

export const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    /* width: 150px; */
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 2px solid #D9E1E7;
    border-radius: 16px;
    color: #D9E1E7;
    cursor: pointer;
    min-width:300px; 
    min-width: ${({width}) => width ? '305px' : '170px'};
    width: fit-content;
    
    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        padding: 0.25rem 0.5rem;
        height:auto;
    }
`

export const SelectValue = styled.h4`
    font-size: 0.8rem;
    color: #fff;
    margin: 0;
    &:hover {
        color: #000;
    }

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        font-size: 0.6rem;
    }
`

export const SelectIcon = styled.div`
    display: flex;
    /* margin-left: 0.5rem; */
`;

export const SelectOptions = styled.ul`
    display: flex;
    width: 100%;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 2px solid #D9E1E7;
    border-radius: 16px;
    font-size: 00.9rem;
    background-color: ${({theme}) => theme.colors.content};
    position: relative;
    z-index: 101;
    align-items: center;
`

export const SelectOption = styled.li`
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    margin: 0;
    cursor: pointer;

    &:hover {
        background-color: #D9E1E7;
    }
`