import styled from 'styled-components/macro';

export const EventsContainer = styled.div`
    width: 21%;
    max-height: 62vh;
    display: flex;
    flex-direction: column;    
    margin-left: 10px;
    /* border-left: 1px solid red; */
    margin-top: 20px;
    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: 100%;
        height: 400px;
    }
`

export const EventsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 0.5rem 0;
`

export const EventsTitle = styled.h4`
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
`

export const EventsList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-y: auto;
`

export const EventsItem = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.7rem 0;
`

export const EventColor = styled.div`
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 50%;
    background-color: ${props => props.color};
    margin-right: 0.5rem;
`

export const EventValue = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: calc(100% - 84px);
`

export const EventVal = styled.h4`
    font-size: 0.9rem;
    font-weight: bold;
    margin: 0;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const EventMessage = styled.h6`
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
    color: ${({theme}) => theme.colors.secondary};
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const EventDate = styled.h6`
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
    color: ${({theme}) => theme.colors.secondary};
`

export const EventAction = styled.div`
    display: flex;
    align-items: center;
    color: #707070;
    cursor: pointer;

    & span {
        font-size: 0.9rem;
    }

    &:hover {
        color: ${({theme}) => theme.colors.secondary};
    }
`

export const EventFilterItem = styled.div`
    display: flex;
    align-items: center;
    margin: 0.25rem;
    cursor: pointer;
    width: 100%;

    & span {
        font-size: 0.9rem;
    }

`