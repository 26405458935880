import React, { useEffect, useMemo, useState } from 'react';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle } from './AuditTrailItem.styled';
//en premiere
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Spinner from 'react-bootstrap/Spinner'
//en derniere 

import {BtnOutlineLink} from '../../../styles/Common';
import ConfirmDelete from '../AuditTrailModal/ConfirmDelete';
import Axios from '../../../axios-proas'
import {getLabelWithKey,getNotyfObject} from '../../../shared/utility';
function AuditTrailItem({data,deleteAuditTrail,setCursor,cursor, refreshItem}) {
    const [showModal,setShowModal]=useState(false)
    const [refreshInterval, setRefreshInterval]=useState(null)
    const statutExtraction = useMemo(()=>[
        "ERROR",
        "PROGRESS",
        "WAITING"
    ],[]);
    let notyf = getNotyfObject();

    useEffect(() => {
        // clearInterval(refreshInterval);
        // if (data.status !== "DONE")
        //     setRefreshInterval(setInterval(() => {
        //         refreshItem(data.uid)
        //     }, 1000))
    }, [data.status])

    const formatCombinedDateStart= (date) => {
        if(date){
            const [start, end] = date.split(':')
            let startDate =+start
           
            return startDate
        }else 
            return ''
    }
    const formatCombinedDateEnd = (date) => {
        if(date){
            const [start, end] = date.split(':')
           // let startDate = formatDate(+start)
            let endDate = +end
            return endDate
        }else   
            return ''
    }
  
    const downloadExcel =() => {
        setCursor("wait")
        if (data.uid != null) {
            Axios.get("audittrail/" + data.uid + "/download", {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((response) => {
                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    const downloadUrl = URL.createObjectURL(blob)
                    const a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = data.title.replace("/", "-")+".xlsx";
                    document.body.appendChild(a);
                    a.click();
                    setCursor("pointer")
                } else {
                    console.log("Archive vide ou non valide");
                    notyf.error("Une erreur s'est produite")
                    setCursor("pointer")
                }
            }).catch((response) => {
                console.log("Une erreur s'est produite");
                notyf.error("Une erreur s'est produite")
                setCursor("pointer")
            });
        }
    }

    return (
        <CIContainer cursor={cursor}>
            <div style={{display:"flex",width: "600PX",justifyContent: "space-between"}}>
            <CITitle>
                PAF : {(data?.title)} 
            </CITitle>
            <CITitle>
               {getLabelWithKey(data?.status)}
            </CITitle>
            </div>
          
            <CIContent>
                <CIData>
                    <DateProgressLine
                        status={data?.status}
                        creationDate={+data?.creationDate}
                    />
                     
                </CIData>
                <CIActions>
                    <Spinner/>
                    {statutExtraction.includes(data.status)?( <Spinner onClick={() => Axios.get("audittrail//test")} animation="border" role="status"style={{color:"#EE5A5A66"}}/>):(
                      
                        <CIDeleteBtn
                        cursor={cursor}
                        color="#1c80d1" 
                        border="#1c80d1"
                        onClick={() => downloadExcel()} >
                        Télécharger
                    </CIDeleteBtn>
                    )}
                    
                    
                    <CIDeleteBtn onClick={() => setShowModal(true)}
                                color="#ff0000" 
                                border="#ff0000" >
                        Supprimer
                    </CIDeleteBtn>

                    {
                        showModal?(
                            <ConfirmDelete handleClose={()=>setShowModal(false)} 
                                           show={showModal}
                                           item={data?.uid}
                                           deleteAuditTrail={deleteAuditTrail}/>
                        ):null
                    }
                    <BtnOutlineLink
                        style={{height: "25px"}}
                        color="#06152B"
                        border="#06152B"
                        to={"/auditTrailForm/"+data.uid}
                        state= {{extractionData: data}}
                        >
                        Consulter  <ArrowRightIcon/>
                    
                    </BtnOutlineLink>
                 

                    
                </CIActions>
            </CIContent>
        </CIContainer>
    );
}

export default AuditTrailItem;
