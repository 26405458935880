import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, TD } from '../../../styles/Table.styled';
import {showColumn, formatDate} from '../../../shared/utility'
import DateFilter from '../../UI/DateFilter/DateFilter';

function TableFilters(props) {
    const {showFilters, filters, filtersChange, applyFilters, columns, unknownInvoicesArchived} = props;
    const {t} = useTranslation();

    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    const [dateInputValue, setDateInputValue] = useState({
        dueDate: filters?.dueDate ? convertDate(filters.dueDate) : '',
        statusDate: filters?.statusDate ? convertDate(filters.statusDate) : ''
    });
    const [showDateModal, setShowDateModal] = useState(false)
    const [dateField, setDateField] = useState('')
   
    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        if (e.target.value?.length >= 3 || e.target.tagName !== "INPUT")
            applyFilters(newFilters);
        else if (e.target.value?.length < 3 && filters[key]?.length >= 3) {
            newFilters[key] = "";
            applyFilters(newFilters);
        }
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }
    
    const getStatusDate = () => {
        return filters?.statusDate ? convertDate(filters.statusDate) : [];
    }

    const getDueDate = () => {
        return filters?.dueDate ? convertDate(filters.dueDate) : [];
    }
    
    const closeModalHandler = () => {
        setShowDateModal(false);
    }

    const dateShowHandler = (field) => {
        setShowDateModal({
            [field]: true
        })
        setDateField(field);
    }

    return (
        <>
            <tr style={{display: showFilters ? 'table-row' : 'none',
                position: "sticky",
                top: "38px",
                backgroundColor: "white",
                zIndex: "99"}}>
                <TD></TD>
                <TD style={{display: !showColumn(columns, "suCode") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                        onChange={(e) => inputChangeHandler(e)}
                        placeholder="Code SU"
                        value={filters?.suCode || ''} 
                        name="suCode" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "supplierCode") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)}
                        placeholder="Code tiers"
                        value={filters?.supplierCode || ''} 
                        name="supplierCode" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "number") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)} 
                        placeholder="Numéro de facture"
                        value={filters?.number || ''} 
                        name="number" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "xmlFileName") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.xmlFileName || ''} 
                        placeholder="Nom de fichier XML"
                        name="xmlFileName" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "statusCode") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.statusCode || ''} 
                            placeholder="Code AIFE"
                            name="statusCode" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "statusDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:statusDate", "Status change date")}
                            from={getStatusDate()[0] || ''}
                            to={getStatusDate()[1] || ''}
                            name="statusDate"
                            activeReset={filters?.statusDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={showDateModal?.statusDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "dueDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={"Date d'échéance calculée"}
                            from={getDueDate()[0] || ''}
                            to={getDueDate()[1] || ''}
                            name="dueDate"
                            activeReset={filters?.dueDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={showDateModal?.dueDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                {!unknownInvoicesArchived && <TD></TD>}
            </tr>
        </>
    )
}

export default TableFilters
