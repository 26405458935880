import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-proas';
import { getChannelWithKey, getNotyfObject, dayForIntervalMonth, dayForIntervalMonthWithKey } from '../../../shared/utility';
import { BarChartContainer, SelectDiv, TitleDiv } from './InvoicesPerChannelChart.styled';
import { useTheme } from 'styled-components';
import SupplierListModal from './SuppliersListModal/SupplierListModal';

function InvoicesPerChannelChart() {
    const {t} = useTranslation();
    const notyf = getNotyfObject()
    const theme = useTheme();
    const chartRef = useRef();
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
    const [chartLabels, setChartLabels] = useState([]);
    const [period, setPeriod] = useState("year");
    const [channel, setChannel] = useState("PAPER");
    const [data, setData] = useState({})
    const [showSuppliersModal, setShowSuppliersModal] = useState(false)

    const getInvoicesAndSuppliersPerMonth = () => {
        Axios.get("/invoice//ownerInvoicesPerChannel", {params: {period: period, channel: channel}}).then(response => {
            const currentMonth = new Date().getMonth();
           
            const invoicesCountTemp = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            const suppliersCountTemp = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            const periodsPerIndexTemp = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null];

            response?.data?.map(row => {
                let temp = period=="month" || period=="lastMonth" ? +row['invoiceMonth'].substr(8,2) : +row['invoiceMonth'].substr(5,2) - 1;
                let pos = period=="month" || period=="lastMonth" ? temp: temp - (currentMonth + 1) >= 0 ? temp - (currentMonth + 1) : (temp - (currentMonth + 1)) + 12
                let position = period == "month" || period=="lastMonth" ? dayForIntervalMonthWithKey(period === "month" ? 0 : -1)?.filter(c => c.key == pos)[0].value: pos

                invoicesCountTemp[position] = parseInt(row.invoicesCount)
                suppliersCountTemp[position] = parseInt(row.suppliersCount)
                periodsPerIndexTemp[position] = row.invoiceMonth;
            })
            let monthLabels = monthList.slice(currentMonth + 1, 12).concat(monthList.slice(0,currentMonth + 1))  
            
            setChartLabels(period=="year" ? monthLabels : dayForIntervalMonth(period === "month" ? 0 : -1));
            setData({invoicesCount: invoicesCountTemp, suppliersCount: suppliersCountTemp, periodsPerIndex: periodsPerIndexTemp});
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
            console.error(res)
        })  
    };

    const formatChannel = () => {
        if (channel === "EDI")
            return getChannelWithKey(channel);
        else 
            return getChannelWithKey(channel)?.toLowerCase();
    }

    useEffect( ()  => {
        getInvoicesAndSuppliersPerMonth()
    }, [period, channel])

    const barChartClickEvent = (event, array) => {
        if (array[1]) {
            const index = array[1]._index;
            const dateString = data.periodsPerIndex[index]; 
            let fromDate = null;
            let toDate = null;
            switch(period) {
                case "year":
                    fromDate = Date.parse(dateString.substr(0, 7) + "-01T00:00:00");
                    toDate = new Date(dateString.substr(0, 7) + "-01T00:00:00");
                    toDate.setMonth(toDate.getMonth() + 1);
                    toDate = toDate.getTime() - 1;
                    break;
                case "month":
                case "lastMonth":
                    fromDate = Date.parse(dateString + "T00:00:00");
                    toDate = new Date(dateString + "T00:00:00");
                    toDate.setDate(toDate.getDate() + 1);
                    toDate = toDate.getTime() - 1;
                    break;
                default:;
            }
            Axios.get("/invoice//ownerSuppliersPerChannel", {params: {period: fromDate+":"+toDate, channel: channel}}).then(response => {
                setShowSuppliersModal({suppliers: response.data, period: fromDate+":"+toDate});
            })
        }
    }

    const chartData ={
        labels: chartLabels,
        datasets: [
            {
                label: 'Factures reçues',
                data: data?.invoicesCount,
                backgroundColor: "#56296f",
                borderColor: "#a267c3",
                borderWidth: 1,
                stack: 0
            },
            {
                label: 'Fournisseurs',
                data: data?.suppliersCount,
                backgroundColor: "red",
                borderColor: "#ff4e4e",
                borderWidth: 1,
                stack: 1
            }
        ]
    }

    const options = {
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: true,
            fontSize: 16,
            position: 'top',
            align: "start",
            fontColor: theme.colors.text,
            text: period=="year"? "Récapitulatif des factures " + formatChannel() + " sur 12 mois" :
                    period == "month" ? "Récapitulatif des factures " + formatChannel() + " du mois en cours" :
                    period == "lastMonth" ? "Récapitulatif des factures " + formatChannel() + " du mois dernier" : ""
        },
        scales: {
            yAxes: [
                {
                    id: 'A',
                    position: 'left',
                    scaleLabel: {
                        display: false,
                        labelString: 'Nombre de factures'
                    },
                    gridLines: {
                        display: false,
                        color: "rgba(200,200,200,0.5)",
                    },
                    ticks: {
                        fontColor: '#1e1e1e',
                        beginAtZero: true,
                    },
                    stacked: true
                }
             ]
            ,
            xAxes: [{
                backgroundColor: "red",
                gridLines: {
                    display: true,
                    color: "#D9E1E766"
                },
                ticks: {
                    fontColor: "#1e1e1e",
                    fontSize: 14,
                    padding: 10,
                },
                position: "relative",
                stacked: true

            }]
        },
        legend: {
            display: true,
            position: "bottom",
            labels: {
                fontColor: 'black',
                fontSize: 12,
                padding: 10,
                boxWidth: 14,
            }
        },
        onClick: barChartClickEvent,
        hover: {
            onHover: function(e) {
                const point = this.getElementAtEvent(e);
                if (point.length && point[0]._datasetIndex === 1)
                    e.target.style.cursor = 'pointer';
                else 
                    e.target.style.cursor = 'default';
           }
        }
    }

    return (
        <BarChartContainer style={{height: "fit-content"}}>
            <TitleDiv>
                <span>Récapitulatif des factures</span>
            </TitleDiv>
            <Bar ref={chartRef} data={chartData} options={options} height={300} width={800} />
            <SupplierListModal data={showSuppliersModal} handleClose={() => setShowSuppliersModal(false)} channel={formatChannel()} />
            <SelectDiv>
                <div style={{width: "40%"}}>
                    <label style={{fontSize: "small"}}>Période :</label>
                    <select className="form-control" onChange={e => setPeriod(e.target.value)} value={period}>
                    <option value="month">Mois en cours</option>
                        <option value="lastMonth">Mois précédent</option>
                        <option value="year">Année en cours</option>
                    </select>
                </div>
                <div style={{width: "40%"}}>
                    <label style={{fontSize: "small"}}>Canal :</label>
                    <select className="form-control" onChange={e => setChannel(e.target.value)} value={channel}>
                        <option value="PAPER">Papier</option>
                        <option value="EMAIL">Email</option>
                        <option value="PORTAL">Portail</option>
                        <option value="EDI">EDI</option>
                    </select>
                </div>
            </SelectDiv>
        </BarChartContainer>
    )
}

export default InvoicesPerChannelChart
