import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PdfViewer from "../../../InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceView/PdfViewer/PdfViewer";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import {getNotyfObject} from "../../../../shared/utility";
import { CFBody, Coselect, CFContainer, CFFormGroup, CFFormSection, CFSection, FormInput, FormLabel } from "./DetailsModal.styled";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Axios from "../../../../axios-proas";
import EditIcon from '@mui/icons-material/Edit';
import InvoiceSide from '../../../InvoiceDetail/InvoiceBody/InvoiceSide/InvoiceSide';
import CustomConfirmModal from "../../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal";
import { CTooltip } from "../../../UI/CTooltip/CTooltip";
import { BtnAction, BtnsContainer, FormContainer, FormDiv, GroupBtns } from "../../../InvoiceDetail/InvoiceBody/InvoiceSide/TraitementForm/InvoiceDetail.styled";

function DetailsModal({
  show,
  modalClosed,
  invoice, 
  refreshTable,
  pdfFile,
  pdfFileName,
  loadingPdf,
  getInvoicePdf,
  statuses
}) {
  const { t } = useTranslation();
  const notyf = getNotyfObject();
  const [entitiesOwner, setEntitiesOwner] = useState();
  const [ownerGlobalFilter, setOwnerGlobalFilter] = useState([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [suppliersForFilter, setSuppliersForFilter] = useState([]);
  const [supplierGlobalFilter, setSetSupplierGlobalFilter] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceNewData, setInvoiceNewData] = useState({});
  const [initialInvoiceData, setInitialInvoiceData] = useState({});

  const [dataChecked, setDataChecked ] = useState({
    number: false,
    entity: false,
    codeClient: false,
    codeSupplier: false,
    supplier:false
  });

  useEffect(() => {
    if (invoice) {
      loadOwnerForFilter("");
      loadSuppliersForFilter("");
      getInvoicePdf(invoice?.invoiceUid, "pdf");
      setInvoiceData(invoice)
      setInvoiceNewData({number: invoice?.number, clientUid: invoice?.clientUid, supplierUid: invoice?.supplierUid});
      setInitialInvoiceData(invoice);

      return () => {
        setDataChecked({
          number: false,
          entity: false,
          codeClient: false,
          codeSupplier: false,
          supplier:false
        })
      };
    } else {
      setInvoiceData({});
      setInitialInvoiceData({});
    }
  }, [invoice])

  const handleSaveForm = () => {
    return new Promise((resolve, reject) => {
      Axios.put("invoice/" + invoiceData?.invoiceUid + "/details", invoiceNewData).then(response => {
        if (response.data.success) {
          modalClosed();
          refreshTable();
          notyf.success("Succès de la sauvegarde");
          resolve(true);
        } else {
          notyf.error("Erreur lors de la sauvegarde");
          reject(false);
        }
      }).catch(() => {
        notyf.error("Échec de la sauvegarde");
        reject(false);
      });
    });
  };

  const ownerChangeHandler = (e) => {
    if(e.value !==''){
        setInvoiceData({ ...invoiceData, clientUid: e.value, clientName: e.name , codeClient: e.code });
        setOwnerGlobalFilter(e);
    }else{
        setOwnerGlobalFilter([])
    }
  };

  const supplierChangeHandler = (e) => {
    if (e.value !=='') {
        setInvoiceData({ ...invoiceData, supplierUid: e.value, supplierName: e.name , codeSupplier: e.code});
      setSetSupplierGlobalFilter(e);
    }else{
      setSetSupplierGlobalFilter([])
    }
  };
    
  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const loadOwnerForFilter = input => {
    Axios.get("/entity//ownerForFilter?searchFilters="+input ).then(response => {
      setEntitiesOwner([{label: '-- Sélectionnez une entité --', value: ''}].concat(response?.data?.map(s => ({value: s.uid, label: s.code + " " + s.name, code: s.code, name:s.name, startCode: s.startCode}))));
    }).catch(err => {initialInvoiceData
      setEntitiesOwner([]);
    })
  }

  const loadSuppliersForFilter = input => {
    Axios.get("/entity//suppliersForFilter?searchFilters="+input ).then(response => {
      setSuppliersForFilter([{label: '-- Sélectionnez un fournisseur --', value: ''}].concat(response?.data?.map(s => ({value: s.uid, label: s.code + " " + s.name, code: s.code, name:s.name, startCode: s.startCode}))));
    }).catch(err => {
      setSuppliersForFilter([]);
    })
  }

  const validateBoxChangeHandler = (name) => {
    if(initialInvoiceData[name]!==invoiceData[name]) {
      setInvoiceNewData((prevState) => ({...prevState,[name]: invoiceData[name]}));
    } else {
      if(invoiceNewData[name]){
        let tmp = invoiceNewData
        delete tmp[name]
        setInvoiceNewData(tmp)
      }
    }
    setDataChecked({...dataChecked, [name]: false})
  }

  const checkBoxChangeHandler = (event) => {
    setDataChecked({...dataChecked, [event]: true})
  }

  const closeBoxChangeHandler = (event) => {
    let temp = invoiceNewData
    delete temp[event]
    setInvoiceNewData(temp)
    setDataChecked({...dataChecked, [event]: false})
    setInvoiceData({...invoiceData, [event]: initialInvoiceData[event]})
  }

  const closeBoxChangeHandlerCodeSu = (event) => {
    let temp = invoiceNewData
    delete temp["codeClient"]
    delete temp["clientName"]
    delete temp["clientUid"]
    setInvoiceNewData(temp)
    setInvoiceData({...invoiceData, codeClient: initialInvoiceData.codeClient,
                                    clientName: initialInvoiceData.clientName,
                                    clientUid: initialInvoiceData.clientUid})
    setDataChecked({...dataChecked, [event]: false})
  }

  const closeBoxChangeHandlerCodeTiers = (event) => {
    let temp = invoiceNewData
    delete temp["codeSupplier"]
    delete temp["supplierName"]
    delete temp["supplierUid"]
    setInvoiceNewData(temp)

    setInvoiceData({...invoiceData,codeSupplier: initialInvoiceData.codeSupplier,
                                supplierName: initialInvoiceData.supplierName,
                                supplierUid: initialInvoiceData.supplierUid})
    setDataChecked({...dataChecked, [event]: false})
  }

  const validateBoxChangeHandlerSu= (name) => {
    setInvoiceNewData((prevState) => ({
      ...prevState,
      clientUid: invoiceData["clientUid"],
      clientName: invoiceData["clientName"],
      codeClient: invoiceData["codeClient"]}));
      setDataChecked({...dataChecked, [name]: false})
  }   

  const validateBoxChangeHandlerCodeSupplier= (name) => {
    setInvoiceNewData((prevState) => ({
      ...prevState,
      supplierUid: invoiceData["supplierUid"],
      supplierName: invoiceData["supplierName"],
      codeSupplier: invoiceData["codeSupplier"]}));
      setDataChecked({...dataChecked, [name]: false})
  }

  return (
    <Modal show={show} onHide={modalClosed} dialogClassName="dialogModal" contentClassName="modal_w_mc">
      <Modal.Header closeButton>
        <Modal.Title>Information facture</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ADMCreationModal__body" style={{ width: "86vw" }}>
        <CFContainer>
          <CFBody>
            <CFSection style={{width: "30%"}}>
              <CFFormSection>
                <CFFormGroup>
                  <FormLabel htmlFor="title" style={!invoiceData?.number ? {color: "red"} : null}>Numéro de facture : </FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                  
                    placeholder="Information obligatoire"
                    value={invoiceData?.number || ""}
                    onChange={inputChangeHandler}
                    maxLength={255}
                    name="number"
                    disabled={!dataChecked?.number}
                  />
                  {(!dataChecked?.number?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("number")}/>:<>
                  <CTooltip title="Cliquer pour valider le changement de numéro de facture"><span><CheckIcon id="number" name="number" style={{cursor: "pointer", color: "green"}} onClick={(e) => validateBoxChangeHandler("number")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement de numéro de facture"><span><CloseIcon id="number" name="number"style={{cursor: "pointer", color: "red"}} onClick={(e) => closeBoxChangeHandler("number")}/></span></CTooltip></>)}
                </CFFormGroup>
                <CFFormGroup>
                  <FormLabel htmlFor="title" style={!invoiceData?.codeClient  ? {color: "red"} : null}>Code SU : </FormLabel>
                  {dataChecked?.codeClient ? (<>
                      <Coselect
                        name="codeClient"
                        value={ownerGlobalFilter}
                        onChange={(e) => ownerChangeHandler(e)}
                        onInputChange={input => loadOwnerForFilter(input)}
                        noOptionsMessage={() => "Aucun résultat"}
                        options={entitiesOwner}
                        isDisabled={!dataChecked?.codeClient}
                      />
                      <CTooltip title="Cliquer pour valider le changement d'entité"><span><CheckIcon id="codeClient" name="codeClient" style={{cursor: "pointer", color: "green"}}  onClick={(e) => validateBoxChangeHandlerSu("codeClient")}/></span></CTooltip>
                      <CTooltip title="Cliquer pour annuler le changement d'entité"><span><CloseIcon id="codeClient" name="codeClient" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandlerCodeSu("codeClient")}/> </span></CTooltip>
                  </>
                  ) : (<>
                      <FormInput
                        id="title"
                        type="text"
                        placeholder="Information obligatoire"
                        value={invoiceData?.codeClient||""} 
                        maxLength={255}
                        name="codeClient"
                        disabled={!dataChecked?.codeClient}
                      />
                      <EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("codeClient")}/>
                    </>
                  )}
                </CFFormGroup>
              
                <CFFormGroup>
                  <FormLabel htmlFor="title" style={!invoiceData?.clientName ? {color: "red"} : null}>Entité : </FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                    placeholder="Information obligatoire"
                    value={invoiceData?.clientName || ""}
                    maxLength={255}
                    name="entity"
                    disabled
                  />
                </CFFormGroup>
            <CFFormGroup>
             <FormLabel htmlFor="title" style={!invoiceData?.codeSupplier ? {color: "red"} : null}>Code tiers :</FormLabel>
             {dataChecked?.codeSupplier ? <>
                  <Coselect
                    name="codeSupplier"
                    noOptionsMessage={() => "Aucun résultat"}
                    placeholder={"Sélectionnez un fournisseur"}
                    options={suppliersForFilter}
                    onInputChange={input => loadSuppliersForFilter(input)}
                    value={supplierGlobalFilter}
                    onChange={(e) => supplierChangeHandler(e)}
                  />
                  <CTooltip title="Cliquer pour valider le changement de fournisseur"><span><CheckIcon id="codeSupplier" name="codeSupplier" style={{cursor: "pointer", color: "green"}}  onClick={(e) => validateBoxChangeHandlerCodeSupplier("codeSupplier")}/></span></CTooltip>
                  <CTooltip title="Cliquer pour annuler le changement de fournisseur"><span><CloseIcon id="codeSupplier" name="codeSupplier" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandlerCodeTiers("codeSupplier")}/></span></CTooltip>
                </>
                :<>
                  <FormInput
                    id="title"
                    type="text"
                    placeholder="Information obligatoire"
                    value={invoiceData?.codeSupplier || ""}
                    maxLength={255}
                    name="supplier"
                    disabled={!dataChecked?.codeSupplier}
                    />
                   <EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("codeSupplier")}/>
                </>
             }
                </CFFormGroup>
            
                <CFFormGroup>
                  <FormLabel htmlFor="title" style={!invoiceData?.supplierName ? {color: "red"} : null}>Nom fournisseur :</FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                    placeholder="Information obligatoire"
                    value={invoiceData?.supplierName|| ""}
                    maxLength={255}
                    name="supplier"
                    disabled
                  />

                </CFFormGroup>
                <CFFormGroup style={{alignItems: "center"}}>
                  <FormContainer>
                    <FormDiv>
                      <BtnsContainer>
                        <GroupBtns style={{margin: "0"}}>
                          <BtnAction
                            bg={"#ff0000"}
                            color={"#ff0000"}
                            minHeight="fit-content"
                            onClick={() => setShowSaveConfirmModal(true)}
                            style={{width: "100%", marginLeft: "0"}}
                            disabled={!invoiceData?.number || !invoiceData?.codeClient || !invoiceData?.codeSupplier || Object.keys(invoiceNewData).length === 0 && invoiceNewData.constructor === Object}
                            >
                            {`Sauvegarder les changements`}
                          </BtnAction>
                        </GroupBtns>
                      </BtnsContainer>
                    </FormDiv>
                    <CustomConfirmModal 
                      show={showSaveConfirmModal}
                      handleClose={() => setShowSaveConfirmModal(false)}
                      text={`Êtes-vous sûr de vouloir sauvegarder les données modifiées dans le formulaire ?`}
                      okCallback={handleSaveForm}/>
                  </FormContainer>
                </CFFormGroup>
              </CFFormSection>
            </CFSection>
            <CFSection style={{width: "44%"}}>
              {loadingPdf ? <Spinner /> : <PdfViewer pdfFile={pdfFile} pdfFileName={pdfFileName} />}
            </CFSection>
            <CFSection style={{width: "25%"}}>
             <InvoiceSide width="100%" statuses={statuses}/> 
            </CFSection>
          </CFBody>
        </CFContainer>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  invoice: state.invoiceDetail.invoiceData,
  loadingPdf: state.invoiceDetail.loadingPdf,
  pdfFile: state.invoiceDetail.pdfFile?.pdfFile || null,
  pdfFileName: state.invoiceDetail?.pdfFile?.docFile || "facture.pdf"
});
const mapDispatchToProps = (dispatch) => ({
  getInvoicePdf: (invoiceUid, type) =>
    dispatch(actions.getInvoicePdf(invoiceUid, type))
});
export default connect(mapStateToProps, mapDispatchToProps) (DetailsModal);
