import styled from 'styled-components/macro';
import Select from 'react-select';
export const HomeContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const HomeHeader = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 3px solid #F5FBFF;
`

export const HeaderInfos = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
`
export const TableGRContainer = styled.ul`
 display:flex ;
    margin: 1px;
    width: 100%; 
 
`
export const HeaderLocation = styled.h3`
    font-size: 1.5rem;
    font-weight: 800;
    color: #F00;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
`

export const HeaderTitle = styled.h4`
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    color: #1e1e1e;
`

export const HeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
`

export const HeaderAction = styled.div`
        height: fit-content;
    margin-left: 1rem;
    display: flex;
    justify-content: end;
`;




export const SearchInputContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem;
    border: 2px solid #D9E1E7;
    border-radius: 16px;
    color: #D9E1E7;

    &:focus-within {
        border: 2px solid #2174B9;
        color: #2174B9;
    }
`

export const SearchInput = styled.input`
    border: none;
    outline: none;
    color: black;
    font-size: 0.9rem;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: black;
    }
`

export const ActionWrapper = styled.div`
    display: flex;
    justify-content: space-between ;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem;
    border: 2px solid #D9E1E7;
    border-radius: 16px;
    color: #D9E1E7;
    cursor: pointer;
    flex-wrap: wrap;
`

export const ActionLabel = styled.h4`
    font-size: 0.9rem;
    color: black;
    margin: 0 0.5rem 0 0;
`
export const HomeBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const HomeCards = styled.div`
    width: 100%;
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-wrap: wrap;

    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* width: 240px;
        min-width: 240px; */
    }
`

export const HomeStats = styled.div`
    width: 100%;
    height: fit-content;
    /* height: 400px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
`

export const HomeInvoices = styled.div`
    width: 100%;
    border-top: 1px solid #D9E1E7CC;
   
    padding: 2rem 0;
    display:flex ;
`



export const Coselect = styled(Select)`
    border: none;
    border-style: none;
    outline: none;
    /* min-width: 170px; */
    flex: 1;
    text-align: centre;
    margin-left: 10px;
    max-width: 95%;
& .select__option {
//background-color: ${({theme}) => "red"} !important;
/* border-color: ${({theme}) => theme.colors.secondary} !important; */
}

& .select__control--is-focused {
 color:  ${({theme}) => "#000"} !important;
 
box-sizing: border-box;
}
& .select__control {
    border-style: none;
    display: flex;
    margin-bottom: -2px;
    align-self: end;
    align-items: end;
   
    border:1px solid ${({theme}) => theme.colors.primary};
}
& .select__control :hover{
  
    border:1px solid ${({theme}) => theme.colors.primary};
}

& .select__value-container{
    display: flex;
    align-items: end;
   
    
}
& .select__single-value{
    color:${({theme}) => theme.colors.text};
}
& .select__input-container{
    
    padding-top: 2px;
    visibility: visible;
    color: hsl(0, 0%, 20%);
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
    height:60px ;
}
/* background-color: red;
border-color: red;
box-sizing: border-box; */
& .select__clear-indicator {
visibility: hidden;
}

`;

export const CardContainer = styled.div`
    display:flex;
    width:49%;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: ${({theme}) => theme.size.laptop}) {
        width:100%;
    }
`

