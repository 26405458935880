
import logo from '../../assets/images/logotransparent.png';

import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavHeader, NavLogo, ReduceIcon, NavMenu, NavItem, NavLink, NavFooter, NavIcon, NavBtn, NavCounter,DivGrouping } from './NavBar.styled.js';
import SubNav from './SubNav/SubNav';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogoContainer } from '../../styles/Common';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { connect } from 'react-redux';
import { CTooltip } from '../UI/CTooltip/CTooltip';

function NavBar({toggleNav, items,activeLink, userType}) {
    const {t} = useTranslation();
    const [activeNav, setActiveNav] = useState(localStorage.getItem('activeNav') ? localStorage.getItem('activeNav') : '');
    const [lang, setLang] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr');
    //const [activeLink, setActiveLink] = useState(localStorage.getItem('activeNavLink') ? localStorage.getItem('activeNavLink') : '');

    const navigate = useNavigate();
    let location = useLocation()



    useEffect(() => {
        setLang(localStorage.getItem('lang') )
    }, [t])


    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key].find(p => value.includes(p.path)));
    }

    function getKeyByPath(array, pathValue) {
        for (let index = 0; index < array.length; index++) {
            const item = array[index];
            if(item.subMenu.find(e => pathValue.includes(e.path)))  
                return item.key;
        }
        return null
    }
  
    //reset active nav when changing route
    useEffect(() => {
        let btnItems = items.filter(item => !item?.isLink)
        getKeyByPath(btnItems, location.pathname) ? setActiveNav(getKeyByPath(btnItems, location.pathname)) : setActiveNav('');
        // setActiveLink(location.pathname)
        // localStorage.setItem('activeNavLink', location.pathname);
    }, [location.pathname, items])

    const navBtnClickHandler = (navTo) => {
        const isActive = activeNav === navTo;
        if(!isActive){
            let subMenuItems = items.find(item => item?.key === navTo).subMenu;
            localStorage.setItem('activeNav', navTo);
            setActiveNav(navTo);
            navigate(subMenuItems.filter(s => s.show === undefined || s.show)[0].path);
          
            // localStorage.setItem('activeNavLink', subMenuItems[0].path);
            // setActiveLink(subMenuItems[0].path)
        }
    }
    // const navBtnClickLink = (navTo) => {
     
    //     localStorage.setItem('activeNavLink', navTo);
    //     setActiveLink(navTo)
    // }
  
  return (
      <>
        <NavBarContainer>
            <NavHeader>
                <LogoContainer>
                    <NavLogo to={{pathname: "/"}}>
                            {userType=="client"?
                                <img  src={logo} alt="logo" />:
                                <img  src={logo} alt="logo" />
                         }
                    </NavLogo>
                </LogoContainer>
                <ReduceIcon onClick={toggleNav}>
                    <ArrowBackIosNewIcon style={{color:"#2196F3"}} /> 
                </ReduceIcon>
            </NavHeader>
            <NavMenu>
                {
                    (items && items.length > 0) && items.map((item) => {
                        if(item?.isLink)
                            return (
                                
                                <CTooltip key={item.key} title={item.tooltip || ""}><NavItem isActive={activeLink === item.pathname}>
 
                                    <NavLink  to={item.pathname} activeclassname="active">
                                        <DivGrouping>
                                            <NavIcon>
                                                {item.icon}
                                            </NavIcon>
                                            <span>{item.label}</span>
                                        </DivGrouping>
                                        {item.counter !== undefined && item.counter !== "0" && <NavCounter>{item.counter}</NavCounter>}
                                    </NavLink>
                                </NavItem></CTooltip>
                            )
                        else
                            return (
                                <CTooltip key={item.key} title={item.tooltip || ""}><NavItem>
                                    <NavBtn isActive={activeNav === item.key} onClick={() => navBtnClickHandler(item.key)}>
                                        <DivGrouping>
                                            <NavIcon>{item.icon}</NavIcon>
                                            <span>{item.label}</span>
                                        </DivGrouping>
                                        {item.counter !== undefined && item.counter !== "0" && <NavCounter>{item.counter}</NavCounter>}
                                    </NavBtn>
                                    {
                                        activeNav === item.key && <SubNav activeLink={activeLink} menuItems={item.subMenu.filter(s => s.show === undefined || s.show)} />
                                    }
                                </NavItem></CTooltip>
                            )
                    })
                }
            </NavMenu>
            <NavFooter>
             
                {/* <p>
                    {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)}
                </p> */}
            </NavFooter>
        </NavBarContainer>

      </>
  );
}


const mapStateToProps = state => {
    return {
   
      userType: state.auth.userType,
    
 
    }
  }
  
  const mapDispatchProps = dispatch => {
    return {
     // onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
  }
  export default connect(mapStateToProps, mapDispatchProps)(NavBar);