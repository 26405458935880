import React, {useCallback} from 'react'
import Axios from '../../../../axios-proas';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TD, TR } from '../../../../styles/Table.styled';
// import { CustomerLink } from './../CampaignLogs.Styled';
import { getChannelWithKey, getEntityStatusWithKey,showColumn } from '../../../../shared/utility';

function TableBody(props) {
    const {campaignLogsData, userRole,columns} = props;
    const history = useNavigate();
    const {t} = useTranslation();

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            var time = newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear() +'  ' + time
        }else return null;    
    }, [])
    
    
    
  
    return (
        <>
            {
                campaignLogsData ? campaignLogsData.map((row,index) => (
                    <TR key={row.id}>
                        <TD style={{display: !showColumn(columns,"creationDate") && 'none'}}>{ formatDate(+row.creationDate) }</TD>
                        <TD  style={{display: !showColumn(columns,"logType") && 'none'}}>
                                {row.logType}
                        </TD>
                        <TD style={{display: !showColumn(columns,"message") && 'none'}}>{row.message}</TD>
                        {/* <TD style={{display: !showColumn(columns,"status") && 'none'}}>
                            { getEntityStatusWithKey(row.status)}
                        </TD> */}
                    </TR>
                )) : null
            }
        </>
    )
}

export default TableBody
