import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Select from 'react-select';
export const EntDivContainer = styled.div`
    width:${props => props.width ? props.width : "47%"};
    display: flex;
    flex-direction: column; 
    margin-bottom:10px ;
    @media (max-width: ${({theme}) => theme.size.laptop}) {
        width:100%;
    }
`;
export const TitleDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom:15px ;
    `;
export const EntContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
    border: 1px solid #D9E1E7CC;
`;

export const NavTabsUl = styled.ul`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    list-style: none;
    margin: 8px 0 0 0;
    padding: 0;
    position: relative;
`;

export const NavTabsLi = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 3rem; */
    width: 100%;
    & :not(:first-child) {
        border-left: 1px solid;
    }
    /* background-color: white; */
    `

export const NavTabsLink = styled(Link)`
    font-weight: bold;
    color: ${({theme}) => theme.colors.secondary};
    border-bottom: ${({active, theme}) => active ? `3px solid ${theme.colors.secondary}` : 'none'};
    text-decoration: none;
    padding-bottom: 1px;
    font-size: 0.9rem;
    &:hover {
        color: ${({theme}) => theme.colors.secondary};
        border-bottom: 3px solid ${({theme}) => theme.colors.secondary};
    }
`

export const EntContent = styled.div`
    width: 100%;
    height: 307px;
    overflow:auto ;
 
`;
export const THead = styled.thead`
background-color: #f00;
height: 32px;
    width: 100%;
    color: white;
    font-size: 0.7rem;
    margin-top:4px ;
  
`


export const LITableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

export const EmptyResultMessageDiv = styled.div`
   text-align: center;
`;
export const Selectdiv = styled.div`
  width:45px;
  height:32px;
  position:absolute;
  background-color:red ;
`;

export const Table = styled.table`
    width: 100%;
    /* height: 100%; */
    table-layout: fixed;
margin-top:4px ;
    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: auto;
    }
`;

export const Coselect = styled(Select)`
    border: none;
    border-style: none;
    outline: none;
    /* min-width: 170px; */
    flex: 1;
    text-align: centre;
    margin-left: 10px;
    width: 95%;
& .select__option {
//background-color: ${({theme}) => "red"} !important;
/* border-color: ${({theme}) => theme.colors.secondary} !important; */
}

& .select__control--is-focused {
 color:  ${({theme}) => "#000"} !important;
 
box-sizing: border-box;
}
& .select__control {
    border-style: none;
    display: flex;
    margin-bottom: -2px;
    align-self: end;
    align-items: end;
   
    border:1px solid ${({theme}) => theme.colors.primary};
}
& .select__control :hover{
  
    border:1px solid ${({theme}) => theme.colors.primary};
}

& .select__value-container{
    display: flex;
    align-items: end;
   
    
}
& .select__single-value{
    color:${({theme}) => theme.colors.text};
}
& .select__input-container{
    
    padding-top: 2px;
    visibility: visible;
    color: hsl(0, 0%, 20%);
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
    height:60px ;
}
/* background-color: red;
border-color: red;
box-sizing: border-box; */
& .select__clear-indicator {
visibility: hidden;
}

`;