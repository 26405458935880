
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CIDeleteBtn } from '../../Extraction/ExtractionItem/ExtractionItem.styled';
import Select from '../Select/Select';

function SetErrorModal({show, closeModal, okCallback}) {
    const {t} = useTranslation();
    const [chosenError, setChosenError] = useState(null)
    
    const confirmClickHandler = () => {         
        okCallback(chosenError).finally(() => {
            closeModal();
        })
    }

    useEffect(() => {
        if (show)
            setChosenError(null);
    }, [show])

    return (
        <>
            <Modal show={show} onHide={closeModal} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>Confirmation d'assignation de code SU</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                        Êtes-vous sûr d'assigner ce code SU à ce document ? Vous devez choisir un code erreur à lui associer.
                    </p>
                    <Select
                    value={chosenError || "-- Veuillez choisir un code d'erreur --"} 
                    onChange={setChosenError} 
                    options={[
                        {value: null, label: "-- Veuillez choisir un code d'erreur --"}, 
                        {value:"err99", label: "err99 - Corbeille"}]}></Select>
                </Modal.Body>
                <Modal.Footer>
                    <CIDeleteBtn style={{fontSize: "1.3rem"}} onClick={closeModal}>
                        {t('default:cancel', "Annuler")} 
                    </CIDeleteBtn>
                    <CIDeleteBtn    style={{fontSize: "1.3rem"}}
                                    onClick={confirmClickHandler}
                                    disabled={!chosenError}
                                    color="#809FB8" border="#809FB8">
                        {t('default:confirm', "Confirmer")}
                    </CIDeleteBtn>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SetErrorModal

