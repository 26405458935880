import React, { useCallback, useRef, useState } from 'react'
import Axios from '../../../axios-proas';
import axios from 'axios';
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
 
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled';

 

function TableActions(props) {
    const {showFilters, filterState ,resetData   } = props;
    const {t} = useTranslation(); 
    return (
        <TAContainer>
            <TableAction title="Réinitialiser les filtres" onClick={() => resetData()}>
                {/* <span>{t("global:Rest")} </span> */}
                <ReplayIcon />
            </TableAction>
            <TableAction active={filterState} onClick={() => showFilters()}>
                <span>{t("global:filters")} </span>
                <FilterListIcon />
            </TableAction> 
        </TAContainer>
    )
}

export default TableActions
