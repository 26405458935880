import React from 'react'
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip';
import MandatoryMarker from '../../../components/UI/MandatoryMarker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function QREditor({ questionId, frQuestion, enQuestion, frAnswer, enAnswer, inputChangeHandler, visibilityChangeHandler, index, isPublic, deleteQuestionHandler}) {

    return (
        <div className="questionAnswer_form_container">
            <div className="form_element_container" style={{padding: "0 5px 5px 0", border: "1px solid #e7e7e7", borderRadius: "4px"}}>
                <div className="form_twoElements_container" style={{borderLeft: "10px red solid", padding: "6px"}}>
                    <label htmlFor="questionFr" style={{fontWeight: "bold"}}>Question<MandatoryMarker /></label>
                    <div style={{ display: "flex" }}>
                        <CTooltip title={<>
                                <div>Cliquez pour changer la visibilité de la question aux fournisseurs</div>
                                <div><VisibilityIcon /> : visible aux fournisseurs et à Transdev</div>
                                <div><VisibilityOffIcon /> : visible à Transdev uniquement</div>
                            </>} placement="left">
                            <div style={{display: "flex"}}>
                                {+isPublic ? <VisibilityIcon style={{cursor: "pointer", margin: "auto"}} onClick={() => visibilityChangeHandler(0, index)} /> : 
                                <VisibilityOffIcon style={{cursor: "pointer", margin: "auto"}} onClick={() => visibilityChangeHandler(1, index)} />}
                            </div>
                        </CTooltip>
                        <CTooltip title="Supprimer le couple question/réponse">
                            <DeleteForeverIcon className="deleteCategoryIcon" style={{marginLeft: "16px"}} onClick={() => deleteQuestionHandler(index)} />
                        </CTooltip>
                    </div>
                </div>
                <div style={{ padding: "5px 0 0 5px", display: "flex", justifyContent: "space-between" }}>
                    <div style={{width:"49%"}}>
                        <input
                            className="form-control"
                            type="text"
                            id={"questionFr" + questionId}
                            placeholder="Question"
                            value={frQuestion || ""}
                            onChange={(e) => inputChangeHandler(e, index)}
                            style={{ width: "100%" }}
                            name="questionFr"
                            maxLength="5000"
                        />
                    </div>
                    <div style={{borderLeft: "1px solid rgb(231, 231, 231)"}}></div>
                    <div style={{width:"49%"}}>
                        <input
                            className="form-control"
                            type="text"
                            id={"questionEn" + questionId}
                            placeholder="Question (anglais)"
                            value={enQuestion || ""}
                            onChange={(e) => inputChangeHandler(e, index)}
                            style={{ width: "100%" }}
                            name="questionEn"
                            maxLength="5000"
                        />
                    </div>
                </div>
            </div>
            <div className="form_twoElements_container" id={"answerFr" + index} style={{padding: "5px", border: "1px solid #e7e7e7", borderRadius: "4px"}}>
                <TextareaAutosize
                    className="form-control"
                    minRows="3"
                    type="text"
                    placeholder="Réponse"
                    value={frAnswer || ""}
                    onChange={(e) => inputChangeHandler(e, index)}
                    style={{ width: "49%" }}
                    name="answerFr"
                    maxLength="5000"
                />
                <div style={{borderLeft: "1px solid rgb(231, 231, 231)"}}></div>
                <TextareaAutosize
                    className="form-control"
                    minRows="3"
                    type="text"
                    placeholder="Réponse (anglais)"
                    value={enAnswer || ""}
                    onChange={(e) => inputChangeHandler(e, index)}
                    style={{ width: "49%" }}
                    name="answerEn"
                    maxLength="5000"
                />
                {/* <SunEditor setContents={question?.answerFr || ''} onBlur={suneditorChangeHandler} height="20vh" setOptions={{buttonList: buttonList.complex}} /> */}
            </div>
        </div>
    )
}

export default QREditor