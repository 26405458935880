import React, { useEffect, useState } from 'react'
import Axios from '../../../axios-proas';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index'

import DescriptionIcon from '@mui/icons-material/Description';

import MailIcon from '@mui/icons-material/Mail';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Card from '../Card/Card';

function StatHeader({userType}) {
    const [totalDocs, setTotalDocs] = useState(0);
    const [totalYearDocs, setTotalYearDocs] = useState(0);
    const [totalPurchases, setTotalPurchases] = useState(0);
    const [notViewedCount, setNotViewedCount] = useState(0);
    const [viewedCount, setViewedCount] = useState(0);
    const [dateInterval, setDateInterval] = useState(null)
    const [startDate, setStartDate] = useState(0)
    const [endDate, setEndDate] = useState(0)


    useEffect(() => {
        const date = new Date();
        var defaultStartDate = new Date();

        const startYear = new Date(date.getFullYear() + "").getTime();
        const endYear = new Date(date.getFullYear() + 1 + "").getTime();
        var d = defaultStartDate.getDate();
        defaultStartDate.setMonth(date.getMonth() - 1);
        if (defaultStartDate.getDate() != d) {
            defaultStartDate.setDate(0);
        }
        defaultStartDate.setHours(0,0,0,0);
        const startDate = dateInterval?.startDate || defaultStartDate.getTime();
        const endDate = dateInterval?.endDate || new Date().getTime();
        
        let query = '/invoice//clientStat?yearInterval='+startYear + ":" + endYear + '&dateInterval='+startDate + ":" + endDate;
        Axios.get(query).then((response) => {
            setTotalDocs(response.data.totalDocs);
            setTotalYearDocs(response.data.totalYearDocs);
            setNotViewedCount(response.data.notViewedDocs);
            setViewedCount(totalDocs - notViewedCount);
            setStartDate(startDate);
            setEndDate(endDate);
            setTotalPurchases(response.data.totalPurchases);
        })
    }, [dateInterval])

    const handleFilters = (dateInterval, clientFilters) => {
        setDateInterval(dateInterval);
    }
    
    return (
    <>
            <Card 
                value={totalDocs} 
                icon={<DescriptionIcon style={{color: "#0090FF"}} />}
                title="Total de documents"    
            />
            <Card 
                value={totalYearDocs} 
                icon={<DescriptionIcon style={{color: "#0090FF"}} />}  
                title="Total de documents de cette année"    
            />
            <Card value={totalPurchases +"€"} 
                  title={`Total des ${userType === 'owner' ? 'ventes' : 'achats'}`}
                  icon={<DateRangeIcon style={{color: "#0090FF"}} />} 
                  dateStart={startDate}
                  dateEnd={endDate}  
                  handleFilters={handleFilters} 
                  isClient={true}  
            />
            <Card 
                value={notViewedCount} 
                icon={<MailIcon style={{color: "#0090FF"}} />}
                title="Documents non lus "
                progress={((notViewedCount * 100) / totalDocs).toFixed(2)}
              
            />
        {/* <div className="row">
            <StatHeaderElementU value={totalDocs} icon={<DescriptionIcon />} progress='100' item="Total de documents" percentage="" textColor="#112732" containerBGColor="white" />
            <StatHeaderElementU value={totalYearDocs} icon={<DescriptionIcon />} progress='100' item="Total de documents de cette année" percentage="" textColor="white" containerBGColor="#5243ac" />
            
            <StatHeaderElementSales value={totalPurchases + " €"} item="Total des achats" dateStart={startDate} dateEnd={endDate} handleFilters={handleFilters} isClient={true} />
            <StatHeaderElementU value={notViewedCount} icon={<MailIcon />} progress={((notViewedCount * 100) / totalDocs).toFixed(2)} item="Documents non lus" percentage={((notViewedCount * 100) / totalDocs).toFixed(2) + "%"} textColor="white" containerBGColor="#112732" />
        </div> */}
    </>)
}



export default StatHeader
