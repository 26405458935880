import React from 'react'
import PanToolIcon from '@mui/icons-material/PanTool';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { TEContainer, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';

function RejectRebornRequestEvent({eventData}) {
    const {t} = useTranslation();

    return (
        <>
            <TEContainer>
                <TEHeader>
                    <TEIcon borderBold={true} bdColor={"orange"} color={"orange"} >
                        <PanToolIcon />
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={"orange"}>Demande de réouverture de rejet archivé</TETitle>
                        <TETime>
                            {eventData?.user ? (eventData?.user?.first_name || eventData?.user?.last_name ? (eventData?.user?.first_name || "") + " " + (eventData?.user?.last_name || "").trim() : eventData?.user?.login) + "\r\n" : (eventData?.noUser ? getLabelWithKey(eventData?.noUser) + "\r\n" : null)}
                            {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
            </TEContainer>
        </>
    )
}

export default RejectRebornRequestEvent