import React, {useEffect, useMemo, useState} from 'react';
import "./App.css";
import { ThemeProvider } from 'styled-components';
import { Navigate, Route, useLocation, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import {HelmetProvider} from 'react-helmet-async';
import GlobalStyles from './styles/Global';
import theme from './styles/theme';
import darkTheme from './styles/DarkTheme';
import clientTheme from './styles/clientTheme';
import supplierTheme from './styles/supplierTheme'
import Auth from './containers/Auth/Auth';
import ResetPassword from './components/Signin/ResetPassword/ResetPassword';
import ForgotPassword from './components/Signin/ForgottenPassword/ForgotPassword';
import OwnerRoot from './containers/AccesRoots/OwnerRoot/OwnerRoot';
import SupplierRoot from './containers/AccesRoots/SupplierRoot/SupplierRoot';
import ClientRoot from './containers/AccesRoots/ClientRoot/ClientRoot';
import InvoiceDetail from './containers/InvoiceDetail/InvoiceDetail';
import InvoiceToken from './containers/InvoiceToken/InvoiceToken';
import CampainToken from './containers/CampainToken/CampainToken';
import Axios from "./axios-proas";
import SupportContact from './components/Signin/SupportContact/SupportContact';
import { useTranslation } from 'react-i18next';

function App(props) {
  const {currentTheme, newprofile, onTryAutoSignup, onConnectFromSSO, isAuthenticated, userType, userLevel, authRedirectPath, userUid, userProfile} = props;
  const accessTokenList = ["document", "forgotPassword", "account", "campaign", "invoiceToken"];
  let location = useLocation();
  const navigate = useNavigate();
  const [ token ] = useSearchParams();
  const [statuses, setStatuses] = useState([])
  const {i18n} = useTranslation();

  useEffect(() => {
    if(token?.get("token")) {
      localStorage.setItem('token', token.get("token"))
      onConnectFromSSO()
    }
  }, [token])

  useEffect(() => {
    if(userUid)
      Axios.get('invoicestatus').then(response => {
        setStatuses(response.data);
      })
  }, [userUid])

  useEffect(() => {
    onTryAutoSignup(newprofile)
  }, [newprofile]);

// useEffect(() => {

//   return () => {
//     setProfile(null)
//   }
// }, []);

  useEffect(() => {
    const accessToken = accessTokenList.includes(location.pathname.split("/")[1]);
    if(authRedirectPath && !accessToken) {
      navigate('/');

    }
  }, [authRedirectPath]);

  let routes;

  if(isAuthenticated) {
    switch (userType) {
      case "supplier":
        routes = (
          <SupplierRoot statuses={statuses} />
        )
        break;
      case "client":
        routes = (
          <ClientRoot />
        )
        break;
      case "owner":
        if (i18n.language !== "fr")
          i18n.changeLanguage("fr")
        routes = (
          <OwnerRoot  userProfile={userProfile} userLevel={userLevel} statuses={statuses} />
        )
        break;
    
      default:
        break;
    }
  } else {
    routes = (
      <>
        <Routes>
          <Route path="/forgotPassword" element={<ForgotPassword />}/>
          <Route path="/contact" element={<SupportContact />}/>
          {/* <Route path="/invoiceToken/:token" element={<InvoiceToken statuses={statuses} />}/> */}
          <Route path="/account/:token" element={<ResetPassword />}/>
          {/* <Route path="/campaign/:token" element={<CampainToken />}/> */}
          <Route path="/" element={<Auth />} />
        </Routes>
      </>
    )
  }

  const getTheme = () => {
    if(currentTheme === "dark") {
      return darkTheme;
    }
    switch (userType) {
      case "client":
        return  clientTheme;
      case "supplier":
        return theme;
      case "owner":
        return theme;
      default:
        return theme;
    }
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <HelmetProvider>
        <GlobalStyles />
        <div className="app">
          {routes}
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    currentTheme: state.app.theme,
    // isAuthenticated: localStorage.getItem('token') !== null,
    isAuthenticated: state.auth.token !== null,
    loading : state.auth.loading,
    userType: state.auth.userType,
    userLevel: state.auth.userLevel,
    userProfile: state.auth.profile,
    userUid : state.auth.userUid,
    authRedirectPath: state.auth.authRedirectPath,
    newprofile: state.auth.newprofile
  }
}

const mapDispatchProps = dispatch => {
  return {
    onTryAutoSignup: (profile) => dispatch(actions.authCheckState(profile)),
    onConnectFromSSO: () => dispatch(actions.authCheckStateFromToken())
  }
}
export default connect(mapStateToProps, mapDispatchProps)(App);
