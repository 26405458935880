import React, { useCallback, useRef, useState ,useEffect} from 'react'  
import Axios from '../../../axios-proas'
import { TD, FilterContainer, SelectFilter, Option, InputFilter } from '../../../styles/Table.styled'
import { useTranslation } from 'react-i18next';
import {showColumn,getLabelWithKey} from '../../../shared/utility'

 

function TableFilters(props) {
    const {  filters, filtersChange, eventFilter, typeFilter , setTypeFilter, setEventFilter,  userProfile} = props;
    const {t} = useTranslation();
    
    const [mailTypes, setMailTypes] = useState([])
    const [mailEvents, setMailEvents] = useState([])
 
    useEffect(() => {   

        let params = {
            filterType: typeFilter,
            filterEvent: eventFilter
        } 
        Axios.get('/settingsemail//tabfilters',{params
            
        }).then(res => {
            let dataTypes = res.data?.types
            let dataEvents = res.data?.events
            if(dataTypes){  
                setMailTypes(dataTypes)
            } 
            if(dataEvents){  
                setMailEvents(dataEvents)
            }  
        }) 
    },  [typeFilter, eventFilter])

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters); 
    }

    const boundedSelectChangeHandler = (e) => { 
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        } 
        if(key === 'type'){
            newFilters.event = ""
        }
        if(key === 'type'){
            setTypeFilter(e.target.value)
            setEventFilter("")
        }else if (key === 'event')
            setEventFilter(e.target.value)
        filtersChange(newFilters); 
    }
 
    return (
        <>
            {
            <tr style={{position: "sticky",
                        top: "38px",
                        backgroundColor: "white",
                        zIndex: "99"}}>
                <TD>
                    <FilterContainer> 
                        <SelectFilter 
                            // onChange={(e) => inputChangeHandler(e)} 
                            onChange={(e) => boundedSelectChangeHandler(e)} 
                            value={filters?.type || ''} 
                            name="type" 
                            >
                            <Option value="">Type</Option>
                            {mailTypes?.map(s => <Option value={s.id}>{getLabelWithKey(s.type)}</Option>)}
                        </SelectFilter>
                    </FilterContainer>   
                </TD>
                <TD>
                    <FilterContainer> 
                        <SelectFilter 
                            onChange={(e) => boundedSelectChangeHandler(e)} 
                            value={filters?.event || ''} 
                            name="event" 
                            >
                            <Option value="">Evènement</Option>
                            {mailEvents?.map(s => <Option value={s.id}>{getLabelWithKey(s.event)}</Option>)}
                        </SelectFilter>
                    </FilterContainer>   
                </TD> 
                <TD>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Sujet"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.fr_subject || ''} 
                            name="fr_subject" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
       
            </tr>}
        </>
    )
}

export default TableFilters
