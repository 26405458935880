import React, { useRef, useState } from 'react'
import { Link, useNavigate} from 'react-router-dom';
import {CTooltip} from '../../UI/CTooltip/CTooltip';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { TD, TR } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import { UserStatus } from '../../../containers/UsersManagement/UsersManagement.styled';
import { getProfileWithKey } from '../../../shared/utility';

function TableBody(props) {
    const {
        data, 
        selectedUsers,
        selectUserRow,
        unselectUserRow,
        disableUser,
        enableUser,
        userType,
        userLevel,
        userUid,
        regions,
        entityFilter
    } = props;
    
    let textRef = useRef();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [userToDisable, setUserToDisable] = useState(null)
    const navigate = useNavigate();
    const {t} = useTranslation();
    const theme = useTheme();

    const getSelectedRowValue = (userUid) => {
        let isSelected = selectedUsers.findIndex(uid => uid === userUid) >= 0;
        // console.log(selectedUsers)
        // console.log(isSelected)
        return isSelected
    }

    const selectRowHandler = (e) => {
        let uid = e.target.name;
        let val = e.target.checked;
        if(val)
            selectUserRow(uid)
        else
            unselectUserRow(uid)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }

    const disableUserClickHandler = (userUid, userEmail) => {
        setUserToDisable({userUid, userEmail})
        setConfirmationModalShow(true);
    }

    const getTypeName = (type) => {
        switch (type) {
            case "owner":
                return "Interne"
                break;
            case "client":
                return "Client"
                break;
            case "supplier":
                return "Fournisseur"
                break;
            default:
                break;
        }
    }

    const getLevelName = (level) => {
        switch (level) {
            case "admin":
                return "Administrateur"
                break;
            case "standard":
                return "Standard"
            default:
                return level;
        }
    }
    
    return (
        <>    
            {
                data.map((row,index) => (
                    <TR key={row.userId} onClick={() => navigate('/userForm/'+row.userUid)}>
                        <TD style={{textAlign:'center'}}>
                            <input 
                                type="checkbox" 
                                name={row.userUid} 
                                onClick={e => e.stopPropagation()}
                                onChange={(e) => selectRowHandler(e)} 
                                checked={getSelectedRowValue(row.userUid)}  
                                className="form-check-input" 
                                style={{margin: 0}} 
                                id="exampleCheck1" 
                            />
                        </TD>
                        <TD >
                            <CTooltip title={row.first_name || ""} className="tableActions__icon">
                                <span>{row.first_name}</span>
                            </CTooltip>
                        </TD>
                        <TD >
                            <CTooltip title={row.last_name || ""} className="tableActions__icon">
                                <span>{row.last_name}</span>
                            </CTooltip>
                        </TD>
                        <TD >
                            <CTooltip title={row.login || ""} className="tableActions__icon">
                                <span>{row.login}</span>
                            </CTooltip>
                        </TD>
                        <TD >
                            <UserStatus
                                bg={Number(row.pending) ? theme.colors.warning : (Number(row.active) === 1 ? theme.colors.greenLight : theme.colors.redLight)}
                                color={Number(row.pending) ? "black" : (Number(row.active) === 1 ? theme.colors.success : theme.colors.danger)}
                            >
                                {Number(row.pending) ? "En attente" : (Number(row.active) === 1 ? t("global:yes", "Yes") : t("global:no", "Non"))}
                            </UserStatus>
                        </TD>
                        {/* { entityFilter === 'owner' ? 
                        (
                        <TD >
                            <CTooltip title={getTypeName(row.type) || ""} className="tableActions__icon">
                                <span>{getTypeName(row.type)}</span>
                            </CTooltip>
                        </TD>
                        ) : null
                        } */}
                        <TD >
                            <CTooltip title={t("user:"+row.level) || ""} className="tableActions__icon">
                                <span>{t("user:"+row.level)}</span>
                            </CTooltip>
                        </TD>
                        { entityFilter === 'owner' ? 
                        (
                            <TD >
                                <CTooltip title={getProfileWithKey(row.profile) || ""} className="tableActions__icon">
                                    <span>{getProfileWithKey(row.profile)}</span>
                                </CTooltip>
                            </TD>
                        ) : null
                        }
                        <TD  >
                            <CTooltip title={row.entities || ""} className="tableActions__icon">
                                <span ref={textRef}>
                                    {row.entities}
                                </span>
                            </CTooltip>
                        </TD>
                        { entityFilter === 'supplier' ? 
                        (
                            <TD >  
                                <CTooltip title={row.entitycodes || ""} className="tableActions__icon">
                                    <span>                                      
                                        {row.entitycodes}
                                    </span>
                                </CTooltip>
                            </TD>
                        ) : null
                        }
                        { entityFilter === 'owner' ? 
                        (
                            <TD >
                                {/* <CTooltip title={regions.find(r => r.id === row.regions)?.name || ""} className="tableActions__icon"></CTooltip> */}
                                <CTooltip title={row.regions || ""} className="tableActions__icon">
                                    <span>
                                        {/* {regions.find(r => r.id === row.region)?.name} */}
                                        {row.regions}
                                    </span>
                                </CTooltip>
                            </TD>
                        ) : null
                        }

                        {
                           

                            (userType === "owner" || (userType === "supplier" && userLevel == "admin")) &&
                            <TD className="userManag_td">
                                {row.userUid !== userUid && <> {
                                    Number(row.active) === 1 ? (
                                        <CTooltip title={t('global:deactivate', "Deactivate")}  className="tableActions__icon">
                                            <ToggleOnIcon 
                                                style={{marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer'}} 
                                                fontSize="small"
                                                onClick={e => {e.stopPropagation(); if(userLevel == "admin") disableUserClickHandler(row.userUid, row.login)}}
                                            />
                                        </CTooltip>
                                    ) : 
                                        <CTooltip title={t('global:activate', "Activate")}  className="tableActions__icon">
                                            <ToggleOffIcon 
                                                style={{marginLeft: '0.25rem', cursor: 'pointer'}} 
                                                fontSize="small"
                                                onClick={e => {e.stopPropagation(); if(userLevel == "admin") enableUser(row.userUid)}}
                                            />
                                        </CTooltip>
                                }
                                {/* <DeleteIcon 
                                    style={{marginLeft: '0.25rem', color: theme.colors.danger, cursor: 'pointer'}} 
                                    fontSize="small" 
                                    onClick={e => {e.stopPropagation(); disableUserClickHandler(row?.userUid, row?.login)}}
                                /> */}
                                </>}
                            </TD>
                          
                        }
                    </TR>
                    
                ))
                
            }
            <ConfirmationModal 
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => disableUser(userToDisable?.userUid)}
                cancel={() => setConfirmationModalShow(false)}
                title={t("user:confirmDeactivation", "Deactivation confirmation")}
                message={t("user:confirmDeactivationMessage", "Do you really want to deactivate this user?") + " (" + userToDisable?.userEmail + ")"}
            />
        </>
    )
}

export default TableBody
