import styled from 'styled-components/macro';

export const EntInfosContainer = styled.div`
    width: 100%;
    font-size: 0.9rem;
    margin-top: 1rem;
`;

export const EntOwners = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.5rem;
`;
export const EntOwnersList = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
`;

export const EntOwnersListItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    padding: 0.25rem;
    background-color: #2174B980;
    color: white;
    border-radius: 8px;
    margin-right: 0.5rem;

    & span {
        margin: 0 0.5rem 0 0;
    }
`

