import React, { useMemo } from 'react';
import { formatDate } from '../../../shared/utility';
import { DProgressLine, DProgressLineBar, DProgressLineItem, DProgressLineItemTitle, DProgressLineItemValue, DProgressLineStatus } from './DateProgressLine.styled';

function DateProgressLine({creationDate, status}) {
   
    const PLItems = useMemo(() => [
        {title: 'Création', value: new Date(+creationDate).setHours(0,0,0,0)}
    ], [creationDate]);

  return (
        <DProgressLine status={status}>
            {
                PLItems.map((item, index) => (
                    <DProgressLineItem key={index}>
                        <DProgressLineItemTitle>
                            <span>{item.title} : </span>
                            {/* {
                                currentDate >= item.value && (
                                    <DProgressLineBar 
                                        isFirst={index === 0}
                                        isCurrent={currentDate < PLItems[index + 1]?.value } 
                                    ></DProgressLineBar>
                                )
                            } */}
                        </DProgressLineItemTitle>
                        <DProgressLineItemValue>
                            <span>{formatDate(item.value)}</span>
                            {/* {
                                (
                                    currentDate >= PLItems[index + 1]?.value
                                    ||   currentDate >= endDate
                                ) && (
                                    <DProgressLineBar
                                        isLast={index === PLItems.length - 1}
                                    ></DProgressLineBar>
                                )
                            } */}
                        </DProgressLineItemValue>
                    </DProgressLineItem>
                ))
            }
        </DProgressLine>
  );
}

export default DateProgressLine;
