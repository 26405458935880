import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize : 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    sortQuery: 'creationDate',
    reverse: {
        creationDate: false
    }
}

const bandeauStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const bandeauSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false
})

const bandeauFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const bandeauSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const bandeauSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const bandeauSetSortQuery = (state, action) => updateObject(state,{
    sortQuery: action.sortQuery
})

const bandeauSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: {...action.reverse}
    }
}

const bandeauUpdateShowFilters = (state, action) => {
    return updateObject(state, {showFilters: action.showFilters})
}

const bandeauSetFiltersQuery = (state, action) => updateObject(state, {
        filtersQuery: {
            ...action.filters},
        filtering: action.filtering
    }
)

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.BANDEAU_START: return bandeauStart(state, action)
        case actionTypes.BANDEAU_SUCCESS: return bandeauSuccess(state, action)
        case actionTypes.BANDEAU_FAIL: return bandeauFail(state, action)
        case actionTypes.BANDEAU_SET_PAGE: return bandeauSetPage(state, action)
        case actionTypes.BANDEAU_SET_PAGESIZE: return bandeauSetPageSize(state, action)
        case actionTypes.BANDEAU_SET_FILTERSQUERY: return bandeauSetFiltersQuery(state, action)
        case actionTypes.BANDEAU_SET_SORTQUERY: return bandeauSetSortQuery(state, action)
        case actionTypes.BANDEAU_SET_REVERSESORT: return bandeauSetReverseSort(state, action)
        case actionTypes.BANDEAU_UPDATE_SHOWFILTERS: return bandeauUpdateShowFilters(state, action)
        default:
            return state
    }
}
export default reducer