import React, { useState, useEffect } from 'react';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import Axios from "../../axios-proas";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import InfoModal from '../../components/UI/InfoModal/InfoModal';
import Select from 'react-select';
import { FormLabel } from '../../styles/Common';
import { DocVisu, UIBody, UICol, UIContainer, UISection, UIFormContainer, UIFormHeader, UIFormHeaderTitle, UIFormStep, UIFormStepBody, UIFormStepDoneIcon, UIFormStepHeader, UIFormSubStepDoneIcon, UIFormSubStepNumber, UIFormStepHeaderTitle, UIFormStepNumber, FormGroup, FormSubGroup } from './UploadInvoice.styled';
import FileUploaderBtn from '../../components/UI/FileUploaderBtn/FileUploaderBtn';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Spinner } from 'react-bootstrap';
import { getNotyfObject } from '../../shared/utility';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from '../../components/UI/ConfirmationModal/ConfirmationModal'

function UploadInvoice() {
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    const [file, setFile] = useState("")
    const [fileName, setFileName] = useState(null)
    const [entities, setEntities] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [url, setUrl] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('');
    const [entity, setEntity] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [entityForUserSupplier, setEntityForUserSupplier] = useState();
    const [regionEntityForUserSupplier, setRegionEntityForUserSupplier] = useState();
    const [documentUploaded, setDocumentUploaded] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);

    const [entityForUserSupplierRegion, setEntityForUserSupplierRegion] = useState();

    const entityFilterOptions = [{value: "", label: "-- "+t("invoiceUpload:selectEntityFilter", "Sélectionnez un filtre d'entité")+" --"},
    {value: "all", label: t("invoiceUpload:seeAllEntities", "Voir toutes les entités")},
    {value: "invoicedEntities", label: t("invoiceUpload:seeBilledEntities", "Voir les entités précédemment facturées")},
    {value: "perRegion", label: t("invoiceUpload:seeRegionEntities", "Voir les entités par région")}];

    const getRegionEntityForUserSupplier = () => {
        Axios.get("/entity//AllRegionEntityForUserSupplier").then((response) => {
            let li=[];
            response?.data?.region?.map((item,i)=>{  
                let p={
                    value: item.uid,
                    label:  item.name 
                };
                li = li.concat(p);            
            });  
    
            setRegionEntityForUserSupplier([{label: '-- '+t("home:chooseRegion", "Select a region")+' --', value: ''}, ...li]);
        }).catch(res => {
            notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    }

    const getEntityForUserSupplier = () => {
        Axios.get("/entity//entityOwnerForUserSupplier", {
            params: {
                region : ''
            },
        }).then((response) => {
            let li=[];
            response?.data?.entity?.map((item,i)=>{
                let p={
                    value: item.uid,
                    label: item.name,
                };
                li = li.concat(p);
            });  

            setEntityForUserSupplier([{label: '-- '+t("home:chooseEntity", "Select an entity")+' --', value: ''}, ...li]);
        }).catch(res => {
            notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    }
    const getEntityForUserSupplierwFilter = () => {
        Axios.get("/entity//entityOwnerForRegion", {
            params: {
                region : selectedRegion
            },
        }).then((response) => {
            let li=[];
            response?.data?.entity?.map((item,i)=>{  
                let p={
                    value: item.uid,
                    label:item.name ,
                };
                li = li.concat(p);
            });  

            setEntityForUserSupplierRegion([{label: '-- '+t("home:chooseEntity", "Select an entity")+' --', value: ''}, ...li]);
        }).catch(res => {
            notyf.error(t("global:errorOccurred", "An error has occurred"));
        })
    }
    useEffect(() => {
        getEntityForUserSupplierwFilter();
    }, [selectedRegion]);

    useEffect(() => {
        getEntityForUserSupplier();
        getRegionEntityForUserSupplier();
    }, []);

    useEffect(() => {
        Axios.get('/entity//distinctOwners?orderBy=name&checkP2P=1').then(response => {
            let li=[];
            response?.data?.map((item,i)=>{  
                let p={
                    value: item.uid,
                    label:  item.name ,
                };
                li = li.concat(p);
            });  
    
            setEntities([{label: '-- '+t("home:chooseEntity", "Select an entity")+' --', value: ''}, ...li]);
        })
    }, [])

    const handleFileChange = e => {
        if (e.target.files && e.target.files[0] && e.target.name === "file"){
            if (e.target.files[0].type !== "application/pdf")
                notyf.error(t("invoiceUpload:pleaseChoosePdf", "Please choose a PDF file"));
            else if (e.target.files[0].size >= 10000000) 
                notyf.error(t("invoiceUpload:pleaseChoose10MB", "Please choose a file which size is lower than 10 megabytes"));
            else {
                setUrl(URL.createObjectURL(e.target.files[0]))
                setFile(e.target.files[0])
                setFileName(e.target.files[0].name);
            }
        }
    }

    const handleDeleteFile = () => {
        setUrl("");
        setFile("");
        setFileName(null);
        document.getElementById("uploader").value = "";
        filterInputChangeHandler({value: ""})
    }

    const uploadInvoiceHandler = () => {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('attachment', uploaderAttach.current.files[0]);
        // formData.append('field1', fields.field1);
        // formData.append('field2', fields.field2);
        formData.append('entity', entity);
        Axios.post("/invoice", formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(response => {
            if (response.data.message) {
                // setDocumentUploaded(true);
                // notyf.success(t("invoiceUpload:uploadSuccess"));
                handleDeleteFile();
                setShowModal(true);
            }
        }).catch((err) => {
            console.error(err);
            notyf.error(t("invoiceUpload:uploadFailed"));
        }).finally(() => {
            setUploadLoading(false);
        })
    }

    const confirmClickHandler = () => {
        setConfirmationModalShow(false)
        if(file){
            uploadInvoiceHandler()
        } else {
            notyf.error(t("invoiceUpload:emptyFile", "Please select a file to upload"));
        }
    }

    const filterInputChangeHandler = (e) => {
        setEntity('')
        setSelectedRegion('')
        setSelectedFilter(e.value);
    };

    const selectChangeSupplier = (e) => {
        setEntity(e.value);
    }
    const selectChangeRegion = (e) => {
        setSelectedRegion(e.value);
        setEntity('');
    }

    return (
        <>
            <UIContainer>
                <TitleHelmet title={"Transdev | " + t("menu:upload", 'Upload an invoice or a credit note')}  />
                <UIBody>
                    <UISection>
                        <UICol>
                            <UIFormContainer>
                                <UIFormHeader>
                                    <UIFormHeaderTitle>{t("invoiceUpload:instructions", "Instructions to upload an invoice/credit note")}</UIFormHeaderTitle>
                                </UIFormHeader>
                                <UIFormStep>
                                    <UIFormStepHeader>
                                        {
                                            url ? 
                                                <UIFormStepDoneIcon><DoneIcon /> </UIFormStepDoneIcon>
                                                :
                                                <UIFormStepNumber>1</UIFormStepNumber>
                                        }
                                        <UIFormStepHeaderTitle>
                                            {t("invoiceUpload:chooseFile", "I choose the invoice or credit note file to upload (only PDF files are accepted, within a limit of 10 megabytes)")}
                                        </UIFormStepHeaderTitle>
                                    </UIFormStepHeader>
                                    <UIFormStepBody>
                                        <FileUploaderBtn
                                            btnLabel={t("invoiceUpload:clickToSelect", "Click to select an invoice/credit note file")}
                                            handleChange={handleFileChange}
                                            name="file"
                                            style={{margin:"auto"}}
                                        />
                                        {file && <FormGroup style={{display: "block"}}>
                                            <FormLabel style={{margin: "5px 0", cursor: "default"}}>{fileName} <CloseIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDeleteFile()}></CloseIcon></FormLabel>
                                            <FormLabel style={{margin: "5px 0", cursor: "default", display: "block"}}><i>{t("invoiceUpload:notTheRightFile", "Not the right file?")}</i> {t("invoiceUpload:clickToDelete", "Click on the red cross icon to delete it")}</FormLabel>
                                        </FormGroup>}
                                    </UIFormStepBody>
                                </UIFormStep>

                                <UIFormStep disabled={!url}>
                                    <UIFormStepHeader>
                                        {
                                            entity ?
                                                <UIFormStepDoneIcon><DoneIcon /> </UIFormStepDoneIcon>
                                            :
                                                <UIFormStepNumber>2</UIFormStepNumber>
                                        }
                                        <UIFormStepHeaderTitle>
                                            {t("invoiceUpload:chooseTransdevEntity", "I select the Transdev entity that I billed")}
                                        </UIFormStepHeaderTitle>
                                    </UIFormStepHeader>
                                    <UIFormStepBody>
                                        <FormGroup>
                                            {selectedFilter ? <UIFormSubStepDoneIcon><DoneIcon style={{fontSize: "1.2em"}} /></UIFormSubStepDoneIcon>
                                                :
                                            <UIFormSubStepNumber>1</UIFormSubStepNumber>}
                                            <FormSubGroup>
                                                <FormLabel style={{marginBottom: "10px"}} htmlFor="footerImagePosition">{t("invoiceUpload:chooseEntityFilter", "I select the filter of entities:")}</FormLabel>
                                                <Select 
                                                    options={entityFilterOptions} 
                                                    defaultValue={entityFilterOptions?.[0]}
                                                    onChange={filterInputChangeHandler} 
                                                    noOptionsMessage={() => t("global:noResult", "No result")}
                                                    isDisabled={!url}
                                                />
                                            </FormSubGroup>
                                        </FormGroup>
                                        { selectedFilter === "all" && (
                                            <FormGroup>
                                                {entity ? <UIFormSubStepDoneIcon><DoneIcon style={{fontSize: "1.2em"}} /></UIFormSubStepDoneIcon>
                                                    :
                                                <UIFormSubStepNumber>2</UIFormSubStepNumber>}
                                                <FormSubGroup>
                                                    <FormLabel style={{marginBottom: "10px"}}>{t("invoiceUpload:chooseEntity", "I select the billed entity:")}</FormLabel>
                                                    <Select 
                                                        options={entities} 
                                                        defaultValue={entities?.[0]}
                                                        onChange={selectChangeSupplier} 
                                                        noOptionsMessage={() => t("global:noResult", "No result")}
                                                        isDisabled={!url}
                                                    />
                                                </FormSubGroup>
                                            </FormGroup>
                                        ) }
                                        { selectedFilter === "invoicedEntities" && (
                                            <FormGroup>
                                                {entity ? <UIFormSubStepDoneIcon><DoneIcon style={{fontSize: "1.2em"}} /></UIFormSubStepDoneIcon>
                                                    :
                                                <UIFormSubStepNumber>2</UIFormSubStepNumber>}
                                                <FormSubGroup>
                                                    <FormLabel style={{marginBottom: "10px"}}>{t("invoiceUpload:chooseEntity", "I select the billed entity:")}</FormLabel>
                                                    <Select 
                                                        options={entityForUserSupplier} 
                                                        defaultValue={entityForUserSupplier?.[0]}
                                                        onChange={selectChangeSupplier} 
                                                        noOptionsMessage={() => t("global:noResult", "No result")}
                                                        isDisabled={!url}
                                                        />
                                                </FormSubGroup> 
                                            </FormGroup>       
                                        )}
                                        { selectedFilter === "perRegion" && (<>
                                            <FormGroup>
                                                {selectedRegion ? <UIFormSubStepDoneIcon><DoneIcon style={{fontSize: "1.2em"}} /></UIFormSubStepDoneIcon>
                                                    :
                                                <UIFormSubStepNumber>2</UIFormSubStepNumber>}
                                                <FormSubGroup>
                                                    <FormLabel style={{marginBottom: "10px"}}>{t("invoiceUpload:chooseEntityRegion", "I select the region of the billed entity:")}</FormLabel>
                                                    <Select 
                                                        options={regionEntityForUserSupplier} 
                                                        defaultValue={regionEntityForUserSupplier?.[0]}
                                                        onChange={selectChangeRegion} 
                                                        noOptionsMessage={() => t("global:noResult", "No result")}
                                                        isDisabled={!url}
                                                    />
                                                </FormSubGroup> 
                                            </FormGroup>
                                            
                                        {selectedRegion && 
                                            <FormGroup>
                                                {entity ? <UIFormSubStepDoneIcon><DoneIcon style={{fontSize: "1.2em"}} /></UIFormSubStepDoneIcon>
                                                    :
                                                <UIFormSubStepNumber>3</UIFormSubStepNumber>}
                                                <FormSubGroup>
                                                    <FormLabel style={{marginBottom: "10px"}}>{t("invoiceUpload:chooseEntity", "I select the billed entity:")}</FormLabel>
                                                    <Select 
                                                        options={entityForUserSupplierRegion} 
                                                        defaultValue={entityForUserSupplierRegion?.[0]}
                                                        onChange={selectChangeSupplier} 
                                                        noOptionsMessage={() => t("global:noResult", "No result")}
                                                        isDisabled={selectedRegion == ""}
                                                    />
                                                </FormSubGroup>
                                            </FormGroup>}</>
                                        )}
                                    </UIFormStepBody>
                                </UIFormStep>

                                <UIFormStep disabled={!entity}>
                                    <UIFormStepHeader>
                                        {
                                            documentUploaded ?
                                                <UIFormStepDoneIcon><DoneIcon /> </UIFormStepDoneIcon>
                                            :
                                                <UIFormStepNumber>3</UIFormStepNumber>
                                        }
                                        <UIFormStepHeaderTitle>
                                            {t("invoiceUpload:confirmUpload", "I confirm the upload of my invoice/credit note by clicking on the button below")}
                                        </UIFormStepHeaderTitle>
                                    </UIFormStepHeader>
                                    <UIFormStepBody>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            {
                                                uploadLoading ?
                                                    <Spinner animation="border" variant="success" />
                                                :
                                                    <Button variant="danger" onClick={() => setConfirmationModalShow(true)} disabled={!entity}> 
                                                        {t("invoiceUpload:confirmUploadButton", "Confirm upload")}
                                                    </Button>
                                            }
                                        </div>
                                    </UIFormStepBody>
                                </UIFormStep>
                            </UIFormContainer>
                        </UICol>
                        {
                            url ? (
                                <DocVisu>
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                                    <Viewer fileUrl={url} />
                                </DocVisu>
                            ) : (
                                <DocVisu>
                                    {t('global:previewArea')}
                                </DocVisu>
                            )
                        }
                    </UISection>
                </UIBody>
                <InfoModal show={showModal} handleClose={() => setShowModal(false)} title={t("invoiceUpload:invoiceUploaded", "Invoice uploaded")} content={t("invoiceUpload:invoiceUploadedContent", "Your invoice is being processed, you can visualize its lifecycle in the Invoice tracking menu.")}></InfoModal>
            </UIContainer>

            <ConfirmationModal 
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={()=>confirmClickHandler()}
                cancel={() => setConfirmationModalShow(false)}
                title={t("invoiceUpload:confirmUploadButton", "Confirm upload")} 
                message={t("invoiceUpload:confirmUploadButtonMessage", "Does your invoice include our order number ?")}
            />
        </>
    )
}
export default UploadInvoice
