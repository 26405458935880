import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Axios from "../../axios-proas";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TitleHelmet from "../../components/UI/TitleHelmet/TitleHelmet";
import {
  BtnAction,
  BtnIcon,
  BtnOutlineAction,
  BtnOutlineLink,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  SearchInput,
  SearchInputContainer,
} from "../../styles/Common";
import {
  ESBody,
  ESCol,
  ESContainer,
  ESContent,
  ESSubject,
  ESView,
  ESViewActions,
  ESViewHeader,
  SubectInput,
  ESManualHeader
} from "./EmailsSettingsDetail.styled";
import { useTheme } from "styled-components";
import EmailEditor from "../../components/EmailsSettings/EmailEditor/EmailEditor";
import { Spinner } from "react-bootstrap";
import { CTooltip } from '../../components/UI/CTooltip/CTooltip'
import { getNotyfObject } from "../../shared/utility";

export default function EmailsSettingsDetail() {
    let {uid,  eventId} = useParams();
    const navigate = useNavigate(); 
    
    const [eventForExistingEmail,setEventForExistingEmail]=useState([])
    const [eventslist,setEventsList]=useState([])
    const [email, setEmail] = useState({
        type: "",
        title: "",
        fr_subject: "",
        manualSending: "0",
        fr_value: "",
            forAdministration: 0,
            forCustomerEntity: 1,
            forIndustrie: 0,
            forService: 0,
        forSupplierEntity: 0,
    }); 
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    const theme = useTheme(); 
    
    useEffect(() => { 
        reloadEmail(uid,eventId)
    }, [uid,eventId])

    const reloadEmail = (uid,eventId) => {
        if(uid){ 
            setIsLoading(true);
            Axios.get(`/settingsemail/${uid}/tsettingsemailData`)
            .then(response => { 
                setEmail(response.data.email)
                setEventForExistingEmail(response.data.variable);
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                notyf.error('Une erreur s\'est produite !')          
            })   
        }

        if(eventId){
            setIsLoading(true);
            Axios.get(`/settingsemail/${eventId}/eventData`)
            .then(response => {  
                let emailTmp = {
                    ...email,
                    type: response.data.event.type,
                    event: response.data.event.event,
                    forCustomerEntity: 0,
                    new: true
                } 
                setEmail(emailTmp)
                setEventsList(response.data.variable);
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                notyf.error('Une erreur s\'est produite !')          
            })   
        }
    } 

    const emailChangeHandler = (e) => {
        const {name, value} = e.target;
        setErrors([]);
        setEmail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }; 

    const emailContentChangeHandler = (content) => {
        setEmail((prevState) => ({
                ...prevState,
        fr_value: content,
            }));
    };

    const emailManualSendingChangeHandler = (manualSendingV) => {
        setEmail((prevState) => ({
                ...prevState,
                manualSending: manualSendingV,
            }));
    };
 
    const addEmail = () => {
        Axios.post("/settingsemail//email", email)
        .then((res) => {
            setIsLoading(false);
            if(res?.data?.success) {
                notyf.success(t("global:success", "Email ajouté avec succès"));
                let newUid = res?.data?.newUid 
                navigate("/emailsContentSettingsDetail/"+newUid)                
            } else {
                notyf.error(t("global:error", "Une erreur s'est produite"));
            }
        })
        .catch((err) => {
            setIsLoading(false);
            notyf.error(t("global:error", "Une erreur s'est produite"));
        });
    };

    const editEmail = () => {
        Axios.put(`/settingsemail/${uid}/email`, email)
        .then((res) => {
                setIsLoading(false);
            notyf.success(
            t("global:success", "La modification a été effectuée avec succès")
            );
        })
        .catch((err) => {
            setIsLoading(false);
        notyf.error(t("global:error", "Une erreur s'est produite"));
        });
    };

//     const sendEmail = () => {
//     Axios.put(`/settingsemail//sendEmail`)
//       .then((res) => {
//         notyf.success(
//           t("global:success", "La modification a été effectuée avec succès")
//         );
//       })
//       .catch((err) => {
//         notyf.error(t("global:error", "Une erreur s'est produite"));
//         });
//   };

    const saveEmailClickHandler = () => {  
        let errors = checkEmailForm();
        if (errors.length > 0) 
             setErrors(errors);
        else if (email?.new) {
            setErrors([]);
            setIsLoading(true);
            addEmail();
        }else {
            setIsLoading(true);
            setErrors([]);
            editEmail();
        }
    };


    const checkEmailForm = () => {
        let errors = []; 
        if (email?.fr_subject === "") {
            errors.push(t("global:errorSubject", "Le sujet est obligatoire"));
            }
        if (email?.fr_value === "") {
            errors.push(t("global:errorContent", "Le contenu est obligatoire"));
            }
        if (email?.type === "") {
            errors.push(t("global:errorType", "Le type est obligatoire"));
            }
            return errors;
    };

 
 

    const getparamsEmail = useCallback(() => {
        Axios.get("/documentation//mailParams")
        .then((res) => {
            const params = res?.data?.data;
            setEmailParams(params);
            setImageFooter(res?.data?.images?.imageFooter);
            setImageHeader(res?.data?.images?.imageHeader);
        })
        .catch((err) => {        
            notyf.error(t("global:error", "Une erreur s'est produite"));
        });
    }, []);



    const deleteTitle=()=>{  
        Axios.delete(`settingsemail/${uid}/deleteTitle`).then(response => {
            getCampaigns()
            setConfirmationModalShow(false)
            notyf.success("La campagne a bien été supprimée")
        }).catch(response => { 
            notyf.error("generatedErrorMessage")
        }) 
    }

    return (
        <> 
            <ESContainer>
             <TitleHelmet title={"Transdev | Gestion des emails"} />
                <Header>
                    <HeaderActions> 
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <BtnAction onClick={saveEmailClickHandler}>
                                <span>{t("global:save", "Save")}</span>
                            </BtnAction>
                        )}
                    </HeaderActions>
                </Header>
                <ESBody>
                    <ESContent> 
                        {errors && errors.length > 0 ? (
                        <div
                            className="alert alert-danger"
                            style={{ fontSize: "0.9rem" }}
                            role="alert"
                        >
                            <ul>
                                {errors.map((error) => (
                                <li key={error}>{error}</li>
                                ))}
                            </ul>
                        </div>
                        ) : null} 
                        <ESView className={email?.type|| email?.new ? "fadeIn" : "fadeOut"}>
                            {email?.type === "REJETS" &&
                                <ESManualHeader>
                                    <CTooltip title="Permet à ce mail d'être choisissable lors de la création d'un échange sur la facture">
                                        <FormLabel htmlFor="emailSubject" >Envoi manuel</FormLabel>
                                    </CTooltip>
                                    {+email?.manualSending == 1 ? (
                                        <CTooltip title="Désactiver l'envoi manuel"  className="tableActions__icon">
                                            <ToggleOnIcon 
                                                style={{marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer'}} 
                                                fontSize="small"
                                                onClick={e => {e.stopPropagation(); emailManualSendingChangeHandler("0")}}
                                            />
                                        </CTooltip>
                                    ) : 
                                        <CTooltip title="Activer l'envoi manuel"  className="tableActions__icon">
                                            <ToggleOffIcon 
                                                style={{marginLeft: '0.25rem', cursor: 'pointer'}} 
                                                fontSize="small"
                                                onClick={e => {e.stopPropagation();  emailManualSendingChangeHandler("1")}}
                                            />
                                        </CTooltip>}  
                                </ESManualHeader>
                            }
                            <ESViewHeader>
                                <ESViewActions>
                                <ESSubject style={{width: "100%"}}>
                                    <FormLabel htmlFor="emailSubject" >{t("emailSetting:Sujet")}</FormLabel>
                                    <SubectInput  
                                        style={{textAlign: "left"}}
                                        id="emailSubject"
                                        type="text"
                                        name="fr_subject"
                                        value={email?.fr_subject || ""}
                                        onChange={(e) => emailChangeHandler(e)}
                                        autoComplete="off"
                                    />
                                </ESSubject>
                                
                                    {email?.new ?
                                        eventslist?.map((item,i) =>(
                                            <CTooltip title={item.description} placement="right">
                                                <span style={{fontSize:"1rem",marginRight:"10px"}} key={i}> {item.variable}  </span>
                                            </CTooltip> 
                                            )
                                        ) :  eventForExistingEmail?.map((item,i) =>(
                                            <CTooltip title={item.description} placement="right">
                                                <span style={{fontSize:"1rem",marginRight:"10px"}} key={i}> {item.variable}  </span>
                                            </CTooltip> 
                                                )
                                        )
                                } 
                                </ESViewActions>
                            </ESViewHeader>
                        
                            <EmailEditor 
                                    content={email?.fr_value || ""}
                                    addTextHandler={emailContentChangeHandler} />
                                
                            </ESView>
                        </ESContent>  
                </ESBody>
            </ESContainer>
        </>
  );
}
