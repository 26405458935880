import styled from 'styled-components/macro';
export const CFContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const FormInput = styled.input`
    /* flex: 1; */
    border: none;
    outline: none;
    font-size: 15px;
    color: #6c7b89;
    flex: 1;

    /* width: 170px; */
    // text-align: right;
    &::placeholder {
        font-size: 12px;
    }
    &:invalid {
        border: 1px red solid;
        border-radius: 10px;
    }
`;
export const CFBody = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    flex-direction: column;
`
export const CFSection = styled.div`
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
`
export const CFFormSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`
export const ADDisabledTD = styled.td`
    padding-right: 16px;
    color: grey;
    text-align: right;
`
