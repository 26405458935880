import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTranslation } from 'react-i18next';
import { TH, THContainer, THead } from '../../../styles/Table.styled';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function TableHead(props) {
    const {columns, columnClickHandler, reverse, filters, userProfile} = props;
    const {t} = useTranslation();
    
    return (
        <THead>
            <tr>
                {columns.filter(row=>row.show==true).map(column => (
                            <TH key={column.field}
                                className={filters?.[column.field] && 'filtered'}
                                scope="col"
                                width={column?.width} 
                                onClick={() => columnClickHandler(column.field, reverse)}>
                        <CTooltip title={column.field == "visible" ? 
                            <>
                                <div><VisibilityIcon /> : visible aux fournisseurs et à Transdev</div>
                                <div><VisibilityOffIcon /> : visible à Transdev uniquement</div>
                            </>
                        : column.title}>
                                <THContainer>
                                <span>{column.title}</span>
                                    <div className="columnHead__sort">
                                        <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column?.field] == true ? 'block' : 'none' }} />
                                        <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column?.field] == false ? 'block' : 'none' }}/>
                                    </div>
                                </THContainer>    
                            </CTooltip>
                        </TH>
               
                ))}
                {userProfile === "ADMIN" &&
                     <TH   width= {"30px"} >
                        <THContainer>
                            <span>Action </span>
                            </THContainer>
                    </TH>}
         

            </tr>
        </THead>
    )
}

export default TableHead
