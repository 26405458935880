import React, { useCallback, useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
import { TableAction, TAContainer } from '../../../styles/Table.styled.js';
import '../../UI/Spinner/Spinner.css';
import Axios from "../../../axios-proas";
import { getNotyfObject } from "../../../shared/utility";
import CustomConfirmModal from '../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal.js';

function TableActions(props) {
    const {resetFilters, setShowFilters, showFilters, selectedRows, setSelectedRows, userProfile, unknownInvoicesArchived, handleRefresh} = props;
    const {t} = useTranslation();
    const notyf = getNotyfObject();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const hasSelection = () => {
        for (const key in selectedRows) {
            if (selectedRows[key])
                return true
        }
        return false
    }

    const deleteLines = useCallback(() => {
        return new Promise((resolve, reject) => {
            const selectedIds = [];
            for (const key in selectedRows)
                if (selectedRows[key])
                    selectedIds.push(key)
            
            if (selectedIds.length > 0)
                Axios.delete('/unknowninvoice//selectedLines?selection='+selectedIds).then((response) => {
                    notyf.success("Succès de la suppression");
                    setSelectedRows(null)
                    handleRefresh();
                    resolve(true);
                }).catch(err => {
                    notyf.error("Une erreur s'est produite lors de la suppression")
                    reject(false)
                })
        })
    },[selectedRows])

    return (
        <TAContainer>
            {+unknownInvoicesArchived && userProfile === "ADMIN" && hasSelection() ? <TableAction title="Supprimer les lignes sélectionnées" onClick={() => setShowDeleteModal(true)}>
                Supprimer les lignes sélectionnées
            </TableAction> : null}
            <TableAction title="Réinitialiser les filtres" onClick={() => resetFilters()}>
                <ReplayIcon />
            </TableAction>
            <TableAction onClick={() => setShowFilters(!showFilters)}>
                <span> {t("global:filters")}</span>
                <FilterListIcon/>
            </TableAction>
            <CustomConfirmModal 
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                text={`Êtes-vous sûr de vouloir supprimer les lignes sélectionnées ? Cette action est irréversible`}
                okCallback={() => deleteLines()}/>
        </TAContainer>
    )
}

export default TableActions
