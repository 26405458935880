import React, { useCallback, useEffect, useState } from 'react';
import Axios from '../../axios-proas';
import { useTranslation } from 'react-i18next';
import { BtnAction, FormGroup, FormInput, FormInputBorder, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common';
import { EPBody, EPContainer, EPFormWrapper, PasswdRules } from './EditPassword.styled';
import { useTheme } from 'styled-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getNotyfObject } from '../../shared/utility';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';

function EditPassword() {
    const notyf = getNotyfObject();
    const uid = localStorage.getItem('userId');
    const {t} = useTranslation();
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState([]);
    const [showErrors, setShowErrors] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [passwordConfirmationStatus, setPasswordConfirmationStatus] = useState(true);
    const [in10Previous, setIn10Previous] = useState(false);
    const theme = useTheme()
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const ResetPassword = (e) => {
        e.preventDefault();
        setError(null)
        if (password === confirmedPassword) {
            editPassword(password, currentPassword);
        } else {
            setError("authentication:differentPasswords")
        }
    }

    const editPassword = useCallback((newPassword, currentPassword) => {
        setError(null)
        Axios.put("/user/"+uid+"/changePassword", {
            password : newPassword, currentPassword : currentPassword
        }).then(response => {
            notyf.success(t("authentication:passwordResetSuccess"));
            setCurrentPassword('');
            setPassword('');
            setConfirmedPassword('');
        }).catch(err => {
            if(err.response?.data?.hasOwnProperty("passwordMatch"))
                notyf.error(t("authentication:passwordMatch", "Mot de passe saisi ne correspond pas au mot de passe actuel"))
            else
                notyf.error(t("authentication:passwordResetFail"))
        })
    }, [password])

    
    
    const inputChangeHandler = (e) => { 
        var passwd = e?.target?.value
        let tmpErrors = {}
        if(!passwd.match( /[0-9]/g)) tmpErrors['psswdNumber'] = t("authentication:passwordContainsDigit", "The password must contain a digit")
        if(!passwd.match( /[A-Z]/g)) tmpErrors['psswdUpperCharacter'] = t("authentication:passwordContainsUppercase", "The password must contain a capital letter")
        if(!passwd.match(/[a-z]/g)) tmpErrors['psswdLowerCharacter'] = t("authentication:passwordContainsLowercase", "The password must contain a lower case letter")
        if(!passwd.match( /[^a-zA-Z\d]/g)) tmpErrors['psswdSpecialCharacter'] = t("authentication:passwordContainsSpecial", "The password must contain a special character")
        if(passwd.length < 12) tmpErrors['psswdLength'] = t("authentication:passwordContains12Characters", "The password must contain at least 12 characters")
        // if(lastPassword!=null && (lastPassword.includes(passwd) && currentPassword.includes(passwd))) tmpErrors['notLikePrevious'] = "Le mot de passe doit être différent du précédent" 
        if(currentPassword!=null && (currentPassword.includes(passwd))) tmpErrors['notLikePrevious'] = t("authentication:passwordIsDifferent", "The password must be different from your 10 previous passwords")

        if(Object.keys(tmpErrors).length>0) setConfirmedPassword("")
        setErrors(tmpErrors)
    } 

    const passwdConfirmationHandler = (value) => {
        if(password == value) { 
            setPasswordConfirmationStatus(true) 
            return true 
        } else {
            setPasswordConfirmationStatus(false)
            return false
        } 
    }

    const checkPreviousPasswords = password => {
        Axios.get("user/"+uid+"/checkPreviousPasswords", {params: {hash: btoa(password)}}).then(response => {
            setIn10Previous(!response.data.allowed)
        })
    }


    // const getPasswordSymbolsErrors = () => {
    //         return <>
    //             {
    //                 Object.keys(passwdSymbolsTable).map(symbol => {
    //                     if(Object.keys(errors).indexOf(symbol)===-1)
    //                         return <li style={{color:"grenn"}}>{passwdSymbolsTable[symbol]}</li>
    //                     else return <li style={{color:"red"}}>{passwdSymbolsTable[symbol]}</li>
    //                 })
    //             }
    //         </>
    // }
    
    

    return (
        <EPContainer>
            <TitleHelmet title={"Transdev | " + t("authentication:changePassword", "Password change")} />
            <Header>
                <HeaderActions>
                    <BtnAction
                        color={theme.colors.success}
                        bg={theme.colors.greenLight}
                        onClick={(e) => ResetPassword(e)}
                        disabled={!currentPassword || !password || password !== confirmedPassword || Object.keys(errors).length>0 || in10Previous}
                    >
                        {t("global:save", 'Sauvegarder')}
                    </BtnAction>
                </HeaderActions>
            </Header>   
            <EPBody>
                {/* {
                    Object.values(errors).length > 0 && showErrors ?
                        <ul class="alert alert-danger" role="alert" style={{width:"30%",paddingLeft: "40px",fontSize: "13px"}}>
                            {
                                Object.values(errors).map(error => {
                                    return(
                                        <li>{error}</li>
                                    ) 
                                })
                            }
                        </ul> 
                    : null
                } */}
                <EPFormWrapper>
                    <FormGroup>
                        <FormLabel htmlFor="currentPassword" >{t("authentication:currentPassword")}</FormLabel>
                        <div>
                            <FormInputBorder 
                                id="currentPassword"
                                type={showCurrentPassword ? "text" : "password"} 
                                name="currentPassword" 
                                style={{paddingRight: "36px"}}
                                onChange={(e) => setCurrentPassword(e.target.value)} 
                                onPaste={e => {e.preventDefault(); return false}}
                                value={currentPassword} 
                                required 
                                autoFocus 
                            />
                            {showCurrentPassword ? <VisibilityIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowCurrentPassword(false)}/>
                            : <VisibilityOffIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowCurrentPassword(true)}/>}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="password" >{t("authentication:newPassword")}</FormLabel>
                        <div>
                            <FormInputBorder 
                                id="password"
                                type={showNewPassword ? "text" : "password"} 
                                name="password" 
                                style={{paddingRight: "36px"}}
                                onFocus={(e) => {setShowErrors(true); inputChangeHandler(e);}}
                                onBlur={(e) => {setShowErrors(false); checkPreviousPasswords(e.target.value)}}
                                onPaste={e => {e.preventDefault(); return false}}
                                onChange={(e) => {setShowErrors(true); setPassword(e.target.value); inputChangeHandler(e); }} 
                                value={password} 
                                required
                            />
                            {showNewPassword ? <VisibilityIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowNewPassword(false)}/>
                            : <VisibilityOffIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowNewPassword(true)}/>}
                        </div>
                    </FormGroup>
                    <FormGroup> 
                        <FormLabel htmlFor="confirmedPassword" >{t("authentication:confirmPassword")}</FormLabel>
                        <div>
                            <FormInputBorder 
                                id="confirmedPassword" 
                                type={showConfirmPassword ? "text" : "password"} 
                                name="confirmedPassword" 
                                disabled={password=="" || errors.length>0} 
                                style={{paddingRight: "36px", border: passwordConfirmationStatus || errors.length>0 ? '' : '1px solid red'}}
                                onPaste={e => {e.preventDefault(); return false}}
                                onChange={(e) => {setConfirmedPassword(e.target.value); passwdConfirmationHandler(e.target.value)}} 
                                value={confirmedPassword} 
                                required 
                            />
                            {showConfirmPassword ? <VisibilityIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowConfirmPassword(false)}/>
                            : <VisibilityOffIcon style={{position: "relative", right: "30px", cursor: "pointer"}} onClick={() => setShowConfirmPassword(true)}/>}
                        </div>
                    </FormGroup>

                </EPFormWrapper>

                <PasswdRules className="">
                    <li>
                        {t("authentication:passwordRules", "12 characters minimum among 90 symbols (from 0 to 9, from A to Z, from a to z and ! #$*% ? &[|]@^µ§ :/ ;.,<>°²³)")}
                    </li>     
                    {/* <li>
                        {passwdMaxObligatoire}
                    </li> */}
                    <div>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdNumber")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}} /> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContainsDigit", "The password must contain a digit")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdUpperCharacter")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContainsUppercase", "The password must contain a capital letter")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdLowerCharacter")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContainsLowercase", "The password must contain a lower case letter")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdSpecialCharacter")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContainsSpecial", "The password must contain a special character")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                ((Object.keys(errors).indexOf("psswdLength")===-1) && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordContains12Characters", "The password must contain at least 12 characters")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                (!in10Previous && password != "") ?
                                    <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/> : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordIsDifferent", "The password must be different from your 10 previous passwords")}
                        </p>
                        <p style={{ padding: "0px", marginBottom: "0px"}}>
                            {
                                (passwordConfirmationStatus && password != "") ?
                                <CheckBoxIcon style={{color:'#6CBB3C', marginRight:"5px"}}/>  : <CheckBoxOutlineBlankIcon style={{marginRight:"5px"}}/> 
                            }
                            {t("authentication:passwordsAreSimilar", "The two passwords must be similar")}
                        </p>
                    </div>

                </PasswdRules>

            </EPBody>

        </EPContainer>
    )
}

export default EditPassword
