import React, { useCallback, useEffect, useRef, useState } from "react"
import TableActions from '../../components/AccountingAuditTable/TableActions/TableActions';
import TableHead from '../../components/AccountingAuditTable/TableHead/TableHead';
import TableBody from '../../components/AccountingAuditTable/TableBody/TableBody';
import TableFilters from '../../components/AccountingAuditTable/TableFilters/TableFilters';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import Axios from "../../axios-proas";
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions } from '../../styles/Common';
import { NoContent, SearchInput, SearchInputContainer, SpinnerContainer, Table, TableAction, TBody } from '../../styles/Table.styled';
import { DContainer, DBody, DTableContainer } from './AccountingAuditTable.styled';
import { Spinner } from 'react-bootstrap';
import { formatDate, getNotyfObject } from "../../shared/utility";
import CustomConfirmModal from "../FAQ/CustomConfirmModal/CustomConfirmModal";
import { CSVLink } from "react-csv";
import Select from "react-select";
import NewAuditModal from '../../components/AccountingAuditTable/NewAuditModal/NewAuditModal';
import StatutoryAuditorsModal from "./StatutoryAuditorsModal/StatutoryAuditorsModal";

function AccountingAuditTable(props) {
    const {
        loading,
        accountingAuditData,
        count,
        currentPage,
        pageSize,
        getAccountingAuditPage,
        setAccountingAuditPage,
        setAccountingAuditPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userProfile,
        updateShowFilters,
        setAccountingAuditFilters,
        setSortQuery,
        setReverse,
        setAccountingAuditQuickFilter,
        selectedRows,
        selectedAllRows,
        setSelectedRows,
        setSelectedAllRows
    } = props;
    const { t } = useTranslation();
    const notyf = getNotyfObject();
    const [owner, setOwner] = useState(null)
    const [entitiesOwner, setEntitiesOwner] = useState([])
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const [showRemindModal, setShowRemindModal] = useState(false)
    const [showNewAuditModal, setShowNewAuditModal] = useState(false)
    const [showStatutoryAuditorModal, setShowStatutoryAuditorModal] = useState(false)
    const [auditors, setAuditors] = useState([]);
    const csvRef = useRef(null);
    const navTitre = "accountingAudit";

    useEffect(() => {
        Axios.get('/entity//distinctOwners?orderBy=code').then(response => {
            const temp = response?.data?.map(item =>
                ({
                    value: item.uid,
                    label: item.code + " " + item.name
                })          
            );
            setEntitiesOwner([{label: '-- Choisir une entité --', value: ''}, ...temp]);
        })
    }, [])

    useEffect(() => {
        Axios.get('/user//navColumns',  {params:{profile:userProfile, navTitre:navTitre}})
            .then(response => {
                let tempColumns = response?.data?.columns
                if (!tempColumns || tempColumns.length == 0) {
                    tempColumns = {suCode: 1, supplierCode: 1, contractRef: 1, thresholdDate: 1, sentDate: 1, statutoryAuditorEmail: 1};
                    for (const key in tempColumns)
                        Axios.put("user//navColumns", {bool: true, navTitre: navTitre, field: key});
                }
                let columnsCopy = [
                    { title: t("invoiceList:entityCode", "Code SU"), field: 'suCode', width: "40px", show: tempColumns.suCode },
                    { title: t("supplier:supplierCodefournisseur", "Code tiers"), field: 'supplierCode', width: "60px", show: tempColumns.supplierCode },
                    { title: "Contacts", field: 'contacts', width: "100px", show: tempColumns.contacts },
                    { title: "Date limite de la circularisation", field: 'thresholdDate', width: "100px", show: tempColumns.thresholdDate },
                    { title: "Date d'envoi du dernier mail", field: 'sentDate', width: "80px", show: tempColumns.sentDate },
                    { title: "Email commissaire aux comptes", field: 'statutoryAuditorEmail', width: "80px", show: tempColumns.statutoryAuditorEmail }
                ];
                setColumns(columnsCopy)
                columnsCopy.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns])

    useEffect(() => {
        if (filtersQuery?.owner)
            getAccountingAuditPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, pageSize])

    useEffect(() => {
        if (!showStatutoryAuditorModal)
            Axios.get("statutoryauditor").then(response => {
                setAuditors(response.data);
            })
    }, [showStatutoryAuditorModal])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    const handleExportCsv = () => {
        csvRef.current.link.click();
    }
    
    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getAccountingAuditPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setAccountingAuditPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        if (filters?.owner)
            getAccountingAuditPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setAccountingAuditFilters({owner: owner});
        setAccountingAuditPage(1)
        getAccountingAuditPage(1, {owner: owner}, sortQuery, reverse, pageSize)
    }

    const handleRefresh = useCallback(() => {
        getAccountingAuditPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const quickSearchChange = (quickSearchValue) => {
        let filtersTemp = {
            ...filtersQuery,
            quickSearch: quickSearchValue
        }
        setAccountingAuditQuickFilter('quickSearch')
        setAccountingAuditFilters(filtersTemp)
        if (quickSearchValue?.length >= 3)
            handleApplyFiltering(filtersTemp);
        else if (quickSearchValue?.length < 3 && filtersQuery?.quickSearch?.length >= 3) {
            filtersTemp.quickSearch = "";
            handleApplyFiltering(filtersTemp);
        }
    }

    const handleSelectOwner = e => {
        setOwner(e.value);
        setAccountingAuditFilters({...filtersQuery, owner: e.value})
        handleApplyFiltering({...filtersQuery, owner: e.value});
    }

    const remindSuppliers = suppliers => {
        return new Promise((resolve, reject) => {
            Axios.put("accountingaudit//remind", suppliers).then(response => {
                if (response.data.success) {
                    handleRefresh();
                    notyf.success("Succès de la relance");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors de la relance");
                    reject(false);
                }
            }).catch(err => {
                console.error(err)
                notyf.error("Échec de la relance");
                reject(false);
            })
        })
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : <NoContent><span>Aucune ligne à afficher</span></NoContent>;

    if (!loading && accountingAuditData?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            columns={columns}
            accountingAuditData={accountingAuditData}
            setShowRemindModal={setShowRemindModal}
            selectedRows={selectedRows} 
            setSelectedRows={setSelectedRows} />)
    }

    return (
        <DContainer>
            <TitleHelmet title={"Transdev | Circularisation"} />
            <div style={{width: "50%", margin:"0 auto 30px auto", zIndex: 100}}>
                <Select
                    placeholder={"-- Choisir une entité --"}
                    options={entitiesOwner} 
                    value={entitiesOwner?.find(option => option.value == owner)} 
                    onChange={handleSelectOwner} 
                />
            </div>
            {userProfile === "ADMIN" && <div style={{position: "relative", height: 0, display: "flex", justifyContent: "end", bottom: "74px"}}>
                <TableAction onClick={() => setShowStatutoryAuditorModal(true)}>
                    <span>Commissaires aux comptes</span>
                </TableAction>
            </div>}
            {owner && <><Header>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        <SearchIcon />
                    </SearchInputContainer>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        setShowNewAuditModal={setShowNewAuditModal}
                        exportCsv={handleExportCsv}
                        selectedRows={selectedRows}
                        setShowRemindModal={setShowRemindModal}
                    />

                </HeaderActions>
            </Header>
            <DBody>
                <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                    currentPage={currentPage}
                    pageChange={handlePageChange}
                    totalElement={count}
                    perPage={pageSize}
                    perPageChange={setAccountingAuditPageSize}
                    filters={filtersQuery}
                />
                <DTableContainer>
                 
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            filters={filtersQuery}
                            selectedAllRows={selectedAllRows}
                            setSelectedAllRows={setSelectedAllRows}
                        />
                        <TBody height={density}>
                            {showFilters && <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                filters={filtersQuery}
                                filtersChange={setAccountingAuditFilters}
                                applyFilters={handleApplyFiltering}
                            />}

                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                </DTableContainer>
            </DBody></>}
            <CustomConfirmModal
                show={showRemindModal}
                handleClose={() => setShowRemindModal(false)}
                okCallback={() => remindSuppliers(showRemindModal)}
                text={Object.keys(showRemindModal)?.filter(r => selectedRows[r])?.length > 1 ? "Voulez-vous relancer les fournisseurs sélectionnés ?" : "Voulez-vous relancer ce fournisseur ?"} />
            <NewAuditModal
                show={showNewAuditModal}
                handleClose={() => setShowNewAuditModal(false)}
                owner={owner}
                refreshTable={handleRefresh}
                auditors={auditors} />
            <StatutoryAuditorsModal
                show={showStatutoryAuditorModal}
                handleClose={() => setShowStatutoryAuditorModal(false)}
                auditorsInit={auditors}
            />
            <CSVLink
                ref={csvRef}
                data={accountingAuditData?.map(d => [d.suCode, d.supplierCode, d.contractRef, formatDate(+d?.thresholdDate) || "", formatDate(+d?.sentDate) || "", d.statutoryAuditorEmail]) || ""}
                filename={"circularisation.csv"}
                headers={columns?.map(c => c.title)}
                separator={";"}>
            </CSVLink>
        </DContainer>
    )
}
const mapStateToProps = (state) => ({
    loading: state.accountingAudit.loading,
    accountingAuditData: state.accountingAudit.data,
    count: state.accountingAudit.count,
    currentPage: state.accountingAudit.currentPage,
    pageSize: state.accountingAudit.pageSize,
    sortQuery: state.accountingAudit.sortQuery,
    reverse: state.accountingAudit.reverse,
    filtersQuery: state.accountingAudit.filtersQuery,
    showFilters: state.accountingAudit.showFilters,
    userProfile: state.auth.profile,
    selectedRows: state.accountingAudit.selectedRows,
    selectedAllRows: state.accountingAudit.selectedAllRows
})
const mapDispatchToProps = dispatch => ({
    getAccountingAuditPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.accountingAuditGetPage(page, filters, sort, reverse, pageSize)),
    setAccountingAuditPage: (page) => dispatch(actions.accountingAuditSetPage(page)),
    setAccountingAuditPageSize: (pageSize) => dispatch(actions.accountingAuditSetPageSize(pageSize)),
    setAccountingAuditQuickFilter: (page) => dispatch(actions.accountingAuditSetPage(page)),
    setSortQuery: (sortQ) => dispatch(actions.accountingAuditSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.accountingAuditSetReverseSort(reverseS)),
    setAccountingAuditFilters: (filters) => dispatch(actions.accountingAuditSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.accountingAuditUpdateShowFilters(show)),
    setSelectedRows: (rowId, selected) => dispatch(actions.accountingAuditSelectRow(rowId, selected)),
    setSelectedAllRows: (selectedAll) => dispatch(actions.accountingAuditSelectAllRows(selectedAll))
})
export default connect(mapStateToProps, mapDispatchToProps)(AccountingAuditTable)

