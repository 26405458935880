import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const CDContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const CDHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`; 
 
export const CDHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    margin-right: 2rem;
    margin-left: 10px;

    @media ${({theme}) => theme.device.laptop} {
        margin-right: 1rem;
        margin-left: 10px;
    }
`;

export const CDHeaderTitle = styled.h4`
    font-size: 1.5rem;
    font-weight: bold;
    align-self: center;


    color: ${({theme}) => theme.colors.text};

    @media ${({theme}) => theme.device.laptop} {
        font-size: 1rem;
    }
` 
 
export const CDBody = styled.div`
    width: 100%;
   // display: flex;
    justify-content: ${({accessToken}) => accessToken ? 'center' : 'space-between'};
    align-items: flex-start;
    margin-top: 2rem;
`
;
export const CDDivContent = styled.div` 
    width: 100%;
    table-layout: fixed;
    //background-color: #F5FBFF;
    padding-bottom: 60px;
    font-size: 1rem;
`;
export const CDFormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #F5FBFF;
`
export const AttachementsContainer = styled.div`
    flex: 1;
    display: flex;
   // justify-content: flex-end;
   align-self: center;
    flex-wrap: wrap;
`;

export const CDAttachementContainer = styled.div`
    cursor: pointer;
`;

 

 
 