import styled from 'styled-components/macro';



export const PVToolbar  = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
`;

export const PVToolbarElements = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PVToolbarElementsActions = styled.div`
    display: flex;
    align-items: center;
    & svg{
        stroke: #2196F3 !important;
        
    }
`

export const PVToHideMobile = styled.div`
    display: block;
    padding: 0px 2px;
    & svg{
        stroke: #2196F3 !important;
        
    }
  
    

    @media (min-width: 320px) and (max-width: 767px) {
        display: none;
    }
`


// export const PVContainer = styled.div`
//     height: 100vh;

//     & .rpv-default-layout-body {
//         background-color: #ffffff;
//     }

//     & .rpv-default-layout-toolbar {
//         background-color: rgba(25, 98, 158, 0.3);
//     }

//     & .rpv-default-layout-toolbar svg {
//         stroke: ${({ theme }) => theme.colors.text};
//     }

//     & .rpv-default-layout-sidebar-headers{
//         background-color: rgba(25, 98, 158, 0.3);
//     }

//     & .rpv-default-layout-sidebar-headers svg {
//         stroke: ${({ theme }) => theme.colors.text};
//     }

//     @media (min-width: 320px) and (max-width: 767px) {
//         .rpv-default-layout-sidebar {
//             display: none;
//         }
//         .rpv-default-layout-container{
//             z-index: 100;
//         }
//     }
// `;
export const PVContainer = styled.div`
    height: 100vh;

    & .rpv-default-layout-body {
        background-color: #ffffff;
    }

    & .rpv-default-layout-toolbar {
        background-color: #FFFFFF;
    }

    & .rpv-default-layout-toolbar svg {
        stroke: ${({ theme }) => theme.colors.text};
    }

    & .rpv-default-layout-sidebar-headers{
        background-color: #FFFFFF;
    }

    & .rpv-default-layout-sidebar-headers svg {
        stroke: ${({ theme }) => theme.colors.text};
    }
    & .rpv-default-layout-sidebar-tabs{
        display: ${({ valide }) => valide?  "block": "none"};;
    }
    & .rpv-default-layout-sidebar-header {
        display: none;
    }
    @media (min-width: 320px) and (max-width: 767px) {
        .rpv-default-layout-sidebar {
            display: none;
        }
        .rpv-default-layout-container{
            z-index: 100;
        }
    }
`;