import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Viewer, pdfjs, Worker } from "@react-pdf-viewer/core";
import Axios from "../../axios-proas";
import { useNavigate, useLocation } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import AddIcon from '@mui/icons-material/Add';
import { CTooltip } from '../../components/UI/CTooltip/CTooltip';
import EastIcon from '@mui/icons-material/East';
import NorthIcon from '@mui/icons-material/North';
import MandatoryMarker from '../../components/UI/MandatoryMarker';
import {
  NDContainer,
  DocVisu,
  NoContent,
  NDCol6,
  NDrow,
  ISContainer,
  EIFormGroup,
  EIFormLabel,
  Input,
  EIFormGroupChecked,
  ToggleDiv,
  InputCheckbox,
  EntitiesContainer,
} from "./NewDocumentation.styled";
import {
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  HeaderTitle,
  BtnOutlineAction,
  BtnAction,
} from "../../styles/Common";
import {
  TableAction,
  TableActionLink,
  TAContainer,
  SelectFilter,
} from "../../styles/Table.styled.js";
import FileUploaderBtn from "../../components/UI/FileUploaderBtn/FileUploaderBtn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

import { useTheme } from "styled-components";
import { getNotyfObject } from "../../shared/utility";
function NewDocumentation() {
  const theme = useTheme();

  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState();
  const [documentation, setDocumentation] = useState({
    documentationType: "fichier",
  });
  const [showError, setShowError] = useState(false)
  const [categoryName, setCategoryName] = useState("")
  const [file, setFile] = useState("");
  const [url, setUrl] = useState("");
  const [ifClient, setIfClient] = useState(false);
  const [ifSupplier, setIfSupplier] = useState(false);
  const [formData, setFormData] = useState();
  const [categories, setCategories] = useState();
  const [showNewCategoryInputField, setShowNewCategoryInputField] = useState(false);
  const notyf = getNotyfObject();
  const [categoriesUpdates, setCategoriesUpdates] = useState(false)
  const uploader = useRef(null);
  let location = useLocation();
  const navigate = useNavigate();
  const entRef = useRef();
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    entRef?.current?.scrollTo({
      bottom: 0,
      left: 0,
    });
    Axios.get("/documentation//categories").then((response) => {
      setCategories(response?.data?.categories);
    });
  }, [categoriesUpdates]);

  // useEffect(() => {
  //   Axios.get("/documentation//categories").then((response) => {
  //     setCategories(response?.data?.categories);
  //   });
  // }, [categoriesUpdates])

  const updateVisible = () => {
    if (visible) {
      setVisible(false);
      let docTempp = {
        ...documentation,
        visible: 0,
      };
      setDocumentation(docTempp);
    } else {
      setVisible(true);
      let docTemp = {
        ...documentation,
        visible: 1,
      };
      setDocumentation(docTemp);
    }
  };

  const handleSelectedClient = (e) => {
    setIfClient(true);
    setIfSupplier(false);
  };

  const handleSelectedSupplier = (e) => {
    setIfSupplier(true);
    setIfClient(false);
  };
  const entitiesRadioChangeHandler = (name, value) => {
    let docTemp = {
      ...documentation,
      [name]: +value,
    };
    setDocumentation(docTemp);
  };
  const deleteSupplierClickHandler = (supplier) => {
    let docTemp = { ...documentation };
    if (documentation?.id && !supplier?.new) {
      docTemp = {
        ...docTemp,
        suppliersToDelete: docTemp?.suppliersToDelete
          ? [...docTemp?.suppliersToDelete, supplier.uid]
          : [supplier.uid],
      };
    }
    let docSuppliers = [...documentation?.suppliers];
    let supplierIndex = documentation?.suppliers.findIndex(
      (ent) => ent.uid === supplier.uid
    );
    if (supplierIndex >= 0) {
      docSuppliers.splice(supplierIndex, 1);
    }
    setDocumentation({
      ...docTemp,
      suppliers: docSuppliers,
    });
  };

  const handleFileChange = (e) => {
    console.log(e);
    console.log(e?.target.files[0]);
    if (e?.target?.files[0]?.type == "application/pdf") {
      if (e?.target.files && e?.target.files[0]) {
        setUrl(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0].name);
      }
      console.log(e?.target.files[0]);
      let tmpDoc = {
        ...documentation,
        formData: e?.target.files[0],
        fileName: e?.target.files[0].name,
        fileType: e?.target.files[0].type,
      };
      setDocumentation(tmpDoc);
      console.log(tmpDoc);
    } else {
      setUrl("");
      notyf.error("Le format PDF est attendu");
    }
  };
  const addDocumentationclickHandlerOfLien = () => {
    let tmpDoc = {
      ...documentation,
      docUid: null,
    };
    Axios.post("documentation//documentation", tmpDoc).then((response) => {
      if (response?.data.success == true) {
        notyf.success("La documentation a bien été ajoutée !");
      } else {
        notyf.error("Cette documentation existe déjà !");
      }
      setUrl("");
      setIfClient(false);
      setIfSupplier(false);
      setVisible(false);
      setSelectedOption(null);
      setDocumentation([]);
    });
  };

  const addDocumentationclickHandler = () => {
    console.log(documentation);
    const formData = new FormData();
    formData.append("file", documentation.formData);
    console.log(documentation.formData);
    Axios.post("documentation//saveDocumentationFile", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response?.data.success) {
          console.log("docUid: " + response?.data?.docUid);
          let tmpDoc = {
            ...documentation,
            docUid: response?.data?.docUid,
          };
          Axios.post("documentation//documentation", tmpDoc).then(
            (response) => {
              if (response?.data.success == true) {
                notyf.success("La documentation a bien été ajoutée !");
              } else {
                notyf.error("Cette documentation existe déjà !");
              }
              setUrl("");
              setIfClient(false);
              setIfSupplier(false);
              setVisible(false);
              setSelectedOption(null);
              setDocumentation([]);
            }
          );
        }
      })
      .catch((err) => {
        notyf.error("Cette documentation existe déjà !");
      })
      .finally(() => {
        setFile(null);
        uploader.current.value = "";
      });
  };

  const suppliersChangeHandler = (suppliersList) => {
    console.log(suppliersList);
    let docTemp = {
      ...documentation,
      suppliers: suppliersList,
      clients: null,
    };
    setDocumentation(docTemp);

    setIfClient(false);
  };

  const deleteClientClickHandler = (client) => {
    let docTemp = { ...documentation };
    if (documentation?.id && !client?.new) {
      console.log(docTemp);
      docTemp = {
        ...docTemp,
        clientsToDelete: docTemp?.clientsToDelete
          ? [...docTemp?.clientsToDelete, client.uid]
          : [client.uid],
      };
      console.log(docTemp);
    }
    let docClients = [...documentation?.clients];
    let clientIndex = documentation?.clients.findIndex(
      (ent) => ent.uid === client.uid
    );
    if (clientIndex >= 0) {
      docClients.splice(clientIndex, 1);
    }
    setDocumentation({
      ...docTemp,
      clients: docClients,
    });
  };
  const clientsChangeHandler = (clientsList) => {
    let docTemp = {
      ...documentation,
      clients: clientsList,
      suppliers: null,
    };
    setDocumentation(docTemp);
    setIfSupplier(false);
  };

  const inputChangeHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    console.log(e.target);
    if (name == "visible") {
      if (e.target.checked) {
        value = 1;
        setIfSupplier(false);
        setIfClient(false);
      } else value = 0;
    }
    let docTemp = {
      ...documentation,
      [name]: value,
    };
    setDocumentation(docTemp);
  };

  const inputChangeHandler2 = (e) => {
    let value = e.target.value;
    console.log(e.target.value);
    console.log(e.target.name);
    // setSelectedOption(e.target.value)
    switch (value) {
      // case "visible":

      //         setIfSupplier(false);
      //          setIfClient(false);

      //     let docTemp = {
      //         ...documentation,
      //         global: 1,
      //         suppliers : null,
      //         clients : null
      //     }
      //     setDocumentation(docTemp)

      //     break;
      case "fichier":
        let docTemp = {
          ...documentation,
          documentationType: value,
        };
        setDocumentation(docTemp);
        break;
      case "lien":
        let docTempp = {
          ...documentation,
          documentationType: value,
        };
        setDocumentation(docTempp);
        break;
      // case "edi":
      //     let docTempp = {
      //         ...documentation,
      //         [name]: value
      //     }
      //     setDocumentation(docTempp)
      //     break;
      default:
        break;
    }
  };
  const chekedDocumentation = () => {
    if (
      documentation?.docName &&
      documentation.docName != "" &&
      documentation?.category &&
      documentation.category != "" &&
      ((documentation?.fileName && documentation.fileName != "") ||
        (documentation?.lien && documentation?.lien != ""))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const addCategoriesHandle = (categoryName) => {
    Axios.put('/documentation//addCategory', { categoryName: categoryName }).then(response => {
        if (response?.data?.success) {
            setCategoriesUpdates(!categoriesUpdates)
        } else if (response?.data?.alreadyExist) {
            notyf.error('Cette catégorie existe déjà!')
        } else {
            notyf.error("Une erreur s\'est produite !")
        }
    })
  }

  const addCategorie = (category) => {
    if(category !== ""){
        addCategoriesHandle(category)
    }else 
        setShowError(true)
  } 

  return (
    <>
      <NDContainer>
        <Header>
          <HeaderTitle style={{marginLeft: "95px"}}>Ajouter un document</HeaderTitle>

          <HeaderActions>
            <BtnAction
              bg="#55EFC466"
              disabled={chekedDocumentation()}
              onClick={() =>
                documentation?.documentationType == "fichier"
                  ? addDocumentationclickHandler()
                  : addDocumentationclickHandlerOfLien()
              }
            >
              <span>Ajouter</span>
            </BtnAction>
          </HeaderActions>
        </Header>
        <NDrow>
          <NDCol6>
            {url ? (
              <DocVisu>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                <Viewer fileUrl={url} />
              </DocVisu>
            ) : (
              <NoContent>{t("global:previewArea")}</NoContent>
            )}
          </NDCol6>

          <NDCol6>
            <ISContainer>
              <EIFormGroup>
                <EIFormLabel htmlFor="docName">Nom<MandatoryMarker /> :</EIFormLabel>
                <Input
                  placeHolder={"Nom"}
                  id="docName"
                  name="docName"
                  type="text"
                  color="#06152B"
                  autoComplete="off"
                  value={documentation?.docName || ""}
                  onChange={(e) => inputChangeHandler(e)}
                />
              </EIFormGroup>
              
              <div style={{}}>

                <EIFormGroup style={{ justifyContent: "space-between" }}>
                  <EIFormLabel htmlFor="first_name">Catégorie<MandatoryMarker /> :</EIFormLabel>
                  <SelectFilter
                    style={{ width: "300px" }}
                    name="category"
                    value={documentation?.category || ""}
                    onChange={(e) => inputChangeHandler(e)}
                  >
                    <option value={-1}></option>
                    {categories?.map((category, index) => {
                      return (
                        <option key={index} value={category?.id}>
                          {category?.name}{" "}
                        </option>
                      );
                    })}
                  </SelectFilter>
                  {
                    !showNewCategoryInputField ? 
                      <CTooltip title="Nouvelle catégorie" style={{  fontSize: "20px"}}>
                        <EastIcon style={{cursor: "pointer"}} onClick={(e) => setShowNewCategoryInputField(true)} />
                      </CTooltip>
                    : 
                    <CTooltip title="Nouvelle catégorie" style={{  fontSize: "20px"}}>
                        <NorthIcon style={{cursor: "pointer"}} onClick={(e) => setShowNewCategoryInputField(false)} />
                      </CTooltip>

                  }
                  
                </EIFormGroup>
                {
                    showNewCategoryInputField ? 
                      <div class="input-group mb-3">
                          <input type="text" className="form-control" value ={categoryName} style={{height: "34px"}} onChange={(e) => {setCategoryName(e.target.value);setShowError(false)}}/>
                          {/* <CTooltip title="Ajouter" style={{  fontSize: "20px"}}>
                            <AddIcon onClick={(e) => addCategorie(categoryName)} className="addCatBotton"/> 
                          </CTooltip> */}
                          <BtnAction bg="#55EFC466"
                              style={{height: "34px"}}
                              onClick={() => addCategorie(categoryName)}
                            >
                              <span>Valider</span>
                          </BtnAction>
                      </div> : null
                }
                {
                  (showError && showNewCategoryInputField) && 
                      <div> 
                          <p style={{marginLeft:"10px", fontSize: "12px",color:"red"}}>Veuillez saisir un nom de catégorie</p>
                      </div>
                }
              </div>

              <EIFormGroup>
                <EIFormLabel
                  htmlFor="visible"
                  className="accountRequest__canalItemLabel"
                >
                  Visible aux fournisseurs
                </EIFormLabel>
                <ToggleDiv>
                  {visible ? (
                    <ToggleOnIcon
                      style={{
                        marginLeft: "0.25rem",
                        color: theme.colors.success,
                        cursor: "pointer",
                      }}
                      fontSize="small"
                      name="edi"
                      id="visible"
                      onClick={() => updateVisible()}
                    />
                  ) : (
                    <ToggleOffIcon
                      style={{ marginLeft: "0.25rem", cursor: "pointer" }}
                      fontSize="small"
                      name="edi"
                      onClick={() => updateVisible()}
                    />
                  )}
                </ToggleDiv>
              </EIFormGroup>
              {documentation?.fileName && (
                <EIFormGroup>
                  <EIFormLabel htmlFor="docName">Fichier</EIFormLabel>
                  <Input
                    placeHolder={"Fichier"}
                    name="docName"
                    color="#06152B"
                    type="text"
                    autoComplete="off"
                    value={documentation?.fileName || ""}
                  />
                </EIFormGroup>
              )}

              <EIFormGroup>
                <EIFormLabel
                  style={{ marginRight: "17%" }}
                  htmlFor="lien"
                  className="accountRequest__canalItemLabel"
                >
                  Type<MandatoryMarker /> :
                </EIFormLabel>
                <EIFormGroupChecked>
                  <InputCheckbox
                    type="radio"
                    style={{ marginRight: "10px" }}
                    id="lien"
                    value="lien"
                    name="web"
                    onChange={inputChangeHandler2}
                    checked={documentation.documentationType === "lien"}
                  />
                  <EIFormLabel htmlFor="docs" class="form-check-label">
                    Lien
                  </EIFormLabel>
                </EIFormGroupChecked>

                <EIFormGroupChecked>
                  <InputCheckbox
                    type="radio"
                    style={{ marginRight: "10px" }}
                    id="doc"
                    name="web"
                    value="fichier"
                    onChange={inputChangeHandler2}
                    checked={documentation.documentationType == "fichier"}
                  />
                  <EIFormLabel class="form-check-label" htmlFor="client">
                    Document
                  </EIFormLabel>
                </EIFormGroupChecked>
              </EIFormGroup>

              {documentation.documentationType === "fichier" && (
                <>
                  <EIFormGroup>
                    <FileUploaderBtn
                      btnLabel="Choisir un fichier *"
                      handleChange={handleFileChange}
                      name="filename"
                      ref={uploader}
                    />
                  </EIFormGroup>
                  <p style={{fontSize: "10px"}}><MandatoryMarker /> Seuls les documents de type PDF sont acceptés</p>
                </>
              )}

              {documentation.documentationType === "lien" && (
                <EIFormGroup>
                  <EIFormLabel htmlFor="liens">{"Lien * :"}</EIFormLabel>
                  <Input
                    placeHolder={"Copier le lien ici"}
                    id="liens"
                    name="lien"
                    color="#06152B"
                    type="text"
                    autoComplete="off"
                    value={documentation?.lien || ""}
                    onChange={(e) => inputChangeHandler(e)}
                  />
                </EIFormGroup>
              )}
            </ISContainer>
          </NDCol6>
        </NDrow>
      </NDContainer>
    </>
  );
}

export default NewDocumentation;
