import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 10,
    pageSize: 10,
 
}

const lastEventsStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const lastEventsSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false,
})

const lastEventsFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    
})

const lastEventsSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.LASTEVENTS_START: return lastEventsStart(state, action)
        case actionTypes.LASTEVENTS_SUCCESS: return lastEventsSuccess(state, action)
        case actionTypes.LASTEVENTS_FAIL: return lastEventsFail(state, action)
        case actionTypes.LASTEVENTS_SET_PAGESIZE: return lastEventsSetPageSize(state, action)
       
        default:
            return state
    }
}
export default reducer